import React from 'react';
import PropTypes from 'prop-types';
import Comment from 'components/UserComment';

class CommentPopUp extends React.Component {

  constructor() {
    super();
    this.popupBtnRef = React.createRef();
    this.popupRef = React.createRef();
  }

  componentDidMount() {
    $(this.popupBtnRef.current).popup({
      inline: false,
      hoverable: true,
      position: 'top center',
      lastResort: 'top center',
      delay: {
        show: 300,
        hide: 800,
      },
      popup: this.popupRef.current,
    });
  }

  render() {
    return (
      <span>
        <i ref={this.popupBtnRef} className="comments outline icon" />
        <div className="ui popup" ref={this.popupRef}>
          <Comment tiny user={this.props.comment.user}>
            {this.props.comment.comment}
          </Comment>
        </div>
      </span>
    );
  }
}

export default CommentPopUp;

CommentPopUp.propTypes = {
  comment: PropTypes.object,
};
