import {dispatch} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const Creative = {
  /**
   * Dispatches an action to fetch a creative
   * @param {Object} data {accountId, creativeId}
   */
  get(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.GET_CREATIVE,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to fetch creatives
   * @param {Object} data data object
   */
  list(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.LIST_CREATIVES,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to add a creative
   * @param {Object} data data object
   */
  add(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.ADD_CREATIVE,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to update creative
   * @param {Object} data Data accountId, creativeId, label, creativeFormatId, creativeLabels
   */
  update(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.UPDATE_CREATIVE,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to remove a folder
   * @param {Object} data data object
   */
  remove(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.REMOVE_CREATIVE,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to push live a creative
   * @param {Object} data {accountId, creativeId}
   */
  pushLive(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.PUSH_LIVE,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to push creatives in folder live
   * @param {Object} data data object
   */
  pushCreativesLive(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.PUSH_CREATIVES_LIVE,
    }, data);
    dispatch(obj);
  },
  /**
   * Dispatches an event to duplicate a creative
   * @param {Object} data {AccountId, creativeId, label, targetFolderId, originalFolderId}
   */
  duplicate(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.DUPLICATE_CREATIVE,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to download a creative
   * @param {Object} data data object
   */
  download(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.DOWNLOAD_CREATIVE,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to search for matching insertions to assign
   * @param {Object} data data object
   */
  searchMatchingInsertions(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.SEARCH_MATCHING_INSERTIONS,
    }, data);

    dispatch(obj);
  },
};

export default Creative;
