/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import {ContextStore, CreativeStore, FolderStore, InsertionStore, CampaignStore, ProjectStore } from 'stores';
import Progress from 'weborama-ui-react/Progress';
import AppConstants from 'constants/AppConstants';
import './loadingscreen.sass';
/**
 * Class loading screen
 * TODO: Since we mount this component on CL and CM, see if we can remove all store callbacks and handle
 * the loading progress over props.
 */
class LoadingScreen extends React.Component {
  /**
   * constructor
   * @date   2016-12-24
   */
  constructor() {
    super();

    this.state = {
      showLoadingScreen: true,
      CMLoading: {
        projectList: false,
        campaignList: false,
        // insertionList: false,
      },
      CLLoading: {
        folderList: false,
        creativeList: false,
      },
      progress: 0,
      active: ContextStore.routingParams() ? ContextStore.routingParams().active : undefined,
    };

    this.onCreativeLibraryStoresChange = this.onCreativeLibraryStoresChange.bind(this);
    this.onCampaignManagerStoresChange = this.onCampaignManagerStoresChange.bind(this);
    this.handleProgressFinish = this.handleProgressFinish.bind(this);

    ProjectStore.addChangeListener(this.onCampaignManagerStoresChange);
    CampaignStore.addChangeListener(this.onCampaignManagerStoresChange);
    InsertionStore.addChangeListener(this.onCampaignManagerStoresChange);
    FolderStore.addChangeListener(this.onCreativeLibraryStoresChange);
    CreativeStore.addChangeListener(this.onCreativeLibraryStoresChange);

    this.progressBarRef = React.createRef();
    this.loadingScreenRef = React.createRef();
  }

  /**
   * Fade out component when progess 100 has been reached
   * @date   2016-12-24
   * @param  {Object}   props properties
   * @param  {Object}   prevState state
   */
  componentDidUpdate(props, prevState) {

    if (ContextStore.routingParams().active !== this.state.active) {
      const CMLoading = {
        projectList: false,
        campaignList: false,

      };
      const CLLoading = {
        folderList: false,
        creativeList: false,
      };
      this.setState({
        showLoadingScreen: true, active: ContextStore.routingParams().active, CMLoading, CLLoading,
      });
    }

    if (prevState.progress !== this.state.progress) {
      const increment = this.state.progress - prevState.progress;
      this.progressBarRef.current.increment(increment);
    }

    if (prevState.active !== undefined && (prevState.active !== this.state.active)) {
      // When it's undefined, we're navigating from a place where active is not set (ex. Accounts menu)
      // But we want this to be happening when we are transitioning to different sections to prevent Loading screen being hanged!
      $(this.loadingScreenRef.current).transition({
        transition: 'show',
        duration: 0,
      });
    }
  }

  /**
   * unregister store callbacks
   * @date   2016-12-22
   */
  componentWillUnmount() {
    ProjectStore.removeChangeListener(this.onCampaignManagerStoresChange);
    CampaignStore.removeChangeListener(this.onCampaignManagerStoresChange);
    InsertionStore.removeChangeListener(this.onCampaignManagerStoresChange);
    FolderStore.removeChangeListener(this.onCreativeLibraryStoresChange);
    CreativeStore.removeChangeListener(this.onCreativeLibraryStoresChange);
  }

  /**
   * CampaignManager stores change
   * @date   2016-12-24
   * @param  {Object}   data data object
   */
  onCampaignManagerStoresChange(data) {
    if (!this.state.showLoadingScreen) {
      return;
    }
    const loading = this.state.CMLoading;
    let {progress} = this.state;

    switch (data.action) {
      case AppConstants.LIST_PROJECTS:
        loading.projectList = false;
        if (ProjectStore.getProjects().length === 0) {
          progress = 100;
        } else {
          progress = this.state.progress + (100 / Object.keys(this.state.CMLoading).length);
        }
        break;
      case AppConstants.LIST_CAMPAIGNS:
        loading.campaignList = false;

        progress = this.state.progress + (100 / Object.keys(this.state.CMLoading).length);

        if (CampaignStore.getCampaigns().length === 0) {
          progress += (100 / Object.keys(this.state.CMLoading).length);
        }
        break;
      // case AppConstants.LIST_INSERTIONS:
      //   loading.insertionList = false;
      //   progress = this.state.progress + (100 / Object.keys(this.state.CMLoading).length);
      //   break;
      // no default
    }
    this.setState({CMLoading: loading, progress});
  }

  /**
   * On Creative Library stores change
   * @date   2016-12-24
   * @param  {Object}   data data
   */
  onCreativeLibraryStoresChange(data) {
    if (!this.state.showLoadingScreen) {
      return;
    }
    const loading = this.state.CLLoading;
    let {progress} = this.state;

    switch (data.action) {
      case AppConstants.LIST_FOLDERS:
        loading.folderList = false;
        if (FolderStore.getFolders().length === 0) {
          progress = 100;
        } else {
          progress = this.state.progress + (100 / Object.keys(this.state.CLLoading).length);
        }
        break;
      case AppConstants.LIST_CREATIVES:
        loading.creativeList = false;
        progress = this.state.progress + (100 / Object.keys(this.state.CLLoading).length);
        break;
      // no default
    }
    this.setState({CLLoading: loading, progress});
  }

  /**
   * Fade out on load complete
   * @date   2016-12-25
   */
  handleProgressFinish() {
    $(this.loadingScreenRef.current).transition({
      transition: 'hide',
      onComplete: () => {
        this.progressBarRef.current.reset();
        this.setState({progress: 0, showLoadingScreen: false});
      },
    });
  }

  /**
   * Renders loading screen
   * @date   2016-12-22
   * @return {array}   nodes
   */
  render() {
    return (
      <div ref={this.loadingScreenRef} className="loading-wrapper">
        <div className="ui basic center aligned middle aligned segment" style={{width: '560px', height: '350px'}}>
          <span style={{width: '63px', height: '63px'}}>
            <span className="weborama-logo" />
          </span>
          <h4 className="ui header">
            {this.state.active === 'cm' ? 'Campaign Manager' : 'Creative Library'}
          </h4>
          <Progress blue ref={this.progressBarRef} tiny onSuccess={this.handleProgressFinish} />
        </div>
      </div>
    );
  }
}

export default LoadingScreen;
