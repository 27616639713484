import PropTypes from 'prop-types';
import React from 'react';
import {CreativeLabelStore, ContextStore} from 'stores';
import {CreativeLabel} from 'actions';
import Dropdown from 'weborama-ui-react/Dropdown';
import Menu from 'weborama-ui-react/Menu';
import {isEmptyObject} from 'constants/Utils';
/**
 * Handles creative labels
 */
class CreativeLabels extends React.Component {
  /**
   * constructor
   * @date   2016-07-29
   * @param  {Object}   props properties
   */
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      creative: props.creative || {},
      creativeLabels: [],
    };

    this.onCreativeLabelStoreChange = this.onCreativeLabelStoreChange.bind(this);
    this.handleDropDownChange = this.handleDropDownChange.bind(this);
    this.calculateCreativeState = this.calculateCreativeState.bind(this);

    CreativeLabelStore.addChangeListener(this.onCreativeLabelStoreChange);

    this.creativeLabelDropdownRef = React.createRef();
  }

  /**
   * Initialize dropdown
   */
  componentDidMount() {
    CreativeLabel.list({accountId: ContextStore.routingParams().accountId});
    this.calculateCreativeState();
  }

  /**
   * set creative labels
   * @date   2016-07-29
   * @param  {object}   prevProps previous properties
   * @param  {object}   prevState previous state
   */
  componentDidUpdate(prevProps, prevState) {
    this.calculateCreativeState();

    if (!isEmptyObject(this.state.creative)
      && JSON.stringify(this.state.creativeLabels) !== JSON.stringify(prevState.creativeLabels)) {
      const labels = this.state.creative.creative_labels.map(creativeLabel => creativeLabel.label);
      this.creativeLabelDropdownRef.current.setSelected(labels);
    }
  }

  /**
   * unregister change event listeners
   * @date   2016-07-29
   */
  componentWillUnmount() {
    CreativeLabelStore.removeChangeListener(this.onCreativeLabelStoreChange);
  }

  /**
   * Invoked on creative label store change
   * @date   2016-07-29
   */
  onCreativeLabelStoreChange() {
    const creativeLabels = CreativeLabelStore.getCreativeLabels();
    this.setState({loading: false, creativeLabels});
  }

  calculateCreativeState() {
    if (JSON.stringify(this.props.creative) !== JSON.stringify(this.state.creative)) {
      this.setState({creative: this.props.creative});
    }
  }

  /**
   * handles drop down changes
   * @date   2016-09-26
   * @param  {[type]}   e [description]
   */
  handleDropDownChange(e) {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(e.split(','));
    }
  }

  /**
   * Renders creative label search selection dropdown
   * @date   2016-07-29
   * @return {jsx}   creative label dropdown
   */
  render() {
    const creativeLabels = this.state.creativeLabels.map(item => (
      <div key={`cl-${item.label}`} className="item" data-value={item.label}>{item.label}</div>
    ));
    return (
      <Dropdown
        ref={this.creativeLabelDropdownRef}
        multiple
        selection
        search
        maxSelections={3}
        allowAdditions
        loading={this.state.loading}
        className="creative-labels-dropdown"
        onChange={this.handleDropDownChange}
      >
        <Menu>
          {creativeLabels}
        </Menu>
      </Dropdown>
    );
  }
}

export default CreativeLabels;

CreativeLabels.propTypes = {
  creative: PropTypes.object,
  onChange: PropTypes.func,
};
