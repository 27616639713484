import {dispatch} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const Token = {
  /**
   * Dispatches an action to generate a public token. The token is
   * used to make a public url where you can view tags based on insertions
   *
   * @param {Object} data Data object
   */
  generate(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.GENERATE_TOKENS,
    }, data);

    dispatch(obj);
  },
};

export default Token;
