import PropTypes from 'prop-types';
import React from 'react';
import PreviewRichMedia from 'components/PreviewRichMedia';
import PreviewVast from 'components/PreviewVast';
import ImageAd from 'components/ImageAd';
/**
 * AdPosition - tags
 * TODO: Create re-launch button, so we can relaunch the preview by removing the Iframe, instead of reloading the page
 * TODO2: Add Image Preview
 */
const PreviewAdPosition = (props) => {
  let creativeType = 'unknown creative type';
  if (props.creativeType === false) {
    window.location.reload();
  } else if (props.creativeType.id.toString() === '6' || props.creativeType.id.toString() === '2') {
    creativeType = (
      <PreviewRichMedia
        tag={props.setupScript.setup_script}
        debug
        size={props.size}
      />
    );
  } else if (props.creativeType.id.toString() === '1') {
    creativeType = (
      <PreviewVast
        setupScript={props.setupScript}
      />
    );
  } else if (props.creativeType.key_label === 'image_ad') {
    creativeType = (
      <ImageAd
        tag={props.setupScript.setup_script}
      />
    );
  }
  return (
    <div>
      {creativeType}
    </div>
  );
};

export default PreviewAdPosition;

PreviewAdPosition.propTypes = {
  size: PropTypes.object,
  creativeType: PropTypes.object,
  setupScript: PropTypes.object,
};
