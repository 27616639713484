import React from 'react';
import { register, unregister } from 'dispatchers/ToastrDispatcher';
import { ToastContainer, ToastMessageAnimated } from 'react-toastr';
import ToastrConstants from 'constants/ToastrConstants';

require('./toastr.min.css');
require('./animate.min.css');

const defaultToastrSettings = {
  timeOut: 15000,
  extendedTimeOut: 10000,
  hideAnimation: 'animated fadeOut',
  showAnimation: 'animated bounceInRight',
};

/**
 * Class Toastr
 */
class Toastr extends React.Component {
  /**
   * Constructor
   * @date   2016-11-29
   */
  constructor() {
    super();
    this.ToastMessageFactory = React.createFactory(ToastMessageAnimated);
    this.fluxId = '';
    this.toasterRef = React.createRef();
  }

  /**
   * Register error event listeners
   * @date   2016-11-29
   * //TODO: Object.assign the default settings with action.settings.
   */
  componentDidMount() {
    this.fluxId = register((action) => {
      switch (action.actionType) {
        case ToastrConstants.SUCCESS:
          this.toasterRef.current.success(
            action.message.body,
            action.message.title,
            defaultToastrSettings
          );
          break;
        case ToastrConstants.ERROR:
          this.toasterRef.current.error(
            action.message.body,
            action.message.title,
            defaultToastrSettings
          );
          break;
        case ToastrConstants.WARNING:
          this.toasterRef.current.warning(
            action.message.message,
            action.message.action,
            defaultToastrSettings
          );
          break;
        case ToastrConstants.INFO:
          this.toasterRef.current.info(
            action.message.body,
            action.message.title,
            defaultToastrSettings
          );
          break;
        // no default
      }
    });
  }

  /**
   * Unregister flux id
   * @date   2016-11-29
   */
  componentWillUnmount() {
    unregister(this.fluxId);
  }

  /**
   * [render description]
   * @date   2016-11-29
   * @return {[type]}   [description]
   */
  render() {
    return (
      <ToastContainer
        ref={this.toasterRef}
        className="toast-top-right"
        toastMessageFactory={this.ToastMessageFactory}
        preventDuplicates={false}
        newestOnTop
      />
    );
  }
}

export default Toastr;
