import BaseStore from 'stores/BaseStore';
import ServerConstants from 'constants/ServerConstants';
import {register} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const CreativeLabelStore = Object.assign({}, BaseStore, {
  creativeLabels: [],
  /**
   * return list of creative labels
   * @date   2016-07-29
   * @return {[type]}   [description]
   */
  getCreativeLabels() {
    return this.creativeLabels;
  },
  /**
   * Adds a creative label
   * @date  2016-07-29
   * @param {number}   accountId account id
   * @param {string}   label     label
   */
  addCreativeLabel(data) {
    const onSuccess = () => {
      this.fetchCreativeLabels({account_id: data.account_id});
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.ADD_CREATIVE_LABEL, message});
    };

    this.post({
      url: ServerConstants.CREATIVE_LIBRARY.CREATIVE_LABELS.CREATE,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Fetches a list of creative labels
   * @date   2016-07-29
   * @param  {number}   accountId accountId
   */
  listCreativeLabels(data) {
    const onSuccess = (response) => {
      this.creativeLabels = response.data.slice();
      this.emitChange({action: AppConstants.LIST_CREATIVE_LABELS});
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.LIST_CREATIVE_LABELS, message});
    };

    this.post({
      url: ServerConstants.CREATIVE_LIBRARY.CREATIVE_LABELS.LIST,
      data,
      onSuccess,
      onError,
    });
  },

  dispatcherIndex: register((action) => {
    // Action will be handled here e.g. remove, edit etc.
    switch (action.actionType) {
      case AppConstants.LIST_CREATIVE_LABELS:
        CreativeLabelStore.listCreativeLabels({
          account_id: action.accountId,
        });
        break;
      // TODO: This can be removed (it is automaticly created when it does not exsists)
      case AppConstants.ADD_CREATIVE_LABEL:
        CreativeLabelStore.addCreativeLabel({
          account_id: action.accountId,
          label: action.label,
        });
        break;
      // no default
    }
    return true;
  }),
});

export default CreativeLabelStore;
