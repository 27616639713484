import BaseStore from 'stores/BaseStore';
import ServerConstants from 'constants/ServerConstants';
import {register} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const AdPositionStore = Object.assign({}, BaseStore, {
  adPositions: [],
  setupScript: {},
  /**
   * returns AdPositions
   * @date   2016-01-14
   * @return {array}   [Folders]
   */
  getAdPositions() {
    return this.adPositions;
  },
  /**
   * Returns setupscript
   * @date   2016-09-14
   * @return {object}   SetupScript
   */
  getSetupScript() { // Be warned that this data might be lagging!
    return this.setupScript;
  },
  /**
   * Creates an ad position
   * @date   2016-07-21
   * @param  {number}   accountId           account id
   * @param  {number}   creativeVersionId creative version id
   * @param  {string}   setupScript        setupscript ?
   * @param  {number}   sizeId             =             1 size id default 1x1
   */
  addAdPosition(data) {
    const onSuccess = (response) => {
      this.emitChange({action: AppConstants.ADD_ADPOSITION, data: response});
      this.listAdPositions({
        account_id: data.account_id,
        folder_id: data.folder_id,
        creative_id: data.creative_id,
        creative_version_id: data.creative_version_id,
      });
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.ADD_ADPOSITION, message});
    };

    this.post({
      url: ServerConstants.CREATIVE_LIBRARY.AD_POSITIONS.CREATE,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Updates an ad position
   * @date   2016-07-25
   * @param  {number}   accountId             account id
   * @param  {number}   adPositionId          ad position id
   * @param  {number}   sizeId                =             undefined size id
   * @param  {String}   beforeAdElementScript =             undefined before ad element script
   * @param  {String}   beforeRenderScript    =             undefined before render script
   * @param  {String}   afterRenderScript     =             undefined after render script
   */
  updateAdPosition(data) {
    const onSuccess = () => {
      this.emitChange({action: AppConstants.UPDATE_AD_POSITION});
      this.listAdPositions({
        account_id: data.account_id,
        folder_id: data.folder_id,
        creative_id: data.creative_id,
        creative_version_id: data.creative_version_id,
      });
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.UPDATE_AD_POSITION}, message);
    };

    this.post({
      url: ServerConstants.CREATIVE_LIBRARY.AD_POSITIONS.UPDATE,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Removes an ad position
   * @date   2016-07-22
   * @param  {number}   accountId    account id
   * @param  {number}   adPositionId ad position id
   */
  removeAdPosition(data) {
    const onSuccess = () => {
      this.emitChange({action: AppConstants.REMOVE_ADPOSITION});
      this.listAdPositions({
        account_id: data.account_id,
        folder_id: data.folder_id,
        creative_id: data.creative_id,
        creative_version_id: data.creative_version_id,
      });
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.REMOVE_ADPOSITION, message});
    };

    this.post({
      url: ServerConstants.CREATIVE_LIBRARY.AD_POSITIONS.DELETE,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Fetches adpositions by account id and creative version id
   * @date   2016-02-12
   * @param  {number}   accountId          Account Id
   * @param  {number}   creativeVersionId Cretive version id
   */
  listAdPositions(queryData) {
    const onSuccess = (response) => {
      this.adPositions = response.data.slice();
      this.emitChange({action: AppConstants.LIST_ADPOSITIONS});
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.LIST_ADPOSITIONS, message});
    };

    const orderSettings = {
      status: 'active',
    };

    const data = Object.assign({}, queryData, orderSettings);

    this.post({
      url: ServerConstants.CREATIVE_LIBRARY.AD_POSITIONS.LIST,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Fetches setupscript
   * @date   2016-09-14
   * @param  {number}   accountId    account id
   * @param  {number}   adPositionId ad position id
   */
  fetchSetupScript(data) {
    const onSuccess = (response) => {
      this.setupScript = response.data;
      this.emitChange({action: AppConstants.GET_SETUP_SCRIPT, setupScript: response.data});
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.GET_SETUP_SCRIPT, message});
    };

    this.post({
      url: ServerConstants.CREATIVE_LIBRARY.AD_POSITIONS.GET_SETUP_SCRIPT,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Updates setupscript
   * @param {Object} data data object
   */
  updateSetupScript(data) {
    const onSuccess = () => {
      this.emitChange({action: AppConstants.UPDATE_SETUP_SCRIPT});
      this.fetchSetupScript({
        account_id: data.account_id,
        folder_id: data.folder_id,
        creative_id: data.creative_id,
        creative_version_id: data.creative_version_id,
        id: data.id,
      });

      this.listAdPositions({
        account_id: data.account_id,
        folder_id: data.folder_id,
        creative_id: data.creative_id,
        creative_version_id: data.creative_version_id,
      });
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.UPDATE_SETUP_SCRIPT, message});
    };

    this.post({
      url: ServerConstants.CREATIVE_LIBRARY.AD_POSITIONS.UPDATE,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Locks setupscript
   * @date   2016-09-20
   * @param  {Number}   accountId    account id
   * @param  {Number}   adPositionId ad position id
   * @param  {String}   setupScript  setupScript
   * @param  {String}   lock         "YES" locked
   */
  lockSetupScript(data) {
    const onSuccess = () => {
      this.emitChange({action: AppConstants.LOCK_SETUP_SCRIPT});

      this.fetchSetupScript({
        account_id: data.account_id,
        folder_id: data.folder_id,
        creative_id: data.creative_id,
        creative_version_id: data.creative_version_id,
        id: data.id,
      });

      this.listAdPositions({
        account_id: data.account_id,
        folder_id: data.folder_id,
        creative_id: data.creative_id,
        creative_version_id: data.creative_version_id,
      });
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.LOCK_SETUP_SCRIPT, message});
    };

    this.post({
      url: ServerConstants.CREATIVE_LIBRARY.AD_POSITIONS.UPDATE,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Handle Store actions
   * @date   2016-02-11
   * @param  {Function}   function(action -
   */
  dispatcherIndex: register((action) => {
    switch (action.actionType) {
      case AppConstants.LIST_ADPOSITIONS:
        AdPositionStore.listAdPositions({
          account_id: action.accountId,
          folder_id: action.folderId,
          creative_id: action.creativeId,
          creative_version_id: action.creativeVersionId,
        });
        break;
      case AppConstants.ADD_ADPOSITION:
        AdPositionStore.addAdPosition({
          account_id: action.accountId,
          folder_id: action.folderId,
          creative_id: action.creativeId,
          creative_version_id: action.creativeVersionId,
        });
        break;
      case AppConstants.REMOVE_ADPOSITION:
        AdPositionStore.removeAdPosition({
          account_id: action.accountId,
          folder_id: action.folderId,
          creative_id: action.creativeId,
          creative_version_id: action.creativeVersionId,
          id: action.adPositionId,
        });
        break;
      case AppConstants.UPDATE_AD_POSITION:
        AdPositionStore.updateAdPosition({
          account_id: parseInt(action.accountId, 10),
          folder_id: parseInt(action.folderId, 10),
          creative_id: parseInt(action.creativeId, 10),
          creative_version_id: parseInt(action.creativeVersionId, 10),
          id: parseInt(action.adPositionId, 10),
          size_id: parseInt(action.sizeId, 10),
          before_ad_element_script: action.beforeAdElementScript,
          before_render_script: action.beforeRenderScript,
          after_render_script: action.afterRenderScript,
        });
        break;
      case AppConstants.GET_SETUP_SCRIPT:
        AdPositionStore.fetchSetupScript({
          account_id: action.accountId,
          folder_id: action.folderId,
          creative_id: action.creativeId,
          creative_version_id: action.creativeVersionId,
          id: Number(action.adPositionId),
        });
        break;
      case AppConstants.UPDATE_SETUP_SCRIPT:
        AdPositionStore.updateSetupScript({
          account_id: action.accountId,
          folder_id: action.folderId,
          creative_id: action.creativeId,
          creative_version_id: action.creativeVersionId,
          id: action.adPositionId,
          before_ad_element_script: action.beforeAdElementScript,
          before_render_script: action.beforeRenderScript,
          after_render_script: action.afterRenderScript,
          lock_script: 'NO',
        });
        break;
      case AppConstants.LOCK_SETUP_SCRIPT:
        if (action.lock) {
          AdPositionStore.lockSetupScript({
            account_id: action.accountId,
            folder_id: action.folderId,
            creative_id: action.creativeId,
            creative_version_id: action.creativeVersionId,
            id: action.adPositionId,
            setup_script: action.setupScript,
            lock_script: 'YES',
          });
        } else {
          AdPositionStore.updateSetupScript({
            account_id: action.accountId,
            folder_id: action.folderId,
            creative_id: action.creativeId,
            creative_version_id: action.creativeVersionId,
            id: action.adPositionId,
            before_ad_element_script: action.beforeAdElementScript,
            before_render_script: action.beforeRenderScript,
            after_render_script: action.afterRenderScript,
            lock_script: 'NO',
          });
        }
        break;
      // no default
    }
    return true; // No errors. Needed by promise in Dispatcher.
  }),
});

export default AdPositionStore;
