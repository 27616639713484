import {dispatch} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const Tag = {
  /**
   * Dispatches an action to fetch a list of tags
   *
   * @param {object} data data object
   */
  list(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.LIST_TAGS,
    }, data);

    dispatch(obj);
  },
};

export default Tag;
