import PropTypes from 'prop-types';
import React from 'react';
import TagStore from 'stores/TagStore';
import {AdSpaceStore, ContextStore} from 'stores';
import {Tag, AdSpace} from 'actions';
import {JsTag, VastTag, ImageTag} from 'components/Tag';
import Segment from 'weborama-ui-react/Segment';
import Menu from 'weborama-ui-react/Menu';
import Item from 'weborama-ui-react/Item';
import {getObjectInArray, isEmptyObject} from 'constants/Utils';
import Loader from 'weborama-ui-react/Loader';
import Dimmer from 'weborama-ui-react/Dimmer';
import Input from 'weborama-ui-react/Input';
import Form, {Field} from 'weborama-ui-react/Form';
import CopyToClipboard from 'components/CopyToClipboard';
/**
 * Class TagManager
 */
class TagManager extends React.Component {
  /**
   * constructor
   * @date   2016-11-14
   * @param  {Object}   props properties
   */
  constructor(props) {
    super(props);

    this.state = {
      insertion: props.insertion || {},
      tags: [],
      placements: [],
      loading: true,
      activeTab: 0,
      ssl: true,
    };

    this.onTagStoreChange = this.onTagStoreChange.bind(this);
    this.handleOpenPublicPage = this.handleOpenPublicPage.bind(this);
    this.onAdSpaceStoreChange = this.onAdSpaceStoreChange.bind(this);

    TagStore.addChangeListener(this.onTagStoreChange);
    AdSpaceStore.addChangeListener(this.onAdSpaceStoreChange);
  }

  /**
   * Fetch list of assigned creatives
   * @date   2016-09-05
   */
  componentDidMount() {
    if (!isEmptyObject(this.state.insertion)) {
      AdSpace.listPlacements({
        accountId: ContextStore.routingParams().accountId,
        adSpaceId: this.state.insertion.ad_space_id,
      });
      Tag.list({
        accountId: ContextStore.routingParams().accountId,
        projectId: ContextStore.routingParams().projectId,
        campaignId: ContextStore.routingParams().campaignId,
        insertionId: this.state.insertion.id,
      });
    }
  }

  /**
   * Unregister store callbacks
   * @date   2016-09-05
   */
  componentWillUnmount() {
    TagStore.removeChangeListener(this.onTagStoreChange);
    AdSpaceStore.removeChangeListener(this.onAdSpaceStoreChange);
  }

  /**
   * invoked on tag store change
   * @date   2016-09-05
   */
  onTagStoreChange() {
    const tags = TagStore.getTags();
    this.setState({
      tags,
      loading: false,
      activeTab: tags[0].placement_id,
    });
  }

  /**
   * Ad Space store callback
   * @date   2016-11-02
   */
  onAdSpaceStoreChange() {
    this.setState({
      placements: AdSpaceStore.getPlacements(),
    });
  }

  /**
   * Handles opening public token page
   */
  handleOpenPublicPage() {
    if (this.state.tags.length > 0) {
      window.open(`${window.location.origin}/public/tags/${encodeURIComponent(this.state.tags[0].token)}`);
    }
  }

  /**
   * Renders tag manager
   * @date   2016-09-05
   * @return {Array}   Node
   */
  render() {
    const tabs = this.state.tags.map((item) => {
      const placement = getObjectInArray(this.state.placements, 'id', item.placement_id);
      return (
        <Item
          key={item.placement_id}
          active={item.placement_id === this.state.activeTab}
          onClick={() => { this.setState({activeTab: item.placement_id}); }}
        >
          {placement.label}
        </Item>
      );
    });
    let publicUrl = '';
    if (this.state.tags.length > 0) {
      publicUrl = `${window.location.origin}/public/tags/${encodeURIComponent(this.state.tags[0].token)}`;
    }
    const tag = getObjectInArray(this.state.tags, 'placement_id', this.state.activeTab) || {};
    return (
      <div style={{minHeight: '250px'}}>
        <Dimmer inverted active={this.state.loading}>
          <Loader inverted />
        </Dimmer>
        <Form>
          <Field label="Public url">
            <Input
              fluid
              readOnly
              value={publicUrl}
            />
            <CopyToClipboard value={publicUrl} />
            <div
              title="Open in new window"
              style={{cursor: 'pointer'}}
              className="ui top pointing label"
              onClick={this.handleOpenPublicPage}
            >
              <i className="external icon" />
              {'Open in new window'}
            </div>
          </Field>
        </Form>
        <Menu top attached tabular>
          {tabs}
        </Menu>
        <Segment bottom attached>
          {Object.prototype.hasOwnProperty.call(tag, 'js-ssl')
            && (
            <JsTag
              id={tag.placement_id}
              jsTag={this.state.ssl ? tag['js-ssl'] : tag.js}
              iframeUrl={tag['iframe-ssl']}
              loading={this.state.loading}
            />
            )
          }
          {Object.prototype.hasOwnProperty.call(tag, 'vast')
            && (
            <VastTag
              key={tag.ad_space_id}
              vastTag={tag['vast-ssl']}
            />
            )
          }
          {Object.prototype.hasOwnProperty.call(tag, 'image')
            && <ImageTag imageTag={tag.image} />
          }
        </Segment>
      </div>
    );
  }
}

export default TagManager;

TagManager.propTypes = {
  insertion: PropTypes.object,
};
