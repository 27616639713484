import PropTypes from 'prop-types';
import React from 'react';
// import Segment from 'weborama-ui-react/Segment';
import Item from 'weborama-ui-react/Item';
// import Filter from 'components/Filter';
import UIActions from 'actions/UIActions';
import AdSpaceWrapper from 'components/AdSpaceWrapper';
import './index.sass';
/**
 * Class InsertionList
 */
class AdSpaceWrapperSegment extends React.Component {
  /**
   * Handles edit insertion
   * @date   2016-11-08
   * @param  {Object}   insertion Insertion
   */
  static handleEditInsertion(insertion) {
    UIActions.editInsertion(insertion);
  }

  /**
   * Handles removing an insertion
   * @date   2016-11-09
   * @param  {Object}   insertion insertion
   */
  static handleRemoveInsertion(insertion) {
    UIActions.removeInsertion(insertion);
  }

  /**
   * constructor
   * @date   2016-11-04
   * @param  {object}   props properties
   */
  constructor(props) {
    super(props);

    this.state = {
      filter: {
        label: '',
        deliveryType: -1,
        adSpace: -1,
      },
      insertions: props.insertions || [],
      loading: props.loading,
    };
  }

  componentDidUpdate(props) {
    if (JSON.stringify(props.insertions) !== JSON.stringify(this.state.insertions)) {
      let { filter } = this.state;
      if (props.insertions.lenght === 0) {
        filter = { label: '', deliveryType: -1, adSpace: -1 };
      }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ filter, insertions: props.insertions });
    }
    if (JSON.stringify(props.loading) !== JSON.stringify(this.state.loading)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ loading: props.loading });
    }
  }

  /**
   * Renders insertionlist
   * @date   2016-11-04
   * @return {array}   nodes
   */
  render() {
    return (
      <span>
        {this.state.insertions.length > 0 && (
          <Item
            basic
            className="insertionlist-segment"
          >
            <AdSpaceWrapper
              adSpaces={this.props.adSpaces}
              insertions={this.state.insertions}
              creatives={this.props.assignedCreatives}
              loading={this.state.loading.assignedCreatives}
              tokens={this.props.tokens}
            />
            <div className="ui horizontal divider" />
          </Item>
        )}
        {this.state.insertions.length === 0 && (
          <h3 className="ui header">
            {'This campaign has no insertions!'}
          </h3>
        )}
      </span>
    );
  }
}

export default AdSpaceWrapperSegment;

AdSpaceWrapperSegment.propTypes = {
  insertions: PropTypes.array,
  adSpaces: PropTypes.array,
  assignedCreatives: PropTypes.array,
  loading: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
  tokens: PropTypes.object,
};
