import React from 'react';
import PropTypes from 'prop-types';
import Paginator from 'components/Paginator';
import Table from 'weborama-ui-react/Table';
import Filter from 'components/Filter';
import {UIActions} from 'actions';
import Menu from 'weborama-ui-react/Menu';
import Button, {Buttons} from 'weborama-ui-react/Button';
import {isEmptyObject} from 'constants/Utils';

class AdNetworkList extends React.Component {
  static applyFilter(array, filter) {
    const result = array.filter((item) => {
      if (item.label.toLowerCase().indexOf(filter) > -1 || item.id.indexOf(filter) > -1) {
        return item;
      }
      return false;
    });
    return result;
  }

  /**
   * constructor
   * @param {Object} props properties
   */
  constructor(props) {
    super(props);

    this.state = {
      adNetworks: props.adNetworks,
      filteredAdNetworks: props.adNetworks,
      selectedAdNetwork: {},
      maxListItems: 10,
      paginator: {
        page: 1,
      },
      filter: '',
    };

    this.handleSelectAdNetwork = this.handleSelectAdNetwork.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handlePropChange = this.handlePropChange.bind(this);
  }

  componentDidUpdate() {
    this.handlePropChange();
  }

  /**
   * Map props to state
   */
  handlePropChange() {
    if (JSON.stringify(this.props.adNetworks) !== JSON.stringify(this.state.adNetworks)) {
      this.setState({
        adNetworks: this.props.adNetworks,
        filteredAdNetworks: AdNetworkList.applyFilter(this.props.adNetworks, this.state.filter),
        selectedAdNetwork: isEmptyObject(this.state.selectedAdNetwork)
          ? this.props.adNetworks[0] : this.state.selectedAdNetwork,
      });
    }
  }

  /**
   * handles filter change
   * @param {Object} e Proxy event object
   */
  handleFilterChange(e) {
    const filter = e.target.value.toLowerCase();
    const filteredAdNetworks = AdNetworkList.applyFilter(this.props.adNetworks, filter);
    this.setState({filteredAdNetworks, filter, paginator: {page: 1}});
  }

  /**
   * ad network select
   * @param {Object} adNetwork ad network
   */
  handleSelectAdNetwork(adNetwork) {
    this.setState({selectedAdNetwork: adNetwork});
    this.props.onSelectAdNetwork(adNetwork);
  }

  /**
   * Renders ad network list
   */
  render() {
    /*
    calculate paginator settings
    */
    const paginatorSettings = {
      totalPages: Math.ceil(this.state.filteredAdNetworks.length / this.state.maxListItems),
      page: this.state.paginator.page,
    };

    const adNetworks = this.state.filteredAdNetworks.slice(
      (0 + (this.state.paginator.page - 1)) * this.state.maxListItems,
      (((0 + (this.state.paginator.page - 1)) * this.state.maxListItems) + this.state.maxListItems),
    );
    const adNetworkRows = adNetworks.map(adNetwork => (
      <tr
        key={adNetwork.id}
        onClick={() => { this.handleSelectAdNetwork(adNetwork); }}
        className={adNetwork.id === this.state.selectedAdNetwork.id ? 'active' : 'cursor-pointer'}
      >
        <td style={{paddingLeft: '1em', paddingRight: '1em'}} className="left aligned single line">
          {adNetwork.label}
          <Buttons icon right floated mini basic>
            <Button icon basic onClick={() => { UIActions.editAdNetwork({adNetwork}); }}>
              <i className="edit icon" />
            </Button>
            <Button icon basic onClick={() => { UIActions.removeAdNetwork({adNetwork}); }}>
              <i className="trash icon" />
            </Button>
          </Buttons>
        </td>
      </tr>
    ));
    return (
      <Table
        small
        very
        basic
        celled
        selectable
        attached
      >
        <thead className="full-width">
          <tr>
            <th>
              <Menu secondary>
                <div className="ui icon item cursor-pointer" onClick={() => { UIActions.addAdNetwork(); }}>
                  <i className="add icon" />
                </div>
                <Filter transparent onChange={this.handleFilterChange} />
              </Menu>
            </th>
          </tr>
        </thead>
        <tbody>
          {adNetworkRows}
        </tbody>
        <tfoot>
          <tr>
            <td>
              <Paginator
                totalPages={paginatorSettings.totalPages}
                page={paginatorSettings.page}
                onPaginationChange={(page) => { this.setState({paginator: {page}}); }}
              />
            </td>
          </tr>
        </tfoot>
      </Table>
    );
  }
}

export default AdNetworkList;

AdNetworkList.propTypes = {
  adNetworks: PropTypes.array,
  onSelectAdNetwork: PropTypes.func,
};
