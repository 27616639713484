import React, { useState, useEffect } from 'react';
import { AdNetwork } from 'actions';
import AppConstants from 'constants/AppConstants';
import { AdNetworkStore, ContextStore } from 'stores';
import AdSpaceForm from 'components/AdSpaceForm';
import Dimmer from 'weborama-ui-react/Dimmer';
import Loader from 'weborama-ui-react/Loader';

function SiteOfferManager() {

  const [componentState, setcomponentState] = useState({ adNetworks: [], loading: false, oldContextRouteParams: { accountId: undefined }});

  /**
   * Ad Network store change
   * @param {Object} data event object
   */
  function onAdNetworkStoreChange(data) {
    if (data.action === AppConstants.LIST_ADNETWORKS) {
      const readyAdNetworks = AdNetworkStore.getAdNetworks();
      readyAdNetworks.sort((a, b) => a.label.localeCompare(b.label));
      setcomponentState(currentState => ({ ...currentState, adNetworks: readyAdNetworks, loading: false }));
    }
  }

  // Runs at componentMount and componentWillUnmount
  useEffect(() => {
    AdNetworkStore.addChangeListener(onAdNetworkStoreChange);
    AdNetwork.list({ accountId: ContextStore.routingParams().accountId });
    setcomponentState(currentState => ({ ...currentState, loading: true }));
    return () => {
      AdNetworkStore.removeChangeListener(onAdNetworkStoreChange);
    };
  }, []);

  // Runs with every change
  useEffect(() => {
    if (ContextStore.routingParams().accountId !== undefined
      && ContextStore.routingParams().accountId !== componentState.oldContextRouteParams.accountId) {
      AdNetwork.list({ accountId: ContextStore.routingParams().accountId });
      setcomponentState(currentState => ({ ...currentState, loading: true, oldContextRouteParams: ContextStore.routingParams() }));
    }
  });

  const result = componentState.adNetworks.length > 0 ? <AdSpaceForm key={componentState.adNetworks.toString() + Date.now()} adNetworks={componentState.adNetworks} /> : null;
  return (
    <React.Fragment>
      <Dimmer inverted active={componentState.loading}>
        <Loader inverted indeterminate text>
          {'Loading...'}
        </Loader>
      </Dimmer>
      <div>
        {result}
      </div>
    </React.Fragment>
  );
}

export default SiteOfferManager;
