import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const ExpandCollapseButton = ((props) => {
  const className = classnames({
    minus: props.isExpanded && !props.loading,
    add: !props.isExpanded && !props.loading,
    notched: props.loading,
    loading: props.loading,
    circle: props.loading,
    square: !props.loading,
    icon: true,
  }, 'cursor-pointer');
  return (
    <i className={className} onClick={props.onClick} />
  );
});

export default ExpandCollapseButton;

ExpandCollapseButton.propTypes = {
  isExpanded: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};
