/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Form, { Field } from 'weborama-ui-react/Form';
import Checkbox from 'weborama-ui-react/Checkbox';
import Message from 'weborama-ui-react/Message';
import Header from 'weborama-ui-react/Header';
import List from 'weborama-ui-react/List';
import Item from 'weborama-ui-react/Item';
import Button from 'weborama-ui-react/Button';
import Menu from 'weborama-ui-react/Menu';
import Dropdown from 'weborama-ui-react/Dropdown';
import Icon from 'weborama-ui-react/Icon';
import Loader from 'weborama-ui-react/Loader';
import Dimmer from 'weborama-ui-react/Dimmer';
import { Grid, Row, Column } from 'weborama-ui-react/Grid';
import { AdElement } from 'actions';
import { getLocationDescriptor, getObjectInArray } from 'constants/Utils';
import { AdElementStore, SizeStore, ContextStore } from 'stores';
import AppConstants from 'constants/AppConstants';

function MoveAdPositionForm(props) {

  const [mode, setMode] = useState('createnew'); // is a string(moveexisting || createmultiple || createnew)
  const [selectedAdPositionId, setSelectedAdPositionId] = useState(null);
  const [leadingStatus, setloadingStatus] = useState(false);

  function onMultipleStoreChange(data) {

    if (data.action === AppConstants.UPDATE_MULTIPLE_ROLE_ADELEMENT) {
      ContextStore.router().push(getLocationDescriptor('adposition', data.adPositionId));
    }

  }

  useEffect(() => {
    AdElementStore.addChangeListener(onMultipleStoreChange);
    return () => {
      AdElementStore.removeChangeListener(onMultipleStoreChange);
    };
  }, []);

  /**
   * Returns a default selected ad position id
   */
  function calculateDefaultSelectedAdPositionId() {
    return props.adPositions.find((singleAdPosition) => {
      if (parseInt(singleAdPosition.id, 10) !== ContextStore.routingParams().adPositionId) {
        setSelectedAdPositionId(singleAdPosition.id);
        return singleAdPosition.id;
      }
    });
  }

  /**
   * Determine whether the submit button is disabled or not
   */
  function isSubmitButtonDisabled() {
    if (mode === 'createnew') {
      return false;
    }

    if (mode === 'moveexisting' && selectedAdPositionId) {
      return false;
    }

    if (mode === 'createmultiple' && props.adElementIds.length > 1) {
      return false;
    }

    return true;
  }

  const selectedAdElements = props.selectedAdElements.map(singleElement => {
    return (
      <Item key={singleElement.id + Date.now()}>
        {singleElement.safe_filename}
      </Item>
    );
  });

  const adPositionsItems = props.adPositions.map(singleAdPosition => {

    const size = getObjectInArray(SizeStore.getSizes(), 'id', singleAdPosition.size_id);
    const dimensions = size.label; // this value is the width x height value
    // const lbl = getSizeLabel(size.label);
    if (parseInt(singleAdPosition.id, 10) !== ContextStore.routingParams().adPositionId) {
      // We don't want current ad position in the moving list
      return (
        <Item value={singleAdPosition.id} key={singleAdPosition.id + dimensions}>
          {/* {`${singleAdPosition.id} - ${lbl} (${dimensions})`} */}
          {`${singleAdPosition.id} - (${dimensions})`}
        </Item>
      );
    }
  });

  function submitForm() {

    setloadingStatus(true);

    if (mode === 'createnew') {
      const payload = {
        accountId: ContextStore.routingParams().accountId,
        adElementIds: props.adElementIds,
        adElementRoleId: props.adElementRoleId, // Fixed position, different values in preprod and prod!
        creativeId: props.creativeId,
        creativeVersionId: props.creativeVersionId,
        originalAdPositionId: ContextStore.routingParams().adPositionId,
        adPositions: props.adPositions,
        selectedAdElements: props.selectedAdElements,
      };

      AdElement.newAdPosWithElements(payload);
    }

    if (mode === 'createmultiple') {

      const payload = {
        accountId: ContextStore.routingParams().accountId,
        adElementIds: props.adElementIds,
        adElementRoleId: props.adElementRoleId, // Fixed position, different values in preprod and prod!
        creativeId: props.creativeId,
        creativeVersionId: props.creativeVersionId,
        originalAdPositionId: ContextStore.routingParams().adPositionId,
      };

      AdElement.newMultipleAdPosWithElements(payload);
    }

    if (mode === 'moveexisting') {

      const payload = {
        accountId: ContextStore.routingParams().accountId,
        adElementIds: props.adElementIds,
        adElementRoleId: props.adElementRoleId,
        adPositionId: selectedAdPositionId,
        creativeVersionId: props.creativeVersionId,
        creativeId: props.creativeId,
        folderId: ContextStore.routingParams().folderId,
        originalAdPositionId: ContextStore.routingParams().adPositionId,
      };
      AdElement.updateMultipleAdElementRole(payload);
    }
  }

  /**
   * Set the loader text based on selected component mode
   */
  function loaderText() {

    if (mode === 'createnew') {
      return 'Creating a new ad position and moving selected ad elements...';
    }

    if (mode === 'createmultiple') {
      return 'Creating new ad positions and spreading selected ad elements...';
    }

    if (mode === 'moveexisting') {
      return 'Moving ad element(s) to the selected ad position...';
    }
    return 'loading';
  }

  return (
    <>
      {leadingStatus && (
        <Column twelve wide style={{ height: '100%' }}>
          <Column twelve wide>
            <Dimmer inverted active>
              <Loader text>
                {loaderText()}
              </Loader>
            </Dimmer>
          </Column>
        </Column>
      )}
      <Grid style={{ paddingBottom: '1rem' }}>
        <Column sixteen wide>
          <Row>
            <Message>
              <Header h5>
                Selected ad position elements
              </Header>
              <List bulleted>
                {selectedAdElements}
              </List>
            </Message>
          </Row>
        </Column>
      </Grid>
      <Form>
        <Field required label="I want to...">
          <div style={{ paddingTop: '0.2rem', paddingBottom: '0.2rem' }}>
            <Checkbox
              radio
              value="createnew"
              checked
              name="adposelementsradio"
              onChange={event => { setMode(event); }}
            >
              Create a new ad position and move selected ad elements to this new ad position
            </Checkbox>
          </div>
          <div style={{ paddingTop: '0.2rem', paddingBottom: '0.2rem' }}>
            <Checkbox
              radio
              value="createmultiple"
              name="adposelementsradio"
              disabled={props.adElementIds.length < 2}
              onChange={event => { setMode(event); }}
            >
              Create a new ad position for each selected ad element
            </Checkbox>
          </div>
          <div>
            <Checkbox
              radio
              value="moveexisting"
              name="adposelementsradio"
              onChange={event => setMode(event)}
              disabled={props.adPositions.length === 1}
            >
              Move selected adelement(s) to an existing ad position
            </Checkbox>
          </div>
        </Field>
        {mode === 'moveexisting' && (
          <Field required name="Select ad position">
            <Dropdown
              compact
              selection
              defaultValue={selectedAdPositionId || calculateDefaultSelectedAdPositionId()}
              onChange={(val) => { setSelectedAdPositionId(val); }}
            >
              <Icon dropdown />
              <Menu>
                {adPositionsItems}
              </Menu>
            </Dropdown>
          </Field>
        )}
        <Button
          primary
          onClick={() => submitForm()}
          disabled={isSubmitButtonDisabled()}
        >
          Submit changes
        </Button>
      </Form>
    </>
  );
}

export default MoveAdPositionForm;

MoveAdPositionForm.propTypes = {
  adElementIds: PropTypes.array,
  adElementRoleId: PropTypes.string,
  creativeId: PropTypes.string,
  creativeVersionId: PropTypes.string,
  adPositions: PropTypes.array,
  selectedAdElements: PropTypes.array,
};
