import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'weborama-ui-react/Icon';
import PushLiveButton from 'components/PushLiveButton';
import FavoriteButton from 'components/FavoriteButton';
import MoveAdElementsButton from 'components/MoveAdelementsButton';

function AdPositionButtons(
  { onPushLive, latestCreativeVersionId, liveCreativeVersionId, creative, adPositions, disabledMoveElements }
) {
  return (
    <>
      <span data-inverted="" data-tooltip="Preview creative" data-position="bottom center">
        <a
          className="active item"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'black' }}
          href={window.location.pathname.replace('cl', 'preview')}
        >
          <Icon eye />
        </a>
      </span>
      <PushLiveButton
        onPushLive={onPushLive}
        warning={latestCreativeVersionId > liveCreativeVersionId}
        success={latestCreativeVersionId === liveCreativeVersionId}
        disabled={liveCreativeVersionId === 0}
        toolTipPosition="bottom center"
      />
      <FavoriteButton isMenuItem toolTipPosition="bottom center" />
      <MoveAdElementsButton
        creative={creative}
        adPositions={adPositions}
        toolTipPosition="bottom center"
        disabledMoveElements={disabledMoveElements}
      />
    </>
  );
}

export default AdPositionButtons;

AdPositionButtons.propTypes = {
  creative: PropTypes.object,
  adPositions: PropTypes.array,
  onPushLive: PropTypes.func,
  latestCreativeVersionId: PropTypes.number,
  liveCreativeVersionId: PropTypes.number,
  disabledMoveElements: PropTypes.func,
};
