import BaseStore from 'stores/BaseStore';
import {Statistics} from 'constants/ServerConstants';
import AppConstants from 'constants/AppConstants';
import {register} from 'dispatchers/AppDispatcher';
/**
 * CampaignStore
 */
const StatisticStore = Object.assign({}, BaseStore, {
  statistics: [],
  monthlyStatistics: [],
  lastNDaysStatistics: [],
  req: {},
  /**
   * Returns list of campaigns;
   * @date   2016-03-14
   * @return {[type]}   [description]
   */
  getStatistics() {
    return this.statistics;
  },
  /**
   * Returns Montly statistics
   * @date   2016-03-17
   * @return {Array}   Monthly statistics
   */
  getMonthlyStatistics() {
    return this.monthlyStatistics;
  },
  /**
   * Returns statistics over the last 'n' days
   * @date   2016-04-01
   * @return {Array}   statistics
   */
  getLastNDaysStatistics() {
    return this.lastNDaysStatistics;
  },
  /**
   * fetches statistics by account Id
   * @date   2016-03-14
   * @param  {Number}   accountId id of account
   */
  fetchStatisticsByAccountId(accountId) {
    return accountId;
  },
  /**
   * Fetch campaign statistics
   * @param {Object} data data object
   */
  fetchCampaignStatistics(data) {
    if (typeof this.req.abort === 'function' && this.req.readyState !== 4) {
      this.req.abort();
    }
    const onSuccess = (response) => {
      if (Object.prototype.hasOwnProperty.call(response.data.data, 'project')
        && Object.prototype.hasOwnProperty.call(response.data.data.project, data.project_id)) {
        this.statistics = response.data.data.project[data.project_id].metrics;
        if (!Object.prototype.hasOwnProperty.call(this.statistics, 'click')) {
          this.statistics.click = 0;
        }
        if (!Object.prototype.hasOwnProperty.call(this.statistics, 'impression')) {
          this.statistics.impression = 0;
        }
      } else {
        this.statistics.click = 0;
        this.statistics.impression = 0;
      }
      this.emitChange({action: AppConstants.GET_STATISTICS});
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.GET_STATISTICS, message});
      // console.group('stats error');
      // console.dir(message);
      // console.groupEnd();
    };

    this.req = this.post({
      url: Statistics.GET,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Fetches statistics for account with a N number of days offset
   * @date   2016-04-01
   * @param  {Number}   accountId    Account Id
   * @param  {Number}   numberOfDays Days back until today
   */
  fetchStatisticsByLastNDays(accountId, numberOfDays) {
    const onSuccess = (data) => {
      const metrics = [];
      // for (let key in data.data.data.campaign) {
      //   let obj = Object.assign({'id': key}, data.data.data.campaign[key].metrics);
      //   metrics.push(obj);
      // }
      this.lastNDaysStatistics = metrics;
      this.emitChange({data});
    };

    const onError = (message) => {
      this.emitError({action: 'nDays', message});
    };

    this.post({
      url: Statistics.LASTNDAYS,
      data: {
        account_id: accountId,
        last_n_days: numberOfDays,
      },
      onSuccess,
      onError,
    });
  },
  /**
   * Fetches monthly statistics by account id
   * @date   2016-03-17
   * @param  {Number}   accountId Account Id
   */
  fetchMonthlyStatisticsByAccountId(accountId) {
    const onSuccess = (data) => {
      const metrics = [];
      // for (let key in data.data.data.campaign) {
      //   let obj = Object.assign({'id': key}, data.data.data.campaign[key].metrics);
      //   metrics.push(obj);
      // }
      this.monthlyStatistics = metrics;
      this.emitChange({data});
    };

    const onError = (message) => {
      this.emitError({action: 'fetch montly statistics', message});
    };

    this.post({
      url: Statistics.MONTHLY,
      data: {account_id: accountId},
      onSuccess,
      onError,
    });
  },

  listAvailableDimensions() {
    const onSuccess = (response) => {
      this.emitChange({action: AppConstants.LIST_AVAILABLE_DIMENSIONS, data: response});
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.LIST_AVAILABLE_DIMENSIONS, message});
    };

    this.post({
      url: Statistics.LIST_AVAILABLE_DIMENSIONS,
      onSuccess,
      onError,
    });
  },
  /**
   * Handle Store actions
   * @date   2016-02-11
   * @param  {Function}   function(action -
   */
  dispatcherIndex: register((action) => {
    switch (action.actionType) {
      case AppConstants.LIST_AVAILABLE_DIMENSIONS:
        StatisticStore.listAvailableDimensions();
        break;
      case AppConstants.GET_STATISTICS:
        StatisticStore.fetchCampaignStatistics({
          account_id: action.accountId,
          dimension_filters: {
            campaign: {
              id: action.campaignId,
            },
          },
          project_id: action.projectId,
          start_date: action.startDate,
          end_date: action.endDate,
        });
        break;
      //  no default
    }
    return true; // No errors. Needed by promise in Dispatcher.
  }),
});
export default StatisticStore;
