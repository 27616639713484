import BaseStore from 'stores/BaseStore';
import ServerConstants from 'constants/ServerConstants';
import AppConstants from 'constants/AppConstants';
import {register} from 'dispatchers/AppDispatcher';
import ToastrActions from 'actions/Toastr';

const FileStore = Object.assign({}, BaseStore, {
  /**
   * Upload files to wcm
   * @date   2016-06-09
   * @param  {number}   accountId         accountId
   * @param  {number}   folderId          folderId
   * @param  {number}   creativeId        creative id
   * @param  {number}   creativeTypeId    creative type id
   * @param  {number}   creativeVersionId creativeVersionId
   * @param  {number}   adPositionId      ad position id
   * @param  {object}   files             files
   */
  uploadFiles(accountId, folderId, creativeId, creativeTypeId, creativeVersionId, adPositionId, files) {
    const data = new window.FormData(); // eslint-disable no-undef
    data.append('account_id', accountId);
    data.append('folder_id', folderId);
    data.append('creative_id', creativeId);
    data.append('creative_type_id', creativeTypeId);
    data.append('creative_version_id', creativeVersionId);
    data.append('ad_position_id', adPositionId);

    files.forEach((item) => {
      data.append(item.name, item);
    });

    const onSuccess = (message) => {
      const filesOutput = JSON.parse(message.message);
      // show errors and file upload information in modal
      if (filesOutput.invalid.files.length) {
        this.emitError({action: AppConstants.UPLOAD_FILES, message});
      } else {
        this.emitChange({action: AppConstants.UPLOAD_FILES});
        if (files.length > 1) { // Note that what we're checking is NOT an array.
          ToastrActions.success({ title: 'Multiple file upload', body: 'File has been sucessfully uploaded' });
        } else {
          ToastrActions.success({ title: files[0].name, body: 'File has been sucessfully uploaded' });
        }
      }
    };
    const onError = (message) => {
      this.emitError({action: AppConstants.UPLOAD_FILES, message});
    };

    const xhr = () => {
      const xhrScoped = new window.XMLHttpRequest();
      xhrScoped.upload.addEventListener('progress', (evt) => {
        if (evt.lengthComputable) {
          let percentComplete = evt.loaded / evt.total;
          percentComplete = parseInt(percentComplete * 100, 10);
          this.emitChange({action: AppConstants.FILE_UPLOAD_PROGRESS, progress: percentComplete});
        }
      }, false);
      return xhrScoped;
    };

    this.post({
      url: ServerConstants.CREATIVE_LIBRARY.FILES.UPLOAD,
      data,
      processData: false,
      contentType: false,
      onSuccess,
      onError,
      xhr,
    });
  },

  dispatcherIndex: register((action) => {
    switch (action.actionType) {
      case AppConstants.UPLOAD_FILES:
        FileStore.uploadFiles(
          action.accountId,
          action.folderId,
          action.creativeId,
          action.creativeTypeId,
          action.creativeVersionId,
          action.adPositionId,
          action.files,
        );
        break;
      // no default
    }
    return true; // No errors. Needed by promise in Dispatcher.
  }),
});

export default FileStore;
