import {dispatch} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const LandingUrl = {
  /**
   * Dispatches an action to fetch a list landing urls
   *
   * @param {object} data data object
   */
  list(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.LIST_LANDING_URL,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to add a landing url
   * @param {Object} data data object
   */
  add(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.ADD_LANDING_URL,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to add multiple landing urls
   * @param {Object} data data object
   */
  addMulti(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.ADD_MULTI_LANDING_URL,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to update landing url
   * @param {Object} data data object
   */
  update(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.UPDATE_LANDING_URL,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to remove a landing url
   * @param {Object} data data object
   */
  remove(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.REMOVE_LANDING_URL,
    }, data);

    dispatch(obj);
  },
};

export default LandingUrl;
