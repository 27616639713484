import React from 'react';
import AppConstants from 'constants/AppConstants';
import {Toastr} from 'actions';
import {
  FolderStore,
  CreativeStore,
  InsertionStore,
  AdPositionStore,
  AdElementStore,
  FileStore,
  FavoriteStore,
  ProjectStore,
  CampaignStore,
  AssignStore,
} from 'stores';
/**
 * Error Controler
 */
class ErrorController extends React.Component {
  static onCampaignStoreError(data) {
    if (data.action === AppConstants.UPDATE_CAMPAIGN) {
      const message = {title: 'ERROR: CAMPAIGN UPDATE', body: data.message};
      Toastr.error(message);
    }
  }

  static onAssignStoreError(data) {
    if (data.action === AppConstants.UNASSIGN_CREATIVE) {
      const message = {title: 'ERROR: UN ASSIGN CREATIVE', body: data.message};
      Toastr.error(message);
    }
    if (data.action === AppConstants.SET_DEFAULT_CREATIVE) {
      const message = {title: 'ERROR: SETTING DEFAULT CREATIVE', body: data.message};
      Toastr.error(message);
    }
  }

  /**
   * Project store error listener
   *
   * @param {Object} data event object
   *
   * @memberOf ErrorController
   */
  static onProjectStoreError(data) {
    if (data.action === AppConstants.REMOVE_PROJECT) {
      const message = {title: 'ERROR: REMOVING PROJECT', body: data.message};
      Toastr.error(message);
    }
  }

  /**
   * Creative store error listener
   * @date   2017-01-13
   * @param  {Object}   data Event object
   */
  static onCreativeStoreError(data) {
    let message = {title: '', body: ''};
    if (data.action === AppConstants.DUPLICATE_CREATIVE) {
      message = {title: 'Error duplicating creative', body: data.message};
      Toastr.error(message);
    }
    if (data.action === AppConstants.PUSH_LIVE) {
      message = {title: 'Error push live', body: data.message};
      Toastr.error(message);
    }
    if (data.action === AppConstants.PUSH_CREATIVES_LIVE) {
      message = {title: 'Error push live', body: data.message};
      Toastr.error(message);
    }
  }

  /**
   * ad postion store error listener
   * @date   2017-01-13
   * @param  {Object}   data event object
   */
  static onAdPositionStoreError(data) {
    const message = {title: '', body: ''};
    if (data.action === AppConstants.ADD_ADPOSITION) {
      message.title = 'ERROR CREATING AD POSITION';
      message.body = data.message;
      Toastr.error(message);
    }
    if (data.action === AppConstants.REMOVE_ADPOSITION) {
      message.title = 'ERROR REMOVING AD POSITION';
      message.body = data.message;
      Toastr.error(message);
    }
  }

  /**
   * Ad Element store error
   * @date   2017-01-13
   * @param  {Object}   data error event
   */
  static onAdElementStoreError(data) {
    if (data.action === AppConstants.UPDATE_ADELEMENT_ROLE) {
      const message = {title: 'ERROR: UPDATING FILE ROLE', body: data.message};
      Toastr.error(message);
    }
  }

  static onFolderStoreError(data) {
    if (data.action === AppConstants.REMOVE_FOLDER) {
      const message = {title: 'ERROR: REMOVING FOLDER', body: data.message};
      Toastr.error(message);
    }
  }

  /**
   * File store error handler
   * @date   2017-01-31
   * @param  {Object}   data error event
   */
  static onFileStoreError() {
  // static onFileStoreError(data) {
    // if (data.action === AppConstants.UPLOAD_FILES) {
    //   const message = {title: 'ERROR: UPLOADING FILE', body: data.message || 'Could not format error message'};
    //   Toastr.error(message);
    // }
  }

  /**
   * Insertion store error listener
   * @date   2017-01-13
   * @param  {Object}   data event object
   */
  static onInsertionStoreError(data) {
    const message = {title: '', body: ''};
    if (data.action === AppConstants.LIST_INSERTIONS || data.action === AppConstants.BROWSE_INSERTIONS) {
      message.title = 'ERROR FETCHING LIST OF INSERTIONS';
      message.body = data.message;
    } else if (data.action === AppConstants.REMOVE_INSERTION) {
      message.title = 'ERROR REMOVING INSERTION';
      message.body = data.message;
    } else if (data.action === AppConstants.ADD_INSERTION) {
      message.title = 'ERROR CREATING INSERTION';
      message.body = data.message;
    } else if (data.action === AppConstants.UPDATE_INSERTION) {
      message.title = 'ERROR UPDATING INSERTION';
      message.body = data.message;
    } else if (data.action === AppConstants.DUPLICATE_INSERTION) {
      message.title = 'ERROR DUPLICATING INSERTION';
      message.body = data.message;
    }
    Toastr.error(message);
  }

  /**
   * On favorite store error
   * @date   2017-01-20
   * @param  {Object}   data data object
   */
  static onFavoriteStoreError(data) {
    const message = {title: '', body: ''};
    if (data.action === AppConstants.ADD_FAVORITE) {
      message.title = 'ERROR CREATING FAVORITE';
      message.body = data.message;
      Toastr.error(message);
    }
  }

  /**
   * Register error listeners
   */
  componentDidMount() {
    CreativeStore.addErrorListener(ErrorController.onCreativeStoreError);
    InsertionStore.addErrorListener(ErrorController.onInsertionStoreError);
    AdPositionStore.addErrorListener(ErrorController.onAdPositionStoreError);
    AdElementStore.addErrorListener(ErrorController.onAdElementStoreError);
    FavoriteStore.addErrorListener(ErrorController.onFavoriteStoreError);
    FileStore.addErrorListener(ErrorController.onFileStoreError);
    ProjectStore.addErrorListener(ErrorController.onProjectStoreError);
    AssignStore.addErrorListener(ErrorController.onAssignStoreError);
    CampaignStore.addErrorListener(ErrorController.onCampaignStoreError);
    FolderStore.addErrorListener(ErrorController.onFolderStoreError);
  }

  /**
   * Unregister error listeners
   * @date   2017-01-13
   */
  componentWillUnmount() {
    CreativeStore.removeErrorListener(ErrorController.onCreativeStoreError);
    InsertionStore.removeErrorListener(ErrorController.onInsertionStoreError);
    AdPositionStore.removeErrorListener(ErrorController.onAdPositionStoreError);
    AdElementStore.removeErrorListener(ErrorController.onAdElementStoreError);
    FavoriteStore.removeErrorListener(ErrorController.onFavoriteStoreError);
    FileStore.removeErrorListener(ErrorController.onFileStoreError);
    ProjectStore.removeErrorListener(ErrorController.onProjectStoreError);
    AssignStore.removeErrorListener(ErrorController.onAssignStoreError);
    CampaignStore.removeErrorListener(ErrorController.onCampaignStoreError);
    FolderStore.removeErrorListener(ErrorController.onFolderStoreError);
  }

  /**
   * returns null
   * @date   2017-01-13
   * @return {array}   nodes
   */
  render() {
    return (
      null
    );
  }
}

export default ErrorController;
