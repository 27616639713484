import React from 'react';
import PropTypes from 'prop-types';
import {UIActions} from 'actions';
import Dropdown from 'weborama-ui-react/Dropdown';
import Item from 'weborama-ui-react/Item';
import Menu from 'weborama-ui-react/Menu';

const TrackerRow = (props => (
  <tr className={props.tracker.is_active === 'NO' ? 'warning' : ''}>
    <td style={props.style} className="right aligned collapsing">
      <Dropdown floating labeled menu isMenu>
        <span className="cursor-pointer">{props.tracker.rule}</span>
        <i className="dropdown icon" />
        <Menu>
          <Item onClick={() => { UIActions.editTracker({tracker: props.tracker}); }}>
            <i className="edit icon" />
            {'Edit tracker'}
          </Item>
          <Item onClick={() => { props.onRemoveTracker(props.tracker); }}>
            <i className="trash icon" />
            {'Remove tracker'}
          </Item>
        </Menu>
      </Dropdown>
    </td>
    <td className="collapsing">{props.tracker.label}</td>
    <td style={props.codeStyle}>{props.tracker.code}</td>
  </tr>
));

export default TrackerRow;

TrackerRow.propTypes = {
  tracker: PropTypes.object,
  onRemoveTracker: PropTypes.func,
  // loading: PropTypes.bool,
  style: PropTypes.object,
  codeStyle: PropTypes.object,
};
