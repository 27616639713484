import PropTypes from 'prop-types';
import React from 'react';
import Accordion, {Title, Content} from 'weborama-ui-react/Accordion';
import { CountryStore } from 'stores';
import {getObjectInArray} from 'constants/Utils';
import Segment from 'weborama-ui-react/Segment';
import Flag from 'weborama-ui-react/Flag';
import Icon from 'weborama-ui-react/Icon';

const AdSpaceAccordion = ((props) => {
  console.log(props);
  const country = getObjectInArray(CountryStore.getCountries(), 'id', props.adSpace.ad_space_fields.country_id);
  const flagCode = country && {[country.code2.toLowerCase()]: true};
  const icon = (!country) ? <Icon world /> : <Flag {...flagCode} />;
  return (
    <Accordion fluid>
      <Title active>
        {icon}
        {`${props.adSpace.ad_network_fields.label} - ${props.adSpace.ad_space_fields.label}`}
      </Title>
      <Content active>
        <Segment basic>
          {props.children}
        </Segment>
      </Content>
    </Accordion>
  );
});

export default AdSpaceAccordion;

AdSpaceAccordion.propTypes = {
  adSpace: PropTypes.object,
  children: PropTypes.any,
};
