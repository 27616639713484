import React from 'react';
import {AccountStore, ContextStore, CountryStore} from 'stores';
import { Link } from 'react-router';
// import Modal from 'weborama-ui-react/Modal';
// import SettingsButton from 'components/SettingsButton';
import { getObjectInArray } from 'constants/Utils';
import Segment from 'weborama-ui-react/Segment';
import Header from 'weborama-ui-react/Header';
import { Account as AccountActions } from 'actions';
import Flag from 'weborama-ui-react/Flag';
import Divider from 'weborama-ui-react/Divider';
import Icon from 'weborama-ui-react/Icon';

/**
 * Class account
 */
class Account extends React.Component {
  /**
   * Constructor
   * @date   2016-09-27
   */
  constructor() {
    super();

    this.state = {
      account: {},
      loading: true,
    };
    this.onAccountStoreChange = this.onAccountStoreChange.bind(this);
    this.handleClearCache = this.handleClearCache.bind(this);
    this.getAccountData = this.getAccountData.bind(this);

    AccountStore.addChangeListener(this.onAccountStoreChange);
  }

  componentDidMount() {
    this.getAccountData();
  }

  componentDidUpdate() {
    this.getAccountData();
  }

  getAccountData() {
    if (ContextStore.routingParams() && JSON.stringify(this.state.account) === '{}') {
      AccountActions.get(ContextStore.routingParams().accountId); // This is to prepare for handleClearCache function later
    }
  }

  /**
   * Unregister store callback
   * @date   2016-09-27
   */
  componentWillUnmount() {
    AccountStore.removeChangeListener(this.onAccountStoreChange);
  }

  /**
   * Store change callback
   * @date   2016-09-27
   */
  onAccountStoreChange(data) {
    const { account } = data;
    this.setState({ account, loading: false });
  }

  /**
 * Handles clearing the cache
 * @date   15-03-2019
 */
  handleClearCache() {
    AccountActions.clearCache(ContextStore.routingParams().accountId);
  }

  /**
   * Render account
   * @date   2016-09-27
   * @return {array}   Nodes
   */
  render() {

    const countryCode = getObjectInArray(CountryStore.getCountries(), 'id', this.state.account.country_id);
    const flag = countryCode && { [countryCode.code2.toLowerCase()]: true };
    return (
      <Segment loading={this.state.loading}>
        <Header h2>
          Account
        </Header>
        <Icon popup className="redo link" onClick={this.handleClearCache} popupContent="Clear cache" />
        <Flag {...flag} />
        {this.state.account.id} - {this.state.account.label}
        <Divider />
        <Link to="/">
          <Icon random />
          Change account
        </Link>
      </Segment>
    );
  }
}

export default Account;
