import {dispatch} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const ThirdPartyTag = {
  /**
   * Dispatches an action to fetch a list of third party yags
   *
   * @param {object} data data object
   */
  list(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.LIST_THIRD_PARTY_TAGS,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to add a third party tracker
   * @param {Object} data data object
   */
  add(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.ADD_THIRD_PARTY_TAG,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to update a third party tag
   * @param {Object} data data object
   */
  update(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.UPDATE_THIRD_PARTY_TAG,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to remove an third party tag
   * @param {Object} data data object
   */
  remove(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.REMOVE_THIRD_PARTY_TAG,
    }, data);

    dispatch(obj);
  },
  /**
   * Searches third party tags
   * @param {Object} data data object
   */
  search(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.SEARCH_THIRD_PARTY_TAG,
    }, data);

    dispatch(obj);
  },
};

export default ThirdPartyTag;
