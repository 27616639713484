import PropTypes from "prop-types";
import React from "react";
import Dropzone from "react-dropzone";
import AppConstants from "constants/AppConstants";
import { Creative, AdElement, AdPosition } from "actions";
import { FileStore, ContextStore } from "stores";
import Progress from "weborama-ui-react/Progress";
import Message from "weborama-ui-react/Message";
import Item from "weborama-ui-react/Item";
import FileList from "components/FileList";
import { getObjectInArray } from "constants/Utils";
import { Grid, Column } from "weborama-ui-react/Grid";
import Modal from "weborama-ui-react/Modal";
import Button from "weborama-ui-react/Button";
import Divider from "weborama-ui-react/Divider";
import Accordion, { Title, Content } from "weborama-ui-react/Accordion";
import JSZip from "jszip";
import UploadChecker from "./UploadChecker";
import ToastrActions from "actions/Toastr";
import "./index.scss";

/**
 * Class FileManager
 */
class FileManager extends React.Component {
  /**
   * Constructor
   * @date   2017-02-03
   * @param  {object}   props Properties
   */
  constructor() {
    super();

    this.state = {
      files: [],
      fileTypes: [],
      upload: {
        label: "Uploading files",
        progress: -1,
      },
      filesUploaded: 0,
      // arrays of upload results
      errors: { total: 0, files: [] },
      completed: { total: 0, files: [] },
      updated: { total: 0, files: [] },
      skipped: { total: 0, files: [] },
    };

    this.onFileStoreChange = this.onFileStoreChange.bind(this);
    this.onFileStoreError = this.onFileStoreError.bind(this);
    this.handleFileUploadDialog = this.handleFileUploadDialog.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.onFileModalShow = this.onFileModalShow.bind(this);

    FileStore.addChangeListener(this.onFileStoreChange);
    FileStore.addErrorListener(this.onFileStoreError);

    this.fileUploadModalRef = React.createRef();
    this.progressBarRef = React.createRef();
    this.dropzoneRef = React.createRef();
  }

  /**
   * Show file upload modal
   * @param {Object} props previous properties
   * @param {Object} state previous state
   */
  componentDidUpdate(props, state) {
    if (
      this.state.files.length > 0 &&
      JSON.stringify(this.state.files) !== JSON.stringify(state.files)
    ) {
      this.fileUploadModalRef.current.show({ onVisible: this.onFileModalShow });
    }
  }

  /**
   * Unregister store callbacks
   * @date   2017-02-03
   */
  componentWillUnmount() {
    FileStore.removeChangeListener(this.onFileStoreChange);
    FileStore.removeErrorListener(this.onFileStoreError);
  }

  /**
   * Whens showing the modal, upload the files
   */
  onFileModalShow() {
    this.handleUpload();
  }

  /**
   * On file store change, fetch new adposition details.
   * @date   2016-06-09
   * @param  {object}   obj Event object
   */
  onFileStoreChange(obj) {
    if (obj.action === AppConstants.UPLOAD_FILES) {
      this.fileUploadModalRef.current.hide();
      this.progressBarRef.current.reset();
      Creative.list({
        accountId: ContextStore.routingParams().accountId,
        folderId: ContextStore.routingParams().folderId,
      });
      this.setState({
        upload: { label: "Uploading files", progress: 0 },
        files: [],
        fileTypes: [],
      });
    } else if (obj.action === AppConstants.FILE_UPLOAD_PROGRESS) {
      const label = "Uploading Files";
      this.progressBarRef.current.increment(
        parseInt(obj.progress, 10) - parseInt(this.state.upload.progress, 10)
      );
      this.setState({ upload: { label, progress: obj.progress } });
    }
  }

  /**
   * File store error callback
   * @date   2017-01-31
   * @param  {Object}   data event object
   */
  onFileStoreError(data) {
    if (data.action === AppConstants.UPLOAD_FILES) {
      AdPosition.details({
        accountId: ContextStore.routingParams().accountId,
        folderId: ContextStore.routingParams().folderId,
        creativeId: ContextStore.routingParams().creativeId,
        creative_version_id: this.props.creative.latest_creative_version_id,
        adPositionId: ContextStore.routingParams().adPositionId,
      });
      const message = data.message.message;
      const { total_files, completed, updated, invalid, skipped } = message;

      this.setState({
        filesUploaded: total_files,
        errors: invalid,
        updated: updated,
        completed: completed,
        skipped: skipped,
        upload: { label: "Uploaded files", progress: -1 },
      });
    }
  }

  /**
   * Check uploaded files for non-accepted characters by WCM
   * @param {JSZip} zipFile zip file, zipped by JSZip
   * @param {Object} files file object containing uploaded files
   */
  checkInvalidChars(zipFile, files) {
    const invalidFileNames = [];
    const invalidCharacters = new RegExp(/–|—/, "gi");

    if (zipFile && zipFile.files) {
      // Zip file (JSZip)
      for (const singleFile in zipFile.files) {
        // eslint-disable-next-line no-prototype-builtins
        if (zipFile.files.hasOwnProperty(singleFile)) {
          if (invalidCharacters.test(singleFile)) {
            invalidFileNames.push(singleFile);
          }
        }
      }
    }

    if (files) {
      // Unzipped files
      for (const singleFile in files) {
        // eslint-disable-next-line no-prototype-builtins
        if (files.hasOwnProperty(singleFile)) {
          if (invalidCharacters.test(files[singleFile].name)) {
            invalidFileNames.push(files[singleFile].name);
          }
        }
      }
    }

    if (invalidFileNames.length > 0) {
      ToastrActions.error({
        title: "Invalid character error(s)",
        body: invalidFileNames.join(", "),
      });
      return false;
    }

    return true;
  }

  /**
   * Send files to component state, ready to be uploaded
   * @param {Object | JSZip} files JSZip or object
   */
  sendFilesToState(files) {
    if (files.length > 0 /* && this.checkInvalidChars(zipFile) */) {
      const fileTypes = [];
      files.forEach((item) => {
        const file = {
          name: item.name,
          size: item.size,
          type: item.type,
        };
        const fileType = getObjectInArray(fileTypes, "type", item.type);
        if (!fileType) {
          const tmp = {
            type: item.type,
            files: [file],
          };
          fileTypes.push(tmp);
        } else {
          fileType.files.push(file);
        }
      });
      this.setState({ files, fileTypes });
    }
  }

  /**
   * handles file uploadFiles
   * @param  {Object} files Fules
   */
  handleFileUploadDialog(files) {
    if (files[0].type === "application/x-zip-compressed") {
      // Upload is zip file
      const zip = new JSZip();
      zip
        .loadAsync(files[0] /* = file blob */)
        .then((zip2) => {
          // We're assigning to a variable to prevent a weird error
          // where checkInvalidChars is called twice when it's placed in an if statement
          const noInvalidChars = this.checkInvalidChars(zip2, null);
          if (noInvalidChars) {
            this.sendFilesToState(files);
          }
          return null; // Change later, this is related to the QA App stuff
          // return zip2.file('index.html').async('string');
        })
        .then((text) => {
          if (text !== null && text !== undefined) {
            this.props.uploadValidator(UploadChecker(text));
          }
        });
    } else {
      // Upload is object
      const noInvalidChars = this.checkInvalidChars(null, files);
      if (noInvalidChars) {
        this.sendFilesToState(files);
      }
    }
  }

  /**
   * handles opening dropzone component
   * @date   2016-09-12
   */
  openDropzone() {
    this.dropzoneRef.current.open();
  }

  /**
   * Handles file upload
   */
  handleUpload() {
    AdElement.upload({
      accountId: ContextStore.routingParams().accountId,
      folderId: ContextStore.routingParams().folderId,
      creativeId: this.props.creative.id,
      creativeTypeId: this.props.creativeType.id,
      creativeVersionId: this.props.adPosition.creative_version_id,
      adPositionId: this.props.adPosition.id,
      files: this.state.files,
    });
    this.setState({
      errors: { total: 0, files: [] },
      completed: { total: 0, files: [] },
      updated: { total: 0, files: [] },
      skipped: { total: 0, files: [] },
    });
  }

  /**
   * Render file manager
   * @date   2017-02-03
   *
   * @return {array}   nodes
   */
  render() {
    const sortedFiles = this.state.fileTypes.sort(
      (a, b) => a.files.length - b.files.length
    );

    const fileList = sortedFiles.map((fileType) => (
      <Column four wide key={fileType.type}>
        <FileList fileType={fileType} />
      </Column>
    ));
    return (
      <span>
        {this.props.adPosition.lock_script === "YES" && (
          <Message header="This ad position is in RAW mode" warning>
            <p>All changes to files will not be updated in the setup script</p>
          </Message>
        )}
        <Dropzone
          ref={this.dropzoneRef}
          noClick
          className="dropZone"
          onDrop={this.handleFileUploadDialog}
          onDragEnter={(event) => {
            const dt = event.dataTransfer;
            if (
              !(
                (dt.types &&
                  (dt.types.indexOf
                    ? dt.types.indexOf("Files") !== -1
                    : dt.types.contains("Files"))) ||
                dt.mozSourceNode === null
              )
            ) {
              // this.setState({isDragActive: false});
            }
          }}
          onDragLeave={() => {
            // this.setState({isDragActive: true});
          }}
        >
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div className="dropZone-outline-adjust" {...getRootProps()}>
              <input {...getInputProps()} />
              {isDragActive && (
                <div style={{ minheight: "280px" }} className="dropZone-active">
                  <div className="active-dropzone-overlay">
                    <h2 className="ui icon header">
                      <i className="cloud upload icon" />
                      <div className="content">{"Drop your files here!"}</div>
                    </h2>
                  </div>
                </div>
              )}
              {this.props.children}
            </div>
          )}
        </Dropzone>
        <Modal
          ref={this.fileUploadModalRef}
          header="Uploading the following files:"
          progressBar={
            <Progress indicating top attached ref={this.progressBarRef} />
          }
        >
          { this.state.filesUploaded ? <> <p
            style={{ fontSize: "17px", fontWeight: 700, marginBottom: 30 }}
          >{`Uploaded  ${this.state.filesUploaded} files`}</p>

          <Grid>
            <Accordion className="accordion-fileupload" menu>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Title>{`Completed: ${this.state.completed.files.length}`}</Title>
                <Content>
                  {this.state.completed.files?.map((completed) => (
                    <Item
                      style={{ fontSize: "0.9em" }}
                      key={`completed-${completed}`}
                    >
                      {"- " + completed}
                    </Item>
                  ))}
                </Content>
              </div>
            </Accordion>

            <Accordion menu className="accordion-fileupload-error">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Title>{`Errors: ${this.state.errors.total}`}</Title>
                <Content active>
                  {this.state.errors?.files?.map((error) => (
                    <Item
                      style={{ fontSize: "0.9em" }}
                      key={`${error.filename}: ${error.message}`}
                    >
                      {`- ${error.filename}: ${error.message}`}
                    </Item>
                  ))}
                </Content>
              </div>
            </Accordion>

            {this.state.skipped.files.length > 0 && (
              <Accordion menu className="accordion-fileupload">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Title>{`Skipped: ${this.state.skipped.total}`}</Title>
                  <Content>
                    {this.state.skipped.files?.map((skipped) => (
                      <Item
                        style={{ fontSize: "0.9em" }}
                        key={`${skipped.filename}: ${skipped.message}`}
                      >
                        {`- ${skipped.filename}: ${skipped.message}`}
                      </Item>
                    ))}
                  </Content>
                </div>
              </Accordion>
            )}

            {this.state.updated.files.length > 0 && (
              <Accordion menu className="accordion-fileupload">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Title>{`Updated: ${this.state.updated.total}`}</Title>
                  <Content>
                    {this.state.updated.files.map((update) => (
                      <Item
                        style={{ fontSize: "0.9em" }}
                        key={`updated-${update}`}
                      >
                        {"- " + update}
                      </Item>
                    ))}
                  </Content>
                </div>
              </Accordion>
            )}
          </Grid>
          </> : <Grid>
            {fileList}
          </Grid>
          }   
          <Divider hidden />
          <Button
            primary
            fluid
            negative={this.state.errors.total > 0}
            onClick={this.handleUpload}
            disabled={!this.state.errors.total}
          >
            {this.state.errors.total > 0 ? "Retry" : `${this.state.upload.progress}%`}
          </Button>
        </Modal>
      </span>
    );
  }
}

export default FileManager;

FileManager.propTypes = {
  children: PropTypes.any,
  adPosition: PropTypes.object,
  creative: PropTypes.object,
  creativeType: PropTypes.object,
  uploadValidator: PropTypes.func,
};
