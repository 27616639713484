/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import PropTypes from 'prop-types';
import Table from 'weborama-ui-react/Table';
import {ThirdPartyTagStore, CampaignStore, ContextStore, CountryStore} from 'stores';
import {ThirdPartyTag, UIActions, Insertion} from 'actions';
import AppConstants from 'constants/AppConstants';
import CampaignTrackerRow from 'components/CampaignTrackerRow';
import {getObjectInArray, isEmptyObject} from 'constants/Utils';
import ExpandCollapseButton from 'components/ExpandCollapseButton';
import TrackerRow from 'components/TrackerRow';
import {Grid, Column} from 'weborama-ui-react/Grid';
import Button from 'weborama-ui-react/Button';
import Icon from 'weborama-ui-react/Icon';
import Loader from 'weborama-ui-react/Loader';
import Dimmer from 'weborama-ui-react/Dimmer';
import Message from 'weborama-ui-react/Message';

class TrackerManager extends React.Component {
  static getAllAssignedAdPositionIds(details) {
    const apIds = [];
    details.forEach((detail) => {
      detail.tracking_elements.forEach((te) => {
        te.assigned_ad_positions.forEach((ap) => {
          apIds.push(ap.id);
        });
      });
    });
    return apIds;
  }

  /**
   * Constructor
   * @param {Object} props properties
   */
  constructor(props) {
    super();
    this.state = {
      loading: true,
      insertions: props.insertions,
      adSpaces: props.adSpaces,
      campaign: props.campaign,
      campaignTrackers: [],
      adSpaceTrackers: [],
      insertionTrackers: [],
      assignedAdPositionTrackers: [],
      campaignError: {},
      trackerLoading: {},
      expandedInsertions: [],
      selectedRow: {subject: 'C', itemId: props.campaign.id},
      currentInsertionsDetails: [],
    };
    this.onCampaignStoreChange = this.onCampaignStoreChange.bind(this);
    this.onCampaignStoreError = this.onCampaignStoreError.bind(this);
    this.onThirdPartyTagStoreChange = this.onThirdPartyTagStoreChange.bind(this);
    this.handleExpandCollapseInsertion = this.handleExpandCollapseInsertion.bind(this);
    this.handleRemoveTracker = this.handleRemoveTracker.bind(this);
    this.handleAddTracker = this.handleAddTracker.bind(this);

    ThirdPartyTagStore.addChangeListener(this.onThirdPartyTagStoreChange);
    CampaignStore.addChangeListener(this.onCampaignStoreChange);
    CampaignStore.addErrorListener(this.onCampaignStoreError);
  }

  /**
   * Fetch trackers
   */
  componentDidMount() {
    this.searchThirdPartyTags();
  }

  componentDidUpdate() {
    if (JSON.stringify(this.props.insertions) !== JSON.stringify(this.state.insertions)) {
      this.setState({insertions: this.props.insertions});
    }
    if (JSON.stringify(this.props.campaign) !== JSON.stringify(this.state.campaign)) {
      this.setState({campaign: this.props.campaign});
    }
    if (JSON.stringify(this.props.adSpaces) !== JSON.stringify(this.state.adSpaces)) {
      this.setState({adSpaces: this.props.adSpaces});
    }
    if (JSON.stringify(this.props.insertionsDetails) !== JSON.stringify(this.state.currentInsertionsDetails)) {
      this.setState({ currentInsertionsDetails: this.props.insertionsDetails }, () => {
        this.searchThirdPartyTags(this.props.insertionsDetails);
      });
    }
  }

  /**
   * Unregister store callback
   */
  componentWillUnmount() {
    ThirdPartyTagStore.removeChangeListener(this.onThirdPartyTagStoreChange);
    CampaignStore.removeChangeListener(this.onCampaignStoreChange);
    CampaignStore.removeErrorListener(this.onCampaignStoreError);
  }

  /**
   * third party tag store callback
   * @param {Object} data data object
   */
  onThirdPartyTagStoreChange(data) {
    if (data.action === AppConstants.ADD_THIRD_PARTY_TAG
        || data.action === AppConstants.REMOVE_THIRD_PARTY_TAG
        || data.action === AppConstants.UPDATE_THIRD_PARTY_TAG) {
      // const campaignAdSpaceIds = this.state.adSpaces.map(adSpace => adSpace.campaigns_ad_space_fields.id);
      // const insertionIds = this.state.insertions.map(insertion => insertion.id);

      // ThirdPartyTag.search({
      //   accountId: ContextStore.routingParams().accountId,
      //   campaignId: ContextStore.routingParams().campaignId,
      //   campaignAdSpaceIds,
      //   insertionIds,
      // });
      this.searchThirdPartyTags();
      this.setState({loading: true, trackerLoading: {}});
    }
    if (data.action === AppConstants.SEARCH_THIRD_PARTY_TAG) {
      const thirdPartyTags = ThirdPartyTagStore.getTags();
      const adSpaceTrackers = [];
      const insertionTrackers = [];
      const assignedAdPositionTrackers = [];
      const campaignTrackers = thirdPartyTags.filter((tag) => {
        if (parseInt(tag.campaign_id, 10) > 0) {
          return tag;
        }
        if (parseInt(tag.campaigns_ad_space_id, 10) > 0) {
          adSpaceTrackers.push(tag);
          return false;
        }
        if (parseInt(tag.insertion_id, 10) > 0) {
          insertionTrackers.push(tag);
          return false;
        }
        if (parseInt(tag.assigned_ad_position_id, 10) > 0) {
          assignedAdPositionTrackers.push(tag);
        }
        return false;
      });
      this.setState({
        loading: false,
        campaignTrackers,
        adSpaceTrackers,
        insertionTrackers,
        assignedAdPositionTrackers,
      });
    }
  }

  /**
   * Campaign store callback
   * @param {Object} data data object
   */
  onCampaignStoreChange(data) {
    if (data.action === AppConstants.LIST_THIRD_PARTY_TAGS_FOR_CAMPAIGN) {
      const thirdPartyTags = CampaignStore.getThirdPartyTags();
      this.setState({campaignTrackers: thirdPartyTags});
    }
  }

  /**
   * campaign store error callback
   * @param {Data} data Object
   */
  onCampaignStoreError(data) {
    this.setStaste({campaignError: data});
  }

  /**
   * search for thirs part tags
   */
  searchThirdPartyTags(details) {
    const assingedAdPositions = isEmptyObject(details) ? this.state.currentInsertionsDetails : details;
    const campaignAdSpaceIds = this.state.adSpaces.map(adSpace => adSpace.campaigns_ad_space_fields.id);
    const insertionIds = this.state.insertions.map(insertion => insertion.id);
    const assignedAdPositionIds = TrackerManager.getAllAssignedAdPositionIds(assingedAdPositions);
    ThirdPartyTag.search({
      accountId: ContextStore.routingParams().accountId,
      campaignId: ContextStore.routingParams().campaignId,
      campaignAdSpaceIds,
      insertionIds,
      assignedAdPositionIds,
    });
  }

  /**
   * removes trakcer
   * @param {Object} tracker tracker
   */
  handleRemoveTracker(tracker) {
    ThirdPartyTag.remove({
      accountId: ContextStore.routingParams().accountId,
      thirdPartyTagId: tracker.id,
    });
    this.setState({trackerLoading: tracker});
  }

  /**
   * Handles new tracker modal
   */
  handleAddTracker() {
    const data = {};
    if (this.state.selectedRow.subject === 'SO') {
      this.state.adSpaces.forEach((adSpace) => {
        if (adSpace.campaigns_ad_space_fields.id === this.state.selectedRow.itemId) {
          data.campaignAdSpace = adSpace.campaigns_ad_space_fields;
        }
      });
    } else if (this.state.selectedRow.subject === 'I') {
      data.insertion = getObjectInArray(this.state.insertions, 'id', this.state.selectedRow.itemId);
    } else if (this.state.selectedRow.subject === 'CR') {
      data.creative = getObjectInArray(this.props.assignedCreatives, 'id', this.state.selectedRow.itemId);
    } else if (this.state.selectedRow.subject === 'AP') {
      data.assignedAdPosition = {id: this.state.selectedRow.itemId};
    }
    UIActions.addTracker(data);
  }

  /**
   * Expands or collapse an insertion row  to see assigned creatives
   * @param {Number} insertionId insertion id
   */
  handleExpandCollapseInsertion(insertionId) {
    this.setState((prevState) => {
      const expandedInsertions = prevState.expandedInsertions.slice();
      const index = expandedInsertions.indexOf(insertionId);
      if (index > -1) {
        expandedInsertions.splice(index, 1);
      } else {
        expandedInsertions.push(insertionId);
        Insertion.details({
          accountId: ContextStore.routingParams().accountId,
          insertionIds: expandedInsertions,
        });
      }
      return {expandedInsertions};
    });
  }

  /**
   * Renders trackermanager
   */
  render() {
    const trackerRow = [];
    const tableRow = [];
    let active = false;
    tableRow.push(<CampaignTrackerRow
      key={this.state.campaign.id}
      active={this.state.selectedRow.subject === 'C'}
      onClick={() => { this.setState({selectedRow: {subject: 'C', itemId: this.state.campaign.id}}); }}
      campaign={this.state.campaign}
    />);
    if (this.state.selectedRow.subject === 'C') {
      this.state.campaignTrackers.forEach((tracker) => {
        trackerRow.push(<TrackerRow
          key={tracker.id}
          tracker={tracker}
          onRemoveTracker={this.handleRemoveTracker}
          loading={this.state.trackerLoading.id === tracker.id}
          codeStyle={{ wordBreak: 'break-all' }}
        />);
      });
    }
    this.state.adSpaces.forEach((adSpace) => {
      const country = getObjectInArray(CountryStore.getCountries(), 'id', adSpace.ad_network_fields.country_id);
      active = this.state.selectedRow.subject === 'SO'
        && this.state.selectedRow.itemId === adSpace.campaigns_ad_space_fields.id;
      tableRow.push((
        <tr
          key={adSpace.campaigns_ad_space_fields.id}
          onClick={() => {
            this.setState({selectedRow: {subject: 'SO', itemId: adSpace.campaigns_ad_space_fields.id}});
          }}
          className={active ? 'active' : 'cursor-pointer'}
        >
          <td className="center aligned collapsing">SO</td>
          <td style={{paddingLeft: '15px'}} className="left aligned single line">
            {country && <i className={`${country.code2.toLowerCase()} flag`} />}
            <b>{adSpace.ad_space_fields.label}</b>
          </td>
        </tr>
      ));
      if (active) {
        this.state.adSpaceTrackers.forEach((adSpaceTracker) => {
          if (adSpaceTracker.campaigns_ad_space_id === adSpace.campaigns_ad_space_fields.id) {
            trackerRow.push(<TrackerRow
              key={adSpaceTracker.id}
              tracker={adSpaceTracker}
              onRemoveTracker={this.handeRemoveTracker}
              loading={this.state.trackerLoading.id === adSpaceTracker.id}
              codeStyle={{ wordBreak: 'break-all' }}
            />);
          }
        });
      }
      this.state.insertions.forEach((insertion) => {
        if (insertion.ad_space_id === adSpace.ad_space_fields.id) {
          active = this.state.selectedRow.subject === 'I' && this.state.selectedRow.itemId === insertion.id;
          tableRow.push((
            <tr
              key={`ins-${insertion.id}`}
              onClick={() => { this.setState({selectedRow: {subject: 'I', itemId: insertion.id}}); }}
              className={active ? 'active' : 'cursor-pointer'}
            >
              <td className="center aligned collapsing">I</td>
              <td style={{paddingLeft: '30px'}} className="left aligned single line">
                <ExpandCollapseButton
                  isExpanded={this.state.expandedInsertions.indexOf(insertion.id) > -1}
                  onClick={(e) => { e.preventDefault(); this.handleExpandCollapseInsertion(insertion.id); }}
                />
                {insertion.label}
              </td>
            </tr>
          ));
          if (active) {
            this.state.insertionTrackers.forEach((insertionTracker) => {
              if (insertionTracker.insertion_id === insertion.id) {
                trackerRow.push(<TrackerRow
                  key={`tr-${insertionTracker.id}`}
                  tracker={insertionTracker}
                  onRemoveTracker={this.handleRemoveTracker}
                  loading={this.state.trackerLoading.id === insertionTracker.id}
                  codeStyle={{ wordBreak: 'break-all' }}
                />);
              }
            });
          }
          if (this.state.expandedInsertions.indexOf(insertion.id) > -1) {
            this.props.assignedCreatives.forEach((creative) => {
              if (creative.insertion_id === insertion.id) {
                let fileIcon = <Icon grey image />;
                if (parseInt(creative.creative_format_id, 10) === 6) {
                  fileIcon = <Icon grey image />;
                } else if (parseInt(creative.creative_format_id, 10) === 4) {
                  fileIcon = <Icon grey video />;
                }
                tableRow.push((
                  <tr
                    key={`${creative.id}-${insertion.id}`}
                    className="cursor: not-allowed"
                  >
                    <td className="center aligned collapsing">CR</td>
                    <td style={{paddingLeft: '45px'}} className="left aligned single line">
                      {fileIcon}
                      {creative.label}
                    </td>
                  </tr>
                ));
                this.props.insertionsDetails.forEach((detail) => {
                  if (parseInt(detail.id, 10) === parseInt(insertion.id, 10)) {
                    detail.tracking_elements.forEach((te) => {
                      te.assigned_ad_positions.forEach((ap) => {
                        if (ap.adposition.creative.id === creative.id) {
                          active = this.state.selectedRow.subject === 'AP' && this.state.selectedRow.itemId === ap.id;
                          tableRow.push((
                            <tr
                              key={ap.id}
                              onClick={() => { this.setState({selectedRow: {subject: 'AP', itemId: ap.id}}); }}
                              className={active ? 'active' : 'cursor-pointer'}
                            >
                              <td className="center aligned collapsing">AP</td>
                              <td style={{paddingLeft: '90px'}} className="left aligned single line">
                                {te.placement.label}
                              </td>
                            </tr>
                          ));
                        }
                        if (active) {
                          this.state.assignedAdPositionTrackers.forEach((apTracker) => {
                            if (apTracker.assigned_ad_position_id === ap.id) {
                              trackerRow.push(<TrackerRow
                                key={`trap-${apTracker.id}`}
                                tracker={apTracker}
                                onRemoveTracker={this.handleRemoveTracker}
                                loading={this.state.trackerLoading.id === apTracker.id}
                                codeStyle={{ wordBreak: 'break-all' }}
                              />);
                            }
                          });
                        }
                      });
                    });
                  }
                });
              }
            });
          }
        }
      });
    });
    return (
      <Grid two column>
        <Dimmer inverted active={this.state.loading}>
          <Loader indeterminate text>Loading trackers</Loader>
        </Dimmer>
        <Column seven wide>
          {!isEmptyObject(this.state.campaignError)
            && <Message error>{this.state.campaignError.message}</Message>
          }
          <Table
            small
            celled
            selectable
            definition
            attached
            single
            line
            unstackable
          >
            <thead className="full-width">
              <tr>
                <th className="one wide center aligned" colSpan="2">
                  {'C = Campaign, SO = Site, I = Insertion, CR = Creative'}
                </th>
              </tr>
            </thead>
            <tbody>
              {tableRow}
            </tbody>
          </Table>
        </Column>
        <Column nine wide>
          <Table
            small
            celled
            selectable
            definition
            attached
          >
            <thead className="full-width">
              <tr>
                <th className="center aligned" colSpan="5">
                  {'Trackers'}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="5">
                  <Button
                    fluid
                    primary
                    small
                    onClick={this.handleAddTracker}
                  >
                    {'Add Tracker'}
                  </Button>
                </td>
              </tr>
              {trackerRow}
            </tbody>
          </Table>
        </Column>
      </Grid>
    );
  }
}

export default TrackerManager;

TrackerManager.propTypes = {
  campaign: PropTypes.object,
  adSpaces: PropTypes.array,
  insertions: PropTypes.array,
  assignedCreatives: PropTypes.array,
  insertionsDetails: PropTypes.array,
};
