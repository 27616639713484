/* eslint-disable react/no-did-update-set-state */
import PropTypes from 'prop-types';
import React from 'react';
import AppConstants from 'constants/AppConstants';
import ProjectStore from 'stores/ProjectStore';
import {Project, UIActions} from 'actions';
import Filter from 'components/Filter';
import Paginator from 'components/Paginator';
import Button from 'weborama-ui-react/Button';
import Loader from 'weborama-ui-react/Loader';
import Dimmer from 'weborama-ui-react/Dimmer';
import {getObjectInArray, getLocationDescriptor} from 'constants/Utils';
import NoItemsMessage from 'components/NoItemsMessage';
import NavigationMenu from 'components/NavigationMenu';
import Divider from 'weborama-ui-react/Divider';
import ContextStore from 'stores/ContextStore';
/**
 * Project list
 */
class ProjectList extends React.Component {
  /**
   * constructor
   * @date   2016-09-01
   * @param  {object}   props properties
   */
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      projects: [],
      paginator: {
        page: 1,
      },
      filter: '',
      maxListItems: props.maxListItems || 10,
      selectedProject: {},
      oldContextRouteParams: { // will be overwritten by route parameters
        accountId: undefined
      }
    };

    this.onProjectStoreChange = this.onProjectStoreChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handlePaginationChange = this.handlePaginationChange.bind(this);
    this.handleSelectProject = this.handleSelectProject.bind(this);
    this.handleEditProject = this.handleEditProject.bind(this);
    this.handleRemoveProject = this.handleRemoveProject.bind(this);
    this.addCampaignFn = this.addCampaignFn.bind(this);
    this.editProjectFn = this.editProjectFn.bind(this);
    this.removeProjectFn = this.removeProjectFn.bind(this);

    ProjectStore.addChangeListener(this.onProjectStoreChange);
  }

  /**
   * Fetch a list of projects
   */
  componentDidMount() {
    Project.list({accountId: ContextStore.routingParams().accountId});
  }

  componentDidUpdate() {
    if (this.props.maxListItems !== this.state.maxListItems) {
      this.setState({maxListItems: this.props.maxListItems});
    }
    if (ContextStore.routingParams().accountId !== this.state.oldContextRouteParams.accountId) {
      this.setState({projects: [], loading: true, oldContextRouteParams: ContextStore.routingParams()});
      Project.list({accountId: ContextStore.routingParams().accountId});
    }
    if (
      ContextStore.routingParams().projectId !== this.state.oldContextRouteParams.projectId
      && this.state.projects.length > 0
      && ContextStore.routingParams().projectId !== undefined) {

      this.setState({
        selectedProject: getObjectInArray(this.state.projects, 'id', ContextStore.routingParams().projectId),
        oldContextRouteParams: ContextStore.routingParams()});
    }
  }

  /**
   * Project store change listener
   * @date   2016-08-25
   */
  componentWillUnmount() {
    ProjectStore.removeChangeListener(this.onProjectStoreChange);
  }

  /**
   * project store change callback
   * @date   2016-08-25
   * @param  {object}   data  info object
   */
  onProjectStoreChange(data = {}) {
    if (data.action === AppConstants.LIST_PROJECTS) {
      const projects = ProjectStore.getProjects();

      let paginatorPage = 1;
      if (ContextStore.routingParams().projectId !== undefined) {
        const elementPos = projects.map(obj => String(obj.id)).indexOf(String(ContextStore.routingParams().projectId));
        paginatorPage = Math.ceil((elementPos + 1) / this.state.maxListItems);
      }
      this.setState({
        loading: false,
        projects,
        paginator: {page: paginatorPage},
        selectedProject: getObjectInArray(projects, 'id', ContextStore.routingParams().projectId),
      });
    }
  }

  /**
   * Filters project list
   * @date   2016-08-25
   * @return {array}   filtered project list
   */
  getFilteredProjectList() {
    const projects = [];
    this.state.projects.forEach((item) => {
      if (item.label.toLowerCase().indexOf(this.state.filter) > -1 || item.id.toString().indexOf(this.state.filter) > -1) {
        projects.push(item);
      }
    });
    return projects;
  }

  /**
   * Handles updating a folder
   * @date   2016-07-19
   */
  handleEditProject() {
    UIActions.editProject(this.state.selectedProject);
  }

  /**
   * handles removing a folderId
   * @date   2016-07-19
   */
  handleRemoveProject() {
    UIActions.removeProject(this.state.selectedProject);
  }

  /**
   * Updates filter
   * @date   2016-08-25
   * @param  {object}   e event object
   */
  handleFilterChange(e) {
    this.setState({
      filter: e.currentTarget.value.toLowerCase(),
      paginator: {page: 1},
    });
  }

  /**
   * Handles pagination
   * @date   2016-05-26
   * @param  {number}   page page to navigate to
   */
  handlePaginationChange(page) {
    this.setState({paginator: {page}});
  }

  /**
   * Handles selecting a creative
   */
  handleSelectProject(item) {
    // if (ContextStore.routingParams().projectId !== parseInt(item.id, 10)) {
    const link = getLocationDescriptor('project', item.id, true);
    ContextStore.router().push(link);
    // }
  }

  /**
  * Adds a new folder
  * @param  {Object} singleProject
  */
  addCampaignFn(singleProject) {
    UIActions.addCampaign({ projectId: parseInt(singleProject.id, 10), projectLabel: singleProject.label });
  }

  /**
  * Edits existing folder
  * @param  {Object} singleProject
  */
  editProjectFn(singleProject) {
    UIActions.editProject(singleProject);
  }

  /**
  * Removes a folder
  * @param  {Object} singleProject
  */
  removeProjectFn(singleProject) {
    UIActions.removeProject({
      accountId: ContextStore.routingParams().accountId,
      projectId: parseInt(singleProject.id, 10),
      projectLabel: singleProject.label,
    });
  }

  /**
   * Render navigation project list
   * @date   2016-08-25
   * @return {jsx}   project list
   */
  render() {
    let projects = [];
    let paginatorSettings = {
      totalPages: 0,
      page: 1,
    };
    projects = this.state.projects.filter((project) => {
      if (project.label.toLowerCase().indexOf(this.state.filter) > -1 || project.id.toString().indexOf(this.state.filter) > -1) {
        return project;
      }
      return false;
    });
    /*
      calculate paginator settings
      */
    paginatorSettings = {
      totalPages: Math.ceil(projects.length / this.state.maxListItems),
      page: this.state.paginator.page,
    };

    projects = projects.slice(
      (0 + (this.state.paginator.page - 1)) * this.state.maxListItems,
      (((0 + (this.state.paginator.page - 1)) * this.state.maxListItems) + this.state.maxListItems),
    );
    return (
      <div>
        <div className="ui items">
          <div className="item" style={{position: 'relative'}}>
            <div className="bottom aligned content" style={{position: 'absolute', bottom: 0}}>
              <div className="header">
                {'Projects'}
              </div>
            </div>
            <div className="extra">
              <Button primary right floated small onClick={UIActions.addProject}>
                <i className="add icon" />
                {'Add Project'}
              </Button>
            </div>
          </div>
        </div>
        <Divider />
        <Dimmer active={this.state.loading} inverted>
          <Loader inverted indeterminate text>Loading Projects</Loader>
        </Dimmer>
        <div>
          <Filter
            fluid
            small
            attached
            prompt="Filter projects"
            onChange={this.handleFilterChange}
          />
          {
            this.state.projects.length === 0 && !this.state.loading && (
              <span>
                <Divider hidden />
                <NoItemsMessage
                  callToAction="click here to add a project"
                  label="No projects found"
                  visible={this.state.projects.length === 0 && !this.state.loading}
                  onAction={ProjectList.handleNewProject}
                />
              </span>
            )
          }
          <NavigationMenu
            items={projects}
            subject="project"
            onClick={this.handleSelectProject}
            addFn={this.addCampaignFn}
            editFn={this.editProjectFn}
            removeFn={this.removeProjectFn}
            labels={{add: 'New Campaign', edit: 'Edit Project', remove: 'Remove project'}}
          />
          <Paginator
            totalPages={paginatorSettings.totalPages}
            page={paginatorSettings.page}
            onPaginationChange={this.handlePaginationChange}
          />
        </div>
      </div>
    );
  }
}

export default ProjectList;

ProjectList.propTypes = {
  maxListItems: PropTypes.number,
};
