import PropTypes from 'prop-types';
import React from 'react';
import Form, {Field} from 'weborama-ui-react/Form';
import Input from 'weborama-ui-react/Input';
import Dropdown from 'weborama-ui-react/Dropdown';
import Item from 'weborama-ui-react/Item';
import Menu from 'weborama-ui-react/Menu';
import {Insertion} from 'actions';
import Accordion, {Title, Content} from 'weborama-ui-react/Accordion';
import Calendar from 'weborama-ui-react/Calendar';
import Button from 'weborama-ui-react/Button';
import {isEmptyObject} from 'constants/Utils';
import AppConstants from 'constants/AppConstants';
import {InsertionStore, ContextStore} from 'stores';
import Checkbox from 'weborama-ui-react/Checkbox';
import AdSpaceSearch from 'components/AdSpaceSearch';
/**
 * Class insertion form
 */
class DuplicateInsertionForm extends React.Component {
  /**
   * Maps props to state
   * @date   2016-11-14
   * @param  {Object}   props properties
   * @return {Object}         state object
   */
  static getStateFromProps(props) {
    if (!isEmptyObject(props.insertion)) {
      const landingUrl = props.insertion.landing_url;

      return {
        label: props.insertion.label,
        deliveryFormatId: props.insertion.delivery_format_id,
        adSpaceId: props.insertion.ad_space_id,
        adNetworkId: props.insertion.ad_network_id,
        landingUrl,
        comments: props.insertion.comments || '',
        priority: props.insertion.priority,
        startDate: props.insertion.start_date,
        endDate: props.insertion.end_date,
        duplicateCreatives: true,
        action: 'duplicate',
        loading: false,
        error: {},
        adSpaces: [],
        adNetworks: [],
      };
    }
    return {
      action: 'add',
      label: '',
      deliveryFormatId: 1,
      adSpaceId: '',
      adNetworkId: '',
      comments: '',
      priority: 'standard',
      landingUrl: '',
      startDate: '',
      endDate: '',
      error: {},
      adSpaces: [],
      adNetworks: [],
      loading: false,
    };
  }

  /**
   * constructor
   * @date   2016-11-14
   * @param  {Object}   props properties
   */
  constructor(props) {
    super(props);

    this.state = DuplicateInsertionForm.getStateFromProps(props);

    this.handleCommentsChange = this.handleCommentsChange.bind(this);
    this.handleLabelChange = this.handleLabelChange.bind(this);
    this.handleUrlChange = this.handleUrlChange.bind(this);
    this.handleDeliveryTypeChange = this.handleDeliveryTypeChange.bind(this);
    this.handlePriorityChange = this.handlePriorityChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleDuplicateCreatives = this.handleDuplicateCreatives.bind(this);
    this.onInsertionStoreError = this.onInsertionStoreError.bind(this);
    this.handleSelectAdSpace = this.handleSelectAdSpace.bind(this);

    InsertionStore.addErrorListener(this.onInsertionStoreError);

    this.duplicateCreativesRef = React.createRef();
    this.inputRef = React.createRef();
  }

  /**
   * set state to new properties
   * @param  {object}   prevProps old properties
   * @param  {object}   props new properties
   */
  componentDidUpdate(prevProps, props) {
    if (prevProps.toString() !== props.toString()) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(DuplicateInsertionForm.getStateFromProps(props));
    }
  }

  /**
   * Fetch a list of ad spaces
   */
  componentDidMount() {
    setTimeout(() => this.inputRef.current.focus(), 500); // autoFocus is not working as expected, this is a workaround
  }

  /**
   * unregister store change callbacks
   * @date   2016-08-30
   */
  componentWillUnmount() {
    InsertionStore.removeErrorListener(this.onInsertionStoreError);
  }

  /**
   * Insertionstore error callback
   * @date   2016-10-18
   * @param  {Object}   error error Object
   */
  onInsertionStoreError(error) {
    if (error.action === AppConstants.DUPLICATE_INSERTION
        || error.action === AppConstants.ADD_INSERTION
        || error.action === AppConstants.UPDATE_INSERTION) {
      this.setState({loading: false, error});
    }
  }

  /**
   * handles comments changes
   * @date   2016-08-30
   * @param  {object}   e event object
   */
  handleCommentsChange(e) {
    this.setState({comments: e.target.value});
  }

  /**
   * Handles label change
   * @date   2016-12-14
   * @param  {String}   value label
   */
  handleLabelChange(value) {
    this.setState({label: value});
  }

  /**
   * handles url change
   * @param  {String}   newLandingUrl
   */
  handleUrlChange(newLandingUrl) {
    this.setState({ landingUrl: newLandingUrl });
  }

  /**
   * handles delivery type changes
   * @date   2016-08-30
   * @param  {string}   value delivery type
   */
  handleDeliveryTypeChange(value) {
    this.setState({deliveryFormatId: value});
  }

  /**
   * Handles priority change
   * @date   2016-08-31
   * @param  {String}   value priority
   */
  handlePriorityChange(value) {
    this.setState({priority: value});
  }

  /**
   * handles start date picker
   * @date   2016-08-29
   * @param  {String}   value selected date time
   */
  handleStartDateChange(value) {
    this.setState({startDate: value.getTime() / 1000});
  }

  /**
   * handles end date picker
   * @date   2016-08-29
   * @param  {String}   value selected date time
   */
  handleEndDateChange(value) {
    this.setState({endDate: value.getTime() / 1000});
  }

  /**
   * Handles "also" add creatives checkbox
   * @date   2016-11-14
   */
  handleDuplicateCreatives() {
    this.setState({duplicateCreatives: this.duplicateCreativesRef.current.isChecked()});
  }

  /**
   * handles ad space
   * @param {Object} adSpace ad space objecft
   */
  handleSelectAdSpace(adSpace) {
    if (adSpace == null) {
      this.setState({ adSpaceId: '' });
      // Added here to make sure submit button is not clickable
      // in case the user selects an adspace, changes their mind and tries to
      // submit without selecting anything else. Works in conjunction with AdSpaceSearch
    } else {
      this.setState({ adSpaceId: adSpace.id });
    }
  }

  /**
   * Calculate whether the submit button should be disabled or not
   */
  isSubmitButtonDisabled() {
    if (this.state.label !== '' && this.state.adSpaceId !== '') {
      return false;
    }
    return true;
  }

  /**
   * Handles form submit
   * @date   2016-08-31
   */
  handleFormSubmit() {
    Insertion.duplicate({
      insertionId: this.props.insertion.id,
      accountId: ContextStore.routingParams().accountId,
      projectId: ContextStore.routingParams().projectId,
      campaignId: ContextStore.routingParams().campaignId,
      adSpaceId: this.state.adSpaceId,
      adNetworkId: this.props.insertion.ad_network_id,
      deliveryFormatId: this.state.deliveryFormatId,
      label: this.state.label,
      duplicateCreatives: this.state.duplicateCreatives,
      comments: this.state.comments,
      landingUrl: this.state.landingUrl,
      priority: this.state.priority,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    });
    this.setState({loading: true});
  }

  /**
   * Renders insertion form
   * @date   2016-08-30
   * @return {jsx}   insertion form
   */
  render() {
    let errorMessage;
    if (!isEmptyObject(this.state.error)) {
      errorMessage = (
        <p className="error">{this.state.error.message}</p>
      );
    }
    return (
      <Form>
        <Field label="Site/Offer" required>
          <AdSpaceSearch
            onSelect={this.handleSelectAdSpace}
            selectedAdSpaceId={this.state.adSpaceId.toString()}
          />
        </Field>
        <Field label="Name" required>
          <Input
            inputRef={this.inputRef}
            placeHolder="Name"
            onChange={this.handleLabelChange}
            className="insertion-label"
            value={this.state.label}
            autoFocus="autoFocus"
          />
        </Field>
        <Field label="Delivery type" required>
          <Dropdown
            inline={false}
            selection
            defaultText="Redirect"
            defaultValue={this.state.deliveryFormatId}
            onChange={this.handleDeliveryTypeChange}
            disabled
          >
            <Menu>
              <Item value={1}>Redirect</Item>
              <Item value={2}>Image</Item>
              <Item value={6}>Vast</Item>
            </Menu>
          </Dropdown>
        </Field>
        <Field>
          <Checkbox ref={this.duplicateCreativesRef} checked onChange={this.handleDuplicateCreatives}>
            {'Duplicate creatives'}
          </Checkbox>
        </Field>
        <Field>
          <Accordion>
            <Title>
              {'Optional settings'}
            </Title>
            <Content>
              <Field label="Comments">
                <textarea
                  className="insertion-comments"
                  onChange={this.handleCommentsChange}
                  value={this.state.comments}
                  rows="2"
                />
              </Field>
              <Field label="Default landing url">
                <Input
                  className="insertion-landing-url"
                  placeHolder="Default landing url"
                  required
                  labeled
                  value={this.state.landingUrl}
                  onChange={this.handleUrlChange}
                >
                </Input>
              </Field>
              <Field label="Priority">
                <Dropdown
                  inline={false}
                  selection
                  defaultText="Standaard"
                  defaultValue={this.state.priority}
                  onChange={this.handlePriorityChange}
                >
                  <Menu>
                    <Item value="standard">Standard</Item>
                    <Item value="high">High</Item>
                    <Item value="low">Low</Item>
                  </Menu>
                </Dropdown>
              </Field>
              <Field label="Start date">
                <Calendar placeHolder="Start date" value={this.state.startDate} onChange={this.handleStartDateChange} />
              </Field>
              <Field label="End date">
                <Calendar placeHolder="End date" value={this.state.endDate} onChange={this.handleEndDateChange} />
              </Field>
            </Content>
          </Accordion>
        </Field>
        <Field>
          <Button
            fluid
            primary
            disabled={this.isSubmitButtonDisabled()}
            loading={this.state.loading}
            onClick={this.handleFormSubmit}
          >
            {'Submit'}
          </Button>
        </Field>
        {errorMessage}
      </Form>
    );
  }
}

export default DuplicateInsertionForm;

DuplicateInsertionForm.propTypes = {
  insertion: PropTypes.object,
};
