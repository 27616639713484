import PropTypes from 'prop-types';
import React from 'react';
import {Insertion} from 'actions';
import EditableUrl from 'components/EditableUrl';
import ContextStore from 'stores/ContextStore';
import { removeHTMLEntities } from 'constants/Utils';
/**
 * Class InsertionClickwrapper. Wraps around the clickable Url's at Landing Url's
 */
class InsertionClickWrapper extends React.Component {
  /**
   * Constructor
   * @date   2016-11-28
   * @param  {Object}   props Properties
   */
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      insertion: props.insertion || {},
      insertionUrl: props.insertion.landing_url || '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  componentDidMount() {
    this.updateState();
  }

  componentDidUpdate() {
    this.updateState();
  }

  updateState() {
    if (JSON.stringify(this.props.insertion) !== JSON.stringify(this.state.insertion)) {
      this.setState({insertionUrl: this.props.insertion.landing_url, insertion: this.props.insertion, loading: false});
    }
  }

  /**
   * Handles url submit
   * @date   2016-12-01
   * @param  {String}   url url
   */
  handleSubmit(url) {

    const obj = {
      accountId: ContextStore.routingParams().accountId,
      projectId: ContextStore.routingParams().projectId,
      campaignId: ContextStore.routingParams().campaignId,
      insertionId: this.props.insertion.id,
      landingUrl: removeHTMLEntities(url),
    };
    // eslint-disable-next-line no-console
    console.log('submitted object for update', obj);
    Insertion.update(obj);
    this.setState({loading: true});
  }

  /**
   * Renders insertionlist for click urls
   * @date   2016-11-28
   * @return {Array}   Nodes
   */
  render() {
    return (
      <EditableUrl
        url={this.state.insertionUrl}
        onSubmit={this.handleSubmit}
        parent="Site / Offer"
        loading={this.state.loading}
      />
    );
  }
}

export default InsertionClickWrapper;

InsertionClickWrapper.propTypes = {
  insertion: PropTypes.object,
  onUpdateUrl: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
};
