import React from 'react';
import PropTypes from 'prop-types';
import EditableUrl from 'components/EditableUrl';
import {AssignedAdPosition} from 'actions';
import {isEmptyObject} from 'constants/Utils';
import ContextStore from 'stores/ContextStore';
import UIActions from 'actions/UIActions';
/**
 * clas AssignedAdPosition
 */
class AssignedAdPositionClick extends React.Component {
  /**
   * Cosntructor
   * @param {Object} props properties
   */
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      assignedAdPosition: props.assignedAdPosition || {},
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleExtraClick = this.handleExtraClick.bind(this);
    this.handleNewProps = this.handleNewProps.bind(this);
    this.handleNewProps(props);
  }

  componentDidUpdate() {
    this.handleNewProps(this.props); // Note that we're using current props, not the prevProps
  }

  /**
   * Map new props to state if needed
   */
  handleNewProps(props) {
    if (JSON.stringify(props.assignedAdPosition) !== JSON.stringify(this.state.assignedAdPosition)) {
      this.setState({assignedAdPosition: props.assignedAdPosition, loading: false});
    }
  }

  /**
   * Handles click submit
   */
  handleSubmit(landingUrl) {
    AssignedAdPosition.update({
      accountId: ContextStore.routingParams().accountId,
      assignedAdPositionId: this.state.assignedAdPosition.id,
      landingUrl,
    });
    this.setState({loading: true});
  }

  /**
   * Handles add extra clicks
   */
  handleExtraClick() {
    UIActions.addExtraClick({
      assignedAdPosition: this.props.assignedAdPosition,
    });
  }

  /**
   * Renders assigned ad position clicks
   */
  render() {
    return (
      <tr>
        <td className="center aligned">AP</td>
        <td className="right aligned">
          <i>{this.props.label}</i>
          <p>
            <a onClick={this.handleExtraClick} className="cursor-pointer">
              {'+ add extra click(s)'}
            </a>
          </p>
        </td>
        <td className="left aligned">
          <EditableUrl
            url={!isEmptyObject(this.state.assignedAdPosition) && this.state.assignedAdPosition.landing_url}
            onSubmit={this.handleSubmit}
            parent="Insertion"
            loading={this.state.loading}
          />
        </td>
      </tr>
    );
  }
}

export default AssignedAdPositionClick;

AssignedAdPositionClick.propTypes = {
  assignedAdPosition: PropTypes.object,
  label: PropTypes.string,
};
