import {Dispatcher} from 'flux';

const flux = new Dispatcher();
/**
 * register flux callback
 * @date   2016-06-20
 * @param  {Function} callback flux callback
 * @return {Function}          flux register function
 */
export function register(callback) {
  return flux.register(callback);
}
/**
 * Dispatches an action
 * @date   2016-06-20
 * @param  {string}   actionType action
 * @param  {string}   action     action
 */
export function dispatch(actionType, action) {
  flux.dispatch(actionType, action);
}
/**
 * Unregister actions
 * @date   2016-10-03
 * @param  {string}   id Flux Id
 */
export function unregister(id) {
  flux.unregister(id);
}
