import React from 'react';
import PropTypes from 'prop-types';
import {Campaign} from 'actions';
import {CampaignStore, ContextStore} from 'stores';
import AppConstants from 'constants/AppConstants';
import EditableUrl from 'components/EditableUrl';
import { removeHTMLEntities } from 'constants/Utils';

class CampaignClick extends React.Component {
  /**
   * Constructor
   * @param {Object} props Properties
   */
  constructor(props) {
    super(props);
    this.state = {
      campaign: props.campaign,
      error: {},
      loading: false,
    };
    this.handleUpdateCampaign = this.handleUpdateCampaign.bind(this);
    this.onCampaignStoreError = this.onCampaignStoreError.bind(this);

    CampaignStore.addErrorListener(this.onCampaignStoreError);
    this.updateCampaignInState = this.updateCampaignInState.bind(this);
    this.updateCampaignInState();
  }

  componentDidUpdate() {
    this.updateCampaignInState();
  }

  updateCampaignInState() {
    if (JSON.stringify(this.props.campaign) !== JSON.stringify(this.state.campaign)) {
      this.setState({campaign: this.props.campaign, loading: false});
    }
  }

  /**
   * remove store listener
   */
  componentWillUnmount() {
    CampaignStore.removeErrorListener(this.onCampaignStoreError);
  }

  /**
   * CampaignStore error callback
   * @param {Object} data data object
   */
  onCampaignStoreError(data) {
    if (data.action === AppConstants.UPDATE_CAMPAIGN) {
      this.setState({error: data.error, loading: false});
    }
  }

  /**
   * handles update campaign url
   * @param {String} url url
   */
  handleUpdateCampaign(url) {
    Campaign.update({
      accountId: ContextStore.routingParams().accountId,
      projectId: ContextStore.routingParams().projectId,
      campaignId: ContextStore.routingParams().campaignId,
      url: removeHTMLEntities(url),
    });

    this.setState({loading: true, error: {}});
  }

  /**
   * Renders campaign click field
   */
  render() {
    return (
      <EditableUrl
        url={this.state.campaign.landing_url}
        onSubmit={this.handleUpdateCampaign}
        baseObject={this.state.campaign}
        loading={this.state.loading}
        error={this.state.error}
      />
    );
  }
}

export default CampaignClick;

CampaignClick.propTypes = {
  campaign: PropTypes.object,
};
