import PropTypes from 'prop-types';
import React from 'react';
import AssignActions from 'actions/AssignActions';
import {getLocationDescriptor} from 'constants/Utils';
import Button from 'weborama-ui-react/Button';
import AssignStore from 'stores/AssignStore';
import AppConstants from 'constants/AppConstants';
import ContextStore from 'stores/ContextStore';

/**
 * Assigned creative item
 */
class AssignedCreativeItem extends React.Component {
  /**
   * Constructor
   */
  constructor() {
    super();
    this.state = {
      loading: false,
    };
    this.setDefaultCreative = this.setDefaultCreative.bind(this);
    this.handleOpenPreview = this.handleOpenPreview.bind(this);
    this.handleOpenInCl = this.handleOpenInCl.bind(this);
    this.onAssignStoreError = this.onAssignStoreError.bind(this);

    AssignStore.addErrorListener(this.onAssignStoreError);
  }

  /**
   * Remove assign store error callback
   */
  componentWillUnmount() {
    AssignStore.removeErrorListener(this.onAssignStoreError);
  }

  /**
   * assign store error callback
   * @param {Object} data data object
   */
  onAssignStoreError(data) {
    if (data.action === AppConstants.SET_DEFAULT_CREATIVE) {
      this.setState({loading: false});
    }
  }

  /**
   * Sets creative as default creative
   */
  setDefaultCreative() {
    AssignActions.setDefaultCreative(ContextStore.routingParams().accountId, this.props.insertion.id, this.props.creative.id);
    this.setState({loading: true});
  }

  /**
   * Opens preview of assigned creative
   */
  handleOpenPreview() {
    const link = `${window.location.origin}/account/${ContextStore.routingParams().accountId}/preview/folder/${this.props.creative.folder_id}/creative/${this.props.creative.id}`; // eslint-disable-line max-len
    window.open(link, '_blank');
  }

  /**
   * Opens assigned creative in creative library
   */
  handleOpenInCl() {
    const ld = getLocationDescriptor('folder', this.props.creative.folder_id, true);
    ld.pathname += `/creative/${this.props.creative.id}`;
    ld.pathname = ld.pathname.replace('cm', 'cl');
    ContextStore.router().push(ld);
  }

  /**
   * Render
   */
  render() {
    let setDefaultButton = (
      <Button
        basic
        mini
        icon
        toolTip="Set as default creative"
        onClick={this.setDefaultCreative}
      >
        {this.state.loading
          && <i className="spinner loading icon" />
        }
        {!this.state.loading
          && <i className="toggle off icon" />
        }
      </Button>
    );
    if (this.props.creative.is_default === 1) {
      setDefaultButton = (
        <Button basic mini icon toolTip="Default creative">
          <i className=" green toggle on icon" />
        </Button>
      );
    }

    // TODO: Icon should change according the creative_type_id.. request is pending
    let icon = <i className="grey image icon" />;
    if (parseInt(this.props.creative.creative_format_id, 10) === 6) {
      icon = <i className="grey image icon" />;
    } else if (parseInt(this.props.creative.creative_format_id, 10) === 4) {
      icon = <i className="grey video icon" />;
    }

    return (
      <tr>
        <td className="right aligned single line"><i>Assigned Creative: </i></td>
        <td className="center aligned">
          {setDefaultButton}
        </td>
        <td colSpan="2" className="single line">
          {icon}
          {this.props.creative?.warm_creative_id ? <i><a className="cursor-pointer" onClick={this.handleOpenInCl}>{this.props.creative.label}</a></i>
            : (
              <span data-inverted="" data-tooltip={'This is a WCM creative'} data-position={'bottom right'}>
                <i><a className="cursor-pointer" style={{color: 'lightgrey', cursor: 'pointer', pointerEvents: 'none'}}>{this.props.creative?.label}</a></i>
              </span>
            )
        }
        </td>
        <td colSpan="2">
          <Button basic fluid onClick={this.handleOpenPreview}>
            <i className="eye icon" />
            {'Preview'}
          </Button>
        </td>
      </tr>
    );
  }
}

export default AssignedCreativeItem;

AssignedCreativeItem.propTypes = {
  creative: PropTypes.object,
  insertion: PropTypes.object,
};
