import {dispatch} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const CreativeLabel = {
  /**
   * Dispatches an action to fecth a list creative labels
   * @param {Object} data {acountId}
   */
  list(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.LIST_CREATIVE_LABELS,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to add a creative label
   * This is not being used, since labels are automaticly created when added
   * to a creative
   * @param {Object} data {accountId, label}
   */
  add(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.ADD_CREATIVE_LABEL,
    }, data);

    dispatch(obj);
  },
};

export default CreativeLabel;
