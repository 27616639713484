import PropTypes from 'prop-types';
import React from 'react';
import Header from 'weborama-ui-react/Header';
import { isEmptyObject } from 'constants/Utils';
import Monaco from 'components/Monaco';
import { SetupScript } from 'actions';
import AppConstants from 'constants/AppConstants';
import AdPositionStore from 'stores/AdPositionStore';
import Message from 'weborama-ui-react/Message';
import Button from 'weborama-ui-react/Button';
import ContextStore from 'stores/ContextStore';
/**
 * SetupScriptForm
 */
class SetupScriptForm extends React.Component {
  /**
   * constructor
   * @date   2016-09-15
   * @param  {object}   props properties
   */
  constructor(props) {
    super(props);

    let setupScript = {};
    if ('setupScript' in props && !isEmptyObject(props.setupScript)) {
      ({ setupScript } = props);
    }

    this.state = {
      loading: false,
      setupScript,
      error: {},
    };

    this.handleBeforeElements = this.handleBeforeElements.bind(this);
    this.handleBeforeRender = this.handleBeforeRender.bind(this);
    this.handleAfterRender = this.handleAfterRender.bind(this);
    this.handleSaveSetupScript = this.handleSaveSetupScript.bind(this);
    this.onAdPositionStoreError = this.onAdPositionStoreError.bind(this);
    this.calculateSetupScriptState = this.calculateSetupScriptState.bind(this);

    AdPositionStore.addErrorListener(this.onAdPositionStoreError);
  }

  componentDidMount() {
    this.calculateSetupScriptState();
  }

  componentDidUpdate() {
    this.calculateSetupScriptState();
  }

  calculateSetupScriptState() {
    if (JSON.stringify(this.props.setupScript) !== JSON.stringify(this.state.setupScript)) {
      this.setState({
        setupScript: this.props.setupScript,
      });
    }
  }

  onAdPositionStoreError(error) {
    if (error.action === AppConstants.UPDATE_SETUP_SCRIPT) {
      this.setState({ loading: false, error });
    }
  }

  /**
   * handles before elements script
   * @date   2016-09-19
   * @param  {string}   value value
   */
  handleBeforeElements(value) {
    const { setupScript } = this.state;
    setupScript.before_ad_element_script = value;
    this.setState({ setupScript });
  }

  /**
   * Handles before render
   * @date   2016-09-19
   * @param  {string}   value value
   */
  handleBeforeRender(value) {
    const { setupScript } = this.state;
    setupScript.before_render_script = value;
    this.setState({ setupScript });
  }

  /**
   * Handles After render
   * @date   2016-09-19
   * @param  {string}   value value
   */
  handleAfterRender(value) {
    const { setupScript } = this.state;
    setupScript.after_render_script = value;
    this.setState({ setupScript });
  }

  /**
   * Saves setupscript
   * @date   2016-09-19
   */
  handleSaveSetupScript() {
    SetupScript.update({
      accountId: ContextStore.routingParams().accountId,
      folderId: ContextStore.routingParams().folderId,
      creativeId: ContextStore.routingParams().creativeId,
      creativeVersionId: this.props.creative.latest_creative_version_id,
      adPositionId: ContextStore.routingParams().adPositionId,
      beforeAdElementScript: this.state.setupScript.before_ad_element_script,
      beforeRenderScript: this.state.setupScript.before_render_script,
      afterRenderScript: this.state.setupScript.after_render_script,
    });
    this.setState({ loading: true });
  }

  /**
   * Renders setup script form
   * @return {array}   setup script form
   */
  render() {
    const beforeElement = this.state.setupScript.before_ad_element_script || '';
    const beforeRender = this.state.setupScript.before_render_script || '';
    const afterRender = this.state.setupScript.after_render_script || '';

    return (
      // Using the Semantic UI Form component is causing a visual error here
      // That's why it has been changed to this state
      <form>
        <Header h3>Before ad elements initialization</Header>
        <div>
          <Message info>
            {'Add JavaScript that should be executed before any ad element is initialized.'}
          </Message>
          <Monaco
            language="javascript"
            height="250"
            width="100%"
            theme="vs-dark"
            readOnly={false}
            code={beforeElement}
            onChange={this.handleBeforeElements}
          />
        </div>
        <Header h3>
          Before render
        </Header>
        <div>
          <Message info>
            {'Add JavaScript that should be executed after initialization of the ad elements,'
              + 'but before they are rendered'}
          </Message>
          <Monaco
            language="javascript"
            height="250"
            width="100%"
            readOnly={false}
            theme="vs-dark"
            code={beforeRender}
            onChange={this.handleBeforeRender}
          />
        </div>
        <Header h3>
          After render
        </Header>
        <div>
          <Message info>
            {'Add JavaScript that should be executed when the creative is rendered'}
          </Message>
          <Monaco
            code={afterRender}
            onChange={this.handleAfterRender}
            language="javascript"
            readOnly={false}
            theme="vs-dark"
            height="250"
            width="100%"
          />
        </div>
        <div style={{ marginTop: '30px' }}>
          <Button fluid primary loading={this.state.loading} onClick={this.handleSaveSetupScript}>
            {'Save'}
          </Button>
        </div>
        {
          !isEmptyObject(this.state.error)
          && <p className="error" style={{ overflowX: 'hidden' }}>{this.state.error.message}</p>
        }
      </form>
    );
  }
}

export default SetupScriptForm;

SetupScriptForm.propTypes = {
  setupScript: PropTypes.object,
  creative: PropTypes.object,
};
