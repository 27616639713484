import React from 'react';
import PropTypes from 'prop-types';
import Button from 'weborama-ui-react/Button';
import {
  getLocationDescriptor, parseComments, getDateTimeFromTimeStamp, datetimeSince, sortFnGenerator, getReadableFileSize
} from 'constants/Utils';
import Menu from 'weborama-ui-react/Menu';
import Dropdown from 'weborama-ui-react/Dropdown';
import Item from 'weborama-ui-react/Item';
import UIActions from 'actions/UIActions';
import Table from 'weborama-ui-react/Table';
import Divider from 'weborama-ui-react/Divider';
import Segment from 'weborama-ui-react/Segment';
import Paginator from 'components/Paginator';
import Header from 'weborama-ui-react/Header';
import Icon from 'weborama-ui-react/Icon';
import Message from 'weborama-ui-react/Message';
import Comment from 'components/UserComment';
import Input from 'weborama-ui-react/Input';
import { Grid, Column } from 'weborama-ui-react/Grid';
import SortByButton from 'components/SortByButton';
import SortByItem from 'components/SortByButton/SortByItem';
import { ContextStore } from 'stores';
import PushLiveButton from '../PushLiveButton';
import Creative from '../../actions/Creative';

class SelectCreative extends React.Component {

  /**
   * Constructor
   */
  constructor(props) {
    super(props);

    this.state = {
      paginator: {
        page: 1,
      },
      maxListItems: 12,
      filter: '',
      sortByItems: [
        new SortByItem('Name', true),
        new SortByItem('Creative id', true),
        new SortByItem('Creation date', false),
        new SortByItem('Update date', true),
      ],
      creatives: props.creatives,
      activeSortBy: new SortByItem('Creation date', false), // Default 'sort by' selection

    };
    this.handleSelectCreative = this.handleSelectCreative.bind(this);
    this.sortCreativesOnClick = this.sortCreativesOnClick.bind(this);

  }

  componentDidUpdate() {
    if (this.props.creatives.length > 0) {
      if (JSON.stringify(this.props.creatives) !== JSON.stringify(this.state.creatives)) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ paginator: { page: 1 }, creatives: this.props.creatives, }, () => {
          if (this.state.activeSortBy) {
            this.sortCreativesUnchanged(this.state.activeSortBy);
          }
        });
      }
    } else if (this.state.creatives.length !== 0) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ paginator: { page: 1 }, creatives: [], });
    }
  }

  /**
   * Handles selecting creative
   * @param {Number} creativeId Creative id
   */
  handleSelectCreative(creativeId) {
    const link = getLocationDescriptor('creative', creativeId, true);
    ContextStore.router().push(link);
    ContextStore.router().push(link); // Pushing it twice will work when getChildContext is removed at App
  }

  /**
   * Perform sorting, then invert ascending/descending
   * @param {Class} sortByItem single SortByItem class with sorting information
   */
  sortCreativesOnClick(sortByItem) {

    const { sortByItems } = this.state;
    const { creatives } = this.state;

    this.resolveSortByType(creatives, sortByItem);

    this.setState({ creatives });

    // Find the sortByItem used for sorting and invert its acending value
    for (let i = 0; i < sortByItems.length; i++) {
      if (sortByItems[i].label === sortByItem.label) {

        const currentSortByItem = new SortByItem(sortByItems[i].label, sortByItems[i].ascending);

        // Why do we have this variable and why are we assigning it to the activeSortBy?
        // When the array item is modified, the ascending position is set to what it's supposed to be when it's clicked
        // But we want the current version to be stored in the state as activeSortBy!

        // Why are we creating a new class instance here?
        // currentSortByItem = sortByItems[i] is jsut a reference to sortByItems[i]
        // In this state, when we change the ascending value of sortByItems[i], it affects currentSortByItem as well

        sortByItems[i].setAscending(!sortByItem.ascending);
        this.setState({ sortByItems, activeSortBy: currentSortByItem });
        break; // no need to continue the loop if we have what we want
      }
    }
  }

  /**
   * Perform sorting, but don't invert ascending/descending
   * @param {Class} sortByItem single SortByItem class with sorting information
   */
  sortCreativesUnchanged(sortByItem) {

    const { sortByItems } = this.state;
    const { creatives } = this.state;

    this.resolveSortByType(creatives, sortByItem);

    this.setState({ creatives, sortByItems });
  }

  /**
   * Identifies which parameter is used for sorting and sorts supplied object
   * @param {Object} objectToSort Object to apply sort function to
   * @param {Class} sortByItem single SortByItem class with sorting information
   */
  resolveSortByType(objectToSort, sortByItem) {

    if (sortByItem.label.toLowerCase() === 'name') {
      objectToSort.sort(sortFnGenerator('label', sortByItem.ascending));
    }

    if (sortByItem.label.toLowerCase() === 'creative id') {
      objectToSort.sort(sortFnGenerator('id', sortByItem.ascending));
    }

    if (sortByItem.label.toLowerCase() === 'creation date') {
      objectToSort.sort(sortFnGenerator('created_at', sortByItem.ascending));
    }

    if (sortByItem.label.toLowerCase() === 'update date') {
      objectToSort.sort(sortFnGenerator('updated_at', sortByItem.ascending));
    }
  }

  render() {
    // Apply filter if it's specified
    let creatives = this.state.creatives.filter((creative) => {
      if (creative.label.toLowerCase().indexOf(this.state.filter) > -1 || creative.id.toString().indexOf(this.state.filter) > -1) {
        return creative;
      }
      return false;
    });

    /*
    Calculate paginator settings
    */
    const paginatorSettings = {
      totalPages: Math.ceil(creatives.length / this.state.maxListItems),
      page: this.state.paginator.page,
    };

    creatives = creatives.slice(
      (0 + (this.state.paginator.page - 1)) * this.state.maxListItems,
      (((0 + (this.state.paginator.page - 1)) * this.state.maxListItems) + this.state.maxListItems),
    );

    const creativeRows = creatives.map((creative) => {
      const liveCreativeVersion = parseInt(creative.live_creative_version_id, 10);
      const latestCreativeVersion = parseInt(creative.latest_creative_version_id, 10);
      let icon = (
        <span
          data-tooltip="Creative is live!"
          data-position="left center"
          data-variation="mini"
          data-inverted=""
        >
          <Icon green circle />
        </span>
      );
      if (liveCreativeVersion === 0) {
        // Creative is not live
        icon = (
          <span
            data-position="left center"
            data-variation="mini"
            data-inverted=""
            data-tooltip="Creative does not have any linked insertions"
          >
            <Icon orange circle />
          </span>
        );
      } else if (liveCreativeVersion < latestCreativeVersion) {
        icon = (
          <span
            data-tooltip="Warning: this version has not been pushed live yet!"
            data-position="left center"
            data-variation="mini"
            data-inverted=""
          >
            <Icon red circle />
          </span>
        );
      }
      return (
        <tr key={creative.id}>
          <td style={{ overflow: 'visible' }}>{icon}</td>
          <td className="collapsing" style={!creative.warm_creative_id ? {color: 'lightgrey'} : {}}>{creative.creative_format_abbreviation}</td>
          <td style={{ overflow: 'visible' }}>
            {creative.warm_creative_id && (
            <Dropdown floating labeled menu isMenu>
              <Item>
                <Icon bordered dropdown />
              </Item>
              <Menu>
                <Item onClick={() => { UIActions.editCreative(creative); }}>
                  <Icon edit />
                  {'Edit creative'}
                </Item>
                <Item onClick={() => {
                  let url = getLocationDescriptor('creative', creative.id).pathname;
                  url = url.replace('cl', 'preview');
                  window.open(url, '_blank');
                }}
                >
                  <Icon eye />
                  {'Preview creative'}
                </Item>
                <Item onClick={() => { UIActions.downloadCreative(creative); }}>
                  <Icon download />
                  {'Download creative'}
                </Item>
                {
                  <PushLiveButton
                    onPushLive={() => this.props.onPushLive(creative.id)}
                    warning={parseInt(creative.latest_creative_version_id, 10) > parseInt(creative.live_creative_version_id, 10)}
                    success={parseInt(creative.latest_creative_version_id, 10) === parseInt(creative.live_creative_version_id, 10)}
                    disabled={parseInt(creative.live_creative_version_id, 10) === 0}
                    displayText="Push creative live"
                    inSelectCreative
                    toolTipPosition="bottom center"
                  />
                }
              </Menu>
            </Dropdown>
            )}
            {creative.warm_creative_id ? <a style={{cursor: 'pointer'}} onClick={() => this.handleSelectCreative(creative.id)}>{creative.label}</a>
              : (
                <span data-inverted="" data-tooltip={'This is a WCM creative'} data-position={'top left'}>
                  <a style={{color: 'lightgrey', cursor: 'pointer', pointerEvents: 'none'}}>{creative.label}</a>
                </span>
              ) }
          </td>
          <td style={!creative.warm_creative_id ? {color: 'lightgrey'} : {}} className="collapsing">
            {getDateTimeFromTimeStamp(creative.created_at?.replace(/-/g, '/'))}
          </td>
          <td style={!creative.warm_creative_id ? {color: 'lightgrey'} : {}} className="collapsing">
            {`${datetimeSince(new Date(`${creative.updated_at?.replace(/-/g, '/')} UTC`))}`}
          </td>
          <td style={!creative.warm_creative_id ? {color: 'lightgrey'} : {}} className="collapsing center aligned">
            {creative.number_of_insertions}
          </td>
          <td style={!creative.warm_creative_id ? {color: 'lightgrey'} : {}} className="collapsing">
            {getReadableFileSize(creative.weight)}
          </td>
          <td style={{overflow: 'visible'}} className="right aligned single line">
            <span data-tooltip={creative.size.map(size => `${size} `)} data-position="left center" data-inverted>
              <Icon bordered info inverted blue />
            </span>
          </td>
          {/* <td>{creative.creative_labels.map(cl => `[${cl.label}] `)}</td> */}
        </tr>
      );
    });

    return (
      <div>
        <Header h2>
          {'Creatives'}
        </Header>
        <Grid>
          <Column four wide>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <Button
                style={{ minWidth: '11vw'}}
                primary
                onClick={() => {
                  UIActions.addCreative({
                    folderId: ContextStore.routingParams().folderId, // We are creating a creative in the same folder we're in
                    folderLabel: this.props.folder.label
                  });
                }}
              >
                <Icon add />
                New Creative
              </Button>
              <Button
                style={{marginLeft: 60, minWidth: '11vw', zIndex: 100}}
                primary
                onClick={() => {
                  Creative.pushCreativesLive({
                    id: ContextStore.routingParams().folderId,
                    account_id: ContextStore.routingParams().accountId
                  });
                }}
              >
                <Icon upload />
                Update all creatives
              </Button>
            </div>
          </Column>
          <Column twelve wide right aligned>
            <div style={{ display: 'inline-block', paddingLeft: '16px' }}>
              <SortByButton sortByItems={this.state.sortByItems} sortFn={this.sortCreativesOnClick} />
            </div>
            <div style={{ display: 'inline-block', paddingLeft: '16px' }}>
              <Input
                placeHolder="Filter"
                left
                icon
                onChange={(filter) => { this.setState({ filter: filter.toLowerCase() }); }}
              >
                <Icon filter />
              </Input>
            </div>
          </Column>
        </Grid>
        {this.props.folder.comments
          && (
            <Message info tiny>
              <Comment tiny user={parseComments(this.props.folder.comments).user}>
                {parseComments(this.props.folder.comments).comment}
              </Comment>
            </Message>
          )
        }
        {!this.props.folder.comments
          && <Divider hidden />
        }
        <Segment padded>
          <Table
            fixed
            single
            line
            very
            basic
            unstackable
            selectable
          >
            <thead>
              <tr>
                <th className="one wide collapsing">Status</th>
                <th className="one wide collapsing">Type</th>
                <th className="seven wide">Name</th>
                <th className="two wide collapsing">Created</th>
                <th className="two wide collapsing">Updated</th>
                <th className="one wide">Ins.</th>
                <th className="two wide collapsing">Size</th>
                <th className="one wide right aligned collapsing single line">Adpos.</th>
              </tr>
            </thead>
            <tbody>
              {creativeRows}
            </tbody>
            <tfoot>
              <tr className="right aligned">
                <th className="center aligned" colSpan="5">
                  <Paginator
                    totalPages={paginatorSettings.totalPages}
                    page={paginatorSettings.page}
                    onPaginationChange={(page) => { this.setState({ paginator: { page } }); }}
                  />
                </th>
                <th className="right aligned single line" colSpan="3">
                  {`${this.state.creatives.length} ${this.state.creatives.length === 1 ? 'creative' : 'creatives'}`}
                </th>
              </tr>
            </tfoot>
          </Table>
        </Segment>
      </div>
    );
  }
}

export default SelectCreative;

SelectCreative.propTypes = {
  creatives: PropTypes.array,
  folder: PropTypes.object,
  onPushLive: PropTypes.func,
};
