import BaseStore from 'stores/BaseStore';
// import ServerConstants from 'constants/ServerConstants';
import TagConstants from 'constants/TagConstants';
import {TagManager, Campaign} from 'constants/ServerConstants';
import AppConstants from 'constants/AppConstants';
import {register} from 'dispatchers/AppDispatcher';
import 'jquery-file-download';
import Cookie from 'js-cookie';

const FileDownloadStore = Object.assign({}, BaseStore, {
  getZipFileFromToken(data) {
    const url = `${TagManager.getZipFromToken}?public_tag_token=${data.token}`;
    // TODO: Fix secure.
    Cookie.set('fileDownload', true, {secure: false});

    $.fileDownload(url, {
      successCallback: () => {
        setTimeout(() => { this.emitChange({action: TagConstants.GET_ZIP_FILE_FROM_TOKEN}); }, 1000);
      },
      failCallback: () => {
        // emit error here!
      },
    });
  },
  getTextFileFromToken(data) {
    const url = `${TagManager.getTextFromToken}?public_tag_token=${data.token}`;

    Cookie.set('fileDownload', true, {secure: false});

    $.fileDownload(url, {
      successCallback: () => {
        setTimeout(() => { this.emitChange({action: TagConstants.GET_TEXT_FILE_FROM_TOKEN}); }, 1000);
      },
      failCallback: () => {
        // emit error here!
      },
    });
  },
  downloadMediaPlan(data) {
    const url = `${Campaign.DOWNLOAD_MEDIA_PLAN}?token=${data.token}&X-Authorization=Bearer ${Cookie.get('warm_auth')}`; // eslint-disable-line max-len

    Cookie.set('fileDownload', true, {secure: false});

    $.fileDownload(url, {
      successCallback: () => {
        setTimeout(() => { this.emitChange({action: AppConstants.DOWNLOAD_MEDIA_PLAN}); }, 1000);
      },
      failCallback: () => {
        // emit error here!
      },
    });
  },
  downloadMediaPlanTemplate() {
    const url = `${Campaign.GET_MEDIA_PLAN_TEMPLATE}?X-Authorization=Bearer ${Cookie.get('warm_auth')}`;

    Cookie.set('fileDownload', true, {secure: false});

    $.fileDownload(url, {
      successCallback: () => {
        setTimeout(() => { this.emitChange({action: AppConstants.DOWNLOAD_MEDIA_PLAN_TEMPLATE}); }, 1000);
      },
      failCallback: () => {
        // emit error here!
      },
    });
  },
  dispatcherIndex: register((action) => {
    switch (action.actionType) {
      case TagConstants.GET_ZIP_FILE_FROM_TOKEN:
        FileDownloadStore.getZipFileFromToken({token: action.token});
        break;
      case TagConstants.GET_TEXT_FILE_FROM_TOKEN:
        FileDownloadStore.getTextFileFromToken({token: action.token});
        break;
      case AppConstants.DOWNLOAD_MEDIA_PLAN:
        FileDownloadStore.downloadMediaPlan({token: action.token});
        break;
      case AppConstants.DOWNLOAD_MEDIA_PLAN_TEMPLATE:
        FileDownloadStore.downloadMediaPlanTemplate();
        break;
      // no default
    }
    return true; // No errors. Needed by promise in Dispatcher.
  }),
});

export default FileDownloadStore;
