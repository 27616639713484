import PropTypes from 'prop-types';
import React from 'react';
import Form, { Field } from 'weborama-ui-react/Form';
import Input from 'weborama-ui-react/Input';
import Dropdown from 'weborama-ui-react/Dropdown';
import Item from 'weborama-ui-react/Item';
import Menu from 'weborama-ui-react/Menu';
import { Insertion } from 'actions';
import Accordion, { Title, Content } from 'weborama-ui-react/Accordion';
import Calendar from 'weborama-ui-react/Calendar';
import Button from 'weborama-ui-react/Button';
import { isEmptyObject, parseComments, makeUserComments } from 'constants/Utils';
import AppConstants from 'constants/AppConstants';
import { InsertionStore, ContextStore } from 'stores';
import AdSpaceSearch from 'components/AdSpaceSearch';
import Message from 'weborama-ui-react/Message';
import Icon from 'weborama-ui-react/Icon';
import InsertionLabels from '../InsertionLabels';
/**
 * Class insertion form
 */
class InsertionForm extends React.Component {
  /**
   * Maps props to state
   * @date   2016-11-14
   * @param  {Object}   props properties
   * @return {Object}         state object
   */
  static getStateFromProps(props) {
    if (!isEmptyObject(props.insertion)) {
      const landingUrl = props.insertion.landing_url;
      // TODO: Delivery format should be fetched from api,
      return {
        label: props.insertion.label,
        deliveryFormatId: props.insertion.delivery_format_id,
        adSpaceId: props.insertion.ad_space_id,
        adNetworkId: props.insertion.ad_network_id,
        landingUrl,
        comments: parseComments(props.insertion.comments).comment || '',
        priority: props.insertion.priority,
        startDate: props.insertion.start_date,
        endDate: props.insertion.end_date,
        selectedInsertionLabels: '',
        insertionLabels: props.insertion.insertion_labels,
        selectedInstertionType: [''],
        action: 'update',
        loading: false,
        error: {},
      };
    }
    return {
      action: 'add',
      label: '',
      deliveryFormatId: 1,
      adSpaceId: '',
      adNetworkId: '',
      comments: '',
      priority: 'standard',
      landingUrl: '',
      startDate: '',
      endDate: '',
      error: {},
      selectedInsertionLabels: '',
      selectedInstertionType: [''],
      insertionLabels: [],
      loading: false,
      adSpacesLoading: true,
    };
  }

  /**
   * constructor
   * @date   2016-11-14
   * @param  {Object}   props properties
   */
  constructor(props) {
    super(props);

    this.state = InsertionForm.getStateFromProps(props);

    this.handleCommentsChange = this.handleCommentsChange.bind(this);
    this.handleLabelChange = this.handleLabelChange.bind(this);
    this.handleUrlChange = this.handleUrlChange.bind(this);
    this.handleDeliveryTypeChange = this.handleDeliveryTypeChange.bind(this);
    this.handlePriorityChange = this.handlePriorityChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleSelectAdSpace = this.handleSelectAdSpace.bind(this);
    this.onInsertionStoreError = this.onInsertionStoreError.bind(this);
    this.isSubmitButtonDisabled = this.isSubmitButtonDisabled.bind(this);
    this.handleSelectionTypeChange = this.handleSelectionTypeChange.bind(this);

    InsertionStore.addErrorListener(this.onInsertionStoreError);
    this.endDateRef = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.toString() !== prevState.toString()) {
      return InsertionForm.getStateFromProps(nextProps);
    }
    return null;
  }

  /**
   * unregister store change callbacks
   * @date   2016-08-30
   */
  componentWillUnmount() {
    InsertionStore.removeErrorListener(this.onInsertionStoreError);
  }

  /**
   * Insertionstore error callback
   * @date   2016-10-18
   * @param  {Object}   error error Object
   */
  onInsertionStoreError(error) {
    if (error.action === AppConstants.ADD_INSERTION || error.action === AppConstants.UPDATE_INSERTION) {
      this.setState({ loading: false, error });
    }
  }

  /**
   * handles comments changes
   * @date   2016-08-30
   * @param  {object}   e event object
   */
  handleCommentsChange(e) {
    this.setState({ comments: e.target.value });
  }

  /**
   * Handles label changes
   * @date   2016-12-14
   * @param  {String}   value value
   */
  handleLabelChange(value) {
    this.setState({ label: value });
  }

  /**
   * Handles url changes
   * @param  {String}   newLandingUrl
   */
  handleUrlChange(newLandingUrl) {
    this.setState({ landingUrl: newLandingUrl });
  }

  /**
   * handles delivery type changes
   * @date   2016-08-30
   * @param  {string}   value delivery type
   */
  handleDeliveryTypeChange(value) {
    this.setState({ deliveryFormatId: value });
  }

  /**
   * Handles priority change
   * @date   2016-08-31
   * @param  {String}   value priority
   */
  handlePriorityChange(value) {
    this.setState({ priority: value });
  }

  /**
   * handles start date picker
   * @date   2016-08-29
   * @param  {String}   value selected date time
   */
  handleStartDateChange(value) {
    this.setState({ startDate: value.getTime() / 1000 });
    this.endDateRef.current.setStartDate(value);
  }

  /**
   * handles end date picker
   * @date   2016-08-29
   * @param  {String}   value selected date time
   */
  handleEndDateChange(value) {
    this.setState({ endDate: value.getTime() / 1000 });
  }

  /**
   * handles label selection type change
   * @date   2021-09-08
   * @param  {String}   value 'Programmatic' || 'Reserved'
   */
  handleSelectionTypeChange(value) {
    this.setState({ selectedInstertionType: [value] });
  }

  /**
   * handles Ad space selection event
   * @param {Object} adSpace ad space objecft
   */
  handleSelectAdSpace(adSpace) {
    if (adSpace == null) {
      this.setState({ adNetworkId: '', adSpaceId: '' });
      // Added here to make sure submit button is not clickable
      // in case the user selects an adspace, changes their mind and tries to
      // submit without selecting anything else. Works in conjunction with AdSpaceSearch
    } else {
      this.setState({ adNetworkId: adSpace.ad_network_id, adSpaceId: adSpace.id });
    }

  }

  /**
   * Calculate whether the submit button should be disabled or not
   */
  isSubmitButtonDisabled() {
    if (this.state.action === 'update') {
      if (this.state.label !== '' && this.state.adNetworkId !== '' && this.state.adSpaceId !== '') {
        return false;
      }
    }
    if (this.state.action === 'add') {
      if (this.state.label !== '' && this.state.adNetworkId !== '' && this.state.adSpaceId !== '' && this.state.selectedInstertionType[0] !== '') {
        return false;
      }
    }
    return true;
  }

  /**
   * Handles form submit
   * @date   2016-08-31
   */
  handleFormSubmit() {
    let comments = '';
    let submitInsertionLabels;
    const insertionLabels = this.state.selectedInsertionLabels;
    if (insertionLabels === '') {
      submitInsertionLabels = this.state.selectedInstertionType.map(singleItem => singleItem.trim());
    } else {
      submitInsertionLabels = this.state.selectedInstertionType.concat(insertionLabels.split(',')).map(singleItem => singleItem.trim());
    }
    if (this.state.action === 'add') {
      if (this.state.comments !== '') {
        comments = makeUserComments(ContextStore.profile().data.firstname, this.state.comments);
      }

      Insertion.add({
        accountId: ContextStore.routingParams().accountId,
        projectId: ContextStore.routingParams().projectId,
        campaignId: ContextStore.routingParams().campaignId,
        adSpaceId: this.state.adSpaceId,
        adNetworkId: this.state.adNetworkId,
        deliveryFormatId: this.state.deliveryFormatId,
        label: this.state.label,
        comments,
        landingUrl: this.state.landingUrl,
        priority: this.state.priority,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        insertionLabels: submitInsertionLabels
      });
    } else if (this.state.action === 'update') {
      if (this.state.comments !== parseComments(this.props.insertion.comments).comment) {
        if (this.state.comments !== '') {
          comments = makeUserComments(ContextStore.profile().data.firstname, this.state.comments);
        } else {
          ({ comments } = this.state);
        }
      } else { ({ comments } = this.props.insertion); }
      Insertion.update({
        insertionId: this.props.insertion.id,
        accountId: ContextStore.routingParams().accountId,
        projectId: ContextStore.routingParams().projectId,
        campaignId: ContextStore.routingParams().campaignId,
        adSpaceId: this.state.adSpaceId,
        adNetworkId: this.state.adNetworkId,
        deliveryFormatId: this.state.deliveryFormatId,
        label: this.state.label,
        comments,
        landingUrl: this.state.landingUrl,
        priority: this.state.priority,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        insertionLabels: submitInsertionLabels
      });
    }
    this.setState({ loading: true });
  }

  /**
   * Renders insertion form
   * @date   2016-08-30
   * @return {jsx}   insertion form
   */
  render() {
    // const adSpaceItems = this.state.adSpaces.map(item => (
    //   <Item key={item.id} value={item.id}>{item.label}</Item>
    // ));
    let errorMessage;
    if (!isEmptyObject(this.state.error)) {
      errorMessage = (
        <p className="error">{this.state.error.message}</p>
      );
    }

    return (
      <span>
        <Form>
          <Field label="Site/Offer" required>
            <AdSpaceSearch
              onSelect={this.handleSelectAdSpace}
              selectedAdSpaceId={this.state.adSpaceId.toString()}
              disabled={this.state.action === 'update'}
              siteOfferLabel={this.props.siteOfferLabel}
            />
          </Field>
          <Field label="Name" required>
            <Input
              placeHolder="Name"
              onChange={this.handleLabelChange}
              className="insertion-label"
              value={this.state.label}
            />
          </Field>
          <Field label="Delivery type" required>
            <Dropdown
              inline={false}
              selection
              defaultValue={this.state.deliveryFormatId}
              onChange={this.handleDeliveryTypeChange}
              disabled={this.state.action === 'update'}
            >
              <Icon dropdown />
              <Menu>
                <Item value={1}>Redirect</Item>
                <Item value={2}>Image</Item>
                <Item value={6}>Vast</Item>
              </Menu>
            </Dropdown>
          </Field>
          <Field label="insertion type" required>
            <Dropdown
              inline={false}
              selection
              defaultValue={this.state.selectedInstertionType}
              onChange={this.handleSelectionTypeChange}
              disabled={this.state.action === 'update'}
            >
              <Icon dropdown />
              <Menu>
                <Item value={'Programmatic'}>Programmatic</Item>
                <Item value={'Reserved'}>Reserved</Item>
              </Menu>
            </Dropdown>
          </Field>
          <Field label="Insertion labels">
            <InsertionLabels
              labels={this.state.insertionLabels}
              onChange={(event) => this.setState({ selectedInsertionLabels: event})}
            />
          </Field>
          <Field>
            <Accordion>
              <Title>{'Optional Settings'}</Title>
              <Content>
                <Field label="Comments">
                  <textarea
                    className="insertion-comments"
                    onChange={this.handleCommentsChange}
                    value={this.state.comments}
                    rows="2"
                  />
                </Field>
                <Field label="Default landing url">
                  <Input
                    className="insertion-landing-url"
                    placeHolder="Default landing url"
                    required
                    labeled
                    value={this.state.landingUrl}
                    onChange={this.handleUrlChange}
                  >
                  </Input>
                </Field>
                <Field label="Priority">
                  <Dropdown
                    inline={false}
                    selection
                    defaultValue={this.state.priority}
                    onChange={this.handlePriorityChange}
                  >
                    <Icon dropdown />
                    <Menu>
                      <Item value="standard">Standard</Item>
                      <Item value="high">High</Item>
                      <Item value="low">Low</Item>
                    </Menu>
                  </Dropdown>
                </Field>
                <Field label="Start date">
                  <Calendar
                    placeHolder="Start date"
                    value={this.state.startDate}
                    onChange={this.handleStartDateChange}
                  />
                </Field>
                <Field label="End date">
                  <Calendar
                    ref={this.endDateRef}
                    placeHolder="End date"
                    value={this.state.endDate}
                    onChange={this.handleEndDateChange}
                  />
                </Field>
              </Content>
            </Accordion>
          </Field>
          <Field>
            <Button
              fluid
              disabled={this.isSubmitButtonDisabled()}
              primary
              loading={this.state.loading}
              onClick={this.handleFormSubmit}
            >
              {`${this.state.action === 'update' ? 'Update' : 'Submit'}`}
            </Button>
          </Field>
          {errorMessage}
        </Form>
        {!isEmptyObject(this.state.error) && (
          <Message error>{this.state.error.message}</Message>
        )}
      </span>
    );
  }
}

export default InsertionForm;

InsertionForm.propTypes = {
  insertion: PropTypes.object,
  siteOfferLabel: PropTypes.string
};
