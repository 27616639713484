import {dispatch} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const CreativeType = {
  /**
   * Dispatches action to retrieve a list of creative types
   * @date   2016-04-16
   */
  list() {
    dispatch({actionType: AppConstants.LIST_CREATIVE_TYPES});
  },
};

export default CreativeType;
