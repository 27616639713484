import PropTypes from 'prop-types';
import React from 'react';
/**
 * AdPosition - tags
 * TODO: Reactify this! no append!
 */
class PreviewRichMedia extends React.Component {
  /**
   * constructor
   * @date   2016-10-04
   * @param  {Object}   props Properties
   */
  constructor(props) {
    super(props);

    const tag = props.tag || undefined;

    this.state = {
      tag,
    };

    this.bannerHolderRef = React.createRef();
  }

  /**
   * Async tag injection
   * @date   2016-10-04
   */
  componentDidMount() {
    const iframe = document.createElement('iframe');
    let {tag} = this.state;

    if (tag.indexOf('wcmpreviewer') > -1) {
      // Replace burst: 'never';
      tag = tag.replace('never', 'always');

      if (this.props.debug === true) {
        tag = tag.replace('debug: false', 'debug: true');
      }
    }

    $(this.bannerHolderRef.current).append(iframe);
    iframe.style.border = 0;
    iframe.style.width = `${this.props.size.width}px` || '1px';
    iframe.style.height = `${this.props.size.height}px` || '1px';
    iframe.contentDocument.write(tag);
  }

  /**
   * render
   * @date   2016-10-04
   * @return {array}   Node
   */
  render() {
    const bannerStyle = {
      // display: 'inline-block',
      // outline: '2px dotted blue',
      // margin: '8px',
      minWidth: `${this.props.size.width}px`,
      minHeight: `${this.props.size.height}px`,
    };

    return (
      <center>
        <div style={bannerStyle} ref={this.bannerHolderRef} />
      </center>
    );
  }
}

export default PreviewRichMedia;

PreviewRichMedia.propTypes = {
  size: PropTypes.object,
  tag: PropTypes.string,
  debug: PropTypes.bool,
};
