import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
// TODO import Input component
const Filter = ((props) => {
  const prompt = props.prompt ? props.prompt : 'Filter';
  const className = classnames({
    ui: true,
    fluid: props.fluid,
    attached: props.attached,
    small: props.small,
    left: true,
    icon: true,
    search: props.search,
    input: true,
    transparent: props.transparent,
  }, props.className);

  return (
    <div className={className}>
      <input
        value={props.value !== undefined ? props.value : undefined}
        // We only want value attribute when we want the input to be fully controlled
        type="text"
        className="prompt"
        placeholder={prompt}
        onChange={props.onChange}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
      />
      <i className="search link icon" />
    </div>
  );
});

export default Filter;

Filter.propTypes = {
  prompt: PropTypes.string,
  fluid: PropTypes.bool,
  attached: PropTypes.bool,
  small: PropTypes.bool,
  search: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  transparent: PropTypes.bool,
  value: PropTypes.string,
};
