import PropTypes from 'prop-types';
import React from 'react';
import Checkbox from 'weborama-ui-react/Checkbox';
import Dropdown from 'weborama-ui-react/Dropdown';
import Item from 'weborama-ui-react/Item';
import Button from 'weborama-ui-react/Button';
import Menu from 'weborama-ui-react/Menu';
import Form, {Field} from 'weborama-ui-react/Form';
import {getObjectInArray} from 'constants/Utils';
import {Token} from 'actions';
import ContextStore from 'stores/ContextStore';
import TagStore from 'stores/TagStore';
import Message from 'weborama-ui-react/Message';
import Segment from 'weborama-ui-react/Segment';
import Divider from 'weborama-ui-react/Divider';
import './GenerateTagForm.sass';
/**
 * Class generate tags form
 */
class GenerateTagPageForm extends React.Component {
  /**
   * Constructor
   * @date   2017-01-19
   * @param  {Object}   props Properties
   */
  constructor(props) {
    super(props);

    this.state = {
      adSpaces: props.adSpaces || [],
      insertions: props.insertions || [],
      selectedInsertions: [],
    };

    this.handleAdSpaceCheck = this.handleAdSpaceCheck.bind(this);
    this.handleAdSpaceUncheck = this.handleAdSpaceUncheck.bind(this);
    this.handleInsertionCheck = this.handleInsertionCheck.bind(this);
    this.handleInsertionUncheck = this.handleInsertionUncheck.bind(this);
    this.handleMraidChange = this.handleMraidChange.bind(this);
    this.handleAdserverChange = this.handleAdserverChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onTagStoreChange = this.onTagStoreChange.bind(this);
    this.onTagStoreError = this.onTagStoreError.bind(this);
    this.newWindow = null;

    TagStore.addChangeListener(this.onTagStoreChange);
    TagStore.addErrorListener(this.onTagStoreError);
  }

  /**
   * Remove change and error listeners
   * @memberOf GenerateTagsForm
   */
  componentWillUnmount() {
    TagStore.removeChangeListener(this.onTagStoreChange);
    TagStore.removeErrorListener(this.onTagStoreError);
  }

  /**
   * tag store change callback
   */
  onTagStoreChange() {
    const tokens = TagStore.getTokens();
    // We push the new token url to the newly opened window.
    // This construction is to avoid popup blockers.
    this.newWindow.location = `${window.location.origin}/public/tags/${encodeURIComponent(tokens.selection.token)}`;
  }

  /**
   * On Tag store error callback
   */
  onTagStoreError() {
    const errorMsg = 'An error occured generating this tag page. This window will close automatically';
    this.newWindow.document.body.append(errorMsg);
    setTimeout(() => { this.newWindow.close(); }, 3000);
  }

  /**
   * Handles ad space check
   * @date   2017-01-20
   * @param  {Number}   adSpaceId ad space id
   */
  handleAdSpaceCheck(adSpaceId) {
    this.state.insertions.forEach((insertion) => {
      if (parseInt(adSpaceId, 10) === parseInt(insertion.ad_space_id, 10)) {
        this.refs[`chk_${insertion.id}`].check();
      }
    });
  }

  /**
   * Handles ad space un check
   * @date   2017-01-20
   * @param  {Number}   adSpaceId ad space id
   */
  handleAdSpaceUncheck(adSpaceId) {
    this.state.insertions.forEach((insertion) => {
      if (parseInt(adSpaceId, 10) === parseInt(insertion.ad_space_id, 10)) {
        this.refs[`chk_${insertion.id}`].unCheck();
      }
    });
  }

  /**
   * Handles insertion check
   * @date   2017-01-20
   * @param  {Number}   insertionId insertion id
   */
  handleInsertionCheck(insertionId) {
    const insertion = getObjectInArray(this.state.insertions, 'id', insertionId);
    const selectedInsertions = this.state.selectedInsertions.slice();
    selectedInsertions.push(insertion);
    this.setState({selectedInsertions});
  }

  /**
   * handles insertion un check
   * @date   2017-01-20
   * @param  {Number}   insertionId insertion id
   */
  handleInsertionUncheck(insertionId) {
    let selectedInsertions = this.state.selectedInsertions.slice();
    selectedInsertions = selectedInsertions.filter((insertion) => {
      if (insertion.id !== insertionId) {
        return insertion;
      }
      return false;
    });
    this.setState({selectedInsertions});
  }

  /**
   * Handles mraid check
   * @date   2021-08-31
   * @param  {Number}   adSpaceId ad space id
   */
  handleMraidChange(adSpaceId) {
    const mraidAdspaces = this.state.adSpaces.slice();
    const mraidInsertions = this.state.insertions.slice();

    mraidAdspaces.forEach((adspace) => {
      if (parseInt(adSpaceId, 10) === parseInt(adspace.ad_space_fields.id, 10)) {
        if (adspace.mraid === 'false') {
          adspace.mraid = 'true'; // eslint-disable-line no-param-reassign
        } else {
          adspace.mraid = 'false'; // eslint-disable-line no-param-reassign
        }
      }
    });
    mraidInsertions.forEach((insertion) => {
      if (parseInt(adSpaceId, 10) === parseInt(insertion.ad_space_id, 10)) {
        if (insertion.mraid === 'false') {
          insertion.mraid = 'true'; // eslint-disable-line no-param-reassign
        } else {
          insertion.mraid = 'false'; // eslint-disable-line no-param-reassign
        }
      }
    });
  }

  /**
   * Handles mraid check
   * @date   2021-08-31
   * @param  {String}   adserver
   */
  handleAdserverChange(adserver) {
    const mraidAdspaces = this.state.adSpaces.slice();
    const mraidInsertions = this.state.insertions.slice();
    const adSpaceId = adserver.split('_')[0];
    const adServerLabel = adserver.split('_')[1];

    mraidAdspaces.forEach((adspace) => {
      if (parseInt(adSpaceId, 10) === parseInt(adspace.ad_space_fields.id, 10)) {
        adspace.adserver = adServerLabel; // eslint-disable-line no-param-reassign
      }
    });
    mraidInsertions.forEach((insertion) => {
      if (parseInt(adSpaceId, 10) === parseInt(insertion.ad_space_id, 10)) {
        insertion.adserver = adServerLabel; // eslint-disable-line no-param-reassign
      }
    });
  }

  /**
   * Submit
   * @date   2017-01-20
   */
  handleSubmit(data) { // eslint-disable-line no-unused-vars
    const selectedInsertions = this.state.selectedInsertions.slice();
    const insertions = [];
    selectedInsertions.forEach((insertion) => {
      const tempArray = [insertion.id, insertion.adserver, insertion.mraid];
      insertions.push(tempArray);
    });

    const insertionIds = this.state.selectedInsertions.map(insertion => insertion.id);

    this.newWindow = window.open('about:blank', '_blank');
    this.newWindow.document.body.append('Generating page.. one moment please..');

    Token.generate({
      accountId: ContextStore.routingParams().accountId,
      projectId: ContextStore.routingParams().projectId,
      campaignId: ContextStore.routingParams().campaignId,
      insertionIds: insertionIds,
      insertions: insertions,
    });
  }

  /**
   * Renders generate tags form
   * @date   2017-01-19
   * @return {Array}   nodes
   */
  render() {
    const adSpaces = this.state.adSpaces.map((adSpace) => {
      const insertions = this.state.insertions.map((insertion) => {
        if (parseInt(insertion.ad_space_id, 10) === parseInt(adSpace.ad_space_fields.id, 10)) {
          return (
            <div key={insertion.id} className="item">
              <Checkbox
                value={insertion.id}
                ref={`chk_${insertion.id}`}
                onChecked={this.handleInsertionCheck}
                onUnchecked={this.handleInsertionUncheck}
              >
                {insertion.label}
              </Checkbox>
            </div>
          );
        }
        return false;
      });
      return (
        <div key={adSpace.ad_space_fields.id}>
          <h3 className="ui top attached header tag-adspace-header">
            <Form>
              <div className="two fields">
                <Field>
                  <Checkbox
                    value={adSpace.ad_space_fields.id}
                    onChecked={this.handleAdSpaceCheck}
                    onUnchecked={this.handleAdSpaceUncheck}
                    className="ad-space-checkbox"
                  >
                    {adSpace.ad_space_fields.label}
                  </Checkbox>
                </Field>
                <Field>
                  <div className="headerToRight">
                    <Dropdown defaultValue={adSpace.ad_space_fields.id + '_default'} selection onChange={this.handleAdserverChange}>
                      <i className="dropdown icon" />
                      <Menu>
                        <Item value={adSpace.ad_space_fields.id + '_default'} key={adSpace.ad_space_fields.id + '_default'}>Default</Item>
                        <Item value={adSpace.ad_space_fields.id + '_xandr'} key={adSpace.ad_space_fields.id + '_xandr'}>Xandr</Item>
                        <Item value={adSpace.ad_space_fields.id + '_dfp'} key={adSpace.ad_space_fields.id + '_dfp'}>DFP</Item>
                        <Item value={adSpace.ad_space_fields.id + '_adform'} key={adSpace.ad_space_fields.id + '_adform'}>Adform</Item>
                        <Item value={adSpace.ad_space_fields.id + '_amazon'} key={adSpace.ad_space_fields.id + '_amazon'}>Amazon</Item>
                        <Item value={adSpace.ad_space_fields.id + '_dcm'} key={adSpace.ad_space_fields.id + '_dcm'}>DCM</Item>
                        <Item value={adSpace.ad_space_fields.id + '_improve'} key={adSpace.ad_space_fields.id + '_improve'}>Improve</Item>
                        <Item value={adSpace.ad_space_fields.id + '_mediamath'} key={adSpace.ad_space_fields.id + '_mediamath'}>Mediamath</Item>
                        <Item value={adSpace.ad_space_fields.id + '_p161'} key={adSpace.ad_space_fields.id + '_p161'}>P161</Item>
                      </Menu>
                    </Dropdown>
                    &nbsp;
                    <Checkbox
                      value={adSpace.ad_space_fields.id}
                      className="mraid-checkbox"
                      onChange={this.handleMraidChange}
                    >
                      {'Add mraid.js'}
                    </Checkbox>
                  </div>
                </Field>
              </div>
            </Form>
          </h3>
          <Segment attached key={adSpace.ad_space_fields.id}>
            <div className="list">
              {insertions}
            </div>
          </Segment>
        </div>
      );
    });
    return (
      <div>
        <Message info>
          {'Please make an selection of insertion to generate a tag page. The tag page is publicly available'}
        </Message>
        {adSpaces}
        <Divider hidden />
        <Button disabled={this.state.selectedInsertions.length === 0} primary fluid onClick={this.handleSubmit}>
          {'Submit'}
        </Button>
      </div>
    );
  }
}

export default GenerateTagPageForm;

GenerateTagPageForm.propTypes = {
  adSpaces: PropTypes.array,
  insertions: PropTypes.array,
};
