const publicWrapper = require('components/PublicWrapper');
const tagManagerModule = require('components/TagManagerModule');

module.exports = {
  path: 'public/tags(/:token)',
  name: 'TagManager',
  component: publicWrapper.default,
  indexRoute: {
    component: tagManagerModule.default,
  },
  childRoutes: [
    {
      path: '*',
      getComponent(nextState, cb) {
        require.ensure([], () => {
          cb(null, tagManagerModule.default);
        });
      },
    },
  ],
};

// module.exports = {
//   path: 'public/tags(/:token)',
//   getComponent(nextState, cb) {
//     require.ensure([], () => {
//       cb(null, require('components/TagManagerModule').default); // eslint-disable-line global-require
//     });
//   },
//   childRoutes: [
//     {
//       path: '*',
//     },
//   ],
// };
