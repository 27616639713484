import React from 'react';
import {User} from 'actions';
import { AuthenticationStore, ContextStore } from 'stores';
import {parseJWT} from 'constants/Utils';
import Button from 'weborama-ui-react/Button';
import './ProfileButton.sass';
/**
 * Class ProfileButton
 */
class ProfileButton extends React.Component {
  /**
   * Log ot
   * @date   2016-09-28
   */
  static handleSignOut() {
    User.logout();
  }

  /**
   * constructor
   * @date   2016-09-28
   */
  constructor() {
    super();

    const {token} = AuthenticationStore.getUser();
    const profile = parseJWT(token);

    this.state = {
      isSignedIn: !!profile,
    };

    if (profile && profile.data) {
      this.state.profile = profile.data.user || {data: {email: ''}}; // TODO: fix this
    }

    this.handleSignIn = this.handleSignIn.bind(this);
  }

  /**
   * Handles sign in
   * @date   2017-01-25
   */
  handleSignIn() {
    const location = ContextStore.router().location.pathname;
    ContextStore.router().replace({pathname: '/login', state: {nextPathname: location}});
  }

  /**
   * Renders Profile button
   * @date   2016-09-28 //<div className="profile-button
   * todo: ADD dropdown component (don't use menu in dropdown)
   * @return {[type]}   [description]
   */
  render() {
    if (this.state.isSignedIn) {
      return (
        <span className="profile-holder">
          <button
            className="circular ui icon button"
            type="button"
            data-tooltip="Sign out"
            data-position="bottom center"
            onClick={ProfileButton.handleSignOut}
          >
            <i className="sign out icon" />
          </button>
          {` ${this.state.profile.email}`}
        </span>
      );
    }
    return (
      <span className="profile-holder">
        <Button grey basic onClick={this.handleSignIn}>
          <i className="sign in icon" />
          {'Sign in'}
        </Button>
      </span>
    );
  }
}

export default ProfileButton;
