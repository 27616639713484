const localPreview = require('components/LocalPreview');

module.exports = {
  path: 'account(/:account)/preview',
  getComponent(nextState, cb) {
    require.ensure([], () => {
      cb(null, localPreview.default);
    });
  },
  childRoutes: [
    {
      path: '*',
    },
  ],
};
