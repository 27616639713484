import React from 'react';
import { register, unregister } from 'dispatchers/UIDispatcher';
import ContextStore from 'stores/ContextStore';
import UIConstants from 'constants/UIConstants';
import AdSpaceManager from 'components/AdSpaceManager';
import Modal from 'weborama-ui-react/Modal';
import FolderForm from 'components/FolderForm';
import CreativeForm from 'components/CreativeForm';
import DuplicateCreativeForm from 'components/DuplicateCreativeForm';
import InsertionForm from 'components/InsertionForm';
import AssignCampaignForm from 'components/AssignCampaignForm';
import DuplicateInsertionForm from 'components/DuplicateInsertionForm';
import SupportForm from 'components/SupportForm';
import SetupScriptForm from 'components/SetupScriptForm';
import ProjectForm from 'components/ProjectForm';
import CampaignForm from 'components/CampaignForm';
import AccountSelector from 'components/AccountSelector';
import VastSettingsForm from 'components/VastSettingsForm';
import {
  FolderStore,
  CreativeStore,
  ProjectStore,
  CampaignStore,
  InsertionStore,
  ThirdPartyTagStore,
  AdNetworkStore,
  AdSpaceStore,
  AdPositionStore,
  LandingUrlStore,
  AdElementStore
} from 'stores';
import RemoveItemForm from 'components/RemoveItemForm';
import TagManager from 'components/TagManager';
import AppConstants from 'constants/AppConstants';
import {
  Creative,
  Folder,
  AdPosition,
  Project,
  Campaign,
  Insertion,
  AdNetwork,
  AdSpace,
  Account,
  SetupScript,
} from 'actions';
import GenerateTagPageForm from 'components/GenerateTagPageForm';
import { getObjectInArray } from 'constants/Utils';
import AssignCreativeManager from 'components/AssignCreativeManager';
import ExtraClickForm from 'components/ExtraClickForm';
import SupportStore from 'stores/SupportStore';
import ThirdPartyTagForm from 'components/ThirdPartyTagForm';
import AdNetworkForm from 'components/AdNetworkForm';
import SiteOfferManager from '../SiteOfferManager';
import AdSpaceForm from 'components/AdSpaceForm';
import ClearAccountCacheForm from 'components/ClearAccountCacheForm';
import EasterEgg from 'components/EasterEgg';
import MoveAdPositionForm from 'components/MoveAdPositionForm';
import './index.scss';

/**
 * ModalControler
 */
class ModalController extends React.Component {
  /**
   * Constructor
   */
  constructor() {
    super();
    this.fluxId = '';
    this.state = {
      defaultSettings: {
        observeChanges: false,
        duration: 300,
        onHidden: () => {
          this.setState({
            modalHeader: '', innerModal: '', action: '',
          });
        },
        onDeny() {
          return true;
        },
      },
      action: '',
      modalHeader: '',
      modalSettings: {},
      innerModal: <span />,
      secondModal: null,
      secondModalHeader: '',
      secondModalSettings: {},
    };
    this.onStoreChange = this.onStoreChange.bind(this);
    this.manageAdNetworks = this.manageAdNetworks.bind(this);
    this.manageFolders = this.manageFolders.bind(this);
    this.manageCreatives = this.manageCreatives.bind(this);
    this.duplicateCreative = this.duplicateCreative.bind(this);
    this.reportBug = this.reportBug.bind(this);
    this.manageVastSettings = this.manageVastSettings.bind(this);
    this.getModalSettings = this.getModalSettings.bind(this);
    this.manageProjects = this.manageProjects.bind(this);
    this.manageCampaigns = this.manageCampaigns.bind(this);
    this.manageInsertions = this.manageInsertions.bind(this);
    this.handleExtraClicks = this.handleExtraClicks.bind(this);
    this.handleAdNetwork = this.handleAdNetwork.bind(this);
    this.listenEscapeKey = this.listenEscapeKey.bind(this);
    this.hide = this.hide.bind(this);

    AdElementStore.addChangeListener(this.onStoreChange);
    FolderStore.addChangeListener(this.onStoreChange);
    CreativeStore.addChangeListener(this.onStoreChange);
    ProjectStore.addChangeListener(this.onStoreChange);
    CampaignStore.addChangeListener(this.onStoreChange);
    InsertionStore.addChangeListener(this.onStoreChange);
    SupportStore.addChangeListener(this.onStoreChange);
    ThirdPartyTagStore.addChangeListener(this.onStoreChange);
    AdNetworkStore.addChangeListener(this.onStoreChange);
    AdSpaceStore.addChangeListener(this.onStoreChange);
    AdPositionStore.addChangeListener(this.onStoreChange);
    LandingUrlStore.addChangeListener(this.onStoreChange);

    this.modalRef = React.createRef();
    this.secondModalRef = React.createRef();
  }

  /**
   * Register store callbacks and ui actions
   */
  componentDidMount() {

    this.fluxId = register((action) => {
      switch (action.actionType) {
        case UIConstants.MANAGE_AD_NETWORKS:
          this.manageAdNetworks(action);
          break;
        case UIConstants.EDIT_FOLDER:
        case UIConstants.ADD_FOLDER:
          this.manageFolders(action);
          break;
        case UIConstants.EDIT_CREATIVE:
        case UIConstants.ADD_CREATIVE:
          this.manageCreatives(action);
          break;
        case UIConstants.DOWNLOAD_CREATIVE:
          Creative.download({
            accountId: ContextStore.routingParams().accountId,
            folderId: action.creative.folder_id,
            creativeId: action.creative.id,
          });
          break;
        case UIConstants.DUPLICATE_CREATIVE:
          this.duplicateCreative(action);
          break;
        case UIConstants.EDIT_PROJECT:
        case UIConstants.ADD_PROJECT:
          this.manageProjects(action);
          break;
        case UIConstants.EDIT_CAMPAIGN:
        case UIConstants.ADD_CAMPAIGN:
          this.manageCampaigns(action);
          break;
        case UIConstants.EDIT_INSERTION:
        case UIConstants.ADD_INSERTION:
          this.manageInsertions(action);
          break;
        case UIConstants.DUPLICATE_INSERTION:
          this.duplicateInsertion(action);
          break;
        case UIConstants.OPEN_SITE_OFFER_MODAL:
          this.openSiteOfferModal(action);
          break;
        case UIConstants.ASSIGN_CAMPAIGN:
          this.assignCampaign(action);
          break;
        case UIConstants.ASSIGN_CREATIVE:
          this.assignCreative(action);
          break;
        case UIConstants.REPORT_BUG:
          this.reportBug(action);
          break;
        case UIConstants.MANAGE_VAST_SETTINGS:
          this.manageVastSettings(action);
          break;
        case UIConstants.VIEW_TAGS:
          this.manageViewTags(action);
          break;
        case UIConstants.GENERATE_TAGS:
          this.generateTagPage(action);
          break;
        case UIConstants.REMOVE_FOLDER:
          this.removeFolder(action);
          break;
        case UIConstants.REMOVE_CREATIVE:
          this.removeCreative(action);
          break;
        case UIConstants.REMOVE_ADPOSITION:
          this.removeAdPosition(action);
          break;
        case UIConstants.REMOVE_PROJECT:
          this.removeProject(action);
          break;
        case UIConstants.REMOVE_CAMPAIGN:
          this.removeCampaign(action);
          break;
        case UIConstants.REMOVE_INSERTION:
          this.removeInsertion(action);
          break;
        case UIConstants.ADD_EXTRA_CLICK:
          this.handleExtraClicks(action);
          break;
        case UIConstants.EDIT_TRACKER:
        case UIConstants.ADD_TRACKER:
          this.handleTracker(action);
          break;
        case UIConstants.EASTER_EGG:
          this.handleEasterEgg(action);
          break;
        case UIConstants.EDIT_AD_NETWORK:
        case UIConstants.ADD_AD_NETWORK:
          this.handleAdNetwork(action);
          break;
        case UIConstants.REMOVE_AD_NETWORK:
          this.setState({
            action: action.actionType,
            secondModalSettings: this.getModalSettings({
              tiny: true,
              onApprove: () => {
                AdNetwork.remove({ accountId: ContextStore.routingParams().accountId, adNetworkId: action.adNetwork.id });
              },
              allowMultiple: false,
              onHidden: () => { this.modalRef.current.show(this.state.modalSettings); this.setState({ secondModal: null }); },
            }),
            secondModalHeader: 'Are you sure to remove this ad network?',
            secondModal: <RemoveItemForm header={`${action.adNetwork.id} - ${action.adNetwork.label}`} />,
          });
          break;
        case UIConstants.EDIT_AD_SPACE:
        case UIConstants.ADD_AD_SPACE:
          this.handleAdSpace(action);
          break;
        case UIConstants.REMOVE_AD_SPACE:
          this.setState({
            action: action.actionType,
            secondModalSettings: this.getModalSettings({
              tiny: true,
              onApprove: () => {
                AdSpace.remove({ accountId: ContextStore.routingParams().accountId, adSpaceId: action.adSpace.id });
              },
              allowMultiple: false,
              onHidden: () => { this.modalRef.current.show(this.state.modalSettings); this.setState({ secondModal: null }); },
            }),
            secondModalHeader: 'Are you sure to remove this site / offer?',
            secondModal: <RemoveItemForm header={`${action.adSpace.id} - ${action.adSpace.label}`} />,
          });
          break;
        case UIConstants.CLEAR_ACCOUNT_CACHE:
          this.setState({
            action: action.actionType,
            modalHeader: 'Are you sure you want to remove all cache for this account?',
            modalSettings: this.getModalSettings({ small: true, onApprove() { Account.clearCache(action.accountId); } }),
            innerModal: <ClearAccountCacheForm />,
          });
          break;
        case UIConstants.RECREATE_SETUP_SCRIPT:
          this.setState({
            action: action.actionType,
            modalSettings: this.getModalSettings({
              tiny: true,
              onDeny() {
                return true;
              },
              onApprove: () => {
                SetupScript.update({
                  accountId: action.accountId,
                  folderId: action.folderId,
                  creativeId: action.creativeId,
                  creativeVersionId: action.creativeVersionId,
                  adPositionId: action.adPositionId,
                  beforeAdElementScript: '',
                  beforeRenderScript: '',
                  afterRenderScript: '',
                });
              },
            }),
            innerModal: <RemoveItemForm header="Are you sure to recreate the setup script?" />,
          });
          break;
        case UIConstants.EDIT_SETUP_SCRIPT:
          this.setState({
            action: action.actionType,
            innerModal: <div style={{ minHeight: '1500px' }} />,
            modalSettings: this.getModalSettings({
              onVisible: () => {
                this.setState({
                  innerModal: <SetupScriptForm
                    setupScript={action.setupScript}
                    creative={action.creative}
                  />,
                });
              },
            }),
          });
          break;
        case UIConstants.CHANGE_ACCOUNT:
          this.setState({
            action: action.actionType,
            innerModal: <AccountSelector />,
          });
          break;
        case UIConstants.MOVE_ADELEMENTS:
          this.setState({
            action: action.actionType,
            innerModal: (
              <MoveAdPositionForm
                accountId={action.accountId}
                adElementIds={action.adElementIds}
                adElementRoleId={action.adElementRoleId}
                creativeId={action.creativeId}
                creativeVersionId={action.creativeVersionId}
                adPositions={action.adPositions}
                selectedAdElements={action.selectedAdElements}
              />
            ),
            modalHeader: 'Move ad element(s)',
            modalSettings: this.getModalSettings({
              closable: false,
              autofocus: true,
            }),
          });
          break;
        // no default
      }
    });
  }

  /**
   * Show modal
   * @param {Object} props previous props
   * @param {Object} state previous state
   */
  componentDidUpdate(props, state) {
    if (this.state.action !== '' && this.state.action !== state.action) {
      this.modalRef.current.show(this.state.modalSettings);
    }
    if (this.state.secondModal !== null && this.state.secondModal !== state.secondModal) {
      this.modalRef.current.hide({
        onHidden: () => {
          this.secondModalRef.current.show(this.state.secondModalSettings);
        },
      });
    }
  }

  /**
   * Unregister flux
   */
  componentWillUnmount() {
    unregister(this.fluxId);
    AdElementStore.removeChangeListener(this.onStoreChange);
    FolderStore.removeChangeListener(this.onStoreChange);
    CreativeStore.removeChangeListener(this.onStoreChange);
    ProjectStore.removeChangeListener(this.onStoreChange);
    CampaignStore.removeChangeListener(this.onStoreChange);
    InsertionStore.removeChangeListener(this.onStoreChange);
    SupportStore.removeChangeListener(this.onStoreChange);
    ThirdPartyTagStore.removeChangeListener(this.onStoreChange);
    AdNetworkStore.removeChangeListener(this.onStoreChange);
    AdSpaceStore.removeChangeListener(this.onStoreChange);
    LandingUrlStore.removeChangeListener(this.onStoreChange);
  }

  /**
   * On Store change callback
   * @param {Object} data data object
   */
  onStoreChange(data) {
    let nextAction;
    switch (data.action) {
      case AppConstants.UPDATE_PROJECT:
      case AppConstants.ADD_PROJECT:
        if (data.addCampaign) {
          nextAction = AppConstants.ADD_CAMPAIGN;
        }
      // falls through
      case AppConstants.UPDATE_CAMPAIGN:
      case AppConstants.ADD_CAMPAIGN:
        if (data.addInsertion) {
          nextAction = AppConstants.ADD_INSERTION;
        }
      // falls through
      case AppConstants.UPDATE_SETUP_SCRIPT:
      case AppConstants.CREATE_TICKET:
      case AppConstants.UPDATE_INSERTION:
      case AppConstants.ADD_INSERTION:
      case AppConstants.DUPLICATE_INSERTION:
      case AppConstants.UPDATE_FOLDER:
      case AppConstants.ADD_FOLDER:
        if (data.addCreative) {
          nextAction = AppConstants.ADD_CREATIVE;
        }
      // falls through
      case AppConstants.ADD_THIRD_PARTY_TAG:
      case AppConstants.UPDATE_THIRD_PARTY_TAG:
      case AppConstants.UPDATE_CREATIVE:
      case AppConstants.ADD_CREATIVE:
        this.hide(nextAction);
        break;
      case AppConstants.DUPLICATE_CREATIVE:
        // Single modal is open in this instance
        this.hide();
        break;
      case AppConstants.ADD_SITE_OFFER:
        // Single modal is open in this instance (not a secondary modal), so hide it
        this.modalRef.current.hide();
        break;
      case AppConstants.ADD_LANDING_URL:
        // Hide the open modal for adding a new landing url
        this.hide();
        break;
      case AppConstants.ADD_MULTI_LANDING_URL:
        // Hide the open modal for adding a new landing url
        this.hide();
        break;
      case AppConstants.UPDATE_MULTIPLE_ROLE_ADELEMENT:
      case AppConstants.NEW_ADPOS_WITH_ELEMENTS:
      case AppConstants.NEW_MULTIPLE_ADPOS_WITH_ELEMENTS:
        // Hide the open modal after updating ad element roles
        this.hide();
        break;
      case AppConstants.ADD_ADSPACE:
      case AppConstants.UPDATE_ADSPACE:
      case AppConstants.ADD_ADNETWORK:
      case AppConstants.UPDATE_ADNETWORK:
        this.secondModalRef.current.hide({
          onHidden: () => { this.modalRef.current.show(this.state.modalSettings); this.setState({ secondModal: null }); },
        });
        break;
      // no default
    }
  }

  /**
   * Returns modal settings,
   * @param {Object} settings settings object
   */
  getModalSettings(settings) {
    return Object.assign({}, this.state.defaultSettings, settings);
  }

  /**
   * Hides open modals
   */
  hide(nextAction) {
    this.modalRef.current.hide({
      onHidden: () => {
        this.setState({
          modalHeader: '', innerModal: '', action: '',
        });
        if (nextAction) {
          if (nextAction === AppConstants.ADD_CREATIVE) {
            this.manageCreatives({ actionType: nextAction });
          }
          if (nextAction === AppConstants.ADD_CAMPAIGN) {
            this.manageCampaigns({ actionType: nextAction });
          }
          if (nextAction === AppConstants.ADD_INSERTION) {
            this.manageInsertions({ actionType: nextAction });
          }
        }
      },
    });
  }

  /**
   * Handles easter egg.
   * @param {Object} action action
   */
  handleEasterEgg(action) {
    this.setState({
      action: action.actionType,
      modalHeader: 'WEBORAMA HARLEM SHAKE - 2015',
      modalSettings: this.getModalSettings({}),
      innerModal: <EasterEgg />,
    });
  }

  /**
   * A function that listens the escape key and closes the modal if it's pressed.
   * @param {Object} event keydown event
   */
  listenEscapeKey(event) {

    if (event.keyCode === 27 || event.key === 'Escape') {

      this.modalRef.current.hide();

      this.setState({
        modalHeader: '', innerModal: '', action: '',
      });
    }

    document.removeEventListener('keydown', this.listenEscapeKey, true);
    // We don't need to listen anymore, popup is closed, remove the event listener
  }

  /**
   * Manage Ad Networks
   * @param {Object} action action
   */
  manageAdNetworks(action) {
    this.setState({
      action: action.actionType,
      modalHeader: ' ',
      modalSettings: this.getModalSettings({}),
      innerModal: <AdSpaceManager />,
    });
  }

  /**
   * Handles ad network
   */
  handleAdNetwork(action) {
    this.setState({
      secondModalHeader: ' ',
      secondModal: <AdNetworkForm adNetwork={action.adNetwork} />,
      secondModalSettings: {
        allowMultiple: false,
        onHidden: () => { this.modalRef.current.show(this.state.modalSettings); this.setState({ secondModal: null }); },
      },
    });
  }

  /**
   * Handles add/edit ad space
   * @param {Object} action action object
   */
  handleAdSpace(action) {
    this.setState({
      secondModalHeader: ' ',
      secondModal: <AdSpaceForm adSpace={action.adSpace} adNetwork={action.adNetwork} />,
      secondModalSettings: {
        allowMultiple: false,
        onHidden: () => { this.modalRef.current.show(this.state.modalSettings); this.setState({ secondModal: null }); },
      },
    });
  }

  /**
   * Manage folder form
   * @param {Object} action action
   */
  manageFolders(action) {
    const modalHeader = (action.actionType === UIConstants.EDIT_FOLDER) ? 'Update folder' : 'New folder';
    this.setState({
      action: action.actionType,
      modalHeader,
      modalSettings: this.getModalSettings({
        small: true,
        closable: false,
        onVisible: () => {
          document.addEventListener('keydown', this.listenEscapeKey, true);
        },
      }),
      innerModal: <FolderForm folder={action.folder} />,
    });
  }

  /**
   * Manage project form
   * @param {Object} actions action
   */
  manageProjects(action) {
    const modalHeader = (action.actionType === UIConstants.EDIT_PROJECT) ? 'Update project' : 'New project';
    this.setState({
      action: action.actionType,
      modalHeader,
      modalSettings: this.getModalSettings({
        small: true,
        closable: false,
        onVisible: () => {
          document.addEventListener('keydown', this.listenEscapeKey, true);
        },
      }),
      innerModal: <ProjectForm project={action.project} />,
    });
  }

  /**
   * Manages campaign
   * @param {Object} action action Object
   */
  manageCampaigns(action) {

    let modalHeader;
    let projectId;

    if (action.actionType === UIConstants.EDIT_CAMPAIGN) {
      modalHeader = 'Update campaign';
    } else {
      modalHeader = action.project ? `New campaign (${action.project.projectLabel})` : 'New campaign';
    }

    if (!action.project) {
      projectId = ContextStore.routingParams().projectId;
      // Above is for situations where projectId is not supplied (when creating completely new project and following up with a campaign creation)
    } else {
      projectId = action.project.projectId;
    }

    this.setState({
      action: action.actionType,
      modalHeader,
      modalSettings: this.getModalSettings({
        small: true,
        closable: false,
        onVisible: () => {
          document.addEventListener('keydown', this.listenEscapeKey, true);
        },
      }),
      innerModal: <CampaignForm projectId={projectId} campaign={action.campaign} />,
    });
  }

  /**
   * Manages insertions
   * @param {Object} action action
   */
  manageInsertions(action) {
    const modalHeader = (action.actionType === UIConstants.EDIT_INSERTION) ? 'Update insertion' : 'New insertion';

    this.setState({
      action: action.actionType,
      modalHeader,
      modalSettings: this.getModalSettings({
        small: true,
        closable: false,
        autofocus: false,
        onVisible: () => {
          document.addEventListener('keydown', this.listenEscapeKey, true);
        },
      }),
      innerModal: <InsertionForm siteOfferLabel={action.siteOfferLabel} insertion={action.insertion} />,
      // Note that siteOfferLabel is only sent when it's pre-defined. So it's optional
    });
  }

  /**
   * handles duplicate insertion
   * @param {Object} action action
   */
  duplicateInsertion(action) {
    this.setState({
      action: action.actionType,
      modalHeader: 'Duplicate insertion',
      modalSettings: this.getModalSettings({
        small: true,
        closable: false,
        onVisible: () => {
          document.addEventListener('keydown', this.listenEscapeKey, true);
        },
      }),
      innerModal: <DuplicateInsertionForm insertion={action.insertion} />,
    });
  }

  /**
  * Modal that houses the new site/offer form
  * @param {Object} action action
  */
  openSiteOfferModal(action) {
    this.setState({
      action: action.actionType,
      modalHeader: 'New Site/Offer',
      modalSettings: this.getModalSettings({ small: true }),
      innerModal: <SiteOfferManager />,
    });
  }

  /**
   * Manages creative
   * @param {Object} action action Object
   */
  manageCreatives(action) {

    let modalHeader;
    let folderId;

    if (action.actionType === UIConstants.EDIT_CREATIVE) {
      modalHeader = 'Update creative';
    } else if (action.data) {
      modalHeader = `New creative (${action.data.folderLabel})`;
    } else {
      modalHeader = 'New creative';
    }

    if (!action.data) {
      folderId = ContextStore.routingParams().folderId;
      // Above is for situations where folderId is not supplied (when creating completely new folder and following up with a creative creation)
    } else {
      folderId = action.data.folderId;
    }

    this.setState({
      action: action.actionType,
      modalHeader,
      modalSettings: this.getModalSettings({
        small: true,
        closable: false,
        onVisible: () => {
          document.addEventListener('keydown', this.listenEscapeKey, true);
        },
      }),
      innerModal: <CreativeForm folderId={folderId} creative={action.creative} />,
    });
  }

  /**
   * handles duplicate creative form
   * @param {action} action action
   */
  duplicateCreative(action) {
    this.setState({
      action: action.actionType,
      modalHeader: 'Duplicate creative',
      modalSettings: this.getModalSettings({
        small: true,
        closable: false,
        onVisible: () => {
          document.addEventListener('keydown', this.listenEscapeKey, true);
        },
      }),
      innerModal: <DuplicateCreativeForm creative={action.creative} />,
    });
  }

  /**
   * Show report a bug form
   * @param {Object} action action
   */
  reportBug(action) {
    this.setState({
      action: action.actionType,
      modalHeader: 'Report a bug',
      modalSettings: this.getModalSettings({ small: true }),
      innerModal: <SupportForm />,
    });
  }

  /**
   * Shows assign campaign form
   * @param {object} action action
   */
  assignCampaign(action) {
    this.setState({
      action: action.actionType,
      modalHeader: 'Link insertions to creative',
      modalSettings: this.getModalSettings({
        closable: false,
        onVisible: () => {
          document.addEventListener('keydown', this.listenEscapeKey, true);
        },
      }),
      innerModal: <AssignCampaignForm />,
    });
  }

  /**
   * handles assign creative to insertions
   * @param {Object} action action
   */
  assignCreative(action) {
    this.setState({
      action: action.actionType,
      modalHeader: `Assign creative(s) to ${action.insertion.label.toUpperCase()}`,
      modalSettings: this.getModalSettings({ large: true }),
      innerModal: <AssignCreativeManager
        insertion={action.insertion}
        adSpace={action.adSpace}
        creatives={action.creatives}
      />,
    });
  }

  /**
   * Handles vast settings form
   * @param {Object} action aciton object
   */
  manageVastSettings(action) {
    this.setState({
      action: action.actionType,
      modalHeader: 'Vast settings',
      modalSettings: this.getModalSettings({ large: true }),
      innerModal: <VastSettingsForm creative={action.creative} />,
    });
  }

  /**
   * handles tag modal
   * @param {Object} action action
   */
  manageViewTags(action) {
    this.setState({
      action: action.actionType,
      modalHeader: action.insertion.label,
      modalSettings: this.getModalSettings({ large: true }),
      innerModal: <TagManager insertion={action.insertion} />,
    });
  }

  /**
   * generate tag page form
   * @param {Object} action action
   * TODO: Pass adpsaces and insertions
   */
  generateTagPage(action) {
    this.setState({
      action: action.actionType,
      modalHeader: 'Create public tag page',
      modalSettings: this.getModalSettings({ large: true }),
      innerModal: <GenerateTagPageForm
        insertions={action.insertions}
        adSpaces={action.adSpaces}
        campaign={action.campaign}
      />,
    });
  }

  /**
   * Handles extra click form
   * @param {Object} action action
   */
  handleExtraClicks(action) {
    const labelNumber = action.assignedAdPosition.landing_urls.length + 1;
    this.setState({
      action: action.actionType,
      modalHeader: action.assignedAdPosition.adposition.creative.label,
      modalSettings: this.getModalSettings({ small: true }),
      innerModal: <ExtraClickForm labelNumber={labelNumber} assignedAdPosition={action.assignedAdPosition} />,
    });
  }

  /**
   * shows third party tag form
   * @param {Object} action action object
   */
  handleTracker(action) {
    this.setState({
      action: action.actionType,
      modalHeader: 'Add third party tracker',
      modalSettings: this.getModalSettings(),
      innerModal: <ThirdPartyTagForm
        thirdPartyTag={action.tracker}
        campaignAdSpace={action.campaignAdSpace}
        insertion={action.insertion}
        creative={action.creative}
        assignedAdPosition={action.assignedAdPosition}
      />,
    });
  }

  /**
   * remove folder form
   * @param {Object} action action
   */
  removeFolder(action) {
    this.setState({
      action: action.actionType,
      modalSettings: this.getModalSettings({
        tiny: true,
        onApprove: () => {
          Folder.remove({ accountId: ContextStore.routingParams().accountId, folderId: action.folder.folderId });
        },
      }),
      modalHeader: 'Are you sure to remove this folder?',
      innerModal: <RemoveItemForm header={`${action.folder.folderId} - ${action.folder.folderLabel}`} />,
    });
  }

  /**
   * Removes creative
   * @param {Object} action action
   */
  removeCreative(action) {
    this.setState({
      action: action.actionType,
      modalSettings: this.getModalSettings({
        tiny: true,
        onApprove: () => {
          Creative.remove({
            accountId: ContextStore.routingParams().accountId,
            folderId: ContextStore.routingParams().folderId,
            creativeId: action.creative.id,
            label: action.creative.label,
          });
        },
      }),
      modalHeader: 'Are you sure to remove this creative?',
      innerModal: <RemoveItemForm header={`${action.creative.id} - ${action.creative.label}`} />,
    });
  }

  /**
   * Removes ad position
   * @param {Object} action action
   */
  removeAdPosition(action) {
    const size = getObjectInArray(ContextStore.sizes(), 'id', action.adposition.size_id);
    this.setState({
      action: action.actionType,
      modalSettings: this.getModalSettings({
        tiny: true,
        onApprove: () => {
          AdPosition.remove({
            accountId: ContextStore.routingParams().accountId,
            folderId: ContextStore.routingParams().folderId,
            creativeId: ContextStore.routingParams().creativeId,
            creativeVersionId: action.adposition.creative_version_id,
            adPositionId: action.adposition.id,
          });
        },
      }),
      modalHeader: 'Are you sure to remove this ad position?',
      innerModal: <RemoveItemForm header={`${action.adposition.id} - ${size.label}`} />,
    });
  }

  /**
   * Removes project
   * @param {Object} action action
   */
  removeProject(action) {
    this.setState({
      action: action.actionType,
      modalSettings: this.getModalSettings({
        tiny: true,
        onApprove: () => {
          Project.remove({ accountId: ContextStore.routingParams().accountId, projectId: action.project.projectId });
        },
      }),
      modalHeader: 'Are you sure to remove this project?',
      innerModal: <RemoveItemForm header={`${action.project.projectId} - ${action.project.projectLabel}`} />,
    });
  }

  /**
   * Removes campaign
   * @param {Object} action action
   */
  removeCampaign(action) {
    this.setState({
      action: action.actionType,
      modalSettings: this.getModalSettings({
        tiny: true,
        onApprove: () => {
          Campaign.remove({
            accountId: ContextStore.routingParams().accountId,
            projectId: ContextStore.routingParams().projectId,
            campaignId: action.campaign.id,
          });
        },
      }),
      modalHeader: 'Are you sure to remove this campaign?',
      innerModal: <RemoveItemForm header={`${action.campaign.id} - ${action.campaign.label}`} />,
    });
  }

  /**
   * removes insertion
   * @param {Object} action action
   */
  removeInsertion(action) {
    this.setState({
      action: action.actionType,
      modalSettings: this.getModalSettings({
        tiny: true,
        onApprove: () => {
          Insertion.remove({
            accountId: ContextStore.routingParams().accountId,
            projectId: ContextStore.routingParams().projectId,
            campaignId: ContextStore.routingParams().campaignId,
            insertionId: action.insertion.id,
            insertionLabel: action.insertion.label // Only used for success message
          });
        },
      }),
      modalHeader: 'Are you sure to remove this insertion?',
      innerModal: <RemoveItemForm header={`${action.insertion.id} - ${action.insertion.label}`} />,
    });
  }

  /**
   * Renders Modal
   */
  render() {
    return (
      <span>
        <Modal
          ref={this.modalRef}
          tiny={this.state.modalSettings.tiny}
          small={this.state.modalSettings.small}
          large={this.state.modalSettings.large}
          fullscreen={this.state.modalSettings.fullscreen}
          header={this.state.modalHeader}
        >
          {this.state.innerModal}
        </Modal>
        <Modal
          ref={this.secondModalRef}
          header={this.state.secondModalHeader}
        >
          {this.state.secondModal}
        </Modal>
      </span>
    );
  }
}

export default ModalController;
