import React from 'react';
import PropTypes from 'prop-types';

const CreativeClickRow = (props) => {
  let fileIcon = <i className="grey image icon" />;
  if (parseInt(props.creative.creative_format_id, 10) === 6) {
    fileIcon = <i className="grey image icon" />;
  } else if (parseInt(props.creative.creative_format_id, 10) === 4) {
    fileIcon = <i className="grey video icon" />;
  }
  return (
    <tr>
      <td className="center aligned" />
      <td colSpan="2" className="left aligned single line" style={{paddingLeft: '40px'}}>
        {fileIcon}
        <b>{props.creative.label}</b>
      </td>
    </tr>
  );
};

export default CreativeClickRow;

CreativeClickRow.propTypes = {
  creative: PropTypes.object,
};
