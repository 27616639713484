import BaseStore from 'stores/BaseStore';
import AppConstants from 'constants/AppConstants';
import ServerConstants from 'constants/ServerConstants';
import {register} from 'dispatchers/AppDispatcher';

const AdElementRoleStore = Object.assign({}, BaseStore, {
  adElementRoles: [],
  /**
   * returns Ad Element Roles
   * @date   2016-01-14
   * @return {array}   ad element roles
   */
  getAdElementRoles() {
    return this.adElementRoles;
  },
  /**
   * Fetches ad element Roles
   * @date   2016-06-08
   */
  fetchAdElementRoles() {
    const onSuccess = (response) => {
      this.adElementRoles = response.data.slice();
      this.emitChange({action: AppConstants.LIST_AD_ELEMENT_ROLES});
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.LIST_AD_ELEMENT_ROLES, message});
    };

    this.post({
      url: ServerConstants.CREATIVE_LIBRARY.AD_ELEMENT_ROLES.LIST,
      onSuccess,
      onError,
    });
  },
  /**
   * Handle Store actions
   * @date   2016-02-11
   * @param  {Function}   function(action -
   */
  dispatcherIndex: register((action) => {
    switch (action.actionType) {
      case AppConstants.LIST_AD_ELEMENT_ROLES:
        AdElementRoleStore.fetchAdElementRoles();
        break;
      // no default
    }
    return true; // No errors. Needed by promise in Dispatcher.
  }),
});

export default AdElementRoleStore;
