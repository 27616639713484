import {dispatch} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const Campaign = {
  /**
   * dispatches action to fetch a list of campaigns
   * @date   2016-03-31
   * @param  {Object}   data  data object
   */
  list(data) {
    dispatch({
      actionType: AppConstants.LIST_CAMPAIGNS,
      accountId: data.accountId,
      projectId: data.projectId,
    });
  },
  /**
   * Browse campaigns
   * @date   2016-10-12
   * @param  {Object}   data  data object
   */
  browse(data) {
    dispatch({
      actionType: AppConstants.BROWSE_CAMPAIGNS,
      accountId: data.accountId,
      projectId: data.projectId,
    });
  },
  /**
   * Dispatch create new campaign event
   * @date   2016-01-20
   * @param  {Object}   data  data object
   */
  add(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.ADD_CAMPAIGN,
      channelId: 1, // fixed channelId for now
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an event to update a campaign
   *
   * @param {object} data data object
   */
  update(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.UPDATE_CAMPAIGN,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to remove a campaign
   *
   * @param {object} data Data Object
   */
  remove(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.REMOVE_CAMPAIGN,
    }, data);

    dispatch(obj);
  },
  /**
   * List ad spaces
   *
   * @param {Object} data Data object
   */
  listAdSpaces(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.LIST_ADSPACES_FROM_CAMPAIGN,
    }, data);

    dispatch(obj);
  },
  /**
   * fetches number of insertions for list of campaign id's
   * @date   2016-08-03
   * @param {object} data Data Object
   */
  getNumberOfInsertions(data) {
    dispatch({
      actionType: AppConstants.LIST_NO_INSERTIONS_FOR_CAMPAIGNS,
      accountId: data.accountId,
      ids: data.ids,
    });
  },
  /**
   * Dispatches an action to fetch a list of third party tags
   * @param {Object} data data object
   */
  getThirdPartyTags(data) {
    dispatch({
      actionType: AppConstants.LIST_THIRD_PARTY_TAGS_FOR_CAMPAIGN,
      accountId: data.accountId,
      projectId: data.projectId,
      campaignId: data.campaignId,
    });
  },
  /**
   * Dispatches an action to download media plan
   * @param {Object} data data object
   */
  downloadMediaPlan(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.DOWNLOAD_MEDIA_PLAN,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to download media plan template
   */
  downloadMediaPlanTemplate() {
    dispatch({actionType: AppConstants.DOWNLOAD_MEDIA_PLAN_TEMPLATE});
  },
  /**
   * uploads media plan
   * @param {Object} data data object
   */
  uploadMediaPlan(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.UPLOAD_MEDIA_PLAN,
    }, data);

    dispatch(obj);
  },
};

export default Campaign;
