import React from 'react';
import PropTypes from 'prop-types';
import Search from 'weborama-ui-react/Search';
import Input from 'weborama-ui-react/Input';
import Icon from 'weborama-ui-react/Icon';
import {getObjectInArray} from 'constants/Utils';
import { ContextStore } from 'stores';

class SizeSearch extends React.Component {
  constructor(props) {
    super(props);
    this.handleResultOpen = this.handleResultOpen.bind(this);
    this.state = {
      selectedId: props.selectedId || undefined,
    };
    this.searchRef = React.createRef();
  }

  componentDidMount() { // Might be redundant
    if (this.props.selectedId && ContextStore.sizes() !== undefined) {
      const size = getObjectInArray(ContextStore.sizes(), 'id', this.props.selectedId);
      this.searchRef.current.setValue(size.label);
    }
  }

  componentDidUpdate() {
    if (this.state && (this.props.selectedId !== this.state.selectedId) && ContextStore.sizes() !== undefined) {
      const size = getObjectInArray(ContextStore.sizes(), 'id', this.props.selectedId);
      this.searchRef.current.setValue(size.label);
    }
  }

  handleResultOpen(result) {
    const value = this.searchRef.current.getValue().toLowerCase();

    if (result) {
      if (value.indexOf('x') > -1 && value.split('x')[1].length > 0) {
        const sizeResult = result.results.filter((size) => {
          if (size.label === value) {
            return size;
          }
          return false;
        });
        if (sizeResult.length === 0) {
          this.searchRef.current.addResult();
        }
      }
    }
  }

  render() {
    return (
      <Search
        ref={this.searchRef}
        settings={{
          source: ContextStore.sizes(),
          fields: {
            title: 'label',
          },
          searchFields: ['label'],
          onSelect: this.props.onSelect,
          onResultOpen: this.handleResultOpen,
        }}
      >
        <Input transparent left icon prompt disabled={this.props.disabled}>
          <Icon write />
        </Input>
      </Search>
    );
  }
}

export default SizeSearch;

SizeSearch.propTypes = {
  disabled: PropTypes.bool,
  selectedId: PropTypes.number,
  onSelect: PropTypes.func,
};
