import PropTypes from 'prop-types';
import React from 'react';
import { Campaign } from 'actions';
import Form, { Field } from 'weborama-ui-react/Form';
import Input from 'weborama-ui-react/Input';
import Accordion, { Title, Content } from 'weborama-ui-react/Accordion';
import Calendar from 'weborama-ui-react/Calendar';
import { parseComments, makeUserComments, isEmptyObject } from 'constants/Utils';
import Checkbox from 'weborama-ui-react/Checkbox';
import Button from 'weborama-ui-react/Button';
import AppConstants from 'constants/AppConstants';
import { CampaignStore, ContextStore } from 'stores';
import CampaignLabels from 'components/CampaignLabels';
import Message from 'weborama-ui-react/Message';
import CampaignVerticals from '../CampaignVerticals';
import CampaignVerticalsWebo from '../CampaignVerticalsWebo';

/**
 * Campaign form
 */
class CampaignForm extends React.Component {
  /**
   * maps props to state object
   * @date   2016-11-12
   * @param  {object}   props properties
   * @return {object}         new state
   */
  static getStateFromProps(props) {
    if (isEmptyObject(props.campaign)) {
      return {
        action: 'add',
        loading: false,
        label: '',
        landingUrl: '',
        campaignLabels: [],
        campaignVertical: '',
        campaignVerticalWebo: '',
        comments: '',
        startDate: '',
        endDate: '',
        externalId: '',
        externalName: '',
        addInsertion: true,
        error: {},
        initialCampaignForm: {
          // Only used for form validation
          label: '',
          landingUrl: '',
          campaignLabels: [],
          campaignVertical: '',
          campaignVerticalWebo: '',
          comments: '',
          startDate: '',
          endDate: '',
          externalId: '',
          externalName: '',
        }
      };
    }

    const campaignLabels = props.campaign.campaign_labels.map(
      (campaignLabel) => campaignLabel.label
    );
    return {
      action: 'update',
      loading: false,
      label: props.campaign.label,
      landingUrl: props.campaign.landing_url,
      comments: parseComments(props.campaign.comments).comment || '',
      startDate: props.campaign.start_date,
      endDate: props.campaign.end_date,
      campaignLabels,
      campaignVertical: '',
      campaignVerticalWebo: '',
      externalId: props.campaign.external_id,
      externalName: props.campaign.external_name,
      addInsertion: false,
      error: {},
      initialCampaignForm: {
        // Only used for form validation
        label: props.campaign.label,
        landingUrl: props.campaign.landing_url,
        campaignLabels: campaignLabels,
        campaignVertical: '',
        campaignVerticalWebo: '',
        comments: parseComments(props.campaign.comments).comment || '',
        externalId: props.campaign.external_id,
        externalName: props.campaign.external_name,
        startDate: new Date(props.campaign.start_date).getTime() / 1000,
        endDate: new Date(props.campaign.end_date).getTime() / 1000,
      },
    };
  }

  /**
   * constructor
   * @date   2016-08-26
   * @param  {object}   props properties
   * //TODO: Make option to add other protocols like app:// message:// etc
   * // Note: This protocol option os now removed, but keeping the comment here for reference
   */
  constructor(props) {
    super(props);

    this.state = CampaignForm.getStateFromProps(props);

    this.handleExternalIdChange = this.handleExternalIdChange.bind(this);
    this.handleExternalNameChange = this.handleExternalNameChange.bind(this);
    this.handleCommentsChange = this.handleCommentsChange.bind(this);
    this.handleUrlChange = this.handleUrlChange.bind(this);
    this.handleLabelChange = this.handleLabelChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleAddInsertionChange = this.handleAddInsertionChange.bind(this);
    this.handleCampaignLabelChange = this.handleCampaignLabelChange.bind(this);
    this.onCampaignStoreError = this.onCampaignStoreError.bind(this);
    this.addInsertion = this.addInsertion.bind(this);
    this.handleCampaignVerticalChange = this.handleCampaignVerticalChange.bind(this);
    this.handleCampaignVerticalWeboChange = this.handleCampaignVerticalWeboChange.bind(this);

    CampaignStore.addErrorListener(this.onCampaignStoreError);

    this.endDateRef = React.createRef();
    this.addInsertionRef = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.toString() !== prevState.toString()) {
      return CampaignForm.getStateFromProps(nextProps);
    }
    return null;
  }

  /**
   * unregister error listeners
   * @date   2016-10-17
   */
  componentWillUnmount() {
    CampaignStore.removeErrorListener(this.onCampaignStoreError);
  }

  /**
   * CampaignStore error listener
   * @date   2016-10-17
   * @param  {Object}   error error
   */
  onCampaignStoreError(error) {
    if (
      error.action === AppConstants.ADD_CAMPAIGN
      || error.action === AppConstants.UPDATE_CAMPAIGN
    ) {
      this.setState({ loading: false, error });
    }
  }

  /**
   * hanndles externalId change
   * @date   2023-01-03
   * @param  {object}   value event object
   */
  handleExternalIdChange(value) {
    this.setState({ externalId: value });
  }

  /**
   * hanndles externalName change
   * @date   2023-01-03
   * @param  {object}   value event object
   */
  handleExternalNameChange(value) {
    this.setState({ externalName: value });
  }

  /**
   * hanndles comments change
   * @date   2016-08-26
   * @param  {object}   e event object
   */
  handleCommentsChange(e) {
    this.setState({ comments: e.target.value });
  }

  /**
   * Handles label change
   * @date   2016-12-14
   * @param  {String}   value value
   */
  handleLabelChange(value) {
    this.setState({ label: value });
  }

  /**
   * Handle landing url change
   * @param  {string}   newLandingUrl current entered landingUrl value at input
   */
  handleUrlChange(newLandingUrl) {
    this.setState({ landingUrl: newLandingUrl });
  }

  /**
   * handles start date picker
   * @date   2016-08-29
   * @param  {String}   value selected date time
   */
  handleStartDateChange(value) {
    this.setState({ startDate: value.getTime() / 1000 });
    this.endDateRef.current.setStartDate(value);
  }

  /**
   * handles end date picker
   * @date   2016-08-29
   * @param  {String}   value selected date time
   */
  handleEndDateChange(value) {
    if (value !== undefined) {
      this.setState({ endDate: value.getTime() / 1000 });
    }
  }

  campaignFormChangedAndValid() {
    const currentCampaignForm = {
      label: this.state.label,
      landingUrl: this.state.landingUrl,
      campaignLabels: this.state.campaignLabels,
      campaignVertical: this.state.campaignVertical,
      campaignVerticalWebo: this.state.campaignVerticalWebo,
      comments: this.state.comments,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      externalId: this.state.externalId,
      externalName: this.state.externalName,
    };

    if (
      JSON.stringify(currentCampaignForm)
      !== JSON.stringify(this.state.initialCampaignForm)
     && this.state.campaignVertical.length > 0
      && this.state.label.length > 0
     && this.state.landingUrl.length > 0
    ) {
      return false;
    }
    return true;
  }

  /**
   * Handles form submit
   * @date   2016-08-26
   */
  handleFormSubmit() {
    if (!this.state.loading) {
      let comments = '';
      if (this.state.action === 'add') {
        if (this.state.comments !== '') {
          comments = makeUserComments(
            ContextStore.profile().data.user.firstname,
            this.state.comments
          );
        }
        Campaign.add({
          accountId: ContextStore.routingParams().accountId,
          projectId: this.props.projectId,
          label: this.state.label,
          url: this.state.landingUrl,
          comments,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          externalId: this.state.externalId,
          externalName: this.state.externalName,
          campaignLabels: [...this.state.campaignLabels, this.state.campaignVertical, this.state.campaignVerticalWebo],
          addInsertion: this.state.addInsertion,
        });
      } else if (this.state.action === 'update') {
        if (
          this.state.comments
           !== parseComments(this.props.campaign.comments).comment
        ) {
          if (this.state.comments !== '') {
            comments = makeUserComments(
              ContextStore.profile().data.user.firstname,
              this.state.comments
            );
          } else {
            ({ comments } = this.state);
          }
        } else {
          ({ comments } = this.props.campaign);
        }
        Campaign.update({
          accountId: ContextStore.routingParams().accountId,
          campaignId: this.props.campaign.id,
          projectId: this.props.projectId,
          label: this.state.label,
          url: this.state.landingUrl,
          comments,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          externalId: this.state.externalId,
          externalName: this.state.externalName,
          campaignLabels: [...this.state.campaignLabels, this.state.campaignVertical, this.state.campaignVerticalWebo],
        });
      }
      this.setState({ loading: true });
    }
  }

  /**
   * Handles add campaign change
   * @date   2016-10-13
   */
  handleAddInsertionChange() {
    const checked = this.addInsertionRef.current.isChecked();
    this.setState({ addInsertion: checked });
  }

  /**
   * Handles campaign labels change
   * @date   2017-01-11
   * @param  {Array}   e labels
   */
  handleCampaignLabelChange(e) {
    this.setState({ campaignLabels: e });
  }

  /**
   * Handles campaign verticals change
   * @date   2017-01-11
   * @param  {Array}   e vertical
   */
  handleCampaignVerticalChange(e) {
    this.setState({ campaignVertical: e });
  }

  /**
   * Handles campaign verticals webo change
   * @date   2022-10-03
   * @param  {Array}   e vertical
   */
  handleCampaignVerticalWeboChange(e) {
    this.setState({ campaignVerticalWebo: e });
  }

  /**
   * Returns if a insertion should be added
   * @date 2016-10-14
   * @return {Boolean}   add insertion after adding the campaign
   */
  addInsertion() {
    return this.state.addInsertion;
  }

  /**
   * renders campaign form
   * TODO: create error message component
   * @date   2016-08-26
   * @return {jsx}   campaign form
   */
  render() {
    return (
      <span>
        <Form onSubmit={this.handleFormSubmit}>
          <Field label="Name" required>
            <Input
              placeHolder="Name"
              name="label"
              onChange={this.handleLabelChange}
              className="campaign-label"
              value={this.state.label}
            />
          </Field>
          <Field label="Default landing url" required>
            <Input
              className="campaign-landing-url"
              placeHolder="Default landing url"
              required
              labeled
              value={this.state.landingUrl}
              onChange={this.handleUrlChange}
            >
            </Input>
          </Field>
          <Field label="Campaign vertical (IAB)" required>
            <CampaignVerticals campaignVertical={this.state.campaignVertical} onChange={this.handleCampaignVerticalChange} />
          </Field>
          <Field label="Campaign vertical (Weborama)">
            <CampaignVerticalsWebo campaignVerticalWebo={this.state.campaignVerticalWebo} onChange={this.handleCampaignVerticalWeboChange} />
          </Field>
          <Field label="Campaign labels">
            <CampaignLabels
              campaign={this.props.campaign}
              onChange={this.handleCampaignLabelChange}
            />
          </Field>
          <Field
            className={`transition ${
              this.state.action === 'update' ? 'hidden' : 'visible'
            }`}
          >
            <Checkbox
              ref={this.addInsertionRef}
              checked
              onChange={this.handleAddInsertionChange}
            >
              {'Also add insertion'}
            </Checkbox>
          </Field>
          <Field>
            <Accordion>
              <Title>{'Optional settings'}</Title>
              <Content>
                <Field label="Comments">
                  <textarea
                    className="campaign-comments"
                    onChange={this.handleCommentsChange}
                    value={this.state.comments}
                    rows="2"
                  />
                </Field>
                <Field label="External ID">
                  <Input
                    className="external-id"
                    placeHolder="External ID"
                    value={this.state.externalId}
                    onChange={this.handleExternalIdChange}
                  >
                  </Input>
                </Field>
                <Field label="External Name">
                  <Input
                    className="external-name"
                    placeHolder="External Name"
                    value={this.state.externalName}
                    onChange={this.handleExternalNameChange}
                  >
                  </Input>
                </Field>
                <Field label="Start date">
                  <Calendar
                    placeHolder="Start date"
                    value={this.state.startDate}
                    onChange={this.handleStartDateChange}
                  />
                </Field>
                <Field label="End date">
                  <Calendar
                    ref={this.endDateRef}
                    placeHolder="End date"
                    value={this.state.endDate}
                    onChange={this.handleEndDateChange}
                  />
                </Field>
              </Content>
            </Accordion>
          </Field>
          <Field>
            <Button
              fluid
              primary
              submit
              loading={this.state.loading}
              onClick={this.handleFormSubmit}
              disabled={this.campaignFormChangedAndValid()}
            >
              {`${this.state.action === 'update' ? 'Update' : 'Submit'}`}
            </Button>
          </Field>
        </Form>
        {!isEmptyObject(this.state.error) && (
          <Message error>{this.state.error.message}</Message>
        )}
      </span>
    );
  }
}

export default CampaignForm;

CampaignForm.propTypes = {
  projectId: PropTypes.number,
  campaign: PropTypes.object,
};
