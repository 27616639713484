import BaseStore from 'stores/BaseStore';
import {Insertion} from 'constants/ServerConstants';
import {register} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';
import ToastrActions from 'actions/Toastr';
/**
 * Insertion Store
 */
const InsertionStore = Object.assign({}, BaseStore, {
  insertions: [],
  assignedAdPositions: [],
  details: [],
  req: {},
  expandedInsertionIds: [], // Only used for a spesific situation concerning refreshing Insertion details at ExtraClickForm
  /**
   * Returns array with insertions
   * @date   2016-03-30
   * @return {Array}   Array with insetions
   */
  getInsertions() {
    return this.insertions;
  },
  /**
   * Returns array with assigned ad positions
   */
  getAssignedAdPositions() {
    return this.assignedAdPositions;
  },
  /**
   * Returns details about insertions
   */
  getDetails() {
    return this.details;
  },
  /**
   * fetches insertions by account Id
   * @date   2016-03-14
   * @param  {Number}   accountId id of account
   * @param  {Number}   campaignId id of campaign
   * @param  {Number}   adSpaceId  site offer id
   * @param  {Boolean}  browse  browse
   */
  listInsertions(queryData, browse = false) {
    if (typeof this.req.abort === 'function' && this.req.readyState !== 4 && this.req.readyState !== 0) {
      this.req.abort();
    }

    let action = AppConstants.LIST_INSERTIONS;

    if (browse) {
      action = AppConstants.BROWSE_INSERTIONS;
    }

    const onSuccess = (data) => {
      this.insertions = data.data.slice();
      this.emitChange({action});
    };

    const onError = (message) => {
      this.emitError({action, message});
    };

    const orderSettings = {
      conditions: {status: 'active'},
      order_fields: ['updated_at', 'created_at'],
      order_direction: 'desc',
    };

    const data = Object.assign({}, queryData, orderSettings);

    this.req = this.post({
      url: Insertion.LIST,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Removes an insertion
   * @date   2016-04-06
   * @param  {number}   accountId   Account Id
   * @param  {number}   insertionId Insertion Id
   */
  removeInsertion(data) {
    const onSuccess = () => {
      this.listInsertions({
        account_id: data.account_id,
        project_id: data.project_id,
        campaign_id: data.campaign_id,
      });
      ToastrActions.success({ title: data.insertionLabel, body: 'Insertion has been removed' });
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.REMOVE_INSERTION, message});
    };

    this.post({
      url: Insertion.DELETE,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Posts a new insertion
   * @date  2016-04-04
   * @param {Object}   insertion   insertion object
   */
  addInsertion(data) {
    const onSuccess = () => {
      this.listInsertions({
        account_id: data.account_id,
        project_id: data.project_id,
        campaign_id: data.campaign_id,
      }, false);
      this.emitChange({action: AppConstants.ADD_INSERTION, data});
      ToastrActions.success({ title: data.label, body: 'A new insertion has been added' });
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.ADD_INSERTION, message});
    };

    this.post({
      url: Insertion.CREATE,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Updates an insertion
   * @date   2016-04-25
   * @param  {Object}   insertion  new insertion
   */
  updateInsertion(data) {
    const onSuccess = () => {
      this.emitChange({action: AppConstants.UPDATE_INSERTION});
      this.listInsertions({
        account_id: data.account_id,
        project_id: data.project_id,
        campaign_id: data.campaign_id,
      });
      ToastrActions.success({ title: data.label, body: 'Insertion has been updated' });
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.UPDATE_INSERTION, message});
    };

    this.post({
      url: Insertion.UPDATE,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Duplicates an insertion
   * @date   2016-11-14
   * @param  {Object}   insertion insertion
   */
  duplicateInsertion(data) {
    const onSuccess = () => {
      this.emitChange({action: AppConstants.DUPLICATE_INSERTION});
      this.listInsertions({
        account_id: data.account_id,
        project_id: data.project_id,
        campaign_id: data.campaign_id,
      });
      ToastrActions.success({ title: data.label, body: 'A new duplicated insertion has been created' });
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.DUPLICATE_INSERTION, message});
    };

    this.post({
      url: Insertion.DUPLICATE,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Fetches a list of assigned ad positions
   * @param {Object} data data object
   */
  listAssignedAdPositions(data) {
    const onSuccess = (response) => {
      this.assignedAdPositions = response.data.slice();
      this.emitChange({action: AppConstants.GET_ASSIGNED_AD_POSITION_FROM_INSERTION, data: {insertionId: data.id}});
    };
    const onError = (message) => {
      this.emitError({action: AppConstants.GET_ASSIGNED_AD_POSITION_FROM_INSERTION, error: message});
    };
    this.post({
      url: Insertion.GET_ASSIGNED_AD_POSITIONS,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Fetches a list with details for an array of insertion ids. details include placements landingurl trackingelements.
   * @param {Object} data data object
   */
  listInsertionDetails(data) {
    this.expandedInsertionIds = data.ids;
    const onSuccess = (response) => {
      this.details = response.data.slice();
      this.emitChange({action: AppConstants.LIST_INSERTION_DETAILS});
    };
    const onError = (message) => {
      this.emitError({action: AppConstants.LIST_INSERTION_DETAILS, message});
    };
    this.post({
      url: Insertion.DETAILS,
      data,
      onSuccess,
      onError,
    });
  },
  dispatcherIndex: register((action) => {
    let data;
    switch (action.actionType) {
      case AppConstants.BROWSE_INSERTIONS:
        InsertionStore.listInsertions({
          account_id: action.accountId,
          project_id: action.projectId,
          campaign_id: action.campaignId,
        }, true);
        break;
      case AppConstants.LIST_INSERTIONS:
        InsertionStore.listInsertions({
          account_id: action.accountId,
          project_id: action.projectId,
          campaign_id: action.campaignId,
        }, false);
        break;
      case AppConstants.ADD_INSERTION:
        InsertionStore.addInsertion({
          account_id: action.accountId,
          project_id: action.projectId,
          campaign_id: action.campaignId,
          ad_space_id: action.adSpaceId,
          ad_network_id: action.adNetworkId,
          label: action.label,
          delivery_format_id: action.deliveryFormatId,
          comments: action.comments,
          landing_url: action.landingUrl,
          priority: action.priority,
          is_auto_start_date: 'YES', // Force to use yes
          is_auto_end_date: 'YES', // Force to use yes
          start_date: action.startDate,
          end_date: action.endDate,
          insertion_label__labels: action.insertionLabels,
        });
        break;
      case AppConstants.REMOVE_INSERTION:
        InsertionStore.removeInsertion({
          account_id: action.accountId,
          project_id: action.projectId,
          campaign_id: action.campaignId,
          id: action.insertionId,
          insertionLabel: action.insertionLabel
        });
        break;
      case AppConstants.UPDATE_INSERTION:
        data = {
          account_id: action.accountId,
          project_id: action.projectId,
          campaign_id: action.campaignId,
          id: action.insertionId,
          label: action.label,
          delivery_format_id: action.deliveryFormatId,
          comments: action.comments,
          landing_url: action.landingUrl,
          priority: action.priority,
          start_date: action.startDate,
          end_date: action.endDate,
          insertion_label__labels: action.insertionLabels,
        };
        InsertionStore.updateInsertion(data);
        break;
      case AppConstants.DUPLICATE_INSERTION:
        data = {
          account_id: action.accountId,
          project_id: action.projectId,
          ad_space_id: action.adSpaceId,
          campaign_id: action.campaignId,
          label: action.label,
          // duplicate_creatives: 'yes',
          ad_network_id: action.adNetworkId,
          duplicate_creatives: action.duplicateCreatives ? 'YES' : 'NO',
          id: action.insertionId,
          comments: action.comments,
          landing_url: action.landingUrl,
          priority: action.priority,
          start_date: action.startDate,
          end_date: action.endDate,
        };
        InsertionStore.duplicateInsertion(data);
        break;
      case AppConstants.GET_ASSIGNED_AD_POSITION_FROM_INSERTION:
        InsertionStore.listAssignedAdPositions({
          account_id: action.accountId,
          id: action.insertionId,
        });
        break;
      case AppConstants.LIST_INSERTION_DETAILS:
        InsertionStore.listInsertionDetails({
          account_id: action.accountId,
          ids: action.insertionIds,
        });
        break;
      // no default
    }
    return true;
  }),
});

export default InsertionStore;
