import PropTypes from 'prop-types';
import React from 'react';
import TagConstants from 'constants/TagConstants';
import TagActions from 'actions/TagActions';
import {PublicTagStore} from 'stores';
import {getObjectInArray} from 'constants/Utils';
import PreviewVast from 'components/PreviewVast';
import PreviewRichMedia from 'components/PreviewRichMedia';
import ImageAd from 'components/ImageAd';
// import PreviewAdPosition from 'components/PreviewAdPosition';
import Loader from 'weborama-ui-react/Loader';
import Dimmer from 'weborama-ui-react/Dimmer';
// import AdPosition from 'components/previewmanager/AdPosition';
/**
 * Class PublicPreview
 */
class PublicPreview extends React.Component {
  /**
   * Constructor
   * @date   2017-01-30
   * @param  {Object}   props   Properties
   */
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      token: props.params.token || '',
      deliveryFormatId: undefined,
      tags: [],
    };

    this.onTagStoreChange = this.onTagStoreChange.bind(this);
    this.onTagStoreError = this.onTagStoreError.bind(this);

    PublicTagStore.addChangeListener(this.onTagStoreChange);
    PublicTagStore.addErrorListener(this.onTagStoreError);
  }

  /**
   * Fetch tags
   */
  componentDidMount() {
    TagActions.getTagsFromToken(this.state.token);
  }

  /**
   * Unregister store callbacks
   * @date   2017-01-30
  */
  componentWillUnmount() {
    PublicTagStore.removeChangeListener(this.onTagStoreChange);
    PublicTagStore.removeErrorListener(this.onTagStoreError);
  }

  /**
   * On tag store change
   * @date   2017-01-30
   * @param  {Object}   data data Object
   */
  onTagStoreChange(data) {
    if (data.action === TagConstants.GET_TAGS_FROM_TOKEN) {
      const {account} = PublicTagStore.getTags();
      const {project} = account;
      const {campaign} = project;
      const adSpaces = campaign.ad_spaces;
      const insertions = adSpaces[0].insertions.slice();
      const tags = insertions[0].tags.slice();

      this.setState({
        deliveryFormatId: parseInt(insertions[0].delivery_format_id, 10),
        insertions,
        tags,
        loading: false,
      });
    }
  }
  /* eslint-disable */
  /**
   * On tag store error
   * @date   2017-01-30
   * @param  {Object}   message error Object
   */
  onTagStoreError(message) {
    //Error handling here
  }

  /* eslint-enable */
  /**
   * Renders public preview
   * @date   2017-01-30
   * @return {array}   nodes
   */
  render() {
    let preview;
    const isSecure = window.location.protocol.indexOf('https:') > -1;

    if (this.state.deliveryFormatId === 6) {
      preview = (<PreviewVast setupScript={isSecure ? this.state.tags[0]['vast-ssl'] : this.state.tags[0].vast} />);
    } else if (this.state.deliveryFormatId === 1) {
      preview = this.state.tags.map((tag) => {
        const sizeObj = getObjectInArray(this.state.insertions[0].placements, 'id', tag.placement_id);
        return (
          <PreviewRichMedia key={tag.tracking_id} size={sizeObj.size} tag={isSecure ? tag['js-ssl'] : tag.js} />
        );
      });
    } else if (this.state.deliveryFormatId === 2) {
      return (
        <ImageAd tag={isSecure ? this.state.tags[0]['image-ssl'] : this.state.tags[0].image} />
      );
    }
    return (
      <div>
        <Dimmer active={this.state.loading}>
          <Loader indeterminate text>Preparing preview</Loader>
        </Dimmer>
        {preview}
      </div>
    );
  }
}

export default PublicPreview;

PublicPreview.propTypes = {
  params: PropTypes.object,
};
