/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable max-classes-per-file */
import React from 'react';
import PropTypes from 'prop-types';
import Form, { Field } from 'weborama-ui-react/Form';
import Checkbox from 'weborama-ui-react/Checkbox';
import Button from 'weborama-ui-react/Button';
import { LandingUrlStore, InsertionStore, ContextStore } from 'stores';
import Input from 'weborama-ui-react/Input';
import { isEmptyObject } from 'constants/Utils';
import { LandingUrl, Insertion } from 'actions';
import AppConstants from 'constants/AppConstants';

/*
* Representation of a single extra click form field information
*/
class ExtraClickFormField {
  constructor(label, url, internalId, urlNumber) {
    this.label = label;
    this.url = url;
    this.internalId = internalId;
    this.urlNumber = urlNumber;
  }
}

/**
 * Class ExtraClickForm
 */
class ExtraClickForm extends React.Component {
  /**
   * Constructor
   * @param {Object} props Properties
   */
  constructor(props) {
    super(props);
    let label = '';
    let url = '';
    if (!isEmptyObject(props.landingUrl)) {
      ({ label } = props.landingUrl);
      url = props.landingUrl.url_text;
    }

    this.state = {
      formFields: [
        new ExtraClickFormField(label, url, 'field-0', props.labelNumber),
        new ExtraClickFormField(label, url, 'field-1', props.labelNumber + 1),
        new ExtraClickFormField(label, url, 'field-2', props.labelNumber + 2)
      ],
      loading: false,
      error: {},
      lastLabelNumber: props.labelNumber + 2, // Last number used on latest created label
      lastExtraClickFormFieldNumber: 2, // Last key/id used on latest created form field
      rawMode: false,
      rawText: '',
    };
    this.handleUrlChange = this.handleUrlChange.bind(this);
    this.handleLabelChange = this.handleLabelChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onLandingUrlStoreError = this.onLandingUrlStoreError.bind(this);
    this.onLandingUrlStoreChange = this.onLandingUrlStoreChange.bind(this);
    this.addExtraFormField = this.addExtraFormField.bind(this);
    this.toggleRawMode = this.toggleRawMode.bind(this);
    this.textAreaChange = this.textAreaChange.bind(this);
    this.textAreaValid = this.textAreaValid.bind(this);

    LandingUrlStore.addChangeListener(this.onLandingUrlStoreChange);
    LandingUrlStore.addErrorListener(this.onLandingUrlStoreError);
  }

  /**
   * unregister landing url store error callback
   */
  componentWillUnmount() {
    LandingUrlStore.removeChangeListener(this.onLandingUrlStoreChange);
    LandingUrlStore.removeErrorListener(this.onLandingUrlStoreError);
  }

  onLandingUrlStoreChange(data) {
    if (data.action === AppConstants.ADD_MULTI_LANDING_URL) {
      Insertion.details({
        accountId: ContextStore.routingParams().accountId,
        insertionIds: InsertionStore.expandedInsertionIds,
      });
    }
  }

  /**
   * Landing url store error callback
   * @param {Object} error error object
   */
  onLandingUrlStoreError(error) {
    this.setState({ error, loading: false });
  }

  /**
   * Handles url change
   * @param {String} newUrl
   * @param {String} formFieldInternalId id of the form field being changed
   */
  handleUrlChange(newUrl, formFieldInternalId) {
    const { formFields } = this.state;
    for (let i = 0; i < this.state.formFields.length; i++) {
      if (this.state.formFields[i].internalId === formFieldInternalId) {
        formFields[i].url = newUrl;
        this.setState({ formFields: formFields });
      }
    }
  }

  /**
   * Handles label change
   * @param {String} newLabel
   * @param {String} formFieldInternalId id of the form field being changed
   */
  handleLabelChange(newLabel, formFieldInternalId) {
    const { formFields } = this.state;
    for (let i = 0; i < this.state.formFields.length; i++) {
      if (this.state.formFields[i].internalId === formFieldInternalId) {
        formFields[i].label = newLabel;
        this.setState({ formFields: formFields });
      }
    }
  }

  /**
   * Add a new form field in normal mode
   */
  addExtraFormField() {
    const { formFields } = this.state;
    formFields.push(new ExtraClickFormField('', '', 'field-' + (this.state.lastExtraClickFormFieldNumber + 1), this.state.lastLabelNumber + 1));

    this.setState({ formFields: formFields, lastLabelNumber: this.state.lastLabelNumber + 1, lastExtraClickFormFieldNumber: this.state.lastExtraClickFormFieldNumber + 1 });
  }

  /**
   * Toggle between normal and raw modes of the component
   */
  toggleRawMode() {
    this.setState({
      rawMode: !this.state.rawMode,
      rawText: '',
      formFields: [
        new ExtraClickFormField('', '', 'field-0', this.props.labelNumber),
        new ExtraClickFormField('', '', 'field-1', this.props.labelNumber + 1),
        new ExtraClickFormField('', '', 'field-2', this.props.labelNumber + 2),
      ],
      lastLabelNumber: this.props.labelNumber + 2, // Last number used on latest created label
      lastExtraClickFormFieldNumber: 2, // Last key/id used on latest created form field
    });
  }

  /**
   * Function that runs every time there is an event at text area
   * @param {String} event event from textarea
   */
  textAreaChange(event) {
    this.setState({ rawText: event.target.value }, () => {
      this.textAreaValid();
    });
  }

  /**
   * Checks whether text area has valid input
   */
  textAreaValid() {

    if (this.state.rawMode) {
      const textAreaPattern = new RegExp(/((?:\/n)?(?:\S)+;http(?:s)?:\/\/(?:\S)+\.(?:\S){2,}(?:\?)?(?:.*)(?:,)?)+/, 'm');
      // We are not using the 'g' flag because we want the Regexp.lastIndex to stay the same
      // If we use global flag, then at the 2nd match, there won't be a match because it will start testing from the last index point

      const splitArr = this.state.rawText.replace('\n', '').split(','); // Remove the newline stuff

      const result = splitArr.every(singleEntry => textAreaPattern.test(singleEntry));

      return !result; // So when there is a match, return false
    }

    return false;

  }

  /**
   * Submit the form
   */
  handleFormSubmit() {

    // Add already existing active clicks
    const landingUrls = [];

    this.props.assignedAdPosition.landing_urls.forEach(singleLandingUrl => {

      if (singleLandingUrl.status.toLowerCase() === 'active') {
        landingUrls.push({
          url_text: singleLandingUrl.url_text,
          url_number: parseInt(singleLandingUrl.url_number, 10),
          label: singleLandingUrl.label
        });
      }
    });

    if (!this.state.rawMode) {
      // Add new clicks in the form
      this.state.formFields.forEach(singleformField => {
        if (singleformField.url !== '' && singleformField.label.length !== '') {
          landingUrls.push({
            url_text: singleformField.url,
            url_number: singleformField.urlNumber,
            label: singleformField.label
          });
        }
      });
    } else { // We are in raw mode

      // First, remove all line breaks
      const cleanedRawText = this.state.rawText.replace('\n', '');

      // and commas
      cleanedRawText.split(',').forEach((singleLine, index) => {

        const splitSingleLine = singleLine.split(';');

        const label = splitSingleLine.shift(); // First match is the label
        const urlText = splitSingleLine.join(';'); // Rest of the string

        landingUrls.push({
          url_text: urlText,
          url_number: this.props.assignedAdPosition.landing_urls.length + index + 1, // Because first index is 0!
          label,
        });
      });
    }

    const payload = {
      accountId: ContextStore.routingParams().accountId,
      assignedAdPositionId: this.props.assignedAdPosition.id,
      landingUrls
    };

    LandingUrl.addMulti(payload);

    this.setState({ loading: true });

  }

  /**
   * Renders extra click form;
   */
  render() {
    const formFields = this.state.formFields.map((singleformField) => {
      return (
        <div className="fields" key={singleformField.internalId}>
          <Field three wide label="label">
            <Input
              value={singleformField.label}
              placeHolder="label"
              onChange={(event) => this.handleLabelChange(event, singleformField.internalId)}
            />
          </Field>
          <Field
            thirteen
            wide
            label={`Extra ${singleformField.urlNumber}`}
          >
            <Input
              value={singleformField.url}
              placeHolder="url"
              onChange={(event) => this.handleUrlChange(event, singleformField.internalId)}
            />
          </Field>
        </div>
      );
    });

    return (
      <Form error={!isEmptyObject(this.state.error)} loading={this.state.loading} tiny>
        <Field>
          <div style={{ display: 'block' }} className="ui right floated">
            <Checkbox toggle onChange={this.toggleRawMode}>
              {'Raw'}
            </Checkbox>
          </div>
        </Field>
        {!this.state.rawMode && (
          <>
            {formFields}
            <p style={{ marginBottom: '30px' }}>
              <a onClick={this.addExtraFormField} className="cursor-pointer">
                {'+ add an extra field'}
              </a>
            </p>
          </>
        )}
        {this.state.rawMode && (
          <>
            <textarea
              rows="10"
              onChange={this.textAreaChange}
              placeholder="newLabel;https://test-url.com?extra1,&#13;&#10;newLabel2;http://test2.com"
            // Line feed and new line HTML entities
            />
            <p style={{ marginBottom: '30px' }}>Enter bulk click data above</p>
          </>
        )}
        <Field>
          <Button
            fluid
            primary
            disabled={this.textAreaValid()}
            onClick={this.handleFormSubmit}
          >
            {'Submit'}
          </Button>
        </Field>
      </Form>
    );
  }
}

export default ExtraClickForm;

ExtraClickForm.propTypes = {
  landingUrl: PropTypes.object,
  labelNumber: PropTypes.number,
  assignedAdPosition: PropTypes.object,
};
