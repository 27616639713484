import React from 'react';
import PropTypes from 'prop-types';
import Message from 'weborama-ui-react/Message';
import {getLocationDescriptor} from 'constants/Utils';
import List from 'weborama-ui-react/List';
import Item from 'weborama-ui-react/Item';
import ContextStore from 'stores/ContextStore';

export const AdPosNotFoundMsg = ((props) => {
  const adPositions = props.adPositions.map((adPosition) => {
    const ld = getLocationDescriptor('adposition', adPosition.cross_version_identifier);
    const handleClick = () => {
      ContextStore.router().replace(ld);
    };
    return (
      <Item key={adPosition.id} onClick={handleClick}>
        <a className="favorite-links">{adPosition.cross_version_identifier}</a>
      </Item>
    );
  });
  return (
    <span>
      <Message warning>
        {'Could not find selected ad position, most likely it is removed. Please select another ad position'}
      </Message>
      <List divided relaxed selection>
        {adPositions}
      </List>
    </span>
  );
});

AdPosNotFoundMsg.propTypes = {
  adPositions: PropTypes.array,
};

export const CreativeNotFoundMsg = ((props) => {
  let creatives;
  if (props.creatives.length > 0) {
    creatives = props.creatives.map((creative) => {
      const ld = getLocationDescriptor('creative', creative.id, true);
      const handleClick = () => {
        ContextStore.router().replace(ld);
      };
      return (
        <Item key={creative.id} onClick={handleClick}>
          <a className="favorite-links">{creative.label}</a>
        </Item>
      );
    });
  } else {
    const ld = getLocationDescriptor('folder', ContextStore.routingParams().folderId, true);
    const handleClick = () => {
      ContextStore.router().replace(ld);
    };
    creatives = (
      <Item onClick={handleClick}>
        <a className="favorite-links">Go back</a>
      </Item>
    );
  }
  return (
    <span>
      <Message warning>
        {`Could not find creative with id ${ContextStore.routingParams().creativeId},
          most likely it is removed. Please select another creative`}
      </Message>
      <List divided relaxed selection>
        {creatives}
      </List>
    </span>
  );
});

CreativeNotFoundMsg.propTypes = {
  creatives: PropTypes.array,
};

export default AdPosNotFoundMsg;
