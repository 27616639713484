import {dispatch} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const User = {
  /**
   * Dispatches an actio to authenticate a user
   * @param {Object} user {username, password}
   */
  login(user) {
    const obj = Object.assign({}, {
      actionType: AppConstants.USER_LOGIN,
    }, user);
    dispatch(obj);
  },
  /**
   * Dispatches an action to log out an user
   * @param {Object} user user object
   */
  logout(user) {
    dispatch({
      actionType: AppConstants.USER_LOGOUT,
      user,
    });
  },
};

export default User;
