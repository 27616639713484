/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import AppConstants from 'constants/AppConstants';
import { getLocationDescriptor, getObjectInArray, isEmptyObject } from 'constants/Utils';
import { Campaign, Token, UIActions } from 'actions';
import {
  ProjectStore,
  CampaignStore,
  InsertionStore,
  AdSpaceStore,
  TagStore,
  FileDownloadStore,
  ContextStore
} from 'stores';
import BreadCrumb, { Crumb } from 'weborama-ui-react/Breadcrumb';
import InsertionManager from 'components/InsertionManager';
import { Column } from 'weborama-ui-react/Grid';
import Menu from 'weborama-ui-react/Menu';
import SelectCampaign from 'components/SelectCampaign';
import Item from 'weborama-ui-react/Item';
import Label from 'weborama-ui-react/Label';
import Loader from 'weborama-ui-react/Loader';
import Modal from 'weborama-ui-react/Modal';
import Dimmer from 'weborama-ui-react/Dimmer';
import Dropdown from 'weborama-ui-react/Dropdown';
import Divider from 'weborama-ui-react/Divider';
import Icon from 'weborama-ui-react/Icon';
/**
 * Campaign manager component
 */
class CampaignManagerModule extends React.Component {
  /**
   * constructor
   * @date   2016-02-29
   */
  constructor() {
    super();

    this.state = {
      projects: [],
      campaigns: [],
      adSpaces: [],
      insertions: [],
      insertionsDetails: [],
      // We only support channel 1 : Display for now.
      channels: [{
        abbreviation: 'D', id: '1', is_visible: 'YES', label: 'Display',
      }],
      selectedProject: {},
      selectedCampaign: {},
      tokens: undefined,
      loading: ContextStore.routingParams().projectId !== undefined,
      fileReady: false,
      oldContext: { // Only here for comparison with current context
        campaignId: undefined,
        projectId: undefined,
        accountId: undefined,
      }
    };

    this.onCampaignStoreChange = this.onCampaignStoreChange.bind(this);
    this.onInsertionStoreChange = this.onInsertionStoreChange.bind(this);
    this.onProjectStoreChange = this.onProjectStoreChange.bind(this);
    this.onAdSpaceStoreChange = this.onAdSpaceStoreChange.bind(this);
    this.handleGenerateTags = this.handleGenerateTags.bind(this);
    this.handleUnSelectCampaign = this.handleUnSelectCampaign.bind(this);
    this.handleDownloadMediaPlan = this.handleDownloadMediaPlan.bind(this);
    this.handleDownloadMediaPlanTemplate = this.handleDownloadMediaPlanTemplate.bind(this);
    this.handleUploadMediaPlan = this.handleUploadMediaPlan.bind(this);
    this.onTagStoreChange = this.onTagStoreChange.bind(this);
    this.onFileDownloadStoreChange = this.onFileDownloadStoreChange.bind(this);
    this.handleUploadDialog = this.handleUploadDialog.bind(this);

    ProjectStore.addChangeListener(this.onProjectStoreChange);
    CampaignStore.addChangeListener(this.onCampaignStoreChange);
    AdSpaceStore.addChangeListener(this.onAdSpaceStoreChange);
    InsertionStore.addChangeListener(this.onInsertionStoreChange);
    TagStore.addChangeListener(this.onTagStoreChange);
    FileDownloadStore.addChangeListener(this.onFileDownloadStoreChange);

    this.fileDownloadModalRef = React.createRef();
    this.dropzoneRef = React.createRef();
  }

  /**
   * Fetch list of creatives
   */
  componentDidMount() {
    if (ContextStore.routingParams().projectId !== undefined) {
      this.setState({ oldContext: ContextStore.routingParams() });
      Campaign.list({ accountId: ContextStore.routingParams().accountId, projectId: ContextStore.routingParams().projectId });
      if (ContextStore.routingParams().campaignId !== undefined) {
        Token.generate({
          accountId: ContextStore.routingParams().accountId,
          projectId: ContextStore.routingParams().projectId,
          campaignId: ContextStore.routingParams().campaignId,
        });
      }
    }
  }

  /**
   * Update state
   * @param  {Object}   props Previous properties
   * @param  {Object}   prevState previous state
   */
  componentDidUpdate(props, prevState) {

    if (ContextStore.routingParams().campaignId !== undefined
      && ContextStore.routingParams().campaignId !== this.state.oldContext.campaignId) {
      const campaign = getObjectInArray(this.state.campaigns, 'id', ContextStore.routingParams().campaignId) || {};
      Token.generate({
        accountId: ContextStore.routingParams().accountId,
        projectId: ContextStore.routingParams().projectId,
        campaignId: ContextStore.routingParams().campaignId,
      });
      this.setState({ selectedCampaign: campaign, oldContext: ContextStore.routingParams() });
    } else if (ContextStore.routingParams().campaignId === undefined && JSON.stringify(this.state.selectedCampaign) !== JSON.stringify({})) {
      this.setState({ selectedCampaign: {} });
    }

    if (ContextStore.router() && ContextStore.router().params) {

      // Get the projectId from the router itself, not from the other routingParams store that is lagging from behind
      // This is a prime example of how messed up our current setup is. All of this should be unified and fixed when we update the router

      const projectMatch = ContextStore.router().params.splat.match(/project\/[\d]+/);

      if (projectMatch) {
        const projectId = parseInt(projectMatch[0].split('/')[1], 10);

        if (ContextStore.routingParams().projectId !== undefined && projectId !== this.state.oldContext.projectId) {
          Campaign.list({ accountId: ContextStore.routingParams().accountId, projectId: projectId });
          this.setState({
            loading: true,
            selectedProject: getObjectInArray(this.state.projects, 'id', ContextStore.router().params.projectId),
            oldContext: ContextStore.routingParams(),
          });
        }
      }
    }

    if (ContextStore.routingParams().accountId !== undefined && ContextStore.routingParams().accountId !== this.state.oldContext.accountId) {
      this.setState({
        insertions: [],
        projects: [],
        campaigns: [],
        adSpaces: [],
        tokens: {},
        selectedCampaign: {},
        selectedProject: {},
        oldContext: ContextStore.routingParams()
      });
    }

    if (prevState.fileReady === false && this.state.fileReady) {
      setTimeout(() => { this.fileDownloadModalRef.current.hide({ transition: 'scale' }); }, 1000);
    }
  }

  /**
   * Remove change listeners
   * @date   2016-08-25
   */
  componentWillUnmount() {
    ProjectStore.removeChangeListener(this.onProjectStoreChange);
    CampaignStore.removeChangeListener(this.onCampaignStoreChange);
    AdSpaceStore.removeChangeListener(this.onAdSpaceStoreChange);
    InsertionStore.removeChangeListener(this.onInsertionStoreChange);
    TagStore.removeChangeListener(this.onTagStoreChange);
    FileDownloadStore.removeChangeListener(this.onFileDownloadStoreChange);
  }

  /**
   * hides modal when change event is remove-creative-modal
   * @date   2016-08-25
   * @param  {object}   data  data object
   */
  onProjectStoreChange(data = {}) {
    if (data.action === AppConstants.LIST_PROJECTS) {
      const projects = ProjectStore.getProjects();
      this.setState({
        projects,
        selectedProject: getObjectInArray(projects, 'id', ContextStore.routingParams().projectId),
      });
    }
  }

  /**
   * hide modal on campaign store change
   * @date   2016-08-29
   * @param  {object}   data data object
   */
  onCampaignStoreChange(data = {}) {
    if (data.action === AppConstants.LIST_CAMPAIGNS) {
      const campaigns = CampaignStore.getCampaigns();
      let selectedCampaign = {};
      if (ContextStore.routingParams().campaignId !== undefined) {
        selectedCampaign = getObjectInArray(campaigns, 'id', ContextStore.routingParams().campaignId);
      }
      this.setState({ loading: false, campaigns, selectedCampaign });
    }
  }

  /**
   * Ad Space Store callback
   * @date   2017-01-19
   * @param  {Object}   data data object
   */
  onAdSpaceStoreChange(data) {
    if (data.action === AppConstants.LIST_ADSPACES_FROM_CAMPAIGN) {
      this.setState({ adSpaces: AdSpaceStore.getCampaignAdSpaces() });
    }
  }

  /**
   * insertion store callbacks
   * @date   2016-08-31
   * @param  {object}   data  data object
   */
  onInsertionStoreChange(data = {}) {
    if (data.action === AppConstants.ADD_INSERTION
      || data.action === AppConstants.UPDATE_INSERTION
      || data.action === AppConstants.DUPLICATE_INSERTION) {
      Token.generate({
        accountId: ContextStore.routingParams().accountId,
        projectId: ContextStore.routingParams().projectId,
        campaignId: ContextStore.routingParams().campaignId,
      });
      Campaign.listAdSpaces({
        accountId: ContextStore.routingParams().accountId,
        campaignId: ContextStore.routingParams().campaignId,
      });
    } else if (data.action === AppConstants.LIST_INSERTIONS) {
      const insertions = InsertionStore.getInsertions();
      this.setState({ insertions });
    } else if (data.action === AppConstants.LIST_INSERTION_DETAILS) {
      this.setState({ insertionsDetails: InsertionStore.getDetails() });
    }
  }

  /**
   * Tag Store change callback
   */
  onTagStoreChange() {
    this.setState({ tokens: TagStore.getTokens() });
  }

  /**
   * handles file download (mediaplan)
   */
  onFileDownloadStoreChange() {
    this.setState({ fileReady: true });
  }

  /**
   * Opens generate tags-token modal
   */
  handleGenerateTags() {
    UIActions.generateTags({
      adSpaces: this.state.adSpaces,
      insertions: this.state.insertions,
      campaign: this.state.selectedCampaign,
    });
  }

  /**
   * Handles unselect campaign (back to campaign list)
   */
  handleUnSelectCampaign() {
    const ld = getLocationDescriptor('project', ContextStore.routingParams().projectId, true);
    ContextStore.router().push(ld);
  }

  /**
   * handles downloading media plan
   */
  handleDownloadMediaPlan() {
    this.setState({ fileReady: false });
    this.fileDownloadModalRef.current.show();
    Campaign.downloadMediaPlan({
      token: this.state.tokens.campaign.token,
    });
  }

  /**
   * Handles downloading media plan template
   */
  handleDownloadMediaPlanTemplate() {
    this.setState({ fileReady: false });
    this.fileDownloadModalRef.current.show();
    Campaign.downloadMediaPlanTemplate();
  }

  /**
   * Opens file upload
   */
  handleUploadDialog() {
    this.dropzoneRef.current.open();
  }

  /**
   * Handles upload media plan
   * @param {array} files files
   */
  handleUploadMediaPlan(files) {
    Campaign.uploadMediaPlan({
      accountId: ContextStore.routingParams().accountId,
      projectId: ContextStore.routingParams().projectId,
      campaignId: ContextStore.routingParams().campaignId,
      files,
    });
  }

  /**
   * Renders campaign manager
   * @date   2016-02-29
   * @return {Jsx}  Campaign Manager
   */
  render() {
    let labels;
    if ('campaign_labels' in this.state.selectedCampaign
      && typeof this.state.selectedCampaign.campaign_labels === 'object') {
      labels = this.state.selectedCampaign.campaign_labels.map(item => (
        <Label key={item.id}>
          <Icon tag />
          {item.label}
        </Label>
      ));
    }
    return (
      <Column twelve wide>
        <Menu small secondary>
          {ContextStore.routingParams().projectId
            && (
              <Item>
                <BreadCrumb>
                  <Dropdown floating labeled menu isMenu>
                    <Icon write square />
                    <Menu>
                      <Item onClick={() => { UIActions.editProject(this.state.selectedProject); }}>
                        <Icon edit />
                        {'Edit project'}
                      </Item>
                      <Item onClick={() => { UIActions.removeProject(this.state.selectedProject); }}>
                        <Icon red trash />
                        {'Remove project'}
                      </Item>
                    </Menu>
                  </Dropdown>
                  <Crumb
                    divider={ContextStore.routingParams().campaignId !== undefined}
                    active={ContextStore.routingParams().campaignId === undefined}
                    onClick={this.handleUnSelectCampaign}
                  >
                    {this.state.selectedProject.label}
                  </Crumb>
                  {!isEmptyObject(this.state.selectedCampaign)
                    && (
                      <Dropdown floating labeled menu isMenu>
                        <Icon write square />
                        <Menu>
                          <Item onClick={() => { UIActions.editCampaign(this.state.selectedCampaign); }}>
                            <Icon edit />
                            {'Edit campaign'}
                          </Item>
                          <Item
                            disabled={this.state.selectedCampaign.has_statistics === 'YES'}
                            onClick={() => { UIActions.removeCampaign(this.state.selectedCampaign); }}
                          >
                            <Icon red trash />
                            {'Remove campaign'}
                          </Item>
                        </Menu>
                      </Dropdown>
                    )
                  }
                  <Crumb active>{this.state.selectedCampaign.label}</Crumb>
                </BreadCrumb>
              </Item>
            )
          }
          <Menu secondary right>
            <Item>
              <div className="ui blue small labels" style={{ float: 'right' }}>
                {labels}
              </div>
            </Item>
          </Menu>
        </Menu>
        <Divider />
        {!isEmptyObject(this.state.selectedCampaign)
          && (
            <InsertionManager
              campaign={this.state.selectedCampaign}
              insertions={this.state.insertions}
              insertionsDetails={this.state.insertionsDetails}
              adSpaces={this.state.adSpaces}
              channels={this.state.channels}
              tokens={this.state.tokens}
              onDownloadMediaPlanTemplate={this.handleDownloadMediaPlanTemplate}
              onDownloadMediaPlan={this.handleDownloadMediaPlan}
              onUploadMediaPlan={this.handleUploadMediaPlan}
              onGenerateTags={this.handleGenerateTags}
            />
          )
        }
        {isEmptyObject(this.state.selectedCampaign) && ContextStore.routingParams().projectId
          && <SelectCampaign campaigns={this.state.campaigns} />
        }
        {!ContextStore.routingParams().projectId
          && (
            <Dimmer inverted active>
              <div className="content">
                <div className="center">
                  <h2 className="ui grey header">
                    {'Please select a project'}
                  </h2>
                </div>
              </div>
            </Dimmer>
          )
        }
        <Dimmer inverted active={this.state.loading}>
          <Loader indeterminate inverted text>
            {`Loading ${this.state.selectedProject.label}`}
          </Loader>
        </Dimmer>
        <Modal small ref={this.fileDownloadModalRef}>
          <div style={{ height: '240px' }}>
            <Loader text inverted active={!this.state.fileReady}>Preparing your file...</Loader>
            {this.state.fileReady
              && (
                <h3 className="ui icon header center aligned" style={{ position: 'relative', top: '50px' }}>
                  <Icon green checkmark />
                  <div className="content">
                    {'Your file is ready'}
                  </div>
                </h3>
              )
            }
          </div>
        </Modal>
      </Column>
    );
  }
}

export default CampaignManagerModule;
