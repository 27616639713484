/* eslint react/no-did-update-set-state: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import {getObjectInArray, getLocationDescriptor} from 'constants/Utils';
import {AdElement, AdPosition} from 'actions';
import ContextStore from 'stores/ContextStore';
import Menu from 'weborama-ui-react/Menu';
import Button from 'weborama-ui-react/Button';
import Icon from 'weborama-ui-react/Icon';
import './index.sass';
/**
 * Class Adpositon Menu
 */
class AdPositionMenu extends React.Component {
  /**
   * show small animation when hover over element when dragging
   * @date   2016-08-24
   * @param  {object}   e event object
   */
  static handleDragEnter(e) {
    $(e.target).addClass('on-drop-over');
    $(e.target).transition('pulse');
  }

  /**
   * Remove drag over className
   * @date   2016-08-24
   * @param  {object}   e event object
   */
  static handleDragLeave(e) {
    document.body.style.cursor = 'initial';
    $(e.target).removeClass('on-drop-over');
  }

  /**
   * Cancel event, this is needed to have the onDrop event to pass through
   * @date   2016-08-24
   * @param  {Object}   e event object
   */
  static handleDragOver(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  /**
   * Dispatches action to add a new ad position
   * @date   2016-09-21
   */
  // static handleNewAdPosition() {
  //   UIActions.addAdPosition();
  // }
  /**
   * constructor
   * @date   2016-09-09
   * @param  {object}   props properties
   */
  constructor(props, context) {
    super(props, context);

    this.state = {
      adPositions: props.adPositions || [],
      sizes: ContextStore.sizes() || [],
    };

    this.handleSelectAdPosition = this.handleSelectAdPosition.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleNewAdPosition = this.handleNewAdPosition.bind(this);
    this.calculateState = this.calculateState.bind(this);
  }

  componentDidMount() {
    this.calculateState();
  }

  componentDidUpdate() {
    this.calculateState();
  }

  /*
  * Initialize component state and check whether to update state
  */
  calculateState() {
    if (JSON.stringify(this.props.adPositions) !== JSON.stringify(this.state.adPositions)) {
      this.setState({adPositions: this.props.adPositions});
    }
    if (ContextStore.sizes() !== undefined && JSON.stringify(ContextStore.sizes()) !== JSON.stringify(this.state.sizes)) {
      this.setState({sizes: ContextStore.sizes()});
    }
  }

  /**
   * Handles routing to another ad position
   * @date   2016-08-16
   * @param  {object}   e event object
   */
  handleSelectAdPosition(e) {
    const ld = getLocationDescriptor('adposition', e.currentTarget.dataset.id);
    ContextStore.router().push(ld);
    ContextStore.router().push(ld); // Pushing it twice will work when getChildContext is removed at App
  }

  /**
   * Update ad position when element has been dropped
   * @date   2016-08-24
   * @param  {object}   e event object
   */
  handleDrop(e) {
    const adElementRoleId = e.dataTransfer.getData('roleid');
    const id = Array(e.dataTransfer.getData('id'));
    const adPositionId = e.target.dataset.id;

    AdElement.updateRole({
      accountId: ContextStore.routingParams().accountId,
      folderId: ContextStore.routingParams().folderId,
      creativeId: ContextStore.routingParams().creativeId,
      adElementIds: id,
      adElementRoleId,
      creativeVersionId: this.props.adPositions[0].creative_version_id,
      originalAdPositionId: ContextStore.routingParams().adPositionId,
      adPositionId,
    });

    $(e.target).removeClass('on-drop-over');
  }

  /**
   * Handles new ad position
   */
  handleNewAdPosition() {
    AdPosition.add({
      accountId: ContextStore.routingParams().accountId,
      folderId: ContextStore.routingParams().folderId,
      creativeId: ContextStore.routingParams().creativeId,
      creativeVersionId: this.props.creative.latest_creative_version_id,
    });
  }

  /**
   * [render description]
   * @date   2016-09-09
   * @return {[type]}   [description]
   */
  render() {
    let addAdPositionButton = [];
    if (parseInt(this.props.creativeType.id, 10) === 6
      && !parseInt(this.props.creative.live_creative_version_id, 10) > 0) {
      addAdPositionButton = (
        <Button small primary icon onClick={this.handleNewAdPosition}>
          <Icon add />
          {'Add position'}
        </Button>
      );
    }
    const items = this.state.adPositions.map((item) => {
      let itemClassName = 'item cursor-pointer';
      const size = getObjectInArray(this.state.sizes, 'id', item.size_id);
      const {label} = size;
      if (parseInt(item.cross_version_identifier, 10) === ContextStore.routingParams().adPositionId) {
        itemClassName = 'active item';
        // TODO: Move this to horizontal menu
        // label = (
        //   <SizesDropdown defaultValue={item.size_id} sizes={this.state.sizes} onChange={this.handleSizeChange}/>
        // );
      }
      return (
        <a
          key={`size-${item.id}`}
          data-id={item.cross_version_identifier}
          onDragEnter={AdPositionMenu.handleDragEnter}
          onDragLeave={AdPositionMenu.handleDragLeave}
          onDragOver={AdPositionMenu.handleDragOver}
          onDrop={this.handleDrop}
          onClick={this.handleSelectAdPosition}
          className={itemClassName}
        >
          {label}
        </a>
      );
    });
    return (
      <Menu secondary>
        <div className="item">
          <h5 className="ui header"><i>Positions:</i></h5>
        </div>
        {items}
        {addAdPositionButton}
      </Menu>
    );
  }
}

export default AdPositionMenu;

AdPositionMenu.propTypes = {
  adPositions: PropTypes.array,
  creativeType: PropTypes.object,
  creative: PropTypes.object,
};
