import {dispatch} from 'dispatchers/AppDispatcher';
import TagConstants from 'constants/TagConstants';

export default {
  /**
   * Dispatches an action to get tags from a token
   * @date   2017-01-26
   * @param  {String}   token token
   */
  getTagsFromToken(token) {
    dispatch({
      actionType: TagConstants.GET_TAGS_FROM_TOKEN,
      token,
    });
  },
  /**
   * Dispatches an action to get all the tags in a zip file from a token
   * @date   2017-01-26
   * @param  {String}   token token
   */
  getZipFileFromToken(token) {
    dispatch({
      actionType: TagConstants.GET_ZIP_FILE_FROM_TOKEN,
      token,
    });
  },
  /**
   * Dispatches an action to get all tags in a text file base by token
   * @date   2017-01-26
   * @param  {String}   token token
   */
  getTextFileFromToken(token) {
    dispatch({
      actionType: TagConstants.GET_TEXT_FILE_FROM_TOKEN,
      token,
    });
  },
};
