import BaseStore from 'stores/BaseStore';
import AppConstants from 'constants/AppConstants';
import {AdSpace} from 'constants/ServerConstants';
import {register} from 'dispatchers/AppDispatcher';
import UIActions from 'actions/UIActions';
import ToastrActions from 'actions/Toastr';

const AdSpaceStore = Object.assign({}, BaseStore, {
  adSpaces: [],
  campaignAdSpaces: [],
  placements: [],
  req: {},
  /**
   * returns array of adspaces
   * @date   2016-03-31
   * @return {Array}   Array of adSpaces
   */
  getAdSpaces() {
    return this.adSpaces;
  },
  /**
   * reeturns array of campaign ad spaces
   */
  getCampaignAdSpaces() {
    return this.campaignAdSpaces;
  },
  /**
   * Returns array of placements
   * @date   2016-11-02
   * @return {Array}   Placements
   */
  getPlacements() {
    return this.placements;
  },
  /**
   * Fetches all adspaces by account id
   * @date   2016-03-31
   * @param  {Number}   accountId Account Id
   */
  listAdSpaces(queryData) {
    const onSuccess = (response) => {
      this.adSpaces = response.data.slice();
      this.emitChange({action: AppConstants.LIST_ADSPACES});
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.LIST_ADSPACES, message});
    };

    const orderSettings = {
      status: 'active',
      order_fields: ['label'],
      order_direction: 'asc',
    };

    const data = Object.assign({}, queryData, orderSettings);

    this.post({
      url: AdSpace.LIST,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Adds an ad space
   * @param {Object} data data object
   */
  addAdSpace(data) {
    const onSuccess = () => {
      this.emitChange({action: AppConstants.ADD_ADSPACE});
      this.listAdSpaces({account_id: data.account_id});
    };
    const onError = (message) => {
      this.emitError({action: AppConstants.ADD_ADSPACE, message});
    };

    this.post({
      url: AdSpace.CREATE,
      data,
      onSuccess,
      onError,
    });
  },

  /**
   * Adds an ad space, then opens the Add Insertion popup
   * @param {Object} data data object
   */
  addSiteOffer(data) {
    const onSuccess = () => {
      this.emitChange({action: AppConstants.ADD_SITE_OFFER});
      this.listAdSpaces({account_id: data.account_id});
      UIActions.addInsertion(data.label); // Reopen the addInsertion popup and populate the add site/offer area
      // The 'data' we are sending is the post request to the server.
      // We're using the label in the data(so site/offer name) to tell the InsertionForm which site/offer should populate the field.
      ToastrActions.success({ title: data.label, body: 'A new site/offer has been created' });
    };
    const onError = (message) => {
      this.emitError({action: AppConstants.ADD_SITE_OFFER, message});
    };

    this.post({
      url: AdSpace.CREATE,
      data,
      onSuccess,
      onError,
    });
  },
  updateAdSpace(data) {
    const onSuccess = () => {
      this.emitChange({action: AppConstants.UPDATE_ADSPACE});
      this.listAdSpaces({account_id: data.account_id});
    };
    const onError = (message) => {
      this.emitError({action: AppConstants.UPDATE_ADSPACE, message});
    };
    this.post({
      url: AdSpace.UPDATE,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Fetches list of placements for adspace
   * @date   2016-11-02
   * @param  {Number}   accountId Account Id
   * @param  {Number}   adSpaceId Ad Space Id
   */
  listPlacements(data) {
    const onSuccess = (response) => {
      this.placements = response.data.slice();
      this.emitChange({action: AppConstants.LIST_ADSPACE_PLACEMENTS});
    };
    const onError = (message) => {
      this.emitError({action: AppConstants.LIST_ADSPACE_PLACEMENTS, message});
    };

    this.post({
      url: AdSpace.LIST_PLACEMENTS,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Fetches list of adspaces in campaign
   * @date   2016-11-02
   * @param  {Number}   accountId  account id
   * @param  {Number}   campaignId campaign id
   */
  listAdSpacesFromCampaign(data) {
    if (typeof this.req.abort === 'function' && this.req.readyState !== 4) {
      this.req.abort();
    }

    const onSuccess = (response) => {
      this.campaignAdSpaces = response.data.slice();
      this.emitChange({action: AppConstants.LIST_ADSPACES_FROM_CAMPAIGN});
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.LIST_ADSPACES_FROM_CAMPAIGN, message});
    };

    this.req = this.post({
      url: AdSpace.LIST_CAMPAIGN_ADSPACES,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Updates campaign adsapces landing url
   * @date   2016-11-29
   * @param  {Number}   accountId  Account Id
   * @param  {Number}   adSpaceCampaignId  Ad Space campaign Id
   * @param  {String}   landingUrl Landing url
   */
  updateCampaignAdSpace(data) {
    const onSuccess = (response) => {
      this.listAdSpacesFromCampaign({
        account_id: data.account_id,
        id: response.data.campaign_id,
      });
      this.emitChange({action: AppConstants.UPDATE_CAMPAIGN_ADPSACES});
      ToastrActions.success({ title: '', body: 'Campaign ad space has been updated' });
    };
    const onError = (message) => {
      this.emitError({action: AppConstants.UPDATE_CAMPAIGN_ADPSACES, message, data});
    };
    this.post({
      url: AdSpace.UPDATE_CAMPAIGN_ADSPACES,
      data,
      onSuccess,
      onError,
    });
  },
  removeAdSpace(data) {
    const onSuccess = () => {
      this.listAdSpaces({account_id: data.account_id});
      this.emitChange({action: AppConstants.REMOVE_ADSPACE});
    };
    const onError = (message) => {
      this.emitError({action: AppConstants.REMOVE_ADSPACE, message});
    };
    this.post({
      url: AdSpace.REMOVE,
      data,
      onSuccess,
      onError,
    });
  },
  dispatcherIndex: register((action) => {
    switch (action.actionType) {
      case AppConstants.LIST_ADSPACES:
        AdSpaceStore.listAdSpaces({account_id: action.accountId});
        break;
      case AppConstants.ADD_ADSPACE:
        AdSpaceStore.addAdSpace({
          account_id: action.accountId,
          ad_network_id: action.adNetworkId,
          country_id: action.countryId,
          ad_server_id: action.adServerId,
          ad_space_type: action.siteOffer,
          label: action.label,
          bursttype: action.burstType,
          burstpath: action.burstPath,
          external_id: action.externalId,
          external_name: action.externalName,
          zindex: action.ZIndex,
        });
        break;
      case AppConstants.UPDATE_ADSPACE:
        AdSpaceStore.updateAdSpace({
          account_id: action.accountId,
          id: action.adSpaceId,
          country_id: action.countryId,
          ad_server_id: action.adServerId,
          ad_space_type: action.siteOffer,
          label: action.label,
          bursttype: action.burstType,
          burstpath: action.burstPath,
          external_id: action.externalId,
          external_name: action.externalName,
          zindex: action.ZIndex,
        });
        break;
      case AppConstants.ADD_SITE_OFFER:
        AdSpaceStore.addSiteOffer({
          account_id: action.accountId,
          ad_network_id: action.adNetworkId,
          country_id: action.countryId,
          ad_server_id: action.adServerId,
          ad_space_type: action.siteOffer,
          label: action.label,
          bursttype: action.burstType,
          burstpath: action.burstPath,
          external_id: action.externalId,
          external_name: action.externalName,
          zindex: action.ZIndex,
        });
        break;
      case AppConstants.LIST_ADSPACE_PLACEMENTS:
        AdSpaceStore.listPlacements({
          account_id: action.accountId,
          id: action.adSpaceId,
        });
        break;
      case AppConstants.LIST_ADSPACES_FROM_CAMPAIGN:
        AdSpaceStore.listAdSpacesFromCampaign({
          account_id: action.accountId,
          id: action.campaignId,
        });
        break;
      case AppConstants.UPDATE_CAMPAIGN_ADPSACES:
        AdSpaceStore.updateCampaignAdSpace({
          account_id: action.accountId,
          id: action.campaignAdSpaceId,
          landing_url: action.url,
        });
        break;
      case AppConstants.REMOVE_ADSPACE:
        AdSpaceStore.removeAdSpace({
          account_id: action.accountId,
          id: action.adSpaceId,
        });
        break;
      // no default
    }
    return true;
  }),
});

export default AdSpaceStore;
