import {dispatch} from '../dispatchers/AppDispatcher';
import AppConstants from '../constants/AppConstants';

export default {
  /**
   * Dispatches an action to retrieve a list of accounts
   * @date   2016-09-27
   */
  listAccounts() {
    dispatch({
      actionType: AppConstants.LIST_ACCOUNTS,
    });
  },
  /**
   * Dispatches an action to retrieve a list of recent accounts
   * @date   2016-09-27
   */
  listRecentAccounts() {
    dispatch({
      actionType: AppConstants.LIST_RECENT_ACCOUNTS,
    });
  },
  /**
   * Dispatches an action to clear the cache for this account
   * @date   2016-09-27
   * @param  {Number}   accountId Account Id
   */
  clearCache(accountId) {
    dispatch({
      actionType: AppConstants.CLEAR_CACHE,
      accountId,
    });
  },
  get(accountId) {
    dispatch({
      actionType: AppConstants.GET_ACCOUNT,
      accountId,
    });
  },

  /**
  * Dispatches an action to clear all cache for accounts
  */
  clearAllCache() {
    dispatch({
      actionType: AppConstants.CLEAR_ALL_CACHE,
    });
  },
};
