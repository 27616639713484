import PropTypes from 'prop-types';
import React from 'react';
import {Grid, Column, Row} from 'weborama-ui-react/Grid';
import Table from 'weborama-ui-react/Table';
import {isEmptyObject, getObjectInArray, getDateFromTimeStamp} from 'constants/Utils';
import Button, {Buttons} from 'weborama-ui-react/Button';
import {UIActions} from 'actions';
import Divider from 'weborama-ui-react/Divider';
import CampaignStatistics from 'components/CampaignStatistics';
import Segment from 'weborama-ui-react/Segment';

const CampaignRow = ((props) => {
  if (isEmptyObject(props.campaign)) {
    return (
      <Row />
    );
  }
  const channel = getObjectInArray(props.channels, 'id', props.campaign.channel_id);

  let startDateFormat = 'auto';
  if (props.campaign.real_start_date !== null) {
    startDateFormat = getDateFromTimeStamp(new Date(props.campaign.real_start_date).getTime());
  }
  const startDate = (
    <span>
      <i className="clock icon" />
      {startDateFormat}
      {'\u00a0\u00a0'}
    </span>
  );

  let endDateFormat = 'auto';
  if (props.campaign.real_end_date !== null) {
    endDateFormat = getDateFromTimeStamp(new Date(props.campaign.real_end_date).getTime());
  }
  const endDate = (
    <span>
      <i className="flag checkered icon" />
      {endDateFormat}
    </span>
  );
  return (
    <Grid one column>
      <Row>
        <Column>
          <CampaignStatistics campaign={props.campaign} />
          <Divider hidden />
          <Divider hidden />
          <Segment basic>
            <Table very basic>
              <tbody>
                <tr>
                  <td />
                  <td><b>Channel</b></td>
                  <td colSpan="2">
                    {channel.label}
                  </td>
                </tr>
                <tr>
                  <td />
                  <td><b>Stage: </b></td>
                  <td><b>{props.campaign.stage}</b></td>
                  <td><b>Start Date</b></td>
                  <td>{startDate}</td>
                </tr>
                <tr>
                  <td />
                  <td><b>Landing Url</b></td>
                  <td style={{maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                    <a
                      href={props.campaign.landing_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {props.campaign.landing_url}
                    </a>
                  </td>
                  <td><b>End Date</b></td>
                  <td>{endDate}</td>
                </tr>
              </tbody>
            </Table>
          </Segment>
          <Divider hidden />
          <Buttons two small>
            <Button primary onClick={() => { UIActions.editCampaign(props.campaign); }}>
              <i className="edit icon" />
              {'Edit campaign'}
            </Button>
            <Button
              red
              disabled={props.campaign.has_statistics === 'YES'}
              onClick={() => { UIActions.removeCampaign(props.campaign); }}
            >
              <i className="trash icon" />
              {'Remove campaign'}
            </Button>
          </Buttons>
        </Column>
      </Row>
    </Grid>
  );
});

export default CampaignRow;

CampaignRow.propTypes = {
  campaign: PropTypes.object,
  channels: PropTypes.array,
};
