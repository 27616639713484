/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import AppConstants from 'constants/AppConstants';
import { getObjectInArray, isEmptyObject, getLocationDescriptor } from 'constants/Utils';
import { Creative, AdPosition, Toastr as ToastrActions } from 'actions';
import { FolderStore, CreativeStore, AdPositionStore, ContextStore, CreativeTypeStore, CreativeFormatStore } from 'stores';
import AdPositionWrapper from 'components/AdPositionWrapper';
import { Grid, Column } from 'weborama-ui-react/Grid';
import SelectCreative from 'components/SelectCreative';
import PropTypes from 'prop-types';
import Loader from 'weborama-ui-react/Loader';
import Dimmer from 'weborama-ui-react/Dimmer';
import FolderDetails from 'components/FolderDetails';
import CreativeWarningWCM from 'components/CreativeWarningWCM';
/**
 * Creative Manager component
 */
class CreativeLibraryModule extends React.Component {
  /**
   * Initializes component
   * @date   2016-01-28
   */
  constructor(props) {
    super(props);
    this.state = {
      folders: [],
      creatives: [],
      adPositions: [],
      selectedFolder: {},
      selectedCreative: {},
      creativeType: {},
      creativeFormat: {},
      loading: ContextStore.routingParams().folderId !== undefined,
      oldContextRoutingParams: { // Object properties will change when it's overriden by current context
        accountId: undefined,
        creativeId: undefined,
        folderId: undefined
      },
      oldRouterLocation: this.props.router.location.pathname
    };

    this.onFolderStoreChange = this.onFolderStoreChange.bind(this);
    this.onCreativesChange = this.onCreativesChange.bind(this);
    this.onAdPositionStoreChange = this.onAdPositionStoreChange.bind(this);
    this.handleUnSelectCreative = this.handleUnSelectCreative.bind(this);
    // this.handleEditCreative = this.handleEditCreative.bind(this);
    // this.handleRemoveCreative = this.handleRemoveCreative.bind(this);
    // this.handleDuplicateCreative = this.handleDuplicateCreative.bind(this);
    // this.handleDownloadCreative = this.handleDownloadCreative.bind(this);
    this.handlePushLive = this.handlePushLive.bind(this);

    CreativeStore.addChangeListener(this.onCreativesChange);
    FolderStore.addChangeListener(this.onFolderStoreChange);
    AdPositionStore.addChangeListener(this.onAdPositionStoreChange);
  }

  /**
   * Fetch list of creatives;
   */
  componentDidMount() {
    if (ContextStore.routingParams().folderId !== undefined) {
      Creative.list({ accountId: ContextStore.routingParams().accountId, folderId: ContextStore.routingParams().folderId });
    }
  }

  /**
   * Determine component state
   * @param  {Object}   prevProps Previous properties
   * @param  {Object}   prevState previous state
   */
  componentDidUpdate(prevProps, prevState) {

    this.setAdPositionId();

    if (!isEmptyObject(this.state.selectedCreative)
      && JSON.stringify(this.state.selectedCreative) !== JSON.stringify(prevState.selectedCreative)) {
      AdPosition.list({
        accountId: ContextStore.routingParams().accountId,
        folderId: ContextStore.routingParams().folderId,
        creativeId: ContextStore.routingParams().creativeId,
        creativeVersionId: this.state.selectedCreative.latest_creative_version_id,
      });
    }

    if (ContextStore.routingParams().accountId !== this.state.oldContextRoutingParams.accountId) {
      this.setState({ creatives: [], selectedCreative: {}, oldContextRoutingParams: ContextStore.routingParams() });
    }

    if (ContextStore.routingParams().creativeId !== undefined
      && ContextStore.routingParams().creativeId !== this.state.oldContextRoutingParams.creativeId) {
      const creative = getObjectInArray(this.state.creatives, 'id', ContextStore.routingParams().creativeId) || {};
      const creativeFormat = getObjectInArray(CreativeFormatStore.getCreativeFormats(), 'id', creative.creative_format_id) || {};
      let creativeType = {};
      if (!isEmptyObject(creativeFormat)) {
        creativeType = getObjectInArray(CreativeTypeStore.getCreativeTypes(), 'id', creativeFormat.creative_type_id);
      }
      this.setState({ selectedCreative: creative, creativeFormat, creativeType, oldContextRoutingParams: ContextStore.routingParams() });
    } else if (ContextStore.routingParams().creativeId === undefined
      && this.state.oldContextRoutingParams !== undefined
      && !isEmptyObject(this.state.selectedCreative)) {
      this.setState({ selectedCreative: {}, creativeType: {}, creativeFormat: {}, oldContextRoutingParams: ContextStore.routingParams() });
    }

    if (ContextStore.router().params && ContextStore.router().params.splat) {
      // Get the folderId from the router itself, not from the other routingParams store that is lagging from behind
      // This is a prime example of how messed up our current setup is. All of this should be unified and fixed when we update the router
      const folderMatch = ContextStore.router().params.splat.match(/folder\/[\d]+/);

      if (folderMatch) {
        const folderId = parseInt(folderMatch[0].split('/')[1], 10);

        if (folderId !== this.state.oldContextRoutingParams.folderId) {
          Creative.list({ accountId: ContextStore.routingParams().accountId, folderId: folderId });
          this.setState({
            loading: true,
            selectedFolder: getObjectInArray(this.state.folders, 'id', folderId) || {},
            oldContextRoutingParams: ContextStore.routingParams(),
          });
        }
      }
    }
  }

  /**
   * unregister onChange eventlistener
   * @date   2016-01-21
   */
  componentWillUnmount() {
    CreativeStore.removeChangeListener(this.onCreativesChange);
    FolderStore.removeChangeListener(this.onFolderStoreChange);
    AdPositionStore.removeChangeListener(this.onAdPositionStoreChange);
  }

  setAdPositionId() {
    // See README on why this code is here
    if (this.props.router && this.props.router.location
      && (this.state.oldRouterLocation !== this.props.router.location.pathname)
      && ContextStore.routingParams().adPositionId === undefined) {
      // The only intention is to make sure we have an adPositionId when we are at CreativeLibrary screen!
      // Once it's in place, everything should start working anyway.
      // Also note that we're substituting what the App component is supposed to be doing, so the if statement here is similar
      if (this.props.routeParams.splat) {
        const adPositionParamFromUrl = this.props.routeParams.splat.match(/adposition\/\d+/);
        // find the adposition and adposition id in the URL
        if (adPositionParamFromUrl) {
          const adPositionNumberFromUrl = Number(adPositionParamFromUrl[0].toString().split('/')[1]); // Extract the adposition id and turn it into a number
          const contextAdPositionId = Object.assign(ContextStore.routingParams(), { adPositionId: adPositionNumberFromUrl });
          ContextStore.setRoutingParams(contextAdPositionId); // Make sure it's in the routingParams
        }
        this.setState({
          oldRouterLocation: this.props.router.location.pathname,
        }); // Note that we don't really need to set state. We are doing it because we want to trigger a re-render
        // of this component and all child components. This way, they will re-render with adPositionId and they will work as intended.
        // This is mimicking what was happening on App component
      }
    }
  }

  /**
   * callback for ad position store change listener
   * @date   2016-09-27
   * @param  {Object}   data data Object
   */
  onAdPositionStoreChange(data) {
    if (data.action === AppConstants.LIST_ADPOSITIONS) {
      this.setState({ adPositions: AdPositionStore.getAdPositions() });
    }
  }

  /**
   * Callback for changeEventListener.
   * When change is passed. Loading is set to false.
   * @date   2016-01-21
   * @param {object} data data
   */
  onCreativesChange(data) {
    if (data.action === AppConstants.LIST_CREATIVES) {
      const creatives = CreativeStore.getCreatives();
      let selectedCreative = {};
      let creativeFormat = {};
      let creativeType = {};
      if (ContextStore.routingParams().creativeId !== undefined) {
        selectedCreative = getObjectInArray(creatives, 'id', ContextStore.routingParams().creativeId) || {};
        creativeFormat = getObjectInArray(CreativeFormatStore.getCreativeFormats(), 'id', selectedCreative.creative_format_id);
        creativeType = getObjectInArray(CreativeTypeStore.getCreativeTypes(), 'id', creativeFormat.creative_type_id);
      }
      this.setState({
        creativeFormat,
        creativeType,
        selectedCreative,
        creatives,
        loading: false,
      });
    } else if (data.action === AppConstants.GET_CREATIVE) {
      // this.setState({selectedCreative: CreativeStore.getCreative()});
    } else if (data.action === AppConstants.PUSH_LIVE) {
      Creative.list({
        accountId: ContextStore.routingParams().accountId,
        folderId: ContextStore.routingParams().folderId,
      });
      ToastrActions.success({ title: this.state.selectedCreative.label, body: 'Creative has been pushed to live' });
    }
  }

  /**
   * FolderStore callback
   * @date   2016-09-23
   * @param  {Object}   data data object
   */
  onFolderStoreChange(data) {
    if (data.action === AppConstants.LIST_FOLDERS) {
      const folders = FolderStore.getFolders();
      this.setState({ folders, selectedFolder: getObjectInArray(folders, 'id', ContextStore.routingParams().folderId) || {} });
    }
  }

  handleUnSelectCreative() {
    const ld = getLocationDescriptor('folder', ContextStore.routingParams().folderId, true);
    ContextStore.router().push(ld);
  }

  /**
   * Handles pushing live a creative
   */
  handlePushLive() {
    Creative.pushLive({
      accountId: ContextStore.routingParams().accountId,
      folderId: ContextStore.routingParams().folderId,
      creativeId: this.state.selectedCreative.id,
    });
  }

  /**
   * Handles pushing live a creative from select creative menu
   */
  handlePushLiveFromSelectCreative(creativeId) {
    Creative.pushLive({
      accountId: ContextStore.routingParams().accountId,
      folderId: ContextStore.routingParams().folderId,
      creativeId: creativeId,
    });
  }

  /**
   * Renders CreativeManager component
   * @date   2016-01-28
   * @return {Jsx}   CreativeManager
   */
  render() {
    // let labels;
    // if ('creative_labels' in this.state.selectedCreative
    //     && typeof this.state.selectedCreative.creative_labels === 'object') {
    //   labels = this.state.selectedCreative.creative_labels.map(item => (
    //     <Label key={item.id}>
    //       <Icon tag />
    //       {item.label}
    //     </Label>
    //   ));
    // }
    return (
      <Grid one column>
        {ContextStore.routingParams().creativeId === undefined && (
          <Column>
            <FolderDetails folder={this.state.selectedFolder} />
          </Column>
        )}
        {!isEmptyObject(this.state.selectedCreative)
        && this.state.selectedCreative?.warm_creative_id
          ? (
            <AdPositionWrapper
              adPositions={this.state.adPositions}
              creative={this.state.selectedCreative}
              creativeType={this.state.creativeType}
              creativeFormat={this.state.creativeFormat}
              onPushLive={this.handlePushLive}
            />
          )
          : ContextStore.routingParams().creativeId && this.state.selectedCreative?.warm_creative_id !== undefined
            // this check is here because when navigating back from CreativeWarningWCM, CreativeWarningWCM should not Render
            // also, for one second after creating a creative warm_creative_id = undefined,so CreativeWarningWCM should not Render
            && (
            <CreativeWarningWCM
              creative={this.state.selectedCreative}
              selectedFolder={this.state.selectedFolder}
            />
            )
        }
        {isEmptyObject(this.state.selectedCreative) && ContextStore.routingParams().folderId
          && (
          <SelectCreative
            folder={this.state.selectedFolder}
            onPushLive={this.handlePushLiveFromSelectCreative}
            creatives={this.state.creatives}
          />
          )
        }
        {!ContextStore.routingParams().folderId
          && (
            <Dimmer
              style={{
                zIndex: 0,
              }} // In this scenario, Dimmer should not overlap menus from the header
              inverted
              active
            >
              <div
                className="content"
              >
                <div className="center">
                  <h2 className="ui grey header">
                    {'Please select a folder'}
                  </h2>
                </div>
              </div>
            </Dimmer>
          )
        }
        <Dimmer inverted active={this.state.loading}>
          <Loader inverted indeterminate text>
            {`Loading ${this.state.selectedFolder.label}`}
          </Loader>
        </Dimmer>
      </Grid>
    );
  }
}

export default CreativeLibraryModule;

CreativeLibraryModule.propTypes = {
  routeParams: PropTypes.object,
  router: PropTypes.object,
};
