import React from 'react';
import PropTypes from 'prop-types';
import {StatisticStore, ContextStore} from 'stores';
import {Statistic as StatAction} from 'actions';
import CountUp from 'react-countup';
import Segment from 'weborama-ui-react/Segment';
import Statistic, {Statistics} from 'weborama-ui-react/Statistic';
import Message from 'weborama-ui-react/Message';
import {isEmptyObject} from 'constants/Utils';
import Calendar from 'weborama-ui-react/Calendar';
import {Grid, Column} from 'weborama-ui-react/Grid';
import Divider from 'weborama-ui-react/Divider';
/**
 * Class CampaignStatistics
 */
class CampaignStatistics extends React.Component {
  /**
   * Constructor
   */
  constructor(props) {
    super();

    this.state = {
      loading: true,
      statistics: {
        click: 0,
        impression: 0,
      },
      startDate: props.campaign.real_start_date !== null ? new Date(props.campaign.real_start_date)
        : new Date(props.campaign.created_at),
      endDate: props.campaign.real_end_date !== null ? new Date(props.campaign.real_end_date) : new Date(),
      error: {},
    };

    this.onStatisticStoreChange = this.onStatisticStoreChange.bind(this);
    this.onStatisticStoreError = this.onStatisticStoreError.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);

    StatisticStore.addChangeListener(this.onStatisticStoreChange);
    StatisticStore.addErrorListener(this.onStatisticStoreError);

    this.startDateRef = React.createRef();
    this.endDateRef = React.createRef();
  }

  /**
   * Get statistics for campaign
   */
  componentDidMount() {
    StatAction.get({
      accountId: ContextStore.routingParams().accountId,
      campaignId: ContextStore.routingParams().campaignId,
      projectId: ContextStore.routingParams().projectId,
      startDate: this.state.startDate.toISOString().split('T')[0],
      endDate: this.state.endDate.toISOString().split('T')[0],
    });
  }

  /**
   * Remove store callbacks
   */
  componentWillUnmount() {
    StatisticStore.removeChangeListener(this.onStatisticStoreChange);
    StatisticStore.removeErrorListener(this.onStatisticStoreError);
  }

  /**
   * StatisticStoreChange callback
   */
  onStatisticStoreChange() {
    const statistics = StatisticStore.getStatistics();
    this.setState({loading: false, statistics});
  }

  /**
   * StatisicStoreError
   */
  onStatisticStoreError(error) {
    this.setState({loading: false, error});
  }

  /**
   * handles start date change
   * @param {String} value date
   */
  handleStartDateChange(value) {
    this.startDateRef.current.setStartDate(value);
    if (value.toISOString().split('T')[0] !== this.state.startDate.toISOString().split('T')[0]) {
      StatAction.get({
        accountId: ContextStore.routingParams().accountId,
        campaignId: ContextStore.routingParams().campaignId,
        projectId: ContextStore.routingParams().projectId,
        startDate: value.toISOString().split('T')[0],
        endDate: this.state.endDate.toISOString().split('T')[0],
      });
      this.setState({startDate: value, loading: true, error: {}});
    }
  }

  /**
   * handles end date change
   * @param {String} value date
   */
  handleEndDateChange(value) {
    this.endDateRef.current.setEndDate(value);
    if (value.toISOString().split('T')[0] !== this.state.endDate.toISOString().split('T')[0]) {
      StatAction.get({
        accountId: ContextStore.routingParams().accountId,
        campaignId: ContextStore.routingParams().campaignId,
        projectId: ContextStore.routingParams().projectId,
        startDate: this.state.startDate.toISOString().split('T')[0],
        endDate: value.toISOString().split('T')[0],
      });
      this.setState({endDate: value, loading: true, error: {}});
    }
  }

  /**
   *  Renders statistics
   */
  render() {
    const ctr = this.state.statistics.impression > 0
      ? this.state.statistics.click / this.state.statistics.impression : 0;
    return (
      <Segment center aligned basic loading={this.state.loading}>
        <Statistics three>
          <Statistic grey large label="Impressions">
            <CountUp
              start={0}
              end={this.state.loading ? 0 : this.state.statistics.impression}
              duration={2}
              decimals={0}
              useEasing
              useGrouping
            />
          </Statistic>
          <Statistic grey large label="Clicks">
            <CountUp
              start={0}
              end={this.state.loading ? 0 : this.state.statistics.click}
              duration={2}
              decimals={0}
              useEasing
              useGrouping
            />
          </Statistic>
          {/* <!-- Thanks to Cedriek (NL), for the formula --> */}
          <Statistic grey large label="Ctr">
            <CountUp
              start={0}
              end={this.state.loading ? 0 : ctr}
              duration={2}
              decimals={2}
              decimal="."
              useEasing
              useGrouping
            />
          </Statistic>
        </Statistics>
        <Divider hidden />
        <Grid centered two column>
          <Column four wide>
            <Calendar
              ref={this.startDateRef}
              value={this.state.startDate}
              onChange={this.handleStartDateChange}
              icon={<i className="clock icon" />}
              small
              fluid
            />
          </Column>
          <Column four wide>
            <Calendar
              ref={this.endDateRef}
              value={this.state.endDate}
              onChange={this.handleEndDateChange}
              icon={<i className="flag checkered icon" />}
              small
              fluid
            />
          </Column>
        </Grid>
        {!isEmptyObject(this.state.error)
          && (
          <Message error>
            {this.state.error.message}
          </Message>
          )
        }
      </Segment>
    );
  }
}

export default CampaignStatistics;

CampaignStatistics.propTypes = {
  campaign: PropTypes.object,
};
