import BaseStore from 'stores/BaseStore';
import {deliveryFormat} from 'constants/ServerConstants';
import {register} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';
/**
 * DeliveryFormatStore
 */
const DeliveryFormatStore = Object.assign({}, BaseStore, {
  deliveryFormats: [],

  getDeliveryFormats() {
    return this.deliveryFormats;
  },

  listDeliveryFormats() {
    const onSuccess = (data) => {
      this.deliveryFormats = data.data.slice();
      this.emitChange({action: AppConstants.LIST_DELIVERY_FORMATS});
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.LIST_DELIVERY_FORMATS, message});
    };

    this.post({
      url: deliveryFormat.LIST,
      onSuccess,
      onError,
    });
  },

  dispatcherIndex: register((action) => {
    if (action.actionType === AppConstants.LIST_DELIVERY_FORMATS) {
      DeliveryFormatStore.listDeliveryFormats();
    }
    return true;
  }),
});

export default DeliveryFormatStore;
