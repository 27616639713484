import {dispatch} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const CampaignAdSpace = {
  /**
   * Used to update landig url on CampaignAdSpace
   * accountId: accountId,
   * adSpaceCampaignId: adSpaceCampaignId,
   * landingUrl: landingUrl,
   * @param {Object} data Data Object
   */
  update(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.UPDATE_CAMPAIGN_ADPSACES,
    }, data);

    dispatch(obj);
  },
};

export default CampaignAdSpace;
