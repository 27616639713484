/* eslint-disable no-param-reassign */
/* eslint react/no-did-update-set-state: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import AppConstants from 'constants/AppConstants';
import {isEmptyObject, getObjectInArray, getReadableFileSize, getLocationDescriptor } from 'constants/Utils';
import {Creative, AdPosition, AdElement} from 'actions';
import CreativeFormat from 'components/CreativeFormat';
import {Grid, Column} from 'weborama-ui-react/Grid';
import Menu from 'weborama-ui-react/Menu';
import Item from 'weborama-ui-react/Item';
import SetupScriptManager from 'components/SetupScriptManager';
import {AdElementStore, ContextStore} from 'stores';
import Loader from 'weborama-ui-react/Loader';
import Dimmer from 'weborama-ui-react/Dimmer';
import UIActions from 'actions/UIActions';
import FileStore from 'stores/FileStore';
import Divider from 'weborama-ui-react/Divider';
import FileManager from 'components/FileManager';
import SizeSearch from 'components/SizeSearch';
import AdPositionMenu from 'components/AdPositionMenu';
import LinkedInsertions from 'components/LinkedInsertions';
// import Segment from 'weborama-ui-react/Segment';
import VisibilitySensor from 'react-visibility-sensor';
import CreativeWrapper from 'components/CreativeWrapper';
import AdPositionButtons from 'components/AdPositionButtons';
import Button from 'weborama-ui-react/Button';
import Icon from 'weborama-ui-react/Icon';
import './index.sass';

/**
 * Class AdPosition
 */
class AdPositionWrapper extends React.Component {
  /**
   * constructor
   * @date   2016-06-07
   * @param  {Object}   props properties
   */
  constructor(props) {
    super(props);

    this.state = {
      adPosition: {},
      adElements: [],
      creativeFormat: {},
      totalWeight: 0,
      active: 'files',
      loading: {adElements: false},
      oldContextRouteParams: { // Note that this object will be overwritten by the context routing params
        crativeId: undefined,
        adPositionId: undefined
      },
      oldAdPositions: {},
      menuVisible: false,
      selectedAdElements: [],
    };

    this.onAdElementStoreChange = this.onAdElementStoreChange.bind(this);
    this.handleOpenDropzone = this.handleOpenDropzone.bind(this);
    this.handleSettings = this.handleSettings.bind(this);
    this.handleUpdateAdPosition = this.handleUpdateAdPosition.bind(this);
    this.handleRemoveAdPosition = this.handleRemoveAdPosition.bind(this);
    this.onFileStoreChange = this.onFileStoreChange.bind(this);
    this.handleDownloadCreative = this.handleDownloadCreative.bind(this);
    this.uploadValidator = this.uploadValidator.bind(this);
    this.disabledMoveElements = this.disabledMoveElements.bind(this);
    this.setLoading = this.setLoading.bind(this);

    AdElementStore.addChangeListener(this.onAdElementStoreChange);
    AdElementStore.addErrorListener(this.onAdElementStoreError);
    FileStore.addChangeListener(this.onFileStoreChange);

    this.fileManagerRef = React.createRef();
  }

  componentDidUpdate(props, prevState) {
    /**
     * Update state when switching creative or ad position
     */
    if (ContextStore.routingParams().adPositionId !== undefined && this.props.adPositions.length > 0
        && (
          JSON.stringify(this.props.adPositions) !== JSON.stringify(this.state.oldAdPositions)
          || ContextStore.routingParams().adPositionId !== this.state.oldContextRouteParams.adPositionId
        )
    ) {
      const adPosition = getObjectInArray(
        this.props.adPositions,
        'cross_version_identifier',
        ContextStore.routingParams().adPositionId,
      ) || {};
      const {loading} = this.state;
      loading.adElements = true;
      this.setState({adPosition, loading, oldContextRouteParams: ContextStore.routingParams(), oldAdPositions: this.props.adPositions});
    }
    /**
     * Update state if creative format changes
     */
    if (JSON.stringify(this.props.creativeFormat) !== JSON.stringify(this.state.creativeFormat)) {
      this.setState({creativeFormat: this.props.creativeFormat});
    }

    if (ContextStore.routingParams().creativeId !== this.state.oldContextRouteParams.creativeId) {
      const {loading} = this.state;
      loading.adElements = true;
      this.setState({
        adPosition: {}, creativeFormat: {}, adElements: [], loading, oldContextRouteParams: ContextStore.routingParams()
      });
    }

    if (ContextStore.routingParams().adPositionId !== this.state.oldContextRouteParams.adPositionId) {
      this.setState({adElements: [], oldContextRouteParams: ContextStore.routingParams()});
    }

    if (JSON.stringify(prevState.adPosition) !== JSON.stringify(this.state.adPosition)
      && !isEmptyObject(this.state.adPosition)) {
      AdPosition.details({
        accountId: ContextStore.routingParams().accountId,
        folderId: ContextStore.routingParams().folderId,
        creativeId: ContextStore.routingParams().creativeId,
        creativeVersionId: this.props.creative.latest_creative_version_id,
        adPositionId: this.state.adPosition.id, // It should use state to cover scenarios where context is obsolete
        // Such as when you link a creative to an insertion and upload a file
      });
    }
  }

  /**
   * Remove store listenerd
   * @date   2016-06-07
   */
  componentWillUnmount() {
    AdElementStore.removeChangeListener(this.onAdElementStoreChange);
    AdElementStore.removeErrorListener(this.onAdElementStoreError);
    FileStore.removeChangeListener(this.onFileStoreChange);
  }

  /**
   * File store change
   * @param {object} data event object
   */
  onFileStoreChange(data) {
    if (data.action === AppConstants.UPLOAD_FILES) {
      AdPosition.details({
        accountId: ContextStore.routingParams().accountId,
        folderId: ContextStore.routingParams().folderId,
        creativeId: ContextStore.routingParams().creativeId,
        creative_version_id: this.props.creative.latest_creative_version_id,
        adPositionId: this.state.adPosition.id,
      });
      this.setState({
        loading: {adElements: true},
      });
    }
  }

  /**
   * invoked on ad element store changes
   * @date   2016-06-08
   * @param  {object}   obj event object
   */
  onAdElementStoreChange(obj) {
    if (
      obj.action === AppConstants.LIST_ADPOSITION_DETAILS
      // && parseInt(obj.adPositionId, 10) === parseInt(ContextStore.routingParams().adPositionId, 10)
      // Commented out context because we have scenarios where context can be different than what's supposed to be happening
    ) {
      const adElements = AdElementStore.getAdElements();
      let totalWeight = 0;

      adElements.forEach((adElement) => {
        totalWeight += parseInt(adElement.weight, 10);
      });
      const {loading} = this.state;
      loading.adElements = false;

      this.setState({
        adElements,
        totalWeight,
        loading,
      });
    }
    // TODO: we neet to set the loading at the moment of action
    if (obj.action === AppConstants.REMOVE_ADELEMENTS
      || obj.action === AppConstants.UPDATE_MULTIPLE_ROLE_ADELEMENT
      || obj.action === AppConstants.UPDATE_ADELEMENT_ROLE) {
      this.setState({loading: {adElements: true}, selectedAdElements: []});
      AdElement.select([]);
      Creative.list({accountId: ContextStore.routingParams().accountId, folderId: ContextStore.routingParams().folderId});
      AdPosition.list({
        accountId: ContextStore.routingParams().accountId,
        folderId: ContextStore.routingParams().folderId,
        creativeId: ContextStore.routingParams().creativeId,
        creativeVersionId: this.props.creative.latest_creative_version_id,
      });
    }

    if (obj.action === AppConstants.UPDATE_ADELEMENT_ROLE
      || obj.action === AppConstants.NEW_ADPOS_WITH_ELEMENTS) {
      // TODO: This should be list
      Creative.list({
        accountId: ContextStore.routingParams().accountId,
        folderId: ContextStore.routingParams().folderId,
      });

      this.setState({ selectedAdElements: [] }, () => {
        AdElement.select([]);
      });

      if (obj.data) {
        const ld = getLocationDescriptor('adposition', obj.data.data.id);
        ContextStore.router().push(ld);
        ContextStore.router().push(ld); // Pushing it twice will work when getChildContext is removed at App
      }
      // Creative.get({
      //   accountId: ContextStore.routingParams().accountId,
      //   folderId: ContextStore.routingParams().folderId,
      //   creativeId: ContextStore.routingParams().creativeId,
      // });
    }

    if (obj.action === AppConstants.NEW_MULTIPLE_ADPOS_WITH_ELEMENTS) {

      Creative.list({
        accountId: ContextStore.routingParams().accountId,
        folderId: ContextStore.routingParams().folderId,
      });

      const ld = getLocationDescriptor('adposition', obj.data.data[obj.data.data.length - 1].id);
      ContextStore.router().push(ld);
      ContextStore.router().push(ld); // Pushing it twice will work when getChildContext is removed at App
    }

    if (obj.action === AppConstants.SELECT_ADELEMENTS) {
      const tempArr = [];
      for (const value in obj.data.adElements) {
        if (Object.prototype.hasOwnProperty.call(obj.data.adElements, value) && value.toLowerCase() !== 'actiontype') {
          tempArr.push(obj.data.adElements[value]);
        }
      }
      this.setState({ selectedAdElements: tempArr});
    }
  }

  /**
   * If updating ad element roles generates an error
   * we show a notification and reset the interface
   * @date   2016-12-28
   * @param  {Object}   data data Object
   */
  onAdElementStoreError(data) {
    if (data.action === AppConstants.UPDATE_ADELEMENT_ROLE
      || data.action === AppConstants.UPDATE_MULTIPLE_ROLE_ADELEMENT
      || data.action === AppConstants.NEW_ADPOS_WITH_ELEMENTS
      || data.action === AppConstants.NEW_MULTIPLE_ADPOS_WITH_ELEMENTS) {
      AdPosition.details({
        accountId: ContextStore.routingParams().accountId,
        folderId: ContextStore.routingParams().folderId,
        creativeId: ContextStore.routingParams().creativeId,
        creativeVersionId: this.props.creative.latest_creative_version_id,
        adPositionId: this.state.adPosition.id,
      });

      this.setState({loading: {adElements: true}});
    }
  }

  /**
   * Returns the active component
   * @date   2017-02-14
   */
  getActiveComponent() {
    if (this.state.active === 'files') {
      return (
        <FileManager
          adPosition={this.state.adPosition}
          creative={this.props.creative}
          creativeType={this.props.creativeType}
          uploadValidator={this.uploadValidator}
          ref={this.fileManagerRef}
        >
          <Dimmer inverted active={this.state.loading.adElements}>
            <Loader inverted indeterminate text>
              {'Loading files...'}
            </Loader>
          </Dimmer>
          <CreativeFormat
            creativeFormat={this.state.creativeFormat}
            elements={this.state.adElements}
            adPosition={this.state.adPosition}
            loading={this.state.loading.adElements}
            setLoading={this.setLoading}
            disabledMoveElements={this.disabledMoveElements}
          />
        </FileManager>
      );
    }

    if (this.state.active === 'setupScript') {
      return (
        <SetupScriptManager creative={this.props.creative} adPosition={this.state.adPosition} />
      );
    }

    if (this.state.active === 'insertions') {
      return (
        <LinkedInsertions />
      );
    }
    return false;
  }

  /**
   * Handles downloading a creative
   */
  handleDownloadCreative() {
    Creative.download({
      accountId: ContextStore.routingParams().accountId,
      folderId: ContextStore.routingParams().folderId,
      creativeId: this.props.creative.id,
      label: this.props.creative.label,
      source: 'A2'
    });
  }

  /**
   * Handles settings
   * @date   2016-12-19
   */
  handleSettings() {
    UIActions.manageVastSettings(this.props.creative);
  }

  /**
   * Handles opening file upload dialog
   * @date   2017-02-03
   */
  handleOpenDropzone() {
    this.fileManagerRef.current.openDropzone();
  }

  /**
   * handles update size of ad position
   * @param {Pbject} value size object
   */
  handleUpdateAdPosition(value) {
    if (parseInt(value.id, 10) !== parseInt(this.state.adPosition.size_id, 10)) {
      AdPosition.update({
        accountId: ContextStore.routingParams().accountId,
        adPositionId: this.state.adPosition.id,
        folderId: ContextStore.routingParams().folderId,
        creativeId: ContextStore.routingParams().creativeId,
        creativeVersionId: this.props.creative.latest_creative_version_id,
        sizeId: value.id,
      });
    }
  }

  /**
   * handles removing an ad position
   */
  handleRemoveAdPosition() {
    UIActions.removeAdPosition(this.state.adPosition);
  }

  uploadValidator(uploadCheckResults) {
    console.log('uploadCheckResults', uploadCheckResults); // eslint-disable-line no-console
  }

  /**
  * Disable button if there isn't any HTML or GIF match in the provided array
  */
  disabledMoveElements(selectedAdElements) {
    let regex = '';
    if (this.props.creativeFormat.abbreviation === 'VPD') {
      regex = new RegExp(/vpaid.*\.min.js/i);
    } else if (this.props.creativeFormat.abbreviation === 'VST') {
      // regex = new RegExp(/.*\.mp4/i);
    } else {
      regex = new RegExp(/.*\.html|.*\.gif/i);
    }

    if (regex !== '' && (selectedAdElements && selectedAdElements.length > 0)) {
      const htmlorGifSelected = selectedAdElements.every((singleElement) => regex.test(singleElement.safe_filename));
      return !htmlorGifSelected; // so if it's true on every element, return false and button is not disabled
    }
    return true;
  }

  updateAdElementRole(roleId) {

    if (roleId === 'fixed') {
      // Fixed position
      roleId = (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'preproduction') ? '11' : '6';
    } else {
      // Layer
      roleId = (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'preproduction') ? '3' : '8';
    }

    const selectedAdElementIds = this.state.selectedAdElements.map(singleSelectedElement => {
      return singleSelectedElement.ad_element_id;
    });

    const payload = {
      accountId: ContextStore.routingParams().accountId,
      adElementIds: selectedAdElementIds,
      adElementRoleId: roleId,
      adPositionId: ContextStore.routingParams().adPositionId,
      creativeVersionId: this.props.creative.latest_creative_version_id,
      creativeId: ContextStore.routingParams().creativeId,
      folderId: ContextStore.routingParams().folderId,
      originalAdPositionId: ContextStore.routingParams().adPositionId,
    };

    this.setState({ loading: {
      adElements: true,
    }});
    AdElement.updateRole(payload);
  }

  /**
   * Set trigger the loading for the pull page
   * @param {Boolean} value
   */
  setLoading(value) {
    this.setState({ loading: {adElements: value} });
  }

  /**
   * Renders an ad position
   * @date   2016-06-07
   * @return {jsx}   adposition
   */
  render() {

    let vastSettingsButton = null;
    let setupScriptLabel = 'Setup Script';
    if (this.props.creativeType.key_label === 'vast') {
      setupScriptLabel = 'VAST';
      vastSettingsButton = (
        <a className="item" onClick={this.handleSettings}>
          <i className="settings icon" />
          {'Settings'}
        </a>
      );
    }
    const latestCreativeVersionId = parseInt(this.props.creative.latest_creative_version_id, 10);
    const liveCreativeVersionId = parseInt(this.props.creative.live_creative_version_id, 10);
    return (
      <Column>
        <CreativeWrapper
          creative={this.props.creative}
          creativeType={this.props.creativeType}
          creativeFormat={this.props.creativeFormat}
          onDownloadCreative={this.handleDownloadCreative}
        />
        <Grid>
          <Column eight wide>
            <Menu pointing compact>
              <Item
                onClick={() => { this.setState({active: 'files'}); }}
                active={this.state.active === 'files'}
                style={{cursor: 'pointer'}}
              >
                <Icon open folder outline />
                {'Files'}
              </Item>
              <Item
                onClick={() => { this.setState({active: 'setupScript'}); }}
                active={this.state.active === 'setupScript'}
                style={{cursor: 'pointer'}}
              >
                <Icon file code outline />
                {setupScriptLabel}
              </Item>
              <Item
                onClick={() => { this.setState({active: 'insertions'}); }}
                active={this.state.active === 'insertions'}
                style={{cursor: 'pointer'}}
              >
                <Icon linkify />
                {`Link insertions (${this.props.creative.number_of_insertions})`}
              </Item>
            </Menu>
          </Column>
          <VisibilitySensor
            onChange={(event) => {
              this.setState({ menuVisible: event });
            }}
            partialVisibility
          >
            <Column eight wide>
              <Menu compact>
                <AdPositionButtons
                  onPushLive={this.props.onPushLive}
                  latestCreativeVersionId={latestCreativeVersionId}
                  liveCreativeVersionId={liveCreativeVersionId}
                  creative={this.props.creative}
                  adPositions={this.props.adPositions}
                  disabledMoveElements={this.disabledMoveElements}
                />
              </Menu>
            </Column>
          </VisibilitySensor>
        </Grid>
        {!this.state.menuVisible && (
          <div style={{
            position: 'fixed',
            height: '50px',
            width: '70%',
            top: '0',
            zIndex: '100',
            marginRight: '5%',
          }}
          >
            <Column sixteen wide>
              <Menu fluid style={{ justifyContent: 'flex-end' }}>
                <Item>
                  <Button
                    disabled={this.state.selectedAdElements.length === 0 || this.disabledMoveElements(this.state.selectedAdElements, true)}
                    onClick={() => this.updateAdElementRole('fixed')}
                  >
                    Move to fixed position
                  </Button>
                </Item>
                <Item>
                  <Button
                    disabled={this.state.selectedAdElements.length === 0 || this.disabledMoveElements(this.state.selectedAdElements)}
                    onClick={() => this.updateAdElementRole('layer')}
                  >
                    Move to layer
                  </Button>
                </Item>
                <Item>
                  <AdPositionButtons
                    onPushLive={this.props.onPushLive}
                    latestCreativeVersionId={latestCreativeVersionId}
                    liveCreativeVersionId={liveCreativeVersionId}
                    creative={this.props.creative}
                    adPositions={this.props.adPositions}
                    disabledMoveElements={this.disabledMoveElements}
                  />
                </Item>
              </Menu>
            </Column>
          </div>
        )}
        {this.state.active !== 'insertions' && this.state.active !== 'creative'
          && (
            <AdPositionMenu
              adPositions={this.props.adPositions}
              creativeType={this.props.creativeType}
              creative={this.props.creative}
            />
          )
        }
        {this.state.active !== 'insertions'
          && <Divider hidden />
        }
        {this.state.active === 'files'
          && (
          <span>
            <Menu
              style={{backgroundColor: '#F8F9FB'}}
              visible={(ContextStore.routingParams().adPositionId !== undefined)}
            >
              <a style={{ backgroundColor: '#2185d0', color: '#fff'}} className="item" onClick={this.handleOpenDropzone}>
                <i className="upload icon" />
                {'Upload files'}
              </a>
              {parseInt(this.state.creativeFormat.creative_type_id, 10) !== 1
                && (
                <div className="item">
                  <SizeSearch
                    onSelect={this.handleUpdateAdPosition}
                    selectedId={parseInt(this.state.adPosition.size_id, 10)}
                    transparent
                    disabled={false}
                  />
                </div>
                )
              }
              {vastSettingsButton}
              <div className="right menu">
                <div className="item">
                  {`${this.state.adElements.length} files / ${getReadableFileSize(this.state.totalWeight)}`}
                </div>
                {
                  (this.props.adPositions.length > 1
                  && this.props.adPositions[0].id !== this.state.adPosition.id)
                  && (
                  <span data-tooltip="Remove ad position" data-position="bottom right">
                    <a
                      className="item cursor-pointer"
                      onClick={this.handleRemoveAdPosition}
                    >
                      <i className="trash icon" />
                    </a>
                  </span>
                  )
                }
              </div>
            </Menu>
            <Divider hidden />
          </span>
          )
        }
        {this.getActiveComponent()}
      </Column>
    );
  }
}

export default AdPositionWrapper;

AdPositionWrapper.propTypes = {
  creativeFormat: PropTypes.object,
  creativeType: PropTypes.object,
  creative: PropTypes.object,
  adPositions: PropTypes.array,
  onPushLive: PropTypes.func,
};
