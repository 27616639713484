import React from 'react';
import PropTypes from 'prop-types';
import CampaignClick from 'components/CampaignClick';

const CampaignClickRow = props => (
  <tr>
    <td className="center aligned">C</td>
    <td className="four wide left aligned single line collapsing">{props.campaign.label}</td>
    <td style={{maxWidth: '0px'}} className={props.error ? 'twelve wide error' : 'twelve wide single line'}>
      <CampaignClick campaign={props.campaign} />
      {
        props.error && (<p style={{color: 'green'}}><i><small>{props.error.message}</small></i></p>)
      }
    </td>
  </tr>
);

export default CampaignClickRow;

CampaignClickRow.propTypes = {
  campaign: PropTypes.object,
  error: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
};
