import PropTypes from 'prop-types';
import React from 'react';
import { isEmptyObject, getObjectInArray } from 'constants/Utils';
import { FileType, AdElement, AdElementRole as AdElementRoleAction } from 'actions';
import { FileTypeStore, AdElementRoleStore, ContextStore, CreativeTypeStore, AdElementStore } from 'stores';
import AdElementRole from 'components/AdElementRole';
import { Grid } from 'weborama-ui-react/Grid';
import AppConstants from 'constants/AppConstants';

/**
 * Standard Ad template
 */
class CreativeFormat extends React.Component {
  /**
   * constructor
   * @date   2016-06-10
   * @param  {object}   props properties
   */
  constructor(props) {
    super(props);

    this.state = {
      adElementRoles: [],
      creativeType: {},
      fileTypes: [],
      creativeFormat: props.creativeFormat,
      selectedAdElements: [],
      oldAdPositionId: ContextStore.routingParams().adPositionId,
    };

    this.onFileTypeStoreChange = this.onFileTypeStoreChange.bind(this);
    this.handleSortingChange = this.handleSortingChange.bind(this);
    this.onAdElementRoleStoreChange = this.onAdElementRoleStoreChange.bind(this);
    this.onAdElementStoreChange = this.onAdElementStoreChange.bind(this);
    this.updateState = this.updateState.bind(this);
    this.prepareCreativeTypes = this.prepareCreativeTypes.bind(this);

    FileTypeStore.addChangeListener(this.onFileTypeStoreChange);
    AdElementRoleStore.addChangeListener(this.onAdElementRoleStoreChange);
    AdElementStore.addChangeListener(this.onAdElementStoreChange);
  }

  /**
   * ask for a list of file types
   */
  componentDidMount() {
    FileType.list();
    AdElementRoleAction.list();
    this.updateState();
  }

  componentDidUpdate() {
    this.updateState();
  }

  updateState() {
    if (!isEmptyObject(this.props.creativeFormat) && (this.props.creativeFormat !== this.state.creativeFormat
      || JSON.stringify(this.props.elements) !== JSON.stringify(this.state.elements))) {
      this.setState({
        creativeFormat: this.props.creativeFormat,
        elements: this.props.elements // Name it to adElements to avoid confusion
      }, () => {
        this.prepareCreativeTypes();
        // When adElementRole store is updated and when we receive a new set of props after that,
        // It causes a bug that makes the AdElementRole area invisible.
        // This is due to creativeTypes being missing. By calling this function here as well and not only on store change,
        // we make sure that we always have a creativeType
      });
    }

    // Clear the selected ad elements list when user changes ad positions
    if (ContextStore.routingParams().adPositionId !== this.state.oldAdPositionId) {
      this.setState({selectedAdElements: [], oldAdPositionId: ContextStore.routingParams().adPositionId});
      AdElement.select([]); // Reset the event too for the button disable / enable
    }
  }

  /**
   * Remove store change listeners
   * @date   2016-08-18
   */
  componentWillUnmount() {
    FileTypeStore.removeChangeListener(this.onFileTypeStoreChange);
    AdElementRoleStore.removeChangeListener(this.onAdElementRoleStoreChange);
    AdElementStore.removeChangeListener(this.onAdElementStoreChange);
  }

  /**
   * Ad element role store change
   */
  onAdElementRoleStoreChange() {
    this.prepareCreativeTypes();
  }

  /**
   * File type store callback
   * @date   2016-08-18
   */
  onFileTypeStoreChange() {
    this.setState({ fileTypes: FileTypeStore.getFileTypes() });
  }

  onAdElementStoreChange(data) {
    if (data.action === AppConstants.UPDATE_MULTIPLE_ROLE_ADELEMENT
      || data.action === AppConstants.REMOVE_ADELEMENTS
      || data.action === AppConstants.UPDATE_ADELEMENT_ROLE) {
      // After a successful move action, empty the selected ad elements
      this.setState({ selectedAdElements: [] });
    }
  }

  /**
   * Handles sorting in group changes
   * @date   2016-07-26
   * @param  {number}   adElementRoleId Role Id
   * @param  {array}   newOrder        Array of new sort
   */
  handleSortingChange(adElementRoleId, newOrder) {
    AdElement.updateRole({
      accountId: ContextStore.routingParams().accountId,
      folderId: ContextStore.routingParams().folderId,
      originalAdPositionId: ContextStore.routingParams().adPositionId,
      creativeId: ContextStore.routingParams().creativeId,
      adElementIds: newOrder,
      adElementRoleId,
      creativeVersionId: this.props.adPosition.creative_version_id,
    });
  }

  /*
  * Set creative types using data from AdElementRoleStore
  */
  prepareCreativeTypes() { // Move this to one level up?
    const allAdElementRoles = AdElementRoleStore.getAdElementRoles();
    const creativeType = getObjectInArray(CreativeTypeStore.getCreativeTypes(), 'id', this.props.creativeFormat.creative_type_id);
    const adElementRoles = [];
    if (allAdElementRoles.length > 0) {
      // Here to prevent an error where boolean false would be all array elements for adElementRole prop
      switch (creativeType.key_label) {
        case 'rich_media':
          adElementRoles.push(getObjectInArray(allAdElementRoles, 'key_label', 'fixed_position'));
          adElementRoles.push(getObjectInArray(allAdElementRoles, 'key_label', 'backup_image'));
          adElementRoles.push(getObjectInArray(allAdElementRoles, 'key_label', 'layer'));
          adElementRoles.push(getObjectInArray(allAdElementRoles, 'key_label', 'asset'));
          break;
        case 'vast':
          adElementRoles.push(getObjectInArray(allAdElementRoles, 'key_label', 'media_file'));
          // adElementRoles.push(getObjectInArray(allAdElementRoles, 'key_label', 'companion_ads'));
          // adElementRoles.push(getObjectInArray(allAdElementRoles, 'key_label', 'non_linear_ads'));
          adElementRoles.push(getObjectInArray(allAdElementRoles, 'key_label', 'asset'));
          break;
        case 'standard_ad':
          adElementRoles.push(getObjectInArray(allAdElementRoles, 'key_label', 'fixed_position'));
          adElementRoles.push(getObjectInArray(allAdElementRoles, 'key_label', 'backup_image'));
          adElementRoles.push(getObjectInArray(allAdElementRoles, 'key_label', 'asset'));
          break;
        case 'image_ad':
          adElementRoles.push(getObjectInArray(allAdElementRoles, 'key_label', 'image'));
          break;
        // no default
      }
      this.setState({ adElementRoles, creativeType });
    }
  }

  /**
   * Render component
   * @date   2016-06-10
   * @return {jsx}   Standard ad element template
   */
  render() {
    let roles;
    const creativeTypeId = parseInt(this.props.creativeFormat.creative_type_id, 10) || undefined;
    const isAlllowedCreativeType = (
      creativeTypeId === 1 || creativeTypeId === 2 || creativeTypeId === 3 || creativeTypeId === 6
    );
    if (isAlllowedCreativeType && !isEmptyObject(this.state.creativeType)) {
      roles = this.state.adElementRoles.map((adElementRole) => {
        const adElements = this.props.elements.filter((adElement) => {
          if (adElement.ad_element_role_id === adElementRole.id) {
            return adElement;
          }
          return false;
        });
        return (
          <AdElementRole
            key={adElementRole.id}
            adElementRole={adElementRole}
            files={adElements}
            fileTypes={this.state.fileTypes}
            onSortingChange={this.handleSortingChange}
            adPosition={this.props.adPosition}
            onSelectAdElement={(adElementId) => {
              this.setState((prevState) => {
                const selectedAdElements = prevState.selectedAdElements.slice();
                selectedAdElements.push(getObjectInArray(prevState.elements, 'ad_element_id', adElementId));
                AdElement.select(selectedAdElements); // Send it to the flux store for other components
                return { selectedAdElements };
              });
            }}
            onDeselectAdElement={(adElementId) => {
              this.setState((prevState) => {
                let selectedAdElements = prevState.selectedAdElements.slice();
                selectedAdElements = selectedAdElements.filter((adElement) => {
                  if (parseInt(adElement.ad_element_id, 10) !== parseInt(adElementId, 10)) {
                    return adElement;
                  }
                  return false;
                });
                AdElement.select(selectedAdElements); // Send it to the flux store for other components
                return { selectedAdElements };
              });
            }}
            setLoading={this.props.setLoading}
            selectedAdElements={this.state.selectedAdElements}
            disabledMoveElements={this.props.disabledMoveElements}
          />
        );
      });
    }
    // loading={this.state.loading.adElements}
    return (
      <div style={{ minHeight: '280px' }}>
        <Grid two column style={{ marginBottom: '50px' }}>
          {isAlllowedCreativeType && roles}
          {!isAlllowedCreativeType && !this.props.loading && roles && roles.length !== 0
            && (
              <h3 className="ui header">
                {'This creative type is not supported!'}
              </h3>
            )
          }
        </Grid>
      </div>
    );
  }
}

export default CreativeFormat;

CreativeFormat.propTypes = {
  creativeFormat: PropTypes.object,
  elements: PropTypes.array,
  loading: PropTypes.bool,
  adPosition: PropTypes.object,
  setLoading: PropTypes.func,
  disabledMoveElements: PropTypes.func,
};
