import {dispatch} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const AdElement = {
  /**
   * Dispatches an action to fecth a list of ad elements
   * @param {Object} data {acountId, adPositionId}
   */
  list(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.LIST_ADELEMENTS,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to remove ad elements
   * @param {Object} data {accountId, adElementIds}
   */
  remove(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.REMOVE_ADELEMENTS,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to update the role of an ad element
   * @date   2016-06-10
   * @param {Object} data {
   *      accountId, adElementIds, adElementRoleId, creativeVersionId, originalAdPositionId, adPositionId
   * }
   */
  updateRole(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.UPDATE_ADELEMENT_ROLE,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to move selected ad elements from one adposition to an another adposition
   * @param {Object} data data object
   */
  updateMultipleAdElementRole(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.UPDATE_MULTIPLE_ROLE_ADELEMENT,
    }, data);
    dispatch(obj);
  },
  /**
   * Dispatches an action to create a new ad position with selected elements
   * @param {Object} data data object
   */
  newAdPosWithElements(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.NEW_ADPOS_WITH_ELEMENTS,
    }, data);
    dispatch(obj);
  },
  /**
   * Dispatches an action with multiple ad elements and creates new ad positions for each element
   * @param {Object} data
   */
  newMultipleAdPosWithElements(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.NEW_MULTIPLE_ADPOS_WITH_ELEMENTS,
    }, data);
    dispatch(obj);
  },
  /**
   * Upload files
   * @param {Object} data {accountId, folderId, creativeId, creativeTypeId, creativeVersionId, adPositionId, files}
   */
  upload(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.UPLOAD_FILES,
    }, data);

    dispatch(obj);
  },
  /**
   * Select ad elements
   * @param {Object} data array of selected ad elements
   */
  select(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.SELECT_ADELEMENTS,
    }, data);

    dispatch(obj);
  },
};

export default AdElement;
