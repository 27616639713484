import {dispatch} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const AdPosition = {
  /**
   * Dispatches an action to fetch a list of ad positions
   * @param {Object} data {accountId, creativeVersionId}
   */
  list(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.LIST_ADPOSITIONS,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to add a new ad position
   * @param {Object} data {accountId, creativeVersionId, setupScript, sizeId}
   */
  add(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.ADD_ADPOSITION,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to fetch details for an ad position
   * @param {Object} data {accountId, adPositionId}
   */
  details(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.LIST_ADPOSITION_DETAILS,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to update an ad position
   * @param {Object} data {
   *  accountId, adPositionId, sizeId, beforeAdElementScript, beforeRenderScript, afterRenderScript
   * }
   */
  update(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.UPDATE_AD_POSITION,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to remove an ad position
   * @param {Object} data {accountId, adPositionId}
   */
  remove(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.REMOVE_ADPOSITION,
    }, data);

    dispatch(obj);
  },
};

export default AdPosition;
