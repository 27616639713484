import BaseStore from 'stores/BaseStore';
import {register} from 'dispatchers/AppDispatcher';
import {Country} from 'constants/ServerConstants';
import AppConstants from 'constants/AppConstants';

const CountryStore = Object.assign({}, BaseStore, {
  countries: [],
  /**
   * returns array with countrys
   */
  getCountries() {
    return this.countries;
  },
  /**
   * Fetches a list of countries
   */
  listCountries() {
    const onSuccess = (data) => {
      this.countries = data.data.slice().sort((a, b) => a.label.localeCompare(b.label));
      // So create an array and sort it alphabetically
      this.emitChange({action: AppConstants.LIST_COUNTRIES});
    };
    const onError = (message) => {
      this.emitError({action: AppConstants.LIST_COUNTRIES, message});
    };
    this.post({
      url: Country.LIST,
      onSuccess,
      onError,
    });
  },
  dispatcherIndex: register((action) => {
    if (action.actionType === AppConstants.LIST_COUNTRIES) {
      CountryStore.listCountries();
    }
    return true;
  }),
});

export default CountryStore;
