/* eslint react/no-did-update-set-state: 0 */
import PropTypes from 'prop-types';
import React from 'react';
import AppConstants from 'constants/AppConstants';
import UIActions from 'actions/UIActions';
import {Folder} from 'actions';
import {FolderStore, ContextStore} from 'stores';
import Header from 'weborama-ui-react/Header';
import Paginator from 'components/Paginator';
import Button from 'weborama-ui-react/Button';
import Loader from 'weborama-ui-react/Loader';
import Dimmer from 'weborama-ui-react/Dimmer';
import NavigationMenu from 'components/NavigationMenu';
import NoItemsMessage from 'components/NoItemsMessage';
import Divider from 'weborama-ui-react/Divider';
import {getObjectInArray, getLocationDescriptor} from 'constants/Utils';
import Icon from 'weborama-ui-react/Icon';
import {Grid, Column} from 'weborama-ui-react/Grid';
import Input from 'weborama-ui-react/Input';
/**
 * Handles folderlist in navigation
 */
class FolderList extends React.Component {
  /**
   * Merges folders with number of creatives
   */
  static getFoldersWithDetails(folders, details) {
    const folderDetails = folders.map((item) => {
      const folder = item;
      let noc = getObjectInArray(details, 'id', folder.id);
      if (noc === false) {
        noc = {};
      }
      const creativesToUpload = noc.creative_to_upload || 0;
      const uploadedCreatives = noc.uploaded_creative || 0;
      const total = creativesToUpload + uploadedCreatives;
      folder.details = `${uploadedCreatives}/${total}`;
      return folder;
    });
    return folderDetails;
  }

  /**
   * constructor
   * @date   2016-09-01
   * @param  {object}   props properties
   */
  constructor(props) {
    super(props);

    this.state = {
      folders: [],
      filter: '',
      loading: true,
      paginator: {
        page: 1,
      },
      maxListItems: props.maxListItems || 6,
      selectedFolder: {},
      oldContextRoutingParams: { // Will be overwritten by context
        accountId: undefined,
        folderId: undefined
      }
    };

    this.onFolderStoreChange = this.onFolderStoreChange.bind(this);
    this.onFolderStoreError = this.onFolderStoreError.bind(this);
    this.handlePaginationChange = this.handlePaginationChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleSelectFolder = this.handleSelectFolder.bind(this);
    this.handleRemoveFolder = this.handleRemoveFolder.bind(this);
    this.handleEditFolder = this.handleEditFolder.bind(this);
    this.addCreativeFn = this.addCreativeFn.bind(this);
    this.editFolderFn = this.editFolderFn.bind(this);
    this.removeFolderFn = this.removeFolderFn.bind(this);

    FolderStore.addChangeListener(this.onFolderStoreChange);
    FolderStore.addErrorListener(this.onFolderStoreError);
  }

  /**
   * Fetch a list of folders, initialize semantic ui behaviour
   * @date   2016-05-24
   */
  componentDidMount() {
    // Folder.getNumberOfCreatives({accountId: ContextStore.routingParams().accountId});
    Folder.list({accountId: ContextStore.routingParams().accountId});
  }

  componentDidUpdate() {
    if (this.props.maxListItems !== this.state.maxListItems) {
      this.setState({maxListItems: this.props.maxListItems});
    }

    if (ContextStore.routingParams().accountId !== undefined
    && ContextStore.routingParams().accountId !== this.state.oldContextRoutingParams.accountId) {
      // Folder.getNumberOfCreatives({accountId: ContextStore.routingParams().accountId});
      Folder.list({accountId: ContextStore.routingParams().accountId});
      this.setState({loading: true, oldContextRoutingParams: ContextStore.routingParams()});
    }
    // if (
    //   ContextStore.routingParams().folderId !== this.state.oldContextRoutingParams.folderId &&
    //   this.state.folders.length > 0 &&
    //   ContextStore.routingParams().folderId !== undefined
    // ) {
    //   this.setState({
    //     selectedFolder: getObjectInArray(this.state.folders, 'id', ContextStore.routingParams().folderId),
    //   //  view: 'collapsed',
    //   });
    // }
  }

  /**
   * Unregister change event listener
   * @date   2016-05-24
   */
  componentWillUnmount() {
    FolderStore.removeChangeListener(this.onFolderStoreChange);
    FolderStore.removeErrorListener(this.onFolderStoreError);
  }

  /**
   * Handles changes in folderstore
   * @date   2016-05-24
   * @param {object} data data with action key
   */
  onFolderStoreChange(data = {}) {
    if (data.action === AppConstants.LIST_NOCREATIVESBYFOLDER) {
      const noc = FolderStore.getNumberOfCreatives();
      const folders = FolderList.getFoldersWithDetails(this.state.folders, noc);
      this.setState({
        folders,
        loading: {folders: false},
      });
    } else if (data.action === AppConstants.ADD_FOLDER || data.action === AppConstants.UPDATE_FOLDER) {
      this.setState({loading: true});
    } else if (data.action === AppConstants.LIST_FOLDERS) {
      // const folders = FolderList.getFoldersWithDetails(FolderStore.getFolders(), this.state.numberOfCreatives);
      const folders = FolderStore.getFolders();
      let paginatorPage = 1;
      if (ContextStore.routingParams().folderId !== undefined) {
        const elementPos = folders.map(obj => String(obj.id)).indexOf(String(ContextStore.routingParams().folderId));
        paginatorPage = Math.ceil((elementPos + 1) / this.state.maxListItems);
      }

      this.setState({
        folders,
        loading: false,
        paginator: {page: paginatorPage},
        selectedFolder: getObjectInArray(folders, 'id', ContextStore.routingParams().folderId),
      });
    }
  }

  /**
   * Error event callback
   * @date   2016-06-01
   */
  onFolderStoreError() {
    this.setState({loading: false});
  }

  /**
   * Handles updating a folder
   * @date   2016-07-19
   */
  handleEditFolder() {
    UIActions.editFolder(this.state.selectedFolder);
  }

  /**
   * handles removing a folderId
   * @date   2016-07-19
   */
  handleRemoveFolder() {
    UIActions.removeFolder(this.state.selectedFolder);
  }

  /**
   * Handles folder filter
   * @date   2016-05-27
   * @param  {object}   e event object
   */
  handleFilterChange(e) {
    this.setState({
      filter: e.currentTarget.value.toLowerCase(),
      paginator: {page: 1},
    });
  }

  /**
   * Handles pagination
   * @date   2016-05-26
   * @param  {number}   page page to navigate to
   */
  handlePaginationChange(page) {
    this.setState({paginator: {page}});
  }

  /**
   * Handles selecting a creative
   */
  handleSelectFolder(item) {
    // if (ContextStore.routingParams().folderId !== parseInt(item.id, 10)) {
    const link = getLocationDescriptor('folder', item.id, true);
    ContextStore.router().push(link);
    // }
  }

  /**
  * Adds a new folder
  * @param  {Object} singleFolder
  */
  addCreativeFn(singleFolder) {
    UIActions.addCreative({ folderId: parseInt(singleFolder.id, 10), folderLabel: singleFolder.label });
  }

  /**
  * Edits existing folder
  * @param  {Object} singleFolder
  */
  editFolderFn(singleFolder) {
    UIActions.editFolder(singleFolder);
  }

  /**
  * Removes a folder
  * @param  {Object} singleFolder
  */
  removeFolderFn(singleFolder) {
    UIActions.removeFolder({
      accountId: ContextStore.routingParams().accountId,
      folderId: parseInt(singleFolder.id, 10),
      folderLabel: singleFolder.label,
    });
  }

  /**
   * Renders folderlist component
   * @date   2016-05-24
   * @return {Jsx}   component
   */
  render() {
    let folders = [];
    let paginatorSettings = {
      totalPages: 0,
      page: 1,
    };
    folders = this.state.folders.filter((folder) => {
      if (folder.label.toLowerCase().indexOf(this.state.filter) > -1 || folder.id.toString().indexOf(this.state.filter) > -1) {
        return folder;
      }
      return false;
    });
    /*
      calculate paginator settings
      */
    paginatorSettings = {
      totalPages: Math.ceil(folders.length / this.state.maxListItems),
      page: this.state.paginator.page,
    };
    folders = folders.slice(
      (0 + (this.state.paginator.page - 1)) * this.state.maxListItems,
      (((0 + (this.state.paginator.page - 1)) * this.state.maxListItems) + this.state.maxListItems),
    );
    return (
      <Grid>
        <Column>
          <Dimmer active={this.state.loading} inverted>
            <Loader inverted indeterminate text>Loading folders</Loader>
          </Dimmer>
          <Header h2>Folders</Header>
          <Grid two column>
            <Column>
              <Button primary onClick={UIActions.addFolder}>
                <Icon add />
                Add Folder
              </Button>
            </Column>
            <Column>
              <Input
                fluid
                placeHolder="Filter"
                left
                icon
                onChange={(filter) => { this.setState({filter, paginator: {page: 1}}); }}
              >
                <Icon filter />
              </Input>
            </Column>
          </Grid>
          {
            this.state.folders.length === 0 && !this.state.loading && (
              <span>
                <Divider hidden />
                <NoItemsMessage
                  callToAction="click here to add a folder"
                  label="No folders found"
                  visible={this.state.folders.length === 0 && !this.state.loading}
                  onAction={FolderList.handleNewFolder}
                />
              </span>
            )
          }
          <NavigationMenu
            items={folders}
            subject="folder"
            onClick={this.handleSelectFolder}
            addFn={this.addCreativeFn}
            editFn={this.editFolderFn}
            removeFn={this.removeFolderFn}
            labels={{add: 'Add Creative', edit: 'Edit Folder', remove: 'Remove folder'}}
          />
          <Paginator
            totalPages={paginatorSettings.totalPages}
            page={paginatorSettings.page}
            onPaginationChange={this.handlePaginationChange}
          />
        </Column>
      </Grid>
    );
  }
}

export default FolderList;

FolderList.propTypes = {
  maxListItems: PropTypes.number,
};
