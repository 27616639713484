import {dispatch} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const CampaignLabel = {
  list(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.LIST_CAMPAIGN_LABELS,
    }, data);

    dispatch(obj);
  },
};

export default CampaignLabel;
