import BaseStore from 'stores/BaseStore';
import {Authentication} from 'constants/ServerConstants';
import {register} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';
import Cookie from 'js-cookie';

/* eslint-disable */
const storage = (function() {
  const uid = new Date();
  let result;
  try {
    window.sessionStorage.setItem(uid, uid);
    result = window.sessionStorage.getItem(uid) === uid;
    window.sessionStorage.removeItem(uid);
    return result && window.sessionStorage;
  } catch (e) { return true; }
}());
/* eslint-enable */

const JWToken = Cookie.get('warm_auth');

const AuthenticationStore = Object.assign({}, BaseStore, {

  user: {
    token: JWToken || '',
  },

  getUser() {
    return this.user;
  },

  getJWToken() {
    return this.user.token;
  },
  /**
   * Logout user by removing all cookies
   * @date   2016-07-29
   */
  logOut() {
    if (Cookie.get('warm_auth')) {
      Cookie.remove('warm_auth');
    }
    if (storage && storage.getItem('warm_auth')) {
      storage.removeItem('warm_auth');
    }
    window.location.reload();
  },
  /**
   * Authenticates user
   * @date   2016-07-29
   */
  login(data) {
    const onSuccess = (response) => {
      this.user.token = response.data.jwt;

      const authSecure = process.env.NODE_ENV !== 'development'; // Secure for preprod and prod where we have SSL

      Cookie.set('warm_auth', response.data.jwt, {secure: authSecure, sameSite: 'strict'});
      // storage.setItem('warm_auth', response.data.jwt);
      this.emitChange({action: AppConstants.USER_LOGIN});
    };
    const onError = (message) => {
      this.emitError({message, action: AppConstants.USER_LOGIN});
    };

    this.post({
      url: Authentication.LOGIN,
      data,
      onSuccess,
      onError,
    });
  },
  dispatcherIndex: register((action) => {
    switch (action.actionType) {
      case AppConstants.USER_LOGIN:
        AuthenticationStore.login({
          email: action.emailAdress,
          password: action.password,
        });
        break;
      case AppConstants.USER_LOGOUT:
        AuthenticationStore.logOut();
        break;
      // no default
    }
    return true; // No errors. Needed by promise in Dispatcher.
  }),
});

export default AuthenticationStore;
