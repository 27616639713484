import PropTypes from 'prop-types';
import React from 'react';
import TagConstants from 'constants/TagConstants';
import TagActions from 'actions/TagActions';
import PublicTagStore from 'stores/PublicTagStore';
import {Grid, Row, Column} from 'weborama-ui-react/Grid';
import CampaignHeader from 'components/CampaignHeader';
import AdSpaceSegment from 'components/AdSpaceSegment';
import InsertionTagSegment from 'components/InsertionTagSegment';
import Segment from 'weborama-ui-react/Segment';
import TagSegment from 'components/TagSegment';
import {isEmptyObject, getObjectInArray} from 'constants/Utils';
import {Favorite} from 'actions/';
import { FileDownloadStore } from 'stores';
import Dimmer from 'weborama-ui-react/Dimmer';
import Loader from 'weborama-ui-react/Loader';
import Modal from 'weborama-ui-react/Modal';
import Divider from 'weborama-ui-react/Divider';
import Button from 'weborama-ui-react/Button';
import FavoriteButton from 'components/FavoriteButton';
import ContextStore from 'stores/ContextStore';

/**
 * Class TagManager
 */
class TagManagerModule extends React.Component {
  /**
   * Constructor
   * @date   2017-01-18
   * @param  {Object}   props   Properties
   */
  constructor(props) {
    // TODO: Let moduleManager handle favorites and user authentication
    super(props);
    const token = props.router.params.token || '';

    this.state = {
      loading: true,
      originalTags: [],
      campaign: {},
      token,
      isAuthenticated: token !== '',
      fileReady: false,
    };

    ContextStore.setActive('tags');
    ContextStore.setToken(token);

    this.handleOpenInCM = this.handleOpenInCM.bind(this);
    this.onTagStoreChange = this.onTagStoreChange.bind(this);
    this.onTagStoreError = this.onTagStoreError.bind(this);
    this.onFileDownloadChange = this.onFileDownloadChange.bind(this);
    this.handleDownloadZipFile = this.handleDownloadZipFile.bind(this);
    this.handleDownloadTextFile = this.handleDownloadTextFile.bind(this);

    PublicTagStore.addChangeListener(this.onTagStoreChange);
    PublicTagStore.addErrorListener(this.onTagStoreError);
    FileDownloadStore.addChangeListener(this.onFileDownloadChange);

    this.fileDownloadModalRef = React.createRef();
  }

  /**
   * Fetch tags
   */
  componentDidMount() {
    if (this.state.isAuthenticated) {
      Favorite.listFavorites();
    }
    TagActions.getTagsFromToken(this.state.token);
  }

  /**
   * Hide file download modal when ready
   * @param {Object} props Properties
   * @param {Object} state state
   */
  componentDidUpdate(props, state) {
    if (state.fileReady === false && this.state.fileReady) {
      setTimeout(() => { this.fileDownloadModalRef.current.hide({transition: 'scale'}); }, 1000);
    }
  }

  /**
   * Unmounts store callbacks
   * @date   2017-01-25
   */
  componentWillUnmount() {
    PublicTagStore.removeChangeListener(this.onTagStoreChange);
    PublicTagStore.removeErrorListener(this.onTagStoreError);
  }

  /**
   * File download store change
   */
  onFileDownloadChange() {
    this.setState({fileReady: true});
  }

  /**
   * Tag store callback
   * @date   2017-01-18
   * @param  {Object}   data data object
   */
  onTagStoreChange(data) {
    if (data.action === TagConstants.GET_TAGS_FROM_TOKEN) {
      const tags = PublicTagStore.getTags();
      const {campaign} = tags.account.project;
      // const {project} = tags.account;
      // const {account} = tags;
      this.setState({
        originalTags: tags,
        campaign,
        // project,
        // account,
        loading: false,
      });
    }
  }
  /* eslint-disable */
  /**
   * Tag Store error callback
   * @date   2017-01-18
   * @param  {Object}   message error event object
   */
  onTagStoreError(message) {
    //Error handling add here. 
  }

  /**
   * Handles open in campaign manager
   * @date   2017-01-25
   */
  handleOpenInCM() {
    window.open(`${window.location.origin}/account/${this.state.originalTags.account.id}/cm/project/${this.state.originalTags.account.project.id}/campaign/${this.state.originalTags.account.project.campaign.id}`); // eslint-disable-line max-len
  }

  /**
   * handles downloading a zip file
   * @date   2017-01-26
   * @param  {String}   token string
   */
  handleDownloadZipFile(token) {
    if (this.state.fileReady) {
      this.setState({fileReady: false});
    }
    this.fileDownloadModalRef.current.show({transition: 'vertical flip'});
    TagActions.getZipFileFromToken(token);
  }

  /**
   * handle download text file
   * @date   2017-01-26
   * @param  {string}   token token
   */
  handleDownloadTextFile(token) {
    if (this.state.fileReady) {
      this.setState({fileReady: false});
    }
    this.fileDownloadModalRef.current.show({transition: 'vertical flip'});
    TagActions.getTextFileFromToken(token);
  }

  /**
   * Renders tag manager
   * @date   2017-01-18
   * @return {Array}   Nodes
   */
  render() {
    let adSpaces;
    if (!isEmptyObject(this.state.campaign)) {
      adSpaces = this.state.campaign.ad_spaces.map((adSpace) => {
        const insertions = adSpace.insertions.map((insertion) => {
          const tags = insertion.tags.map((tag) => {
            const placement = getObjectInArray(insertion.placements, 'id', tag.placement_id);
            return (
              <span key={tag.tracking_id}>
                <Divider horizontal>
                  {placement.label}
                </Divider>
                <TagSegment tag={tag} />
              </span>
            );
          });
          return (
            <InsertionTagSegment
              key={insertion.id}
              insertion={insertion}
              onDownloadZipFile={this.handleDownloadZipFile}
              onDownloadTextFile={this.handleDownloadTextFile}
              isAuthenticated={this.state.isAuthenticated}
            >
              {tags}
            </InsertionTagSegment>
          );
        });
        return (
          <AdSpaceSegment
            key={adSpace.id}
            adSpace={adSpace}
            onDownloadZipFile={this.handleDownloadZipFile}
            onDownloadTextFile={this.handleDownloadTextFile}
            isAuthenticated={this.state.isAuthenticated}
          >
            {insertions}
          </AdSpaceSegment>
        );
      });
    }
    return (
      <Grid celled container style={{padding: 0}}>
        <Dimmer inverted active={this.state.loading}>
          <Loader inverted indeterminate text>
            {'Fetching tags'}
          </Loader>
        </Dimmer>
        <Row>
          <Column style={{background: '#FFFFFF'}}>
            <Segment basic>
              { ContextStore.token() && this.state.accountId
                && (
                <div
                  className="ui right floated basic buttons"
                  style={{position: 'absolute', top: '10px', right: '10px'}}
                >
                  <Button
                    basic
                    icon
                    toolTipPosition="bottom right"
                    toolTip="Open in Campaign Manager"
                    onClick={this.handleOpenInCM}
                  >
                    <i className="external icon" />
                  </Button>
                  <FavoriteButton disabled toolTipPosition="bottom right" location={this.props.location} />
                </div>
                )
              }
              <CampaignHeader
                campaign={this.state.campaign}
              />
              <Divider />
              {adSpaces}
            </Segment>
            <Modal small ref={this.fileDownloadModalRef}>
              <div style={{height: '240px'}}>
                <Loader text inverted active={!this.state.fileReady}>Preparing your file...</Loader>
                {this.state.fileReady
                  && (
                  <h3 className="ui icon header center aligned" style={{position: 'relative', top: '50px'}}>
                    <i className="green checkmark icon" />
                    <div className="content">
                      {'Your file is ready'}
                    </div>
                  </h3>
                  )
                }
              </div>
            </Modal>
          </Column>
        </Row>
      </Grid>
    );
  }
}

export default TagManagerModule;

TagManagerModule.propTypes = {
  location: PropTypes.object,
  router: PropTypes.object,
};
