import BaseStore from 'stores/BaseStore';
import AppConstants from 'constants/AppConstants';
import {AssignedAdPosition} from 'constants/ServerConstants';
import {register} from 'dispatchers/AppDispatcher';
/**
 * Handles Project data
 */
const AssignedAdPositionStore = Object.assign({}, BaseStore, {
  adPositions: [],
  /**
   * returns assigned ad positions
   * @date   2016-12-01
   * @return {Array}   assigned ad positions
   */
  getAssignedAdPositions() {
    return this.adPositions;
  },
  /**
   * Lists assigned ad position details.
   * @param {Object} data account_id, id
   */
  listDetails(data) {
    const onSuccess = (response) => {
      // console.group('AssignedAdPostion details');
      // console.dir(data);
      // console.groupEnd();
      this.emitChange({action: AppConstants.LIST_ASSIGNED_AD_POSITIONS_DETAILS, response});
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.LIST_ASSIGNED_AD_POSITIONS_DETAILS, message});
    };

    this.post({
      url: AssignedAdPosition.DETAILS,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Fetches a list of assigned ad positions
   * @date   2016-12-01
   * @param  {Number}   accountId account id
   */
  listAssignedAdPositions(data) {
    const onSuccess = (response) => {
      this.emitChange({action: AppConstants.LIST_ASSIGNED_AD_POSITIONS, response});
    };
    const onError = (message) => {
      this.emitError({action: AppConstants.LIST_ASSIGNED_AD_POSITIONS, message});
    };

    this.post({
      url: AssignedAdPosition.LIST,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Updates landing url on an assigned ad position
   * @param {Object} data data object
   */
  update(data) {
    const onSuccess = () => {
      this.emitChange({action: AppConstants.UPDATE_ASSIGNED_AD_POSITION});
    };
    const onError = (message) => {
      this.emitError({action: AppConstants.UPDATE_ASSIGNED_AD_POSITION}, message);
    };

    this.post({
      url: AssignedAdPosition.UPDATE,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Performs a search for assigned ad positions
   * @param {Object} queryData query params
   */
  search(queryParams) {
    const onSucces = () => {
      // console.group('assigned ad position search');
      // console.dir(response);
      // console.groupEnd();
    };
    const onError = () => {
      // console.group('error assigned ad position search');
      // console.dir(message);
      // console.groupEnd();
    };
    const data = {
      account_id: queryParams.accountId,
      detailed_response: true,
      conditions: {
        '-OR': {
          id: {'-IN': 177},
        },
      },
    };
    this.post({
      url: AssignedAdPosition.SEARCH,
      data,
      onSucces,
      onError,
    });
  },
  dispatcherIndex: register((action) => {
    switch (action.actionType) {
      case AppConstants.LIST_ASSIGNED_AD_POSITIONS:
        AssignedAdPositionStore.listAssignedAdPositions({account_id: action.accountId});
        break;
      case AppConstants.DETAILS_ASSIGNED_AD_POSITIONS:
        AssignedAdPositionStore.listDetails({
          account_id: action.accountId,
          latest_creative_version_id: action.latestCreativeVersionId,
          // id: action.assignedAdPositionId,
        });
        break;
      case AppConstants.UPDATE_ASSIGNED_AD_POSITION:
        AssignedAdPositionStore.update({
          account_id: action.accountId,
          id: action.assignedAdPositionId,
          landing_url: action.landingUrl,
        });
        break;
      case AppConstants.SEARCH_ASSIGNED_ADPOSITION:
        AssignedAdPositionStore.search({
          accountId: action.accountId,
          insertionIds: action.insertionIds,
        });
        break;
      // no default
    }
  }),
});

export default AssignedAdPositionStore;
