import React from 'react';
import Button, {Buttons} from 'weborama-ui-react/Button';
import {Field} from 'weborama-ui-react/Form';
import Message from 'weborama-ui-react/Message';

const ClearAccountCacheForm = () => (
  <div>
    <div className="content" style={{marginBottom: '20px'}}>
      <Field>
        <h4 className="ui header">
          <Message warning>
            {`All server - cache used for this account will be deleted.
              This will impact the speed of Admire II for this account, until the cache has been rebuild.`}
          </Message>
        </h4>
      </Field>
    </div>
    <div className="actions">
      <Buttons two>
        <Button fluid primary approve>Ok!</Button>
        <Button fluid cancel>Cancel</Button>
      </Buttons>
    </div>
  </div>
);

export default ClearAccountCacheForm;
