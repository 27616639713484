import React from 'react';
import ContextStore from 'stores/ContextStore';
import FavoriteWrapper from 'components/FavoriteWrapper';
// import 'vendor/semantic/popup.min.js';
// import 'vendor/semantic/popup.min.css';
/**
 * Favorite popup button class
 */
class FavoritePopupButton extends React.Component {

  constructor() {
    super();
    this.state = {
      oldContextParams: {},
    };
    this.favoritePopupBtnRef = React.createRef();
  }
  /**
   * Init popup button
   * @date   2017-01-21
   */
  componentDidMount() {
    $(this.favoritePopupBtnRef.current).popup({
      on: 'click',
      position: 'bottom right',
      lastResort: 'bottom right',
      popup: '.favorites-popup',
    });
  }

  /**
   * Check whether we are navigating around so that we can close the popup
   */
  componentDidUpdate() {
    if (JSON.stringify(ContextStore.routingParams()) !== JSON.stringify(this.state.oldContextParams)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ oldContextParams: ContextStore.routingParams() }, () => {
        this.closePopup();
      });
    }
  }

  /**
   * Handles closing the popup
   */
  closePopup() {
    if ($(this.favoritePopupBtnRef.current).popup('is visible')) {
      $(this.favoritePopupBtnRef.current).popup('hide');
    }
  }

  render() {
    return (
      <span>
        <div ref={this.favoritePopupBtnRef} className="ui circular icon button">
          <i className="yellow star icon" />
        </div>
        <div className="ui fluid popup">
          <FavoriteWrapper />
        </div>
      </span>
    );
  }
}

export default FavoritePopupButton;
