import BaseStore from 'stores/BaseStore';
import ServerConstants from 'constants/ServerConstants';
import { register } from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';
import ToastrActions from 'actions/Toastr';

const AdElementStore = Object.assign({}, BaseStore, {
  adElements: [],
  req: {},
  /**
   * returns AdPositions
   * @date   2016-01-14
   * @return {array}   [Folders]
   */
  getAdElements() {
    return this.adElements;
  },
  /**
   * Fetches ad position details
   * @date   2016-06-08
   * @param  {number}   accountId    account Id
   * @param  {number}   adPositionId ad position id
   */
  listAdPositionDetails(data) {
    const onSuccess = (response) => {
      this.adElements = response.data.slice();
      this.emitChange({ action: AppConstants.LIST_ADPOSITION_DETAILS, adPositionId: data.id });
    };

    const onError = (message) => {
      this.emitError({ action: AppConstants.LIST_ADPOSITION_DETAILS, message });
    };

    this.post({
      url: ServerConstants.CREATIVE_LIBRARY.AD_POSITIONS.DETAILS,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Fetches ad elements by ad position
   * @date   2016-02-12
   * @param  {number}   accountId          Account Id
   * @param  {number}   adPositionId     Ad position id
   * //TODO: This can be removed.. is not being used.. (tst it);
   */
  fetchAdElementsByAdPosition(accountId, adPositionId) {
    const onSuccess = (data) => {
      this.adElements = data.data.slice();
      this.emitChange({ action: AppConstants.LIST_ADELEMENTS });
    };

    const onError = (message) => {
      this.emitError({ action: AppConstants.LIST_ADELEMENTS, message });
    };

    this.post({
      url: ServerConstants.CREATIVE_LIBRARY.ADELEMENTS.LIST,
      data: {
        account_id: accountId,
        ad_position_id: adPositionId,
      },
      onSuccess,
      onError,
    });
  },
  /**
   * Updates ad element role
   * @date   2016-06-14
   * @param  {number}   accountId             account id
   * @param  {array}    adElementIds          ad element ids
   * @param  {number}   adElementRoleId       ad element role id
   * @param  {number}   creativeVersionId     creative version id
   * @param  {number}   originalAdPositionId  original ad position id
   * @param  {number}   adPositionId          ad position id
   */
  updateAdElementRole(data) {
    if (typeof this.req.abort === 'function' && this.req.readyState !== 4) {
      this.req.abort();
    }
    const onSuccess = () => {
      this.emitChange({ action: AppConstants.UPDATE_ADELEMENT_ROLE });

      AdElementStore.listAdPositionDetails({
        account_id: data.account_id,
        folder_id: data.folder_id,
        creative_id: data.creative_id,
        creative_version_id: data.creative_version_id,
        id: data.original_ad_position_id,
      });
    };

    const onError = (message) => {
      this.emitError({ action: AppConstants.UPDATE_ADELEMENT_ROLE, message });
    };

    this.req = this.post({
      url: ServerConstants.CREATIVE_LIBRARY.ADELEMENTS.UPDATE_ROLE,
      data,
      onSuccess,
      onError,
    });
  },
  updateMultipleAdElementRoles(data) {
    const onSuccess = () => {
      ToastrActions.success({
        title: 'Move ad elements',
        body: `Selected ad elements have been moved to ad position ${data.ad_position_id}`,
      });
      this.emitChange({ action: AppConstants.UPDATE_MULTIPLE_ROLE_ADELEMENT, adPositionId: data.ad_position_id });
    };
    const onError = (message) => {
      ToastrActions.error({
        title: 'Move ad elements',
        body: `Error when moving ad elements: ${message}`,
      });
      this.emitError({ action: AppConstants.UPDATE_MULTIPLE_ROLE_ADELEMENT, message });
    };
    this.post({
      url: ServerConstants.CREATIVE_LIBRARY.ADELEMENTS.UPDATE_MULTIPLE_ROLE,
      data,
      onSuccess,
      onError,
    });
  },
  newAdPosWithElements(data) {
    const onSuccess = (response) => {
      this.emitChange({ action: AppConstants.NEW_ADPOS_WITH_ELEMENTS, data: response });
      ToastrActions.success({
        title: 'Ad position creation with elements',
        body: 'A new ad position with selected element(s) have been created',
      });
    };
    const onError = (message) => {
      this.emitError({ action: AppConstants.NEW_ADPOS_WITH_ELEMENTS, message });
      ToastrActions.error({
        title: 'Ad position creation with elements',
        body: message,
      });
    };
    this.post({
      url: ServerConstants.CREATIVE_LIBRARY.ADELEMENTS.NEW_ADPOS_WITH_ELEMENTS,
      data,
      onSuccess,
      onError,
    });
  },
  newMultipleAdPosWithElements(data) {
    const onSuccess = (response) => {
      this.emitChange({ action: AppConstants.NEW_MULTIPLE_ADPOS_WITH_ELEMENTS, data: response });
      ToastrActions.success({
        title: 'Multiple ad position creation with ad elements',
        body: 'New ad positions with selected elements have been created',
      });
    };
    const onError = (message) => {
      this.emitError({ action: AppConstants.NEW_MULTIPLE_ADPOS_WITH_ELEMENTS, message });
      ToastrActions.error({
        title: 'Multiple Ad position creation with ad elements',
        body: message,
      });
    };
    this.post({
      url: ServerConstants.CREATIVE_LIBRARY.ADELEMENTS.NEW_MULTIPLE_ADPOS_WITH_ELEMENTS,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * removes ad elements from ad position
   * @date   2016-06-14
   * @param  {[type]}   accountId    [description]
   * @param  {[type]}   adElementIds [description]
   */
  removeAdElements(data) {
    const onSuccess = () => {
      this.emitChange({ action: AppConstants.REMOVE_ADELEMENTS });
      AdElementStore.listAdPositionDetails({
        account_id: data.account_id,
        folder_id: data.folder_id,
        creative_id: data.creative_id,
        id: data.ad_position_id,
      });
    };
    const onError = (message) => {
      this.emitError({ action: AppConstants.REMOVE_ADELEMENTS, message });
    };

    this.post({
      url: ServerConstants.CREATIVE_LIBRARY.ADELEMENTS.DELETE,
      data,
      onSuccess,
      onError,
    });
  },

  /**
   * Function to distribute selected ad elements to other components without prop drilling
   * @param  {Array}   data array of selected ad elements
   */
  selectAdElements(data) {
    this.emitChange({ action: AppConstants.SELECT_ADELEMENTS, data });
  },

  /**
   * Handle Store actions
   * @date   2016-02-11
   * @param  {Function}   function(action -
   */
  dispatcherIndex: register((action) => {
    switch (action.actionType) {
      case AppConstants.LIST_ADELEMENTS:
        AdElementStore.fetchAdElementsByAdPosition(
          action.accountId,
          action.adPositionId,
        );
        break;
      case AppConstants.LIST_ADPOSITION_DETAILS:
        AdElementStore.listAdPositionDetails({
          account_id: action.accountId,
          folder_id: action.folderId,
          creative_id: action.creativeId,
          creative_version_id: action.creativeVersionId,
          id: action.adPositionId,
        });
        break;
      case AppConstants.UPDATE_ADELEMENT_ROLE:
        AdElementStore.updateAdElementRole({
          account_id: action.accountId,
          folder_id: action.folderId,
          creative_id: action.creativeId,
          ad_element_ids: action.adElementIds,
          ad_element_role_id: action.adElementRoleId,
          creative_version_id: action.creativeVersionId,
          original_ad_position_id: action.originalAdPositionId,
          ad_position_id: action.adPositionId,
        });
        break;
      case AppConstants.UPDATE_MULTIPLE_ROLE_ADELEMENT:
        AdElementStore.updateMultipleAdElementRoles({
          account_id: action.accountId,
          folder_id: action.folderId,
          creative_id: action.creativeId,
          ad_element_ids: action.adElementIds,
          ad_element_role_id: action.adElementRoleId,
          creative_version_id: action.creativeVersionId,
          original_ad_position_id: action.originalAdPositionId,
          ad_position_id: action.adPositionId,
        });
        break;
      case AppConstants.NEW_ADPOS_WITH_ELEMENTS:
        AdElementStore.newAdPosWithElements({
          account_id: action.accountId,
          creative_id: action.creativeId,
          ad_element_ids: action.adElementIds,
          ad_element_role_id: action.adElementRoleId,
          creative_version_id: action.creativeVersionId,
          original_ad_position_id: action.originalAdPositionId,
        });
        break;
      case AppConstants.NEW_MULTIPLE_ADPOS_WITH_ELEMENTS:
        AdElementStore.newMultipleAdPosWithElements({
          account_id: action.accountId,
          creative_id: action.creativeId,
          ad_element_ids: action.adElementIds,
          ad_element_role_id: action.adElementRoleId,
          creative_version_id: action.creativeVersionId,
          original_ad_position_id: action.originalAdPositionId,
        });
        break;
      case AppConstants.REMOVE_ADELEMENTS:
        AdElementStore.removeAdElements({
          account_id: action.accountId,
          folder_id: action.folderId,
          creative_id: action.creativeId,
          ad_position_id: action.adPositionId,
          creative_version_id: action.creativeVersionId,
          ids: action.adElementIds,
        });
        break;
      case AppConstants.SELECT_ADELEMENTS:
        AdElementStore.selectAdElements({
          adElements: action,
        });
        break;
      // no default
    }
    return true; // No errors. Needed by promise in Dispatcher.
  }),
});

export default AdElementStore;
