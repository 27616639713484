import BaseStore from './BaseStore';
import AppConstants from '../constants/AppConstants';
import ServerConstants from '../constants/ServerConstants';
import {register} from '../dispatchers/AppDispatcher';
/**
 * Handles Project data
 */
const SizeStore = Object.assign({}, BaseStore, {
  sizes: [],
  /**
   * Returns list of ad sizes
   * @date   2016-06-07
   * @return {[type]}   [description]
   */
  getSizes() {
    return this.sizes;
  },
  /**
   * Fetches a list of ad Sizes
   * @date   2016-06-07
   */
  listSizes() {
    const onSuccess = (response) => {
      this.sizes = response.data.slice();
      this.emitChange({action: AppConstants.LIST_SIZES});
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.LIST_SIZES, message});
    };

    this.post({
      url: ServerConstants.CREATIVE_LIBRARY.SIZES.LIST,
      data: {
        order_fields: ['is_generic'],
        order_direction: 'asc',
      },
      onSuccess,
      onError,
    });
  },
  dispatcherIndex: register((action) => {
    switch (action.actionType) {
      case AppConstants.LIST_SIZES:
        SizeStore.listSizes(action.accountId, action.label);
        break;
      //  no default
    }
    return true;
  }),
});
export default SizeStore;
