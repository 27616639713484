import BaseStore from 'stores/BaseStore';

/**
 * Stores commonly used parameters & data used throughout the app
 */
const ContextStore = Object.assign({}, BaseStore, {
  routeParams: undefined,
  routerLocation: undefined,
  routerData: undefined,
  userProfile: undefined,
  adSizes: undefined, // Note that sizes have their own store,
  // but I decided to keep them here to make sure context-related data is grouped together
  activeModule: undefined, // Which module is active? CreativeLibrary, CampaignManager, tags etc.
  spesificToken: undefined,

  routingParams() {
    return this.routeParams;
  },

  setRoutingParams(newRouteParams) {
    this.routeParams = newRouteParams;
  },

  /* Location property of router.
   Look into merging it with router in the future */
  location() {
    return this.routerLocation;
  },

  setLocation(newLocation) {
    this.routerLocation = newLocation;
  },

  router() {
    return this.routerData;
  },

  setRouter(newRouter) {
    this.routerData = newRouter;
  },

  profile() {
    return this.userProfile;
  },

  setProfile(newProfileData) {
    this.userProfile = newProfileData;
  },

  sizes() {
    return this.adSizes;
  },

  setSizes(newAdSizes) {
    this.adSizes = newAdSizes;
  },

  active() {
    return this.activeModule;
  },

  setActive(newActiveModule) {
    this.activeModule = newActiveModule;
  },

  token() {
    return this.spesificToken;
  },

  setToken(newToken) {
    this.spesificToken = newToken;
  }

});

export default ContextStore;
