import {dispatch} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const AssignedAdPosition = {
  /**
   * Dispatches an action to fetch a list of assigned ad positions
   * @param {Object} data data object {accountId}
   */
  list(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.LIST_ASSIGNED_AD_POSITIONS,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to update an assigned ad position
   * @param {Object} data data object
   */
  update(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.UPDATE_ASSIGNED_AD_POSITION,
    }, data);

    dispatch(obj);
  },
  /**
   * Fetch details of assigned ad position
   * @param {Object} data Data object {accountId, assignedAdPositionId}
   */
  details(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.DETAILS_ASSIGNED_AD_POSITIONS,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to list landing urls
   * @param {Object} data {accountId, assignedAdPositionId}
   */
  listLandingUrls(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.LIST_LANDING_URLS,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to search assgined ad positions
   * @param {Object} data data object
   */
  search(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.SEARCH_ASSIGNED_ADPOSITION,
    }, data);

    dispatch(obj);
  },
};

export default AssignedAdPosition;
