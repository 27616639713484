import BaseStore from 'stores/BaseStore';
import {FAVORITES} from 'constants/ServerConstants';
import AppConstants from 'constants/AppConstants';
import {register} from 'dispatchers/AppDispatcher';

const FavoriteStore = Object.assign({}, BaseStore, {
  favorites: [],
  /**
   * return favourites
   * @date   2017-01-19
   * @return {Array}   favourites
   */
  getFavorites() {
    return this.favorites;
  },
  /**
   * Fetches favourites
   * @date   2017-01-19
   */
  fetchFavorites() {
    const onSuccess = (data) => {
      this.favorites = data.data.slice();
      this.emitChange({action: AppConstants.LIST_FAVORITES});
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.LIST_FAVORITES, message});
    };

    this.post({
      url: FAVORITES.LIST,
      onSuccess,
      onError,
    });
  },
  /**
   * Add Favorite
   * @date  2017-01-19
   * @param {Object}   data data
   */
  addFavorite(data) {
    const onSuccess = () => {
      this.emitChange({action: AppConstants.ADD_FAVORITE});
      this.fetchFavorites();
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.ADD_FAVORITE, message});
    };

    this.post({
      url: FAVORITES.CREATE,
      onSuccess,
      onError,
      data,
    });
  },
  /**
   * Remove favorite
   * @date   2017-01-19
   * @param {Number}  id   Favorite id
   */
  removeFavorite(id) {
    const onSuccess = () => {
      this.fetchFavorites();
      this.emitChange({aciton: AppConstants.REMOVE_FAVORITE});
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.REMOVE_FAVORITE, message});
    };

    this.post({
      url: FAVORITES.DELETE,
      onSuccess,
      onError,
      data: {
        id,
      },
    });
  },

  dispatcherIndex: register((action) => {
    // Action will be handled here e.g. remove, edit etc.
    switch (action.actionType) {
      case AppConstants.LIST_FAVORITES:
        FavoriteStore.fetchFavorites();
        break;
      case AppConstants.ADD_FAVORITE:
        FavoriteStore.addFavorite(action.data);
        break;
      case AppConstants.REMOVE_FAVORITE:
        FavoriteStore.removeFavorite(action.favoriteId);
        break;
      // no default
    }
  }),
});

export default FavoriteStore;
