import {dispatch} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const Folder = {
  /**
   * Dispatches an action to fetch a "browsing" list of folders
   * @param {Object} data data object
   */
  browse(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.BROWSE_FOLDERS,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to fetch folders
   * @param {Object} data data object
   */
  list(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.LIST_FOLDERS,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to add a folder
   * @param {Object} data data object
   */
  add(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.ADD_FOLDER,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to update folders
   * @param {Object} data Data object
   */
  update(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.UPDATE_FOLDER,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to remove a folder
   * @param {Object} data data object
   */
  remove(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.REMOVE_FOLDER,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to retrieve the number of creatives in a folder
   * @param {Object} data {accountId}
   */
  getNumberOfCreatives(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.LIST_NOCREATIVESBYFOLDER,
    }, data);

    dispatch(obj);
  },
};

export default Folder;
