import PropTypes from 'prop-types';
import React from 'react';
import InsertionWrapper from 'components/InsertionWrapper';
import AssignedCreativeItem from 'components/AssignedCreativeItem';
import Table from 'weborama-ui-react/Table';
import {getObjectInArray} from 'constants/Utils';

class InsertionListWrapper extends React.Component {
  constructor() {
    super();
    this.state = {
      expandedInsertions: [],
    };
    this.handleExpandCollapse = this.handleExpandCollapse.bind(this);
  }

  handleExpandCollapse(insertion) {
    const expandedInsertions = this.state.expandedInsertions.slice();
    const index = expandedInsertions.indexOf(insertion);
    if (index > -1) {
      expandedInsertions.splice(index, 1);
    } else {
      expandedInsertions.push(insertion);
    }
    this.setState({expandedInsertions});
  }

  render() {
    const tableRows = [];
    this.props.insertions.forEach((insertion) => {
      const isExpanded = getObjectInArray(this.state.expandedInsertions, 'id', insertion.id);
      const assignedCreatives = this.props.creatives.filter((creative) => {
        if (creative.insertion_id === insertion.id) {
          return creative;
        }
        return undefined;
      });

      if (this.props.tokens) {
        const insertionToken = this.props.tokens.filter(singleInsertionToken => insertion.id === singleInsertionToken.ids[0]);
        tableRows.push(<InsertionWrapper
          key={`tr-${insertion.id}`}
          adSpace={this.props.adSpace}
          insertion={insertion}
          creatives={assignedCreatives}
          onExpandCollapse={this.handleExpandCollapse}
          isExpanded={!!isExpanded}
          token={insertionToken}
        />);
      }

      if (isExpanded) {
        this.props.creatives.forEach((creative) => {
          if (insertion.id === creative.insertion_id) {
            tableRows.push(<AssignedCreativeItem key={insertion.label + creative.id + Date.now()} insertion={insertion} creative={creative} />);
          }
        });
      }
    });
    return (
      <Table small very basic selectable>
        {/* <thead>
          <tr>
            <th style={{minWidth: '180px'}} className="collapsing">{'Name'}</th>
            <th className="collapsing">{'Stage'}</th>
            <th>{'Format'}</th>
            <th className="collapsing">{'Start date'}</th>
            <th style={{minWidth: '128px'}} className="collapsing">{'End date'}</th>
          </tr>
        </thead> */}
        <tbody>
          {tableRows}
        </tbody>
      </Table>
    );
  }
}

export default InsertionListWrapper;

InsertionListWrapper.propTypes = {
  insertions: PropTypes.array,
  creatives: PropTypes.array, // eslint-disable-line react/no-unused-prop-types
  loading: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  adSpace: PropTypes.object,
  tokens: PropTypes.array,
};
