import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {InsertionLabelStore, ContextStore} from 'stores';
import Dropdown from 'weborama-ui-react/Dropdown';
import Menu from 'weborama-ui-react/Menu';
import {InsertionLabel} from 'actions';

function InsertionLabels({labels, onChange }) {

  const [insertionLabels, setInsertionLabels] = useState([]);
  const [loading, setLoading] = useState(true);

  const dropdownRef = useRef();

  function onInsertionLabelStoreChange(data) {

    setInsertionLabels(data.data);
    setLoading(false);
  }

  useEffect(() => {
    InsertionLabel.list({accountId: ContextStore.routingParams().accountId});
    InsertionLabelStore.addChangeListener(onInsertionLabelStoreChange);

    return () => InsertionLabelStore.removeChangeListener(onInsertionLabelStoreChange);
  }, []);

  useEffect(() => {
    if (dropdownRef.current && labels) {
      const pureLabels = labels.map(item => item.label);
      dropdownRef.current.setSelected(pureLabels);
    }
  }, [labels]);

  return (
    <Dropdown
      ref={dropdownRef}
      multiple
      selection
      search
      maxSelections={2}
      allowAdditions
      loading={loading}
      className="campaign-labels-dropdown"
      onChange={event => onChange(event)}
    >
      <Menu>
        {insertionLabels.map(item => (
          <div key={`cl-${item.label}`} className="item" data-value={item.label}>{item.label}</div>
        ))}
      </Menu>
    </Dropdown>
  );

}

export default InsertionLabels;

InsertionLabels.propTypes = {
  labels: PropTypes.array,
  onChange: PropTypes.func
};
