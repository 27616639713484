import React from 'react';
import Dropdown from 'weborama-ui-react/Dropdown';
import Item from 'weborama-ui-react/Item';
import Menu from 'weborama-ui-react/Menu';
import ContextStore from 'stores/ContextStore';
import './switch.sass';
/**
 * Class Switch
 */
class Switch extends React.Component {
  /**
   * constructor
   * @date   2016-09-29
   * @param  {Object}   props   properties
   * @param  {Object}   context context
   */
  constructor(props, context) {
    super(props, context);

    this.handleDropdownChange = this.handleDropdownChange.bind(this);
  }

  /**
   * Handles switching between Creative Library and Campaign Manager
   * @date   2016-12-12
   * @param  {String}   value cl or cm
   */
  handleDropdownChange(value) {
    const pathname = (value === 'campaign manager')
      ? ContextStore.router().location.pathname.replace('/cl', '/cm')
      : ContextStore.router().location.pathname.replace('/cm', '/cl');

    const ld = {
      search: ContextStore.router().location.search,
      pathname,
    };

    ContextStore.router().push(ld);
  }

  /**
   * Renders switching
   * @date   2016-09-29
   * @return {array}   Nodes
   */
  render() {
    return (
      <Dropdown
        defaultValue={ContextStore.active() === 'CampaignManager' ? 'Campaign Manager' : 'Creative Library'}
        onChange={this.handleDropdownChange}
        className="switch-dropdown"
      >
        <i className="dropdown icon" />
        <Menu>
          <Item>Campaign Manager</Item>
          <Item>Creative Library</Item>
        </Menu>
      </Dropdown>
    );
  }
}

export default Switch;
