import BaseStore from 'stores/BaseStore';
import ServerConstants from 'constants/ServerConstants';
import {register} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';
import ToastrActions from 'actions/Toastr';

/**
 * AccountStore handles all data actions about acounts
 * @date   2016-01-14
 * @param  {[object]}                        [Dispatcher]
 */
const AccountStore = Object.assign({}, BaseStore, {
  accounts: [],
  recentAccounts: [],
  /**
   * retuns all accounts
   * @date   2016-09-27
   * @return {array}   all accounts
   */
  getAccounts() {
    return this.accounts;
  },
  /**
   * Returns recent accounts
   * @date   2016-09-27
   * @return {[type]}   [description]
   */
  getRecentAccounts() {
    return this.recentAccounts;
  },
  /**
   * Fetches accounts
   * @date   2016-09-27
   */
  listAccounts() {
    const onSuccess = (data) => {
      this.accounts = data.data.slice();
      this.emitChange({action: AppConstants.LIST_ACCOUNTS});
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.LIST_ACCOUNTS, message});
    };

    this.post({
      url: ServerConstants.ACCOUNTS.LIST,
      onSuccess,
      onError,
    });
  },
  getAccount(data) {
    const onSuccess = (response) => {
      this.emitChange({action: AppConstants.GET_ACCOUNT, account: response.data});
    };
    const onError = (message) => {
      this.emitError({action: AppConstants.GET_ACCOUNT, message});
    };
    this.post({
      url: ServerConstants.ACCOUNTS.GET,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Fetches list of recent accounts
   * @date   2016-09-27
   */
  listRecentAccounts() {
    const onSuccess = (data) => {
      this.recentAccounts = data.data.slice();
      this.emitChange({action: AppConstants.LIST_RECENT_ACCOUNTS});
    };
    const onError = (message) => {
      this.emitError({action: AppConstants.LIST_RECENT_ACCOUNTS, message});
    };

    this.post({
      url: ServerConstants.ACCOUNTS.RECENT,
      onSuccess,
      onError,
    });
  },
  /**
   * Clears chache on the server for accountId
   * @date   2016-02-26
   * @param  {number}   accountId id of account
   */
  clearCache(data) {
    this.post({
      url: ServerConstants.ACCOUNTS.CLEAR_CACHE,
      data,
      onSuccess() {
        window.location.reload();
      },
      onError() {
        return false;
      },
    });
  },

  /**
   * Clears chache on the server for all accounts
   */
  clearAllCache() {
    this.post({
      url: ServerConstants.ACCOUNTS.CLEAR_ALL_CACHE,
      onSuccess() {
        window.location.reload();
      },
      onError() {
        this.emitChange({action: AppConstants.CLEAR_ALL_CACHE});
        ToastrActions.error({
          title: 'Clear all cache',
          body: 'There was an error with clearing all cache',
        });
        return false;
      },
    });
  },

  /**
   * Handle Store actions
   * @date   2016-02-11
   * @param  {Function}   function(action -
   */
  dispatcherIndex: register((action) => {
    switch (action.actionType) {
      case AppConstants.LIST_ACCOUNTS:
        AccountStore.listAccounts();
        break;
      case AppConstants.LIST_RECENT_ACCOUNTS:
        AccountStore.listRecentAccounts();
        break;
      case AppConstants.CLEAR_CACHE:
        AccountStore.clearCache({id: action.accountId});
        break;
      case AppConstants.CLEAR_ALL_CACHE:
        AccountStore.clearAllCache();
        break;
      case AppConstants.GET_ACCOUNT:
        AccountStore.getAccount({id: action.accountId});
        break;
      // no default
    }
    return true; // No errors. Needed by promise in Dispatcher.
  }),
});

export default AccountStore;
