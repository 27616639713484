import BaseStore from 'stores/BaseStore';
import {register} from 'dispatchers/AppDispatcher';
import {Support} from 'constants/ServerConstants';
import AppConstants from 'constants/AppConstants';

const SupportStore = Object.assign({}, BaseStore, {
  /**
   * Creates a ticket
   * @param {Object} data data object
   */
  createTicket(data) {
    const onSuccess = () => {
      this.emitChange({action: AppConstants.CREATE_TICKET});
    };
    const onError = (message) => {
      this.emitError({action: AppConstants.CREATE_TICKET, message});
    };
    this.post({
      url: Support.CREATE,
      data,
      onSuccess,
      onError,
    });
  },
  dispatcherIndex: register((action) => {
    if (action.actionType === AppConstants.CREATE_TICKET) {
      SupportStore.createTicket({
        user: JSON.stringify(action.user),
        user_agent: action.userAgent,
        url: action.url,
        title: action.title,
        description: action.description,
      });
    }
    return true;
  }),
});

export default SupportStore;
