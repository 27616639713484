import React from 'react';
import {AdNetwork, AdSpace} from 'actions';
import AppConstants from 'constants/AppConstants';
import {AdSpaceStore, AdNetworkStore, ContextStore} from 'stores';
// import {getObjectInArray} from 'constants/Utils';
import {Grid, Column} from 'weborama-ui-react/Grid';
import AdNetworkList from 'components/AdNetworkList';
import AdSpaceTable from 'components/AdSpaceTable';
/**
 * Class AdSpaceManager
 */
class AdSpaceManager extends React.Component {
  static applyFilter(array, filter) {
    const result = array.filter((item) => {
      if (item.label.toLowerCase().indexOf(filter) > -1 || item.id.indexOf(filter) > -1) {
        return item;
      }
      return false;
    });
    return result;
  }

  /**
   * Cosntructor
   */
  constructor() {
    super();

    this.state = {
      adSpaces: [],
      adNetworks: [],
      selectedAdNetwork: {},
      filteredAdSpaces: [],
      // filterAdNetwork: '',
      loading: {
        adSpaces: true,
        adNetworks: true,
      },
      contextParams: {
        accountId: undefined
      },
    };

    this.onAdSpaceStoreChange = this.onAdSpaceStoreChange.bind(this);
    this.onAdNetworkStoreChange = this.onAdNetworkStoreChange.bind(this);
    // this.handleAdSpaceChange = this.handleAdSpaceChange.bind(this);
    // this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleSelectAdNetwork = this.handleSelectAdNetwork.bind(this);

    AdSpaceStore.addChangeListener(this.onAdSpaceStoreChange);
    AdNetworkStore.addChangeListener(this.onAdNetworkStoreChange);

    this.adNetworkModalRef = React.createRef(); // Redundant???
  }

  /**
   * Fetch a list of ad networks and ad spaces
   */
  componentDidMount() {
    AdSpace.list({accountId: ContextStore.routingParams().accountId});
    AdNetwork.list({accountId: ContextStore.routingParams().accountId});
  }

  /**
   * Fetch new list when account changes
   */
  componentDidUpdate() {
    if (ContextStore.routingParams().accountId !== undefined && ContextStore.routingParams().accountId !== this.state.contextParams.accountId) {
      AdSpace.list({accountId: ContextStore.routingParams().accountId});
      AdNetwork.list({accountId: ContextStore.routingParams().accountId});
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({loading: {adSpaces: true, adNetworks: true, contextParams: ContextStore.routingParams()}});
    }
  }

  /**
   * Remove store callbacks
   */
  componentWillUnmount() {
    AdSpaceStore.removeChangeListener(this.onAdSpaceStoreChange);
    AdNetworkStore.removeChangeListener(this.onAdNetworkStoreChange);
  }

  /**
   * Ad Space Store change
   * @param {Object} data event object
   */
  onAdSpaceStoreChange(data) {
    if (data.action === AppConstants.LIST_ADSPACES) {
      const {loading} = this.state;
      loading.adSpaces = false;
      const adSpaces = AdSpaceStore.getAdSpaces();
      const filteredAdSpaces = adSpaces.filter((adSpace) => {
        if (adSpace.ad_network_id === this.state.selectedAdNetwork.id) {
          return adSpace;
        }
        return false;
      });
      this.setState({adSpaces, filteredAdSpaces, loading});
    }
  }

  /**
   * Ad Network store change
   * @param {Object} data event object
   */
  onAdNetworkStoreChange(data) {
    if (data.action === AppConstants.LIST_ADNETWORKS) {
      const {loading} = this.state;
      loading.adNetworks = false;
      const adNetworks = AdNetworkStore.getAdNetworks();
      adNetworks.sort((a, b) => a.label.localeCompare(b.label));
      // const filteredAdNetworks = AdSpaceManager.applyFilter(adNetworks, this.state.filterAdNetwork);
      this.setState({adNetworks, loading});
      adNetworks.length > 0 && this.handleSelectAdNetwork(adNetworks[0]);
    }
    if (data.action === AppConstants.ADD_ADNETWORK) {
      this.adNetworkModalRef.current.hide(); // Redundant???
    }
  }

  // /**
  //  * Handles selecting adspace
  //  * @param {String} value ad space id
  //  */
  // handleAdSpaceChange(value) {
  //   this.setState({selectedAdSpace: getObjectInArray(this.state.adSpaces, 'id', value)});
  // }
  /**
   * handles filter change
   * @param {Object} e Proxy event object
   */
  // handleFilterChange(e) {
  //   const filterAdNetwork = e.target.value.toLowerCase();
  //   const filteredAdNetworks = AdSpaceManager.applyFilter(this.state.adNetworks, filterAdNetwork);
  //   this.setState({filteredAdNetworks});
  // }
  /**
   * ad network select
   * @param {Object} adNetwork ad network
   */
  handleSelectAdNetwork(adNetwork) {
    const filteredAdSpaces = this.state.adSpaces.filter((adSpace) => {
      if (adSpace.ad_network_id === adNetwork.id) {
        return adSpace;
      }
      return false;
    });
    this.setState({selectedAdNetwork: adNetwork, filteredAdSpaces});
  }

  /**
   * Renders AdSpaceManager
   */
  render() {
    return (
      <Grid two column>
        <Column>
          <div>
            <h2 className="ui header">
              <div className="content">
                {'Ad Networks'}
                <div className="sub header">{`Found ${this.state.adNetworks.length} ad networks`}</div>
              </div>
            </h2>
            <AdNetworkList adNetworks={this.state.adNetworks} onSelectAdNetwork={this.handleSelectAdNetwork} />
          </div>
        </Column>
        <Column>
          <h2 className="ui header">
            <div className="content">
              {'Site / Offers'}
              <div className="sub header">{`Found ${this.state.filteredAdSpaces.length} sites / offers`}</div>
            </div>
          </h2>
          <AdSpaceTable adSpaces={this.state.filteredAdSpaces} adNetwork={this.state.selectedAdNetwork} />
        </Column>
      </Grid>
    );
  }
}

export default AdSpaceManager;
