import React from 'react';
import Dropdown from 'weborama-ui-react/Dropdown';
import Menu from 'weborama-ui-react/Menu';
import PropTypes from 'prop-types';
import Icon from 'weborama-ui-react/Icon';

export default function CampaignVerticalsWebo({onChange}) {
  const verticalsArr = [
    'Arts, Entertainment, Events and Going Out',
    'Automotive',
    'B2B Manufacturing',
    'B2B Services',
    'Careers',
    'E-Commerce Food',
    'E-Commerce Non-Food',
    'Education',
    'Electronic Hardware',
    'Entertainment Streaming Services',
    'Food and Drinks',
    'Gambling and Lotteries',
    'Government and Society',
    'Hobbies and Interests',
    'Home and Lifestyle',
    'Home Utilty Services',
    'Media and Communication',
    'Music and Audio',
    'Non-Profit and Non-Governmental Organisation',
    'Personal Financial Services',
    'Personal Health and Beauty',
    'Pets',
    'Retail Food',
    'Retail Non-Food',
    'Small Business',
    'Style and Fashion',
    'Travel',
    'Video Gaming'
  ];
  return (
    <Dropdown
      inline={false}
      selection
      search
      required
      loading={false}
      className="campaign-verticals-webo-dropdown"
      onChange={(e) => {
        onChange(e);
      }}
    >
      <Icon dropdown />
      <Menu>
        {verticalsArr.map((item) => <div key={`cl-w_${item}`} className="item" data-value={`w_${item}`}>{item}</div>)}
      </Menu>
    </Dropdown>
  );
}

CampaignVerticalsWebo.propTypes = {
  onChange: PropTypes.func,
};
