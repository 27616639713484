import React from 'react';
import PropTypes from 'prop-types';
import CampaignAdSpaceClick from 'components/CampaignAdSpaceClick';

const SiteOfferClickRow = props => (
  <tr key={props.adSpace.ad_space_fields.id}>
    <td className="center aligned collapsing">SO</td>
    <td style={{paddingLeft: '15px'}} className="left aligned single line">
      {props.country && <i className={`${props.country.code2.toLowerCase()} flag`} />}
      <b>{props.adSpace.ad_space_fields.label}</b>
    </td>
    <td className={props.error ? 'error' : ''}>
      <CampaignAdSpaceClick error={props.error} campaignAdSpace={props.adSpace.campaigns_ad_space_fields} />
      {
        props.error && (<p style={{color: 'green'}}><i>{props.error.message}</i></p>)
      }
    </td>
  </tr>
);

export default SiteOfferClickRow;

SiteOfferClickRow.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
  adSpace: PropTypes.object,
  country: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
};
