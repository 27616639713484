import React from 'react';
import PropTypes from 'prop-types';
import Button from 'weborama-ui-react/Button';

const AssignInsertionRow = props => (
  <tr>
    <td className={`single line collapsing ${props.isAssigned ? 'disabled' : ''}`}>
      <p style={{wordBreak: 'break-word', width: 450, whiteSpace: 'pre-wrap'}}>{props.insertion.label}</p>
    </td>
    <td className="right aligned">
      {!props.isAssigned
        && (
        <Button
          basic
          mini
          loading={props.loading}
          onClick={() => { props.onAssignInsertion(props.insertion); }}
        >
          {'Add'}
        </Button>
        )
      }
    </td>
  </tr>
);

export default AssignInsertionRow;

AssignInsertionRow.propTypes = {
  insertion: PropTypes.object,
  isAssigned: PropTypes.bool,
  loading: PropTypes.bool,
  onAssignInsertion: PropTypes.func,
};
