import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

const NoItemsMessage = ((props) => {
  const className = classnames({
    ui: true,
    grey: true,
    center: true,
    aligned: true,
    icon: true,
    header: true,
    transition: true,
  });
  const handleAction = () => {
    props.onAction();
  };
  return (
    <h5 className={className}>
      <i className="mini info icon" />
      {props.label}
      <br />
      <a className="cursor-pointer" onClick={handleAction}>{props.callToAction}</a>
    </h5>
  );
});

export default NoItemsMessage;

NoItemsMessage.propTypes = {
  label: PropTypes.string,
  callToAction: PropTypes.string,
  onAction: PropTypes.func,
};
