import BaseStore from 'stores/BaseStore';
import {register} from 'dispatchers/AppDispatcher';
import TagConstants from 'constants/TagConstants';
import {TagManager} from 'constants/ServerConstants';
/**
 * Tag Store
 */
const PublicTagStore = Object.assign({}, BaseStore, {
  tags: [],
  tokens: [],
  /**
   * Returns array with tags
   * @date   2016-05-03
   * @return {Array}   Tags
   */
  getTags() {
    return this.tags;
  },
  /**
   * Retruns tokens
   * @return {Array}   Tokens
   */
  getTokens() {
    return this.tokens;
  },
  /**
   * Fetches tag for insertion.
   * @date   2016-05-02
   * @param  {String}   token   token
   */
  getTagsFromToken(data) {
    const onSuccess = (response) => {
      this.tags = response.data;
      this.emitChange({action: TagConstants.GET_TAGS_FROM_TOKEN});
    };

    const onError = (message) => {
      this.emitError({action: TagConstants.GET_TAGS_FROM_TOKEN, message});
    };

    this.post({
      url: TagManager.getTagsFromToken,
      data,
      onSuccess,
      onError,
    });
  },
  // getZipFileFromToken(data) {
  //   console.log('getZipFileFromToken');
  //    const defaultSettings = {
  //     dataType: 'json',
  //     type: 'POST',
  //     cache: false,
  //     processData: true,
  //     url: 'http://46.101.204.249/public/getTagsInZipFromToken?public_tag_token=eNqrViqpLEhVslLKzCtOLSrJzM9T0lFKTE7OL80ric9MAUoYmRlaGFkARQuK8rNSk6GiFgZAEYi8iTmQmZyYW5CYmZ4H1WNkCTImJb64IDE5FarD0lCpFgCRhiFO',
  //     contentType: 'Content-Disposition: attachment; filename=example.tags.zip',
  //     onError() {},
  //     onSuccess() {},
  //   };

  //   $.ajax(defaultSettings);
  //   //"http://46.101.204.249/public/getTagsInZipFromToken?public_tag_token=eNqrViqpLEhVslLKzCtOLSrJzM9T0lFKTE7OL80ric9MAUoYmRlaGFkARQuK8rNSk6GiFgZAEYi8iTmQmZyYW5CYmZ4H1WNkCTImJb64IDE5FarD0lCpFgCRhiFO"
  // },
  /**
   * Register at app dispatcherIndex
   */
  dispatcherIndex: register((action) => {
    switch (action.actionType) {
      case TagConstants.GET_TAGS_FROM_TOKEN:
        PublicTagStore.getTagsFromToken({public_tag_token: action.token});
        break;
      case TagConstants.GET_ZIP_FILE_FROM_TOKEN:
        // console.log('get zip file from token');
        // PublicTagStore.getZipFileFromToken();
        // PublicTagStore.getZipFromToken({token: action.token});
        break;
      case TagConstants.GET_TEXT_FILE_FROM_TOKEN:
        break;
      // no default
    }
  }),
});

export default PublicTagStore;
