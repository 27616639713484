import PropTypes from 'prop-types';
import React from 'react';
import {CampaignLabelStore, ContextStore} from 'stores';
import {CampaignLabel} from 'actions';
import Dropdown from 'weborama-ui-react/Dropdown';
import Menu from 'weborama-ui-react/Menu';
import {isEmptyObject} from 'constants/Utils';
/**
 * Handles campaign labels
 */
class CampaignLabels extends React.Component {
  /**
   * constructor
   * @date   2016-12-16
   * @param  {Object}   props properties
   */
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      campaign: props.campaign || {},
      campaignLabels: [],
    };

    this.onCampaignLabelStoreChange = this.onCampaignLabelStoreChange.bind(this);
    this.handleDropDownChange = this.handleDropDownChange.bind(this);
    this.calculateCampaignInState = this.calculateCampaignInState.bind(this);

    CampaignLabelStore.addChangeListener(this.onCampaignLabelStoreChange);

    this.calculateCampaignInState();
    this.campaignLabelDropdownRef = React.createRef();
  }

  /**
   * get a list of campaign labels, register store listener
   * @date   2016-12-16
   */
  componentDidMount() {

    CampaignLabel.list({accountId: ContextStore.routingParams().accountId});
  }

  /**
   * set campaign labels
   * @date   2016-12-16
   * @param  {object}   props current props
   * @param  {object}   prevState previous state
   */
  componentDidUpdate(props, prevState) {
    this.calculateCampaignInState();
    if (!isEmptyObject(this.state.campaign)
      && JSON.stringify(this.state.campaignLabels) !== JSON.stringify(prevState.campaignLabels)) {
      const labels = this.state.campaign.campaign_labels.map(campaignLabel => campaignLabel.label);
      this.campaignLabelDropdownRef.current.setSelected(labels);
    }
  }

  /**
   * unregister change event listeners
   * @date   2016-12-16
   */
  componentWillUnmount() {
    CampaignLabelStore.removeChangeListener(this.onCampaignLabelStoreChange);
  }

  calculateCampaignInState() {
    if (JSON.stringify(this.props.campaign) !== JSON.stringify(this.state.campaign)) {
      this.setState({campaign: this.props.campaign});
    }
  }

  /**
   * Invoked on campaign label store change
   * @date   2016-12-16
   */
  onCampaignLabelStoreChange() {
    const campaignLabels = CampaignLabelStore.getCampaignlabels();
    this.setState({loading: false, campaignLabels});
  }

  /**
   * handles drop down changes
   * @date   2016-09-26
   * @param  {[type]}   e [description]
   */
  handleDropDownChange(e) {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(e.split(','));
    }
  }

  /**
   * Renders campaign label search selection dropdown
   * @date   2016-12-16
   * @return {jsx}   campaign label dropdown
   */
  render() {
    const campaignLabels = this.state.campaignLabels.map(item => (
      <div key={`cl-${item.label}`} className="item" data-value={item.label}>{item.label}</div>
    ));
    return (
      <Dropdown
        ref={this.campaignLabelDropdownRef}
        multiple
        selection
        search
        maxSelections={2}
        allowAdditions
        loading={this.state.loading}
        className="campaign-labels-dropdown"
        onChange={this.handleDropDownChange}
      >
        <Menu>
          {campaignLabels}
        </Menu>
      </Dropdown>
    );
  }
}

export default CampaignLabels;

CampaignLabels.propTypes = {
  campaign: PropTypes.object,
  onChange: PropTypes.func,
};
