import React from 'react';
import { Search as SearchUrl } from 'constants/ServerConstants';
import Cookie from 'js-cookie';
import UIActions from 'actions/UIActions';
import { getDateFromTimeStamp, getLocationDescriptor, parseComments } from 'constants/Utils';
import Search from 'weborama-ui-react/Search';
import Input from 'weborama-ui-react/Input';
import Icon from 'weborama-ui-react/Icon';
import ContextStore from 'stores/ContextStore';
import './Omnibar.scss';

class Omnibar extends React.Component {
  constructor() {
    super();

    this.state = {
      searchQuery: '',
      filter: {
        accounts: true,
        folders: true,
        creatives: true,
        projects: true,
        campaigns: true,
        insertions: true,
      },
      maxResults: 5,
    };
    this.handleSearchChange = this.handleSearchChange.bind(this);

    const JWToken = Cookie.get('warm_auth');
    this.searchSettings = {
      type: 'category',
      minCharacters: 3,
      apiSettings: {
        url: SearchUrl.SEARCH,
        method: 'POST',
        beforeXHR: (xhr) => {
          xhr.setRequestHeader('X-Authorization', `Bearer ${JWToken}`);
        },
        beforeSend: (obj) => {
          obj.data = { // eslint-disable-line no-param-reassign
            account_id: ContextStore.routingParams().accountId,
            search: this.state.searchQuery,
          };
          return obj;
        },
        onResponse: (warmResponse) => {
          const response = {
            results: {
              folders: {
                name: 'Folders',
                results: [],
              },
              creatives: {
                name: 'Creatives',
                results: [],
              },
              campaigns: {
                name: 'Campaigns',
                results: [],
              },
              projects: {
                name: 'Projects',
                results: [],
              },
              insertions: {
                name: 'Insertions',
                results: [],
              },
              accounts: {
                name: 'Accounts',
                results: [],
              },
            },
          };
          if (this.state.filter.creatives) {
            warmResponse.data.creatives.map((creative, index) => {
              if (index >= this.state.maxResults) {
                return false;
              }
              response.results.creatives.results.push({
                title: creative.label,
                description: getDateFromTimeStamp(creative.updated_at),
                id: creative.id,
                folderId: creative.folder_id,
                subject: 'creative',
              });
              return true;
            });
          }
          if (this.state.filter.folders) {
            warmResponse.data.folders.map((folder, index) => {
              if (index >= this.state.maxResults) {
                return false;
              }
              const description = folder.comment
                ? `${parseComments(folder.comments).user} - ${parseComments(folder.comments).comment}` : '';
              response.results.folders.results.push({
                title: folder.label,
                description,
                subject: 'folder',
                id: folder.id,
              });
              return true;
            });
          }
          if (this.state.filter.projects) {
            warmResponse.data.projects.map((project, index) => {
              if (index >= this.state.maxResults) {
                return false;
              }
              response.results.folders.results.push({
                title: project.label,
                description: project.updated_at,
                subject: 'project',
                id: project.id,
              });
              return true;
            });
          }
          if (this.state.filter.campaigns) {
            warmResponse.data.campaigns.map((campaign, index) => {
              if (index >= this.state.maxResults) {
                return false;
              }
              const description = campaign.comments
                ? `${parseComments(campaign.comments).user} - ${parseComments(campaign.comments).comment}` : '';
              response.results.campaigns.results.push({
                title: campaign.label,
                description,
                subject: 'campaign',
                id: campaign.id,
                projectId: campaign.project_id,
              });
              return true;
            });
          }
          if (this.state.filter.insertions) {
            warmResponse.data.insertions.map((insertion, index) => {
              if (index >= this.state.maxResults) {
                return false;
              }
              response.results.insertions.results.push({
                title: insertion.label,
                description: insertion.comments,
                subject: 'insertion',
                projectId: insertion.project_id,
                campaignId: insertion.campaign_id,
                id: insertion.id,
              });
              return true;
            });
          }
          return response;
        },
      },
      onSelect: (result) => {
        let ld = { pathname: ContextStore.router().location.pathname, search: ContextStore.router().location.search };
        switch (result.subject) {
          case 'folder':
            ld = getLocationDescriptor('module', 'cl');
            ld = getLocationDescriptor('folder', result.id, true, ld.pathname);
            break;
          case 'creative':
            ld = getLocationDescriptor('module', 'cl');
            ld = getLocationDescriptor('folder', result.folderId, true, ld.pathname);
            ld = getLocationDescriptor('creative', result.id, false, ld.pathname);
            break;
          case 'project':
            ld = getLocationDescriptor('module', 'cm');
            ld = getLocationDescriptor('project', result.id, true, ld.pathname);
            break;
          case 'campaign':
            ld = getLocationDescriptor('module', 'cm');
            ld = getLocationDescriptor('project', result.projectId, true, ld.pathname);
            ld = getLocationDescriptor('campaign', result.id, true, ld.pathname);
            break;
          case 'insertion':
            ld = getLocationDescriptor('module', 'cm');
            ld = getLocationDescriptor('project', result.projectId, true, ld.pathname);
            ld = getLocationDescriptor('campaign', result.campaignId, false, ld.pathname);
            ld = getLocationDescriptor('insertion', result.id, false, ld.pathname);
            break;
          // no default
        }
        ContextStore.router().push(ld);
        $(this.omniBarRef.current).search('clear cache');
        return result;
      },
      onSearchQuery: () => {
        // this.searchFormRef.current.hide();
      },
    };

    this.omniBarRef = React.createRef(); // Redundant???
  }

  getGreeting() {
    const currentDate = new Date();
    let greeting = '';
    if (currentDate.getHours() < 12) {
      greeting = 'Good Morning';
    } else if (currentDate.getHours() >= 12 && currentDate.getHours() <= 17) {
      greeting = 'Good Afternoon';
    } else if (currentDate.getHours() > 17 && currentDate.getHours() <= 24) {
      greeting = 'Good Evening';
    }
    greeting += ` ${ContextStore.profile().data.firstname}, What can I search for you?`;
    return greeting;
  }

  handleSearchChange(value) {
    if (value === 'Harlem Shake') {
      UIActions.easterEgg();
    }
    this.setState({ searchQuery: value });
  }

  render() {
    return (
      <span className="omnibar-search">
        <Search category fluid settings={this.searchSettings}>
          <Input
            prompt
            left
            icon
            fluid
            placeHolder="Search..."
            onChange={this.handleSearchChange}
          >
            <Icon search />
          </Input>
        </Search>
      </span>
    );
  }
}

export default Omnibar;
