import PropTypes from 'prop-types';
import React from 'react';
import {getReadableFileSize} from 'constants/Utils';
// import UIActions from 'actions/UIActions';
import Checkbox from 'weborama-ui-react/Checkbox';
import './File.sass';
/**
 * Class File
 */
class File extends React.Component {
  /**
   * constructor
   * @date   2017-01-03
   * @param  {Object}   props Properties
   */
  constructor(props) {
    super(props);

    let fileIcon;
    let fileColor;
    switch (props.fileType.label) {
      case 'other':
        fileIcon = 'outline icon file';
        break;
      case 'image':
        fileIcon = 'outline icon file image';
        break;
      case 'audio':
        fileIcon = 'icon file outline audio';
        break;
      case 'video':
        fileIcon = 'icon film';
        break;
      case 'html':
        fileIcon = 'icon html5';
        break;
      default:
        fileIcon = 'icon file outline';
    }

    switch (props.details.extension) {
      case 'bmp':
      case 'gif':
      case 'jpeg':
      case 'jpg':
      case 'png':
      case 'svg':
      case 'tiff':
        fileIcon = 'icon file outline image';
        break;
      case 'mp3':
        fileIcon = 'icon file outline audio';
        break;
      case '3g2':
      case '3gp':
      case 'avi':
      case 'm4v':
      case 'mov':
      case 'mp4':
      case 'mpg':
      case 'ogg':
      case 'ogv':
      case 'qt':
      case 'webm':
      case 'wmv':
        fileIcon = 'icon film';
        break;
      case 'css':
      case 'htm':
      case 'html':
        fileIcon = 'icon html5';
        break;
      case 'js':
        fileIcon = 'icon js square';
        fileColor = 'rgb(77, 171, 247)';
        break;
      default:
        fileIcon = 'icon file outline';
    }

    if ((props.details.extension === 'html' || props.details.extension === 'htm')
    && (props.details.metadata !== null)) {
      fileColor = 'rgb(105, 219, 124)';
    }

    let draggableFile = 'draggable cursor-pointer item draggable-file-item';
    if (props.disabledMoveElements([props.details])) {
      draggableFile += ' filter-drag';
    }

    this.state = {
      fileIcon,
      fileColor,
      // selected: false,
      fileName: props.details.safe_filename || '',
      fileSize: getReadableFileSize(props.details.weight),
      draggableFile,
    };

    this.setChecked = this.setChecked.bind(this);
    this.handleCheckFile = this.handleCheckFile.bind(this);
    this.handleUncheckFile = this.handleUncheckFile.bind(this);
    this.startMarquee = this.startMarquee.bind(this);
    this.stopMarquee = this.stopMarquee.bind(this);
    this.animation = false;

    this.fileWrapperRef = React.createRef();
    this.checkBoxRef = React.createRef();
    this.fileLabelRef = React.createRef();

    this.metadata = this.props.details.metadata;
  }

  /**
   * Initialize hover handler.
   */
  componentDidMount() {
    $(this.fileWrapperRef.current).hover(this.startMarquee, this.stopMarquee);
  }

  /**
   * Checks the checkbox
   * @date 2017-01-03
   */
  setChecked() {
    this.checkBoxRef.current.check();
  }

  /**
   * Unchecks checkbox
   * @date 2017-01-03
   */
  setUnchecked() {
    this.checkBoxRef.current.unCheck();
  }

  /**
   * Handles uncheck file
   * @date   2017-01-03
   * @param  {String}   value value
   */
  handleUncheckFile(value) {
    this.props.onUnchecked(value);
  }

  /**
   * Handle check file
   * @date   2017-01-03
   * @param  {String}   value value
   */
  handleCheckFile(value) {
    this.props.onChecked(value);
  }

  /**
   * Starts marquee
   */
  startMarquee() {
    const wrapperWidth = $(this.fileWrapperRef.current).width();
    const fileNameWidth = $(this.fileLabelRef.current).width();

    if (this.animation) {
      this.animation.cancel();
    }

    if (fileNameWidth > wrapperWidth) {
      const scrollDistance = wrapperWidth - (fileNameWidth * 1.2);
      this.animation = this.fileLabelRef.current.animate(
        [
          {
            left: window.getComputedStyle(this.fileLabelRef.current).left,
          },
          {
            left: `${scrollDistance}px`,
          },
        ],
        {
          duration: 1500,
          easing: 'ease-in-out',
          direction: 'alternate',
          iterations: 2,
        },
      );
    }
  }

  /**
   * Stops marquee
   */
  stopMarquee() {
    if (this.animation) {
      this.animation.reverse();
    }
  }

  /**
   * Renders file element
   * @date   2017-01-03
   * @return {array}   Nodes
   */
  render() {
    let style = {};
    if (this.props.hidden) {
      style = {display: 'none'};
    } else if (this.state.fileColor !== undefined) {
      style = {borderLeft: '2px solid ' + this.state.fileColor, borderRadius: '0 0.5em 0.5em 0', paddingLeft: '5px'};
    }
    return (
      <div
        className={this.state.draggableFile}
        data-id={this.props.details.ad_element_id}
        data-roleid={this.props.details.ad_element_role_id}
        style={style}
      >
        <span className="file-wrapper" ref={this.fileWrapperRef}>
          <div className="bottom aligned content" style={{overFlow: 'hidden', width: '100%'}}>
            <Checkbox
              value={this.props.details.ad_element_id}
              onChecked={this.handleCheckFile}
              onUnchecked={this.handleUncheckFile}
              ref={this.checkBoxRef}
            >
              <span style={{width: '100%', overflow: 'hidden', display: 'flex'}}>
                <i className={this.state.fileIcon} />
                <a className="file-label" ref={this.fileLabelRef}>{this.state.fileName}</a>
              </span>
              {this.metadata && Object.prototype.hasOwnProperty.call(JSON.parse(this.metadata), 'templatename')
                ? (
                  <span style={{width: '100%', overflow: 'hidden', display: 'flex'}}>
                    <i className="info icon" />
                    <div className="file-template-data" ref={this.fileLabelRef}>
                      {JSON.parse(this.metadata).templatename.replace(/-/g, ' ').replace(/_/g, '-')}
                      {' / '}
                      v{JSON.parse(this.metadata).templateversion}
                      {' / '}
                      {JSON.parse(this.metadata).templatedate}
                    </div>
                  </span>
                ) : ''}
              {this.metadata && Object.prototype.hasOwnProperty.call(JSON.parse(this.metadata), 'templatecomment')
               && JSON.parse(this.metadata).templatecomment !== '.'
                ? (
                  <span style={{width: '100%', overflow: 'hidden', display: 'flex'}}>
                    <i className="outline comment icon" />
                    <div className="file-template-comment" ref={this.fileLabelRef}>
                      {JSON.parse(this.metadata).templatecomment.replace(/-/g, ' ').replace(/_/g, '-')}
                    </div>
                  </span>
                ) : ''}
              {this.metadata && Object.prototype.hasOwnProperty.call(JSON.parse(this.metadata), 'templatetagvars')
               && JSON.parse(this.metadata).templatetagvars !== '.'
                ? (
                  <span style={{width: '100%', overflow: 'hidden', display: 'flex'}}>
                    <i className="code icon" />
                    <div className="file-template-comment" ref={this.fileLabelRef} style={{margin: '0', whiteSpace: 'pre'}}>
                      {JSON.parse(this.metadata).templatetagvars.replace(/-/g, ' ').replace('/', '\n')}
                    </div>
                  </span>
                ) : ''}
            </Checkbox>
            <span className="file-details">{this.state.fileSize}</span>
          </div>
        </span>
      </div>
    );
  }
}

export default File;

File.propTypes = {
  details: PropTypes.object.isRequired,
  fileType: PropTypes.object,
  hidden: PropTypes.bool,
  onChecked: PropTypes.func,
  onUnchecked: PropTypes.func,
  disabledMoveElements: PropTypes.func,
};
