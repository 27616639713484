import React from 'react';
import {Router, browserHistory} from 'react-router';
import {render} from 'react-dom';
import App from 'components/App';
import astore from 'stores/AuthenticationStore';
import Cookie from 'js-cookie';
import 'weborama-ui-react/Site';
import './index.scss';
/* eslint-disable */
/**
 * Handles sesion storage
 * @date   2016-01-14
 */
var storage = (function() {
  var uid = new Date();
  var result;
  try {
    sessionStorage.setItem(uid, uid);
    result = sessionStorage.getItem(uid) === uid;
    sessionStorage.removeItem(uid);
    return result && sessionStorage;
  } catch (e) {}
}());
/* eslint-enable */
/**
 * Handles authentication. Function checks if user is authenticated,
 * before every route change.
 * @date   2016-01-18
 * @param  {Object}   nextState    next routing state
 * @param  {Object}   replace      Routing
 */
function requireAuth(nextState, replace) {
  const user = astore.getUser();
  if (user.token === '') {
    if (storage && storage.getItem('warm_auth') !== null) {
      const JWToken = storage.getItem('warm_auth');
      if (JWToken !== undefined && JWToken !== '') {
        user.token = JWToken;
      }
    } else {
      const JWToken = Cookie.get('warm_auth');
      if (JWToken !== undefined && JWToken !== '') {
        user.token = JWToken;
      } else {
        replace({pathname: '/login', state: {nextPathname: nextState.location.pathname + nextState.location.search}});
      }
    }
  }
}

const rootRoute = [
  {
    path: '/',
    component: App,
    onEnter: requireAuth,
    // indexRoute: {component: require('components/Home').default}, // eslint-disable-line global-require
    indexRoute: {
      component: require('components/AccountSelectorWrapper').default, // eslint-disable-line global-require
    },
    childRoutes: [
      require('./routes/account'), // eslint-disable-line global-require
      require('./routes/account/routes/cl'), // eslint-disable-line global-require
      require('./routes/account/routes/cm'), // eslint-disable-line global-require
      require('./routes/account/routes/preview'), // eslint-disable-line global-require
      // require('./routes/account/routes/manage'), // eslint-disable-line global-require
    ],
  },
  {
    path: '/',
    childRoutes: [
      require('./routes/login'), // eslint-disable-line global-require
      require('./routes/public/tags'), // eslint-disable-line global-require
      require('./routes/public/preview'), // eslint-disable-line global-require
    ],
  },
  {
    path: '*',
    component: require('components/NotFound').default, // eslint-disable-line global-require
  },
];

render(
  (
    <Router
      history={browserHistory}
      routes={rootRoute}
    />
  ), document.getElementById('main'),
);

// Info about the routes setup.. React router with code splitting
// https://reactjsnews.com/webpack-in-production
// http://jonathancreamer.com/advanced-webpack-part-2-code-splitting/
