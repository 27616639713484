import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import 'vendor/jwplayer/jwplayer.js';
import './index.sass';
/**
 * Class Vast
 */
class PreviewVast extends React.Component {
  /**
   * constructor
   * @date   2016-10-05
   * @param  {Object}   props properties
   */
  constructor(props) {
    super(props);
    this.state = {
      key: process.env.JWPLAYER_KEY,
    };
    this.JWPlayer = null;
    this.videoRef = React.createRef();
  }

  /**
   * componentDidMount
   * @date   2016-10-05
   */
  componentDidMount() {
    const {jwplayer} = window;
    let vastTag = this.props.setupScript;

    let ad = {
      source: 'vast',
      tag: vastTag,
    };

    if (typeof this.props.setupScript === 'object') {
      vastTag = this.props.setupScript.setup_script;
      ad = {
        source: 'vast',
        vastxml: vastTag,
      };
    }

    const relativeUrl = window.location.origin;

    // Check if we need to use Flash or HTML5 player
    const parser = new DOMParser(); // eslint-disable-line no-undef
    const xmlDoc = parser.parseFromString(vastTag, 'text/xml');
    const mediaFile = xmlDoc.getElementsByTagName('MediaFile');

    let primary = 'html5';

    Array.from(mediaFile).forEach((file) => {
      if (file.innerHTML.indexOf('.swf') > -1
          || file.innerHTML.indexOf('.flv') > -1
          || file.innerHTML.indexOf('.f4v') > -1) {
        primary = 'flash';
      }
    });

    // Setup JW Player
    this.JWPlayer = jwplayer(ReactDOM.findDOMNode(this.videoRef.current)); // eslint-disable-line react/no-find-dom-node
    this.JWPlayer.setup({
      autostart: true,
      key: this.state.key,
      file: `${relativeUrl}/images/weborama_video.mp4`,
      height: 360,
      width: 640,
      flashplayer: `${relativeUrl}/vendor/jwplayer/jwplayer.flash.swf`,
      primary,
      advertising: {
        client: 'vast',
        schedule: {
          adbreak1: {
            offset: 'pre',
            ad,
          },
        },
      },
    });

    // Launch pre-roll
    // this.JWPlayer.on('beforePlay', function() {
    //   this.JWPlayer.playAd('data:text/xml;base64,' + btoa(vastTag));
    // }.bind(this));

    // Inject all ad companions.. currently only img is supported;
    this.JWPlayer.on('adCompanions', (event) => {
      for (let i = 0; i < event.companions.length; i += 1) {
        const entry = event.companions[i];
        const div = document.getElementById('adCompanions');
        div.innerHTML = `<a target="_blank" href="${entry.click}"><img src="${entry.resource}"/></a>`;
      }
    });
  }

  /**
   * Renders vast Preview
   * @date   2016-10-05
   * @return {array}   nodes
   */
  render() {
    return (
      <div className="jwplayer-holder">
        <div ref={this.videoRef} id="jwplayerHolder" />
        <div className="companion-holder">
          <div id="adCompanions" />
        </div>
      </div>
    );
  }
}

export default PreviewVast;

PreviewVast.propTypes = {
  setupScript: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};
