import BaseStore from 'stores/BaseStore';
import ServerConstants from 'constants/ServerConstants';
import {register} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const InsertionLabelStore = Object.assign({}, BaseStore, {
  insertionLabels: [],
  /**
   * Returns insertion labels
   * @date   2016-08-31
   * @return {array}   insertion labels
   */
  getInsertionLabels() {
    return this.insertionLabels;
  },
  /**
   * Fetches insertion labels
   * @param {Number} accountId Account Id
   */
  fetchLabels(accountId) {
    const onSuccess = (response) => {
      const insertionLabels = response.data.slice();
      this.insertionLabels = insertionLabels;
      this.emitChange({action: AppConstants.LIST_INSERTION_LABELS, data: insertionLabels});
    };
    const onError = (message) => {
      this.emitError({action: AppConstants.LIST_INSERTION_LABELS, message});
    };

    this.post({
      url: ServerConstants.CAMPAIGN_MANAGER.INSERTION_LABELS.LIST,
      data: {
        account_id: accountId,
      },
      onSuccess,
      onError,
    });
  },
  dispatcherIndex: register((action) => {
    switch (action.actionType) {
      case AppConstants.LIST_INSERTION_LABELS:
        InsertionLabelStore.fetchLabels(action.accountId);
        break;
      // no default
    }
    return true;
  }),
});

export default InsertionLabelStore;
