import PropTypes from 'prop-types';
import React from 'react';
import { parseComments, makeUserComments, isEmptyObject } from 'constants/Utils';
import AppConstants from 'constants/AppConstants';
import { Folder } from 'actions';
import { FolderStore, ContextStore } from 'stores';
import Checkbox from 'weborama-ui-react/Checkbox';
import Button from 'weborama-ui-react/Button';
import Form, { Field } from 'weborama-ui-react/Form';
import { folderForm } from 'constants/FormValidation';
import Message from 'weborama-ui-react/Message';
import Input from 'weborama-ui-react/Input';

/**
 * Handles add and update of folder properties
 */

class FolderForm extends React.Component {
  /**
   * constructor
   * @param  {object} props properties
   */
  constructor(props) {
    super(props);

    let label = '';
    let comments = '';
    let action = 'add';

    if ('folder' in props && !isEmptyObject(props.folder)) {
      ({ label } = props.folder);
      comments = parseComments(props.folder.comments).comment;
      action = 'update';
    }

    this.state = {
      loading: false,
      label,
      comments,
      action,
      addCreative: true,
      error: {},
      initialFolderForm: { // Only used for form validation
        label: label,
        comments: comments, // We want the parsed comments or an empty string for correct validation
      }
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleAddCreativeChange = this.handleAddCreativeChange.bind(this);
    this.onFolderStoreError = this.onFolderStoreError.bind(this);

    FolderStore.addErrorListener(this.onFolderStoreError);

    this.addCreativeRef = React.createRef();
  }

  componentDidMount() {
    if ('folder' in this.props) {
      if (isEmptyObject(this.props.folder)) {
        this.setState({
          label: '', comments: '', action: 'add', error: {},
        });
      }
    } else {
      this.setState({
        label: this.state.label,
        comments: this.state.comments,
        action: 'update',
        addCreative: false,
        error: {},
      });
    }
  }

  componentWillUnmount() {
    FolderStore.removeErrorListener(this.onFolderStoreError);
  }

  /**
   * Handles errors
   * @param  {Object} error Error Object
   */
  onFolderStoreError(error) {
    if (error.action === AppConstants.ADD_FOLDER || error.action === AppConstants.UPDATE_FOLDER) {
      this.setState({ loading: false, error });
    } else {
      this.setState({ loading: false });
    }
  }

  /**
   * Submits the form
   */
  handleFormSubmit() {
    if (!this.state.loading) {
      let comments = '';
      if (this.state.action === 'add') {
        if (this.state.comments !== '') {
          comments = makeUserComments(ContextStore.profile().data.user.firstname, this.state.comments);
        }
        Folder.add({
          accountId: ContextStore.routingParams().accountId,
          label: this.state.label,
          comments,
          addCreative: this.state.addCreative,
        });
      } else if (this.state.action === 'update') {
        if (this.state.comments !== parseComments(this.props.folder.comments).comment) {
          if (this.state.comments !== '') {
            comments = makeUserComments(ContextStore.profile().data.user.firstname, this.state.comments);
          } else {
            ({ comments } = this.state);
          }
        } else { ({ comments } = this.props.folder); }
        Folder.update({
          accountId: ContextStore.routingParams().accountId,
          folderId: this.props.folder.id,
          label: this.state.label,
          comments,
        });
      }
      this.setState({ loading: true, error: {} });
    }
  }

  /**
   * Controls add creative checkbox
   */
  handleAddCreativeChange() {
    const checked = this.addCreativeRef.current.isChecked();
    this.setState({ addCreative: checked });
  }

  folderFormChangedAndValid() {

    const currentFolderForm = {
      label: this.state.label,
      comments: this.state.comments || '',
    };

    if (JSON.stringify(currentFolderForm) !== JSON.stringify(this.state.initialFolderForm)
      && this.state.label.length > 0) {
      return false;
    }
    return true;
  }

  /**
   * Render folder form
   * @date   2016-07-19
   * @return {JSX}   FolderForm
   */
  render() {
    return (
      <span>
        <Form
          onSubmit={this.handleFormSubmit}
          validation={folderForm}
        >
          <Field required label="Name">
            <Input
              placeHolder="Name"
              name="label"
              value={this.state.label}
              onChange={(label) => { this.setState({ label }); }}
            />
          </Field>
          <Field label="Comments">
            <textarea
              ref="folderComments"
              className="input-folder-comments"
              rows="2"
              placeholder="Comments..."
              value={this.state.comments}
              onChange={(comments) => { this.setState({ comments: comments.target.value }); }}
            />
          </Field>
          <Field className={`transition ${this.state.action === 'update' ? 'hidden' : 'visible'}`}>
            <Checkbox
              ref={this.addCreativeRef}
              checked
              onChange={this.handleAddCreativeChange}
            >
              Also add creative
            </Checkbox>
          </Field>
          <Button
            fluid
            submit
            primary
            loading={this.state.loading}
            onClick={this.handleFormSubmit}
            disabled={this.folderFormChangedAndValid()}
          >
            {`${this.state.action === 'update' ? 'Update' : 'Submit'}`}
          </Button>
        </Form>
        {
          !isEmptyObject(this.state.error)
          && <Message error>{this.state.error.message}</Message>
        }
      </span>
    );
  }
}

FolderForm.propTypes = {
  folder: PropTypes.object,
};

export default FolderForm;
