/**
 * Renders a location descriptor.
 * @date   2016-04-11
 * @param  {String}   subject subject in url e.g. folder, creative, campaign etc.
 * @param  {Number}   itemId id
 * @param  {Boolean}  removeIndent Also remove higher subject items
 * @return {Object}        Location descriptor
 */
export function getLocationDescriptor(subject, itemId, removeIndent = false, fromUrl = undefined) {
  if (subject === 'module') {
    let loc = window.location.pathname;
    // TODO: better use regular expression
    // TODO: Remove this code, very buggy,
    if (itemId === 'cm') {
      loc = loc.replace('/cl/', '/cm/');
    } else if (itemId === 'cl') {
      loc = loc.replace('/cm/', '/cl/');
    }
    return ({ pathname: loc, search: `${window.location.search}` });
  }

  let url = window.location.pathname.split(new RegExp(`${subject}/[\\d]+`));

  if (fromUrl) {
    url = fromUrl.split(new RegExp(`${subject}/[\\d]+`));
  }

  let newUrl;

  if (url.length > 1) {
    newUrl = `${url[0]}${subject}/${itemId}${url[1]}`;
  } else {
    if (url[0] === '/') {
      url[0] = '';
    }
    newUrl = `${url[0]}/${subject}/${itemId}`;
  }
  /*
   * Strip entities that are higher in the hierarchy from the url,
   * e.g. folder/12/creative/23  => folder/12
   */
  if (removeIndent) {
    const CLParams = ['account', 'folder', 'creative', 'adposition'];
    const CMParams = ['account', 'project', 'campaign', 'siteoffer', 'insertion'];
    const CMIndex = CMParams.indexOf(subject);
    const CLIndex = CLParams.indexOf(subject);
    if (CLIndex > -1) {
      for (let i = CLIndex + 1; i <= CLParams.length - 1; i += 1) {
        newUrl = newUrl.replace(new RegExp(`/${CLParams[i]}/[\\d]+`), '');
      }
    }
    if (CMIndex > -1) {
      for (let i = CMIndex + 1; i <= CMParams.length - 1; i += 1) {
        newUrl = newUrl.replace(new RegExp(`/${CMParams[i]}/[\\d]+`), '');
      }
    }
  }
  return ({ pathname: newUrl, search: `${window.location.search}` });
  // So in the end, it returns the path that's supposed to take you to that 'entity'
  // ex. {search: "", pathName: "/account/261954/cm/folder/15/creative/89/adposition/185/project/7/campaign/26"}
}
/**
 * Escapes all regex-chars in a string
 * @date   2016-02-26
 * @param  {String}   str string to escape
 * @return {String}       Escaped string
 */
function pregQuote(str) {
  return (str).replace(/([\\\.\+\*\?\[\^\]\$\(\)\{\}\=\!\<\>\|\:])/g, '\\$1'); // eslint-disable-line no-useless-escape
}
/**
 * Highlights search text
 * http://stackoverflow.com/questions/280793/case-insensitive-string-replacement-in-javascript
 * @date   2016-02-26
 * @param  {String}   data   String to search for keyword
 * @param  {String}   search keyword
 * @return {String}          String with highlights
 */
export function highlightString(data, search) {
  if (search !== '' && data !== null) {
    return data.replace(new RegExp(`(${pregQuote(search)})`, 'gi'), '<strong><mark>$1</mark></strong>');
  }

  return data;
}
/**
 * Searches for object in array of objects
 * @date   2016-03-02
 * @param  {array}    arrayOfObjects Array with objects
 * @param  {key}      key            Key that should match the valueOptional
 * @param  {String}   value          Keyword to search forEach
 * @return {object}                  Object that matches key: value
 */
export function getObjectInArray(arrayOfObjects, key, value) {
  // const elementPos = arrayOfObjects.map(function(obj) {
  //   return String(obj[key]);
  // }).indexOf(String(value));

  const elementPos = arrayOfObjects.map(obj => String(obj[key])).indexOf(String(value));

  return arrayOfObjects[elementPos] || false;
}
/**
 * Returns if an object is empty
 * @date   2016-04-29
 * @param  {Object}   obj Object
 * @return {Boolean}      returns true if object has no own properties
 */
export function isEmptyObject(obj) {
  const { hasOwnProperty } = Object.prototype;
  // null and undefined are "empty"
  if (obj === null || obj === undefined) {
    return true;
  }
  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) { return false; }
  if (obj.length === 0) { return true; }

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  for (const key in obj) { // eslint-disable-line no-restricted-syntax
    if (hasOwnProperty.call(obj, key)) { return false; }
  }
  return true;
}
/* eslint-disable */
/**
 * Converts filesize to readable string
 * borrowed: http://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable
 * @date   2016-06-02
 * @param  {number}   bytes bytes
 * @param  {boolean}   si  unit
 * @return {String}        readable filesize
 */
export function getReadableFileSize(bytes, si = true) {

  var thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    if (!bytes) {
      return '0 B';
    }
    return bytes + ' B';
  }
  var units = si ?
    ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] :
    ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  var u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + ' ' + units[u];
}
/**
 * Returns user comment
 */
export function makeUserComments(firstname, comments) {
  return `##${firstname}## ${comments}`;
}
/**
 * handles comments with username
 * @param {String} comments comments
 */
export function parseComments(comments) {
  if (comments === '' || comments === null || comments === undefined) {
    return comments;
  }
  let userComment = {};
  if (comments.indexOf('##') > -1) {
    userComment.user = comments.split('##')[1];
    userComment.comment = comments.split('##')[2].trim();
  } else {
    userComment.user = 'Anon';
    userComment.comment = comments;
  }
  return userComment;
}
/**
 * Returns date time from timestamp
 * @date   2021-08-06
 * @param  {Number}   UNIX_timestamp timestamp
 * @return {string}                  Date time
 */
export function getDateFromTimeStamp(UNIX_timestamp) {
  var a = new Date(`${UNIX_timestamp} UTC`);
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var time = date + ' ' + month + ' ' + year;
  return time;
}
/**
 * Returns date time from timestamp
 * @date   2016-06-02
 * @param  {Number}   UNIX_timestamp timestamp
 * @return {string}                  Date time
 */
export function getDateTimeFromTimeStamp(UNIX_timestamp) {
  var a = new Date(`${UNIX_timestamp} UTC`);
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = format_two_digits(a.getHours());
  var min = format_two_digits(a.getMinutes());
  var sec = format_two_digits(a.getSeconds());
  var time = hour + ':' + min + /*':' + sec + */ ' ' + date + ' ' + month + ' ' + year;
  return time;
}
/**
 * Formats time into a two digit number e.g: 2 --> 02
 * @date   2016-11-15
 * @param  {Number}   n Number
 * @return {Number}     Two digit number
 */
function format_two_digits(n) {
  return n < 10 ? '0' + n : n;
}
/**
 * Converts time stamp to seconds / minutes / days ago string
 * @date   2016-08-18
 * @param  {number}   date timestamp
 * @returns  {string}   time ago
 */
export function timeSince(date) {
  var seconds = Math.floor((new Date() - date) / 1000);
  var interval = Math.floor(seconds / 31536000);
  if (interval > 1) {
    return interval + ' years';
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + ' months';
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + ' days';
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + ' hours';
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + ' minutes';
  }
  return Math.floor(seconds) + ' seconds';
}
/**
 * Converts time stamp to seconds / minutes / days ago string
 * @date   2016-08-18
 * @param  {number}   date timestamp
 * @returns  {string}   time ago
 */
export function datetimeSince(date) {
  var seconds = Math.floor((new Date() - date) / 1000);
  var interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    var time = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: false }).format(date);
    var day = new Intl.DateTimeFormat('en-US', { day: '2-digit' }).format(date);
    var month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
    var year = new Intl.DateTimeFormat('en-US', { year: 'numeric' }).format(date);
    return time + ' ' + day + ' ' + month + ' ' + year;
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + ' hours ago';
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + ' minutes ago';
  }
  return Math.floor(seconds) + ' seconds ago';
}
/**
 * Copy's messages to the clipboard.
 * @date   2016-05-04
 * @param  {String}   message text to copy to the clipboard
 */
export function copyToClipboard(message) {
  /*eslint-disable */
  function selectElementText(element) {
    if (document.selection) {
      var range = document.body.createTextRange();
      range.moveToElementText(element);
      range.select();
    } else if (window.getSelection) {
      var range = document.createRange();
      range.selectNode(element);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
    }
  }

  var element = document.createElement('TEXTAREA');
  element.textContent = message;
  document.body.appendChild(element);
  selectElementText(element);
  document.execCommand('copy');
  element.remove();
}
/* eslint-enable */
/**
 * Parses JWT Token
 * @date   2016-09-28
 * @param  {String}   token Token
 * @return {Object}         Token info
 */
export function parseJWT(token) {
  if (token === undefined || token === '' || token === null) {
    return false;
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}
/**
 * Converts 'splatted' params as String to params as Object
 * @date   2016-03-01
 * @param  {Object}   acceptedParams Acceptance criteria
 * @param  {String}   splat          Splatted parameters
 * @return {Object}                  Oject with routing params
 */
export function splatToParams(acceptedParams, splat) {
  const newParams = {};

  acceptedParams.forEach((param) => {
    const splitResult = splat.split(new RegExp(`${param.key}/?`));
    let value = splitResult[1] && splitResult[1].match(new RegExp(param.criteria));

    value = value && value.length > 0 ? value[0] : undefined;

    if (value !== undefined) {
      if (param.type === 'int') {
        value = parseInt(value, 10);
        // Just in case the regex is not tight enough to match a number assign `undefined` to this param
        if (Number.isNaN(value)) {
          value = undefined;
        }
      }
    } else if (!param.valueOptional) {
      return;
    }
    newParams[param.rename || param.key] = value;
  });

  return newParams;
}
/**
 * Splits an array in multiple arrays with a fixed size
 * @param {Array} array array
 * @param {Number} groupSize group size
 */
export function splitArray(array, groupSize = 10) {
  const groups = Array.map(array, (item, index) => (index % groupSize === 0
    ? array.slice(index, index + groupSize) : null)).filter(item => item);
  return groups;
}

export function validateUrl(value) {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value); // eslint-disable-line max-len
}

/**
 * Returns a generated sort function based on supplied parameters
 * @param {String} propertyToCompare
 * @param {boolean} inverse
 */
export function sortFnGenerator(propertyToCompare, inverse) {

  if (!inverse) { // so ascending is true
    return (a, b) => {
      if (a[propertyToCompare] > b[propertyToCompare]) {
        return -1;
      }
      if (a[propertyToCompare] < b[propertyToCompare]) {
        return 1;
      }
      return 0;
    };
  }

  // So ascending is false
  return (a, b) => {
    if (a[propertyToCompare] > b[propertyToCompare]) {
      return 1;
    }
    if (a[propertyToCompare] < b[propertyToCompare]) {
      return -1;
    }
    return 0;
  };
}

/**
 * Removes HTML entities such as '&amp;' and returns a clean string
 * @param {String} stringToClean string that possibly contains HTML entities
 */
export function removeHTMLEntities(stringToClean) {
  // Why are we not doing this at Editable Url?
  // Because it's causing the text to be non-selectable with ctrl+A, which impedes traffickers
  // When they enter landing url's

  const dummyContainer = document.createElement('div');
  dummyContainer.innerHTML = stringToClean.trim();

  return dummyContainer.textContent;
}
