/* eslint import/no-extraneous-dependencies:0 */
import {EventEmitter} from 'events';
import Cookie from 'js-cookie';
import {User} from 'actions';

const CHANGE_EVENT = 'change';
const ERROR_EVENT = 'error';
/**
 * returns query parameter
 * @date   2016-09-22
 * @param  {String}   name query parameter
 * @return {String}        query parameter value
 */
function getParameter(name) {
  const url = window.location.href;
  const qpm = name.replace(/[[]]/g, '\\$&');
  const regex = new RegExp('[?&]' + qpm + '(=([^&#]*)|&|#|$)'); // eslint-disable-line prefer-template
  const results = regex.exec(url);
  if (!results) { return null; }
  if (!results[2]) { return true; }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const debug = (getParameter('debug')) || false;
/**
 * Base classe for stores
 */
const BaseStore = Object.assign({}, EventEmitter.prototype, {
  JWToken: undefined,
  /**
   * Add event listener on CHANGE event.
   * @date   2016-01-15
   * @param  {Function} callback Event Listener callback
   */
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  },
  /**
   * Removes event Listener
   * @date   2016-01-18
   * @param  {Function} callback callback
   */
  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  },
  /**
   * Adds error event handler
   * @date   2016-04-19
   * @param  {Function} callback callback
   */
  addErrorListener(callback) {
    this.on(ERROR_EVENT, callback);
  },
  /**
   * Removes event listener
   * @date   2016-04-19
   * @param  {Function} callback callback
   */
  removeErrorListener(callback) {
    this.removeListener(ERROR_EVENT, callback);
  },
  /**
   * emits event
   * @date   2016-01-14
   * @param  {Object}   data change message
   */
  emitChange(data) {
    this.emit(CHANGE_EVENT, data);
  },
  /**
   * Emits an error event
   * @date   2016-04-19
   * @param  {Object}   data error message
   */
  emitError(data) {
    if (data.message !== 'abort') {
      this.emit(ERROR_EVENT, data);
    }
  },
  /**
   * Executes an xhr post request
   * @date   2016-04-20
   * @param  {Object}   userSettings xhr request settings
   * @return {Object}                xhr request
   */
  post(userSettings) {
    if (this.JWToken === undefined) {
      this.JWToken = Cookie.get('warm_auth');
    }

    const disableCache = getParameter('no_cache') || false;
    const defaultSettings = {
      dataType: 'json',
      type: 'POST',
      cache: false,
      processData: true,
      contentType: userSettings.contentType || 'application/x-www-form-urlencoded; charset=UTF-8',
      onError() {},
      beforeSend: (xhr) => {
        xhr.setRequestHeader('X-Authorization', `Bearer ${this.JWToken}`);
        if (disableCache) {
          xhr.setRequestHeader('X-DisableCache', disableCache);
        }
        if (debug) {
          xhr.setRequestHeader('X-Debug', true);
        }
      },
    };

    const settings = {};

    Object.assign(settings, defaultSettings, userSettings);

    const onError = (jqXHR, textStatus, errorThrown) => {
      let message;
      if (jqXHR.responseJSON !== undefined && jqXHR.responseJSON.message.length > 0) {
        if (jqXHR.responseJSON.message === 'Expired token') {
          User.logOut();
        }
        ({message} = jqXHR.responseJSON);
      } else {
        message = errorThrown;
      }
      if (message !== 'abort') {
        settings.onError(message);
      }
    };
    // TODO: Add debug info
    if (debug) {
      settings.error = onError;
      settings.success = settings.onSuccess;
    } else {
      settings.error = onError;
      settings.success = settings.onSuccess;
    }
    return $.ajax(settings);
  },
});
// set max listeners to unlimited to avoid node warning:
// (node) warning possible eventemitter memory leak detected. 11 listeners added
BaseStore.setMaxListeners(0);
export default BaseStore;
