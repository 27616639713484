import {dispatch} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const Insertion = {
  /**
   * Dispatches an action to list insertions
   *
   * @param {object} data data object
   */
  list(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.LIST_INSERTIONS,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to brwoser insertions
   *
   * @param {object} data Data object
   */
  browse(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.BROWSE_INSERTIONS,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to add an insertions
   *
   * @param {object} data Data Object
   */
  add(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.ADD_INSERTION,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to update an insertion
   *
   * @param {Object} data data object
   */
  update(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.UPDATE_INSERTION,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to remove an insertion
   *
   * @param {Object} data data object
   */
  remove(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.REMOVE_INSERTION,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to duplicate an insertion
   *
   * @param {Object} data data object
   */
  duplicate(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.DUPLICATE_INSERTION,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to get a list of assigned ad positions
   * @param {Object} data data object
   */
  getAssignedAdPositions(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.GET_ASSIGNED_AD_POSITION_FROM_INSERTION,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to fetch a list of details for an array of insertion ids.
   * @param {Object} data data object
   */
  details(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.LIST_INSERTION_DETAILS,
    }, data);

    dispatch(obj);
  },
};

export default Insertion;
