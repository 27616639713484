const moduleWrapper = require('components/ModuleWrapper');
const creativeLibraryModule = require('components/CreativeLibraryModule');

module.exports = {
  path: 'account(/:account)/cl',
  component: moduleWrapper.default,
  name: 'CreativeLibrary',
  indexRoute: {
    component: creativeLibraryModule.default,
  },
  childRoutes: [
    {
      path: '*',
      getComponent(nextState, cb) {
        require.ensure([], () => {
          cb(null, creativeLibraryModule.default);
        });
      },
    },
  ],
};
