import {dispatch} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const SetupScript = {
  /**
   * Dispatches an action to fetch a setup script
   * @param {Object} data {accountId, adPositionId}
   */
  get(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.GET_SETUP_SCRIPT,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to update the setup script
   * @param {Object} data {AccountId, adPositionId, beforeAdElementScript, beforeRenderScript, afterRenderScript}
   */
  update(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.UPDATE_SETUP_SCRIPT,
    }, data);

    dispatch(obj);
  },
  /**
   * Lock / unlocks the setupscript (raw mode)
   * @param {Object} data {accountId, adPositionId, setupScript, lock}
   */
  lock(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.LOCK_SETUP_SCRIPT,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to fetch the preview setup script
   * @param {Object} data {accountId, creativeId}
   */
  getPreviewSetupScript(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.GET_MULTI_PREVIEW_SETUP_SCRIPT,
    }, data);

    dispatch(obj);
  },
};

export default SetupScript;
