import React from 'react';
import PropTypes from 'prop-types';
import {
  CreativeTypeStore,
  CreativeFormatStore,
  SizeStore,
  AuthenticationStore,
} from 'stores';
import {
  Country,
  Favorite,
  CreativeType,
  CreativeFormat,
  Size,
} from 'actions';
import AppConstants from 'constants/AppConstants';
import {parseJWT} from 'constants/Utils';
import {Grid, Column, Row} from 'weborama-ui-react/Grid';
import SmartRouter from 'components/SmartRouter';
// import Navigation from 'components/Navigation';
import Toastr from 'components/Toastr';
import Header from 'components/Header';
import ErrorController from 'components/ErrorController';
import LoadingScreen from 'components/LoadingScreen';
import ModalController from 'components/ModalController';
import Footer from 'components/Footer';
import Account from 'components/Account';
import NavigationProjectList from 'components/NavigationProjectList';
import NavigationFolderList from 'components/NavigationFolderList';
import ContextStore from '../../stores/ContextStore';

/**
 * Module Wrapper class
 */
class ModuleWrapper extends React.Component {
  /**
   * Constructor
   * @date   2017-01-23
   * @param {object} props properties
   */
  constructor(props) {
    super(props);

    const {token} = AuthenticationStore.getUser();
    const profile = parseJWT(token);

    if (profile && profile.data.country_id === null) profile.data.country_id = -1;

    const activeValue = props.route.name || undefined;

    this.state = {
      creativeTypes: [],
      creativeFormats: [],
      active: activeValue,
      allPreloaded: false,
      token,
    };

    ContextStore.setActive(activeValue);
    ContextStore.setToken(token);

    this.onCreativeTypesChange = this.onCreativeTypesChange.bind(this);
    this.onCreativeFormatsChange = this.onCreativeFormatsChange.bind(this);
    this.onSizeStoreChange = this.onSizeStoreChange.bind(this);
    this.calculateActiveRoute = this.calculateActiveRoute.bind(this);

    CreativeTypeStore.addChangeListener(this.onCreativeTypesChange);
    CreativeFormatStore.addChangeListener(this.onCreativeFormatsChange);
    SizeStore.addChangeListener(this.onSizeStoreChange);
  }

  /**
   * Fetch list of favorites
   */
  componentDidMount() {
    if (this.state.token && this.state.token !== '') {
      CreativeFormat.list();
      CreativeType.list();
      Favorite.listFavorites();
      Country.list();
      Size.list();
    }
    this.calculateActiveRoute();
  }

  componentDidUpdate() {
    this.calculateActiveRoute();
  }

  calculateActiveRoute() {
    if (this.props.route.name !== this.state.active) {
      this.setState({active: this.props.route.name});
    }
  }

  /**
   * Remove store callback
   * @date   2017-01-23
   */
  componentWillUnmount() {
    CreativeTypeStore.removeChangeListener(this.onCreativeTypesChange);
    CreativeFormatStore.removeChangeListener(this.onCreativeFormatsChange);
    SizeStore.removeChangeListener(this.onSizeStoreChange);
  }

  /**
   * size store callback
   * @param {Object} data data object
   */
  onSizeStoreChange(data) {
    if (data.action === AppConstants.LIST_SIZES) {
      ContextStore.setSizes(SizeStore.getSizes());
    }
  }

  /**
   * Event listener for changes in the creative types store
   * @date   2016-04-16
   */
  onCreativeTypesChange() {
    const allPreloaded = (this.state.creativeFormats.length > 0);
    this.setState({
      creativeTypes: CreativeTypeStore.getCreativeTypes(),
      allPreloaded,
    });
  }

  /**
   * Event listener for changes in the creative formats store
   * @date   2016-04-16
   */
  onCreativeFormatsChange() {
    const allPreloaded = (this.state.creativeTypes.length > 0);
    this.setState({
      creativeFormats: CreativeFormatStore.getCreativeFormats(),
      allPreloaded,
    });
  }

  /**
   * Renders module wrapper
   * @date   2017-01-23
   * @return {Array}   nodes
   */
  render() {
    return (
      <span>
        <Grid padded>
          <Header />
        </Grid>
        <Grid padded>
          <SmartRouter />
          {this.state.allPreloaded
            && (
            <Row>
              <Grid
                two
                column
                internally
                celled
                stackable
                relaxed
                padded
              >
                <Column four wide>
                  <Account />
                  {this.state.active === 'CreativeLibrary'
                    && <NavigationFolderList maxListItems={10} />
                  }
                  {this.state.active === 'CampaignManager'
                    && <NavigationProjectList maxListItems={10} />
                  }
                </Column>
                <Column twelve wide>
                  {this.props.children}
                </Column>
              </Grid>
            </Row>
            )
          }
          <Row>
            <Footer />
          </Row>
          <Toastr />
          <ErrorController />
          <LoadingScreen />
          <ModalController />
        </Grid>
      </span>
    );
  }
}

ModuleWrapper.propTypes = {
  children: PropTypes.object,
  route: PropTypes.object,
};

export default ModuleWrapper;
