import PropTypes from 'prop-types';
import React from 'react';
import AssignActions from 'actions/AssignActions';
import {Creative, Project, Campaign} from 'actions';
import {
  CreativeStore,
  ProjectStore,
  CampaignStore,
  AdSpaceStore,
  AssignStore,
  ContextStore,
  CountryStore
} from 'stores';
import AppConstants from 'constants/AppConstants';
import {getObjectInArray, isEmptyObject, getLocationDescriptor} from 'constants/Utils';
import Paginator from 'components/Paginator';
import Segment from 'weborama-ui-react/Segment';
import {Grid, Column, Row} from 'weborama-ui-react/Grid';
// import ExpandableList from 'components/ExpandableList';
import Table from 'weborama-ui-react/Table';
import Button from 'weborama-ui-react/Button';
import Label from 'weborama-ui-react/Label';
import Message from 'weborama-ui-react/Message';
// import Divider from 'weborama-ui-react/Divider';
import Filter from 'components/Filter';
// import Flag from 'weborama-ui-react/Flag';
import Dimmer from 'weborama-ui-react/Dimmer';
import Loader from 'weborama-ui-react/Loader';
import AssignInsertionTable from 'components/AssignInsertionTable';
import AssignInsertionRow from 'components/AssignInsertionRow';
// import Menu from 'weborama-ui-react/Menu';
import Item from 'weborama-ui-react/Item';
import Accordion, {Title, Content} from 'weborama-ui-react/Accordion';
import Divider from 'weborama-ui-react/Divider';
/**
 * Class AssignCampaign;
 */
class AssignCampaignForm extends React.Component {
  /**
   * Constructor
   * @date   2016-10-21
   */
  constructor() {
    super();

    this.state = {
      loadingCampaigns: false,
      loadingInsertions: false,
      loadingAssign: false,
      projects: [],
      campaigns: [],
      insertions: [],
      adSpaces: [],
      selectedProject: {},
      selectedCampaign: {},
      selectedAdSpace: {},
      projectsFilter: '', // Filter text used for filtering projects
      campaignsFilter: '', // Filter text used for filtering campaigns
      filterValue: '', // Filter value visible on the filter input itself
      paginator: {
        page: 1,
      },
      maxListItems: 10,
      assignedInsertions: {},
      assignError: {},
    };

    this.onProjectStoreChange = this.onProjectStoreChange.bind(this);
    this.onCampaignStoreChange = this.onCampaignStoreChange.bind(this);
    this.onAdSpaceStoreChange = this.onAdSpaceStoreChange.bind(this);
    this.onAssignStoreError = this.onAssignStoreError.bind(this);
    this.onAssignStoreChange = this.onAssignStoreChange.bind(this);
    this.onCreativeStoreChange = this.onCreativeStoreChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleAssign = this.handleAssign.bind(this);
    this.handleExpandProject = this.handleExpandProject.bind(this);
    this.handleExpandCampaign = this.handleExpandCampaign.bind(this);
    this.handleExpandAdSpace = this.handleExpandAdSpace.bind(this);
    this.handlePaginationChange = this.handlePaginationChange.bind(this);
    // this.handleUnassign = this.handleUnassign.bind(this);
    this.handleInsertionClick = this.handleInsertionClick.bind(this);

    ProjectStore.addChangeListener(this.onProjectStoreChange);
    CampaignStore.addChangeListener(this.onCampaignStoreChange);
    AdSpaceStore.addChangeListener(this.onAdSpaceStoreChange);
    AssignStore.addErrorListener(this.onAssignStoreError);
    AssignStore.addChangeListener(this.onAssignStoreChange);
    CreativeStore.addChangeListener(this.onCreativeStoreChange);
  }

  /**
   * Fetch list of campaigns
   * @date   2016-10-21
   */
  componentDidMount() {
    Creative.get({
      accountId: ContextStore.routingParams().accountId,
      folderId: ContextStore.routingParams().folderId,
      creativeId: ContextStore.routingParams().creativeId,
    });
    Project.browse({accountId: ContextStore.routingParams().accountId});
    // AssignActions.listAssignedInsertions({
    //   accountId: ContextStore.routingParams().accountId,
    //   folderId: ContextStore.routingParams().folderId,
    //   creativeId: ContextStore.routingParams().creativeId,
    //   detailedResponse: true,
    // });
  }

  /**
   * Unregister store change callbacks
   * @date   2016-10-21
   */
  componentWillUnmount() {
    ProjectStore.removeChangeListener(this.onProjectStoreChange);
    CampaignStore.removeChangeListener(this.onCampaignStoreChange);
    AdSpaceStore.removeChangeListener(this.onAdSpaceStoreChange);
    AssignStore.removeErrorListener(this.onAssignStoreError);
    AssignStore.removeChangeListener(this.onAssignStoreChange);
    CreativeStore.removeChangeListener(this.onCreativeStoreChange);
  }

  /**
   * Project store change callback
   * @date   2016-10-21
   * @param  {object}   data Data object
   */
  onProjectStoreChange(data) {
    if (data.action === AppConstants.BROWSE_PROJECTS) {
      this.setState({projects: ProjectStore.getProjects()});
    }
  }

  /**
   * CampaignStore callbacks
   * @date   2016-10-21
   * @param  {Object}   data Data object
   */
  onCampaignStoreChange(data) {
    if (data.action === AppConstants.BROWSE_CAMPAIGNS) {
      const campaigns = CampaignStore.getCampaigns();
      this.setState({campaigns, loadingCampaigns: false});
    }
  }

  /**
   * AdSpaceStore callback
   * @date   2017-01-11
   * @param  {Object}   data data Object
   */
  onAdSpaceStoreChange(data) {
    if (data.action === AppConstants.LIST_ADSPACES_FROM_CAMPAIGN) {
      const adSpacesResult = AdSpaceStore.getCampaignAdSpaces();
      const adSpaces = adSpacesResult.map(adSpace => (
        adSpace.ad_space_fields
      ));
      this.setState({adSpaces});
    }
  }

  /**
   * Assign store change
   * @param {Object} data data object
   */
  onAssignStoreChange(data) {
    if (data.action === AppConstants.LIST_ASSIGNED_INSERTIONS) {
      this.setState({assignedInsertions: AssignStore.getAssignedInsertions()});
    }
    if (data.action === AppConstants.UNASSIGN_INSERTION || data.action === AppConstants.ASSIGN_CREATIVES_INSERTIONS) {
      AssignActions.listAssignedInsertions({
        accountId: ContextStore.routingParams().accountId,
        folderId: ContextStore.routingParams().folderId,
        creativeId: ContextStore.routingParams().creativeId,
        detailedResponse: true,
      });
      Creative.list({accountId: ContextStore.routingParams().accountId, folderId: ContextStore.routingParams().folderId});
      this.setState({loadingAssign: false});
    }
  }

  /**
   * Error callback
   * @param {Object} data error object
   */
  onAssignStoreError(data) {
    if (data.action === AppConstants.UNASSIGN_INSERTION) {
      // this.setState({unAssignError: data});
    }
    if (data.action === AppConstants.ASSIGN_CREATIVES_INSERTIONS) {
      this.setState({loadingAssign: false, assignError: data});
    }
  }

  /**
   * Creative store change callback
   * @param {Object} data data object
   */
  onCreativeStoreChange(data) {
    if (data.action === AppConstants.SEARCH_MATCHING_INSERTIONS) {
      this.setState({insertions: CreativeStore.getMatchingInsertions(), loadingInsertions: false});
    }
    if (data.action === AppConstants.GET_CREATIVE) {
      // const creative = CreativeStore.getCreative();
      // AdPosition.list({
      //   accountId: ContextStore.routingParams().accountId,
      //   folderId: ContextStore.routingParams().folderId,
      //   creativeId: ContextStore.routingParams().creativeId,
      //   creativeVersionId: creative.latest_creative_version_id,
      // });
      // this.setState({creative});
    }
  }

  /**
   * Handles assigning insertions to creativeId
   * @date   2016-10-24
   */
  handleAssign(insertion) {
    const creative = [];
    const insertions = [];
    creative.push(ContextStore.routingParams().creativeId);
    insertions.push(insertion.id);
    AssignActions.assignCreativesInsertions(
      ContextStore.routingParams().accountId,
      insertions,
      creative,
    );
    this.setState({
      assignError: {}, loadingAssign: insertion,
    });
  }

  /**
   * Runs filter changes
   * @param {Object} e event object
   */
  handleFilterChange(e) {

    // If we have a selected project, filter campaigns
    if (Object.keys(this.state.selectedProject).length !== 0 && this.state.selectedProject.constructor === Object) {
      this.setState({campaignsFilter: e.target.value.toLowerCase(), filterValue: e.target.value, paginator: {page: 1}});
    } else {
      // If we don't have a selected project, filter projects
      this.setState({projectsFilter: e.target.value.toLowerCase(), filterValue: e.target.value, paginator: {page: 1}});
    }
  }

  /**
   * Handles expanding a project
   * @param {Object} project Project
   */
  handleExpandProject(project) {
    if (!isEmptyObject(this.state.selectedProject) && project.id === this.state.selectedProject.id) {
      // When unselecting a selected project (collapsing), clear all filter values everywhere
      this.setState({selectedProject: {}, selectedCampaign: {}, selectedAdSpace: {}, projectsFilter: '', campaignsFilter: '', filterValue: ''});
    } else {
      Campaign.browse({accountId: ContextStore.routingParams().accountId, projectId: project.id});
      this.setState({
        selectedProject: project,
        selectedCampaign: {},
        campaigns: [],
        selectedAdSpace: {},
        loadingCampaigns: true,
        filterValue: '' // When selecting a project, empty the filter zone
      });
    }
  }

  /**
   * Handles expanding campaign
   * @param {Object} campaign campaign
   */
  handleExpandCampaign(campaign) {
    if (!isEmptyObject(this.state.selectedCampaign) && campaign.id === this.state.selectedCampaign.id) {
      this.setState({selectedCampaign: {}, selectedAdSpace: {}, insertions: []});
    } else {
      Creative.searchMatchingInsertions({
        accountId: ContextStore.routingParams().accountId,
        folderId: ContextStore.routingParams().folderId,
        creativeId: ContextStore.routingParams().creativeId,
        projectId: this.state.selectedProject.id,
        campaignId: campaign.id,
      });
      Campaign.listAdSpaces({accountId: ContextStore.routingParams().accountId, campaignId: campaign.id});
      this.setState({
        selectedCampaign: campaign,
        selectedAdSpace: {},
        adSpaces: [],
        insertions: [],
        loadingInsertions: true,
      });
    }
  }

  /**
   * Handles expanding ad space
   * @param {Object} adSpace adspace
   */
  handleExpandAdSpace(adSpace) {
    if (!isEmptyObject(this.state.selectedAdSpace) && adSpace.id === this.state.selectedAdSpace.id) {
      this.setState({selectedAdSpace: {}});
    } else {
      this.setState({selectedAdSpace: adSpace});
    }
  }

  /**
   * Handles pagination
   * @date   2016-05-26
   * @param  {number}   page page to navigate to
   */
  handlePaginationChange(page) {
    this.setState({paginator: {page}});
  }

  /**
   * Link to Campaign manager module - assigned insertion
   * @param {Object} insertion assigned insertion
   */
  handleInsertionClick(insertion) {
    let ld = getLocationDescriptor('module', 'cm');
    ld = getLocationDescriptor('project', insertion.project_id, true, ld.pathname);
    ld = getLocationDescriptor('campaign', insertion.campaign_id, false, ld.pathname);
    ContextStore.router().push(ld);
  }

  /**
   * Renders campaign insertion selection
   * @date   2016-10-21
   * @return {array}   nodes  assign campaign/insertion
   */
  render() {
    let adSpaceTable = [];
    if (!isEmptyObject(this.state.selectedCampaign) && !this.state.loadingInsertions) {
      const adSpaceIds = [];
      this.state.insertions.forEach((insertion) => {
        if (adSpaceIds.indexOf(insertion.ad_space_id) === -1) {
          adSpaceIds.push(insertion.ad_space_id);
        }
      });
      adSpaceTable = adSpaceIds.map((adSpaceId) => {
        const insertions = this.state.insertions.filter((insertion) => {
          if (insertion.ad_space_id === adSpaceId) {
            return insertion;
          }
          return false;
        });
        const adSpace = getObjectInArray(this.state.adSpaces, 'id', adSpaceId);
        const insertionRows = insertions.map((insertion) => {
          const isAssigned = !!getObjectInArray(this.props.assignedInsertions, 'id', insertion.id);
          return (
            <AssignInsertionRow
              loading={this.state.loadingAssign.id === insertion.id}
              onAssignInsertion={this.handleAssign}
              key={insertion.id}
              isAssigned={isAssigned}
              insertion={insertion}
            />
          );
        });
        return (
          <AssignInsertionTable
            key={adSpaceId}
            countries={CountryStore.getCountries()}
            adSpace={adSpace}
            insertions={insertions}
            onAssignInsertion={this.handleAssign}
          >
            {insertionRows}
          </AssignInsertionTable>
        );
      });
    }

    const filteredProjects = this.state.projects.filter((project) => {
      if (project.id.toString().indexOf(this.state.projectsFilter) > -1 || project.label.toLowerCase().indexOf(this.state.projectsFilter) > -1) {
        return project;
      }
      return false;
    });

    const paginatorSettings = {
      totalPages: Math.ceil(filteredProjects.length / this.state.maxListItems),
      page: this.state.paginator.page,
    };

    const projects = filteredProjects.slice(
      (0 + (this.state.paginator.page - 1)) * this.state.maxListItems,
      (((0 + (this.state.paginator.page - 1)) * this.state.maxListItems) + this.state.maxListItems),
    );
    /**
     * Generate assigned insertions table
     */
    let assignedProjects = [];
    let assignedCampaigns = [];
    let tmpAdSpace = [];
    if (!isEmptyObject(this.state.assignedInsertions)) {
      this.state.assignedInsertions.projects.forEach((project) => {
        this.state.assignedInsertions.campaigns.forEach((campaign) => {
          if (campaign.project_id === project.id) {
            assignedCampaigns.push((
              <tr key={`pr-${project.id}-ca-${campaign.id}`}>
                <td className="collapsing">Campaign:</td>
                <td colSpan="5" className=" single line"><b>{campaign.label}</b></td>
              </tr>
            ));
            this.state.assignedInsertions.insertions.forEach((insertion) => {
              if (insertion.campaign_id === campaign.id) {
                const adSpaceFound = getObjectInArray(tmpAdSpace, 'id', insertion.ad_space_id) || false;
                if (!adSpaceFound) {
                  const adSpace = getObjectInArray(this.state.assignedInsertions.ad_spaces, 'id', insertion.ad_space_id); // eslint-disable-line max-len
                  tmpAdSpace.push(adSpace);
                  const countryCode = getObjectInArray(CountryStore.getCountries(), 'id', adSpace.country_id);
                  assignedCampaigns.push((
                    <tr key={`ads-${adSpace.id}-${insertion.id}`}>
                      <td>Site</td>
                      <td colSpan="4">
                        {countryCode && <i className={`${countryCode.code2.toLowerCase()} flag`} />}
                        {adSpace.label}
                      </td>
                    </tr>
                  ));
                }
                assignedCampaigns.push((
                  <tr key={`pr-${project.id}-ca-${campaign.id}-in-${insertion.id}`}>
                    <td className="collapsing" />
                    <td style={{marginLeft: '20px'}}>
                      <Button
                        basic
                        tiny
                        icon
                        onClick={() => { this.handleUnassign(insertion); }}
                        toolTip="Unlink insertion"
                        toolTipPosition="bottom left"
                      >
                        <i className="unlinkify icon" />
                      </Button>
                      <a className="cursor-pointer" onClick={() => { this.handleInsertionClick(insertion); }}>
                        {insertion.label}
                      </a>
                    </td>
                  </tr>
                ));
              }
            });
          }
          tmpAdSpace = [];
        });
        assignedProjects.push((
          <Table
            key={`pr-${project.id}`}
            small
          >
            <thead>
              <tr>
                <th>Project:</th>
                <th colSpan="5">{project.label}</th>
              </tr>
            </thead>
            <tbody>
              {assignedCampaigns}
            </tbody>
          </Table>
        ));
        assignedCampaigns = [];
      });
    }
    if (assignedProjects.length === 0) {
      assignedProjects = (
        <div style={{textAlign: 'center'}}>
          <i>This creative is not linked to any insertion</i>
        </div>
      );
    }
    const accordionItems = projects.map((project) => {
      let accordionContent = (
        <Dimmer inverted active>
          <Loader indeterminate text>Loading Campaigns</Loader>
        </Dimmer>
      );
      if (project.id === this.state.selectedProject.id && !this.state.loadingCampaigns) {
        if (this.state.campaigns.length > 0) {

          const filteredCampaigns = this.state.campaigns.filter((singleCampaign) => {
            if (singleCampaign.id.toString().indexOf(this.state.campaignsFilter) > -1 || singleCampaign.label.toLowerCase().indexOf(this.state.campaignsFilter) > -1) {
              return singleCampaign;
            }
            return null;
          });

          accordionContent = filteredCampaigns.map(campaign => (
            <Item
              style={{fontSize: '0.9em'}}
              useATag
              key={campaign.id}
              onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.handleExpandCampaign(campaign); }}
              active={this.state.selectedCampaign.id === campaign.id}
            >
              {campaign.label}
            </Item>
          ));
        } else {
          accordionContent = (<Item style={{fontSize: '0.9em'}} disabled center aligned>No campaigns found</Item>);
        }
      }
      return (
        <Item key={project.id} onClick={() => { this.handleExpandProject(project); }}>
          <Title active={this.state.selectedProject.id === project.id}>
            {project.label}
          </Title>
          {project.id === this.state.selectedProject.id
            && (
            <Content active>
              {accordionContent}
            </Content>
            )
          }
        </Item>
      );
    });

    return (
      <Grid two column divided>
        <Row streched>
          <Column>
            <Segment basic>
              <Label top attached>Select a campaign</Label>
              <Filter value={this.state.filterValue} placeHolder="Filter projects" fluid onChange={this.handleFilterChange} />
              {!isEmptyObject(this.state.assignError)
                && (
                <Message tiny header="Error: Assigning insertion" error closable>
                  {this.state.assignError.message}
                </Message>
                )
              }
              <Accordion fluid secondary vertical menu>
                {accordionItems}
              </Accordion>
              <Paginator
                totalPages={paginatorSettings.totalPages}
                page={paginatorSettings.page}
                onPaginationChange={this.handlePaginationChange}
              />
            </Segment>
          </Column>
          <Column>
            <Segment basic>
              <Label top attached>Link insertions</Label>
              <Dimmer inverted active={this.state.loadingInsertions}>
                <Loader text indeterminate>Loading matching insertions</Loader>
              </Dimmer>
              <Divider hidden />
              {adSpaceTable}
            </Segment>
          </Column>
        </Row>
      </Grid>
    );
  }
}

export default AssignCampaignForm;

AssignCampaignForm.propTypes = {
  assignedInsertions: PropTypes.array,
};
