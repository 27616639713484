import React from 'react';
import PropTypes from 'prop-types';
import AdSpaceAccordion from 'components/AdSpaceAccordion';
import InsertionListWrapper from 'components/InsertionListWrapper';
import {getObjectInArray, isEmptyObject} from 'constants/Utils';
import Icon from 'weborama-ui-react/Icon';
import Label from 'weborama-ui-react/Label';
// import Segment, {Segments} from 'weborama-ui-react/Segment';
import Item, {Items} from 'weborama-ui-react/Item';

const AdSpaceWrapper = ((props) => {
  const adSpaces = props.adSpaces.map((adSpace) => {
    const insertions = props.insertions.filter((insertion) => {
      if (insertion.ad_space_id === adSpace.ad_space_fields.id) {
        return insertion;
      }
      return false;
    });

    let token = null;
    if (!isEmptyObject(props.tokens) && props.tokens && Object.hasOwnProperty.call(props.tokens, 'ad_spaces')) {
      token = getObjectInArray(props.tokens.ad_spaces, 'ad_space_id', adSpace.ad_space_fields.id);
    }
    return (
      <Item key={adSpace.ad_space_fields.id} style={{position: 'relative'}}>
        {token
          && (
          <Label
            tiny
            right
            corner
            onClick={() => {
              window.open(`${window.location.origin}/public/tags/${token.token}`, '_blank');
            }}
          >
            <span data-inverted="true" data-size="small" data-tooltip="Open tag page" style={{cursor: 'pointer'}}>
              <Icon external />
            </span>
          </Label>
          )
        }
        <AdSpaceAccordion adSpace={adSpace}>
          <InsertionListWrapper
            insertions={insertions}
            creatives={props.creatives}
            loading={props.loading}
            adSpace={adSpace}
            tokens={props.tokens.insertions}
          />
        </AdSpaceAccordion>
      </Item>
    );
  });
  return (
    <Items divided>
      {adSpaces}
    </Items>
  );
});

export default AdSpaceWrapper;

AdSpaceWrapper.propTypes = {
  adSpaces: PropTypes.array,
  insertions: PropTypes.array, // eslint-disable-line react/no-unused-prop-types
  creatives: PropTypes.array, // eslint-disable-line react/no-unused-prop-types
  children: PropTypes.any, // eslint-disable-line react/no-unused-prop-types
  loading: PropTypes.bool,
  tokens: PropTypes.object,
};
