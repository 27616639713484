import BaseStore from 'stores/BaseStore';
import ServerConstants, {Campaign} from 'constants/ServerConstants';
import {register} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';
import ToastrActions from 'actions/Toastr';
/**
 * CampaignStore
 */
const CampaignStore = Object.assign({}, BaseStore, {
  campaigns: [],
  thirdPartyTags: [],
  req: {},
  /**
   * Returns list of campaigns;
   * @date   2016-03-14
   * @return {Array}   Campaigns
   */
  getCampaigns() {
    return this.campaigns;
  },
  /**
   * returns third party tags
   */
  getThirdPartyTags() {
    return this.thirdPartyTags;
  },
  /**
   * fetches campaigns by account Id
   * @date   2016-03-14
   * @param  {Number}   accountId id of account
   * @param  {Number}   projectId id of project
   * @param  {Boolean}  browse browse
   */
  listCampaigns(queryData, browse = false) {
    if (typeof this.req.abort === 'function' && this.req.readyState !== 4) {
      this.req.abort();
    }
    let action = AppConstants.LIST_CAMPAIGNS;
    if (browse) {
      action = AppConstants.BROWSE_CAMPAIGNS;
    }

    const onSuccess = (data) => {
      this.campaigns = data.data.slice();
      this.emitChange({action});
    };

    const onError = (message) => {
      this.emitError({action, message});
    };

    const orderSettings = {
      conditions: {status: 'active'},
      order_fields: ['updated_at', 'created_at'],
      order_direction: 'desc',
    };

    const data = Object.assign({}, queryData, orderSettings);

    this.req = this.post({
      url: ServerConstants.CAMPAIGN_MANAGER.CAMPAIGNS.LIST,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Create new CreateCampaign
   * @date  2016-03-15
   * @param {Number}   accountId        Account id
   * @param {Number}   projectId        Project Id
   * @param {String}   label            Campaign name
   * @param {String}   url              Landings url
   * @param {String}   comments=""      Comments
   * @param {Number}   channelId        Channel Id
   * @param {String}   startDate        Start Date
   * @param {String}   endDate          End Date
   * @param {Array}    campaignLabels   campaign labels
   */
  addCampaign(data, addInsertion) {
    const onSuccess = (response) => {
      this.listCampaigns({
        account_id: data.account_id,
        project_id: data.project_id,
      });
      this.emitChange({action: AppConstants.ADD_CAMPAIGN, data: response, addInsertion});
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.ADD_CAMPAIGN, message});
    };

    this.post({
      url: ServerConstants.CAMPAIGN_MANAGER.CAMPAIGNS.CREATE,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Submits edit campaign
   * @date   2016-04-08
   * @param {Number}   accountId      Account id
   * @param {Number}   campaignId     Account id
   * @param {Number}   projectId      Project Id
   * @param {String}   label          Campaign name
   * @param {String}   url            Landings url
   * @param {String}   comments=""    Comments
   * @param {Number}   channelId      Channel Id
   * @param {String}   startDate      Start Date
   * @param {String}   endDate        End Date
   * @param {Array}    campaignLabels campaign labels
   */
  updateCampaign(data) {
    const onSuccess = () => {
      this.listCampaigns({account_id: data.account_id, project_id: data.project_id});
      this.emitChange({action: AppConstants.UPDATE_CAMPAIGN});
      ToastrActions.success({ title: '', body: 'Campaign has been updated' });
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.UPDATE_CAMPAIGN, message});
    };

    this.post({
      url: ServerConstants.CAMPAIGN_MANAGER.CAMPAIGNS.UPDATE,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Remove a project
   * @date   2016-03-16
   * @param  {Number}   accountId  Account id
   * @param  {Number}   campaignId campaignId
   */
  removeCampaign(data) {
    const onSuccess = (response) => {
      this.emitChange({action: AppConstants.REMOVE_CAMPAIGN, data: response});
      this.listCampaigns({
        account_id: data.account_id,
        project_id: data.project_id,
      });
      ToastrActions.success({ title: 'Remove campaign', body: 'Campaign has been removed' });
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.REMOVE_CAMPAIGN, message});
      ToastrActions.error({ title: 'Remove campaign', body: message });
    };

    this.post({
      url: ServerConstants.CAMPAIGN_MANAGER.CAMPAIGNS.DELETE,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Fetches number of insertions for campaigns
   * @date   2016-08-03
   * @param  {number}   accountId account id
   * @param  {array}   ids       campaign id's
   */
  fetchNumberOfInsertions(data) {
    const onSuccess = () => {
      this.emitChange({action: AppConstants.LIST_NO_INSERTIONS_FOR_CAMPAIGNS});
    };

    const onError = (message) => {
      this.emirError({action: AppConstants.LIST_NO_INSERTIONS_FOR_CAMPAIGNS, message});
    };

    this.post({
      url: ServerConstants.CAMPAIGN_MANAGER.CAMPAIGNS.GET_NUMBER_OF_INSERTIONS,
      data,
      onSuccess,
      onError,
    });
  },
  listThirdPartyTags(data) {
    const onSuccess = (response) => {
      this.thirdPartyTags = response.data.slice();
      this.emitChange({action: AppConstants.LIST_THIRD_PARTY_TAGS_FOR_CAMPAIGN});
    };
    const onError = (error) => {
      this.emitError({action: AppConstants.LIST_THIRD_PARTY_TAGS_FOR_CAMPAIGN, message: error});
    };
    this.post({
      url: Campaign.GET_THIRD_PARTY_TAGS,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Uploads media plan
   * @param {Object} params parameters
   */
  uploadMediaPlan(params) {
    const data = new window.FormData(); // eslint-disable no-undef
    data.append('account_id', params.accountId);
    data.append('project_id', params.projectId);
    data.append('campaign_id', params.campaignId);

    params.files.forEach((item) => {
      data.append(item.name, item);
    });

    const onSuccess = () => {
      this.emitChange({action: AppConstants.UPLOAD_FILES});
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.UPLOAD_FILES, message});
    };

    const xhr = () => {
      const xhrScoped = new window.XMLHttpRequest();
      xhrScoped.upload.addEventListener('progress', (evt) => {
        if (evt.lengthComputable) {
          let percentComplete = evt.loaded / evt.total;
          percentComplete = parseInt(percentComplete * 100, 10);
          this.emitChange({action: AppConstants.FILE_UPLOAD_PROGRESS, progress: percentComplete});
        }
      }, false);
      return xhrScoped;
    };

    this.post({
      url: Campaign.UPLOAD_MEDIA_PLAN,
      data,
      processData: false,
      contentType: false,
      onSuccess,
      onError,
      xhr,
    });
  },
  dispatcherIndex: register((action) => {
    // Action will be handled here e.g. remove, edit etc.
    switch (action.actionType) {
      case AppConstants.BROWSE_CAMPAIGNS:
        CampaignStore.listCampaigns({
          account_id: action.accountId,
          project_id: action.projectId,
        }, true);
        break;
      case AppConstants.LIST_CAMPAIGNS:
        CampaignStore.listCampaigns({
          account_id: action.accountId,
          project_id: action.projectId,
        }, false);
        break;
      case AppConstants.ADD_CAMPAIGN:
        CampaignStore.addCampaign({
          account_id: action.accountId,
          project_id: action.projectId,
          channel_id: 1,
          label: action.label,
          landing_url: action.url,
          comments: action.comments,
          external_id: action.externalId,
          external_name: action.externalName,
          // is_auto_start_date: action.startDate !== undefined ? 'NO' : 'YES',
          // is_auto_end_date: action.endDate !== undefined ? 'NO' : 'YES',
          start_date: action.startDate,
          end_date: action.endDate,
          campaign_label__labels: action.campaignLabels,
        }, action.addInsertion);
        break;
      case AppConstants.UPDATE_CAMPAIGN:
        CampaignStore.updateCampaign({
          account_id: action.accountId,
          id: action.campaignId,
          project_id: action.projectId,
          channel_id: 1,
          label: action.label,
          landing_url: action.url,
          comments: action.comments,
          external_id: action.externalId,
          external_name: action.externalName,
          // is_auto_start_date: action.startDate !== undefined ? 'NO' : 'YES',
          // is_auto_end_date: action.endDate !== undefined ? 'NO' : 'YES',
          start_date: action.startDate,
          end_date: action.endDate,
          campaign_label__labels: action.campaignLabels,
        });
        break;
      case AppConstants.REMOVE_CAMPAIGN:
        CampaignStore.removeCampaign({
          account_id: action.accountId,
          project_id: action.projectId,
          id: action.campaignId,
        });
        break;
      case AppConstants.LIST_NO_INSERTIONS_FOR_CAMPAIGNS:
        CampaignStore.fetchNumberOfInsertions({
          account_id: action.accountId,
          ids: action.ids,
        });
        break;
      case AppConstants.LIST_THIRD_PARTY_TAGS_FOR_CAMPAIGN:
        CampaignStore.listThirdPartyTags({
          account_id: action.accountId,
          project_id: action.projectId,
          id: action.campaignId,
        });
        break;
      case AppConstants.UPLOAD_MEDIA_PLAN:
        CampaignStore.uploadMediaPlan(action);
        break;
      // no default
    }
    return true; // No errors. Needed by promise in Dispatcher.
  }),
});

export default CampaignStore;
