import React, { Fragment } from 'react';
import AccountSelector from 'components/AccountSelector';
import Header from 'components/Header';
import { Grid, Column } from 'weborama-ui-react/Grid';
import { ContextStore, AuthenticationStore } from 'stores';
import { Favorite } from 'actions';

class AccountSelectorWrapper extends React.Component {

  constructor() {
    super();

    const {token} = AuthenticationStore.getUser();

    ContextStore.setToken(token);
    ContextStore.setActive('AccountSelection');
  }

  componentDidMount() {
    Favorite.listFavorites();
  }

  render() {
    return (
      <Fragment>
        <Grid padded>
          <Header />
        </Grid>
        <Grid padded>
          <Column>
            <AccountSelector />
          </Column>
        </Grid>
      </Fragment>
    );
  }
}

export default AccountSelectorWrapper;
