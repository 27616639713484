import BaseStore from 'stores/BaseStore';
import ServerConstants from 'constants/ServerConstants';
import {register} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const FileTypeStore = Object.assign({}, BaseStore, {
  fileTypes: [],
  /**
   * Return file types
   * @date   2016-08-18
   * @return {array}   filetypes
   */
  getFileTypes() {
    return this.fileTypes;
  },
  /**
   * Fetches list of file types
   * @date   2016-08-18
   */
  fetchFileTypes() {
    const onSuccess = (response) => {
      this.fileTypes = response.data.slice();
      this.emitChange({action: AppConstants.LIST_FILE_TYPES});
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.LIST_FILE_TYPES, message});
    };

    this.post({
      url: ServerConstants.CREATIVE_LIBRARY.FILE_TYPES.LIST,
      onSuccess,
      onError,
    });
  },
  dispatcherIndex: register((action) => {
    switch (action.actionType) {
      case AppConstants.LIST_FILE_TYPES:
        FileTypeStore.fetchFileTypes();
        break;
      // no default
    }
    return true; // No errors. Needed by promise in Dispatcher.
  }),
});

export default FileTypeStore;
