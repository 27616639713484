import {dispatch} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const InsertionLabel = {
  /**
   * Dispatches an action to retrieve a list of insertion labels
   *
   * @param {Object} data data object (accountId);
   */
  list(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.LIST_INSERTION_LABELS,
    }, data);

    dispatch(obj);
  },
};

export default InsertionLabel;
