import {dispatch} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const Statistics = {
  get(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.GET_STATISTICS,
    }, data);

    dispatch(obj);
  },
  listAvailableDimensions() {
    const obj = Object.assign({}, {
      actionType: AppConstants.LIST_AVAILABLE_DIMENSIONS,
    });

    dispatch(obj);
  },
};

export default Statistics;
