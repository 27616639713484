import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import Sortable from 'sortablejs';

const store = {
  nextSibling: null,
  activeComponent: null,
};
/**
 * Sortable class
 */
class Sortable2 extends React.Component {
  /**
   * constructor
   * @date   2016-08-24
   */
  constructor() {
    super();
    this.sortable = null;
  }

  /**
   * Initialize
   * @date   2016-08-24
   */
  componentDidMount() {
    const {options} = this.props;
    [
      'onStart',
      'onEnd',
      'onAdd',
      'onSort',
      'onUpdate',
      'onRemove',
      'onFilter',
      'onMove',
    ].forEach((name) => {
      const eventHandler = options[name];

      options[name] = (evt) => {
        if (name === 'onStart') {
          store.nextSibling = evt.item.nextElementSibling;
          store.activeComponent = this;
        } else if ((name === 'onAdd' || name === 'onUpdate') && this.props.onChange) {
          const items = this.sortable.toArray();
          const remote = store.activeComponent;
          const remoteItems = remote.sortable.toArray();

          evt.from.insertBefore(evt.item, store.nextSibling);
          if (remote !== this) {
            const remoteOptions = remote.props.options || {};

            if ((typeof remoteOptions.group === 'object') && (remoteOptions.group.pull === 'clone')) {
              // Remove the node with the same data-reactid
              evt.item.parentNode.removeChild(evt.item);
            }
            if (remote.props.onChange) {
              remote.props.onChange(remoteItems, remote.sortable, evt);
            }
          }
          if (this.props.onChange) {
            this.props.onChange(items, this.sortable, evt);
          }
        }

        setTimeout(() => {
          eventHandler && eventHandler(evt);
        }, 0);
      };
    });
    // TODO: This should be move to properties!
    const name = options.group;
    const {put} = options;
    options.group = {
      name,
      put,
    };
    options.chosenClass = 'sortable-chosen';
    options.filter = '.filter-drag';
    options.setData = (dataTransfer, dragEl) => {
      dataTransfer.setData('roleid', dragEl.dataset.roleid);
      dataTransfer.setData('id', dragEl.dataset.id);
    };
    /* eslint-disable */
    this.sortable = Sortable.create(ReactDOM.findDOMNode(this), options);
    /* eslint-enable */
  }

  /**
   * component will mount
   * @date   2016-08-24
   */
  componentWillUnmount() {
    if (this.sortable) {
      this.sortable.destroy();
      this.sortable = null;
    }
  }

  /**
   * Renders sortable lists
   * @date   2016-08-24
   * @return {jsx}   sortable list
   */
  render() {
    return (
      <div className={this.props.className}>
        {this.props.children}
      </div>
    );
  }
}

export default Sortable2;

Sortable2.propTypes = {
  options: PropTypes.object,
  onChange: PropTypes.func,
  children: PropTypes.any,
  className: PropTypes.string,
};
