import React from 'react';
import {splatToParams, parseJWT} from 'constants/Utils';
import PropTypes from 'prop-types';
import {AuthenticationStore, ContextStore} from 'stores';
import LogRocket from 'logrocket';
/**
 * App Component
 */
class App extends React.Component {
  /**
   * Constructs APP component
   * @date   2016-01-27
   * @param  {Object}   props holds location object
   */
  constructor(props) {
    super(props);

    const {token} = AuthenticationStore.getUser();
    this.profile = parseJWT(token);

    if (this.profile && this.profile.data.country_id === null) this.profile.data.country_id = -1;

    this.account = {};
    this.state = {
      routingParams: {
        accountId: undefined,
        folderId: undefined,
        creativeId: undefined,
        adPositionId: undefined,
      },
      oldContextProfile: undefined,
    };
    // Acceptance criterea for routing parameters in the url
    this.acceptedParams = [
      {
        key: 'account', rename: 'accountId', criteria: '[\\d]+', valueOptional: true, type: 'int',
      },
      {
        key: 'folder', rename: 'folderId', criteria: '[\\d]+', valueOptional: true, type: 'int',
      },
      {
        key: 'creative', rename: 'creativeId', criteria: '[\\d]+', valueOptional: true, type: 'int',
      },
      {
        key: 'adposition', rename: 'adPositionId', criteria: '[\\d]+', valueOptional: true, type: 'int',
      },
      {
        key: 'project', rename: 'projectId', criteria: '[\\d]*', valueOptional: true, type: 'int',
      },
      {
        key: 'campaign', rename: 'campaignId', criteria: '[\\d]*', valueOptional: true, type: 'int',
      },
      {
        key: 'siteoffer', rename: 'siteOfferId', criteria: '[\\d]*', valueOptional: true, type: 'int',
      },
      {
        key: 'insertion', rename: 'insertionId', criteria: '[\\d]*', valueOptional: true, type: 'int',
      },
    ];

    if (props.params.account !== undefined) {
      this.account.id = parseInt(props.params.account, 10);
    }
    this.state.routingParams = this.handleRoutingParams(props.params, props.location);
    this.state.oldRouterLocation = props.router.location.pathname;
    this.handleRoutingParams = this.handleRoutingParams.bind(this);
    this.updateRouteParams = this.updateRouteParams.bind(this);
    this.passProfileDataToContextStore = this.passProfileDataToContextStore.bind(this);

    if (window.location.hostname === 'warm.weborama.nl') {
      LogRocket.init('lbspqf/admire-2-prod');
    } // Only run when at prod
  }

  componentDidMount() {

    if (ContextStore.profile() === undefined) {
      // Make sure the profile data is passed to ContextStore
      this.passProfileDataToContextStore();
    }

    if (ContextStore.routingParams() === undefined) {
      // Make sure our routing params are populated
      this.updateRouteParams();
    }
  }

  componentDidUpdate() {

    if (this.props.router && this.props.router.location
      && (this.state.oldRouterLocation !== this.props.router.location.pathname)) {
      // So only update when router is there, we have a location, location changes
      this.updateRouteParams();
    }

    if (JSON.stringify(this.profile) !== JSON.stringify(this.state.oldContextProfile)) {
      this.passProfileDataToContextStore();
    }
  }

  passProfileDataToContextStore() {
    this.setState({
      oldContextProfile: this.profile,
    }, () => {
      const { user } = this.profile.data;
      if (window.location.hostname === 'warm.weborama.nl') {
        LogRocket.identify(user.id, {
          name: `${user.firstname} ${user.lastname}`,
          email: user.email,
        });
      } // Only run when at prod
      ContextStore.setProfile(this.profile);
    });
  }

  updateRouteParams() {
    ContextStore.setRouter(this.props.router);
    ContextStore.setLocation(this.props.location);
    ContextStore.setRoutingParams(this.handleRoutingParams(this.props.params, this.props.location));
    // console.log(ContextStore.location());
    // console.log('routingParams', ContextStore.routingParams());
    // eslint-disable-next-line react/no-did-update-set-state
    this.setState({
      routingParams: this.handleRoutingParams(this.props.params, this.props.location),
      oldRouterLocation: this.props.router.location.pathname
    });
  }
  /**
   * Handles Routing parameters
   * @date   2016-03-01
   * @param  {Object}   prms Routing parameters
   * @param  {Object}   loc    location
   * @return {Object}          URL parameters split into Object
   */
  handleRoutingParams(prms, loc) {
    const params = prms;
    let splitParams;
    // Prefix the splat with the account parameter, so that it will be processed like the other params.
    if (params.account !== undefined) {
      if (params.splat !== undefined) {
        const locWithoutpath = loc.pathname.split('/'); // Turn it to Array
        const filteredParams = locWithoutpath.filter( // Filter unneeded stuff
          (pathParam) => {
            if (pathParam !== 'cl' || pathParam !== 'cm') {
              return true;
            }
            return false;
          }
        );
        filteredParams.shift(); // Remove empty first element
        params.splat = filteredParams.join('/'); // Make a path by joining
      } else {
        params.splat = `account/${params.account}`;
      }
    }
    if (params.splat !== undefined) {
      splitParams = splatToParams(this.acceptedParams, params.splat);
    }

    // TODO: Not the nicest solution... maybe handle this in routing,
    // and set the active param as props
    if (loc.pathname.indexOf('/cl') > -1) {
      params.active = 'cl';
    } else if (loc.pathname.indexOf('/cm') > -1) {
      params.active = 'cm';
    } else {
      params.active = undefined;
    }

    return Object.assign(params, splitParams);
  }

  /**
   * renders app component
   * @date   2016-01-27
   * @return {Jsx}   things
   */
  render() {
    return (
      this.props.children
    );
  }
}

App.propTypes = {
  params: PropTypes.object,
  children: PropTypes.object,
  location: PropTypes.object,
  router: PropTypes.object,
};

export default App;
