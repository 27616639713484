import BaseStore from 'stores/BaseStore';
import AppConstants from 'constants/AppConstants';
import { AdNetwork } from 'constants/ServerConstants';
import { register } from 'dispatchers/AppDispatcher';
import ToastrActions from 'actions/Toastr';

const AdNetworkStore = Object.assign({}, BaseStore, {
  adNetworks: [],
  /**
   * Returns an array of ad networks
   * @date   2016-04-01
   * @return {Array}   Ad networks
   */
  getAdNetworks() {
    return this.adNetworks;
  },
  /**
   * Fetches list of ad networks
   * @date   2016-04-01
   * @param  {Number}   accountId Account Id
   */
  listAdNetworks(queryData) {
    const onSuccess = (response) => {
      this.adNetworks = response.data.slice();
      this.emitChange({
        action: AppConstants.LIST_ADNETWORKS
      });
    };

    const onError = (message) => {
      this.emitError({
        action: AppConstants.LIST_ADNETWORKS,
        message
      });
    };

    const orderSettings = {
      status: 'active',
      order_fields: ['updated_at', 'created_at'],
      order_direction: 'desc',
    };

    const data = Object.assign({}, queryData, orderSettings);

    this.post({
      url: AdNetwork.LIST,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Adds an ad network
   * @param {Object} data data object
   */
  addAdNetwork(data) {
    const onSuccess = (response) => {
      this.listAdNetworks(data);
      this.emitChange({
        action: AppConstants.ADD_ADNETWORK,
        data: response
      });
    };
    const onError = (message) => {
      this.emitError({
        action: AppConstants.ADD_ADNETWORK,
        message
      });
    };
    this.post({
      url: AdNetwork.CREATE,
      data,
      onSuccess,
      onError,
    });
  },

  /**
   * Adds an ad network
   * @param {Object} data data object
   */
  addAdNetworkSiteOffer(data) {
    const onSuccess = (response) => {
      this.emitChange({
        action: AppConstants.ADD_SITE_OFFER, // We are following up with a new site offer
        data: response
      });
      ToastrActions.success({ title: response.data.label, body: 'A new Ad Network has been created' });
    };
    const onError = (message) => {
      this.emitError({
        action: AppConstants.ADD_ADNETWORK_SITE_OFFER, // Check when looking at success handling to make sure it's consistent
        message
      });
    };

    this.post({
      url: AdNetwork.CREATE,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Updates an ad network
   * @param {Object} data data object
   */
  updateAdNetwork(data) {
    const onSuccess = () => {
      this.listAdNetworks({
        account_id: data.account_id
      });
      this.emitChange({
        action: AppConstants.UPDATE_ADNETWORK
      });
    };
    const onError = (message) => {
      this.emitError({
        action: AppConstants.UPDATE_ADNETWORK,
        message
      });
    };
    this.post({
      url: AdNetwork.UPDATE,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Remove an ad network
   * @param {Object} data data object
   */
  removeAdNetwork(data) {
    const onSuccess = () => {
      this.listAdNetworks({
        account_id: data.account_id
      });
      this.emitChange({
        action: AppConstants.REMOVE_ADNETWORK
      });
    };
    const onError = (message) => {
      this.emitError({
        action: AppConstants.REMOVE_ADNETWORK,
        message
      });
    };
    this.post({
      url: AdNetwork.REMOVE,
      data,
      onSuccess,
      onError,
    });
  },
  dispatcherIndex: register((action) => {
    switch (action.actionType) {
      case AppConstants.LIST_ADNETWORKS:
        AdNetworkStore.listAdNetworks({
          account_id: action.accountId
        });
        break;
      case AppConstants.ADD_ADNETWORK:
        AdNetworkStore.addAdNetwork({
          account_id: action.accountId,
          label: action.label,
        });
        break;
      case AppConstants.ADD_ADNETWORK_SITE_OFFER:
        AdNetworkStore.addAdNetworkSiteOffer({
          account_id: action.accountId,
          label: action.label,
        });
        break;
      case AppConstants.UPDATE_ADNETWORK:
        AdNetworkStore.updateAdNetwork({
          account_id: action.accountId,
          id: action.adNetworkId,
          label: action.label,
        });
        break;
      case AppConstants.REMOVE_ADNETWORK:
        AdNetworkStore.removeAdNetwork({
          account_id: action.accountId,
          id: action.adNetworkId,
        });
        break;
        // no default
    }
    return true;
  }),
});

export default AdNetworkStore;
