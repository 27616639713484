import PropTypes from 'prop-types';
import React from 'react';
import Form, {Field} from 'weborama-ui-react/Form';
import Input from 'weborama-ui-react/Input';
import DropDown from 'weborama-ui-react/Dropdown';
import Item from 'weborama-ui-react/Item';
import Menu from 'weborama-ui-react/Menu';
import {Creative, Folder} from 'actions';
import {CreativeStore, FolderStore, ContextStore} from 'stores';
import AppConstants from 'constants/AppConstants';
import Button from 'weborama-ui-react/Button';
/**
 * Class duplicate creative form
 */
class DuplicateCreativeForm extends React.Component {
  /**
   * Constructor
   * @date   2016-12-28
   * @param  {Object}   props properties
   */
  constructor(props) {
    super(props);
    this.state = {
      folders: [],
      loading: true,
      selectedFolderId: undefined,
      creativeLabel: `${props.creative.label} (copy)`,
    };

    this.handleSelectFolder = this.handleSelectFolder.bind(this);
    this.handleCreativeLabel = this.handleCreativeLabel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onCreativeStoreError = this.onCreativeStoreError.bind(this);
    this.onFolderStoreChange = this.onFolderStoreChange.bind(this);

    FolderStore.addChangeListener(this.onFolderStoreChange);
    CreativeStore.addErrorListener(this.onCreativeStoreError);
  }

  /**
   * Fetch a list of folders
   */
  componentDidMount() {
    Folder.browse({accountId: ContextStore.routingParams().accountId});
  }

  /**
   * Remove error listener
   * @date   2016-12-30
   */
  componentWillUnmount() {
    FolderStore.removeChangeListener(this.onFolderStoreChange);
    CreativeStore.removeErrorListener(this.onCreativeStoreError);
  }

  /**
   * Folderstore change callback
   */
  onFolderStoreChange() {
    this.setState({folders: FolderStore.getFolders(), loading: false});
  }

  /**
   * Error listener
   * @date   2016-12-30
   * @param  {object}   data error object
   */
  onCreativeStoreError(data) {
    if (data.action === AppConstants.DUPLICATE_CREATIVE) {
      this.setState({loading: false});
    }
  }

  /**
   * Selects folder
   * @date   2016-12-30
   * @param  {Number}   folderId selected folder id
   */
  handleSelectFolder(folderId) {
    this.setState({selectedFolderId: folderId});
  }

  /**
   * Handles creative label
   * @date   2016-12-30
   * @param  {string}   value label
   */
  handleCreativeLabel(value) {
    this.setState({creativeLabel: value});
  }

  /**
   * Handles submit
   * @date   2016-12-30
   */
  handleSubmit() {
    Creative.duplicate({
      accountId: ContextStore.routingParams().accountId,
      creativeId: this.props.creative.id,
      label: this.state.creativeLabel,
      targetFolderId: this.state.selectedFolderId,
      originalFolderId: ContextStore.routingParams().folderId,
    });
    this.setState({loading: true});
  }

  /**
   * Renders duplicate creative form
   * @date   2016-12-28
   * <div class="ui fluid search selection dropdown">
   * @return {array}   nodes
   */
  render() {
    const folders = this.state.folders.map(folder => (
      <Item key={folder.id} value={folder.id}>{folder.label}</Item>
    ));
    return (
      <Form>
        <Field required label="Select folder">
          <DropDown fluid search selection onChange={this.handleSelectFolder}>
            <Menu>
              {folders}
            </Menu>
          </DropDown>
        </Field>
        <Field required label="Creative label">
          <Input onChange={this.handleCreativeLabel} value={this.state.creativeLabel} />
        </Field>
        <Field>
          <Button
            onClick={this.handleSubmit}
            primary
            loading={this.state.loading}
            submit
            fluid
          >
            {'Submit'}
          </Button>
        </Field>
      </Form>
    );
  }
}

export default DuplicateCreativeForm;

DuplicateCreativeForm.propTypes = {
  creative: PropTypes.object.isRequired,
};
