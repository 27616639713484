import PropTypes from 'prop-types';
import React from 'react';
import Item from 'weborama-ui-react/Item';
import Icon from 'weborama-ui-react/Icon';
/**
 * Pushing live button component
 */
class PushLiveButton extends React.Component {
  /**
   * constructor
   * @date   2017-01-13
   * @param  {object}   props Properties
   */
  constructor(props) {
    super(props);

    this.state = {
      active: 'disabled',
      loading: false,
      disabled: {
        color: 'red',
        toolTip: 'You need at least one assigned insertion',
      },
      warning: {
        color: 'orange',
        toolTip: 'This version has not been pushed live yet',
      },
      success: {
        color: 'green',
        toolTip: 'Creative is live',
      },
    };

    this.handleClick = this.handleClick.bind(this);
    this.setActiveState = this.setActiveState.bind(this);
  }

  componentDidMount() {
    this.setActiveState();
  }

  /**
   * Animate button if state changed to warning
   * @date   2017-01-16
   * @param  {Object}   prevProps previous props
   */
  componentDidUpdate(prevProps) {

    if (prevProps.success !== this.props.success) {
      this.setActiveState();
    }

  }

  /**
   * Function that calculates what state the button is in
   */
  setActiveState() {
    let {active} = this.state;
    if (this.props.success) {
      active = 'success';
    }
    if (this.props.warning) {
      active = 'warning';
    }
    if (this.props.disabled) {
      active = 'disabled';
    }
    this.setState({active, loading: false});
  }

  /**
   * Handles click
   * @date   2017-01-17
   */
  handleClick() {
    if (!this.props.warning || this.state.loading) {
      return;
    }
    this.setState({loading: true});
    this.props.onPushLive();
  }

  /**
   * Renders push live button
   * @date   2017-01-13
   * @return {Array}   nodes
   */
  render() {
    let style = {};
    let icon = ['cloud', 'upload'];
    if (this.state.loading) {
      icon = ['spinner', 'loading'];
    }
    if (this.props.disabled) {
      style = {pointerEvents: 'none'};
    }
    if (this.props.inSelectCreative) {
      style = {padding: 5, paddingLeft: 15};
    }
    if (this.props.disabled && this.props.inSelectCreative) {
      style = {padding: 5, paddingLeft: 15, pointerEvents: 'none'};
    }
    const color = {[this.state[this.state.active].color]: true};
    const plIcon = [];
    icon.forEach((iconElement) => {
      plIcon[iconElement] = true;
    });
    return (
      <span data-inverted="" data-tooltip={this.state[this.state.active].toolTip} data-position={this.props.toolTipPosition ? this.props.toolTipPosition : 'top right'}>
        <Item
          useATag
          active={this.props.warning && !this.props.disabled}
          disabled={this.state.loading || this.props.disabled || !this.props.warning}
          onClick={this.handleClick}
          style={style}
        >
          <Icon {...color} {...plIcon} />
          {this.props.displayText && this.props.inSelectCreative && <p style={{paddingLeft: 8, marginBottom: 5}}>{`${this.props.displayText}`}</p>}
        </Item>
      </span>
    );
  }
}

export default PushLiveButton;

PushLiveButton.propTypes = {
  onPushLive: PropTypes.func,
  disabled: PropTypes.bool,
  warning: PropTypes.bool,
  success: PropTypes.bool,
  toolTipPosition: PropTypes.string,
  inSelectCreative: PropTypes.bool,
  displayText: PropTypes.string
};
