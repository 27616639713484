import {dispatch} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const Project = {
  /**
   * Dispatches an action to list projects
   * @date   2016-08-25
   * @param  {object}   data  data object
   */
  list(data) {
    dispatch({
      actionType: AppConstants.LIST_PROJECTS,
      accountId: data.accountId,
    });
  },
  /**
   * Browse projects
   * @date   2016-10-12
   * @param  {object}   data  data object
   */
  browse(data) {
    dispatch({
      actionType: AppConstants.BROWSE_PROJECTS,
      accountId: data.accountId,
    });
  },
  /**
   * Dispatch create new project event
   * @date   2016-01-20
   * @param  {object}   data  data object
   */
  add(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.ADD_PROJECT,
    }, data);

    dispatch(obj);
  },
  /**
   * dispatches update project events
   * @date   2016-03-04
   * @param  {object}   data  data object
   */
  update(data) {
    dispatch({
      actionType: AppConstants.UPDATE_PROJECT,
      accountId: data.accountId,
      projectId: data.projectId,
      label: data.label,
    });
  },
  /**
   * Dispatches remove project event
   * @date   2016-03-04
  * @param  {object}   data  data object
   */
  remove(data) {
    dispatch({
      actionType: AppConstants.REMOVE_PROJECT,
      accountId: data.accountId,
      projectId: data.projectId,
    });
  },
};

export default Project;
