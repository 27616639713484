import BaseStore from 'stores/BaseStore';
import {Folder, Creative} from 'constants/ServerConstants';
import {register} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';
import 'jquery-file-download';
import Cookie from 'js-cookie';
import ToastrActions from 'actions/Toastr';

const CreativeStore = Object.assign({}, BaseStore, {
  creative: [],
  creatives: [],
  creativeTypes: [],
  creativeFormats: [],
  previewSetupScript: {},
  multiPreviewSetupScripts: [],
  matchingInsertions: [],
  /**
   * Returns one creative
   * @date   2016-02-16
   * @return {Object}   Creative object
   */
  getCreative() {
    return this.creative;
  },
  /**
   * returns creatives
   * @date   2016-01-14
   * @return {array}   creatives
   */
  getCreatives() {
    return this.creatives;
  },
  /**
   * Returns preview setupscript
   * @date   2016-10-19
   * @return {Object}   Preview setupscript
   */
  getPreviewSetupScript() {
    return this.previewSetupScript;
  },
  /**
   * Returns mulitple preview setupscripts
   * @date   2016-10-19
   * @return {Array}   Preview setupscripts
   */
  getMultiPreviewSetupScript() {
    return this.multiPreviewSetupScripts;
  },
  /**
   * Returns matching insertions
   */
  getMatchingInsertions() {
    return this.matchingInsertions;
  },
  /**
   * Fetches creatives by folder Id
   * @date   2016-01-14
   * @param  {number}   accountId id of account
   * @param  {number}   folderId id of folder
   * @param  {boolean}  browse browse
   */
  listCreatives(queryData, browse = false) {
    let action = AppConstants.LIST_CREATIVES;
    if (browse) {
      action = AppConstants.BROWSE_CREATIVES;
    }

    const onSuccess = (response) => {
      this.creatives = response.data.slice();
      this.emitChange({action});
    };

    const onError = (message) => {
      this.emitError({action, message});
    };

    const orderSettings = {
      conditions: {status: 'active'},
      order_fields: ['updated_at', 'created_at'],
      order_direction: 'desc',
    };

    const data = Object.assign({}, queryData, orderSettings);

    this.post({
      url: Folder.GET_CREATIVE_LIST,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Fetches creatives by folder Id
   * @date   2016-01-14
   * @param  {number}   accountId id of account
   * @param  {number}   creativeId id of creative
   */
  fetchCreative(data) {
    const onSuccess = (response) => {
      this.creative = response.data;
      this.emitChange({action: AppConstants.GET_CREATIVE});
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.GET_CREATIVE, message});
    };

    this.post({
      url: Creative.GET,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Fetches preview setupscript
   * @date   2016-10-19
   * @param  {Number}   accountId  account id
   * @param  {Number}   creativeId creative id
   */
  fetchPreviewSetupScript(data) {
    const onSuccess = (response) => {
      this.previewSetupScript = response.data;
      this.emitChange({action: AppConstants.GET_PREVIEW_SETUP_SCRIPT});
    };
    const onError = (message) => {
      this.emitError({action: AppConstants.GET_PREVIEW_SETUP_SCRIPT, message});
    };

    this.post({
      url: Creative.GET_PREVIEW_SETUP_SCRIPT,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Fetches multiple preview setupscript for multiple ad positions
   * @param {Number} accountId Account Id
   * @param {Number} creativeId Creative Id
   */
  fetchMultiPreviewSetupScript(queryData) {
    const onSuccess = (response) => {
      this.multiPreviewSetupScripts = response.data.slice();
      this.emitChange({action: AppConstants.GET_MULTI_PREVIEW_SETUP_SCRIPT});
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.GET_MULTI_PREVIEW_SETUP_SCRIPT, message});
    };

    const data = Object.assign({}, queryData, {enable_debug: 1});

    this.post({
      url: Creative.GET_MULTI_PREVIEW_SETUP_SCRIPT,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Add a new creative_format_id
   * @date   2016-02-03
   * @param  {Number}   accountId         Account Id
   * @param  {Number}   creativeFormatId Creative Format Id
   * @param  {Number}   folderId          Folder Id
   * @param  {String}   label              label
   * @param  {Array}    creativeLabels     creative labels
   */
  addCreative(data) {
    const onSuccess = (response) => {

      this.emitChange({action: AppConstants.ADD_CREATIVE, data: response, folderId: data.folder_id});
      CreativeStore.listCreatives({account_id: data.account_id, id: data.folder_id});
      ToastrActions.success({ title: data.label, body: 'A new creative has been created' });
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.ADD_CREATIVE, message});
    };

    this.post({
      url: Creative.CREATE,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Updated a creative_id
   * @date   2016-02-11
   * @param  {Number}   accountId         Account ID
   * @param  {Number}   creativeId creativeid
   * @param  {String}   label          Label
   * @param  {Number}   creativeFormatId creative format id
   * @param  {Array}    creativeLabels creative labels
   */
  updateCreative(data) {
    const onSuccess = (response) => {
      this.emitChange({action: AppConstants.UPDATE_CREATIVE});
      this.listCreatives({account_id: data.account_id, id: response.data.folder_id});
      ToastrActions.success({ title: data.label, body: 'Creative has been updated' });
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.UPDATE_CREATIVE, message});
    };

    this.post({
      url: Creative.UPDATE,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Duplicates a creative_id
   * @date   2016-02-09
   * @param  {Number}   accountId  Account Id
   * @param  {Number}   creativeId Creative Id
   * @param  {Number}   targetFolderId   Id of folder to duplicate in
   * @param  {String}   label       New label
   * @param  {Number}   originalFolderId       original folder id
   */
  duplicateCreative(data) {
    const onSuccess = (response) => {
      if (data.folder_id === data.original_folder_id) {
        CreativeStore.listCreatives({account_id: data.account_id, id: data.original_folder_id});
      }
      ToastrActions.success({ title: data.label, body: 'Creative has been duplicated' });
      this.emitChange({action: AppConstants.DUPLICATE_CREATIVE, data: response});
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.DUPLICATE_CREATIVE, message});
    };

    this.post({
      url: Creative.DUPLICATE_CREATIVE,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Removes a creative_id
   * @date   2016-02-12
   * @param  {Number}   accountId  Account Id
   * @param  {Number}   creativeId Creative ID
   */
  removeCreative(data) {
    const onSuccess = (response) => {
      this.emitChange({action: AppConstants.REMOVE_CREATIVE, data: response.data});
      CreativeStore.listCreatives({account_id: data.account_id, id: response.data.folder_id});
      ToastrActions.success({ title: data.label, body: 'Creative has been removed' });
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.REMOVE_CREATIVE, message});
    };

    this.post({
      url: Creative.DELETE,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Push live a creative
   * @date   2016-09-15
   * @param  {Number}   accountId  Account Id
   * @param  {Number}   creativeId Creative Id
   * @param  {Number}   folderId folder Id
   */
  pushLive(data) {
    const onSuccess = () => {
      this.emitChange({action: AppConstants.PUSH_LIVE});
      CreativeStore.listCreatives({account_id: data.accountId, id: data.folderId});
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.PUSH_LIVE, message});
    };

    this.post({
      url: Creative.PUSH_LIVE,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Push live a folder of creatives
   * @date   2021-09-10
   * @param  {Number}   accountId  Account Id
   * @param  {Number}   id Folder Id
   */
  pushCreativesLive(data) {
    const onSuccess = () => {
      this.emitChange({action: AppConstants.PUSH_CREATIVES_LIVE});
      ToastrActions.success({ title: 'Push successfull!', body: 'Creatives in folder pushed live' });
      CreativeStore.listCreatives({account_id: data.account_id, id: data.id});
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.PUSH_CREATIVES_LIVE, message});
    };

    this.post({
      url: Folder.SET_CREATIVES_LIVE,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Updates vast settings
   * @date   2016-12-20
   * @param  {Number}   accountId    Account Id
   * @param  {Number}   creativeId   Creative Id
   * @param  {object}   settings     Settings object
   * //TODO: implement
   * '{"skip_event":"YES","video_progress_30s":"NO","video_progress_event":"NO",
   * "vast_version":"3.0","companion_ads":"none","skip_offset":"NO"}'
   */
  updateVastSettings(accountId, creativeId, settings) {
    const onSuccess = (response) => {
      this.emitChange({action: AppConstants.UPDATE_VAST_SETTINGS, data: response});
    };
    const onError = (message) => {
      this.emitError({action: AppConstants.UPDATE_VAST_SETTINGS, message});
    };

    this.post({
      url: Creative.UPDATE,
      data: {
        account_id: accountId,
        id: creativeId,
        settings: `'${JSON.stringify(settings)}'`,
      },
      onSuccess,
      onError,
    });
  },
  /**
   * Downloads a creative
   * @param {Object} data data object
   */
  downloadCreative(queryParams) {
    const JWToken = Cookie.get('warm_auth');
    const auth = {'X-Authorization': `Bearer ${JWToken}`};
    const data = Object.assign('', queryParams, auth);

    Cookie.set('fileDownload', true, {secure: false});
    ToastrActions.info({ title: queryParams.label, body: 'Creative is being prepared for download' });

    $.fileDownload(Creative.DOWNLOAD, {
      successCallback: () => {
        // console.log('SUCCES DOWNLOAD CREATIVE');
        // setTimeout(() => { this.emitChange({action: TagConstants.GET_ZIP_FILE_FROM_TOKEN}) }, 1000);
      },
      failCallback: () => {
        // console.log('ERROR DOWNLOAD CREATIVE');
        // emit error here!
      },
      httpMethod: 'POST',
      data,
    });
  },
  /**
   * Search matching insertions
   * @param {Object} params params
   */
  searchMatchingInsertions(params) {
    const onSuccess = (data) => {
      this.matchingInsertions = data.data.slice();
      this.emitChange({action: AppConstants.SEARCH_MATCHING_INSERTIONS});
    };
    const onError = (err) => {
      this.emitError({action: AppConstants.SEARCH_MATCHING_INSERTIONS, message: err});
    };
    this.post({
      url: Creative.SEARCH_MATCHING_INSERTIONS,
      data: params,
      onSuccess,
      onError,
    });
  },
  dispatcherIndex: register((action) => {
    // Action will be handled here e.g. remove, edit etc.
    switch (action.actionType) {
      case AppConstants.BROWSE_CREATIVES:
        CreativeStore.listCreatives({
          account_id: action.accountId,
          id: action.folderId,
        }, true);
        break;
      case AppConstants.LIST_CREATIVES:
        CreativeStore.listCreatives({
          account_id: action.accountId,
          id: action.folderId,
        }, false);
        break;
      case AppConstants.GET_CREATIVE:
        CreativeStore.fetchCreative({
          account_id: action.accountId,
          folder_id: action.folderId,
          id: action.creativeId,
        });
        break;
      case AppConstants.ADD_CREATIVE:
        CreativeStore.addCreative({
          account_id: action.accountId,
          creative_format_id: action.creativeFormatId,
          folder_id: action.folderId,
          label: action.label,
          creative_label__labels: action.creativeLabels,
        });
        break;
      case AppConstants.UPDATE_CREATIVE:
        CreativeStore.updateCreative({
          account_id: action.accountId,
          folder_id: action.folderId,
          id: action.creativeId,
          label: action.label,
          creative_format_id: action.creativeFormatId,
          creative_label__labels: action.creativeLabels,
          settings: JSON.stringify(action.settings),
        });
        break;
      case AppConstants.DUPLICATE_CREATIVE:
        CreativeStore.duplicateCreative({
          account_id: parseInt(action.accountId, 10),
          id: parseInt(action.creativeId, 10),
          folder_id: parseInt(action.targetFolderId, 10),
          original_folder_id: parseInt(action.originalFolderId, 10),
          label: action.label,
        });
        break;
      case AppConstants.REMOVE_CREATIVE:
        CreativeStore.removeCreative({
          account_id: action.accountId,
          folder_id: action.folderId,
          id: action.creativeId,
          label: action.label,
        });
        break;
      case AppConstants.PUSH_LIVE:
        CreativeStore.pushLive({
          account_id: action.accountId,
          folder_id: action.folderId,
          id: action.creativeId,
        });
        break;
      case AppConstants.PUSH_CREATIVES_LIVE:
        CreativeStore.pushCreativesLive({
          account_id: action.account_id,
          id: action.id,
        });
        break;
      case AppConstants.GET_PREVIEW_SETUP_SCRIPT:
        CreativeStore.fetchPreviewSetupScript({
          account_id: action.accountId,
          folder_id: action.folderId,
          id: action.creativeId,
        });
        break;
      case AppConstants.GET_MULTI_PREVIEW_SETUP_SCRIPT:
        CreativeStore.fetchMultiPreviewSetupScript({
          account_id: action.accountId,
          folder_id: action.folderId,
          id: action.creativeId,
        });
        break;
      case AppConstants.UPDATE_VAST_SETTINGS:
        CreativeStore.updateVastSettings(
          action.accountId,
          action.creativeId,
          action.settings,
        );
        break;
      case AppConstants.DOWNLOAD_CREATIVE:
        CreativeStore.downloadCreative({
          account_id: action.accountId,
          folder_id: action.folderId,
          id: action.creativeId,
          label: action.label,
          source: action.source,
        });
        break;
      case AppConstants.SEARCH_MATCHING_INSERTIONS:
        // creatives/searchMatchingInsertions [id, campaign_id, project_id, folder_id]
        CreativeStore.searchMatchingInsertions({
          account_id: action.accountId,
          id: action.creativeId,
          folder_id: action.folderId,
          project_id: action.projectId,
          campaign_id: action.campaignId,
        });
      // no default
    }
    return true; // No errors. Needed by promise in Dispatcher.
  }),
});

export default CreativeStore;
