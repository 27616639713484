/* eslint-disable no-unneeded-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { AdSpaceStore, AdServerStore, AdNetworkStore, ContextStore } from 'stores';
import AppConstants from 'constants/AppConstants';
import Accordion, { Title, Content } from 'weborama-ui-react/Accordion';
import Form, { Field } from 'weborama-ui-react/Form';
import Message from 'weborama-ui-react/Message';
import Button from 'weborama-ui-react/Button';
import { isEmptyObject, getObjectInArray } from 'constants/Utils';
import { AdSpace, AdServer, AdNetwork } from 'actions';
import Checkbox from 'weborama-ui-react/Checkbox';
import Dropdown from 'weborama-ui-react/Dropdown';
import Item from 'weborama-ui-react/Item';
import Menu from 'weborama-ui-react/Menu';
import CountryDropdown from '../CountryDropdown';
/**
 * Class AdSpaceForm houses the form for new site/offers
 * In a perhaps not-so-smooth way, I preserved its previous functionality and extended it
 * for use in other context as well. The best way to handle this situation here would be to have a base class
 * and extend it, but our hands are a bit tied here. HOC maybe?
 */
class AdSpaceForm extends React.Component {
  /**
   * Constructor
   */
  constructor(props) {
    super();
    const adSpace = props.adSpace || {};
    this.state = {
      selectedAdNetwork: {},
      adNetworks: [],
      selectedAdServer: {},
      countryId: adSpace.country_id || 0,
      label: adSpace.label || '',
      adServers: [],
      siteOffer: adSpace.ad_space_type || 'site',
      burstType: adSpace.bursttype || 'auto',
      burstPath: adSpace.burstpath || '',
      externalId: adSpace.external_id || '',
      externalName: adSpace.external_name || '',
      ZIndex: adSpace.zindex || '',
      error: {},
      loading: false,
      withNewAdNetwork: false,
      newAdNetworkValue: ''
    };

    this.onAdSpaceStoreChange = this.onAdSpaceStoreChange.bind(this);
    this.onAdSpaceStoreError = this.onAdSpaceStoreError.bind(this);
    this.onAdServerStoreChange = this.onAdServerStoreChange.bind(this);
    this.handleSiteOfferChange = this.handleSiteOfferChange.bind(this);
    this.handleLabelChange = this.handleLabelChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleBurstTypeChange = this.handleBurstTypeChange.bind(this);
    this.handleZIndexChange = this.handleZIndexChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleExternalIdChange = this.handleExternalIdChange.bind(this);
    this.handleExternalNameChange = this.handleExternalNameChange.bind(this);
    this.handleBurstPathChange = this.handleBurstPathChange.bind(this);
    this.handleAdServerChange = this.handleAdServerChange.bind(this);
    this.handleNewAdnetworkValueChange = this.handleNewAdnetworkValueChange.bind(this);
    this.onAdNetworkStoreChange = this.onAdNetworkStoreChange.bind(this);
    this.evaluateIncomingAdNetwork = this.evaluateIncomingAdNetwork.bind(this);
  }

  /**
   * Fetch list of counties and adservers
   */
  componentDidMount() {
    AdSpaceStore.addChangeListener(this.onAdSpaceStoreChange);
    AdSpaceStore.addErrorListener(this.onAdSpaceStoreError);
    AdServerStore.addChangeListener(this.onAdServerStoreChange);
    AdNetworkStore.addChangeListener(this.onAdNetworkStoreChange);
    AdServer.list();

    if (this.props.adNetworks) {
      this.setState({ adNetworks: this.props.adNetworks });
    }
    this.evaluateIncomingAdNetwork();
  }

  componentDidUpdate() {
    this.evaluateIncomingAdNetwork();
  }

  /**
   * Removes store callback
   */
  componentWillUnmount() {
    AdSpaceStore.removeChangeListener(this.onAdSpaceStoreChange);
    AdSpaceStore.removeErrorListener(this.onAdSpaceStoreError);
    AdServerStore.removeChangeListener(this.onAdServerStoreChange);
    AdNetworkStore.removeChangeListener(this.onAdNetworkStoreChange);
  }

  evaluateIncomingAdNetwork() {
    if (this.props.adNetwork && (JSON.stringify(this.props.adNetwork) !== JSON.stringify(this.state.selectedAdNetwork))) {
      this.setState({ selectedAdNetwork: this.props.adNetwork });
    }
  }

  /**
   * AdSpace store change callback
   * @param {Object} data event data object
   */
  onAdSpaceStoreChange(data) {
    if (data.action === (AppConstants.ADD_ADSPACE === AppConstants.ADD_SITE_OFFER)) {
      this.setState({ loading: false });
    }
  }

  /**
   * Ad Space store error
   * @param {Object} error error event object
   */
  onAdSpaceStoreError(error) {
    this.setState({ error, loading: false });
  }

  /**
   * AdServer store callback
   * @param {Object} data data object
   */
  onAdServerStoreChange() {
    let selectedAdServer = {};
    if (!isEmptyObject(this.props.adSpace)) {
      selectedAdServer = getObjectInArray(AdServerStore.getAdServers(), 'id', this.props.adSpace.ad_server_id);
    }
    this.setState({ adServers: AdServerStore.getAdServers(), selectedAdServer });
  }

  /**
   * AdNetwork store change callback
   * @param {Object} storeData event data object
   */
  onAdNetworkStoreChange(storeData) {
    if (storeData && storeData.action === AppConstants.ADD_SITE_OFFER) {
      // Follow up to new add network request by creating a site/offer request
      AdSpace.addSiteOffer({
        accountId: ContextStore.routingParams().accountId,
        label: this.state.label,
        countryId: this.state.countryId,
        adNetworkId: storeData.data.data.id, // This is coming from server response
        siteOffer: this.state.siteOffer,
        adServerId: this.state.selectedAdServer.id ? this.state.selectedAdServer.id : '',
        burstType: this.state.burstType,
        burstPath: this.state.burstPath,
        externalId: this.state.externalId,
        externalName: this.state.externalName,
        ZIndex: this.state.ZIndex,
      });
    }
  }

  /**
   * Label input change
   * @param {Object} e event object
   */
  handleLabelChange(e) {
    this.setState({ label: e.target.value });
  }

  /**
   * Site or offer
   * @param {String} value value
   */
  handleSiteOfferChange(value) {
    this.setState({ siteOffer: value });
  }

  /**
   * Country change
   * @param {number} value country id
   */
  handleCountryChange(value) {
    this.setState({ countryId: value });
  }

  /**
   * handles selecting ad server
   * @param {string} value adserver id
   */
  handleAdServerChange(value) {
    const selectedAdServer = getObjectInArray(this.state.adServers, 'id', value);
    this.setState({ selectedAdServer });
  }

  /**
   * Handles external Id change
   * @param {Object} e event object
   */
  handleExternalIdChange(e) {
    this.setState({ externalId: e.target.value });
  }

  /**
   * Handles external name change
   * @param {Object} e event object
   */
  handleExternalNameChange(e) {
    this.setState({ externalName: e.target.value });
  }

  /**
   * Handles burst type change
   * @param {String} value burst type
   */
  handleBurstTypeChange(value) {
    this.setState({ burstType: value });
  }

  /**
   * Handles burst path change
   * @param {Object} e event object
   */
  handleBurstPathChange(e) {
    this.setState({ burstPath: e.target.value });
  }

  /**
   * Handles z index change
   * @param {Object} ZIndex event object
   */
  handleZIndexChange(e) {
    this.setState({ ZIndex: e.target.value });
  }

  /**
 * Handles new ad network name change
 * @param {Object} event event object
 */
  handleNewAdnetworkValueChange(event) {
    this.setState({ newAdNetworkValue: event.target.value });
  }

  /**
   * Handles form submit
   */
  handleFormSubmit() {

    if (this.state.withNewAdNetwork) {

      AdNetwork.addAdNetWorkAndSiteOffer({
        accountId: ContextStore.routingParams().accountId,
        label: this.state.newAdNetworkValue,
      });
    } else if (!isEmptyObject(this.props.adSpace)) {

      // We have adSpace, so we just need to update it.
      // If it's an adspace, it should have an adNetwork next to it as a prop as well
      AdSpace.update({
        accountId: ContextStore.routingParams().accountId,
        adSpaceId: this.props.adSpace.id,
        label: this.state.label,
        countryId: this.state.countryId,
        adNetworkId: this.props.adNetwork.id,
        siteOffer: this.state.siteOffer,
        adServerId: this.state.selectedAdServer.id ? this.state.selectedAdServer.id : '',
        burstType: this.state.burstType,
        burstPath: this.state.burstPath,
        externalId: this.state.externalId,
        externalName: this.state.externalName,
        ZIndex: this.state.ZIndex,
      });
      this.setState({ loading: true });
    } else if (this.props.adNetwork) {
      // This the situation is with two modals and we're inside the second modal, so use the add function here
      AdSpace.add({
        accountId: ContextStore.routingParams().accountId,
        label: this.state.label,
        countryId: this.state.countryId,
        adNetworkId: this.props.adNetwork.id,
        siteOffer: this.state.siteOffer,
        adServerId: this.state.selectedAdServer.id ? this.state.selectedAdServer.id : '',
        burstType: this.state.burstType,
        burstPath: this.state.burstPath,
        externalId: this.state.externalId,
        externalName: this.state.externalName,
        ZIndex: this.state.ZIndex,
      });
      this.setState({ loading: true });
    } else {
      // We only have one modal open and we need to handle that, not the above situation
      // Note that we're using selectedAdNetwork here
      AdSpace.addSiteOffer({
        accountId: ContextStore.routingParams().accountId,
        label: this.state.label,
        countryId: this.state.countryId,
        adNetworkId: this.state.selectedAdNetwork.id,
        siteOffer: this.state.siteOffer,
        adServerId: this.state.selectedAdServer.id ? this.state.selectedAdServer.id : '',
        burstType: this.state.burstType,
        burstPath: this.state.burstPath,
        externalId: this.state.externalId,
        externalName: this.state.externalName,
        ZIndex: this.state.ZIndex,
      });
    }
  }

  /**
   * Renders Ad Space form
   */
  render() {
    const adServerItems = this.state.adServers.map(adServer => (
      <Item key={adServer.id} value={adServer.id}>
        {adServer.label}
      </Item>
    ));

    let visibleAdNetworkItems;

    if (this.props.adNetworks) {
      // There are multiple ad networks, so show the dropdown
      visibleAdNetworkItems = this.state.adNetworks.map(singleAdNetwork => (
        <Item
          onClick={() => { this.setState({ selectedAdNetwork: singleAdNetwork }); }}
          key={singleAdNetwork.id}
          value={singleAdNetwork.id}
          style={{ fontWeight: '700' }}
        >
          {singleAdNetwork.label}
        </Item>
      ));

      visibleAdNetworkItems.unshift(
        <Item
          onClick={() => { this.setState({ withNewAdNetwork: true }); }}
          key={Date.now()}
          value="Create a new ad network"
          style={{ fontStyle: 'italic', fontWeight: '300' }}
        >
          Create a new ad network
        </Item>
      );
    }

    if (this.props.adNetwork) {
      // There is only one adNetWork, like the original situation
      visibleAdNetworkItems = (
        <Item key={this.props.adNetwork.id} value={this.props.adNetwork.id}>
          {this.props.adNetwork.label}
        </Item>
      );
    }

    let visibleAdNetworkElements;

    if (this.state.withNewAdNetwork) {
      visibleAdNetworkElements = (
        <input
          type="text"
          placeholder="New ad network name"
          name="newAdNetwork"
          value={this.state.newAdNetworkValue}
          onChange={this.handleNewAdnetworkValueChange}
        />
      );
    } else {
      visibleAdNetworkElements = (
        <Dropdown
          fluid
          selection
          disabled={this.props.adNetwork ? true : false}
          defaultValue={this.props.adNetwork && this.props.adNetwork.id ? this.props.adNetwork.id : null}
        >
          <Menu>
            {visibleAdNetworkItems}
          </Menu>
        </Dropdown>
      );
    }
    return (
      <span>
        {
          !isEmptyObject(this.state.error)
          && <Message closeable error>{this.state.error.message}</Message>
        }
        <Form>
          <Field inline>
            <Field label="Site" labelAfterComponent>
              <Checkbox
                value="site"
                name="siteoffer"
                radio
                checked={this.state.siteOffer.toLowerCase() === 'site'}
                onChange={this.handleSiteOfferChange}
              />
            </Field>
            <Field label="Offer" labelAfterComponent>
              <Checkbox
                value="offer"
                name="siteoffer"
                radio
                checked={this.state.siteOffer.toLowerCase() === 'offer'}
                onChange={this.handleSiteOfferChange}
              />
            </Field>
          </Field>
          <Field required label="Name">
            <input
              type="text"
              placeholder="Name"
              name="label"
              value={this.state.label}
              onChange={this.handleLabelChange}
            />
          </Field>
          <Field required label="Country">
            <CountryDropdown
              eight
              fluid
              search
              selection
              onSelect={this.handleCountryChange}
              selectedId={this.state.countryId.toString()}
            />
          </Field>
          <Field required label={this.state.withNewAdNetwork ? 'New AdNetwork name' : 'AdNetwork'}>
            {visibleAdNetworkElements}
          </Field>
          <Field>
            <Accordion>
              <Title>
                {'Optional settings'}
              </Title>
              <Content>
                <Field label="Ad server">
                  <Dropdown
                    fluid
                    search
                    selection
                    defaultValue={this.state.selectedAdServer.id}
                    onChange={this.handleAdServerChange}
                  >
                    <Menu>
                      {adServerItems}
                    </Menu>
                  </Dropdown>
                </Field>
                <Field label="External Id">
                  <input
                    type="text"
                    placeholder="External Id"
                    name="externalId"
                    value={this.state.externalId}
                    onChange={this.handleExternalIdChange}
                  />
                </Field>
                <Field label="External name">
                  <input
                    type="text"
                    placeholder="External name"
                    name="externalName"
                    value={this.state.externalName}
                    onChange={this.handleExternalNameChange}
                  />
                </Field>
                <Field label="Burst path">
                  <input
                    type="text"
                    placeholder="Burst Path"
                    name="burstPath"
                    value={this.state.burstPath}
                    onChange={this.handleBurstPathChange}
                  />
                </Field>
                <Field label="Burst type">
                  <Field inline>
                    <Field label="Auto" labelAfterComponent>
                      <Checkbox
                        value="auto"
                        name="burstType"
                        radio
                        disabled
                        checked={this.state.burstType === 'auto'}
                        onChange={this.handleBurstTypeChange}
                      />
                    </Field>
                    <Field label="Always" labelAfterComponent>
                      <Checkbox
                        value="always"
                        name="burstType"
                        radio
                        disabled
                        checked={this.state.burstType === 'always'}
                        onChange={this.handleBurstTypeChange}
                      />
                    </Field>
                    <Field label="Never" labelAfterComponent>
                      <Checkbox
                        value="never"
                        name="burstType"
                        disabled // Bug in WCM
                        radio
                        checked={this.state.burstType === 'never'}
                        onChange={this.handleBurstTypeChange}
                      />
                    </Field>
                  </Field>
                </Field>
                <Field label="Z-index">
                  <input
                    type="text"
                    placeholder="Z-index"
                    name="ZIndex"
                    value={this.state.ZIndex}
                    onChange={this.handleZIndexChange}
                  />
                </Field>
              </Content>
            </Accordion>
          </Field>
          <Button
            loading={this.state.loading}
            disabled={this.state.label.length < 1}
            primary
            fluid
            onClick={this.handleFormSubmit}
          >
            {'Submit'}
          </Button>
        </Form>
      </span>
    );
  }
}

export default AdSpaceForm;

AdSpaceForm.propTypes = {
  adNetwork: PropTypes.object,
  adNetworks: PropTypes.array,
  adSpace: PropTypes.object,
};
