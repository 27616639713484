import {dispatch} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';
// TODO: change to list, add, remove
const Favorite = {
  /**
   * Dispatches an action to list favorites
   */
  listFavorites() {
    dispatch({
      actionType: AppConstants.LIST_FAVORITES,
    });
  },
  /**
   * Adds favorite
   * @date  2017-01-20
   * @param {Object}   data data object (accountId, projectId, campaignId, folderId, creativeId, url(pathname))
   */
  addFavorite(data) {
    dispatch({
      actionType: AppConstants.ADD_FAVORITE,
      data,
    });
  },
  /**
   * Dispatches an action to remove a favorite item
   * @param {Number} favoriteId favoriteId
   */
  removeFavorite(favoriteId) {
    dispatch({
      actionType: AppConstants.REMOVE_FAVORITE,
      favoriteId,
    });
  },
};

export default Favorite;
