import BaseStore from 'stores/BaseStore';
import {ThirdPartyTag} from 'constants/ServerConstants';
import AppConstants from 'constants/AppConstants';
import {register} from 'dispatchers/AppDispatcher';
/**
 * Tag Store
 */
const ThirdPartyTagStore = Object.assign({}, BaseStore, {
  tags: [],

  getTags() {
    return this.tags;
  },

  list(data) {
    const onSuccess = () => {
      this.emitChange({action: AppConstants.LIST_THIRD_PARTY_TAGS});
    };
    const onError = ((error) => {
      this.emitError({action: AppConstants.LIST_THIRD_PARTY_TAGS, message: error});
    });
    this.post({
      url: ThirdPartyTag.LIST,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * add a third party tag
   * @param {Object} data data object
   */
  add(data) {
    const onSuccess = () => {
      this.emitChange({action: AppConstants.ADD_THIRD_PARTY_TAG});
    };
    const onError = ((error) => {
      this.emitError({action: AppConstants.ADD_THIRD_PARTY_TAG, message: error});
    });
    this.post({
      url: ThirdPartyTag.CREATE,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * updated a third party tag
   * @param {Object} data data object
   */
  update(data) {
    const onSuccess = () => {
      this.emitChange({action: AppConstants.UPDATE_THIRD_PARTY_TAG});
    };
    const onError = () => {
      this.emitChange({action: AppConstants.UPDATE_THIRD_PARTY_TAG});
    };
    this.post({
      url: ThirdPartyTag.UPDATE,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Removed a third party tag
   * @param {Object} data data object
   */
  remove(data) {
    const onSuccess = () => {
      this.emitChange({action: AppConstants.REMOVE_THIRD_PARTY_TAG});
    };
    const onError = ((error) => {
      this.emitError({action: AppConstants.REMOVE_THIRD_PARTY_TAG, message: error});
    });
    this.post({
      url: ThirdPartyTag.DELETE,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Searches third party tags
   * @param {Object} queryParams data object
   */
  search(queryParams) {
    const onSuccess = (response) => {
      this.tags = response.data.slice();
      this.emitChange({action: AppConstants.SEARCH_THIRD_PARTY_TAG});
    };
    const onError = (message) => {
      this.emitError({action: AppConstants.SEARCH_THIRD_PARTY_TAG, error: message});
    };
    const data = {
      account_id: queryParams.accountId,
      conditions: {
        status: {'-!=': 'deleted'},
        '-OR': {
          campaign_id: {'-=': queryParams.campaignId},
          insertion_id: {'-IN': queryParams.insertionIds},
          campaigns_ad_space_id: {'-IN': queryParams.campaignAdSpaceIds},
          assigned_ad_position_id: {'-IN': queryParams.assignedAdPositionIds},
        },
      },
    };
    this.post({
      url: ThirdPartyTag.SEARCH,
      data,
      onSuccess,
      onError,
    });
  },
  dispatcherIndex: register((action) => {
    switch (action.actionType) {
      case AppConstants.LIST_THIRD_PARTY_TAGS:
        ThirdPartyTagStore.list({
          account_id: action.accountId,
          project_id: action.projectId,
          campaign_id: action.campaignId,
        });
        break;
      case AppConstants.ADD_THIRD_PARTY_TAG:
        ThirdPartyTagStore.add({
          account_id: action.accountId,
          project_id: action.projectId,
          campaign_id: action.campaignId,
          campaigns_ad_space_id: action.campaignAdSpaceId,
          insertion_id: action.insertionId,
          creative_id: action.creativeId,
          assigned_ad_position_id: action.assignedAdPositionId,
          code: action.url,
          label: action.label,
          rule: action.type,
          is_active: action.isActive,
        });
        break;
      case AppConstants.UPDATE_THIRD_PARTY_TAG:
        ThirdPartyTagStore.update({
          account_id: action.accountId,
          id: action.thirdPartyTagId,
          code: action.url,
          label: action.label,
          rule: action.type,
          is_active: action.isActive,
        });
        break;
      case AppConstants.REMOVE_THIRD_PARTY_TAG:
        ThirdPartyTagStore.remove({
          account_id: action.accountId,
          id: action.thirdPartyTagId,
        });
        break;
      case AppConstants.SEARCH_THIRD_PARTY_TAG:
        // TODO: this needs to be updated
        ThirdPartyTagStore.search({
          accountId: action.accountId,
          campaignId: action.campaignId,
          campaignAdSpaceIds: action.campaignAdSpaceIds || [],
          insertionIds: action.insertionIds || [],
          assignedAdPositionIds: action.assignedAdPositionIds || [],
        });
        break;
      // no default
    }
    return true;
  }),
});

export default ThirdPartyTagStore;
