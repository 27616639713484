import PropTypes from 'prop-types';
import React from 'react';
import { JsTag, VastTag, ImageTag } from 'components/Tag';

function TagSegment(props) {

  if (Object.prototype.hasOwnProperty.call(props.tag, 'vast')) {
    return (
      <VastTag
        vastTag={props.tag['vast-ssl']}
      />
    );
  }

  if (Object.prototype.hasOwnProperty.call(props.tag, 'js')) {
    return (
      <JsTag
        jsTag={props.tag['js-ssl']}
        iframeUrl={props.tag['iframe-ssl']}
      />
    );
  }

  if (Object.prototype.hasOwnProperty.call(props.tag, 'image')) {
    return (
      <ImageTag imageTag={props.tag['image-ssl']} />
    );
  }

  return null;

}

export default TagSegment;

TagSegment.propTypes = {
  tag: PropTypes.object,
};
