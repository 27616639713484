import React from 'react';
import Menu from 'weborama-ui-react/Menu';
import Item from 'weborama-ui-react/Item';
import Icon from 'weborama-ui-react/Icon';
import AssignCampaignForm from 'components/AssignCampaignForm';
import { AssignStore, ContextStore, CountryStore} from 'stores';
import {AssignActions, Creative} from 'actions';
import Label from 'weborama-ui-react/Label';
import Dimmer from 'weborama-ui-react/Dimmer';
import Button from 'weborama-ui-react/Button';
import Loader from 'weborama-ui-react/Loader';
import Segment from 'weborama-ui-react/Segment';
import Message from 'weborama-ui-react/Message';
import AppConstants from 'constants/AppConstants';
import {isEmptyObject, getObjectInArray} from 'constants/Utils';
import LinkedAdSpaceTable from 'components/LinkedAdSpaceTable';
/**
 * Class LinkedInsertions
 */
class LinkedInsertions extends React.Component {
  constructor() {
    super();
    this.state = {
      assignedInsertions: {},
      loadingInsertion: {},
      active: 'insertionList',
      loading: true,
      error: {},
    };
    this.onAssignStoreChange = this.onAssignStoreChange.bind(this);
    this.handleUnassign = this.handleUnassign.bind(this);

    AssignStore.addErrorListener(this.onAssignStoreError);
    AssignStore.addChangeListener(this.onAssignStoreChange);
  }

  /**
   * Fetch list of assigned insertions
   */
  componentDidMount() {
    AssignActions.listAssignedInsertions({
      accountId: ContextStore.routingParams().accountId,
      folderId: ContextStore.routingParams().folderId,
      creativeId: ContextStore.routingParams().creativeId,
      detailedResponse: true,
    });
  }

  /**
   * Remove change listeners
   */
  componentWillUnmount() {
    AssignStore.removeErrorListener(this.onAssignStoreError);
    AssignStore.removeChangeListener(this.onAssignStoreChange);
  }

  /**
   * on Error handler
   * @param {Object} error error object
   */
  onAssignStoreError(error) {
    this.setState({error});
  }

  /**
   * Assign store change callback
   * @param {Object} data data object
   */
  onAssignStoreChange(data) {
    if (data.action === AppConstants.LIST_ASSIGNED_INSERTIONS) {
      this.setState({loading: false, assignedInsertions: AssignStore.getAssignedInsertions()});
    }
    if (data.action === AppConstants.UNASSIGN_INSERTION || data.action === AppConstants.ASSIGN_CREATIVES_INSERTIONS) {
      AssignActions.listAssignedInsertions({
        accountId: ContextStore.routingParams().accountId,
        folderId: ContextStore.routingParams().folderId,
        creativeId: ContextStore.routingParams().creativeId,
        detailedResponse: true,
      });
      Creative.list({accountId: ContextStore.routingParams().accountId, folderId: ContextStore.routingParams().folderId});
      this.setState({loading: true, loadingInsertion: {}});
    }
  }

  /**
   * Handles unassigning an insertion
   * @param {Object} insertion insertion
   */
  handleUnassign(insertion) {
    AssignActions.unAssignInsertion({
      accountId: ContextStore.routingParams().accountId,
      creativeId: ContextStore.routingParams().creativeId,
      insertionId: insertion.id,
    });
    this.setState({error: {}, loadingInsertion: insertion});
  }

  /**
   * Render
   */
  render() {
    let adSpaceTable = [];
    if (!isEmptyObject(this.state.assignedInsertions)) {
      const adSpaceIds = [];
      this.state.assignedInsertions.insertions.forEach((insertion) => {
        if (adSpaceIds.indexOf(insertion.ad_space_id) === -1) {
          adSpaceIds.push(insertion.ad_space_id);
        }
      });
      adSpaceTable = adSpaceIds.map((aId) => {
        const adSpace = getObjectInArray(this.state.assignedInsertions.ad_spaces, 'id', aId);
        const insertions = this.state.assignedInsertions.insertions.filter((insertion) => {
          if (insertion.ad_space_id === aId) {
            return insertion;
          }
          return false;
        });
        return (
          <LinkedAdSpaceTable
            key={adSpace.id}
            countries={CountryStore.getCountries()}
            adSpace={adSpace}
            insertions={insertions}
            campaigns={this.state.assignedInsertions.campaigns}
            projects={this.state.assignedInsertions.projects}
            onUnAssign={this.handleUnassign}
            loadingInsertion={this.state.loadingInsertion}
          />
        );
      });
    }
    const assignedInsertions = Object.hasOwnProperty.call(this.state.assignedInsertions, 'insertions')
      ? this.state.assignedInsertions.insertions : [];
    return (
      <div>
        <Menu pointing style={{backgroundColor: 'rgb(248, 249, 251)'}}>
          <Item
            useATag
            onClick={() => { this.setState({active: 'insertionList'}); }}
            active={this.state.active === 'insertionList'}
          >
            {!isEmptyObject(this.state.assignedInsertions)
              && (
              <Label floating small blue>
                {this.state.assignedInsertions.insertions.length}
              </Label>
              )
            }
            <Icon linkify />
            {'Linked insertions'}
          </Item>
          <Item
            useATag
            onClick={() => { this.setState({active: 'link'}); }}
            active={this.state.active === 'link'}
          >
            <Icon add />
            {'Add insertions'}
          </Item>
        </Menu>
        {!isEmptyObject(this.state.error)
          && <Message error>{this.state.error.message}</Message>
        }
        {this.state.loading
          && (
          <Dimmer inverted active>
            <Loader indeterminate text>
              {'Loading linked insertions'}
            </Loader>
          </Dimmer>
          )
        }
        {this.state.active === 'link'
          && <AssignCampaignForm assignedInsertions={assignedInsertions} />
        }
        {this.state.active === 'insertionList'
          && (
          <div>
            {adSpaceTable.length === 0
              && (
              <Segment center aligned basic>
                <h3 className="ui header">
                  {'No linked insertions found'}
                  <div className="sub header">
                    <Button small primary onClick={() => { this.setState({active: 'link'}); }}>
                      {'Click here to link an insertion'}
                    </Button>
                  </div>
                </h3>
              </Segment>
              )
            }
            {adSpaceTable}
          </div>
          )
        }
      </div>
    );
  }
}

export default LinkedInsertions;
