import PropTypes from 'prop-types';
import React from 'react';
import {isEmptyObject, getDateFromTimeStamp} from 'constants/Utils';

const CampaignHeader = ((props) => {
  if (isEmptyObject(props.campaign)) {
    return null;
  }

  let startDateFormat = 'auto';
  if (props.campaign.real_start_date !== null) {
    startDateFormat = getDateFromTimeStamp(new Date(props.campaign.real_start_date).getTime());
  }
  const startDate = (
    <span>
      <i className="clock icon" />
      {startDateFormat}
      {'\u00a0\u00a0'}
    </span>
  );

  let endDateFormat = 'auto';
  if (props.campaign.real_end_date !== null) {
    endDateFormat = getDateFromTimeStamp(new Date(props.campaign.real_end_date).getTime());
  }
  const endDate = (
    <span>
      <i className="flag checkered icon" />
      {endDateFormat}
    </span>
  );

  return (
    <h2 className="ui header" style={{marginTop: 0}}>
      {props.campaign.id} - {props.campaign.label}
      <div className="sub header">
        {startDate}
        <span>
          {'\u00a0\u00a0'}
        </span>
        {endDate}
      </div>
    </h2>
  );
});

export default CampaignHeader;

CampaignHeader.propTypes = {
  campaign: PropTypes.object,
};
