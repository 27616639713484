import React from 'react';
import AppConstants from 'constants/AppConstants';
import { getLocationDescriptor, getObjectInArray } from 'constants/Utils';
import {
  FolderStore, CreativeStore, AdPositionStore, ProjectStore, CampaignStore, ContextStore
} from 'stores';
import Modal from 'weborama-ui-react/Modal';
import { AdPosNotFoundMsg, CreativeNotFoundMsg } from 'components/NotFoundMsg';
/**
 * Class Smartrouter
  */
class SmartRouter extends React.Component {
  /**
 * Validates param;
 */
  static validateParam(array, id, identifier = 'id') {
    return getObjectInArray(array, identifier, id);
  }

  /**
   * Constructor
   */
  constructor() {
    super();

    this.state = {
      folders: [],
      // creatives: [],
      adPositions: [],
      projects: [],
      campaigns: [],
      // insertions: [],
      innerModal: null,
      oldContextParams: { // Object will be overwritten with new context parameters
        adPositionId: undefined,
        creativeId: undefined
      }
    };

    this.onFolderStoreChange = this.onFolderStoreChange.bind(this);
    this.onCreativeStoreChange = this.onCreativeStoreChange.bind(this);
    this.onAdPositionStoreChange = this.onAdPositionStoreChange.bind(this);
    this.onProjectStoreChange = this.onProjectStoreChange.bind(this);
    this.onCampaignStoreChange = this.onCampaignStoreChange.bind(this);

    FolderStore.addChangeListener(this.onFolderStoreChange);
    CreativeStore.addChangeListener(this.onCreativeStoreChange);
    AdPositionStore.addChangeListener(this.onAdPositionStoreChange);
    ProjectStore.addChangeListener(this.onProjectStoreChange);
    CampaignStore.addChangeListener(this.onCampaignStoreChange);

    this.messageRef = React.createRef();
  }

  /**
   * @param {Object} props props
   * @param {Object} prevState previous state
   */
  componentDidUpdate(props, prevState) {

    if (ContextStore.routingParams().adPositionId !== this.state.oldContextParams.adPositionId
      && ContextStore.routingParams().creativeId === this.state.oldContextParams.creativeId) {
      if (!SmartRouter.validateParam(this.state.adPositions, ContextStore.routingParams().adPositionId, 'cross_version_identifier')) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ innerModal: <AdPosNotFoundMsg adPositions={this.state.adPositions} />, oldContextParams: ContextStore.routingParams() });
      }
    }
    /**
     * Hide modal after routing
     */
    if (JSON.stringify(ContextStore.routingParams()) !== JSON.stringify(this.state.oldContextParams) && this.messageRef.current.isActive()) {
      this.messageRef.current.hide();
    }
    if (this.state.innerModal !== null && JSON.stringify(this.state.innerModal) !== JSON.stringify(prevState.innerModal)) {
      this.messageRef.current.show({
        closable: false,
        onHidden: () => {
          this.setState({ innerModal: null });
        },
      });
    }
    // if (ContextStore.routingParams() && Object.prototype.hasOwnProperty.call(ContextStore.routingParams(), 'adPositionId') &&
    //   ContextStore.routingParams().adPositionId !== undefined) {
    //   if (JSON.stringify(state.adPositions) !== JSON.stringify(this.state.adPositions)) {
    //    // this.validateAdPosition(ContextStore.routingParams().adPositionId);
    //   }
    // }
  }

  /**
   * un-register store listeners
   */
  componentWillUnmount() {
    FolderStore.removeChangeListener(this.onFolderStoreChange);
    CreativeStore.removeChangeListener(this.onCreativeStoreChange);
    AdPositionStore.removeChangeListener(this.onAdPositionStoreChange);
    ProjectStore.removeChangeListener(this.onProjectStoreChange);
    CampaignStore.removeChangeListener(this.onCampaignStoreChange);
  }

  /**
   * Project store change callback
   * @param {Object} data data object
   */
  onProjectStoreChange(data) {
    switch (data.action) {
      case AppConstants.LIST_PROJECTS: {
        const projects = ProjectStore.getProjects();
        this.setState({ projects });
        if (ContextStore.routingParams().projectId === undefined && projects.length > 0) {
          const ld = getLocationDescriptor('project', projects[0].id, true);
          ContextStore.router().push(ld);
        }
        break;
      }
      case AppConstants.ADD_PROJECT: {
        const ld = getLocationDescriptor('project', data.data.data.id, true);
        ContextStore.router().push(ld);
        break;
      }
      case AppConstants.REMOVE_PROJECT: {
        if (this.state.projects.length > 1) {
          const elementPos = this.state.projects.map(obj => String(obj.id)).indexOf(String(data.data.data.id));
          const nextProject = this.state.projects[elementPos - 1] || this.state.projects[elementPos + 1];
          const ld = getLocationDescriptor('project', nextProject.id, true);
          ContextStore.router().push(ld);
        } else {
          const ld = getLocationDescriptor('project', data.data.data.id, true);
          ld.pathname = ld.pathname.replace(new RegExp('/project/[\\d]+'), '');
          ContextStore.router().push(ld);
        }
        break;
      }
      // no default
    }
  }

  onCampaignStoreChange(data) {
    switch (data.action) {
      case AppConstants.LIST_CAMPAIGNS: {
        const campaigns = CampaignStore.getCampaigns();
        this.setState({ campaigns });
        if (ContextStore.routingParams().campaignId === undefined && campaigns.length > 0) {
          // const ld = getLocationDescriptor('campaign', campaigns[0].id);
          // ContextStore.router().replace(ld);
        }
        // } else if (ContextStore.routingParams().creativeId !== undefined) {
        //   if (!SmartRouter.validateParam(creatives, ContextStore.routingParams().creativeId)) {
        //     this.setState({innerModal: <CreativeNotFoundMsg creatives={creatives} />});
        //   }
        // }
        break;
      }
      case AppConstants.ADD_CAMPAIGN: {

        if (data.data.data.project_id !== ContextStore.routingParams().projectId) { // So if the project is different, update the route
          const newLocation = getLocationDescriptor('project', data.data.data.project_id, true);
          ContextStore.router().push(newLocation);
        }

        const ld = getLocationDescriptor('campaign', data.data.data.id, true);
        ContextStore.router().push(ld);
        break;
      }
      case AppConstants.REMOVE_CAMPAIGN: {
        if (this.state.campaigns.length > 1) {
          const elementPos = this.state.campaigns.map(obj => String(obj.id)).indexOf(String(data.data.data.id));
          const nextCampaign = this.state.campaigns[elementPos - 1] || this.state.campaigns[elementPos + 1];
          const ld = getLocationDescriptor('campaign', nextCampaign.id, true);
          ContextStore.router().push(ld);
        } else {
          const ld = getLocationDescriptor('campaign', data.data.data.id, true);
          ld.pathname = ld.pathname.replace(new RegExp('/campaign/[\\d]+'), '');
          ContextStore.router().push(ld);
        }
        break;
      }
      // no default
    }
  }

  /**
   * Folder store callback
   * @param {Object} data data object
   */
  onFolderStoreChange(data) {
    switch (data.action) {
      case AppConstants.LIST_FOLDERS: {
        const folders = FolderStore.getFolders();
        this.setState({ folders });
        if (ContextStore.routingParams().folderId === undefined && folders.length > 0) {
          const ld = getLocationDescriptor('folder', folders[0].id);
          // transition to the new url, withouth creating browserhistory.
          ContextStore.router().replace(ld);
        } else if (ContextStore.routingParams().folderId !== undefined && folders.length > 0) {
          // if a folder is selected in the url, but cannot be found in the folderlist we redirect
          // to the first folder in the list
          // TODO: this should also be impleneted for creative, adposition, project and campaign
          const folderObj = getObjectInArray(folders, 'id', ContextStore.routingParams().folderId);
          if (folderObj === false) {
            const ld = getLocationDescriptor('folder', folders[0].id);
            // transition to the new url, withouth creating browserhistory.
            ContextStore.router().replace(ld);
          }
        }
        break;
      }
      case AppConstants.ADD_FOLDER: {
        const ld = getLocationDescriptor('folder', data.data.data.id, true);
        ContextStore.router().push(ld);
        break;
      }
      case AppConstants.REMOVE_FOLDER: {
        if (this.state.folders.length > 1) {
          const elementPos = this.state.folders.map(obj => String(obj.id)).indexOf(String(data.data.data.id));
          const nextFolder = this.state.folders[elementPos - 1] || this.state.folders[elementPos + 1];
          const ld = getLocationDescriptor('folder', nextFolder.id, true);
          ContextStore.router().push(ld);
        } else {
          const ld = getLocationDescriptor('folder', data.data.data.id, true);
          ld.pathname = ld.pathname.replace(new RegExp('/folder/[\\d]+'), '');
          ContextStore.router().push(ld);
        }
        break;
      }
      // no default
    }
  }

  /**
   * Creative store callback
   */
  onCreativeStoreChange(data) {
    switch (data.action) {
      case AppConstants.LIST_CREATIVES: {
        const creatives = CreativeStore.getCreatives();
        // this.setState({creatives});
        if (ContextStore.routingParams().creativeId === undefined && creatives.length > 0) {
          // const ld = getLocationDescriptor('creative', creatives[0].id)
          // ContextStore.router().replace(ld);
        } else if (ContextStore.routingParams().creativeId !== undefined) {
          if (!SmartRouter.validateParam(creatives, ContextStore.routingParams().creativeId)) {
            this.setState({ innerModal: <CreativeNotFoundMsg creatives={creatives} /> });
          }
        }
        break;
      }
      case AppConstants.ADD_CREATIVE: {

        if (data.folder_id !== ContextStore.routingParams().folderId) { // So if the folder is different, update the route
          const newLocation = getLocationDescriptor('folder', data.folderId, true);
          ContextStore.router().push(newLocation);
        }
        const ld = getLocationDescriptor('creative', data.data.data.id, true);
        ContextStore.router().push(ld);
        break;
      }
      // case AppConstants.UPDATE_CREATIVE:
      case AppConstants.DUPLICATE_CREATIVE: {
        if (parseInt(data.data.data.folder_id, 10) === parseInt(ContextStore.routingParams().folderId, 10)) {
          const ld = getLocationDescriptor('creative', data.data.data.id, true);
          ContextStore.router().push(ld);
        } else {
          // if not duplicated to the same folder, we transition to the other folder.
          const ld = getLocationDescriptor('folder', data.data.data.folder_id, true);
          ContextStore.router().push(ld);
        }
        break;
      }
      case AppConstants.REMOVE_CREATIVE: {
        const ld = getLocationDescriptor('creative', data.data.id, true);
        ld.pathname = ld.pathname.replace(new RegExp('/creative/[\\d]+'), '');
        ContextStore.router().push(ld);
        break;
      }
      // no default
    }
  }

  /**
   * Ad Position store callback
   */
  onAdPositionStoreChange(data) {
    switch (data.action) {
      case AppConstants.LIST_ADPOSITIONS: {
        const adPositions = AdPositionStore.getAdPositions();
        this.setState({ adPositions });
        if (ContextStore.routingParams().adPositionId === undefined && adPositions.length > 0) {
          const ld = getLocationDescriptor('adposition', adPositions[0].cross_version_identifier);
          ContextStore.router().replace(ld);
        } else if (ContextStore.routingParams().adPositionId !== undefined) {
          if (!SmartRouter.validateParam(adPositions, ContextStore.routingParams().adPositionId, 'cross_version_identifier')) {
            this.setState({ innerModal: <AdPosNotFoundMsg adPositions={adPositions} /> });
          }
        }
        break;
      }
      case AppConstants.ADD_ADPOSITION: {
        const { adPositions } = this.state;
        adPositions.push(data.data.data);
        this.setState({ adPositions });
        ContextStore.router().replace(getLocationDescriptor('adposition', data.data.data.cross_version_identifier));
        break;
      }
      case AppConstants.REMOVE_ADPOSITION:
        ContextStore.router().replace(getLocationDescriptor(
          'adposition',
          this.state.adPositions[0].cross_version_identifier,
        ));
        break;
      // no default
    }
  }

  /**
   * Resets inner modal
   */
  resetInnerModal() {
    this.setState({ innerModal: null });
  }

  /**
   * Render nothing
   */
  render() {
    return (
      <Modal ref={this.messageRef} onHide={this.resetInnerModal}>
        {this.state.innerModal}
      </Modal>
    );
  }
}

export default SmartRouter;
