/* eslint-disable object-curly-newline */
import PropTypes from 'prop-types';
import React from 'react';
import {Insertion, LandingUrl} from 'actions'; // AssignedAdPosition  ThirdPartyTag, // CampaignAdSpace
import { CampaignStore, AdSpaceStore, InsertionStore, LandingUrlStore, ContextStore, CountryStore } from 'stores'; // AssignedAdPositionStore  ThirdPartyTagStore
import {getObjectInArray, isEmptyObject, removeHTMLEntities} from 'constants/Utils';
import Table from 'weborama-ui-react/Table';
import AssignedAdPositionClick from 'components/AssignedAdPositionClick';
import SiteOfferClickRow from 'components/SiteOfferClickRow';
import InsertionClickRow from 'components/InsertionClickRow';
import CreativeClickRow from 'components/CreativeClickRow';
import CampaignClickRow from 'components/CampaignClickRow';
import EditableUrl from 'components/EditableUrl';
import AppConstants from 'constants/AppConstants';
/**
 * Click Manager
 */
class ClickManager extends React.Component {
  /**
   * constructor
   * @date   2016-11-17
   * @param {object} props Properties
   */
  constructor(props) {
    super(props);

    const sortedInsertions = props.insertions.sort((a, b) => a.label.localeCompare(b.label));

    this.state = {
      loading: {
        campaign: false,
        AdSpace: false,
        insertion: false,
      },
      campaign: props.campaign || {},
      insertions: sortedInsertions || [],
      expandedInsertions: [],
      adSpaces: props.adSpaces || [],
      error: {
        adSpace: {},
        campaign: false,
      },
      insertionsDetails: props.insertionsDetails || [],
    };

    this.onCampaignStoreError = this.onCampaignStoreError.bind(this);
    this.onAdSpaceStoreError = this.onAdSpaceStoreError.bind(this);
    this.expandCollapseInsertion = this.expandCollapseInsertion.bind(this);
    this.calculateComponentState = this.calculateComponentState.bind(this);
    this.handleEditableUrlSubmit = this.handleEditableUrlSubmit.bind(this);
    this.handleDeleteUrl = this.handleDeleteUrl.bind(this);
    this.onLandingUrlStoreChange = this.onLandingUrlStoreChange.bind(this);

    LandingUrlStore.addChangeListener(this.onLandingUrlStoreChange);
    CampaignStore.addErrorListener(this.onCampaignStoreError);
    AdSpaceStore.addErrorListener(this.onAdSpaceStoreError);
  }

  componentDidMount() {
    this.calculateComponentState();
  }

  componentDidUpdate() {
    this.calculateComponentState();
  }

  /**
   * Remove error listeners
   */
  componentWillUnmount() {
    LandingUrlStore.removeChangeListener(this.onLandingUrlStoreChange);
    CampaignStore.removeErrorListener(this.onCampaignStoreError);
    AdSpaceStore.removeErrorListener(this.onAdSpaceStoreError);
  }

  calculateComponentState() {
    if (JSON.stringify(this.props.campaign) !== JSON.stringify(this.state.campaign)) {
      const {loading} = this.state;
      loading.campaign = false;
      const {error} = this.state;
      error.campaign = false;
      this.setState({campaign: this.props.campaign, loading, error});
    }
    if (JSON.stringify(this.props.adSpaces) !== JSON.stringify(this.state.adSpaces)) {
      const {error} = this.state;
      error.adSpace = {};
      this.setState({adSpaces: this.props.adSpaces, error});
    }
    if (JSON.stringify(this.props.insertions) !== JSON.stringify(this.state.insertions)) {
      const {loading} = this.state;
      loading.insertion = true;
      this.setState({ loading }, () => {
        const sortedInsertions = this.props.insertions.sort((a, b) => a.label.localeCompare(b.label));
        loading.insertion = false;
        this.setState({insertions: sortedInsertions, loading});
      });

    }
    if (JSON.stringify(this.props.insertionsDetails) !== JSON.stringify(this.state.insertionsDetails)) {
      this.setState({insertionsDetails: this.props.insertionsDetails});
    }
  }

  onLandingUrlStoreChange(data) {
    if (data.action === AppConstants.UPDATE_LANDING_URL || data.action === AppConstants.REMOVE_LANDING_URL) {
      setTimeout(() => {
        Insertion.details({
          accountId: ContextStore.routingParams().accountId,
          insertionIds: InsertionStore.expandedInsertionIds,
        });
      }, 1000);
    }
  }

  /**
   * Campaign store error callback
   * @param {Object} err error object
   */
  onCampaignStoreError(err) {
    const {error} = this.state;
    const {loading} = this.state;
    loading.campaign = false;
    error.campaign = err;
    this.setState({error, loading});
  }

  /**
   * Ad Space Store error
   * @param {Object} err error object
   */
  onAdSpaceStoreError(err) {
    const {loading} = this.state;
    loading.adSpace = false;
    const {error} = this.state;
    error.adSpace = err;
    this.setState({error, loading});
  }

  /**
   * Expands or collapse an insertion row  to see assigned creatives
   * @param {Number} insertionId insertion id
   */
  expandCollapseInsertion(insertionId) {
    const expandedInsertions = this.state.expandedInsertions.slice();
    const index = expandedInsertions.indexOf(insertionId);
    if (index > -1) {
      expandedInsertions.splice(index, 1);
    } else {
      expandedInsertions.push(insertionId);
      Insertion.details({
        accountId: ContextStore.routingParams().accountId,
        insertionIds: expandedInsertions,
      });
    }
    this.setState({expandedInsertions});
  }

  handleEditableUrlSubmit(urlFromEditableUrl, baseObject) {

    LandingUrl.update({
      accountId: ContextStore.routingParams().accountId,
      id: baseObject.id,
      label: baseObject.label,
      url_text: removeHTMLEntities(urlFromEditableUrl),
      assigned_ad_position_id: baseObject.assigned_ad_position_id,
      url_number: baseObject.url_number,
    });
  }

  handleDeleteUrl(baseObject) {
    LandingUrl.remove({
      accountId: ContextStore.routingParams().accountId,
      id: baseObject.id,
    });
  }

  render() {
    const tableRow = [];
    tableRow.push(<CampaignClickRow
      key={this.state.campaign.id + Date.now() + Math.random()}
      error={this.state.error.campaign}
      campaign={this.state.campaign}
    />);
    // add all ad spaces
    this.state.adSpaces.forEach((adSpace) => {
      const country = getObjectInArray(CountryStore.getCountries(), 'id', adSpace.ad_network_fields.country_id);
      let adSpaceError = false;
      if (!isEmptyObject(this.state.error.adSpace)) {
        adSpaceError = parseInt(this.state.error.adSpace.data.id, 10) === parseInt(adSpace.campaigns_ad_space_fields.id, 10) ? this.state.error.adSpace : false; // eslint-disable-line max-len
      }
      tableRow.push((
        <SiteOfferClickRow key={adSpace.ad_space_fields.id + Date.now() + Math.random()} adSpace={adSpace} country={country} error={adSpaceError} />
      ));
      // Get insertions by adspaces
      this.state.insertions.forEach((insertion) => {
        if (insertion.ad_space_id === adSpace.ad_space_fields.id) {
          let isExpanded = false;
          if (this.state.expandedInsertions.indexOf(insertion.id) > -1
              && !isEmptyObject(getObjectInArray(this.state.insertionsDetails, 'id', insertion.id) || {})) {
            isExpanded = true;
          }
          if (this.state.expandedInsertions.indexOf(insertion.id) > -1
            && isEmptyObject(getObjectInArray(this.state.insertionsDetails, 'id', insertion.id))) {
            isExpanded = 'loading';
          }
          tableRow.push(<InsertionClickRow
            key={insertion.id + Date.now() + Math.random()}
            insertion={insertion}
            isExpanded={isExpanded}
            expandCollapse={this.expandCollapseInsertion}
          />);
          // If insertion is expanded, cycle through assigned creatives and ad positions
          if (isExpanded) {
            const details = getObjectInArray(this.state.insertionsDetails, 'id', insertion.id) || {};
            const filteredCreatives = this.props.assignedCreatives.filter((item) => {
              if (parseInt(item.insertion_id, 10) === parseInt(insertion.id, 10)) {
                return item;
              }
              return false;
            });
            filteredCreatives.forEach((assignedCreative) => {
              tableRow.push(<CreativeClickRow key={assignedCreative.id + Date.now() + Math.random()} creative={assignedCreative} />);
              if (!isEmptyObject(details)) {
                const trackingElements = [];
                const adPositions = [];

                details.tracking_elements.forEach((trackingElement) => {
                  const trackingElementId = trackingElement.id;
                  trackingElements[trackingElementId] = trackingElement;
                  trackingElement.assigned_ad_positions.forEach((assignedAdPosition) => {
                    const adPositionId = assignedAdPosition.ad_position_id;
                    adPositions[adPositionId] = assignedAdPosition;
                  });
                });

                adPositions.forEach((assignedAdPosition) => {
                  if (assignedAdPosition.adposition.creative.id === assignedCreative.id) {

                    const nonDeletedLandingUrls = [];

                    assignedAdPosition.landing_urls.forEach((singleLandingUrl) => {
                      // We get the deleted ones as well, don't use them
                      if (singleLandingUrl.status !== 'deleted') {

                        // Reorganize the clicks so that we ignore the deleted ones when
                        // calculating the next click label
                        const cleanLandingUrl = singleLandingUrl;
                        cleanLandingUrl.url_number = String(nonDeletedLandingUrls.length + 1);

                        nonDeletedLandingUrls.push(cleanLandingUrl);
                      }
                    });

                    // Only send the cleaned ones (cleaned from deleted landing urls)
                    const cleanedAssignedAdPosition = assignedAdPosition;
                    cleanedAssignedAdPosition.landing_urls = nonDeletedLandingUrls;

                    tableRow.push(<AssignedAdPositionClick
                      key={assignedAdPosition.id + Date.now() + Math.random()}
                      assignedAdPosition={cleanedAssignedAdPosition}
                      label={trackingElements[assignedAdPosition.tracking_element_id].placement.label}
                    />);

                    nonDeletedLandingUrls.forEach(singleNonDeletedLandingUrl => {
                      tableRow.push((
                        <tr key={singleNonDeletedLandingUrl.id + Date.now() + Math.random()}>
                          <td className="center aligned">AP</td>
                          <td className="left aligned">
                            <i style={{ color: 'lightgrey' }}>{`Extra ${singleNonDeletedLandingUrl.url_number}`}</i>
                            <span style={{ float: 'right' }}>{singleNonDeletedLandingUrl.label}</span>
                          </td>
                          <td>
                            <EditableUrl
                              baseObject={singleNonDeletedLandingUrl}
                              url={singleNonDeletedLandingUrl.url_text}
                              onSubmit={this.handleEditableUrlSubmit}
                              onDelete={this.handleDeleteUrl}
                            />
                          </td>
                        </tr>
                      ));
                    });
                  }
                });
              }
            });
          }
        }
      });
    });
    return (
      <Table
        small
        celled
        definition
        attached
      >
        <thead className="full-width">
          <tr>
            <th className="sixteen wide center aligned" colSpan="3">
              {'C = Campaign, SO = Site / Offer, I = Insertion, AP = Ad Position'}
            </th>
          </tr>
        </thead>
        <tbody>
          {tableRow}
        </tbody>
      </Table>
    );
  }
}

ClickManager.propTypes = {
  campaign: PropTypes.object,
  adSpaces: PropTypes.array,
  insertions: PropTypes.array,
  insertionsDetails: PropTypes.array,
  assignedCreatives: PropTypes.array,
};

export default ClickManager;
