import BaseStore from 'stores/BaseStore';
import ServerConstants from 'constants/ServerConstants';
import {register} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const FolderStore = Object.assign({}, BaseStore, {
  folders: [],
  listNumberOfCreatives: [],
  /**
   * returns Folders
   * @date   2016-01-14
   * @return {array}   Folders
   */
  getFolders() {
    return this.folders;
  },
  /**
   * Returns array with number of creatives per folders
   * @date   2016-05-25
   * @return {array}   List with number of creatives
   */
  getNumberOfCreatives() {
    return this.listNumberOfCreatives;
  },
  /**
   * Fetches all folder by account ID
   * @date   2016-01-14k
   * @param  {number}   accountId id of account
   * @param  {boolean}  browse  browse
   */
  listFolders(queryData, browse = false) {
    let action = AppConstants.LIST_FOLDERS;

    if (browse) {
      action = AppConstants.BROWSE_FOLDERS;
    }

    const onSuccess = (response) => {
      this.folders = response.data.slice();
      this.emitChange({action});
    };

    const onError = (message) => {
      this.emitError({action, message});
    };

    const orderSettings = {
      status: 'active',
      order_fields: ['created_at', 'updated_at'],
      order_direction: 'desc',
    };

    const data = Object.assign({}, queryData, orderSettings);

    this.post({
      url: ServerConstants.CREATIVE_LIBRARY.FOLDERS.LIST,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Posts new folder to backend
   * @date   2016-01-18
   * @param  {Number}   accountId Account id
   * @param  {String}   label    Folder name
   * @param  {String}   comments Folder Comment
   */
  addFolder(data, addCreative) {
    const onSuccess = (response) => {
      this.emitChange({action: AppConstants.ADD_FOLDER, data: response, addCreative});
      this.listFolders({account_id: data.account_id});
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.ADD_FOLDER, message});
    };

    this.post({
      url: ServerConstants.CREATIVE_LIBRARY.FOLDERS.CREATE,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Updates the properties of a folder
   * @date   2016-01-22
   * @param  {Number}   accountId id of account id
   * @param  {Number}   folderId  Id of the folder that should be updated
   * @param  {String}   label      Updated label
   * @param  {String}   comments   Updated comments
   */
  updateFolder(data) {
    const onSuccess = () => {
      this.emitChange({action: AppConstants.UPDATE_FOLDER});
      this.listFolders({account_id: data.account_id});
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.UPDATE_FOLDER, message});
    };

    this.post({
      url: ServerConstants.CREATIVE_LIBRARY.FOLDERS.UPDATE,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Removes a folder for account
   * @date   2016-01-20
   * @param  {number}   accountId Id of account for folder
   * @param  {number}   folderId  Id of the folder that need to be removed
   */
  removeFolder(data) {
    const onSuccess = (response) => {
      this.emitChange({action: AppConstants.REMOVE_FOLDER, data: response});
      this.listFolders({account_id: data.account_id});
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.REMOVE_FOLDER, message});
    };

    this.post({
      url: ServerConstants.CREATIVE_LIBRARY.FOLDERS.DELETE,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Fetches a list with number of creatives per folder for account.
   * @date   2016-05-25
   * @param  {Number}   accountId account id
   */
  fetchNumberOfCreatives(data) {
    const onSuccess = (response) => {
      Object.keys(response.data).forEach((key) => {
        let obj = {
          id: parseInt(key, 10),
        };
        obj = Object.assign({}, obj, response.data[key]);
        this.listNumberOfCreatives.push(obj);
      });

      this.emitChange({action: AppConstants.LIST_NOCREATIVESBYFOLDER});
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.LIST_NOCREATIVESBYFOLDER, message});
    };

    this.post({
      url: ServerConstants.CREATIVE_LIBRARY.FOLDERS.GET_NUMBER_OF_CREATIVES,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Handle Store actions
   * @date   2016-02-11
   * @param  {Function}   function(action -
   */
  dispatcherIndex: register((action) => {
    switch (action.actionType) {
      case AppConstants.BROWSE_FOLDERS:
        FolderStore.listFolders({account_id: action.accountId}, true);
        break;
      case AppConstants.LIST_FOLDERS:
        FolderStore.listFolders({account_id: action.accountId}, false);
        break;
      case AppConstants.ADD_FOLDER:
        FolderStore.addFolder({
          account_id: action.accountId,
          label: action.label,
          comments: action.comments,
        }, action.addCreative);
        break;
      case AppConstants.UPDATE_FOLDER:
        FolderStore.updateFolder({
          account_id: action.accountId,
          id: action.folderId,
          label: action.label,
          comments: action.comments,
        });
        break;
      case AppConstants.REMOVE_FOLDER:
        FolderStore.removeFolder({
          account_id: action.accountId,
          id: action.folderId,
        });
        break;
      case AppConstants.LIST_NOCREATIVESBYFOLDER:
        FolderStore.fetchNumberOfCreatives({
          account_id: action.accountId,
        });
        break;
      //  no default
    }
    return true; // No errors. Needed by promise in Dispatcher.
  }),
});

export default FolderStore;
