import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'weborama-ui-react/Dropdown';
import Menu from 'weborama-ui-react/Menu';
import Item from 'weborama-ui-react/Item';
import ContextStore from 'stores/ContextStore';
import './NavigationMenu.scss';

/*
* This is the component that's at the bottom left where you can navigate to different folders or projects
*/
const NavigationMenu = ((props) => {

  const [routingParams, setRoutingParams] = useState(ContextStore.routingParams()[`${props.subject}Id`]);
  // This hook is mainly here for re-triggering  component with the help of useEffect so that we get the latest
  // routing parameters processed. We really need to get rid of router v3!

  let menuItems;

  useEffect(() => {
    if (routingParams !== ContextStore.routingParams()[`${props.subject}Id`]) {
      setRoutingParams(ContextStore.routingParams()[`${props.subject}Id`]);
    }
  });

  if (props.items) {
    menuItems = props.items.map(item => {

      const activeStatus = parseInt(item.id, 10) === routingParams;

      return (
        <Item
          key={`${props.subject}-${item.id}`}
          active={activeStatus}
          onClick={() => { props.onClick(item); }}
          style={!activeStatus ? { cursor: 'pointer' } : null}
        >
          <a
            className="folder-margin-align"
            href="#"
          >
            {item.label}
          </a>
          <Dropdown
            inline
            isMenu
            style={{ padding: '0', background: 'none' }}
            stopPropagation
          >
            <i className="dropdown icon" />
            <Menu>
              <Item
                onClick={() => props.addFn(item)}
              > {/* For some reason, id is coming as string here. Investigate! */}
                <i className="plus icon" />
                {props.labels.add}
              </Item>
              <Item onClick={() => props.editFn(item)}>
                <i className="external icon" />
                {props.labels.edit}
              </Item>
              <Item
                onClick={() => props.removeFn(item)}
              >
                <i className="trash icon" />
                {props.labels.remove}
              </Item>
            </Menu>
          </Dropdown>
        </Item>
      );
    });
  }
  return (
    <Menu
      fluid
      vertical
    >
      {menuItems}
    </Menu>
  );
});

NavigationMenu.propTypes = {
  onClick: PropTypes.func,
  addFn: PropTypes.func,
  editFn: PropTypes.func,
  removeFn: PropTypes.func,
  labels: PropTypes.object,
  items: PropTypes.array,
  subject: PropTypes.string,
};

export default NavigationMenu;
