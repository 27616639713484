import React from 'react';
import PropTypes from 'prop-types';
import EditableUrl from 'components/EditableUrl';
import {CampaignAdSpace} from 'actions';
import {isEmptyObject} from 'constants/Utils';
import ContextStore from 'stores/ContextStore';

/**
 * Class Campaign AdSpace click
 */
class CampaignAdSpaceClick extends React.Component {
  /**
   * Constructor
   * @param {Object} props Properties
   */
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: {},
      campaignAdSpace: props.campaignAdSpace || {},
    };
    this.handleUpdateCampaignAdSpace = this.handleUpdateCampaignAdSpace.bind(this);
    this.calculateComponentUpdate = this.calculateComponentUpdate.bind(this);
  }

  componentDidMount() { // Unnecessary???
    this.calculateComponentUpdate();
  }
  componentDidUpdate() {
    this.calculateComponentUpdate();
  }

  calculateComponentUpdate() {
    if (JSON.stringify(this.props.campaignAdSpace) !== JSON.stringify(this.state.campaignAdSpace)) {
      this.setState({campaignAdSpace: this.props.campaignAdSpace, loading: false});
    }

    if (this.props.error && JSON.stringify(this.props.error) !== JSON.stringify(this.state.error)) {
      // Make sure there is an actual error happening to trigger this isntead of type coercion shenanigans
      this.setState({error: this.props.error, loading: false});
    }
  }

  /**
   * Handles update campaign ad space url
   * @param {string} url url
   */
  handleUpdateCampaignAdSpace(url) {
    CampaignAdSpace.update({
      accountId: ContextStore.routingParams().accountId,
      campaignAdSpaceId: this.state.campaignAdSpace.id,
      url,
    });

    this.setState({loading: true, error: {}});
  }

  /**
   * Renders campaign adspace url form
   */
  render() {
    const url = (!isEmptyObject(this.state.campaignAdSpace) && this.state.campaignAdSpace.landing_url !== null)
      ? this.state.campaignAdSpace.landing_url : '';
    return (
      <EditableUrl
        url={url}
        parent="Campaign"
        baseObject={this.state.campaignAdSpace}
        error={this.state.error}
        onSubmit={this.handleUpdateCampaignAdSpace}
        loading={this.state.loading}
      />
    );
  }
}

export default CampaignAdSpaceClick;

CampaignAdSpaceClick.propTypes = {
  campaignAdSpace: PropTypes.object,
  error: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
};
