import React from 'react';
import PropTypes from 'prop-types';
import AppConstants from 'constants/AppConstants';
import {Grid, Row, Column} from 'weborama-ui-react/Grid';
import {Creative} from 'actions';
import {isEmptyObject} from 'constants/Utils';
import Dimmer from 'weborama-ui-react/Dimmer';
import Input from 'weborama-ui-react/Input';
import {Field} from 'weborama-ui-react/Form';
import Checkbox from 'weborama-ui-react/Checkbox';
import Button from 'weborama-ui-react/Button';
import {CreativeStore, ContextStore} from 'stores';
import Message from 'weborama-ui-react/Message';
import Segment from 'weborama-ui-react/Segment';
import './vastsettingsform.sass';
/**
 * Class VastSettingsForm
 */
class VastSettingsForm extends React.Component {
  /**
   * constructor
   * @date   2016-12-19
   * @param  {Object}   props properties
   */
  constructor(props) {
    super(props);

    const settings = JSON.parse(props.creative.settings);

    this.state = {
      creative: props.creative || {},
      vastVersion: settings.vast_version || '3.0',
      companionAds: settings.companion_ads || 'none',
      skipEvent: settings.skip_event || 'YES',
      skipOffset: settings.skip_offset || 'NO',
      offset: settings.offset || '00:00:05',
      videoProgress30s: settings.video_progress_30s || 'NO',
      videoProgressEvent: settings.video_progress_event || 'NO',
      videoProgressEventOffset: settings.video_progress_event_offset || '00:00:05',
      error: {},
      loading: false,
    };

    this.handleVastVersionChange = this.handleVastVersionChange.bind(this);
    this.handleCompanionChange = this.handleCompanionChange.bind(this);
    this.handleSkipButtonChange = this.handleSkipButtonChange.bind(this);
    this.handleSkipOffsetChange = this.handleSkipOffsetChange.bind(this);
    this.handleSkipEventChange = this.handleSkipEventChange.bind(this);
    this.handleVideoProgressEventChange = this.handleVideoProgressEventChange.bind(this);
    this.handleVideoProgressEventOffsetChange = this.handleVideoProgressEventOffsetChange.bind(this);
    this.handleSaveSettings = this.handleSaveSettings.bind(this);
    this.handleVideoProgress30s = this.handleVideoProgress30s.bind(this);
    this.onCreativeStoreChange = this.onCreativeStoreChange.bind(this);
    this.onCreativeStoreError = this.onCreativeStoreError.bind(this);

    CreativeStore.addErrorListener(this.onCreativeStoreError);
    CreativeStore.addChangeListener(this.onCreativeStoreChange);

    this.dimmerRef = React.createRef();
    this.skipButtonRef = React.createRef();
    this.skipEventRef = React.createRef();
    this.vp30sRef = React.createRef();
    this.videoProgressEventRef = React.createRef();
  }

  componentDidUpdate() {
    // Initial values are already assigned at the constructor, therefore we can skip componentDidMount

    if (JSON.stringify(this.props.creative) !== JSON.stringify(this.state.creative)
    && this.props.creative !== undefined && !isEmptyObject(this.props.creative)) {
      const settings = this.props.creative.settings !== '' ? JSON.parse(this.props.creative.settings) : {};
      if (!isEmptyObject(settings) && settings !== null) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          creative: this.props.creative,
          vastVersion: settings.vast_version || '3.0',
          companionAds: settings.companion_ads || 'none',
          skipEvent: settings.skip_event || 'YES',
          skipOffset: settings.skip_offset || 'NO',
          offset: settings.offset || '00:00:05',
          videoProgress30s: settings.video_progress_30s || 'NO',
          videoProgressEvent: settings.video_progress_event || 'NO',
          videoProgressEventOffset: settings.video_progress_event_offset || '00:00:05',
          loading: false,
        });
      } else {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({creative: this.props.creative, loading: false});
      }
    }

    // Handle dimming options that are not available
    if (this.state.vastVersion === '2.0') {
      this.dimmerRef.current.show();
    } else {
      this.dimmerRef.current.hide();
    }
  }

  /**
   * Unregister store callbacks
   */
  componentWillUnmount() {
    CreativeStore.removeErrorListener(this.onCreativeStoreError);
    CreativeStore.removeChangeListener(this.onCreativeStoreChange);
  }

  /**
   * On creative store error
   * @param {Object} data data Object
   */
  onCreativeStoreError(data) {
    if (data.action === AppConstants.UPDATE_CREATIVE) {
      this.setState({loading: false, error: data});
    }
  }

  /**
   * On creative store change
   * @param {Object} data data object
   */
  onCreativeStoreChange(data) {
    if (data.action === AppConstants.UPDATE_CREATIVE) {
      this.setState({loading: false});
    }
  }

  /**
   * Handle vast version change
   * @date   2016-12-20
   * @param  {String}   value value
   */
  handleVastVersionChange(value) {
    this.setState({vastVersion: value});
  }

  /**
   * Handles change in companion ads
   * @date   2016-12-20
   * @param  {String}   value value
   */
  handleCompanionChange(value) {
    this.setState({companionAds: value});
  }

  /**
   * Handles skip button change
   * @date   2016-12-21
   */
  handleSkipButtonChange() {
    if (this.skipButtonRef.current.isChecked()) {
      this.setState({skipOffset: 'YES', offset: '00:00:05'});
    } else {
      this.setState({skipOffset: 'NO', offset: '00:00:00'});
    }
  }

  /**
   * Handles skip offset value change
   * @date  2016-12-21
   * @param {string}   value Value
   */
  handleSkipOffsetChange(value) {
    this.setState({offset: value});
  }

  /**
   * Handles Skip event change
   */
  handleSkipEventChange() {
    let skipEvent = 'YES';
    if (!this.skipEventRef.current.isChecked()) {
      skipEvent = 'NO';
    }
    this.setState({skipEvent});
  }

  /**
   * Handles video progress 30 s
   */
  handleVideoProgress30s() {
    let videoProgress30s = 'NO';
    if (this.vp30sRef.current.isChecked()) {
      videoProgress30s = 'YES';
    }
    this.setState({videoProgress30s});
  }

  /**
   * Handles video progress event
   */
  handleVideoProgressEventChange() {
    let videoProgressEvent = 'NO';
    if (this.videoProgressEventRef.current.isChecked()) {
      videoProgressEvent = 'YES';
    }
    this.setState({videoProgressEvent});
  }

  /**
   * video progress offset 00:00:00 or x%
   * @param {String} value value
   */
  handleVideoProgressEventOffsetChange(value) {
    this.setState({videoProgressEventOffset: value});
  }

  /**
   * Saves vast settings
   * @date   2016-12-20
   */
  handleSaveSettings() {
    const settings = {
      vast_version: this.state.vastVersion,
    };

    if (this.state.vastVersion === '3.0') {
      settings.companion_ads = this.state.companionAds;
      settings.skip_event = this.state.skipEvent;
      settings.skip_offset = this.state.skipOffset;
      if (this.state.skipOffset === 'YES') {
        settings.offset = this.state.offset;
      }
      settings.video_progress_30s = this.state.videoProgress30s;
      settings.video_progress_event = this.state.videoProgressEvent;
      if (this.state.videoProgressEvent === 'YES') {
        settings.video_progress_event_offset = this.state.videoProgressEventOffset;
      }
    }

    Creative.update({
      accountId: ContextStore.routingParams().accountId,
      creativeId: ContextStore.routingParams().creativeId,
      settings,
    });

    this.setState({loading: true, error: {}});
  }

  /**
   * Renders vast settings form
   * @date   2016-12-19
   * TODO: Dimmer has been updated, icons should be passed as children
   * @return {Array}   nodes
   */
  render() {
    const compangionAdInfo = `
    <b>all:</b> the video player must attempt to display the contents for all <Companion> elements provided; if
    all Companion creative cannot be displayed, the Ad should be disregarded and the ad server should
    be notified using the <Error> element
    • any: the video player must attempt to display content from at least one of the <Companion>
    elements provided (i.e. display the one with dimensions that best fit the page); if none of the
    Companion creative can be displayed, the Ad should be disregarded and the ad server should be
    notified using the <Error> element
    • none: the video player may choose to not display any of the Companion creative, but is not restricted
    from doing so; the ad server may use this option when the advertiser prefers that the master ad be
    displayed with or without the Companion creative`;
    return (
      <Segment basic attached compact className="vast-settings-form-segment">
        <Grid padded celled two column>
          <Row>
            <Column four wide>
              <h4 className="ui dividing header">Vast version</h4>
              <div className="grouped fields">
                <Field label="Vast 2.0" labelAfterComponent>
                  <Checkbox
                    slider
                    value="2.0"
                    name="vastVersion"
                    radio
                    checked={this.state.vastVersion === '2.0'}
                    onChange={this.handleVastVersionChange}
                  />
                </Field>
                <Field label="Vast 3.0" labelAfterComponent>
                  <Checkbox
                    slider
                    value="3.0"
                    name="vastVersion"
                    radio
                    checked={this.state.vastVersion === '3.0'}
                    onChange={this.handleVastVersionChange}
                  />
                </Field>
              </div>
            </Column>
            <Column twelve wide>
              <Grid className="three columns">
                <Dimmer inverted ref={this.dimmerRef}>
                  <span style={{position: 'relative', top: '45%'}}>
                    {'Vast 3.0 or higher is required for these settings'}
                  </span>
                </Dimmer>
                <Column>
                  <h4 className="ui dividing header">
                    {'Companion ad settings'}
                    <span data-tooltip={compangionAdInfo} data-variation="basic" data-position="bottom center">
                      <i className="info circle icon" />
                    </span>
                  </h4>
                  <div className="inline fields">
                    <Field label="All" labelAfterComponent>
                      <Checkbox
                        slider
                        value="all"
                        name="CompanionAds"
                        radio
                        checked={this.state.companionAds === 'all'}
                        onChange={this.handleCompanionChange}
                      />
                    </Field>
                    <Field label="Any" labelAfterComponent>
                      <Checkbox
                        slider
                        value="any"
                        name="CompanionAds"
                        radio
                        checked={this.state.companionAds === 'any'}
                        onChange={this.handleCompanionChange}
                      />
                    </Field>
                    <Field label="None" labelAfterComponent>
                      <Checkbox
                        slider
                        value="none"
                        name="CompanionAds"
                        radio
                        checked={this.state.companionAds === 'none'}
                        onChange={this.handleCompanionChange}
                      />
                    </Field>
                  </div>
                </Column>
                <Column>
                  <h4 className="ui dividing header">Skip settings</h4>
                  <Field label="Skip Button" labelAfterComponent>
                    <Checkbox
                      toggle
                      ref={this.skipButtonRef}
                      onChange={this.handleSkipButtonChange}
                      checked={this.state.skipOffset !== 'NO'}
                    />
                  </Field>
                  <div style={{marginTop: '10px'}}>
                    <Field>
                      <span data-tooltip="HH:MM:SS or X%" data-position="bottom center">
                        <Input
                          transparent
                          left
                          icon
                          disabled={this.state.skipOffset === 'NO'}
                          onChange={this.handleSkipOffsetChange}
                          value={this.state.skipOffset === 'NO' ? 'HH:MM:SS or X%' : this.state.offset}
                        >
                          <i className="clock icon" />
                        </Input>
                      </span>
                    </Field>
                  </div>
                </Column>
                <Column>
                  <h4 className="ui dividing header">Events</h4>
                  <Field label="Skip event" labelAfterComponent>
                    <Checkbox
                      toggle
                      ref={this.skipEventRef}
                      onChange={this.handleSkipEventChange}
                      checked={this.state.skipEvent !== 'NO'}
                    />
                  </Field>
                  <Field label="Video progress 30 sec" labelAfterComponent>
                    <Checkbox
                      toggle
                      ref={this.vp30sRef}
                      onChange={this.handleVideoProgress30s}
                      checked={this.state.videoProgress30s !== 'NO'}
                    />
                  </Field>
                  <Field label="Video Progress Event" labelAfterComponent>
                    <Checkbox
                      toggle
                      ref={this.videoProgressEventRef}
                      onChange={this.handleVideoProgressEventChange}
                      checked={this.state.videoProgressEvent !== 'NO'}
                    />
                  </Field>
                  <div style={{marginTop: '10px'}}>
                    <Field>
                      <span data-tooltip="HH:MM:SS or X%" data-position="bottom center">
                        <Input
                          transparent
                          left
                          icon
                          disabled={this.state.videoProgressEvent === 'NO'}
                          onChange={this.handleVideoProgressEventOffsetChange}
                          value={
                            this.state.videoProgressEvent === 'NO'
                              ? 'HH:MM:SS or X%' : this.state.videoProgressEventOffset
                          }
                        >
                          <i className="clock icon" />
                        </Input>
                      </span>
                    </Field>
                  </div>
                </Column>
              </Grid>
            </Column>
          </Row>
          <Button
            fluid
            primary
            submit
            onClick={this.handleSaveSettings}
            loading={this.state.loading}
          >
            {'Save settings'}
          </Button>
        </Grid>
        {
          !isEmptyObject(this.state.error)
          && <Message closeable attached error>{this.state.error.message}</Message>
        }
      </Segment>
    );
  }
}

export default VastSettingsForm;

VastSettingsForm.propTypes = {
  creative: PropTypes.object,
};
