import React from 'react';
import PropTypes from 'prop-types';
import Header from 'components/Header';
import AuthenticationStore from 'stores/AuthenticationStore';
import { ContextStore } from 'stores';
import {parseJWT, isEmptyObject} from 'constants/Utils';
import {Favorite, Country} from 'actions';
import {Grid} from 'weborama-ui-react/Grid';
/**
 * Class PublicWrapper
 */
class PublicWrapper extends React.Component {
  /**
   * Constructor
   */
  constructor(props) {
    super(props);

    const user = AuthenticationStore.getUser();
    const profile = user.token !== '' ? parseJWT(user.token) : {};

    if (!isEmptyObject(profile) && profile.data.country_id === null) profile.data.country_id = -1;

    const activeValue = props.route.name || undefined;

    this.state = {
      token: user.token,
    };

    ContextStore.setActive(activeValue);
    ContextStore.setToken(this.state.token);

  }

  /**
   * Fetch a list of favorites
   */
  componentDidMount() {

    if (this.state.token && this.state.token !== '') {
      ContextStore.setRouter(this.props.router);
      // We need to set router again since PublicWrapper is a new tab
      // and ContextStore is not persisted across different tabs
      Favorite.listFavorites();
      Country.list();
    }
  }

  /**
   * Renders public wrapper
   */
  render() {
    return (
      <span>
        <Grid padded style={{boxShadow: '0px 6px 3px -4px rgba(0,0,0,0.25)'}}>
          <Header />
        </Grid>
        <Grid
          container
          style={{marginBottom: '100px', boxShadow: '0 1px 1px 0 rgba(0,0,0,0.06), 0 2px 5px 0 rgba(0,0,0,0.2)'}}
        >
          {this.props.children}
        </Grid>
      </span>
    );
  }
}

PublicWrapper.propTypes = {
  children: PropTypes.any,
  route: PropTypes.object,
  router: PropTypes.object,
};

export default PublicWrapper;
