import React, { useState } from 'react';
import Dropdown from 'weborama-ui-react/Dropdown';
import Menu from 'weborama-ui-react/Menu';
import Item from 'weborama-ui-react/Item';
import PropTypes from 'prop-types';

/**
 * A button component that accepts an array of classes and displays
 * them as options in a dropdown
 */
function SortByButton(props) {

  const [componentState, setdefaultText] = useState({ defaultText: 'Sort by' });

  const items = props.sortByItems.map(singleItem => {

    const givenClass = singleItem.ascending ? 'arrow up icon' : 'arrow down icon';

    return (
      <Item
        key={singleItem.label + singleItem.ascending + Date.now()}
        onClick={() => { setdefaultText({ defaultText: null }); props.sortFn(singleItem); }}
      >
        <i className={givenClass} />
        {singleItem.label}
      </Item>
    );
  });

  return (
    <Menu nested right>
      <Dropdown
        icon
        selection
      >
        {componentState.defaultText}
        <i className="dropdown icon" />
        <Menu nested right>
          {items}
        </Menu>
      </Dropdown>
    </Menu>
  );

}

export default SortByButton;

SortByButton.propTypes = {
  sortByItems: PropTypes.array,
  sortFn: PropTypes.func,
};
