import PropTypes from 'prop-types';
import React from 'react';
import AuthenticationStore from 'stores/AuthenticationStore.js';
import {User} from 'actions';
import AppConstants from 'constants/AppConstants';
import {Grid, Column} from 'weborama-ui-react/Grid';
import Form, {Field} from 'weborama-ui-react/Form';
import Message from 'weborama-ui-react/Message';
import Button from 'weborama-ui-react/Button';
import Input from 'weborama-ui-react/Input';
import {isEmptyObject} from 'constants/Utils';
import Segment from 'weborama-ui-react/Segment';
import Label from 'weborama-ui-react/Label';
import Divider from 'weborama-ui-react/Divider';
import Icon from 'weborama-ui-react/Icon';

/**
 * Responsible to authenticate the User
*/
class Authentication extends React.Component {

  /**
   * Sets default state and adds eventlisteenrs
   * @date   2016-01-18
   */
  constructor() {
    super();

    this.state = {
      emailAdress: '',
      password: '',
      error: {},
      loading: false,
    };

    this.handleEmailAdressChange = this.handleEmailAdressChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onAuthenticationStoreChange = this.onAuthenticationStoreChange.bind(this);
    this.onAuthenticationStoreError = this.onAuthenticationStoreError.bind(this);
    this.loginUser = this.loginUser.bind(this);

    AuthenticationStore.addChangeListener(this.onAuthenticationStoreChange);
    AuthenticationStore.addErrorListener(this.onAuthenticationStoreError);
  }

  /**
   * Unregister store callback
   */
  componentWillUnmount() {
    AuthenticationStore.removeChangeListener(this.onAuthenticationStoreChange);
    AuthenticationStore.addErrorListener(this.onAuthenticationStoreError);
  }

  /**
   * Handles changes in the authentication data
   * @date   2016-01-14
   */
  onAuthenticationStoreChange(data) {
    if (data.action === AppConstants.USER_LOGIN) {
      const nextPath = (this.props.location.state === null || this.props.location.state === undefined)
        ? '/' : this.props.location.state.nextPathname;
      this.props.router.push(nextPath);
    }
  }

  /**
   * Error callback
   */
  onAuthenticationStoreError(data) {
    if (data.action === AppConstants.USER_LOGIN) {
      this.setState({error: data, loading: false});
    }
  }

  /**
   * Unsubscribe to authentication events
   * @date   2016-01-14
   */
  componentWillUnmout() {
    AuthenticationStore.removeChangeListener(this.onAuthenticationStoreChange);
    AuthenticationStore.removeErrorListener(this.onAuthenticationStoreError);
  }

  /**
   * Email adress change
   * @param {string} value value
   */
  handleEmailAdressChange(value) {
    this.setState({emailAdress: value});
  }

  /**
   * Password change
   * @param {String} value value
   */
  handlePasswordChange(value) {
    this.setState({password: value});
  }

  /**
   * Handles form submit
   * @date   2016-01-14
   * @param  {object}   e [event]
   */
  handleSubmit() {
    this.loginUser();
  }

  /**
   * Log in user
   */
  loginUser() {
    User.login({
      emailAdress: this.state.emailAdress,
      password: this.state.password,
    });
    this.setState({loading: true, error: {} });
  }

  /**
   * Renders authentication component
   * @date   2016-01-18
   * @return {Jsx}   Authentication component
   */
  render() {
    const logoStyle = {
      marginTop: '-2px',
      width: '252px',
    };
    const segmentStyle = {
      marginTop: '150px',
      width: '450px',
      marginLeft: 'auto',
      marginRight: 'auto',
      boxShadow: '0 1px 1px 0 rgba(0,0,0,0.06), 0 2px 5px 0 rgba(0,0,0,0.2)',
    };
    return (
      <Grid one column centered container>
        <Column twelve wide>
          <Segment attached style={segmentStyle}>
            <Label top attached inverted black>
              <div style={logoStyle}>
                <svg viewBox="0 0 190 28.54">
                  <g style={{fill: '#c1002a'}} id="weborama_w" data-name="weborama w">
                    {/* eslint-disable-next-line */}
                    <path d="M54,939.75a2.56,2.56,0,0,1-2.23,1.54H46.27a2.54,2.54,0,0,1-2.21-1.55l-2.4-6.5c-0.31-.85-0.81-0.85-1.12,0l-2.29,6.48a2.5,2.5,0,0,1-2.19,1.55H30.52a2.61,2.61,0,0,1-2.25-1.53l-5.75-14.6a1,1,0,0,1,1-1.53h3.88a2.42,2.42,0,0,1,2.15,1.57l3.13,9.67c0.28,0.86.77,0.87,1.08,0l3.64-9.71a2.56,2.56,0,0,1,2.22-1.54h2.8a2.55,2.55,0,0,1,2.22,1.55l3.61,9.71c0.32,0.85.8,0.84,1.07,0l3.05-9.66a2.4,2.4,0,0,1,2.14-1.57h3.88a1.06,1.06,0,0,1,1.06,1.54Z" transform="translate(-22.41 -918.3)" />
                  </g>
                </svg>
              </div>
              <span style={{position: 'absolute', top: 24, left: 72}}>
                {'Sign in to your account'}
              </span>
            </Label>
            <Divider hidden />
            <div style={{marginTop: '50px'}}>
              <Form onSubmit={this.handleSubmit}>
                <Field>
                  <Input
                    onChange={this.handleEmailAdressChange}
                    placeHolder="Email adress"
                    left
                    icon
                  ><Icon at />
                  </Input>
                </Field>
                <Field>
                  <Input
                    onChange={this.handlePasswordChange}
                    placeHolder="Password"
                    type="password"
                    left
                    icon
                  ><Icon lock />
                  </Input>
                </Field>
                <Button submit primary fluid loading={this.state.loading}>
                  {'Login'}
                </Button>
                {!isEmptyObject(this.state.error)
                  && (
                  <Message medium negative header="Sorry! we can't log you in...">
                    {this.state.error.message}
                  </Message>
                  )
                }
              </Form>
            </div>
          </Segment>
        </Column>
      </Grid>
    );
  }
}

Authentication.propTypes = {
  location: PropTypes.object,
  router: PropTypes.object.isRequired,
};

export default Authentication;
