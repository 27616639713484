import React from 'react';
import AccountActions from 'actions/AccountActions';
import { Country } from 'actions';
import {
  AccountStore,
  CountryStore,
  ContextStore,
  AuthenticationStore,
} from 'stores';
import AppConstants from 'constants/AppConstants';
import {
  getLocationDescriptor,
  getObjectInArray,
  sortFnGenerator,
  parseJWT,
} from 'constants/Utils';
import Segment from 'weborama-ui-react/Segment';
import Paginator from 'components/Paginator';
import Dimmer from 'weborama-ui-react/Dimmer';
import Loader from 'weborama-ui-react/Loader';
import Input from 'weborama-ui-react/Input';
import Table from 'weborama-ui-react/Table';
import Header from 'weborama-ui-react/Header';
import Icon from 'weborama-ui-react/Icon';
import Button from 'weborama-ui-react/Button';
import { Grid, Column } from 'weborama-ui-react/Grid';
import SortByButton from 'components/SortByButton';
import SortByItem from 'components/SortByButton/SortByItem';
import CountryDropdown from 'components/CountryDropdown';
import './AccountSelector.scss';

/**
 * Accountselector is responsible for showing a list of accounts
 */
class AccountSelector extends React.Component {
  constructor() {
    super();

    this.state = {
      accounts: [],
      // recentAccounts: [],
      countryId: -1,
      filter: '',
      countries: [],
      maxListItems: 12,
      user: parseJWT(AuthenticationStore.getJWToken()).data
        ? parseJWT(AuthenticationStore.getJWToken()).data.user
        : {}, // By this point, we should be authenticated!
      paginator: {
        page: 1,
      },
      oldContextProfile: undefined,
      sortByItems: [
        new SortByItem('Name', true),
        new SortByItem('Account id', true),
        new SortByItem('Creation date', true),
        new SortByItem('Update date', true),
      ],
      clearCacheLoading: false,
    };

    this.onAccountStoreChange = this.onAccountStoreChange.bind(this);
    this.handleSelectAccount = this.handleSelectAccount.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.onCountryStoreChange = this.onCountryStoreChange.bind(this);
    this.setCountryId = this.setCountryId.bind(this);
    this.sortAccounts = this.sortAccounts.bind(this);

    AccountStore.addChangeListener(this.onAccountStoreChange);
    AccountStore.addErrorListener(this.onAccountStoreError);
    CountryStore.addChangeListener(this.onCountryStoreChange);
  }

  componentDidMount() {
    Country.list();
    AccountActions.listAccounts();
    this.setCountryId();
    // AccountActions.listRecentAccounts();
  }

  componentDidUpdate() {
    this.setCountryId();
  }

  /**
   * Removes event listener on unmount
   * @date   2016-01-14
   */
  componentWillUnmount() {
    AccountStore.removeChangeListener(this.onAccountStoreChange);
    CountryStore.removeChangeListener(this.onCountryStoreChange);
  }

  /**
   * Sorts accounts based on information provided by parameter
   * @param {Class} clickedItem
   */
  sortAccounts(clickedItem) {
    const { sortByItems } = this.state;
    const { accounts } = this.state;

    if (clickedItem.label.toLowerCase() === 'name') {
      accounts.sort(sortFnGenerator('label', clickedItem.ascending));
    }

    if (clickedItem.label.toLowerCase() === 'account id') {
      accounts.sort(sortFnGenerator('id', clickedItem.ascending));
    }

    if (clickedItem.label.toLowerCase() === 'creation date') {
      accounts.sort(sortFnGenerator('created_at', clickedItem.ascending));
    }

    if (clickedItem.label.toLowerCase() === 'update date') {
      accounts.sort(sortFnGenerator('updated_at', clickedItem.ascending));
    }

    this.setState({ accounts: accounts });

    // Find the sortByItem used for sorting and invert its acending value
    for (let i = 0; i < sortByItems.length; i++) {
      if (sortByItems[i].label === clickedItem.label) {
        sortByItems[i].setAscending(!clickedItem.ascending);
        this.setState({ sortByItems });
        break;
      }
    }
  }

  setCountryId() {
    if (
      JSON.stringify(ContextStore.profile())
      !== JSON.stringify(this.state.oldContextProfile)
    ) {
      if (ContextStore.profile().data.user.country_id !== null) {
        this.setState({
          countryId: parseInt(ContextStore.profile().data.user.country_id, 10),
          oldContextProfile: ContextStore.profile(),
        });
      }
    }
  }

  /**
   * Country store callback
   */
  onCountryStoreChange() {
    this.setState({ countries: CountryStore.getCountries() });
  }

  /**
   * Handles changes in the account data
   * and resets the state of the component
   * @param  {Object}   data data
   */
  onAccountStoreChange(data) {
    if (data.action === AppConstants.LIST_ACCOUNTS) {
      const accounts = AccountStore.getAccounts();
      accounts.sort(sortFnGenerator('id', true));
      this.setState({ accounts });
    } else if (data.action === AppConstants.LIST_RECENT_ACCOUNTS) {
      // this.setState({recentAccounts: AccountStore.getRecentAccounts()});
    }
  }

  /**
   * When an error happens at account store
   * @param {Object} data
   */
  onAccountStoreError(data) {
    if (data.action === AppConstants.CLEAR_ALL_CACHE) {
      this.setState({ clearCacheLoading: false });
    }
  }

  /**
   * Filters accounts based on selected country id
   * @return {Array}   Filtered account list
   */
  getFilteredAccounts() {
    const accounts = this.state.accounts.filter((account) => {
      if (
        parseInt(this.state.countryId, 10) === -1
        || account.country_id === this.state.countryId
      ) {
        if (
          account.label.toLowerCase().indexOf(this.state.filter) > -1
          || account.id.toString().indexOf(this.state.filter) > -1
        ) {
          return account;
        }
      }
      return false;
    });
    return accounts;
  }
  /**
   * Send user to a selected account
   * @param  {Number}   id Id
   */
  handleSelectAccount(id) {
    if (
      parseInt(id, 10) !== parseInt(ContextStore.routingParams().accountId, 10)
    ) {
      ContextStore.router().push(getLocationDescriptor('account', id, true));
    }
  }

  /**
   * settings account filter
   * @param {String} value country cody
   */
  handleCountryChange(value) {
    const { filter } = this.state;
    filter.countryId = value;
    this.setState({ filter });
  }

  render() {
    // const recentAccounts = this.state.recentAccounts.map((account) => {
    //   const country = getObjectInArray(this.state.countries, 'id', account.country_id);
    //   if (country) {
    //     return (
    //       <ListItem key={account.id} id={account.id} onClick={this.handleSelectAccount} className="cursor-pointer">
    //         <i className={`${country.code2.toLowerCase()} flag`} />
    //         {account.label}
    //       </ListItem>
    //     );
    //   }
    //   return false;
    // });

    let filteredAccounts = this.getFilteredAccounts();

    /*
    calculate paginator settings
    */
    const paginatorSettings = {
      totalPages: Math.ceil(filteredAccounts.length / this.state.maxListItems),
      page: this.state.paginator.page,
    };

    filteredAccounts = filteredAccounts.slice(
      (0 + (this.state.paginator.page - 1)) * this.state.maxListItems,
      (0 + (this.state.paginator.page - 1)) * this.state.maxListItems
        + this.state.maxListItems
    );
    const accounts = filteredAccounts.map((account) => {
      const country = getObjectInArray(
        this.state.countries,
        'id',
        account.country_id
      );
      return (
        <tr key={account.id}>
          <td className="single lint">
            <a
              className="cursor-pointer"
              onClick={() => {
                this.handleSelectAccount(account.id);
              }}
            >
              {country && (
                <i
                  className={country && `${country.code2.toLowerCase()} flag`}
                />
              )}
              {`${account.id} - ${account.label}`}
            </a>
          </td>
        </tr>
      );
    });

    // const recentAccounts = this.state.recentAccounts.map((account) => {
    //   const country = getObjectInArray(this.state.countries, 'id', account.country_id);
    //   return (
    //     <tr key={`rec-${account.id}`}>
    //       <td className="single lint">
    //         <a className="cursor-pointer" onClick={() => { this.handleSelectAccount(account.id); }}>
    //           {country && <i className={`${country.code2.toLowerCase()} flag`} />}
    //           {account.label}
    //         </a>
    //       </td>
    //     </tr>
    //   );
    // });
    return (
      <Grid one column container>
        <Column className="accountselector-margin-adjust">
          <Grid>
            <Column five wide>
              <CountryDropdown
                eight
                fluid
                search
                selection
                onSelect={(countryId) => {
                  this.setState({ countryId: parseInt(countryId, 10) });
                }}
                selectedId={this.state.countryId}
              />
            </Column>
            <Column three wide>
              {this.state.user.is_webo_admin
                && this.state.user.is_webo_admin.toLowerCase() === 'yes' && (
                  <Button
                    primary
                    type="button"
                    onClick={() => {
                      this.setState({ clearCacheLoading: true }, () => {
                        AccountActions.clearAllCache();
                      });
                    }}
                  >
                    Clear all cache
                  </Button>
              )}
            </Column>
            <Column eight wide right aligned>
              <div style={{ display: 'inline-block', paddingRight: '16px' }}>
                <SortByButton
                  sortByItems={this.state.sortByItems}
                  sortFn={this.sortAccounts}
                />
              </div>
              <div style={{ display: 'inline-block' }}>
                <Input
                  left
                  icon
                  placeHolder="Filter accounts"
                  onChange={(filter) => {
                    this.setState({ filter: filter.toLowerCase() });
                  }}
                >
                  <Icon filter />
                </Input>
              </div>
            </Column>
          </Grid>
          <Segment padded>
            <Header h2>
              <div className="content">
                {'Accounts'}
                <div className="sub header">
                  {`Found ${filteredAccounts.length} of ${this.state.accounts.length} accounts`}
                </div>
              </div>
            </Header>
            {this.state.clearCacheLoading && (
              <Dimmer inverted active>
                <Loader inverted indeterminate text>
                  {'Clearing cache...'}
                </Loader>
              </Dimmer>
            )}
            <Table very basic selectable>
              <tbody>{accounts}</tbody>
              <tfoot>
                <tr>
                  <th className="center aligned">
                    <Paginator
                      totalPages={paginatorSettings.totalPages}
                      page={paginatorSettings.page}
                      onPaginationChange={(page) => {
                        this.setState({ paginator: { page } });
                      }}
                    />
                  </th>
                </tr>
              </tfoot>
            </Table>
          </Segment>
        </Column>
      </Grid>
    );
  }
}

export default AccountSelector;
