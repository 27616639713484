import React from 'react';
import PropTypes from 'prop-types';
import List from 'weborama-ui-react/List';
import Item from 'weborama-ui-react/Item';

const ExpandableList = ((props) => {
  const items = props.items.map((item) => {
    let icon = (props.expanded && props.expanded === parseInt(item.id, 10)) ? 'minus square icon cursor-pointer' : 'add square icon cursor-pointer'; // eslint-disable-line max-len
    if (props.loading && props.expanded && props.expanded === parseInt(item.id, 10)) {
      icon = 'notched circle loading icon';
    }
    return (
      <Item
        key={item.id}
        onClick={(e) => { e.preventDefault(); e.stopPropagation(); props.onExpandCollapse(item); }}
        className="cursor-pointer"
        // active={props.expanded && props.expanded === parseInt(item.id, 10)}
      >
        <span>
          <i className={icon} style={{float: 'left'}} />
          <div className="header">
            {item.label}
          </div>
        </span>
        <div className="">
          {props.expanded === parseInt(item.id, 10)
            && props.children
          }
        </div>
      </Item>
    );
  });
  return (
    <List nested={props.nested} selection divided relaxed>
      {items}
    </List>
  );
});

export default ExpandableList;

ExpandableList.propTypes = {
  onExpandCollapse: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
  expanded: PropTypes.oneOfType([ // eslint-disable-line react/no-unused-prop-types
    PropTypes.number,
    PropTypes.bool,
  ]),
  items: PropTypes.array,
  children: PropTypes.any,
  loading: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  // mini: PropTypes.bool,
  // tiny: PropTypes.bool,
  // small: PropTypes.bool,
  nested: PropTypes.bool,
};
