import React from 'react';
import PropTypes from 'prop-types';
import InsertionClickWrapper from 'components/InsertionClickWrapper';
import ExpandCollapseButton from 'components/ExpandCollapseButton';

const InsertionClickRow = props => (
  <tr className="right aligned" key={props.insertion.id}>
    <td className="center aligned">I</td>
    <td className="left aligned single line" style={{paddingLeft: '30px'}}>
      <ExpandCollapseButton
        onClick={() => props.expandCollapse(props.insertion.id)}
        isExpanded={!!props.isExpanded}
        loading={props.isExpanded === 'loading'}
      />
      {props.insertion.label}
    </td>
    <td className={props.error ? 'left aligned error' : 'left aligned'}>
      <InsertionClickWrapper insertion={props.insertion} />
      {props.error && (<p><i>{props.error.message}</i></p>)}
    </td>
  </tr>
);

export default InsertionClickRow;

InsertionClickRow.propTypes = {
  insertion: PropTypes.object,
  error: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
  expandCollapse: PropTypes.func,
  isExpanded: PropTypes.oneOfType([ // ugly
    PropTypes.string,
    PropTypes.bool,
  ]),
};
