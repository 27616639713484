import React from 'react';
import {Grid, Column} from 'weborama-ui-react/Grid';
import Button from 'weborama-ui-react/Button';
import Header from 'weborama-ui-react/Header';
import UIActions from 'actions/UIActions';
import Icon from 'weborama-ui-react/Icon';

const Footer = () => (
  <Grid
    one
    column
    relaxed
    padded
  >
    <Column>
      <Button
        right
        floated
        basic
        icon
        toolTip="Report a bug"
        toolTipPosition="top right"
        onClick={UIActions.reportBug}
      >
        <Icon bug />
      </Button>
      <Header left floated h5>
        <div className="sub header">
          <Icon copyright />
          {`${new Date().getFullYear()} Admire II Powered By Weborama, version: ${process.env.VERSION}`}
        </div>
      </Header>
    </Column>
  </Grid>
);

export default Footer;
