import React from 'react';
import PropTypes from 'prop-types';
import Table from 'weborama-ui-react/Table';
import Menu from 'weborama-ui-react/Menu';
import Filter from 'components/Filter';
import Button, {Buttons} from 'weborama-ui-react/Button';
import { CountryStore } from 'stores';
import Paginator from 'components/Paginator';
import {UIActions} from 'actions';
import {getObjectInArray} from 'constants/Utils';

class AdSpaceTable extends React.Component {
  static applyFilter(array, filter) {
    const result = array.filter((item) => {
      if (item.label.toLowerCase().indexOf(filter) > -1 || item.id.indexOf(filter) > -1) {
        return item;
      }
      return false;
    });
    return result;
  }

  /**
   * Constructor
   */
  constructor(props) {
    super();

    this.state = {
      paginator: {
        page: 1,
      },
      adSpaces: props.adSpaces,
      filteredAdSpaces: props.adSpaces,
      maxListItems: 10,
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.evaluatePropsUpdate = this.evaluatePropsUpdate.bind(this);
  }

  componentDidMount() {
    this.evaluatePropsUpdate();
  }

  componentDidUpdate() {
    this.evaluatePropsUpdate();
  }

  evaluatePropsUpdate() {
    if (JSON.stringify(this.props.adSpaces) !== JSON.stringify(this.state.adSpaces)) {
      this.setState({
        adSpaces: this.props.adSpaces,
        paginator: {page: 1},
        filteredAdSpaces: this.props.adSpaces,
      });
    }
  }

  /**
   * handles filter change
   * @param {Object} e Proxy event object
   */
  handleFilterChange(e) {
    const filter = e.target.value.toLowerCase();
    const filteredAdSpaces = AdSpaceTable.applyFilter(this.props.adSpaces, filter);
    this.setState({filteredAdSpaces});
  }

  /**
   * Renders Ad Space Table
   */
  render() {
    /*
    calculate paginator settings
    */
    const paginatorSettings = {
      totalPages: Math.ceil(this.state.filteredAdSpaces.length / this.state.maxListItems),
      page: this.state.paginator.page,
    };
    const adSpaces = this.state.filteredAdSpaces.slice(
      (0 + (this.state.paginator.page - 1)) * this.state.maxListItems,
      (((0 + (this.state.paginator.page - 1)) * this.state.maxListItems) + this.state.maxListItems),
    );
    const adSpaceRows = adSpaces.map((adSpace) => {
      const country = getObjectInArray(CountryStore.getCountries(), 'id', adSpace.country_id);
      return (
        <tr
          key={adSpace.id}
        >
          <td style={{paddingLeft: '1em', paddingRight: '1em'}} className="left aligned single line">
            {country && <i className={`${country.code2.toLowerCase()} flag`} />}
            {adSpace.label}
            <Buttons icon right floated mini basic>
              <Button icon basic onClick={() => { UIActions.editAdSpace({adSpace, adNetwork: this.props.adNetwork}); }}>
                <i className="edit icon" />
              </Button>
              <Button icon basic onClick={() => { UIActions.removeAdSpace({adSpace}); }}>
                <i className="trash icon" />
              </Button>
            </Buttons>
          </td>
        </tr>
      );
    });
    return (
      <Table
        small
        very
        basic
        celled
        attached
      >
        <thead className="full-width">
          <tr>
            <th>
              <Menu secondary>
                <div
                  className="ui icon item cursor-pointer"
                  onClick={() => { UIActions.addAdSpace({adNetwork: this.props.adNetwork}); }}
                >
                  <i className="add icon" />
                </div>
                <Filter transparent onChange={this.handleFilterChange} />
              </Menu>
            </th>
          </tr>
        </thead>
        <tbody>
          {adSpaceRows}
        </tbody>
        <tfoot>
          <tr>
            <td>
              <Paginator
                totalPages={paginatorSettings.totalPages}
                page={paginatorSettings.page}
                onPaginationChange={(page) => { this.setState({paginator: {page}}); }}
              />
            </td>
          </tr>
        </tfoot>
      </Table>
    );
  }
}

export default AdSpaceTable;

AdSpaceTable.propTypes = {
  adSpaces: PropTypes.array,
  adNetwork: PropTypes.object,
};
