import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'weborama-ui-react/Dropdown';
import { CountryStore } from 'stores';
import Item from 'weborama-ui-react/Item';
import Menu from 'weborama-ui-react/Menu';
import Icon from 'weborama-ui-react/Icon';
import Flag from 'weborama-ui-react/Flag';
import './CountryDropdown.scss';

const CountryDropdown = ((props) => {
  let countryItems;
  if (CountryStore.getCountries()) {
    countryItems = CountryStore.getCountries().map((country) => {
      const flagCode = {[country.code2.toLowerCase()]: true};
      return (
        <Item key={country.id} value={country.id}>
          <Flag {...flagCode} />
          {!props.showFlagsOnly && country.label}
        </Item>
      );
    });
    countryItems.unshift((
      <Item key={-1} value={-1}>
        <Icon world />
        {!props.showFlagsOnly && 'All'}
      </Item>
    ));
  }

  return (
    <Dropdown
      className="cursor-fix display-adjustment"
      defaultValue={props.selectedId}
      search
      label
      disabled={props.disabled}
      onChange={props.onSelect}
    >
      <Icon dropdown />
      <Menu>
        {countryItems}
      </Menu>
    </Dropdown>
  );
});

export default CountryDropdown;

CountryDropdown.propTypes = {
  onSelect: PropTypes.func,
  showFlagsOnly: PropTypes.bool,
  selectedId: PropTypes.number,
  disabled: PropTypes.bool,
};
