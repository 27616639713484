import React from 'react';
import PropTypes from 'prop-types';
import MonacoEditor from 'react-monaco-editor';
import './monaco.sass';
/**
 * Monaco editor
 */
class Monaco extends React.Component {
  /**
   * Constructor
   */
  constructor(props) {
    super(props);

    this.state = {
      code: props.code,
      readOnly: !!props.readOnly,
      width: props.width || '100%',
      height: props.height || '700',
    };
    this.onChange = this.onChange.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  componentDidMount() {
    this.updateState();
  }

  componentDidUpdate() {
    this.updateState();
  }

  updateState() {
    // We can also use getDerivedStateFromProps here as well. It would also be a more elegant solution.
    if (this.props.code !== this.state.code) {
      this.setState({code: this.props.code});
    }
    if (this.props.readOnly !== this.state.readOnly) {
      this.setState({readOnly: this.props.readOnly});
    }
  }

  /**
   * Handles changes
   * @param {String} code new value
   */
  onChange(code) {
    this.setState({code});
    if (this.props.onChange) {
      this.props.onChange(code);
    }
  }

  /**
   * Renders monaco editor
   */
  render() {
    const options = {
      selectOnLineNumbers: true,
      scrollBeyondLastLine: false,
      experimentalScreenReader: false,
      readOnly: this.state.readOnly,
      fontSize: '10px',
      theme: this.props.theme,
      contextmenu: (this.props.contextMenu !== undefined) ? this.props.contextMenu : true,
      minimap: {enabled: false},
      // scrollbar: true,
    };
    return (
      <div>
        {this.state.readOnly
          && (
          <MonacoEditor
            width={this.state.width}
            height={this.state.height}
            language={this.props.language}
            value={this.state.code}
            options={options}
            onChange={this.onChange}
          />
          )
        }
        {!this.state.readOnly
          && (
          <MonacoEditor
            width={this.state.width}
            height={this.state.height}
            language={this.props.language}
            value={this.state.code}
            options={options}
            onChange={this.onChange}
          />
          )
        }
      </div>
    );
  }
}

export default Monaco;

Monaco.propTypes = {
  code: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  theme: PropTypes.string,
  language: PropTypes.string,
  contextMenu: PropTypes.bool,
};
