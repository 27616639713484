import React from 'react';
import PropTypes from 'prop-types';
import Table from 'weborama-ui-react/Table';
import Flag from 'weborama-ui-react/Flag';
import Button from 'weborama-ui-react/Button';
import Icon from 'weborama-ui-react/Icon';
import {getObjectInArray, getLocationDescriptor} from 'constants/Utils';
import { ContextStore } from 'stores';

const LinkedAdSpaceTable = ((props) => {
  const country = getObjectInArray(props.countries, 'id', props.adSpace.country_id);
  const flagCode = {[country.code2.toLowerCase()]: true};
  const insertions = props.insertions.map((insertion) => {
    const project = getObjectInArray(props.projects, 'id', insertion.project_id);
    const campaign = getObjectInArray(props.campaigns, 'id', insertion.campaign_id);
    return (
      <tr key={insertion.id}>
        <td className="collapsing">
          <span
            data-tooltip="Unlink insertion"
            data-position="left center"
            data-inverted
          >
            <Button
              icon
              loading={props.loadingInsertion.id === insertion.id}
              onClick={() => { props.onUnAssign(insertion); }}
            >
              <Icon unlinkify />
            </Button>
          </span>
        </td>
        <td>
          <a onClick={() => {
            const ld = getLocationDescriptor('project', project.id, true);
            ld.pathname = ld.pathname.replace('cl', 'cm');
            ContextStore.router().push(ld);
          }}
          >
            {project.label}
          </a>
          <i className="right angle icon divider" />
          <a onClick={() => {
            let ld = getLocationDescriptor('project', project.id, true);
            ld.pathname = ld.pathname.replace('cl', 'cm');
            ld = getLocationDescriptor('campaign', campaign.id, false, ld.pathname);
            ContextStore.router().push(ld);
          }}
          >
            {campaign.label}
          </a>
          <i className="right angle icon divider" />
          <a onClick={() => {
            let ld = getLocationDescriptor('project', project.id, true);
            ld.pathname = ld.pathname.replace('cl', 'cm');
            ld = getLocationDescriptor('campaign', campaign.id, false, ld.pathname);
            ContextStore.router().push(ld);
          }}
          >
            {insertion.label}
          </a>
        </td>
      </tr>
    );
  });

  return (
    <Table
      small
    >
      <thead>
        <tr>
          <th colSpan="2">
            <Flag {...flagCode} />
            {props.adSpace.label}
          </th>
        </tr>
      </thead>
      <tbody>
        {insertions}
      </tbody>
    </Table>
  );
});

export default LinkedAdSpaceTable;

LinkedAdSpaceTable.propTypes = {
  adSpace: PropTypes.object,
  projects: PropTypes.array,
  campaigns: PropTypes.array,
  insertions: PropTypes.array,
  countries: PropTypes.array,
  onUnAssign: PropTypes.func,
  loadingInsertion: PropTypes.object,
};
