/* eslint react/no-did-update-set-state: 0 */
import PropTypes from 'prop-types';
import React from 'react';
import { AssignStore, ContextStore, InsertionStore } from 'stores';
import {
  Insertion, Campaign, AssignActions, UIActions,
} from 'actions';
import {getObjectInArray, parseComments} from 'constants/Utils';
import Filter from 'components/Filter';
import AppConstants from 'constants/AppConstants';
import Dropdown from 'weborama-ui-react/Dropdown';
import Item from 'weborama-ui-react/Item';
import Dimmer from 'weborama-ui-react/Dimmer';
import Loader from 'weborama-ui-react/Loader';
import Menu from 'weborama-ui-react/Menu';
import AdSpaceWrapperSegment from 'components/AdSpaceWrapperSegment';
import ClickManager from 'components/ClickManager';
import Divider from 'weborama-ui-react/Divider';
import TrackerManager from 'components/TrackerManager';
import Form, {Field} from 'weborama-ui-react/Form';
import Button from 'weborama-ui-react/Button';
import CampaignRow from 'components/CampaignRow';
import Message from 'weborama-ui-react/Message';
import Comment from 'components/UserComment';
import FavoriteButton from 'components/FavoriteButton';
/**
 * Class InsertionManager
 */
class InsertionManager extends React.Component {
  /**
   * constructor
   * @date   2017-01-19
   * @param  {Object}   props Properties
   */
  constructor(props) {
    super(props);

    this.state = {
      insertions: props.insertions || [],
      adSpaces: props.adSpaces || [],
      assignedCreatives: [],
      filter: {
        label: '',
        deliveryType: -1,
        adSpace: -1,
      },
      loading: {
        adSpaces: true,
        insertions: true,
        assignedCreatives: true,
      },
      active: 'insertionManager',
      oldContextParams: { // Will be overriden by new context
        campaignId: undefined,
      }
    };

    this.onAssignStoreChange = this.onAssignStoreChange.bind(this);
    this.onInsertionStoreChange = this.onInsertionStoreChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleAdSpaceFilterChange = this.handleAdSpaceFilterChange.bind(this);
    this.handleDeliveryTypeFilterChange = this.handleDeliveryTypeFilterChange.bind(this);

    AssignStore.addChangeListener(this.onAssignStoreChange);
    InsertionStore.addChangeListener(this.onInsertionStoreChange);
  }

  /**
   * Retrieve a list of InsertionStore
   */
  componentDidMount() {

    if (ContextStore.routingParams().campaignId !== undefined) {
      Campaign.listAdSpaces({
        accountId: ContextStore.routingParams().accountId,
        campaignId: ContextStore.routingParams().campaignId,
      });

      Insertion.list({
        accountId: ContextStore.routingParams().accountId,
        projectId: ContextStore.routingParams().projectId,
        campaignId: ContextStore.routingParams().campaignId,
      });
    }
  }

  /**
   * Fetch a new list of insertions when the url changes
   */
  componentDidUpdate() {
    // TODO: also clear when account switches
    if (ContextStore.routingParams().campaignId !== this.state.oldContextParams.campaignId && ContextStore.routingParams().campaignId !== undefined) {
      const loading = {insertions: true, assignedCreatives: true, adSpaces: true};
      this.setState({insertions: [], adSpaces: [], loading, oldContextParams: ContextStore.routingParams()});
      Campaign.listAdSpaces({
        accountId: ContextStore.routingParams().accountId,
        campaignId: ContextStore.routingParams().campaignId,
      });

      Insertion.list({
        accountId: ContextStore.routingParams().accountId,
        projectId: ContextStore.routingParams().projectId,
        campaignId: ContextStore.routingParams().campaignId,
      });
    } else if (ContextStore.routingParams().campaignId === undefined) {
      this.setState({insertions: [], adSpaces: []});
    }
    // TODO: Fix loading indicator
    if (JSON.stringify(this.props.insertions) !== JSON.stringify(this.state.insertions) || this.state.loading.insertions) {
      const {loading} = this.state;
      loading.insertions = false;
      this.setState({insertions: this.props.insertions, loading});
    }

    if (JSON.stringify(this.props.adSpaces) !== JSON.stringify(this.state.adSpaces)) {
      if (this.props.adSpaces.length > 0 && ContextStore.routingParams().campaignId !== undefined) {
        AssignActions.listAssignedCreatives({
          accountId: ContextStore.routingParams().accountId,
          projectId: ContextStore.routingParams().projectId,
          campaignId: ContextStore.routingParams().campaignId,
        });
      }
      const {loading} = this.state;
      loading.adSpaces = false;
      this.setState({adSpaces: this.props.adSpaces, loading});
    }
  }

  /**
   * Unregister store listeners
   * @date   2016-08-30
   */
  componentWillUnmount() {
    AssignStore.removeChangeListener(this.onAssignStoreChange);
    InsertionStore.removeChangeListener(this.onInsertionStoreChange);
  }

  /**
   * Assign store change callback
   * @date   2016-11-08
   * @param  {Object}   data event data Object
   */
  onAssignStoreChange(data) {
    if (data.action === AppConstants.LIST_ASSIGNED_CREATIVES) {
      const {loading} = this.state;
      loading.assignedCreatives = false;
      this.setState({assignedCreatives: AssignStore.getAssignedCreatives(), loading});
    }
  }

  onInsertionStoreChange(data) {
    if (data.action === AppConstants.ADD_INSERTION || data.action === AppConstants.REMOVE_INSERTION
      || data.action === AppConstants.UPDATE_INSERTION) {
      Insertion.list({
        accountId: ContextStore.routingParams().accountId,
        projectId: ContextStore.routingParams().projectId,
        campaignId: ContextStore.routingParams().campaignId,
      });
    }
  }

  /**
   * returns list of filtered insertionId
   * @date   2016-11-04
   * @return {array}   filtered insertions
   */
  getFilteredInsertions() {
    const insertions = this.getDTFilteredInsertions();
    const filteredInsertions = insertions.filter((insertion) => {
      if (insertion.label.toLowerCase().indexOf(this.state.filter.label) > -1) {
        return insertion;
      }
      return false;
    });
    return filteredInsertions;
  }

  /**
   * Returns a list of filtered insertions by deliveryType
   * @date   2016-11-08
   * @return {array}   insertion list
   */
  getDTFilteredInsertions() {
    if (parseInt(this.state.filter.deliveryType, 10) === -1) {
      return this.state.insertions;
    }
    const filteredInsertions = this.state.insertions.filter((insertion) => {
      if (parseInt(insertion.delivery_format_id, 10) === parseInt(this.state.filter.deliveryType, 10)) {
        return insertion;
      }
      return false;
    });
    return filteredInsertions;
  }

  /**
   * Sets filter
   * @date   2016-11-04
   * @param  {object}   e event object
   */
  handleFilterChange(e) {
    const {filter} = this.state;
    filter.label = e.currentTarget.value.toLowerCase();
    this.setState({filter});
  }

  /**
   * Handles ad space filter change
   * @date   2016-11-08
   * @param  {Number}   value ad space id
   */
  handleAdSpaceFilterChange(value) {
    const {filter} = this.state;
    filter.adSpace = value;
    this.setState({filter});
  }

  /**
   * Handles delivery type filter changes
   * @date   2016-11-08
   * @param  {Number}   value delivery type id
   */
  handleDeliveryTypeFilterChange(value) {
    const {filter} = this.state;
    if (value.indexOf('so-') > -1) {
      filter.adSpace = value.split('so-')[1]; // eslint-disable-line prefer-destructuring
    } else {
      filter.deliveryType = value;
    }
    this.setState({filter});
  }

  /**
   * Render
   * @date   2016-08-29
   * @return {jsx}   Render
   */
  render() {
    const adSpaceItems = [<Item value="so--1" key={-1}>Show All</Item>];

    const filteredAdSpaces = this.state.adSpaces.filter((adSpace) => {
      adSpaceItems.push((
        <Item
          value={`so-${adSpace.ad_space_fields.id}`}
          key={adSpace.ad_space_fields.id}
        >
          {adSpace.ad_space_fields.label}
        </Item>
      ));
      if (parseInt(this.state.filter.adSpace, 10) === -1
      || parseInt(adSpace.ad_space_fields.id, 10) === parseInt(this.state.filter.adSpace, 10)) {
        if (getObjectInArray(this.state.insertions, 'ad_space_id', adSpace.ad_space_fields.id)) {
          return adSpace;
        }
      }
      return false;
    });
    return (
      <span>
        <Menu>
          <Item
            blue
            useATag
            onClick={() => { this.setState({active: 'campaign'}); }}
            active={this.state.active === 'campaign'}
          >
            <i className="info icon" />
            {'Campaign'}
          </Item>
          <Item
            blue
            useATag
            onClick={() => { this.setState({active: 'insertionManager'}); }}
            active={this.state.active === 'insertionManager'}
          >
            <i className="linkify icon" />
            {'Insertions'}
          </Item>
          <Item
            blue
            useATag
            onClick={() => { this.setState({active: 'clickManager'}); }}
            active={this.state.active === 'clickManager'}
          >
            <i className="external icon" />
            {'Landing urls'}
          </Item>
          <Item
            blue
            useATag
            onClick={() => { this.setState({active: 'trackerManager'}); }}
            active={this.state.active === 'trackerManager'}
          >
            <i className="external icon" />
            {'Trackers'}
          </Item>
          <Menu nested right>
            <Dropdown item icon isMenu>
              {'Media plan'}
              <i className="dropdown icon" />
              <Menu>
                <Item onClick={this.props.onUploadMediaPlan}>
                  <i className="upload icon" />
                  {'Import media plan'}
                </Item>
                <Item onClick={this.props.onDownloadMediaPlan}>
                  <i className="external icon" />
                  {'Export media plan'}
                </Item>
                <Item onClick={this.props.onDownloadMediaPlanTemplate}>
                  <i className="download icon" />
                  {'Download template'}
                </Item>
              </Menu>
            </Dropdown>
            <Dropdown item icon isMenu>
              {'Tags'}
              <i className="dropdown icon" />
              <Menu>
                <Item onClick={this.props.onGenerateTags}>
                  <i className="globe icon" />
                  {'Public tag page'}
                </Item>
                <Item>
                  <i className="file text outline icon" />
                  {'Download all (.txt)'}
                </Item>
              </Menu>
            </Dropdown>
            <Menu nested right>
              <FavoriteButton isMenuItem toolTipPosition="bottom right" />
            </Menu>
          </Menu>
        </Menu>
        <Divider hidden />
        {this.props.campaign.comments
          && (
          <Message info tiny>
            <Comment tiny user={parseComments(this.props.campaign.comments).user}>
              {parseComments(this.props.campaign.comments).comment}
            </Comment>
          </Message>
          )
        }
        {this.state.active !== 'campaign'
          && (
          <Form>
            <div className="four fields">
              <Field label="Filter">
                <Filter
                  prompt="Filter insertions"
                  onChange={this.handleFilterChange}
                />
              </Field>
              <Field label="Site/Offers">
                <Dropdown defaultValue="so--1" selection onChange={this.handleDeliveryTypeFilterChange}>
                  <i className="dropdown icon" />
                  <Menu>
                    {adSpaceItems}
                  </Menu>
                </Dropdown>
              </Field>
              <Field label="Delivery type">
                <Dropdown defaultValue={-1} selection onChange={this.handleDeliveryTypeFilterChange}>
                  <i className="dropdown icon" />
                  <Menu>
                    <Item active value={-1}>Show All</Item>
                    <Item value={1}>Redirect</Item>
                    <Item value={2}>Image</Item>
                    <Item value={6}>Vast</Item>
                  </Menu>
                </Dropdown>
              </Field>
              <Field label={'\u00A0'}>
                <Button
                  fluid
                  onClick={() => { UIActions.addInsertion(); }}
                  primary
                >
                  <i className="add icon" />
                  {'Add insertion'}
                </Button>
              </Field>
            </div>
            <Divider hidden />
          </Form>
          )
        }
        {this.state.active === 'campaign'
          && (
          <CampaignRow
            campaign={this.props.campaign}
            channels={this.props.channels}
          />
          )
        }
        {
          this.state.active === 'insertionManager' && (
            this.state.loading.adSpaces
            || this.state.loading.insertions
            || this.state.loading.assignedCreatives
          ) && (
            <div style={{ position: 'relative', height: '300px' }}>
              <Dimmer inverted active>
                <Loader inverted indeterminate text>
                  {'Loading...'}
                </Loader>
              </Dimmer>
            </div>
          )
        }
        {this.state.active === 'insertionManager'
          && (
            !this.state.loading.adSpaces
            && !this.state.loading.insertions
            && !this.state.loading.assignedCreatives
          ) && (
            <AdSpaceWrapperSegment
              loading={this.state.loading}
              insertions={this.getFilteredInsertions()}
              onClick={this.handleSelectInsertion}
              adSpaces={filteredAdSpaces}
              assignedCreatives={this.state.assignedCreatives}
              tokens={this.props.tokens}
            />
        )
        }
        {this.state.active === 'clickManager'
          && (
          <ClickManager
            campaign={this.props.campaign}
            adSpaces={filteredAdSpaces}
            insertions={this.getFilteredInsertions()}
            assignedCreatives={this.state.assignedCreatives}
            insertionsDetails={this.props.insertionsDetails}
          />
          )
        }
        {this.state.active === 'trackerManager'
          && (
          <TrackerManager
            campaign={this.props.campaign}
            insertions={this.getFilteredInsertions()}
            adSpaces={filteredAdSpaces}
            assignedCreatives={this.state.assignedCreatives}
            insertionsDetails={this.props.insertionsDetails}
          />
          )
        }
      </span>
    );
  }
}

export default InsertionManager;

InsertionManager.propTypes = {
  campaign: PropTypes.object,
  insertions: PropTypes.array,
  adSpaces: PropTypes.array,
  channels: PropTypes.array,
  insertionsDetails: PropTypes.array,
  tokens: PropTypes.object,
  onDownloadMediaPlanTemplate: PropTypes.func,
  onDownloadMediaPlan: PropTypes.func,
  onUploadMediaPlan: PropTypes.func,
  onGenerateTags: PropTypes.func,
};
