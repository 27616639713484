import React from 'react';
import Form, {Field} from 'weborama-ui-react/Form';
import Input from 'weborama-ui-react/Input';
import Button from 'weborama-ui-react/Button';
import {Support} from 'actions';
import {SupportStore, ContextStore} from 'stores';
import AppConstants from 'constants/AppConstants';
import Message from 'weborama-ui-react/Message';
import {isEmptyObject} from 'constants/Utils';
/**
 * SupportForm
 */
class SupportForm extends React.Component {
  /**
   * Constructor
   * @param {Object} props Properties
   */
  constructor(props) {
    super(props);

    this.state = {
      profile: ContextStore.profile() || undefined,
      url: window.location.href,
      userAgent: window.navigator.userAgent,
      title: undefined,
      description: undefined,
      loading: false,
      error: {},
      oldContextProfile: undefined,
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.onSupportStoreChange = this.onSupportStoreChange.bind(this);
    this.onSupportStoreError = this.onSupportStoreError.bind(this);

    SupportStore.addChangeListener(this.onSupportStoreChange);
    SupportStore.addErrorListener(this.onSupportStoreError);
  }

  componentDidMount() {

    if (this.state.profile === undefined) {
      this.setState({
        profile: ContextStore.profile(),
        oldContextProfile: ContextStore.profile(),
      });
    }
  }

  componentDidUpdate() {

    if (JSON.stringify(ContextStore.profile()) !== JSON.stringify(this.state.oldContextProfile)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        profile: ContextStore.profile(),
        oldContextProfile: ContextStore.profile(),
      });
    }
  }

  /**
   * Remove store callbacks
   */
  componentWillUnmount() {
    SupportStore.removeChangeListener(this.onSupportStoreChange);
    SupportStore.removeErrorListener(this.onSupportStoreError);
  }

  onSupportStoreChange(data) {
    if (data.action === AppConstants.CREATE_TICKET) {
      this.setState({loading: false});
    }
  }

  onSupportStoreError(data) {
    if (data.action === AppConstants.CREATE_TICKET) {
      this.setState({loading: false, error: data});
    }
  }

  handleTitleChange(title) {
    this.setState({title});
  }

  /**
   * Handles description change
   * @param {Object} e event object
   */
  handleDescriptionChange(e) {
    this.setState({description: e.target.value});
  }

  /**
   * handles form submit
   */
  handleFormSubmit() {
    Support.createTicket({
      user: this.state.profile.data,
      userAgent: this.state.userAgent,
      url: this.state.url,
      title: `(${process.env.VERSION}) - ${this.state.title}`,
      description: this.state.description,
    });
    this.setState({loading: true, error: {}});
  }

  /**
   * Renders bug form
   */
  render() {
    return (
      <span>
        <Form>
          <Field required label="User">
            <Input
              disabled
              value={`${this.state.profile.data.user.firstname} ${this.state.profile.data.user.lastname} <${this.state.profile.data.user.email}>`} // eslint-disable-line max-len
            />
          </Field>
          <Field required label="Url">
            <Input
              disabled
              value={this.state.url}
            />
          </Field>
          <Field required label="Browser">
            <Input
              disabled
              value={this.state.userAgent}
            />
          </Field>
          <Field required label="Title">
            <Input
              focus
              onChange={this.handleTitleChange}
              value={this.state.title}
              placeHolder="Title"
            />
          </Field>
          <Field required label="Description">
            <textarea
              rows="8"
              placeholder="bug description"
              value={this.state.description}
              onChange={this.handleDescriptionChange}
            />
          </Field>
          <Button
            fluid
            submit
            primary
            onClick={this.handleFormSubmit}
            loading={this.state.loading}
          >
            {'Submit'}
          </Button>
        </Form>
        {
          !isEmptyObject(this.state.error) && <Message closeable error>{this.state.error.message}</Message>
        }
      </span>
    );
  }
}

export default SupportForm;
