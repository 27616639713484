import BaseStore from 'stores/BaseStore';
import {Insertion} from 'constants/ServerConstants';
import {register} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';
/**
 * Tag Store
 */
const TagStore = Object.assign({}, BaseStore, {
  tags: [],
  tokens: [],
  /**
   * Returns array with tags
   * @date   2016-05-03
   * @return {Array}   Tags
   */
  getTags() {
    return this.tags;
  },
  getTokens() {
    return this.tokens;
  },
  /**
   * Fetches tag for insertion.
   * @date   2016-05-02
   * @param  {Object}   data   Post parameters
   */
  fetchTags(data) {
    const onSuccess = (response) => {
      this.tags = response.data.slice();
      this.emitChange({action: AppConstants.LIST_TAGS});
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.LIST_TAGS, message});
    };

    this.post({
      url: Insertion.GET_TAGS,
      data,
      onSuccess,
      onError,
    });
  },
  generateTokens(data) {
    const onSuccess = (response) => {
      this.tokens = response.data;
      this.emitChange({action: AppConstants.GENERATE_TOKENS});
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.GENERATE_TOKENS, message});
    };

    this.post({
      url: Insertion.GENERATE_TOKENS,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Register at app dispatcherIndex
   */
  dispatcherIndex: register((action) => {
    let data;
    if (action.actionType === AppConstants.LIST_TAGS) {
      data = {
        account_id: action.accountId,
        project_id: action.projectId,
        campaign_id: action.campaignId,
        id: action.insertionId,
      };
      TagStore.fetchTags(data);
    } else if (action.actionType === AppConstants.GENERATE_TOKENS) {
      data = {
        account_id: action.accountId,
        project_id: action.projectId,
        campaign_id: action.campaignId,
        ids: action.insertionIds,
        insertions: action.insertions,
      };
      TagStore.generateTokens(data);
    }
  }),
});

export default TagStore;
