import React from 'react';
import PropTypes from 'prop-types';
import Comment, {Comments} from 'weborama-ui-react/Comment';

const UserComment = props => (
  <Comments {...props}>
    <Comment>
      <a className="avatar" data-content={props.children} data-title={props.user}>
        <img src="/images/piet.svg" alt="I'm Jenny, your personal assistant" />
      </a>
      <div className="content">
        <a className="author">{props.user}</a>
        <div className="text">
          {props.children}
        </div>
      </div>
    </Comment>
  </Comments>
);

export default UserComment;

UserComment.propTypes = {
  user: PropTypes.string,
  small: PropTypes.bool,
  mini: PropTypes.bool,
  tiny: PropTypes.bool,
  children: PropTypes.any,
};
