export default {
  USER_LOGIN: 'user_login',
  USER_LOGOUT: 'user_logout',
  LIST_ACCOUNTS: 'list_accounts',
  LIST_RECENT_ACCOUNTS: 'list_recent_accounts',
  SWITCH_ACCOUNT: 'switch_account',
  CLEAR_CACHE: 'clear_cache',
  CLEAR_ALL_CACHE: 'clear_all_cache',
  LIST_FOLDERS: 'list_folders',
  ADD_FOLDER: 'add_folder',
  REMOVE_FOLDER: 'remove_folder',
  UPDATE_FOLDER: 'update_folder',
  LIST_NOCREATIVESBYFOLDER: 'list_nocreativesbyfolder',
  LIST_CREATIVES: 'list_creatives',
  GET_CREATIVE: 'get_creative',
  ADD_CREATIVE: 'add_creative',
  DUPLICATE_CREATIVE: 'duplicate_creative',
  REMOVE_CREATIVE: 'remove_creative',
  UPDATE_CREATIVE: 'update_creative',
  LIST_PROJECTS: 'list_projects',
  ADD_PROJECT: 'add_project',
  REMOVE_PROJECT: 'remove_project',
  UPDATE_PROJECT: 'update_project',
  LIST_CAMPAIGNS: 'list_campaigns',
  ADD_CAMPAIGN: 'add_campaign',
  UPDATE_CAMPAIGN: 'update_campaign',
  REMOVE_CAMPAIGN: 'remove_campaign',
  ADD_INSERTION: 'add_insertion',
  LIST_INSERTIONS: 'list_insertion',
  DUPLICATE_INSERTION: 'duplicate_insertion',
  REMOVE_INSERTION: 'remove_insertion',
  UPDATE_INSERTION: 'update_insertion',
  LIST_TAGS: 'list_tags',
  LIST_ADSPACES: 'list_adspaces',
  ADD_ADSPACE: 'add_adspace',
  UPDATE_ADSPACE: 'update_adspace',
  REMOVE_ADSPACE: 'remove_adspace',
  LIST_ADSPACE_PLACEMENTS: 'list_adspace_placements',
  LIST_ADNETWORKS: 'list_adnetworks',
  ADD_ADNETWORK: 'add_adnetwork',
  UPDATE_ADNETWORK: 'update_adnetwork',
  REMOVE_ADNETWORK: 'remove_adnetwork',
  LIST_CREATIVE_TYPES: 'list_creative_types',
  LIST_CREATIVE_FORMATS: 'list_creative_formats',
  LIST_ADPOSITIONS: 'list_adpositions',
  LIST_SIZES: 'list_sizes',
  LIST_ADELEMENTS: 'list_adelements',
  LIST_ADPOSITION_DETAILS: 'list_adelement_details',
  LIST_ADSPACES_FROM_CAMPAIGN: 'list_adspaces_from_campaign',
  UPDATE_CAMPAIGN_ADPSACES: 'update_campaign_adspaces',
  UPLOAD_FILES: 'upload_files',
  UPDATE_ADELEMENT_ROLE: 'update_adelement_role',
  UPDATE_MULTIPLE_ROLE_ADELEMENT: 'update_multiple_role_adelement',
  NEW_ADPOS_WITH_ELEMENTS: 'new_adpos_with_elements',
  NEW_MULTIPLE_ADPOS_WITH_ELEMENTS: 'new_multiple_adpos_with_elements',
  FILE_UPLOAD_PROGRESS: 'file_upload_progress',
  REMOVE_ADELEMENTS: 'remove_adelements',
  ADD_ADPOSITION: 'add_adposition',
  LIST_AD_ELEMENT_ROLES: 'list_ad_element_roles',
  REMOVE_ADPOSITION: 'remove_adposition',
  UPDATE_AD_POSITION: 'update_ad_position',
  LIST_CREATIVE_LABELS: 'list_creative_labels',
  LIST_INSERTION_LABELS: 'list_insertion_labels',
  ADD_CREATIVE_LABEL: 'add_creative_label',
  LIST_NO_INSERTIONS_FOR_CAMPAIGNS: 'list_no_of_insertions_by_campaigns',
  LIST_FILE_TYPES: 'list_file_types',
  ASSIGN_CREATIVE_INSERTION: 'assign_creative_insertion',
  LIST_PLACEMENTS: 'list_placements',
  GET_SETUP_SCRIPT: 'get_setup_script',
  PUSH_LIVE: 'push_live',
  UPDATE_SETUP_SCRIPT: 'update_setup_script',
  LOCK_SETUP_SCRIPT: 'LOCK_SETUP_SCRIPT',
  LIST_CHANNELS: 'list_channels',
  BROWSE_FOLDERS: 'browse_folders',
  BROWSE_CREATIVES: 'browse_creatives',
  BROWSE_PROJECTS: 'browse_projects',
  BROWSE_CAMPAIGNS: 'browse_campaigns',
  BROWSE_INSERTIONS: 'browse_insertions',
  ASSIGN_CREATIVES_INSERTIONS: 'assign_creatives_insertions',
  UNASSIGN_INSERTION: 'unassign_insertion',
  UNASSIGN_CREATIVE: 'unassign_creative',
  GET_PREVIEW_SETUP_SCRIPT: 'get_preview_setup_script',
  GET_MULTI_PREVIEW_SETUP_SCRIPT: 'get_multi_preview_setup_script',
  LIST_ASSIGNED_INSERTIONS: 'list_assigned_insertions',
  LIST_ASSIGNED_CREATIVES: 'list_assigned_creatives',
  SET_DEFAULT_CREATIVE: 'set_default_creative',
  LIST_ASSIGNED_AD_POSITIONS: 'list_assigned_ad_positions',
  LIST_ASSIGNED_AD_POSITIONS_DETAILS: 'list_assigned_ad_positions_details',
  DETAILS_ASSIGNED_AD_POSITIONS: 'details_assigned_ad_positions',
  LIST_LANDING_URL: 'list_landing_url',
  ADD_LANDING_URL: 'add_landing_url',
  ADD_MULTI_LANDING_URL: 'add_multi_landing_url',
  REMOVE_LANDING_URL: 'remove_landing_url',
  UPDATE_LANDING_URL: 'update_landing_url',
  LIST_CAMPAIGN_LABELS: 'list_campaign_labels',
  UPDATE_VAST_SETTINGS: 'update_vast_settings',
  LIST_FAVORITES: 'list_favorites',
  ADD_FAVORITE: 'add_favorite',
  REMOVE_FAVORITE: 'remove_favorite',
  GENERATE_MULTIPLE_TAGS: 'generate_multiple_tags',
  GENERATE_TOKENS: 'generate_tokens',
  LIST_DELIVERY_FORMATS: 'list_delivery_formats',
  LIST_AVAILABLE_DIMENSIONS: 'list_available_dimensions',
  LIST_COUNTRIES: 'list_countries',
  LIST_ADSERVERS: 'list_adservers',
  LIST_THIRD_PARTY_TAGS: 'list_third_party_tags',
  LIST_THIRD_PARTY_TAGS_FOR_CAMPAIGN: 'list_third_party_tags_for_campaign',
  ADD_THIRD_PARTY_TAG: 'add_third_party_tag',
  REMOVE_THIRD_PARTY_TAG: 'remove_third_party_tag',
  UPDATE_THIRD_PARTY_TAG: 'update_third_party_tag',
  DOWNLOAD_CREATIVE: 'download_creative',
  GET_ASSIGNED_AD_POSITION_FROM_INSERTION: 'get_assigned_ad_position_from_insertion',
  UPDATE_ASSIGNED_AD_POSITION: 'update_assigned_ad_position',
  SEARCH_THIRD_PARTY_TAG: 'search_third_party_tag',
  SEARCH_ASSIGNED_ADPOSITION: ' search_assigned_ad_postition',
  LIST_INSERTION_DETAILS: 'list_insertion_details',
  CREATE_TICKET: 'create_ticket',
  SEARCH: 'search',
  SEARCH_MATCHING_INSERTIONS: 'search_matching_insertions',
  DOWNLOAD_MEDIA_PLAN: 'download_media_plan',
  DOWNLOAD_MEDIA_PLAN_TEMPLATE: 'download_media_plan_template',
  UPLOAD_MEDIA_PLAN: 'upload_media_plan',
  GET_STATISTICS: 'get_statistics',
  GET_ACCOUNT: 'get_account',
  ADD_SITE_OFFER: 'add_site_offer',
  ADD_ADNETWORK_SITE_OFFER: 'add_adnetwork_site_offer',
  SELECT_ADELEMENTS: 'select_adelements',
  PUSH_CREATIVES_LIVE: 'push_creatives_live'
};
// TODO: ADPOSITION SHOULD BE AD_POSITION
