import React from 'react';
import PropTypes from 'prop-types';
import Button from 'weborama-ui-react/Button';
import { getLocationDescriptor, getDateFromTimeStamp, parseComments, sortFnGenerator } from 'constants/Utils';
import Input from 'weborama-ui-react/Input';
import { Grid, Column } from 'weborama-ui-react/Grid';
import UIActions from 'actions/UIActions';
import Table from 'weborama-ui-react/Table';
import Segment from 'weborama-ui-react/Segment';
import Paginator from 'components/Paginator';
import Header from 'weborama-ui-react/Header';
import Icon from 'weborama-ui-react/Icon';
import Image from 'weborama-ui-react/Image';
import SortByButton from 'components/SortByButton';
import SortByItem from 'components/SortByButton/SortByItem';
import { ContextStore } from 'stores';

class SelectCampaign extends React.Component {
  /**
   * Constructor
   * @param {Object} props properties
   */
  constructor(props) {
    super(props);

    this.state = {
      filter: '',
      paginator: {
        page: 1,
      },
      campaigns: props.campaigns,
      maxListItems: 12,
      sortByItems: [
        new SortByItem('Name', true),
        new SortByItem('Campaign id', true),
        new SortByItem('Creation date', false),
        new SortByItem('Update date', true),
      ],
      activeSortBy: new SortByItem('Creation date', false), // Default 'sort by' selection
    };

    this.handleSelectCampaign = this.handleSelectCampaign.bind(this);
    this.sortCampaignsOnClick = this.sortCampaignsOnClick.bind(this);
  }

  /**
   * Update state if props change
   */
  componentDidUpdate() {
    if (JSON.stringify(this.props.campaigns) !== JSON.stringify(this.state.campaigns)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ filter: '', paginator: { page: 1 }, campaigns: this.props.campaigns }, () => {
        if (this.state.activeSortBy) {
          this.sortCampaignsUnchanged(this.state.activeSortBy);
        }
      });
    }
  }

  /**
   * Handles selecting creative
   * @param {Number} campaignId campaign id
   */
  handleSelectCampaign(campaignId) {
    const link = getLocationDescriptor('campaign', campaignId, true);
    ContextStore.router().push(link);
    ContextStore.router().push(link); // Pushing it twice will work when getChildContext is removed at App
  }

  /**
   * Perform sorting, then invert ascending/descending
   * @param {Class} sortByItem single SortByItem class with sorting information
   */
  sortCampaignsOnClick(sortByItem) {

    const { sortByItems } = this.state;
    const { campaigns } = this.state;

    this.resolveSortByType(campaigns, sortByItem);

    this.setState({ campaigns });

    // Find the sortByItem used for sorting and invert its acending value
    for (let i = 0; i < sortByItems.length; i++) {
      if (sortByItems[i].label === sortByItem.label) {

        const currentSortByItem = new SortByItem(sortByItems[i].label, sortByItems[i].ascending);

        // Why do we have this variable and why are we assigning it to the activeSortBy?
        // When the array item is modified, the ascending position is set to what it's supposed to be when it's clicked
        // But we want the current version to be stored in the state as activeSortBy!

        // Why are we creating a new class instance here?
        // currentSortByItem = sortByItems[i] is jsut a reference to sortByItems[i]
        // In this state, when we change the ascending value of sortByItems[i], it affects currentSortByItem as well

        sortByItems[i].setAscending(!sortByItem.ascending);
        this.setState({ sortByItems, activeSortBy: currentSortByItem });
        break; // no need to continue the loop if we have what we want
      }
    }
  }

  /**
   * Perform sorting, but don't invert ascending/descending
   * @param {Class} sortByItem single SortByItem class with sorting information
   */
  sortCampaignsUnchanged(sortByItem) {

    const { sortByItems } = this.state;
    const { campaigns } = this.state;

    this.resolveSortByType(campaigns, sortByItem);

    this.setState({ campaigns, sortByItems });
  }

  /**
   * Identifies which parameter is used for sorting and sorts supplied object
   * @param {Object} objectToSort Object to apply sort function to
   * @param {Class} sortByItem single SortByItem class with sorting information
   */
  resolveSortByType(objectToSort, sortByItem) {

    if (sortByItem.label.toLowerCase() === 'name') {
      objectToSort.sort(sortFnGenerator('label', sortByItem.ascending));
    }

    if (sortByItem.label.toLowerCase() === 'campaign id') {
      objectToSort.sort(sortFnGenerator('id', sortByItem.ascending));
    }

    if (sortByItem.label.toLowerCase() === 'creation date') {
      objectToSort.sort(sortFnGenerator('created_at', sortByItem.ascending));
    }

    if (sortByItem.label.toLowerCase() === 'update date') {
      objectToSort.sort(sortFnGenerator('updated_at', sortByItem.ascending));
    }
  }

  render() {

    // Apply filter if it's specified
    let campaigns = this.state.campaigns.filter((campaign) => {
      if (campaign.label.toLowerCase().indexOf(this.state.filter) > -1 || campaign.id.toString().indexOf(this.state.filter) > -1) {
        return campaign;
      }
      return false;
    });

    /*
    calculate paginator settings
    */
    const paginatorSettings = {
      totalPages: Math.ceil(campaigns.length / this.state.maxListItems),
      page: this.state.paginator.page,
    };

    campaigns = campaigns.slice(
      (0 + (this.state.paginator.page - 1)) * this.state.maxListItems,
      (((0 + (this.state.paginator.page - 1)) * this.state.maxListItems) + this.state.maxListItems),
    );

    const campaignInfo = campaigns.map(campaign => (
      <tr key={campaign.id} style={{ position: 'relative' }}>
        <td style={{ overflow: 'visible' }}>
          {campaign.comments
            && (
              <span
                data-tooltip={
                  `${parseComments(campaign.comments).user} - ${parseComments(campaign.comments).comment}`
                }
                data-inverted
                data-position="left center"
              >
                <Image avatar src="/images/piet.svg" />
                {campaign.stage}
              </span>
            )
          }
          {!campaign.comments
            && (
              <span>
                {campaign.stage}
              </span>
            )
          }
        </td>
        <td className="single line">
          <a style={{ cursor: 'pointer' }} onClick={() => this.handleSelectCampaign(campaign.id)}>{campaign.label}</a>
        </td>
        {/* <td>
          {campaign.comments &&
            <Comment tiny user={parseComments(campaign.comments).user}>
              {parseComments(campaign.comments).comment}
            </Comment>
          }
        </td> */}
        <td className="collapsing">{campaign.has_statistics.toLowerCase()}</td>
        <td className="single line">
          {
            campaign.start_date !== null ? getDateFromTimeStamp(new Date(campaign.start_date).getTime()) : 'auto' // eslint-disable-line max-len
          }
        </td>
        <td className="single line">
          {
            campaign.end_date !== null ? getDateFromTimeStamp(new Date(campaign.end_date).getTime()) : 'auto'
          }
        </td>
      </tr>
    ));
    return (
      <div>
        <Header h2>
          Campaigns
        </Header>
        <Grid>
          <Column four wide>
            <Button primary onClick={() => { UIActions.addCampaign({ projectId: ContextStore.routingParams().projectId, projectLabel: '' }); }}>
              <Icon add />
              New Campaign
            </Button>
          </Column>
          <Column twelve wide right aligned>
            <div style={{ display: 'inline-block', paddingLeft: '16px' }}>
              <SortByButton sortByItems={this.state.sortByItems} sortFn={this.sortCampaignsOnClick} />
            </div>
            <div style={{ display: 'inline-block', paddingLeft: '16px' }}>
              <Input
                placeHolder="Filter"
                left
                icon
                onChange={(filter) => { this.setState({ filter: filter.toLowerCase() }); }}
              >
                <Icon filter />
              </Input>
            </div>
          </Column>
        </Grid>
        <Segment padded>
          <Table
            fixed
            single
            line
            very
            basic
            unstackable
            selectable
          >
            <thead>
              <tr>
                <th className="two wide collapsing">Stage</th>
                <th className="eight wide collapsing single line">Name</th>
                {/* <th className="one wide">Comments</th> */}
                <th className="two wide collapsing">Stats</th>
                <th className="two wide collapsing">Start date</th>
                <th className="two wide collapsing">End date</th>
              </tr>
            </thead>
            <tbody>
              {campaignInfo}
            </tbody>
            <tfoot>
              <tr className="right aligned">
                <th className="center aligned" colSpan="4">
                  <Paginator
                    totalPages={paginatorSettings.totalPages}
                    page={paginatorSettings.page}
                    onPaginationChange={(page) => { this.setState({ paginator: { page } }); }}
                  />
                </th>
                <th className="right aligned single line">
                  {`${this.state.campaigns.length} ${this.state.campaigns.length === 1 ? 'campaign' : 'campaigns'}`}
                </th>
              </tr>
            </tfoot>
          </Table>
        </Segment>
      </div>
    );
  }
}

export default SelectCampaign;

SelectCampaign.propTypes = {
  campaigns: PropTypes.array,
};
