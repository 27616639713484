import PropTypes from 'prop-types';
import React from 'react';
import {isEmptyObject} from 'constants/Utils';
import AppConstants from 'constants/AppConstants';
import ProjectStore from 'stores/ProjectStore';
// import CampaignManagerActions from 'actions/CampaignManagerActions';
import Form, {Field} from 'weborama-ui-react/Form';
import Checkbox from 'weborama-ui-react/Checkbox';
import Button from 'weborama-ui-react/Button';
import {projectForm} from 'constants/FormValidation';
import {Project} from 'actions';
import Message from 'weborama-ui-react/Message';
import ContextStore from 'stores/ContextStore';
/**
 * Project form
 */
class ProjectForm extends React.Component {
  /**
   * constructor
   * @date   2016-08-25
   * @param  {object}   props properties
   */
  constructor(props) {
    super(props);

    let label = '';
    let action = 'add';

    if ('project' in props && !isEmptyObject(props.project)) {
      ({label} = props.project);
      action = 'update';
    }

    this.state = {
      loading: false,
      label,
      addCampaign: true,
      action,
      error: {},
      initialProjectForm: { // Only used for form validation
        label: label,
      }
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleAddCampaignChange = this.handleAddCampaignChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onProjectStoreError = this.onProjectStoreError.bind(this);

    ProjectStore.addErrorListener(this.onProjectStoreError);

    this.projectLabelRef = React.createRef();
    this.addCampaignRef = React.createRef();
  }

  componentDidMount() {
    if ('project' in this.props) {
      if (isEmptyObject(this.props.project)) {
        this.setState({
          loading: false, label: '', action: 'add', error: {},
        });
      }
    } else {
      this.setState({
        loading: false,
        label: this.props.project.label,
        action: 'update',
        addCampaign: false,
        error: {},
      });
    }
  }

  /**
   * remove error listener
   * @date   2016-10-17
   */
  componentWillUnmount() {
    ProjectStore.removeErrorListener(this.onProjectStoreError);
  }

  /**
   * Project store error callbacks
   * @date   2016-10-17
   * @param  {object}   error Error event object
   */
  onProjectStoreError(error) {
    if (error.action === AppConstants.ADD_PROJECT || error.action === AppConstants.UPDATE_PROJECT) {
      this.setState({loading: false, error});
    }
  }

  /**
   * Handles updates to the form
   * @date   2016-08-25
   * @param  {object}   e event object
   */
  handleFormChange() {
    this.setState({label: this.projectLabelRef.current.value});
  }

  /**
   * Submits the form
   * @date   2016-07-19
   */
  handleFormSubmit() {
    if (!this.state.loading) {
      if (this.state.action === 'add') {
        Project.add({
          accountId: ContextStore.routingParams().accountId,
          label: this.state.label,
          addCampaign: this.state.addCampaign,
        });
      } else if (this.state.action === 'update') {
        Project.update({
          accountId: ContextStore.routingParams().accountId,
          projectId: this.props.project.id,
          label: this.state.label,
        });
      }
      this.setState({loading: true});
    }
  }

  /**
   * Handles add campaign change
   * @date   2016-10-13
   */
  handleAddCampaignChange() {
    const checked = this.addCampaignRef.current.isChecked();
    this.setState({addCampaign: checked});
  }

  /**
   * returns if a campaign should be added after adding the projectId
   * @date 2016-10-13
   * @return {Boolean}   add campaign after adding the project
   */
  addCampaign() {
    return this.state.addCampaign;
  }

  projectFormChangedAndValid() {

    const currentProjectForm = {
      label: this.state.label,
    };

    if (JSON.stringify(currentProjectForm) !== JSON.stringify(this.state.initialProjectForm)
      && this.state.label.length > 0) {
      return false;
    }
    return true;
  }

  /**
   * Renders project form
   * @date   2016-08-25
   * @return {jsx}   project form
   */
  render() {
    return (
      <span>
        <Form
          onSubmit={this.handleFormSubmit}
          validation={projectForm}
          onValid={this.handleFormValid}
          onInvalid={this.handleFormInvalid}
        >
          <Field required label="Project Name">
            <input
              className="modal input projectname"
              ref={this.projectLabelRef}
              name="label"
              placeholder="Project Name"
              type="text"
              value={this.state.label}
              onChange={this.handleFormChange}
            />
          </Field>
          <Field className={`transition ${this.state.action === 'update' ? 'hidden' : 'visible'}`}>
            <Checkbox
              ref={this.addCampaignRef}
              checked
              onChange={this.handleAddCampaignChange}
            >
              {'Also add campaign'}
            </Checkbox>
          </Field>
          <Field>
            <Button
              fluid
              submit
              primary
              loading={this.state.loading}
              onClick={this.handleFormSubmit}
              disabled={this.projectFormChangedAndValid()}
            >
              {`${this.state.action === 'update' ? 'Update' : 'Submit'}`}
            </Button>
          </Field>
        </Form>
        {
          !isEmptyObject(this.state.error)
          && <Message error>{this.state.error.message}</Message>
        }
      </span>
    );
  }
}

export default ProjectForm;

ProjectForm.propTypes = {
  project: PropTypes.object,
};
