import {dispatch} from 'dispatchers/ToastrDispatcher';
import ToastrConstants from 'constants/ToastrConstants';

export default {
  /**
   * Dispatches action to display a success message
   * @date   2016-11-29
   * @param  {Object}   message    Title, Body
   * @param  {Object}   options={} Extra paramaters (toastr)
   */
  success(message, options = {}) {
    dispatch({
      actionType: ToastrConstants.SUCCESS,
      message,
      options,
    });
  },
  /**
   * Dispatches action to display an error message
   * @date   2016-11-29
   * @param  {Object}   message    Title, Body
   * @param  {Object}   options={} Extra paramaters (toastr)
   */
  error(message, options = {}) {
    dispatch({
      actionType: ToastrConstants.ERROR,
      message,
      options,
    });
  },
  /**
   * Dispatches action to display a warning message
   * @date   2016-11-29
   * @param  {Object}   message    Title, Body
   * @param  {Object}   options={} Extra paramaters (toastr)
   */
  warning(message, options = {}) {
    dispatch({
      actionType: ToastrConstants.WARNING,
      message,
      options,
    });
  },
  /**
   * Dispatches action to display a warning message
   * @date   2016-11-29
   * @param  {Object}   message    Title, Body
   * @param  {Object}   options={} Extra paramaters (toastr)
   */
  info(message, options = {}) {
    dispatch({
      actionType: ToastrConstants.INFO,
      message,
      options,
    });
  },
};
