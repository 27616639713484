import {dispatch} from 'dispatchers/UIDispatcher';
import UIConstants from 'constants/UIConstants';

export default {
  /**
   * Dispaches action to add a folder
   * @date 2016-10-24
   */
  addFolder() {
    dispatch({
      actionType: UIConstants.ADD_FOLDER,
    });
  },
  /**
   * Dispatches action to update folder
   * @date   2016-10-24
   * @param  {Object}   folder Folder
   */
  editFolder(folder) {
    dispatch({
      actionType: UIConstants.EDIT_FOLDER,
      folder,
    });
  },
  /**
   * Dispatches action to remove a folder
   * @date   2016-10-24
   * @param  {Object}   folder Folder
   */
  removeFolder(folder) {
    dispatch({
      actionType: UIConstants.REMOVE_FOLDER,
      folder,
    });
  },
  /**
   * Dispatches action to add a creative
   * @date 2016-10-24
   */
  addCreative(data) {
    dispatch({
      actionType: UIConstants.ADD_CREATIVE,
      data
    });
  },
  /**
   * Dispatches action to update a creative
   * @date   2016-10-24
   * @param  {Object}   creative creative
   */
  editCreative(creative) {
    dispatch({
      actionType: UIConstants.EDIT_CREATIVE,
      creative,
    });
  },
  /**
   * Dispatches action to remove a creative
   * @date   2016-10-24
   * @param  {Object}   creative creative
   */
  removeCreative(creative) {
    dispatch({
      actionType: UIConstants.REMOVE_CREATIVE,
      creative,
    });
  },
  /**
   * Dispatches an action to duplicate a creative
   * @date   2016-12-28
   * @param  {Object}   creative creative
   */
  duplicateCreative(creative) {
    dispatch({
      actionType: UIConstants.DUPLICATE_CREATIVE,
      creative,
    });
  },
  /**
   * Dispatches an action to download a creative
   * @param {Object} creative creative
   */
  downloadCreative(creative) {
    dispatch({
      actionType: UIConstants.DOWNLOAD_CREATIVE,
      creative,
    });
  },
  /**
   * Dispatches action to add an ad position
   * @date 2016-10-24
   */
  addAdPosition() {
    dispatch({
      actionType: UIConstants.ADD_ADPOSITION,
    });
  },
  /**
   * Dispatches action to remove an ad position
   * @date   2016-10-24
   * @param  {Object}   adposition Ad position
   */
  removeAdPosition(adposition) {
    dispatch({
      actionType: UIConstants.REMOVE_ADPOSITION,
      adposition,
    });
  },
  selectFile(file) {
    dispatch({
      actionType: UIConstants.SELECT_FILE,
      file,
    });
  },
  /**
   * Dispatches action to add a project
   * @date 2016-10-24
   */
  addProject() {
    dispatch({
      actionType: UIConstants.ADD_PROJECT,
    });
  },
  /**
   * Dispatches action to update a project
   * @date   2016-10-24
   * @param  {Object}   project project
   */
  editProject(project) {
    dispatch({
      actionType: UIConstants.EDIT_PROJECT,
      project,
    });
  },
  /**
   * Dispatches action to remove a project
   * @date   2016-10-24
   * @param  {Object}   project project
   */
  removeProject(project) {
    dispatch({
      actionType: UIConstants.REMOVE_PROJECT,
      project,
    });
  },
  /**
   * Dispatches action to add a campaign
   * @date 2016-10-24
   */
  addCampaign(project) {
    dispatch({
      actionType: UIConstants.ADD_CAMPAIGN,
      project
    });
  },
  /**
   * Dispatches action to update a campaign
   * @date   2016-10-24
   * @param  {Object}   campaign campaign
   */
  editCampaign(campaign) {
    dispatch({
      actionType: UIConstants.EDIT_CAMPAIGN,
      campaign,
    });
  },
  /**
   * Dispatches action to remove a campaign
   * @date   2016-10-24
   * @param  {Object}   campaign campaign
   */
  removeCampaign(campaign) {
    dispatch({
      actionType: UIConstants.REMOVE_CAMPAIGN,
      campaign,
    });
  },
  /**
   * Dispatches action to add an insertion
   * @date 2016-10-24
   */
  addInsertion(data) {
    const obj = Object.assign({}, {
      actionType: UIConstants.ADD_INSERTION,
      siteOfferLabel: data
    });
    dispatch(obj);
  },
  /**
   * Dispatches event to edit an insertion
   * @date   2016-11-08
   * @param  {object}   insertion InsertionItem
   */
  editInsertion(insertion) {
    dispatch({
      actionType: UIConstants.EDIT_INSERTION,
      insertion,
    });
  },
  /**
   * Dispatches action to remove an insertion
   * @date   2016-11-09
   * @param  {Object}   insertion insertion
   */
  removeInsertion(insertion) {
    dispatch({
      actionType: UIConstants.REMOVE_INSERTION,
      insertion,
    });
  },
  /**
   * Dispatches action to open a new modal containing a site/offer form
   */
  openSiteOfferModal() {
    dispatch({
      actionType: UIConstants.OPEN_SITE_OFFER_MODAL,
    });
  },
  /**
   * Dispatches action to assign a creative
   * @date   2016-10-24
   * @param  {Object}   insertion insertion
   */
  assignCreatives(data) {
    const obj = Object.assign({}, {
      actionType: UIConstants.ASSIGN_CREATIVE,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches action to assign a campaign
   * @date   2016-10-24
   */
  assignCampaign() {
    dispatch({
      actionType: UIConstants.ASSIGN_CAMPAIGN,
    });
  },
  /**
   * Dispatches action to assign a creative
   * @date   2016-11-01
   */
  // assignCreative() {
  //   dispatch({
  //     actionType: UIConstants.ASSIGN_CREATIVE,
  //   });
  // },
  /**
   * Dispatches an action to set the default creative
   * @date  2016-11-10
   * @param {Number}   creativeId creative id
   * @param {Number}   insertionId insertion id
   */
  setDefaultCreative(creativeId, insertionId) {
    dispatch({
      actionType: UIConstants.SET_DEFAULT_CREATIVE,
      creativeId,
      insertionId,
    });
  },
  /**
   * Dispatches an action to view tags
   * @date   2016-11-14
   * @param {Obect}   insertion insertion
   */
  viewTags(insertion) {
    dispatch({
      actionType: UIConstants.VIEW_TAGS,
      insertion,
    });
  },
  /**
   * dispatches an action to duplicate an insertion
   * @date   2016-12-28
   * @param  {Object}   insertion insertion
   */
  duplicateInsertion(insertion) {
    dispatch({
      actionType: UIConstants.DUPLICATE_INSERTION,
      insertion,
    });
  },
  /**
   * Dispatches an action to show the generate tags form
   * @date   2017-01-20
   */
  generateTags(data) {
    const obj = Object.assign({}, {
      actionType: UIConstants.GENERATE_TAGS,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to unassign a creative
   */
  unassignCreative(insertion, creative) {
    dispatch({
      actionType: UIConstants.UNASSIGN_CREATIVE,
      insertion,
      creative,
    });
  },
  /**
   * Dispatches an action to manage ad networks
   */
  manageAdNetWorks() {
    dispatch({
      actionType: UIConstants.MANAGE_AD_NETWORKS,
    });
  },
  /**
   * Dispatches an action to add an ad network
   */
  addAdNetwork() {
    dispatch({
      actionType: UIConstants.ADD_AD_NETWORK,
    });
  },
  /**
   * edit ad network
   * @param {Object} data object
   */
  editAdNetwork(data) {
    const obj = Object.assign({}, {
      actionType: UIConstants.EDIT_AD_NETWORK,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to add an Ad Space.
   * @param {Object} data data object
   */
  addAdSpace(data) {
    const obj = Object.assign({}, {
      actionType: UIConstants.ADD_AD_SPACE,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to edit an Ad Space.
   * @param {Object} data data object
   */
  editAdSpace(data) {
    const obj = Object.assign({}, {
      actionType: UIConstants.EDIT_AD_SPACE,
    }, data);

    dispatch(obj);
  },
  /**
 * Dispatches an action to remove an ad space;
   * @param {Object} data data object
   */
  removeAdSpace(data) {
    const obj = Object.assign({}, {
      actionType: UIConstants.REMOVE_AD_SPACE,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to remove an ad network.
   * @param {Obect} data data object
   */
  removeAdNetwork(data) {
    const obj = Object.assign({}, {
      actionType: UIConstants.REMOVE_AD_NETWORK,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to report a bug
   */
  reportBug() {
    dispatch({
      actionType: UIConstants.REPORT_BUG,
    });
  },
  manageVastSettings(creative) {
    dispatch({
      actionType: UIConstants.MANAGE_VAST_SETTINGS,
      creative,
    });
  },
  /**
   * Dispatches an action to add an extra click
   * @param {Object} data
   */
  addExtraClick(data) {
    const obj = Object.assign({}, {
      actionType: UIConstants.ADD_EXTRA_CLICK,
    }, data);

    dispatch(obj);
  },
  /**
   * Disptaches an action to add an third party tracker
   * @param {Obejct} data data object
   */
  addTracker(data) {
    const obj = Object.assign({}, {
      actionType: UIConstants.ADD_TRACKER,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to update a tracker
   * @param {Object} data data object
   */
  editTracker(data) {
    const obj = Object.assign({}, {
      actionType: UIConstants.EDIT_TRACKER,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to clear account cache
   * @param {Object} data data object
   */
  clearAccountCache(data) {
    const obj = Object.assign({}, {
      actionType: UIConstants.CLEAR_ACCOUNT_CACHE,
    }, data);

    dispatch(obj);
  },
  /**
   * Launches the easter egg
   */
  easterEgg() {
    dispatch({actionType: UIConstants.EASTER_EGG});
  },
  /**
   * Dispatches an action to recreate the setup script
   * @param {Object} data data object
   */
  recreateSetupScript(data) {
    const obj = Object.assign({}, {
      actionType: UIConstants.RECREATE_SETUP_SCRIPT,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to edit the setupscript. (not raw mode);
   * @param {Object} data data object
   */
  editSetupScript(data) {
    const obj = Object.assign({}, {
      actionType: UIConstants.EDIT_SETUP_SCRIPT,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to change account.
   */
  changeAccount() {
    dispatch({actionType: UIConstants.CHANGE_ACCOUNT});
  },
  /**
   * Dispatches an action to open a modal to move ad element(s)
   * @param {Object} data data object
   */
  moveAdElements(data) {
    const obj = Object.assign({}, {
      actionType: UIConstants.MOVE_ADELEMENTS,
    }, data);
    dispatch(obj);
  },
};
