// let BASE_URL = `${document.location.protocol}//${process.env.API_URL}/`;
// import env from '../../env.config';

let BASE_URL = process.env.NODE_ENV === 'production' ? 'https://api.warm.weborama.nl/' : 'https://api.warm.preprod.weborama.nl/';

/* eslint-disable */
/**
 * returns query parameter
 * @date   2016-09-22
 * @param  {String}   param query parameter
 * @return {String}        query parameter value
 */
function getParameter(param) {
  const url = window.location.href;
  const name = param.replace(/[\[\]]/g, '\\$&');
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) { return null; }
  if (!results[2]) { return ''; }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

/* eslint-enable  */

const definedIp = getParameter('useip');

if (definedIp !== null) {
// BASE_URL = document.location.protocol + '//' + definedIp + '/';
  BASE_URL = 'https://' + definedIp + '/';
}

export const FAVORITES = {
  LIST: BASE_URL + 'favorites/list',
  CREATE: BASE_URL + 'favorites/create',
  DELETE: BASE_URL + 'favorites/delete',
};

export const deliveryFormat = {
  LIST: BASE_URL + 'deliveryformats/list',
};

const ACCOUNTS = {
  GET: BASE_URL + 'accounts/get',
  LIST: BASE_URL + 'accounts/list',
  USER: BASE_URL + 'accounts/user',
  RECENT: BASE_URL + 'accounts/recent',
  CLEAR_CACHE: BASE_URL + 'accounts/clearcache',
  CLEAR_ALL_CACHE: BASE_URL + 'accounts/flushallcache',
};

const CREATIVE_LIBRARY = {
  FOLDERS: {
    LIST: BASE_URL + 'folders/list',
    CREATE: BASE_URL + 'folders/create',
    UPDATE: BASE_URL + 'folders/update',
    DELETE: BASE_URL + 'folders/delete',
    GET: BASE_URL + 'folders/get',
    RECENT: BASE_URL + 'folders/recent',
    GET_CREATIVE_LIST: BASE_URL + 'folders/creativelist',
    GET_NUMBER_OF_CREATIVES: BASE_URL + 'folders/numberOfCreatives',
  },
  CREATIVES: {
    GET: BASE_URL + 'creatives/get',
    LIST: BASE_URL + 'creatives/list',
    CREATE: BASE_URL + 'creatives/create',
    DUPLICATE_CREATIVE: BASE_URL + 'creatives/duplicate',
    GET_PREVIEW_SETUP_SCRIPT: BASE_URL + 'creatives/getPreviewSetupScript',
    GET_MULTI_PREVIEW_SETUP_SCRIPT: BASE_URL + 'creatives/getMultiPreviewSetupScript',
    UPDATE: BASE_URL + 'creatives/update',
    DELETE: BASE_URL + 'creatives/delete',
    PUSH_LIVE: BASE_URL + 'creatives/settolive',
    LIST_ASSIGNED_INSERTIONS: BASE_URL + 'creatives/getassignedinsertions',
  },
  CREATIVE_TYPES: {
    LIST: BASE_URL + 'creativetypes/list',
    FORMATS_BY_CREATIVETYPE_ID: BASE_URL + 'creativetypes/formatsbycreativetype',
  },
  CREATIVE_FORMATS: {
    LIST: BASE_URL + 'creativeformats/list',
    // FORMATS_BY_CREATIVETYPE_ID: BASE_URL + 'creativetypes/formatsbycreativetype'
  },
  AD_POSITIONS: {
    LIST: BASE_URL + 'adpositions/list',
    DETAILS: BASE_URL + 'adpositions/listadelementdetails',
    CREATE: BASE_URL + 'adpositions/create',
    DELETE: BASE_URL + 'adpositions/delete',
    UPDATE: BASE_URL + 'adpositions/update',
    GET_SETUP_SCRIPT: BASE_URL + 'adpositions/getsetupscript',
  },
  ELEMENTS: {
    LIST: BASE_URL + 'adelements/list',
  },
  FILES: {
    UPLOAD: BASE_URL + 'files/upload',
  },
  SIZES: {
    LIST: BASE_URL + 'sizes/list',
  },
  ADELEMENTS: {
    LIST: BASE_URL + 'adelements/list',
    UPDATE_ROLE: BASE_URL + 'adelements/updaterole',
    UPDATE_MULTIPLE_ROLE: BASE_URL + 'adelements/updaterole',
    NEW_ADPOS_WITH_ELEMENTS: BASE_URL + 'adelements/createadpositionwithelements',
    NEW_MULTIPLE_ADPOS_WITH_ELEMENTS: BASE_URL + 'adelements/createMultiAdPositionAndSpreadElements',
    DELETE: BASE_URL + 'adelements/delete',
  },
  AD_ELEMENT_ROLES: {
    LIST: BASE_URL + 'adelementroles/list',
  },
  CREATIVE_LABELS: {
    LIST: BASE_URL + 'creativelabels/list',
    CREATE: BASE_URL + 'creativelabels/create',
  },
  FILE_TYPES: {
    LIST: BASE_URL + 'filetypes/list',
  },
};

const CAMPAIGN_MANAGER = {
  PROJECTS: {
    LIST: BASE_URL + 'projects/list',
    CREATE: BASE_URL + 'projects/create',
    UPDATE: BASE_URL + 'projects/update',
    DELETE: BASE_URL + 'projects/delete',
    GET: BASE_URL + 'projects/get',
    RECENT: BASE_URL + 'projects/recent',
  },
  CAMPAIGNS: {
    LIST: BASE_URL + 'campaigns/list',
    GET: BASE_URL + 'campaigns/get',
    UPDATE: BASE_URL + 'campaigns/update',
    CREATE: BASE_URL + 'campaigns/create',
    DELETE: BASE_URL + 'campaigns/delete',
    GET_NUMBER_OF_INSERTIONS: BASE_URL + 'campaigns/numberofinsertions',
    // TODO: this should be moved to campaignadspaces
    GET_AD_SPACES: BASE_URL + 'campaigns/getCampaignAdSpacesDetails',
    // GET_AD_SPACES: BASE_URL + 'campaigns/getAdSpaces',
    // GET_AD_SPACES: BASE_URL + 'Campaignadspaces/list',
    // Campaignadspaces
    LIST_ASSIGNED_CREATIVES: BASE_URL + 'campaigns/getAssignedCreatives',
  },
  STATISTICS: {
    LASTNDAYS: BASE_URL + 'statistics/lastndays',
  },
  CAMPAIGN_ADSPACES: {
    UPDATE: BASE_URL + 'campaignadspaces/update',
  },
  INSERTION_LABELS: {
    LIST: BASE_URL + 'insertionlabels/list',
    CREATE: BASE_URL + 'insertionlabels/create',
  },
  PLACEMENTS: {
    LIST: BASE_URL + 'placements/list',
  },
  CHANNELS: {
    LIST: BASE_URL + 'channels/list',
  },
  CAMPAIGN_LABELS: {
    LIST: BASE_URL + 'campaignlabels/list',
  },
};

export const Authentication = {
  LOGIN: BASE_URL + 'session/authenticate',
  LOGOUT: BASE_URL + 'session/logout',
};

export const Campaign = {
  LIST: BASE_URL + 'campaigns/list',
  GET: BASE_URL + 'campaigns/get',
  UPDATE: BASE_URL + 'campaigns/update',
  CREATE: BASE_URL + 'campaigns/create',
  DELETE: BASE_URL + 'campaigns/delete',
  GET_NUMBER_OF_INSERTIONS: BASE_URL + 'campaigns/numberofinsertions',
  // TODO: this should be moved to campaignadspaces
  GET_AD_SPACES: BASE_URL + 'campaigns/getCampaignAdSpacesDetails',
  // GET_AD_SPACES: BASE_URL + 'campaigns/getAdSpaces',
  // GET_AD_SPACES: BASE_URL + 'Campaignadspaces/list',
  // Campaignadspaces
  LIST_ASSIGNED_CREATIVES: BASE_URL + 'campaigns/getAssignedCreatives',
  GET_THIRD_PARTY_TAGS: BASE_URL + 'campaigns/getthirdpartytags',
  DOWNLOAD_MEDIA_PLAN: BASE_URL + 'campaigns/downloadmediaplan',
  UPLOAD_MEDIA_PLAN: BASE_URL + 'campaigns/uploadmediaplan',
  GET_MEDIA_PLAN_TEMPLATE: BASE_URL + 'campaigns/getmediaplantemplate',
};

export const Creative = {
  GET: BASE_URL + 'creatives/get',
  LIST: BASE_URL + 'creatives/list',
  CREATE: BASE_URL + 'creatives/create',
  DUPLICATE_CREATIVE: BASE_URL + 'creatives/duplicate',
  GET_PREVIEW_SETUP_SCRIPT: BASE_URL + 'creatives/getPreviewSetupScript',
  GET_MULTI_PREVIEW_SETUP_SCRIPT: BASE_URL + 'creatives/getMultiPreviewSetupScript',
  UPDATE: BASE_URL + 'creatives/update',
  DELETE: BASE_URL + 'creatives/delete',
  PUSH_LIVE: BASE_URL + 'creatives/settolive',
  LIST_ASSIGNED_INSERTIONS: BASE_URL + 'creatives/getassignedinsertions',
  DOWNLOAD: BASE_URL + 'creatives/download',
  SEARCH_MATCHING_INSERTIONS: BASE_URL + 'creatives/searchmatchinginsertions',
};

export const Folder = {
  LIST: BASE_URL + 'folders/list',
  CREATE: BASE_URL + 'folders/create',
  UPDATE: BASE_URL + 'folders/update',
  DELETE: BASE_URL + 'folders/delete',
  GET: BASE_URL + 'folders/get',
  RECENT: BASE_URL + 'folders/recent',
  GET_CREATIVE_LIST: BASE_URL + 'folders/creativelist',
  GET_NUMBER_OF_CREATIVES: BASE_URL + 'folders/numberOfCreatives',
  SET_CREATIVES_LIVE: BASE_URL + 'folders/setCreativesLive'
};

export const TagManager = {
  getTagsFromToken: BASE_URL + 'public/getTagsFromToken',
  getZipFromToken: BASE_URL + 'public/getTagsInZipFromToken',
  getTextFromToken: BASE_URL + 'public/getTagsInTextFromToken',
};

export const Statistics = {
  LIST_AVAILABLE_DIMENSIONS: BASE_URL + 'statistics/availabledimensions',
  GET: BASE_URL + 'statistics/get',
};

export const AdNetwork = {
  LIST: BASE_URL + 'adnetworks/list',
  CREATE: BASE_URL + 'adnetworks/create',
  UPDATE: BASE_URL + 'adnetworks/update',
  REMOVE: BASE_URL + 'adnetworks/delete',
};

export const AdSpace = {
  LIST: BASE_URL + 'adspaces/list',
  CREATE: BASE_URL + 'adspaces/create',
  UPDATE: BASE_URL + 'adspaces/update',
  REMOVE: BASE_URL + 'adspaces/delete',
  LIST_PLACEMENTS: BASE_URL + 'adspaces/getPlacements',
  LIST_CAMPAIGN_ADSPACES: BASE_URL + 'campaigns/getCampaignAdSpacesDetails',
  UPDATE_CAMPAIGN_ADSPACES: BASE_URL + 'campaignadspaces/update',
};

export const Country = {
  LIST: BASE_URL + 'countries/list',
};

export const AdServer = {
  LIST: BASE_URL + 'adservers/list',
};

export const ThirdPartyTag = {
  LIST: BASE_URL + 'thirdpartytags/list',
  CREATE: BASE_URL + 'thirdpartytags/create',
  UPDATE: BASE_URL + 'thirdpartytags/update',
  DELETE: BASE_URL + 'thirdpartytags/delete',
  SEARCH: BASE_URL + 'thirdpartytags/search',
  GET: BASE_URL + 'thirdpartytags/get',
};

export const LandingUrl = {
  LIST: BASE_URL + 'landingurls/list',
  ADD_MULTI: BASE_URL + 'assignedadpositions/updatealllandingurls',
  CREATE: BASE_URL + 'landingurls/create',
  UPDATE: BASE_URL + 'landingurls/update',
  DELETE: BASE_URL + 'landingurls/delete',
};

export const Insertion = {
  LIST: BASE_URL + 'insertions/list',
  CREATE: BASE_URL + 'insertions/createwithplacements',
  DELETE: BASE_URL + 'insertions/delete',
  UPDATE: BASE_URL + 'insertions/update',
  GET_TAGS: BASE_URL + 'insertions/generatetags',
  GET_MULTIPLE_TAGS: BASE_URL + 'insertions/generateMultipleTags',
  GENERATE_TOKENS: BASE_URL + 'insertions/generatetokens',
  ASSIGN_CREATIVES: BASE_URL + 'insertions/multiAssignToCreatives',
  UNASSIGN_INSERTION: BASE_URL + 'insertions/unassignFromCreative',
  UNASSIGN_CREATIVE: BASE_URL + 'insertions/unassigncreative',
  SET_DEFAULT_CREATIVE: BASE_URL + 'insertions/setDefaultCreative',
  DUPLICATE: BASE_URL + 'insertions/duplicate',
  GET_ASSIGNED_AD_POSITIONS: BASE_URL + 'insertions/getassignedadpositions',
  DETAILS: BASE_URL + 'insertions/listdetails',
};

export const AssignedAdPosition = {
  LIST: BASE_URL + 'assignedadpositions/list',
  DETAILS: BASE_URL + 'assignedadpositions/getdetails',
  SEARCH: BASE_URL + 'assignedadpositions/search',
  UPDATE: BASE_URL + 'assignedadpositions/update',
};

export const Support = {
  CREATE: BASE_URL + 'support/createticket',
};

export const Search = {
  SEARCH: BASE_URL + 'search/all',
};

export default {
  ACCOUNTS: ACCOUNTS,
  CREATIVE_LIBRARY: CREATIVE_LIBRARY,
  CAMPAIGN_MANAGER: CAMPAIGN_MANAGER,
};
