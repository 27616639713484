const localPreview = require('components/Authentication');

module.exports = {
  path: '/login',
  getComponent(nextState, cb) {
    require.ensure([], () => {
      cb(null, localPreview.default);
    });
  },
};
