import BaseStore from 'stores/BaseStore';
import AppConstants from 'constants/AppConstants';
import { LandingUrl } from 'constants/ServerConstants';
import { register } from 'dispatchers/AppDispatcher';
import ToastrActions from 'actions/Toastr';
/**
 * Handles Landing urls for creatives only (extra clicks)
 */
const LandingUrlStore = Object.assign({}, BaseStore, {
  urls: [],
  /**
   * Returns list of landing urls
   * @date   2016-03-02
   * @return {array}   List of urls
   */
  getUrls() {
    return this.urls;
  },
  /**
   * Fetches all landing urls
   * @date   2016-12-05
   * @param  {Number}   accountId            accountId
   * @param  {Number}   assignedAdPositionId Assigned ad positon id
   */
  listUrls(data) {
    const onSuccess = (response) => {
      this.urls = response.data.slice();
      this.emitChange({ action: AppConstants.LIST_LANDING_URL });
    };

    const onError = (message) => {
      this.emitError({ action: AppConstants.LIST_LANDING_URL, message });
    };

    this.post({
      url: LandingUrl.LIST,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Adds an extra click to an assigned ad position
   * @param {Object} data data object
   */
  add(data) {
    const onSuccess = () => {
      this.emitChange({ action: AppConstants.ADD_LANDING_URL });
    };
    const onError = () => {
      this.emitError({ action: AppConstants.ADD_LANDING_URL });
    };
    this.post({
      url: LandingUrl.CREATE,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Adds multiple extra clicks to an assigned ad position
   * @param {Object} data data object
   */
  addMulti(data) {

    const onSuccess = () => {
      this.emitChange({ action: AppConstants.ADD_MULTI_LANDING_URL });
      ToastrActions.success({ title: 'Landing url clicks', body: 'Landing url clicks have been updated' });
    };
    const onError = () => {
      this.emitError({ action: AppConstants.ADD_MULTI_LANDING_URL });
      ToastrActions.success({ title: 'Landing url clicks', body: 'There was an error updating landing url clicks' });
    };
    this.post({
      url: LandingUrl.ADD_MULTI,
      data,
      // contentType,
      onSuccess,
      onError,
    });
  },

  /**
   * Updates a landing url of an assigned position
   * @param {Object} data data object
   */
  update(data) {
    const onSuccess = () => {
      this.emitChange({ action: AppConstants.UPDATE_LANDING_URL });
    };
    const onError = () => {
      this.emitError({ action: AppConstants.UPDATE_LANDING_URL });
    };
    this.post({
      url: LandingUrl.UPDATE,
      data,
      onSuccess,
      onError,
    });
  },

  /**
   * Removes a landing url of an assigned position
   * @param {Object} data data object
   */
  remove(data) {
    const onSuccess = () => {
      this.emitChange({ action: AppConstants.REMOVE_LANDING_URL });
    };
    const onError = () => {
      this.emitError({ action: AppConstants.REMOVE_LANDING_URL });
    };
    this.post({
      url: LandingUrl.DELETE,
      data,
      onSuccess,
      onError,
    });
  },

  dispatcherIndex: register((action) => {
    switch (action.actionType) {
      case AppConstants.LIST_LANDING_URL:
        LandingUrlStore.listUrls({
          account_id: action.accountId,
          assigned_ad_position_id: action.assignedAdPositionId,
        });
        break;
      case AppConstants.ADD_LANDING_URL:
        LandingUrlStore.add({
          account_id: action.accountId,
          assigned_ad_position_id: action.assignedAdPositionId,
          label: action.label,
          url_text: action.url,
          url_number: action.clickNumber,
        });
        break;
      case AppConstants.ADD_MULTI_LANDING_URL:
        LandingUrlStore.addMulti({
          account_id: action.accountId,
          id: action.assignedAdPositionId,
          landing_urls: action.landingUrls,
        });
        break;
      case AppConstants.UPDATE_LANDING_URL:
        LandingUrlStore.update({
          account_id: action.accountId,
          assigned_ad_position_id: action.assigned_ad_position_id,
          label: action.label,
          id: action.id,
          url_text: action.url_text,
          url_number: action.url_number,
        });
        break;
      case AppConstants.REMOVE_LANDING_URL:
        LandingUrlStore.remove({
          account_id: action.accountId,
          id: action.id,
        });
        break;
      // no default
    }
    return true;
  }),
});

export default LandingUrlStore;
