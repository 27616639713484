import {dispatch} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const AdServer = {
  /**
   * Dispatches an action to fetch a list of countries
   */
  list() {
    dispatch({
      actionType: AppConstants.LIST_ADSERVERS,
    });
  },
};

export default AdServer;
