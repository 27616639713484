import { dispatch } from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const AdNetworks = {
  /**
   * Dispatches an action to fetch a list of ad networks
   * @param {Object} data data object
   */
  list(data) {
    dispatch({
      actionType: AppConstants.LIST_ADNETWORKS,
      accountId: data.accountId,
    });
  },
  /**
   * Dispatches an action to create a new ad network
   * @param {Object} data data object
   */
  add(data) {
    dispatch({
      actionType: AppConstants.ADD_ADNETWORK,
      accountId: data.accountId,
      label: data.label,
    });
  },

  /**
   * Dispatches an action to create a new ad network
   * The difference with above regular add ad network is
   * the action is handled differently compared to regular addNewNetwork
   * @param {Object} data data object
   */
  addAdNetWorkAndSiteOffer(data) {
    dispatch({
      actionType: AppConstants.ADD_ADNETWORK_SITE_OFFER,
      accountId: data.accountId,
      label: data.label,
    });
  },
  /**
   * Dispatches an action to update an ad network
   * @param {Object} data data object
   */
  update(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.UPDATE_ADNETWORK,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to remove an ad network.
   * @param {Object} data data object
   */
  remove(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.REMOVE_ADNETWORK,
    }, data);

    dispatch(obj);
  },
};

export default AdNetworks;
