/* eslint react/no-did-update-set-state:0 */
import React from 'react';
import {
  CreativeStore, SizeStore, CreativeTypeStore, CreativeFormatStore, ContextStore
} from 'stores';
import {
  Creative, CreativeFormat, CreativeType, Size, SetupScript,
} from 'actions';
import PropTypes from 'prop-types';
import AppConstants from 'constants/AppConstants';
import { getObjectInArray } from 'constants/Utils';
import PreviewAdPosition from 'components/PreviewAdPosition';
import Loader from 'weborama-ui-react/Loader';
import Dimmer from 'weborama-ui-react/Dimmer';
import Header from 'weborama-ui-react/Header';
/**
 * Preview class
 */
class LocalPreview extends React.Component {
  /**
   * constructor
   * @date   2016-10-04
   */
  constructor(props) {
    super(props);

    this.state = {
      creative: {},
      sizes: [],
      setupScripts: [],
      creativeTypes: [],
      creativeFormats: [],
      loading: true,
      oldContextRouteParams: {}
    };

    this.onCreativeStoreChange = this.onCreativeStoreChange.bind(this);
    this.onSizeStoreChange = this.onSizeStoreChange.bind(this);
    this.onCreativeTypeStoreChange = this.onCreativeTypeStoreChange.bind(this);
    this.onCreativeFormatStoreChange = this.onCreativeFormatStoreChange.bind(this);
    this.initializeLocalPreview = this.initializeLocalPreview.bind(this);
    document.body.style.backgroundColor = 'white';
    ContextStore.setRoutingParams(this.props.params);
    // Note that params here are different than what's at App component
    // because we're not using the function for splat
    // But to make PublicPreview functional, we don't need splat anyway.

    CreativeStore.addChangeListener(this.onCreativeStoreChange);
    SizeStore.addChangeListener(this.onSizeStoreChange);
    CreativeTypeStore.addChangeListener(this.onCreativeTypeStoreChange);
    CreativeFormatStore.addChangeListener(this.onCreativeFormatStoreChange);
  }

  /**
   * Fetch creative
   */
  componentDidMount() {
    if (ContextStore.routingParams()) {
      this.initializeLocalPreview();
    }

    $('body').keydown((e) => {
      if (e.which === 27) {
        window.close();
      }
    });
  }

  componentDidUpdate() {
    if (ContextStore.routingParams() !== undefined
      && JSON.stringify(ContextStore.routingParams()) !== JSON.stringify(this.state.oldContextRouteParams)) {
      this.setState({ oldContextRouteParams: ContextStore.routingParams() }, () => {
        this.initializeLocalPreview();
      });

    }
  }

  initializeLocalPreview() {
    const preview = {
      accountId: ContextStore.routingParams().accountId,
      folderId: ContextStore.routingParams().folderId,
      creativeId: ContextStore.routingParams().creativeId,
    };
    Creative.get(preview);
    SetupScript.getPreviewSetupScript(preview);
    Size.list();
    CreativeFormat.list();
    CreativeType.list();
  }

  /**
   * Unregister store callback
   * @date   2016-10-04
   */
  componentWillUnmount() {
    CreativeStore.removeChangeListener(this.onCreativeStoreChange);
    SizeStore.removeChangeListener(this.onSizeStoreChange);
    CreativeTypeStore.removeChangeListener(this.onCreativeTypeStoreChange);
    CreativeFormatStore.removeChangeListener(this.onCreativeFormatStoreChange);
  }

  /**
   * Get creative
   * @date   2016-10-04
   * @param  {Object} data
   */
  onCreativeStoreChange(data) {
    if (data.action === AppConstants.GET_MULTI_PREVIEW_SETUP_SCRIPT) {
      const multiPreviewSetupScript = CreativeStore.getMultiPreviewSetupScript();
      this.setState({ loading: false, setupScripts: multiPreviewSetupScript });
    } else if (data.action === AppConstants.GET_CREATIVE) {
      const creative = CreativeStore.getCreative();
      this.setState({ creative });
    }
  }

  /**
   * Set list of sizes to state
   * @param  {Object} data
   */
  onSizeStoreChange(data) {
    if (data.action === AppConstants.LIST_SIZES) {
      this.setState({ sizes: SizeStore.getSizes() });
    }
  }

  /**
   * Creative type store callback
   * @date   2016-10-05
   */
  onCreativeTypeStoreChange() {
    this.setState({ creativeTypes: CreativeTypeStore.getCreativeTypes() });
  }

  /**
   * CreativeFromat store callback
   * @date   2016-10-05
   */
  onCreativeFormatStoreChange() {
    this.setState({ creativeFormats: CreativeFormatStore.getCreativeFormats() });
  }

  /**
   * Renders local previewmanager
   * @date   2016-10-04
   * @return {array}   nodes
   */
  render() {
    const creativeFormat = getObjectInArray(this.state.creativeFormats, 'id', this.state.creative.creative_format_id);
    const creativeType = getObjectInArray(this.state.creativeTypes, 'id', creativeFormat.creative_type_id);
    const adPositions = this.state.setupScripts.map((setupScript) => {
      const size = getObjectInArray(this.state.sizes, 'id', setupScript.size_id);
      return (
        <PreviewAdPosition
          key={setupScript.id}
          creativeType={creativeType}
          size={size}
          setupScript={setupScript}
        />
      );
    });
    return (
      <div>
        <div style={{ position: 'absolute', top: '2px', left: '4px' }}>
          <h5 className="ui grey header" style={{ fontWeight: 'normal' }}>Press esc to close this window</h5>
        </div>
        <Dimmer active={this.state.loading}>
          <Loader indeterminate text>Preparing preview</Loader>
        </Dimmer>
        <div style={{ position: 'relative', top: '110px', marginBottom: '800px' }}>
          <center>
            <Header h3>{this.state.creative.label}</Header>
          </center>
          {adPositions}
        </div>
      </div>
    );
  }
}

export default LocalPreview;

LocalPreview.propTypes = {
  params: PropTypes.object,
};
