import React from 'react';
import PropTypes from 'prop-types';
import {getObjectInArray} from 'constants/Utils';
import Table from 'weborama-ui-react/Table';
import Flag from 'weborama-ui-react/Flag';
import Icon from 'weborama-ui-react/Icon';

const AssignInsertionTable = (props) => {
  console.log(props);
  const country = getObjectInArray(props.countries, 'id', props.adSpace.country_id);
  const flagCode = country && {[country.code2.toLowerCase()]: true};
  const icon = (!country) ? <Icon world /> : <Flag {...flagCode} />;
  return (
    <div style={{overflowX: 'auto'}}>
      <Table>
        <thead>
          <tr>
            <th colSpan="2">
              {icon}
              {props.adSpace.label}
            </th>
          </tr>
        </thead>
        <tbody>
          {props.children}
        </tbody>
      </Table>
    </div>
  );
};

export default AssignInsertionTable;

AssignInsertionTable.propTypes = {
  adSpace: PropTypes.object,
  insertions: PropTypes.array,
  countries: PropTypes.array,
  onAssignInsertion: PropTypes.func,
  children: PropTypes.any,
};
