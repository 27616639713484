import PropTypes from 'prop-types';
import React from 'react';
import { Grid, Column, Row } from 'weborama-ui-react/Grid';
import { isEmptyObject, getReadableFileSize } from 'constants/Utils';
import Table from 'weborama-ui-react/Table';
import Button, { Buttons } from 'weborama-ui-react/Button';
import { UIActions } from 'actions';
import Header from 'weborama-ui-react/Header';
import Icon from 'weborama-ui-react/Icon';

const CreativeWrapper = (props) => {
  if (isEmptyObject(props.creative)) {
    return (
      <Row />
    );
  }

  function isCreativeDisabled() {
    if (parseInt(props.creative.number_of_insertions, 10) > 0) {
      return true;
    }
    return false;
  }

  const isLive = props.creative.live_creative_version_id === props.creative.latest_creative_version_id;

  return (
    <Grid one column>
      <Column eight wide>
        <Header h2>
          {props.creative.label}
        </Header>
        <Table very basic>
          <tbody>
            <tr>
              <td className="collapsing single line"><b>Creative type: </b></td>
              <td>
                {props.creativeType.label}
                {' '}
                {props.creativeFormat.label}
              </td>
              <td className={isLive ? 'collapsing' : 'collapsing warning'}>
                <b>Local Version:</b>
              </td>
              <td className={isLive ? '' : 'warning'}>{props.creative.latest_creative_version_id}</td>
            </tr>
            <tr>
              <td><b>Status:</b></td>
              <td>{props.creative.status}</td>
              <td className={isLive ? 'collapsing' : 'collapsing warning'}>
                <b>Live Version:</b>
              </td>
              <td className={isLive ? '' : 'warning'}>{props.creative.live_creative_version_id}</td>
            </tr>
            <tr>
              <td className="collapsing single line"><b>Assigned insertions:</b></td>
              <td>{props.creative.number_of_insertions}</td>
              <td><b>Total Weight:</b></td>
              <td>{props.creative.weight ? getReadableFileSize(props.creative.weight) : '0 B'}</td>
            </tr>
          </tbody>
        </Table>
      </Column>
      <Column eight wide className="bottom aligned">
        <Buttons two>
          <Button onClick={() => { UIActions.editCreative(props.creative); }}>
            <Icon edit />
            Edit Creative
          </Button>
          <Button onClick={() => { UIActions.duplicateCreative(props.creative); }}>
            <Icon clone />
            Clone Creative
          </Button>
        </Buttons>
        <Buttons two>
          <Button onClick={props.onDownloadCreative}>
            <Icon download />
            Download Creative
          </Button>
          <Button
            disabled={isCreativeDisabled()}
            onClick={() => { UIActions.removeCreative(props.creative); }}
          >
            <Icon trash />
            Remove Creative
          </Button>
        </Buttons>
      </Column>
    </Grid>
  );
};

export default CreativeWrapper;

CreativeWrapper.propTypes = {
  creative: PropTypes.object.isRequired,
  creativeType: PropTypes.object,
  creativeFormat: PropTypes.object,
  onDownloadCreative: PropTypes.func,
};
