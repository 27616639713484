import BaseStore from 'stores/BaseStore';
import ServerConstants from 'constants/ServerConstants';
import AppConstants from 'constants/AppConstants';
import {register} from 'dispatchers/AppDispatcher';
/**
 * CreativeTypeStore
 */
const CreativeTypeStore = Object.assign({}, BaseStore, {
  creativeTypes: [],
  /**
   * Returns list of creative types
   * @date   2016-04-16
   * @return {Array}   creative types
   */
  getCreativeTypes() {
    return this.creativeTypes;
  },
  /**
   * Fetches a list of creative types.
   * @date   2016-02-03
   */
  listCreativeTypes() {
    const onSuccess = (data) => {
      this.creativeTypes = data.data.slice();
      this.emitChange();
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.LIST_CREATIVE_TYPES, message});
    };

    this.post({
      url: ServerConstants.CREATIVE_LIBRARY.CREATIVE_TYPES.LIST,
      onSuccess,
      onError,
    });
  },
  dispatcherIndex: register((action) => {
    if (action.actionType === AppConstants.LIST_CREATIVE_TYPES) {
      CreativeTypeStore.listCreativeTypes();
    }
    return true;
  }),
});

export default CreativeTypeStore;
