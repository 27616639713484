/* eslint-disable react/no-did-update-set-state */
import PropTypes from 'prop-types';
import React from 'react';
import { Creative, SetupScript, UIActions } from 'actions';
import AdPositionStore from 'stores/AdPositionStore';
import Segment from 'weborama-ui-react/Segment';
import AppConstants from 'constants/AppConstants';
import Menu from 'weborama-ui-react/Menu';
import Item from 'weborama-ui-react/Item';
import Icon from 'weborama-ui-react/Icon';
import Checkbox from 'weborama-ui-react/Checkbox';
import Monaco from 'components/Monaco';
import Divider from 'weborama-ui-react/Divider';
import Button from 'weborama-ui-react/Button';
import ContextStore from 'stores/ContextStore';

/**
 * Setup script manager
 */
class SetupScriptManager extends React.Component {
  /**
   * constructor
   * @date   2016-07-22
   * @param {object} props properties
   */
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      mode: 'javascript',
      raw: false,
      setupScript: {},
      oldContextRouteParams: {
        adPositionId: undefined
      },
      oldCreative: undefined,
    };

    this.handleRecreateSetupScript = this.handleRecreateSetupScript.bind(this);
    this.onSetupScriptStoreChange = this.onSetupScriptStoreChange.bind(this);
    this.handleEditSetupScript = this.handleEditSetupScript.bind(this);
    this.handleRawMode = this.handleRawMode.bind(this);
    this.handleSaveSetupScript = this.handleSaveSetupScript.bind(this);
    this.handleSetupScriptChange = this.handleSetupScriptChange.bind(this);

    AdPositionStore.addChangeListener(this.onSetupScriptStoreChange);

    this.rawCheckboxRef = React.createRef();
    this.rawModeSegmentRef = React.createRef();
    this.saveButtonRef = React.createRef();
    this.setupScriptModalRef = React.createRef(); // Possibly redundant
    this.ssFormRef = React.createRef(); // Possibly redundant
  }

  /**
   * Get setupscript
   * @date   2016-09-14
   */
  componentDidMount() {
    if (ContextStore.routingParams().adPositionId !== undefined) {
      SetupScript.get({
        accountId: ContextStore.routingParams().accountId,
        folderId: ContextStore.routingParams().folderId,
        creativeId: ContextStore.routingParams().creativeId,
        creativeVersionId: this.props.creative.latest_creative_version_id,
        adPositionId: this.props.adPosition.id,
      });
    }
  }

  /**
   * Make api requests based on routing params, Set checkbox according the lock_script value
   * @date   2016-09-20
   * @param  {Object}   prevProps previous properties
   * @param  {Object}   prevState previous state
   */
  componentDidUpdate(prevProps, prevState) {

    if ((ContextStore.routingParams().adPositionId !== undefined
      && ContextStore.routingParams().adPositionId !== this.state.oldContextRouteParams.adPositionId)
      || (JSON.stringify(this.props.creative) !== JSON.stringify(this.state.oldCreative))) {
      this.setState({
        setupScript: {},
        loading: true,
        oldContextRouteParams: ContextStore.routingParams(),
        oldCreative: this.props.creative
      });

      SetupScript.get({
        accountId: ContextStore.routingParams().accountId,
        folderId: ContextStore.routingParams().folderId,
        creativeId: ContextStore.routingParams().creativeId,
        creativeVersionId: this.props.creative.latest_creative_version_id,
        adPositionId: ContextStore.routingParams().adPositionId,
      });
    } else if (ContextStore.routingParams().adPositionId === undefined) {
      this.setState({ setupScript: {} });
    }

    if (JSON.stringify(prevState.setupScript) !== JSON.stringify(this.state.setupScript)
      && prevState.raw !== this.state.raw) {
      if (this.state.raw) {
        this.rawCheckboxRef.current.check();
      } else {
        this.rawCheckboxRef.current.unCheck();
      }
    }
    // if (prevState.loading !== this.state.loading) {
    //   $(this.rawModeSegmentRef.current).transition('stop all');
    //   $(this.saveButtonRef.current).transition('stop all');
    //   if (this.state.loading) {
    //     $(this.rawModeSegmentRef.current).transition('hide');
    //     $(this.saveButtonRef.current).transition('hide');
    //   } else {
    //     $(this.rawModeSegmentRef.current).transition('show');
    //     if (this.state.raw !== !$(this.saveButtonRef.current).transition('is hidden')) {
    //       $(this.saveButtonRef.current).transition('scale');
    //     }
    //   }
    // }
  }

  /**
   * Unregister store callback
   * @date   2016-09-14
   */
  componentWillUnmount() {
    AdPositionStore.removeChangeListener(this.onSetupScriptStoreChange);
  }

  /**
   * Setupscript store callback
   * @date   2016-09-19
   * @param  {Object}   data data
   */
  onSetupScriptStoreChange(data) {
    if (data.action === AppConstants.UPDATE_SETUP_SCRIPT) {
      Creative.list({accountId: ContextStore.routingParams().accountId, folderId: ContextStore.routingParams().folderId});
      this.setState({ loading: true });
    } else if (data.action === AppConstants.GET_SETUP_SCRIPT) {
      const { setupScript } = data;
      let mode = 'javascript';
      if (setupScript.setup_script.indexOf('<?xml') > -1) {
        mode = 'xml';
      }
      // Strip white spacess.
      setupScript.setup_script = setupScript.setup_script.replace(/(\r\n|\r|\n){2,}/g, '$1\n');
      const raw = setupScript.lock_script === 'YES';
      this.setState({
        raw, mode, setupScript, loading: false,
      });
    } else if (data.action === AppConstants.LOCK_SETUP_SCRIPT) {
      this.setState({ loading: true });
    }
  }

  /**
   * Shows edit setup script modal
   * @date   2016-09-15
   */
  handleEditSetupScript() {
    UIActions.editSetupScript({
      creative: this.props.creative,
      setupScript: this.state.setupScript,
    });
    // this.setupScriptModalRef.current.show({onVisible: this.ssFormRef.current.setVisible});
  }

  /**
   * recreates - the setupscript
   * @date   2016-07-25
   */
  handleRecreateSetupScript() {
    UIActions.recreateSetupScript({
      accountId: ContextStore.routingParams().accountId,
      folderId: ContextStore.routingParams().folderId,
      creativeId: ContextStore.routingParams().creativeId,
      creativeVersionId: this.props.creative.latest_creative_version_id,
      adPositionId: this.props.adPosition.id,
      beforeAdElementScript: '',
      beforeRenderScript: '',
      afterRenderScript: '',
    });
  }

  /**
   * Locks or unlocks raw editing mode
   * @date   2016-09-20
   */
  handleRawMode() {
    const raw = this.rawCheckboxRef.current.isChecked();
    if (raw !== this.state.raw) {
      this.setState({ raw });
      SetupScript.lock({
        accountId: ContextStore.routingParams().accountId,
        folderId: ContextStore.routingParams().folderId,
        creativeId: ContextStore.routingParams().creativeId,
        creativeVersionId: this.props.creative.latest_creative_version_id,
        adPositionId: this.props.adPosition.id,
        setupScript: this.state.setupScript.setup_script,
        lock: raw,
      });
    }
    if (raw) {
      $(this.saveButtonRef.current).transition('show');
    } else {
      $(this.saveButtonRef.current).transition('hide');
    }
  }

  /**
   * Handles changes to the setupScript
   * @date   2016-09-20
   * @param  {String}   value setupScript
   */
  handleSetupScriptChange(value) {
    const { setupScript } = this.state;
    setupScript.setup_script = value;
    this.setState({ setupScript });
  }

  /**
   * Save a raw setupscript
   * @date   2016-09-20
   */
  handleSaveSetupScript() {
    SetupScript.lock({
      accountId: ContextStore.routingParams().accountId,
      folderId: ContextStore.routingParams().folderId,
      creativeId: ContextStore.routingParams().creativeId,
      creativeVersionId: this.props.creative.latest_creative_version_id,
      adPositionId: this.props.adPosition.id,
      setupScript: this.state.setupScript.setup_script,
      lock: true,
    });
    this.setState({ loading: true });
  }

  /**
   * Renders setupscript manager
   * @date   2016-07-22
   * @return {jsx}   setupscript manager
   */
  render() {
    const setupScript = this.state.setupScript.setup_script || '';
    return (
      <div>
        <Menu style={{ backgroundColor: 'rgb(248, 249, 251)' }}>
          {this.state.mode === 'javascript'
            && (
              <Item
                useATag
                disabled={this.state.raw}
                onClick={this.handleEditSetupScript}
              >
                <Icon edit />
                {'Edit script'}
              </Item>
            )
          }
          <Item useATag onClick={this.handleRecreateSetupScript}>
            <Icon refresh />
            {'Recreate script'}
          </Item>
        </Menu>
        <Segment basic loading={this.state.loading}>
          <div style={{
            position: 'absolute', right: '45px', top: '45px', zIndex: 20,
          }}
          >
            <div className="" ref={this.rawModeSegmentRef}>
              <div className="ui right floated compact segment">
                <Checkbox ref={this.rawCheckboxRef} toggle onChange={this.handleRawMode}>
                  {'Raw'}
                </Checkbox>
                <div ref={this.saveButtonRef} className="save-button-holder transition hidden">
                  <Divider />
                  <Button mini fluid primary onClick={this.handleSaveSetupScript}>Save</Button>
                </div>
              </div>
            </div>
          </div>
          <div className="left floated" style={{ position: 'relative' }}>
            <Monaco
              code={setupScript}
              onChange={this.handleSetupScriptChange}
              readOnly={this.state.setupScript.lock_script === 'NO'}
              language={this.state.mode}
              theme="vs-dark"
            />
          </div>
        </Segment>
      </div>
    );
  }
}

export default SetupScriptManager;

SetupScriptManager.propTypes = {
  creative: PropTypes.object,
  adPosition: PropTypes.object,
};
