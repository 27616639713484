export const AuthenticationForm = {
  fields: {
    input_email: {
      rules: [
        {
          type: 'email',
        },
      ],
    },
    input_password: {
      rules: [
        {
          type: 'empty',
        },
      ],
    },
  },
  inline: true,
  on: 'blur',
  keyboardShortcuts: false,
};

export const folderForm = {
  on: 'change',
  inline: true,
  fields: {
    label: {
      rules: [
        {
          type: 'empty',
        },
      ],
    },
  },
};

export const creativeForm = {
  on: 'change',
  inline: true,
  fields: {
    label: {
      rules: [
        {
          type: 'empty',
        },
      ],
    },
  },
};

export const projectForm = {
  on: 'change',
  inline: true,
  fields: {
    label: {
      rules: [
        {
          type: 'empty',
        },
      ],
    },
  },
};
