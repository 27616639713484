import PropTypes from 'prop-types';
import React from 'react';
import Segment from 'weborama-ui-react/Segment';
import Button, {Buttons} from 'weborama-ui-react/Button';
import Icon from 'weborama-ui-react/Icon';
import Header from 'weborama-ui-react/Header';

const InsertionTagSegment = ((props) => {
  const handleDownloadZip = () => {
    props.onDownloadZipFile(props.insertion.token);
  };

  const handleDownloadText = () => {
    props.onDownloadTextFile(props.insertion.token);
  };

  const handlePreview = () => {
    window.open(`${window.location.origin}/public/preview/${props.insertion.token}`);
  };

  const handleOpenTagPage = () => {
    window.open(`${window.location.origin}/public/tags/${props.insertion.token}`);
  };

  return (
    <div style={{position: 'relative'}}>
      <Header h4 inverted top attached>
        {props.insertion.label}
        <span style={{position: 'absolute', right: '7px', top: '7px'}}>
          <Buttons mini icon>
            <Button
              onClick={handlePreview}
              toolTip="Preview advertisement"
              toolTipPosition="bottom right"
            >
              <Icon eye />
            </Button>
            {props.isAuthenticated
              && (
              <Button
                onClick={handleOpenTagPage}
                toolTip="Open tag page"
                toolTipPosition="bottom right"
              >
                <Icon external />
              </Button>
              )
            }
            <Button
              toolTip="Download tags in zip file"
              toolTipPosition="bottom right"
              onClick={handleDownloadZip}
            >
              <Icon file archive outline />
            </Button>
            <Button
              toolTip="Download tags in text file"
              toolTipPosition="bottom right"
              onClick={handleDownloadText}
            >
              <Icon file text outline />
            </Button>
          </Buttons>
        </span>
      </Header>
      <Segment attached>
        {props.children}
      </Segment>
    </div>
  );
});

export default InsertionTagSegment;

InsertionTagSegment.propTypes = {
  insertion: PropTypes.object,
  children: PropTypes.any,
  isAuthenticated: PropTypes.bool,
  onDownloadZipFile: PropTypes.func,
  onDownloadTextFile: PropTypes.func,
};
