export default class SortByItem {

  constructor(label, ascending) {
    this.label = label;
    this.ascending = ascending;
  }

  setAscending(value) {
    // Not inverting because if something is wrong with the flow,
    // it will cause a bug here (so it will be visible)
    this.ascending = value;
  }
}
