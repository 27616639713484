import React from 'react';
import ProfileButton from 'components/ProfileButton';
import {Column, Row, Grid} from 'weborama-ui-react/Grid';
import Switch from 'components/Switch';
import FavoritePopupButton from 'components/FavoritePopupButton';
import Omnibar from 'components/Omnibar';
import './index.sass';
import { ContextStore } from 'stores';

const Header = () => {
  let moduleSwitch = null;
  if (ContextStore.active() === 'CreativeLibrary' || ContextStore.active() === 'CampaignManager'
    || ContextStore.active() === 'SettingsManager') {
    moduleSwitch = <Switch />;
  } else if (ContextStore.active() === 'TagManager') {
    moduleSwitch = (
      <span style={{color: 'white'}}>Tag Manager</span>
    );
  } else if (ContextStore.active() === 'AccountSelection') {
    moduleSwitch = (
      <span style={{color: 'white'}}>Account Selector</span>
    );
  }

  const logoStyle = {
    marginTop: '-5px',
    width: '270px',
  };

  return (
    <Row className="header-container">
      <Grid container>
        <Column four wide>
          <div style={logoStyle}>
            <svg viewBox="0 0 190 28.54">
              <g style={{fill: '#c1002a'}} id="weborama_w" data-name="weborama w">
                {/* eslint-disable-next-line */}
                <path d="M54,939.75a2.56,2.56,0,0,1-2.23,1.54H46.27a2.54,2.54,0,0,1-2.21-1.55l-2.4-6.5c-0.31-.85-0.81-0.85-1.12,0l-2.29,6.48a2.5,2.5,0,0,1-2.19,1.55H30.52a2.61,2.61,0,0,1-2.25-1.53l-5.75-14.6a1,1,0,0,1,1-1.53h3.88a2.42,2.42,0,0,1,2.15,1.57l3.13,9.67c0.28,0.86.77,0.87,1.08,0l3.64-9.71a2.56,2.56,0,0,1,2.22-1.54h2.8a2.55,2.55,0,0,1,2.22,1.55l3.61,9.71c0.32,0.85.8,0.84,1.07,0l3.05-9.66a2.4,2.4,0,0,1,2.14-1.57h3.88a1.06,1.06,0,0,1,1.06,1.54Z" transform="translate(-22.41 -918.3)" />
              </g>
            </svg>
          </div>
          <span style={{position: 'absolute', top: '22px', left: '69px'}}>
            {moduleSwitch}
          </span>
        </Column>
        <Column eight wide>
          {ContextStore.active() !== 'TagManager' && ContextStore.active() !== 'AccountSelection' && <Omnibar fluid />}
        </Column>
        <Column four wide right aligned>
          {
            ContextStore.token() !== '' && ContextStore.token() !== undefined && (
              <span>
                <FavoritePopupButton />
              </span>
            )
          }
          <ProfileButton />
        </Column>
      </Grid>
    </Row>
  );
};

export default Header;
