import PropTypes from 'prop-types';
import React from 'react';
import AssignActions from 'actions/AssignActions';
import AppConstants from 'constants/AppConstants';
import FolderStore from 'stores/FolderStore';
import {CreativeStore, AssignStore, ContextStore, CountryStore} from 'stores';
import {isEmptyObject, getObjectInArray} from 'constants/Utils';
import {Grid, Row, Column} from 'weborama-ui-react/Grid';
import Segment from 'weborama-ui-react/Segment';
import Label from 'weborama-ui-react/Label';
import Filter from 'components/Filter';
import ExpandableList from 'components/ExpandableList';
import Paginator from 'components/Paginator';
import Table from 'weborama-ui-react/Table';
import Button from 'weborama-ui-react/Button';
import Divider from 'weborama-ui-react/Divider';
import Message from 'weborama-ui-react/Message';
import './AssignCreativeManager.scss';
/**
 * Assign Creative Wrapper
 */
class AssignCreativeManager extends React.Component {
  /**
   * constructor
   * @date   2016-11-01
   */
  constructor(props) {
    super();
    this.state = {
      folders: [],
      creatives: [],
      assignedCreatives: props.creatives || [],
      selectedFolder: {},
      filter: '',
      paginator: {
        page: 1,
      },
      loadingFolders: true,
      loadingCreatives: false,
      loadingAssignedCreatives: false,
      loadingUnAssign: false,
      maxListItems: 13,
      unAssignError: {},
      assignError: {},
    };

    this.onFolderStoreChange = this.onFolderStoreChange.bind(this);
    this.onCreativeStoreChange = this.onCreativeStoreChange.bind(this);
    this.onAssignStoreError = this.onAssignStoreError.bind(this);
    this.handleAssign = this.handleAssign.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleExpandFolder = this.handleExpandFolder.bind(this);
    this.onAssignStoreChange = this.onAssignStoreChange.bind(this);
    this.handleUnAssign = this.handleUnAssign.bind(this);
    this.handlePaginationChange = this.handlePaginationChange.bind(this);

    FolderStore.addChangeListener(this.onFolderStoreChange);
    CreativeStore.addChangeListener(this.onCreativeStoreChange);
    AssignStore.addErrorListener(this.onAssignStoreError);
    AssignStore.addChangeListener(this.onAssignStoreChange);
  }

  /**
   * Fetch a list of folders
   * @date   2016-11-01
   */
  componentDidMount() {
    AssignActions.browseFolders(ContextStore.routingParams().accountId);
  }

  /**
   * Remove store change callbacks
   * @date   2016-11-01
   */
  componentWillUnmount() {
    FolderStore.removeChangeListener(this.onFolderStoreChange);
    CreativeStore.removeChangeListener(this.onCreativeStoreChange);
    AssignStore.removeChangeListener(this.onAssignStoreChange);
    AssignStore.removeErrorListener(this.onAssignStoreError);
  }

  /**
   * assign store change callback
   * @param {Object} data data object
   */
  onAssignStoreChange(data) {
    if (data.action === AppConstants.LIST_ASSIGNED_CREATIVES) {
      const assignedCreatives = AssignStore.getAssignedCreatives().filter((creative) => {
        if (creative.insertion_id === this.props.insertion.id) {
          return creative;
        }
        return undefined;
      });
      this.setState({loadingAssignedCreatives: false, loadingAssign: false, assignedCreatives});
    }
    if (data.action === AppConstants.UNASSIGN_INSERTION
      || data.action === AppConstants.ASSIGN_CREATIVES_INSERTIONS) {
      AssignActions.listAssignedCreatives({
        accountId: ContextStore.routingParams().accountId,
        projectId: ContextStore.routingParams().projectId,
        campaignId: ContextStore.routingParams().campaignId,
        // insertionId: this.props.insertion.id,
      });
      this.setState({loadingUnAssign: false, loadingAssignedCreatives: true});
    }
  }

  /**
   * FolderStore change callbacks
   * @date   2016-11-01
   * @param  {Object}   data data Object
   */
  onFolderStoreChange(data) {
    if (data.action === AppConstants.BROWSE_FOLDERS) {
      this.setState({folders: FolderStore.getFolders(), loadingFolders: false});
    }
  }

  /**
   * Creative Store change callbacks
   * @date   2016-11-01
   * @param  {Object}   data data
   */
  onCreativeStoreChange(data) {
    if (data.action === AppConstants.BROWSE_CREATIVES) {
      this.setState({
        creatives: CreativeStore.getCreatives(),
        loadingCreatives: false,
      });
    }
  }

  /**
   * On assign store error
   * @param {Object} data event object
   */
  onAssignStoreError(data) {
    if (data.action === AppConstants.ASSIGN_CREATIVES_INSERTIONS) {
      this.setState({loadingAssign: false, assignError: data});
    }
    if (data.action === AppConstants.UNASSIGN_INSERTION) {
      this.setState({loadingUnAssign: false, unAssignError: data});
    }
  }

  /**
   * Handles assigning insertions to creativeId
   * @date   2016-10-24
   */
  handleAssign(creative) {
    const creatives = [];
    const insertion = [];
    insertion.push(this.props.insertion.id);
    creatives.push(creative.id);
    AssignActions.assignCreativesInsertions(
      ContextStore.routingParams().accountId,
      insertion,
      creatives,
    );
    this.setState({assignError: {}, loadingAssign: creative});
  }

  /**
   * Handles unassign creative - insertion
   * @param {Object} creative creative object
   */
  handleUnAssign(creative) {
    AssignActions.unAssignInsertion({
      accountId: ContextStore.routingParams().accountId,
      creativeId: creative.id,
      insertionId: this.props.insertion.id,
    });
    this.setState({unAssignError: {}, loadingUnAssign: creative});
  }

  /**
   * Handles pagination
   * @param  {number}   page page to navigate to
   */
  handlePaginationChange(page) {
    this.setState({paginator: {page}});
  }

  /**
   * Filter change
   * @param {Object} e event object
   */
  handleFilterChange(e) {
    this.setState({filter: e.target.value.toLowerCase(), paginator: {page: 1}});
  }

  handleExpandFolder(folder) {
    if (!isEmptyObject(this.state.selectedFolder) && folder.id === this.state.selectedFolder.id) {
      this.setState({selectedFolder: {}});
    } else {
      // TODO: Should pass an object
      AssignActions.browseCreatives(ContextStore.routingParams().accountId, folder.id);
      this.setState({
        selectedFolder: folder,
        creatives: [],
        loadingCreatives: true,
      });
    }
  }

  /**
   * Renders assign creative Wrapper
   * @date   2016-11-01
   * @return {array}   nodes
   */
  render() {
    /**
     * Paginator
     */
    const filteredFolders = this.state.folders.filter((folder) => {
      if (folder.id.toString().indexOf(this.state.filter) > -1 || folder.label.toLowerCase().indexOf(this.state.filter) > -1) {
        return folder;
      }
      return false;
    });
    const paginatorSettings = {
      totalPages: Math.ceil(filteredFolders.length / this.state.maxListItems),
      page: this.state.paginator.page,
    };

    const folders = filteredFolders.slice(
      (0 + (this.state.paginator.page - 1)) * this.state.maxListItems,
      (((0 + (this.state.paginator.page - 1)) * this.state.maxListItems) + this.state.maxListItems),
    );

    const creativeTable = this.state.creatives.map((creative) => {
      const isAssigned = getObjectInArray(this.state.assignedCreatives, 'id', creative.id);
      return (
        <tr key={creative.id}>
          <td className="collapsing table-override"><b>{creative.creative_format_abbreviation}</b></td>
          <td style={{wordBreak: 'break-word', overflowWrap: 'break-word'}} className="collapsing table-override">{creative.label}</td>
          <td className="collapsing table-override">{creative.size.map(size => `[${size}] `)}</td>
          <td className="collapsing table-override">
            {!isAssigned
              && (
              <Button
                basic
                tiny
                loading={this.state.loadingAssign && this.state.loadingAssign.id === creative.id}
                onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.handleAssign(creative); }}
              >
                {'Link'}
              </Button>
              )
            }
          </td>
        </tr>
      );
    });
    const country = getObjectInArray(CountryStore.getCountries(), 'id', this.props.adSpace.ad_network_fields.country_id);

    const assignedCreatives = this.state.assignedCreatives.map(creative => (
      <tr key={creative.id}>
        <td>
          <Button
            basic
            icon
            loading={this.state.loadingUnAssign && this.state.loadingUnAssign.id === creative.id}
            onClick={() => { this.handleUnAssign(creative); }}
          >
            <i className="unlinkify icon" />
          </Button>
          {creative.label}
        </td>
      </tr>
    ));

    return (
      <Grid two column divided>
        <Row streched>
          <Column>
            <Segment basic loading={this.state.loadingFolders}>
              <Label top attached>{'Select creative\'s to assign to this insertion'}</Label>
              <Filter transparent placeholder="Filter folders" fluid onChange={this.handleFilterChange} />
              {!isEmptyObject(this.state.assignError)
                && (
                <Message tiny header="Error: Assigning insertion" error closable>
                  {this.state.assignError.message}
                </Message>
                )
              }
              <ExpandableList
                small
                expanded={parseInt(this.state.selectedFolder.id, 10) || false}
                items={folders}
                onExpandCollapse={this.handleExpandFolder}
                loading={this.state.loadingCreatives}
              >
                <Table
                  small
                  striped={this.state.creatives.length > 2}
                  very
                  basic
                >
                  <tbody>
                    {creativeTable}
                  </tbody>
                </Table>
              </ExpandableList>
              <Paginator
                totalPages={paginatorSettings.totalPages}
                page={paginatorSettings.page}
                onPaginationChange={this.handlePaginationChange}
              />
            </Segment>
          </Column>
          <Column>
            <Segment basic loading={this.state.loadingInsertion}>
              <Label top attached>Insertion</Label>
              <Table
                small
              >
                <thead>
                  <tr>
                    <th>
                      {country
                        && <i className={`${country.code2.toLowerCase()} flag`} />
                      }
                      {`${this.props.adSpace.ad_network_fields.label} - ${this.props.adSpace.ad_space_fields.label}`}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{this.props.insertion.label}</td>
                  </tr>
                </tbody>
              </Table>
            </Segment>
            <Segment
              basic
              loading={this.state.loadingAssignedCreatives}
              style={{paddingLeft: '0.5em', paddingRight: '0.5em'}}
            >
              <Label top attached>Assigned creatives</Label>
              <Divider hidden />
              {!isEmptyObject(this.state.unAssignError)
                && (
                <Message tiny header="Error: Unassign insertion" error closable>
                  {this.state.unAssignError.message}
                </Message>
                )
              }
              <div style={{maxHeight: '450px', overflowY: 'auto'}}>
                <Table
                  small
                  striped={this.state.assignedCreatives.length > 2}
                >
                  <tbody>
                    {assignedCreatives}
                  </tbody>
                </Table>
              </div>
            </Segment>
          </Column>
        </Row>
      </Grid>
    );
  }
}

export default AssignCreativeManager;

AssignCreativeManager.propTypes = {
  insertion: PropTypes.object,
  adSpace: PropTypes.object,
  creatives: PropTypes.array,
};
