import BaseStore from 'stores/BaseStore';
import AppConstants from 'constants/AppConstants';
import ServerConstants from 'constants/ServerConstants';
import {register} from 'dispatchers/AppDispatcher';
import ToastrActions from 'actions/Toastr';
/**
 * Handles Project data
 */
const ProjectStore = Object.assign({}, BaseStore, {
  projects: [],
  /**
   * Returns list of projects
   * @date   2016-03-02
   * @return {array}   List of Projects
   */
  getProjects() {
    return this.projects;
  },
  /**
   * Fetches all projects by account id
   * @date   2016-01-14
   * @param  {number}   accountId id of account
   * @param  {Boolean}  browse browse
   */
  listProjects(accountId, browse = false) {
    let action = AppConstants.LIST_PROJECTS;
    if (browse) {
      action = AppConstants.BROWSE_PROJECTS;
    }

    const onSuccess = (response) => {
      this.projects = response.data.slice();
      this.emitChange({action});
    };

    const onError = (message) => {
      this.emitError({action, message});
    };

    this.post({
      url: ServerConstants.CAMPAIGN_MANAGER.PROJECTS.LIST,
      data: {
        account_id: accountId,
        status: 'active',
        order_fields: ['updated_at', 'created_at'],
        order_direction: 'desc',
      },
      onSuccess,
      onError,
    });
  },
  /**
   * Posts new project
   * @date  2016-03-03
   * @param {Object}   data data object
   */
  addProject(data, addCampaign) {
    const onSuccess = (response) => {
      ProjectStore.listProjects(data.account_id);
      this.emitChange({action: AppConstants.ADD_PROJECT, data: response, addCampaign});
      ToastrActions.success({ title: response.data.label, body: 'A new project has been created' });
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.ADD_PROJECT, message});
    };

    this.post({
      url: ServerConstants.CAMPAIGN_MANAGER.PROJECTS.CREATE,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Remove a project
   * @date   2016-03-04
   * @param  {Number}   accountId Account id
   * @param  {Number}   projectId Project id
   */
  removeProject(accountId, projectId) {
    const onSuccess = (response) => {
      this.emitChange({action: AppConstants.REMOVE_PROJECT, data: response});
      ProjectStore.listProjects(accountId);
      ToastrActions.success({ title: response.data.label.split('[')[0], body: 'Project has been removed' });
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.REMOVE_PROJECT, message});
    };

    this.post({
      url: ServerConstants.CAMPAIGN_MANAGER.PROJECTS.DELETE,
      data: {
        account_id: accountId,
        id: projectId,
      },
      onSuccess,
      onError,
    });
  },
  /**
   * Updates a projectId
   * @date   2016-03-15
   * @param  {Number}   accountId Id of accountId
   * @param  {Number}   projectId Id of projectId
   * @param  {string}   label     Label
   */
  updateProject(accountId, projectId, label) {
    const onSuccess = () => {
      ProjectStore.listProjects(accountId);
      this.emitChange({action: AppConstants.UPDATE_PROJECT});
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.UPDATE_PROJECT, message});
    };

    this.post({
      url: ServerConstants.CAMPAIGN_MANAGER.PROJECTS.UPDATE,
      data: {
        account_id: accountId,
        id: projectId,
        label,
      },
      onSuccess,
      onError,
    });
  },
  dispatcherIndex: register((action) => {
    switch (action.actionType) {
      case AppConstants.BROWSE_PROJECTS:
        ProjectStore.listProjects(action.accountId, true);
        break;
      case AppConstants.LIST_PROJECTS:
        ProjectStore.listProjects(action.accountId);
        break;
      case AppConstants.ADD_PROJECT:
        ProjectStore.addProject({
          account_id: action.accountId,
          label: action.label,
        }, action.addCampaign);
        break;
      case AppConstants.REMOVE_PROJECT:
        ProjectStore.removeProject(action.accountId, action.projectId);
        break;
      case AppConstants.UPDATE_PROJECT:
        ProjectStore.updateProject(action.accountId, action.projectId, action.label);
        break;
      // no default
    }
    return true;
  }),
});

export default ProjectStore;
