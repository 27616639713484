import React from 'react';
import PropTypes from 'prop-types';

const CampaignTrackerRow = props => (
  <tr className={props.active ? 'active' : 'cursor-pointer'} onClick={props.onClick}>
    <td className="center aligned collapsing">C</td>
    <td className="left aligned single line">
      {props.campaign.label}
    </td>
  </tr>
);

export default CampaignTrackerRow;

CampaignTrackerRow.propTypes = {
  campaign: PropTypes.object,
  onClick: PropTypes.func,
  active: PropTypes.bool,
};
