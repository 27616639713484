const publicPreview = require('components/PublicPreview');

module.exports = {
  path: 'public/preview(/:token)',
  getComponent(nextState, cb) {
    require.ensure([], () => {
      cb(null, publicPreview.default);
    });
  },
  childRoutes: [
    {
      path: '*',
    },
  ],
};
