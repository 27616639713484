import React from 'react';
import {Grid, Column} from 'weborama-ui-react/Grid';

const NotFound = () => (
  <Grid centered one column container>
    <Column>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '15%',
        }}
      >
        <img alt="yikes" src="/images/404.gif" />
        <h3 className="ui header" style={{marginLeft: '20px'}}>
          {'YIKES! This page could not be found'}
        </h3>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <a href="/">Take me home!</a>
      </div>
    </Column>
  </Grid>
);

export default NotFound;
