import React from 'react';
import PropTypes from 'prop-types';
import AppConstants from 'constants/AppConstants';
import {AdNetworkStore, ContextStore} from 'stores';
import {AdNetwork} from 'actions';
import Form, {Field} from 'weborama-ui-react/Form';
import Message from 'weborama-ui-react/Message';
import Button from 'weborama-ui-react/Button';
import {isEmptyObject} from 'constants/Utils';
/**
 * Class AdNetworkForm
 */
class AdNetworkForm extends React.Component {
  /**
   * Constructor
   */
  constructor(props) {
    super(props);
    this.state = {
      label: !isEmptyObject(props.adNetwork) ? props.adNetwork.label : '',
      error: {},
      loading: false,
    };
    this.onAdNetworkStoreChange = this.onAdNetworkStoreChange.bind(this);
    this.onAdNetworkStoreError = this.onAdNetworkStoreError.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    AdNetworkStore.addChangeListener(this.onAdNetworkStoreChange);
    AdNetworkStore.addErrorListener(this.onAdNetworkStoreError);
  }

  /**
   * Unregister store callback
   */
  componentWillUnmount() {
    AdNetworkStore.removeChangeListener(this.onAdNetworkStoreChange);
    AdNetworkStore.removeErrorListener(this.onAdNetworkStoreError);
  }

  /**
   * AdNetworkstore change callback
   * @param {Object} data event object
   */
  onAdNetworkStoreChange(data) {
    if (data.action === AppConstants.ADD_ADNETWORK) {
      this.setState({loading: false});
    }
  }

  /**
   * AdNetworkStore error
   * @param {Object} error Event error object
   */
  onAdNetworkStoreError(error) {
    this.setState({error, loading: false});
  }

  /**
   * Handles form change
   * @param {Object} e Event object
   */
  handleFormChange(e) {
    this.setState({label: e.target.value});
  }

  /**
   * Handles form submit
   */
  handleFormSubmit() {
    if (!isEmptyObject(this.props.adNetwork)) {
      AdNetwork.update({
        accountId: ContextStore.routingParams().accountId,
        adNetworkId: this.props.adNetwork.id,
        label: this.state.label,
      });
    } else {
      AdNetwork.add({
        accountId: ContextStore.routingParams().accountId,
        label: this.state.label,
      });
    }
    this.setState({loading: true});
  }

  /**
   * Renders ad network form
   */
  render() {
    return (
      <span>
        {
          !isEmptyObject(this.state.error)
          && <Message closeable error>{this.state.error.message}</Message>
        }
        <Form>
          <Field required label="Name">
            <input
              type="text"
              placeholder="Name"
              name="label"
              value={this.state.label}
              onChange={this.handleFormChange}
            />
          </Field>
          <Button
            loading={this.state.loading}
            onClick={this.handleFormSubmit}
            primary
            fluid
            submit
          >
            {'Submit'}
          </Button>
        </Form>
      </span>
    );
  }
}

export default AdNetworkForm;

AdNetworkForm.propTypes = {
  adNetwork: PropTypes.object,
};
