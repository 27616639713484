import React from 'react';
import PropTypes from 'prop-types';

const ImageAd = props => (
  <center>
    <div dangerouslySetInnerHTML={{__html: props.tag}} />
    {' '}
  </center>
);

export default ImageAd;

ImageAd.propTypes = {
  tag: PropTypes.string,
};
