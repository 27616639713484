import BaseStore from 'stores/BaseStore';
import ServerConstants, {Insertion} from 'constants/ServerConstants';
import {register} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';
import ToastrActions from 'actions/Toastr';

const AssignStore = Object.assign({}, BaseStore, {
  assignedInsertions: [],
  assignedCreatives: [],
  req: {},
  /**
   * Returns list of assigned insertions
   * @date   2016-10-20
   * @return {Array}   list of assigned insertions
   */
  getAssignedInsertions() {
    return this.assignedInsertions;
  },
  /**
   * Returns list of assigned creatives
   * @date   2016-10-28
   * @return {Array}   List of assigned creatives
   */
  getAssignedCreatives() {
    return this.assignedCreatives;
  },
  /**
   * Fetches list of assigned insertions
   * @date   2016-10-20
   * @param  {Number}   accountId  Account id
   * @param  {Number}   creativeId Creative id
   */
  fetchAssignedInsertions(data) {
    const onSuccess = (response) => {
      if (data.detailed_response) {
        this.assignedInsertions = response.data;
      } else {
        this.assignedInsertions = response.data.slice();
      }
      this.emitChange({action: AppConstants.LIST_ASSIGNED_INSERTIONS});
    };
    const onError = (message) => {
      this.emitError({action: AppConstants.LIST_ASSIGNED_INSERTIONS, message});
    };
    this.post({
      url: ServerConstants.CREATIVE_LIBRARY.CREATIVES.LIST_ASSIGNED_INSERTIONS,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Fetches list of assigned creatives
   * @date   2016-10-28
   * @param  {Number}   accountId   Account Id
   * @param  {Number}   campaignId  campaign Id
   */
  fetchAssignedCreatives(data) {
    if (typeof this.req.abort === 'function' && this.req.readyState !== 4 && this.req.readyState !== 0) {
      this.req.abort();
    }
    const onSuccess = (response) => {
      this.assignedCreatives = response.data.slice();
      this.emitChange({action: AppConstants.LIST_ASSIGNED_CREATIVES});
    };
    const onError = (message) => {
      this.emitError({action: AppConstants.LIST_ASSIGNED_CREATIVES, message});
    };
    this.req = this.post({
      url: ServerConstants.CAMPAIGN_MANAGER.CAMPAIGNS.LIST_ASSIGNED_CREATIVES,
      data,
      onSuccess,
      onError,
    });
  },
  /**
   * Unassigns insertion from creative
   * @date   2016-10-28
   * @param  {Number}   accountId   Account Id
   * @param  {Number}   creativeId  Creative Id
   * @param  {Number}   insertionId Insertion Id
   */
  unassignInsertion(data) {
    const onSuccess = () => {
      this.emitChange({action: AppConstants.UNASSIGN_INSERTION});
      // this.fetchAssignedInsertions(data.account_id, data.creative_id);
      // this.fetchAssignedCreatives(data.account_id, data.id); // id = insertion_id
    };
    const onError = (message) => {
      this.emitError({action: AppConstants.UNASSIGN_INSERTION, message});
    };
    this.post({
      url: Insertion.UNASSIGN_INSERTION,
      data,
      onSuccess,
      onError,
    });
  },
  // unassignCreative(accountId, insertionId, creativeId) {
  //   let onSuccess = () => {
  //     this.emitChange({action: AppConstants.UNASSIGN_CREATIVE});
  //     this.fetchAssignedInsertions(accountId, creativeId);
  //     this.fetchAssignedCreatives(accountId, insertionId);
  //   }
  //   let onError = (message) => {
  //     this.emitError({action: AppConstants.UNASSIGN_CREATIVE, message: message});
  //   };
  //   this.post({
  //     url: ServerConstants.CAMPAIGN_MANAGER.INSERTIONS.UNASSIGN_CREATIVE,
  //     data: {
  //       account_id: accountId,
  //       creative_id:
  //     }
  //   })
  // }
  /**
   * Assignes creatives and insertions
   * @date   2016-10-28
   * @param  {Number}   accountId  Account Id
   * @param  {Array}   insertions  Insertions
   * @param  {Array}   creatives  Creatives
   */
  assignCreativesInsertions(accountId, insertions, creatives) {
    const onSuccess = () => {
      // const splitResult = JSON.stringify(data).split(new RegExp('campaign_id/?'));
      // const value = splitResult[1] && splitResult[1].match(/\d+/);
      // const campaignId = value[0];
      // AssignStore.fetchAssignedCreatives(accountId, campaignId);
      this.emitChange({action: AppConstants.ASSIGN_CREATIVES_INSERTIONS});
    };
    const onError = (message) => {
      ToastrActions.error({ title: 'Assign creatives to Insertions error', body: message });
      this.emitError({action: AppConstants.ASSIGN_CREATIVES_INSERTIONS, message});
    };
    this.post({
      url: Insertion.ASSIGN_CREATIVES,
      data: {
        account_id: accountId,
        creative_ids: creatives,
        insertion_ids: insertions,
      },
      onSuccess,
      onError,
    });
  },
  /**
   * Marks a creative as default creative
   * @date  2016-11-09
   * @param {Number}   accountId   Account Id
   * @param {Number}   insertionId Insertion Id
   * @param {Number}   creativeId  creativeId
   */
  setDefaultCreative(accountId, insertionId, creativeId) {
    const onSuccess = (data) => {
      AssignStore.fetchAssignedCreatives({account_id: accountId, id: data.data.campaign_id});
      this.emitChange({action: AppConstants.SET_DEFAULT_CREATIVE});
    };
    const onError = (message) => {
      this.emitError({action: AppConstants.SET_DEFAULT_CREATIVE, message});
    };
    this.post({
      url: Insertion.SET_DEFAULT_CREATIVE,
      data: {
        account_id: accountId,
        id: insertionId,
        creative_id: creativeId,
      },
      onSuccess,
      onError,
    });
  },
  /**
   * Unassign creative from insertion
   * @param {Object} data data object
   */
  unAssignCreative(data) {
    const onSuccess = () => {
      this.emitChange({action: AppConstants.UNASSIGN_CREATIVE});
    };
    const onError = (error) => {
      this.emitError({action: AppConstants.UNASSIGN_CREATIVE, error});
    };
    this.post({
      url: Insertion.UNASSIGN_CREATIVE,
      data,
      onSuccess,
      onError,
    });
  },
  dispatcherIndex: register((action) => {
    switch (action.actionType) {
      case AppConstants.UNASSIGN_INSERTION:
        AssignStore.unassignInsertion({
          account_id: action.accountId,
          creative_id: action.creativeId,
          id: action.insertionId,
        });
        break;
      case AppConstants.ASSIGN_CREATIVES_INSERTIONS:
        AssignStore.assignCreativesInsertions(
          action.accountId,
          action.insertions,
          action.creatives,
        );
        break;
      case AppConstants.LIST_ASSIGNED_INSERTIONS:
        AssignStore.fetchAssignedInsertions({
          account_id: action.accountId,
          folder_id: action.folderId,
          id: action.creativeId,
          detailed_response: action.detailedResponse,
        });
        break;
      case AppConstants.LIST_ASSIGNED_CREATIVES:
        AssignStore.fetchAssignedCreatives({
          account_id: action.accountId,
          project_id: action.projectId,
          id: action.campaignId,
          insertion_id: action.insertionId,
          detailed_response: action.detailedResponse,
        });
        break;
      case AppConstants.SET_DEFAULT_CREATIVE:
        AssignStore.setDefaultCreative(action.accountId, action.insertionId, action.creativeId);
        break;
      case AppConstants.UNASSIGN_CREATIVE:
        AssignStore.unAssignCreative({
          account_id: action.accountId,
          id: action.insertionId,
          creative_id: action.creativeId,
        });
        break;
      // no default
    }
  }),
});

export default AssignStore;
