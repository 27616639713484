/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import PropTypes from 'prop-types';
import CountryDropdown from 'components/CountryDropdown';
import Input from 'weborama-ui-react/Input';
import { AdSpaceStore, ContextStore, CountryStore } from 'stores';
import { AdSpace } from 'actions';
import { getObjectInArray, isEmptyObject } from 'constants/Utils';
import './AdSpaceSearch.scss';
import UIActions from '../../actions/UIActions';
import Message from 'weborama-ui-react/Message';

/**
 * Class AsSpaceSearch
 */
class AdSpaceSearch extends React.Component {
  /**
   * returns array of adSpaces filtered by country
   * @param {Object} selectedCountry selected country
   * @param {Array} adSpaces Ad spaces
   */
  static getFilteredAdSpaces(selectedCountry, adSpaces) {
    let filteredAdSpaces = [];
    if (!isEmptyObject(selectedCountry)) {
      filteredAdSpaces = adSpaces.filter((adSpace) => {
        if (adSpace.country_id.toString() === selectedCountry.id.toString()) {
          return adSpace;
        }
        return false;
      });
    } else {
      return adSpaces;
    }
    return filteredAdSpaces;
  }

  /**
   * Constructor
   */
  constructor(props) {
    super(props);

    this.state = {
      adSpaces: [],
      filteredAdSpaces: [], // Ad spaces filtered by country
      visibleFilteredAdSpaces: [], // Ad spaces that should be visible as a result of a search action
      selectedCountry: getObjectInArray(CountryStore.getCountries(), 'id', ContextStore.profile().data.user.country_id) || {},
      showSearchResults: false, // Whether the search results are visible in DOM
      inputValue: '',
      adSpaceSelected: false, // Is an ad space clicked (or selected with enter), or the visible value in the form is just some text?
      selectedAdSpaceValue: '', // Record the selected adSpace and invalidate the submission when it changes by typing
      showSiteOfferError: false, // Show the error associated with Site/Offer
      currentTabIndex: null // At the input dropdown, which index are we at?
    };

    this.handleSelectCountry = this.handleSelectCountry.bind(this);
    this.handleSearchSelect = this.handleSearchSelect.bind(this);
    this.onAdSpaceStoreChange = this.onAdSpaceStoreChange.bind(this);
    this.handleSearchOnFocus = this.handleSearchOnFocus.bind(this);
    this.handleSearchOnBlur = this.handleSearchOnBlur.bind(this);
    this.handleSearchOnChange = this.handleSearchOnChange.bind(this);
    this.handlePreSelectedSiteOffer = this.handlePreSelectedSiteOffer.bind(this);
    this.showErrorMessage = this.showErrorMessage.bind(this);
    this.keyEventFunc = this.keyEventFunc.bind(this);

    AdSpaceStore.addChangeListener(this.onAdSpaceStoreChange);
    this.inputRef = React.createRef();
  }

  /**
   * Fetch a list of ad spaces
   */
  componentDidMount() {
    AdSpace.list({ accountId: ContextStore.routingParams().accountId });
    setTimeout(() => this.inputRef.current.focus(), 0); // autoFocus is not working as expected, this is a workaround
  }

  /**
   * Remove store change callback
   */
  componentWillUnmount() {
    AdSpaceStore.removeChangeListener(this.onAdSpaceStoreChange);
    clearTimeout(this.onBlurTimeout); // Required to keep things clean
  }

  /**
   * Ad space store change callback
   */
  onAdSpaceStoreChange() {
    const adSpaces = AdSpaceStore.getAdSpaces();
    if (this.props.selectedAdSpaceId) {
      const selectedAdSpace = getObjectInArray(adSpaces, 'id', this.props.selectedAdSpaceId);
      const selectedCountry = getObjectInArray(CountryStore.getCountries(), 'id', selectedAdSpace.country_id) || {};
      const filteredAdSpaces = AdSpaceSearch.getFilteredAdSpaces(selectedCountry, adSpaces);
      this.setState({ adSpaces, selectedCountry, filteredAdSpaces }, this.handlePreSelectedSiteOffer);
      this.handleSelectCountry(selectedCountry.id);
    } else {
      const filteredAdSpaces = AdSpaceSearch.getFilteredAdSpaces(this.state.selectedCountry, adSpaces);
      this.setState({ adSpaces, filteredAdSpaces }, this.handlePreSelectedSiteOffer);
    }
  }

  /**
   * handles defining country filter
   * @param {Number} country country id
   */
  handleSelectCountry(countryId) {
    const selectedCountry = getObjectInArray(CountryStore.getCountries(), 'id', countryId) || {};
    const filteredAdSpaces = AdSpaceSearch.getFilteredAdSpaces(selectedCountry, this.state.adSpaces);
    this.setState({ filteredAdSpaces });
  }

  /**
   * Selected ad space
   * @param {Object} adSpace ad space
   */
  handleSearchSelect(adSpace) {
    this.setState({ inputValue: adSpace.label }, () => { // Don't forget to update the Input value!
      const country = getObjectInArray(CountryStore.getCountries(), 'id', adSpace.country_id);
      country && this.setState({ selectedCountry: country, adSpaceSelected: true, selectedAdSpaceValue: adSpace.label });
      this.props.onSelect(adSpace); // Note that it's handled by parent component
    });
  }

  /**
   * Handle the key events from the input and self-made dropdown form
   * @param {Object} event event object
   */
  keyEventFunc(event) {
    if (event.keyCode === 40 && this.state.currentTabIndex < this.state.visibleFilteredAdSpaces.length) { // Arrow down
      const newTabIndex = this.state.currentTabIndex !== null ? this.state.currentTabIndex + 1 : 0;
      this.setState({ currentTabIndex: newTabIndex });
    }

    if (event.keyCode === 38) { // Arrow up
      const newTabIndex = this.state.currentTabIndex >= 1 ? this.state.currentTabIndex - 1 : 0;
      this.setState({ currentTabIndex: newTabIndex });
    }

    if (event.keyCode === 13) { // Enter key
      if (this.state.currentTabIndex === 0) { // New site/offer
        document.removeEventListener('keydown', this.keyEventFunc, true);
        UIActions.openSiteOfferModal();
      } else { // Select an existing one
        this.handleSearchSelect(this.state.visibleFilteredAdSpaces[this.state.currentTabIndex - 1]);
        this.setState({ showSearchResults: false, adSpaceSelected: true });
      }

    }
  }

  /**
   * Input onFocus
   */
  handleSearchOnFocus() {
    this.setState({ showSearchResults: true }, () => {
      document.addEventListener('keydown', this.keyEventFunc, true);
    });
  }

  /**
 * Input onBlur
 */
  handleSearchOnBlur() {
    this.onBlurTimeout = setTimeout(() => {
      this.setState({ showSearchResults: false }, () => {
        document.removeEventListener('keydown', this.keyEventFunc, true);
      });
      this.showErrorMessage();
    }, 150);
  }

  /**
  * Input onChange
  * @param {String} event Input event target
  */
  handleSearchOnChange(event) {
    // Note that due to the custom setup of the Input component, the 'event' here is actually event.target
    this.setState({ inputValue: event }, () => {
      const combinedTempAdSpaces = []; // Temporary holder variable to hold a collection of results
      if (event !== '' && event.length > 1) { // See above comment
        this.state.filteredAdSpaces.forEach((singleAdSpace) => {
          if (singleAdSpace.label.toUpperCase().indexOf(event.toUpperCase()) > -1) {
            combinedTempAdSpaces.push(singleAdSpace);
          }
        });
      }

      if (event !== this.state.selectedAdSpaceValue || event === '') { // compare it to what's been selected previously
        this.setState({ adSpaceSelected: false });
        this.props.onSelect(null); // Reset information at the parent InsertionForm
      }
      this.setState({ visibleFilteredAdSpaces: combinedTempAdSpaces });
    });
  }

  /**
   * Function run to select a site/offer automatically when the component receives its label
   * By default, there should be no label
   */
  handlePreSelectedSiteOffer() {
    if (this.props.siteOfferLabel) {
      this.handleSearchOnChange(this.props.siteOfferLabel);
      for (let i = 0; i < this.state.filteredAdSpaces.length; i++) {
        if (this.state.filteredAdSpaces[i].label.toString() === this.props.siteOfferLabel.toString()) {
          // We found the correct Ad space, the one we intend to set at the site/offers section
          this.handleSearchSelect(this.state.filteredAdSpaces[i]);
          break; // We don't need to continue the loop after we found what we're looking for
        }
      }
    } else if (this.props.selectedAdSpaceId && this.state.filteredAdSpaces) {
      // We are already receiving a selected ad space id, so arrange component accordingly

      // This is different than above method, better to be consistent
      // Look into refactoring and merging this whole component with InsertionForm.
      // they share a lot of functionality
      const preSelectedAdSpace = this.state.filteredAdSpaces.filter((singleAdSpace) => {
        return singleAdSpace.id.toString() === this.props.selectedAdSpaceId.toString();
      });
      this.handleSearchOnChange(preSelectedAdSpace[0].label);
      this.handleSearchSelect(preSelectedAdSpace[0]);
    }
  }

  showErrorMessage() {
    if (this.state.inputValue !== '' && this.state.inputValue.length > 1 && !this.state.adSpaceSelected) {
      // there is input, it's more than 2 characters and no ad space is selected
      this.setState({ showSiteOfferError: true });
    } else {
      this.setState({ showSiteOfferError: false });
    }
  }

  /**
   * Renders AdSpace search
   */
  render() {
    // eslint-disable-next-line no-nested-ternary
    const selectedId = !isEmptyObject(this.state.selectedCountry) ? this.state.selectedCountry.id
      : (ContextStore.profile().data.user.country_id !== null ? ContextStore.profile().data.user.country_id : 5);
    const shownSearchResults = this.state.visibleFilteredAdSpaces.map((singleAdSpace, index) => {
      const itemTabIndex = index + 1;
      return (
        <a
          key={singleAdSpace.label.toString() + Date.now()}
          onClick={() => this.handleSearchSelect(singleAdSpace)}
          className={itemTabIndex === this.state.currentTabIndex ? 'result link-normalize' : 'result link-normalize dropdown-highlight'}
          tabIndex={itemTabIndex}
        >
          <div className="content">
            <div className="title">{singleAdSpace.label}</div>
          </div>
        </a>
      );
    });
    return (
      <React.Fragment>
        <div style={{ position: 'relative' }}>
          <div className="search-holder">
            <CountryDropdown
              showFlagsOnly
              onSelect={this.handleSelectCountry}
              disabled={this.props.disabled}
              selectedId={selectedId}
            />
            <div style={{ width: '100%' }}>
              <Input
                inputRef={this.inputRef}
                prompt
                left
                labeled
                placeHolder={`Search sites (${this.state.filteredAdSpaces.length})`}
                onFocus={this.handleSearchOnFocus}
                onBlur={this.handleSearchOnBlur}
                onChange={this.handleSearchOnChange}
                value={this.state.inputValue || ''}
              />
              <div className={this.state.showSearchResults ? 'results transition visible' : ' results transition hidden'}>
                <a
                  onClick={() => { UIActions.openSiteOfferModal(); }}
                  className={this.state.currentTabIndex === 0 ? 'result link-normalize' : 'result link-normalize dropdown-highlight'}
                  tabIndex={0}
                >
                  <div className="content">
                    <div className="title emphasize-text">Add site offer</div>
                  </div>
                </a>
                {shownSearchResults}
              </div>
            </div>
          </div>
        </div>
        {
          this.state.showSiteOfferError && <Message error visible>Please select a value from the Site/Offer dropdown</Message>
        }
      </React.Fragment>
    );
  }
}

export default AdSpaceSearch;

AdSpaceSearch.propTypes = {
  onSelect: PropTypes.func,
  selectedAdSpaceId: PropTypes.string,
  disabled: PropTypes.bool,
  siteOfferLabel: PropTypes.string
};
