import {dispatch} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const AdSpace = {
  /**
   * Dispatches an action to fetch a list of adspaces
   *
   * @param {object} data data object
   */
  list(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.LIST_ADSPACES,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to fetch a list of placements by ad space id
   * @param {Object} data {accountId, adSpaceId}
   */
  listPlacements(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.LIST_ADSPACE_PLACEMENTS,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to add an adspace
   * @param {Object} data data object
   */
  add(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.ADD_ADSPACE,
    }, data);

    dispatch(obj);
  },

  /**
   * Dispatches an action to create a new site / offer
   * @param {Object} data data object
   */
  addSiteOffer(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.ADD_SITE_OFFER,
    }, data);

    dispatch(obj);
  },

  /**
   * Dispatches an action to update an site offer
   * @param {Object} data data object
   */
  update(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.UPDATE_ADSPACE,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to remove an ad space
   * @param {Object} data data object
   */
  remove(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.REMOVE_ADSPACE,
    }, data);

    dispatch(obj);
  },
};

export default AdSpace;
