/* eslint react/no-did-update-set-state: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import ReactSortable from 'components/Sortable';
import {getObjectInArray} from 'constants/Utils';
import File from 'components/File';
import {Column} from 'weborama-ui-react/Grid';
import Segment from 'weborama-ui-react/Segment';
import {AdElement} from 'actions';
import Checkbox from 'weborama-ui-react/Checkbox';
import Button from 'weborama-ui-react/Button';
import Menu from 'weborama-ui-react/Menu';
import Item from 'weborama-ui-react/Item';
import './index.sass';
import {AdPositionStore, ContextStore, AdElementStore} from 'stores';
import AppConstants from 'constants/AppConstants';

/**
 * Creates a sortable group, used as part of CreativeLibrary
 */
class AdElementRole extends React.Component {
  /**
   * constructor
   * @date   2016-07-26
   * @param  {object}   props properties
   */
  constructor(props) {
    super(props);

    const adElementRole = props.adElementRole || {};

    this.state = {
      adElementRole,
      itemOrder: [],
      assetLimit: 10,
      fileTypes: [],
      loading: false,
      adPosition: [], // Only used for staying away from context
      adPositionIdFromContext: undefined,
      oldFileTypes: undefined,
    };

    this.handleSortingChanged = this.handleSortingChanged.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleAssetLimit = this.handleAssetLimit.bind(this);
    this.handleCheckFiles = this.handleCheckFiles.bind(this);
    this.handleUncheckFiles = this.handleUncheckFiles.bind(this);
    this.handleRemoveFiles = this.handleRemoveFiles.bind(this);
    this.onAdElementStoreChange = this.onAdElementStoreChange.bind(this);
    this.isDeleteDisabled = this.isDeleteDisabled.bind(this);

    AdElementStore.addChangeListener(this.onAdElementStoreChange);
  }

  componentDidUpdate() {
    if (this.state.adPositionIdFromContext !== ContextStore.routingParams().adPositionId) {
      this.setState({adPositionIdFromContext: ContextStore.routingParams().adPositionId, assetLimit: 10});
    }
    if (JSON.stringify(this.props.fileTypes) !== JSON.stringify(this.state.oldFileTypes)) {
      this.setState({fileTypes: this.props.fileTypes, oldFileTypes: this.props.fileTypes});
    }

    const adPositionResult = AdPositionStore.getAdPositions();
    if (adPositionResult[0] && this.state.adPosition
      && JSON.stringify(adPositionResult[0]) !== JSON.stringify(this.state.adPosition)) { // Can be potentially broken when we are not changing first child?
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ adPosition: adPositionResult[0] }); // We're using it to get the correct id value for the remove action
    }
  }

  componentWillUnmount() {
    AdElementStore.removeChangeListener(this.onAdElementStoreChange);
  }

  onAdElementStoreChange(data) {
    if (data.action === AppConstants.REMOVE_ADELEMENTS
      || data.action === AppConstants.UPDATE_ADELEMENT_ROLE) {
      this.setState({loading: false});
    }
  }

  /**
   * handles change in sorting
   * @date   2016-07-26
   * @param  {array}   itemOrder new item order
   * @param  {object}   sortable  sortable object
   * @param  {object}   evt       event object
   */
  handleSortingChanged(itemOrder) {
    if ((
      this.state.adElementRole.key_label === 'layer'
      || this.state.adElementRole.key_label === 'fixed_position'
      || this.state.adElementRole.key_label === 'media_file') && itemOrder.length > 1
    ) {
      if (itemOrder.length > 1) {
        this.setState({loading: true});
        this.props.onSortingChange(this.state.adElementRole.id, itemOrder);
      }
    }
    if (itemOrder.length < this.props.files.length) {
      this.setState({loading: true});
    }
    this.props.setLoading(true);
    this.setState({itemOrder});
  }

  /**
   * handles dropping
   * @date   2016-07-26
   */
  handleDrop() {
    if (!this.state.loading) {
      this.props.onSortingChange(this.state.adElementRole.id, this.state.itemOrder);
      this.setState({loading: true});
    }
  }

  /**
   * resets asset limit
   * @date 2016-07-28
   */
  handleAssetLimit() {
    this.setState({assetLimit: -1});
  }

  /**
   * Checks all files
   * @date   2016-06-14
   * @param  {Object}   e event Object
   */
  handleCheckFiles(e) {
    if (e === 'asset' && this.state.assetLimit !== -1) {
      this.setState({assetLimit: -1});
    }
    /* eslint-disable */
    for (const file in this.refs) {
      this.refs[file].setChecked();
    }
    /* eslint-enable */
  }

  /**
   * Unchecks all files
   * @date   2017-01-03
   */
  handleUncheckFiles() {
    // TODO: This should be fixed
    /* eslint-disable */
    for (const file in this.refs) {
      this.refs[file].setUnchecked();
    }
    /* eslint-enable */
  }

  /**
   * Removes files (ad elements)
   */
  handleRemoveFiles() {

    const adElementIds = this.props.selectedAdElements.map(singleAdElement => {
      return singleAdElement.ad_element_id;
    });

    AdElement.remove({
      accountId: ContextStore.routingParams().accountId,
      folderId: ContextStore.routingParams().folderId,
      creativeId: ContextStore.routingParams().creativeId,
      adPositionId: this.state.adPosition.id,
      creativeVersionId: this.props.adPosition.creative_version_id,
      adElementIds: adElementIds,
    });
    this.setState({ loading: true });
  }

  /**
   * Is delete icon disabled?
   */
  isDeleteDisabled() {

    let result = true; // By default, delete icon is disabled

    if (this.props.files.length > 0 && this.props.selectedAdElements.length > 0) {

      this.props.files.forEach(singleFile => {
        this.props.selectedAdElements.forEach(singleSelectedAdElement => {

          if (singleSelectedAdElement.ad_element_id === singleFile.ad_element_id) {
            result = false;
          }

        });
      });
    }

    return result;
  }

  /**
   * Is sortable?
   */
  isSortable() {
    const roles = [
      'backup_image',
      'layer',
      'fixed_position',
      'media_file',
      'companion_ads',
      'non_linear_ads',
    ];

    return roles.includes(this.state.adElementRole.key_label);
  }

  /**
   * Renders a sortable group
   * @date   2016-07-26
   * @return {JSX}   sortable group
   */
  render() {
    // sort elements by element order
    let adElements = this.props.files.sort((a, b) => (
      parseFloat(a.ad_element_order, 10) - parseFloat(b.ad_element_order, 10)
    ));

    // sort elements by file name for assets.
    if (this.state.adElementRole.key_label === 'asset') {
      adElements = this.props.files.sort((a, b) => a.safe_filename.localeCompare(b.safe_filename));
    }

    const items = adElements.map((item, index) => {
      const fileType = getObjectInArray(this.state.fileTypes, 'id', item.file_type_id) || {};
      if (this.state.adElementRole.key_label === 'asset' && this.state.assetLimit !== -1) {
        if (index < this.state.assetLimit) {
          return (
            <File
              key={`file-${item.id}`}
              details={item}
              fileType={fileType}
              ref={`file-${item.id}`}
              onChecked={this.props.onSelectAdElement}
              onUnchecked={this.props.onDeselectAdElement}
              disabledMoveElements={this.props.disabledMoveElements}
            />
          );
        }
        return (
          <File
            key={`file-${item.id}`}
            details={item}
            fileType={fileType}
            ref={`file-${item.id}`}
            onChecked={this.props.onSelectAdElement}
            onUnchecked={this.props.onDeselectAdElement}
            disabledMoveElements={this.props.disabledMoveElements}
            hidden
          />
        );
      }
      return (
        <File
          key={`file-${item.id}`}
          details={item}
          fileType={fileType}
          ref={`file-${item.id}`}
          onChecked={this.props.onSelectAdElement}
          onUnchecked={this.props.onDeselectAdElement}
          disabledMoveElements={this.props.disabledMoveElements}
        />
      );
    });
    return (
      <Column>
        <Menu tiny top attached style={{background: '#F6F8FA'}}>
          <Item>
            <div className="middle aligned content">
              <div className="header">
                <Checkbox
                  onChecked={this.handleCheckFiles}
                  onUnchecked={this.handleUncheckFiles}
                  value={this.state.adElementRole.group}
                >
                  {this.state.adElementRole.label}
                </Checkbox>
              </div>
            </div>
          </Item>
          <Menu right nested>
            <Item>
              <Button
                right
                floated
                circular
                icon
                disabled={this.isDeleteDisabled()}
                onClick={this.handleRemoveFiles}
              >
                <i className="trash icon" />
              </Button>
            </Item>
          </Menu>
        </Menu>
        <Segment id="role-segment" bottom attached loading={this.state.loading}>
          <ReactSortable
            options={{
              group: this.state.adElementRole.key_label,
              onAdd: this.handleDrop,
              put: [
                'backup_image',
                'layer',
                'fixed_position',
                'asset',
                'media_file',
                'companion_ads',
                'non_linear_ads',
              ],
              sort: this.isSortable(),
            }}
            className="ui middle aligned selection list file-list"
            onChange={this.handleSortingChanged}
          >
            {items}
          </ReactSortable>
          {
            this.state.adElementRole.key_label === 'asset'
            && this.state.assetLimit !== -1 && this.props.files.length > this.state.assetLimit
            && (
              <span className="show-all-assets cursor-pointer">
                <a className="show-assets" onClick={this.handleAssetLimit}>
                  {`\u00ab Show all ${this.props.files.length} assets \u00bb`}
                </a>
              </span>
            )
          }
        </Segment>
      </Column>
    );
  }
}

export default AdElementRole;

AdElementRole.propTypes = {
  adElementRole: PropTypes.object.isRequired,
  onSortingChange: PropTypes.func,
  onSelectAdElement: PropTypes.func,
  onDeselectAdElement: PropTypes.func,
  adPosition: PropTypes.object,
  files: PropTypes.array,
  fileTypes: PropTypes.array,
  setLoading: PropTypes.func,
  selectedAdElements: PropTypes.array,
  disabledMoveElements: PropTypes.func,
};
