import React from 'react';
import PropTypes from 'prop-types';
import Form, {Field} from 'weborama-ui-react/Form';
import Input from 'weborama-ui-react/Input';
import DropDown from 'weborama-ui-react/Dropdown';
import Item from 'weborama-ui-react/Item';
import Menu from 'weborama-ui-react/Menu';
import Button from 'weborama-ui-react/Button';
import {ThirdPartyTag} from 'actions';
import {isEmptyObject} from 'constants/Utils';
import {ThirdPartyTagStore, ContextStore} from 'stores';
import Message from 'weborama-ui-react/Message';
/**
 * Class Third Party Tag Form
 */
class ThirdPartyTagForm extends React.Component {
  /**
   * Constructor
   * @param {Object} props Properties
   */
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      label: props.thirdPartyTag ? props.thirdPartyTag.label : '',
      url: props.thirdPartyTag ? props.thirdPartyTag.code : '',
      type: props.thirdPartyTag ? props.thirdPartyTag.rule : 'imp',
      isActive: props.thirdPartyTag ? props.thirdPartyTag.is_active : 'YES',
      error: {},
      currentThirdPartyTag: undefined // Only used for comparing the current value from prop
    };

    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleLabelChange = this.handleLabelChange.bind(this);
    this.handleUrlChange = this.handleUrlChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleActiveChange = this.handleActiveChange.bind(this);
    this.handleRemoveThirdPartyTag = this.handleRemoveThirdPartyTag.bind(this);
    this.onThirdPartyStoreError = this.onThirdPartyStoreError.bind(this);
    this.onThirdPartyStoreChange = this.onThirdPartyStoreChange.bind(this);
  }

  /**
   * register Third Party Store change and error listener
   */
  componentDidMount() {
    // INFO: We set the max listeners to unlimited, to avoid a nodeJs max event listeners warning.
    // default this is 10 listeners. This component will be mounted more than 10 time, thus havind more than
    // 10 event listeners
    // Since there are lots of event listening action happening here, I'll keep it away from constructor at the moment
    ThirdPartyTagStore.setMaxListeners(0);
    ThirdPartyTagStore.addErrorListener(this.onThirdPartyStoreError);
    ThirdPartyTagStore.addChangeListener(this.onThirdPartyStoreChange);
  }

  componentDidUpdate() {
    if (!isEmptyObject(this.props.thirdPartyTag)
      && JSON.stringify(this.props.thirdPartyTag) !== JSON.stringify(this.state.currentThirdPartyTag)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        label: this.props.thirdPartyTag.label,
        url: this.props.thirdPartyTag.code,
        type: this.props.thirdPartyTag.rue,
        isActive: this.props.thirdPartyTag.is_active,
        loading: false,
        currentThirdPartyTag: this.props.thirdPartyTag
      });
    }
  }

  /**
   * register Third Party Store error listener
   */
  componentWillUnmount() {
    ThirdPartyTagStore.removeErrorListener(this.onThirdPartyStoreError);
    ThirdPartyTagStore.removeChangeListener(this.onThirdPartyStoreChange);
  }

  /**
   * Thirs Party store error callback
   */
  onThirdPartyStoreError(error) {
    if (this.state.loading) {
      this.setState({error, loading: false});
    }
  }

  /**
   * On change
   * @param {Object} data data object
   */
  onThirdPartyStoreChange() {
    if (isEmptyObject(this.props.thirdPartyTag) && this.state.loading) {
      this.setState({
        loading: false,
        label: '',
        url: '',
        type: 'imp',
        isActive: 'YES',
        error: {},
      });
    }
  }

  /**
   * Handles form submit
   */
  handleSubmit() {
    if (isEmptyObject(this.props.thirdPartyTag)) {
      ThirdPartyTag.add({
        accountId: ContextStore.routingParams().accountId,
        projectId: ContextStore.routingParams().projectId,
        campaignId: ContextStore.routingParams().campaignId,
        campaignAdSpaceId: !isEmptyObject(this.props.campaignAdSpace) ? this.props.campaignAdSpace.id : undefined,
        insertionId: !isEmptyObject(this.props.insertion) ? this.props.insertion.id : undefined,
        creativeId: !isEmptyObject(this.props.creative) ? this.props.creative.id : undefined,
        assignedAdPositionId: !isEmptyObject(this.props.assignedAdPosition) ? this.props.assignedAdPosition.id : undefined, // eslint-disable-line max-len
        url: this.state.url,
        type: this.state.type,
        label: this.state.label,
        isActive: this.state.isActive,
      });
    } else {
      ThirdPartyTag.update({
        accountId: ContextStore.routingParams().accountId,
        thirdPartyTagId: this.props.thirdPartyTag.id,
        url: this.state.url,
        type: this.state.type,
        label: this.state.label,
        isActive: this.state.isActive,
      });
    }
    this.setState({loading: true, error: {}});
  }

  /**
   * Changes type
   * @param {String} type tracking type
   */
  handleTypeChange(type) {
    this.setState({type});
  }

  /**
   * handle label change
   * @param {string} label label
   */
  handleLabelChange(label) {
    this.setState({label});
  }

  /**
   * Url
   * @param {string} url url
   */
  handleUrlChange(url) {
    this.setState({url});
  }

  /**
   * Handles active
   */
  handleActiveChange() {
    this.setState({
      isActive: this.state.isActive === 'YES' ? 'NO' : 'YES',
    });
  }

  /**
   * Handles removing a third party tag
   */
  handleRemoveThirdPartyTag() {
    ThirdPartyTag.remove({
      accountId: ContextStore.routingParams().accountId,
      thirdPartyTagId: this.props.thirdPartyTag.id,
    });
  }

  /**
   * Renders third party tag form
   */
  render() {
    return (
      <Form error={!isEmptyObject(this.state.error)} loading={this.state.loading} tiny onSubmit={this.handleSubmit}>
        <div className="fields">
          <Field label="Type">
            <DropDown selection onChange={this.handleTypeChange} defaultValue={this.state.type}>
              <Menu>
                <Item value="imp">Impression tracker</Item>
                <Item value="click">Click tracker</Item>
                <Item value="script_imp">Script for impression</Item>
              </Menu>
            </DropDown>
          </Field>
          <Field label="label">
            <Input
              value={this.state.label}
              placeHolder="label"
              onChange={this.handleLabelChange}
            />
          </Field>
          <Field ten wide label="url">
            <Input
              value={this.state.url}
              placeHolder="url"
              onChange={this.handleUrlChange}
            />
          </Field>
          <Field label={this.state.isActive === 'YES' ? 'Active' : 'Paused'}>
            <div className="ui tiny icon buttons">
              <Button
                active={this.state.isActive === 'YES'}
                green={this.state.isActive === 'YES'}
                orange={this.state.isActive === 'NO'}
                onClick={this.handleActiveChange}
              >
                {this.state.isActive === 'YES'
                  && <i className="play icon" />
                }
                {this.state.isActive === 'NO'
                  && <i className="pause icon" />
                }
              </Button>
            </div>
          </Field>
          <Field label="Actions">
            <div className="ui tiny icon buttons">
              <Button submit>
                <i className="save icon" />
              </Button>
              {!isEmptyObject(this.props.thirdPartyTag)
                && (
                <Button onClick={this.handleRemoveThirdPartyTag}>
                  <i className="trash icon" />
                </Button>
                )
              }
            </div>
          </Field>
        </div>
        <Message error>{this.state.error.message}</Message>
      </Form>
    );
  }
}

export default ThirdPartyTagForm;

ThirdPartyTagForm.propTypes = {
  thirdPartyTag: PropTypes.object,
  campaignAdSpace: PropTypes.object,
  insertion: PropTypes.object,
  creative: PropTypes.object,
  assignedAdPosition: PropTypes.object,
};
