import PropTypes from 'prop-types';
import React from 'react';
import Form, { Field } from 'weborama-ui-react/Form';
import Input from 'weborama-ui-react/Input';
import CopyToClipboard from 'components/CopyToClipboard';
import Monaco from 'components/Monaco';

export const JsTag = props => (
  <Form>
    <Field label="JavaScript tag">
      <Monaco
        code={props.jsTag}
        readOnly
        language="javascript"
        height="210"
        width="100%"
        theme="vs"
        contextMenu={false}
        minimap={false}
      />
      <div className="ui labels">
        <CopyToClipboard value={props.jsTag} />
      </div>
    </Field>
  </Form>
);

export default JsTag;

JsTag.propTypes = {
  jsTag: PropTypes.string.isRequired,
};

export const VastTag = props => (
  <Form>
    <Field label="Vast tag">
      <Input value={props.vastTag} readOnly />
      <div className="ui labels">
        <CopyToClipboard value={props.vastTag} />
      </div>
    </Field>
  </Form>
);

VastTag.propTypes = {
  vastTag: PropTypes.string.isRequired,
};

export const ImageTag = props => (
  <Form>
    <Field label="Image tag">
      <Input value={props.imageTag} readOnly />
      <CopyToClipboard value={props.imageTag} />
    </Field>
  </Form>
);

ImageTag.propTypes = {
  imageTag: PropTypes.string.isRequired,
};
