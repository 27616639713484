import React from 'react';

const EasterEgg = () => (
  <iframe
    title="easter egg"
    width="100%"
    height="447"
    src="https://www.youtube.com/embed/2uvnAfrf40w?autoplay=1"
    frameBorder="0"
    allowFullScreen
  />
);

export default EasterEgg;
