import PropTypes from 'prop-types';
import classnames from 'classnames';
/* eslint-disable */
import React from 'react';

// import 'stylesheets/modules/ui/paginator';
/**
 * Handles pagination
 * @date   2016-01-14
 * @param  {object}   props see propTypes
 * @return {jsx}      pagination
 */
const Paginator = (props, context) => {
  const paginationItems = [];
  let paginationItem;
  let leftChevron;
  let rightChevron;
  let itemStyle = {padding: 0};
  const MAX_PAGINATION_ITEMS = 5;

  for (let i = 1; i <= props.totalPages; i += 1) {
    paginationItems.push(i);
  }

  if (paginationItems.length > 1) {
    paginationItem = paginationItems.map((item, index) => {
      const boundClick = props.onPaginationChange.bind(this, item);
      const amountOfPageItems = MAX_PAGINATION_ITEMS;
      let className;
      let offsetLeft;
      let offsetRight;
      let defaultOffset;

      offsetLeft = offsetRight = defaultOffset = Math.round(amountOfPageItems / 2);

      if (props.page < defaultOffset) {
        offsetLeft = props.page;
        offsetRight = defaultOffset + defaultOffset - offsetLeft;
      } else if (props.page > props.totalPages - defaultOffset) {
        offsetRight = props.totalPages - props.page + 1;
        offsetLeft = defaultOffset + defaultOffset - offsetRight;
      }

      if (item > (props.page - offsetLeft) && item < props.page + offsetRight) {
        if (item === props.page) {
          className = 'active item ';
        } else {
          className = 'item';
        }
        return (
          <a key={item} className={className} onClick={boundClick}>{item}</a>
        );
      }
      return false;
    });
  } else {
    itemStyle = {display: 'none'};
  }

  let prevPage = props.page - 1;
  let LCBoundClick = props.onPaginationChange.bind(this, prevPage);
  if (props.page === 1) {
    leftChevron =  (<a className="icon disabled item"><i className="disbaled left chevron icon"/></a>);
  } else {
    leftChevron =  (<a className="icon item" onClick={LCBoundClick}><i className="left chevron icon"/></a>);
  }

  let nextPage = props.page + 1;
  let RCBoundClick = props.onPaginationChange.bind(this, nextPage);

  if (props.page === props.totalPages) {
    rightChevron = (<a className="disabled icon item"><i className="right chevron icon"/></a>);
  } else {
    rightChevron = (<a className="icon item" onClick={RCBoundClick}><i className="right chevron icon"/></a>);
  }

  const className = classnames({
    ui: true,
    inverted: props.inverted,
    borderless: true,
    text: true,
    ten: true,
    item: true,
    menu: true,
  });

  return (
    <div className={className} style={itemStyle}>
      {leftChevron}
      {paginationItem}
      {rightChevron}
    </div>
  );
};

export default Paginator;

Paginator.propTypes = {
  totalPages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onPaginationChange: PropTypes.func.isRequired,
  inverted: PropTypes.bool,
};

