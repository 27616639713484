import {dispatch} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const CreativeFormat = {
  /**
   * Dispatches action to retrieve a list of creative formats
   * @date   2016-04-16
   */
  list() {
    dispatch({actionType: AppConstants.LIST_CREATIVE_FORMATS});
  },
};

export default CreativeFormat;
