import {dispatch} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const FileType = {
  /**
   * Dispatches an action to list file types
   * @date   2016-08-18
   */
  list() {
    dispatch({
      actionType: AppConstants.LIST_FILE_TYPES,
    });
  },
};

export default FileType;
