import PropTypes from 'prop-types';
import React from 'react';
import ContentEditable from 'react-contenteditable';
import Button from 'weborama-ui-react/Button';
import { LandingUrlStore } from 'stores';
/**
 * Class Editable Url
 */
class EditableUrl extends React.Component {
  /**
   * constructor
   * @date   2016-12-01
   * @param  {Object}   props Properties
   */
  constructor(props) {
    super(props);

    this.state = {
      url: props.url,
      hasFocus: false,
      saveLoading: false,
      removeLoading: false,
      error: {},
    };

    this.handleUrlChange = this.handleUrlChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateState = this.updateState.bind(this);
    this.onLandingUrlStoreError = this.onLandingUrlStoreError.bind(this);

    LandingUrlStore.addErrorListener(this.onLandingUrlStoreError);
  }

  componentDidMount() {
    this.updateState();
  }

  componentDidUpdate() {
    this.updateState();
  }

  componentWillUnmount() {
    LandingUrlStore.removeErrorListener(this.onLandingUrlStoreError);
  }

  onLandingUrlStoreError() {
    this.setState({
      saveLoading: false,
    }); // Should only happen in update situations, when an update is rejected by the server
  }

  updateState() {
    if (this.props.loading !== this.state.saveLoading && this.props.url !== this.state.url) {
      this.setState({ saveLoading: this.props.loading });
    }
    if (JSON.stringify(this.props.error) !== JSON.stringify(this.state.error)) {
      this.setState({ error: this.props.error });
    }
  }

  /**
   * Returns a "react-friendly" editable url (with contentEditable)
   * @date   2016-11-28
   * @return {String}   editable content string
   */
  getUrl() {
    if (this.props.url === ''
      || this.props.url === null) {
      return `<i style="color: lightgray">Landing url is inherited from ${this.props.parent}</i>`;
    }
    return this.state.url;
  }

  /**
   * Handles url change
   * @date   2016-11-28
   * @param  {Object}   e event object
   */
  handleUrlChange(e) {
    // There was apparently an issue in the past where ContentEditable would sometimes return <span> and <div> tags
    // This is a better fix than previous one because using innerHTML caused bugs where content would not be selectable
    // in some scenarios such as 'https://bs.serving-sys.com/Serving/adServer.bs?cn=trd&pli=1075153595&adid=1079692650&ord=%%CACHEBUSTER%%'
    const eventString = e.target.value.trim().replace(/<[^>]*>?/gm, '');
    this.setState({ url: eventString });
  }

  /**
   * Clear the input if it does not contain a url yet
   * @date   2016-11-30
   */
  handleFocus() {
    this.setState({ hasFocus: true });
  }

  /**
   * Handles saving adSpace-campaign url
   * @date   2016-11-28
   */
  handleBlur() {
    this.setState({ hasFocus: false });
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }

  /**
   * Handles submit
   */
  handleSubmit() {
    if (this.props.onSubmit) {
      this.setState({ saveLoading: true }); this.props.onSubmit(this.state.url, this.props.baseObject);
    }
  }

  /**
   * Renders an editable url
   * @date   2016-12-01
   * @return {array}   Nodes
   */
  render() {
    const style = {
      minWidth: '100%',
      display: 'inline-flex',
      marginTop: '4px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    };
    let { url } = this.state;
    if (this.state.url === '' && !this.state.hasFocus) {
      url = `<i style="color: lightgray">Landing url is inherited from ${this.props.parent}</i>`;
    }
    return (
      <span style={style}>
        <span style={{ width: '100%' }}>
          <ContentEditable
            onKeyUp={(event) => {
              if (event.keyCode === 13) {
                this.handleSubmit();
              }
            }}
            html={url}
            disabled={false} // use true to disable edition
            onChange={this.handleUrlChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
          />
        </span>
        <Button
          basic
          mini
          icon
          right
          floated
          loading={this.state.saveLoading}
          onClick={() => this.handleSubmit()}
        >
          <i className="save icon" />
        </Button>
        {this.props.onDelete && (
        <Button
          basic
          mini
          icon
          right
          floated
          loading={this.state.removeLoading}
          onClick={() => { this.setState({ removeLoading: true }); this.props.onDelete(this.props.baseObject); }}
        >
          <i className="close icon" />
        </Button>
        )
        }
      </span>
    );
  }
}

export default EditableUrl;

EditableUrl.propTypes = {
  url: PropTypes.string,
  parent: PropTypes.string,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  loading: PropTypes.bool,
  onBlur: PropTypes.func,
  error: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
  baseObject: PropTypes.object, // Used for LandingUrl
  // label: PropTypes.string,
};
