import React from 'react';
import Dropdown from 'weborama-ui-react/Dropdown';
import Menu from 'weborama-ui-react/Menu';
import PropTypes from 'prop-types';
import Icon from 'weborama-ui-react/Icon';

export default function CampaignVerticals({onChange}) {
  const verticalsArr = ['Automotive',
    'Books and Literature',
    'Business and Finance',
    'Careers',
    'Content Channel',
    'Content Language',
    'Content Media Format',
    'Content Source',
    'Content Source Geo',
    'Content Type',
    'Education',
    'Events and Attractions',
    'Family and Relationships',
    'Fine Art',
    'Food and Drink',
    'Healthy Living',
    'Hobbies and Interests',
    'Home and Garden',
    'Medical Health',
    'Movies',
    'Music and Audio',
    'News and Politics',
    'Personal Finance',
    'Pets',
    'Pop Culture',
    'Real Estate',
    'Religion and Spirituality',
    'Science',
    'Shopping',
    'Sports',
    'Style and Fashion',
    'Technology and Computing',
    'Television',
    'Travel',
    'Video Gaming'];
  return (
    <Dropdown
      inline={false}
      selection
      search
      required
      loading={false}
      className="campaign-verticals-dropdown"
      onChange={(e) => {
        onChange(e);
      }}
    >
      <Icon dropdown />
      <Menu>
        {verticalsArr.map((item) => <div key={`cl-${item}`} className="item" data-value={item}>{item}</div>)}
      </Menu>
    </Dropdown>
  );
}

CampaignVerticals.propTypes = {
  onChange: PropTypes.func,
};
