import PropTypes from 'prop-types';
import React from 'react';
import {copyToClipboard} from 'constants/Utils';
import './CopyToClipBoard.sass';
// TODO: see if we can rename or the stateless function or the copyToClipboard function.. (duplicate)
// TODO: Copied is not based on succes, but on click, see if we can extends this functionality with success and error
// copy-to-clipboard-label
const CopyToClipboard = (props) => {
  const handleCopy = (e) => {
    copyToClipboard(props.value);
    const {target} = e;
    target.classList.add('copied');
    setTimeout(() => { target.classList.remove('copied'); }, 1300);
  };
  return (
    <div
      title="copy to clipboard"
      style={{cursor: 'pointer'}}
      className="ui top pointing label copy-to-clipboard-label cursor-pointer"
      onClick={handleCopy}
    >
      <i className="copy icon" />
      {'Copy to clipboard'}
    </div>
  );
};

export default CopyToClipboard;

CopyToClipboard.propTypes = {
  value: PropTypes.string.isRequired,
};
