import {dispatch} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

export default {
  /**
   * Browse folders (e.g. assign insertion <-> folders, withouth disturbing navigation)
   * @date   2016-10-12
   * @param  {number}   accountId accountId
   */
  browseFolders(accountId) {
    dispatch({
      actionType: AppConstants.BROWSE_FOLDERS,
      accountId,
    });
  },
  /**
   * Browse creatives
   * @date   2016-10-12
   * @param  {Number}   accountId accountId
   * @param  {Number}   folderId  folderId
   */
  browseCreatives(accountId, folderId) {
    dispatch({
      actionType: AppConstants.BROWSE_CREATIVES,
      accountId,
      folderId,
    });
  },
  /**
   * Assigns creatives and insertions
   * @date   2016-10-13
   * @param  {Number}   accountId  Account Id
   * @param  {array}   insertions  insertion ids
   * @param  {array}   creatives  creative ids
   */
  assignCreativesInsertions(accountId, insertions, creatives) {
    dispatch({
      actionType: AppConstants.ASSIGN_CREATIVES_INSERTIONS,
      accountId,
      insertions,
      creatives,
    });
  },
  /**
   * unassign insertion from creative
   * @date   2016-10-27
   * @param  {Number}   accountId   accountId
   * @param  {Number}   creativeId  creativeId
   * @param  {Number}   insertionId insertionId
   */
  unAssignInsertion(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.UNASSIGN_INSERTION,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches an action to unassign a creative from an insertion
   * @date   2016-11-15
   * @param  {Object}   data   data object
   */
  unAssignCreative(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.UNASSIGN_CREATIVE,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispaches an action to list assigned insertions
   * @date   2016-10-20
   * @param  {Number}   accountId  Account Id
   * @param  {Number}   creativeId Creative Id
   */
  listAssignedInsertions(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.LIST_ASSIGNED_INSERTIONS,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches action to retrieve a list of assigned creatives
   * @date   2016-10-28
   * @param  {Number}   accountId   Account Id
   * @param  {Number}   campaignId  campaignId
   */
  listAssignedCreatives(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.LIST_ASSIGNED_CREATIVES,
    }, data);

    dispatch(obj);
  },
  /**
   * Dispatches action to set a default creative to an insertion
   * @date  2016-11-09
   * @param {Number}   accountId   account id
   * @param {Number}   insertionId insertion id
   * @param {Number}   creativeId  creative Id
   */
  setDefaultCreative(accountId, insertionId, creativeId) {
    dispatch({
      actionType: AppConstants.SET_DEFAULT_CREATIVE,
      accountId,
      insertionId,
      creativeId,
    });
  },
};
