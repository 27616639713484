import PropTypes from 'prop-types';
import React from 'react';
import {CreativeStore, ContextStore, CreativeTypeStore, CreativeFormatStore} from 'stores';
import {Creative} from 'actions';
import CreativeLabels from 'components/CreativeLabels';
import Dropdown from 'weborama-ui-react/Dropdown';
import Item from 'weborama-ui-react/Item';
import Menu from 'weborama-ui-react/Menu';
import Button from 'weborama-ui-react/Button';
import {isEmptyObject, getObjectInArray} from 'constants/Utils';
import Form, {Field} from 'weborama-ui-react/Form';
import Input from 'weborama-ui-react/Input';
import {creativeForm} from 'constants/FormValidation';
import Icon from 'weborama-ui-react/Icon';

/**
 * create a new creative;
 */
class CreativeForm extends React.Component {

  constructor(props) {
    super(props);

    let action = 'add';
    let creativeTypeId = 6;
    let creativeFormatId = 15;
    let filteredCreativeFormats = [];
    let label = '';
    let creativeLabels = [];

    if (!isEmptyObject(props.creative)) {
      creativeFormatId = props.creative.creative_format_id;
      const creativeFormat = getObjectInArray(CreativeFormatStore.getCreativeFormats(), 'id', props.creative.creative_format_id);
      creativeTypeId = creativeFormat.creative_type_id;
      filteredCreativeFormats = CreativeFormatStore.getCreativeFormats().filter((item) => {
        if (parseInt(item.creative_type_id, 10) === parseInt(creativeFormat.creative_type_id, 10)) {
          return item;
        }
        return false;
      });
      ({label} = props.creative);
      creativeLabels = props.creative.creative_labels.slice();
      action = 'update';
    }

    this.state = {
      loading: {
        self: false,
        creativeTypes: false,
        creativeFormats: false,
      },
      action,
      creativeTypeId: parseInt(creativeTypeId, 10),
      creativeFormatId: parseInt(creativeFormatId, 10),
      creativeTypes: CreativeTypeStore.getCreativeTypes(),
      creativeFormats: CreativeFormatStore.getCreativeFormats(),
      filteredCreativeFormats,
      label,
      creativeLabels,
      error: {},
      initialCreativeForm: { // Only used for validation
        label: props.creative ? props.creative.label : '', // When it's a new form, we have no creative prop
        creativeFormatId: parseInt(creativeFormatId, 10),
        creativeLabels: creativeLabels,
        creativeId: props.creative ? props.creative.id : 0, // When it's a new form, we have no creative prop
      },
    };

    if (action === 'update') {
      Object.assign(this.state.initialCreativeForm, { creativeId: props.creative.id });
    }

    this.creativeStoreChange = this.creativeStoreChange.bind(this);
    this.handleCreativeTypeChange = this.handleCreativeTypeChange.bind(this);
    this.handleCreativeFormatChange = this.handleCreativeFormatChange.bind(this);
    this.handleCreativeLabelChange = this.handleCreativeLabelChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onCreativeStoreError = this.onCreativeStoreError.bind(this);
    this.creativeFormChangedAndValid = this.creativeFormChangedAndValid.bind(this);

    CreativeStore.addChangeListener(this.creativeStoreChange);
    CreativeStore.addErrorListener(this.onCreativeStoreError);
  }

  /**
   * Unregister change event listeners
   */
  componentWillUnmount() {
    CreativeStore.removeChangeListener(this.creativeStoreChange);
    CreativeStore.removeErrorListener(this.onCreativeStoreError);
  }

  /**
   * On creative store error
   * @param  {Object}   error Error object
   */
  onCreativeStoreError(error) {
    const {loading} = this.state;
    loading.self = false;
    this.setState({loading, error});
  }

  /**
   * invoked on creative store change
   * @date   2016-08-08
   * @param  {object}   data   data object
   */
  creativeStoreChange() {
    this.setState({loading: {self: false}});
  }

  /**
   * Handle creative type change
   * @param  {String}   value        value
   */
  handleCreativeTypeChange(value) {
    const creativeFormats = this.state.creativeFormats.filter((item) => {
      if (parseInt(item.creative_type_id, 10) === parseInt(value, 10)) {
        return item;
      }
      return false;
    });
    this.setState({
      filteredCreativeFormats: creativeFormats,
      creativeTypeId: parseInt(value, 10),
    });
  }

  /**
   * Handles change in creative format
   * @param  {String}   value        value
   */
  handleCreativeFormatChange(value) {
    this.setState({creativeFormatId: parseInt(value, 10)});
  }

  /**
   * Handles creative labels change
   * @param  {Array}   e labels
  */
  handleCreativeLabelChange(e) {

    // eslint-disable-next-line quotes
    if (e[0] === "" || e[0] === '') {
      // When a tag is added and removed, the last event is an empty string.
      // When an empty string is passed to the state, it causes the component to think that
      // current state is different than initial state we set at the start. This is to fix this false signal
      this.setState({creativeLabels: []});
    } else {
      this.setState({creativeLabels: e});
    }
  }

  /*
  * Hanles form is changed from its initial state and is valid
  */
  creativeFormChangedAndValid() {

    const currentCreativeForm = {
      label: this.state.label,
      creativeFormatId: this.state.creativeFormatId,
      creativeLabels: this.state.creativeLabels,
    };

    if (this.state.action === 'update') {
      Object.assign(currentCreativeForm, { creativeId: this.props.creative.id });
    }

    // console.log(JSON.stringify(currentCreativeForm));
    // console.log(JSON.stringify(this.state.initialCreativeForm));
    // console.log(JSON.stringify(currentCreativeForm) !== JSON.stringify(this.state.initialCreativeForm));
    if (JSON.stringify(currentCreativeForm) !== JSON.stringify(this.state.initialCreativeForm)
    && this.state.label.length > 0 && this.state.creativeTypeId) { // Needs to be better!
      return false;
    }
    return true;
  }

  /**
   * submits a new creative
   */
  handleFormSubmit() {
    if (!this.state.loading.self) {
      if (this.state.action === 'add') {
        Creative.add({
          accountId: ContextStore.routingParams().accountId,
          folderId: this.props.folderId,
          creativeFormatId: this.state.creativeFormatId,
          label: this.state.label,
          creativeLabels: this.state.creativeLabels,
        });
      } else if (this.state.action === 'update') {
        Creative.update({
          accountId: ContextStore.routingParams().accountId,
          folderId: ContextStore.routingParams().folderId,
          creativeId: this.props.creative.id,
          label: this.state.label,
          creativeFormatId: this.state.creativeFormatId,
          creativeLabels: this.state.creativeLabels,
        });
      }
      this.setState({loading: {self: true}});
    }
  }

  /**
   * Renders new creative
   * @date   2016-02-01
   * @return {JSX} JSX
   */
  render() {
    const creativeTypeItems = this.state.creativeTypes.map(item => (
      <Item value={item.id} key={`cti-${item.id}`}>
        {item.label}
      </Item>
    ));

    const creativeFormatItems = this.state.filteredCreativeFormats.map(item => (
      <Item value={item.id} key={`cfi-${item.id}`}>
        {item.label}
      </Item>
    ));

    let errorMessage;
    if (!isEmptyObject(this.state.error)) {
      errorMessage = (
        <p className="error">{this.state.error.message}</p>
      );
    }
    return (
      <Form
        onSubmit={this.handleFormSubmit}
        validation={creativeForm}
        onValid={this.handleFormValid}
        onInvalid={this.handleFormInvalid}
      >
        <Field required label="Name">
          <Input
            name="label"
            placeHolder="Name"
            value={this.state.label}
            onChange={(label) => { this.setState({label}); }}
          />
        </Field>
        <Field required label="Select creative type">
          <Dropdown
            items={this.state.creativeTypes}
            loading={this.state.loading.creativeTypes}
            inline={false}
            selection
            disabled={this.state.action === 'update'}
            onChange={this.handleCreativeTypeChange}
            activeItem={this.state.creativeTypeId}
            defaultValue={this.state.creativeTypeId}
          >
            <Icon dropdown />
            <Menu>
              {creativeTypeItems}
            </Menu>
          </Dropdown>
        </Field>
        <Field required label="Select creative format">
          <Dropdown
            items={this.state.filteredCreativeFormats}
            loading={this.state.loading.creativeFormats}
            inline={false}
            selection
            activeItem={this.state.creativeFormatId}
            onChange={this.handleCreativeFormatChange}
            defaultValue={this.state.creativeFormatId}
          >
            <Icon dropdown />
            <Menu>
              {creativeFormatItems}
            </Menu>
          </Dropdown>
        </Field>
        <Field label="Creative labels">
          <CreativeLabels creative={this.props.creative} onChange={this.handleCreativeLabelChange} />
        </Field>
        <Field>
          <Button
            fluid
            primary
            loading={this.state.loading.self}
            onClick={this.handleFormSubmit}
            disabled={this.creativeFormChangedAndValid()}
          >
            {`${this.state.action === 'update' ? 'Update' : 'Submit'}`}
          </Button>
        </Field>
        {errorMessage}
      </Form>
    );
  }
}

CreativeForm.propTypes = {
  creative: PropTypes.object,
  folderId: PropTypes.number
};

export default CreativeForm;
