import BaseStore from 'stores/BaseStore';
import {register} from 'dispatchers/AppDispatcher';
import {AdServer} from 'constants/ServerConstants';
import AppConstants from 'constants/AppConstants';

const AdServerStore = Object.assign({}, BaseStore, {
  adServers: [],
  /**
   * returns array with countrys
   */
  getAdServers() {
    return this.adServers;
  },
  /**
   * Fetches a list of countries
   */
  listAdServers() {
    const onSuccess = (data) => {
      this.adServers = data.data.slice();
      this.emitChange({action: AppConstants.LIST_ADSERVERS});
    };
    const onError = (message) => {
      this.emitError({action: AppConstants.LIST_ADSERVERS, message});
    };
    this.post({
      url: AdServer.LIST,
      onSuccess,
      onError,
    });
  },
  dispatcherIndex: register((action) => {
    if (action.actionType === AppConstants.LIST_ADSERVERS) {
      AdServerStore.listAdServers();
    }
    return true;
  }),
});

export default AdServerStore;
