import React from 'react';
import Button from 'weborama-ui-react/Button';
import Divider from 'weborama-ui-react/Divider';
import Segment from 'weborama-ui-react/Segment';
import { Column } from 'weborama-ui-react/Grid';
import Header from 'weborama-ui-react/Header';
import PropTypes from 'prop-types';
import ContextStore from '../../stores/ContextStore';
import CopyToClipboard from '../CopyToClipboard';

export default function CreativeWarningWCM({creative, selectedFolder}) {
  const accountId = parseInt(ContextStore.routingParams().accountId, 10);
  const linkToWCM = `https://adap-interface.weborama.com/${accountId}/default/user/autosignin?product_account_id=${accountId}&kc_idp_hint=webo-oidc`;

  return (
    <Column style={{margin: '10vh auto', width: '50vw', height: '60vh'}}>
      <Segment style={{padding: '20px'}}>
        <Header h1>
          Creative cannot be managed in Admire
        </Header>
        <p style={{marginLeft: '1vw'}}>This creative is made in WCM, we do not support this creative due to file management.</p>
        <a href={linkToWCM} style={{marginLeft: '2vw'}}>Click this link to open this account in WCM</a><br></br>
        <div style={{marginLeft: '5vw'}}>
          <CopyToClipboard value={linkToWCM} />
        </div>
        <div style={{paddingTop: '5vh'}}>
          <Divider />
        </div>
        <Header h2>
          Creative Info
        </Header>
        <table style={{padding: 30, paddingTop: 10}}>
          <tbody>
            <tr>
              <td>Account:</td>
              <td><p style={{marginLeft: '2vw'}}>{accountId}</p></td>
            </tr>
            <tr>
              <td>Folder:</td>
              <td><p style={{marginLeft: '2vw'}}>{`${selectedFolder?.label} (${creative?.folder_id})`}</p></td>
            </tr>
            <tr>
              <td>Creative:</td>
              <td><p style={{marginLeft: '2vw'}}>{`${creative?.label} (${creative?.id})`}</p></td>
            </tr>
          </tbody>
        </table>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <Button primary onClick={() => { window.location.href = `/account/${accountId}/cl/folder/${creative?.folder_id}`; }}>
            Back to folder overview
          </Button>
        </div>
      </Segment>
    </Column>

  );
}
CreativeWarningWCM.propTypes = {
  creative: PropTypes.object,
  selectedFolder: PropTypes.object,
};
