import React from 'react';
import { Favorite } from 'actions';
import Table from 'weborama-ui-react/Table';
import { getObjectInArray } from 'constants/Utils';
import Segment from 'weborama-ui-react/Segment';
import Flag from 'weborama-ui-react/Flag';
import Button from 'weborama-ui-react/Button';
import Icon from 'weborama-ui-react/Icon';
import { ContextStore, FavoriteStore, CountryStore } from 'stores';
import AppConstants from 'constants/AppConstants';

/**
 * Class FavoriteList
 */
class FavoriteWrapper extends React.Component {
  /**
   * Sorts the favorites into cm cl and tag favorites
   * @param {Array} favorites Favorites
   */
  static sortFavorites(favorites) {
    const cmFavorites = [];
    const tagFavorites = [];
    const clFavorites = favorites.filter((favorite) => {
      if (favorite.url.split('/')[3] === 'cl') {
        return favorite;
      } if (favorite.url.split('/')[3] === 'cm') {
        cmFavorites.push(favorite);
      } else {
        tagFavorites.push(favorite);
      }
      return false;
    });

    return {
      clFavorites,
      cmFavorites,
      tagFavorites,
    };
  }

  /**
   * Groups favorites by account
   * @param {Array} favorites Favorites
   */
  static orderFavoritesByAccount(favorites) {
    const accounts = [];

    favorites.filter((item) => {
      const favorite = Object.assign({}, item);
      const accountObj = getObjectInArray(accounts, 'id', favorite.account.id);
      if (!accountObj) {
        const account = Object.assign({}, favorite.account);
        delete favorite.account;
        const items = [];
        items.push(favorite);
        account.items = items.slice();
        accounts.push(account);
      } else {
        delete favorite.account;
        accountObj.items.push(favorite);
      }
      return false;
    });
    return accounts;
  }

  /**
   * constructor
   * @date   2017-01-21
   */
  constructor() {
    super();

    this.state = {
      favorites: [],
    };

    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.onFavoriteStoreChange = this.onFavoriteStoreChange.bind(this);

    FavoriteStore.addChangeListener(this.onFavoriteStoreChange);
  }

  componentWillUnmount() {
    FavoriteStore.removeChangeListener(this.onFavoriteStoreChange);
  }

  onFavoriteStoreChange(data) {
    if (data.action === AppConstants.LIST_FAVORITES) {
      const favorites = FavoriteWrapper.orderFavoritesByAccount(FavoriteStore.getFavorites());
      // Object.assign(favorites, FavoriteWrapper.orderFavoritesByAccount(ContextStore.favorites()));
      this.setState({favorites});
    }
  }

  /**
   * Filter change
   * @param {Object} e proxy
   */
  handleFilterChange(e) {
    if (this.state.favorites) {
      const filter = e.target.value.toLowerCase();
      //  const insertions = props.insertions.filter((insertion) => {
      const favorites = this.state.favorites.filter((favorite) => {
        if (favorite.account.label.toLowerCase().indexOf(filter) > -1
          || favorite.account.id.indexOf(filter) > -1) {
          return favorite;
        }
        if (Object.prototype.hasOwnProperty.call(favorite, 'creative')
          && (favorite.creative.label.toLowerCase().indexOf(filter) > -1 || favorite.creative.id.indexOf(filter) > -1)) {
          return favorite;
        }
        if (Object.prototype.hasOwnProperty.call(favorite, 'campaign')
          && (favorite.campaign.label.toLowerCase().indexOf(filter) > -1 || favorite.campaign.id.indexOf(filter) > -1)) {
          return favorite;
        }
        return false;
      });
      this.setState(FavoriteWrapper.sortFavorites(favorites));
    }
  }

  /**
   * Renders favorites list
   * @date   2017-01-21
   * //TODO: remove settingsManager form active comparison. Make a default. more future proof
   * @return {Array}   nodes
   */
  render() {
    let table = <p style={{ whiteSpace: 'nowrap' }}>No favorites found!</p>;
    if (this.state.favorites.length > 0) {
      table = this.state.favorites.map((account) => {
        const row = account.items.map((favorite) => {
          const rowObj = {};
          if (Object.hasOwnProperty.call(favorite, 'folder')) {
            rowObj.label = `${favorite.folder.label} / ${favorite.creative.label}`;
            rowObj.tag = 'CL';
            rowObj.color = 'powderblue';
          }
          if (Object.hasOwnProperty.call(favorite, 'project')) {
            rowObj.label = `${favorite.project.label} / ${favorite.campaign.label}`;
            rowObj.tag = 'CM';
            rowObj.color = 'bisque';
            if (favorite.url.indexOf('public') > -1) {
              rowObj.tag = 'TM';
              rowObj.color = 'gainsboro';
            }
          }
          rowObj.url = favorite.url;
          return (
            <tr key={favorite.id} className="single line">
              <td className="collapsing">
                <Button mini circular icon onClick={() => { Favorite.removeFavorite(favorite.id); }}>
                  <Icon trash />
                </Button>
              </td>
              <td className="center aligned" style={{ fontWeight: 'bold', color: 'white', background: rowObj.color }}>
                {rowObj.tag}
              </td>
              <td>
                <a onClick={() => { ContextStore.router().push({ pathname: rowObj.url }); }} style={{ cursor: 'pointer' }}>
                  {rowObj.label}
                </a>
              </td>
            </tr>
          );
        });
        const country = getObjectInArray(CountryStore.getCountries(), 'id', account.country_id);
        const flagCode = country && { [country.code2.toLowerCase()]: true };
        return (
          <Table very basic key={account.id}>
            <thead className="full-width">
              <tr>
                <th colSpan="3">
                  <Flag {...flagCode} />
                  {account.label}
                </th>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <th className="fourteen wide" />
              </tr>
            </thead>
            <tbody>
              {row}
            </tbody>
          </Table>
        );
      });
    }
    return (
      <Segment padded>
        {table}
      </Segment>
    );
  }
}

export default FavoriteWrapper;
