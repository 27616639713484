import BaseStore from 'stores/BaseStore';
import ServerConstants from 'constants/ServerConstants';
import {register} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';
/**
 * CreativeTypeStore
 */
const CreativeFormatStore = Object.assign({}, BaseStore, {
  creativeFormats: [],
  /**
   * Returns list of creative Formats
   * @date   2016-04-16
   * @return {Array}   creative formats
   */
  getCreativeFormats() {
    return this.creativeFormats;
  },
  /**
   * Fetches a list of creative types.
   * @date   2016-02-03
   */
  listCreativeFormats() {
    const onSuccess = (data) => {
      this.creativeFormats = data.data.slice().sort((a, b) => a.label.localeCompare(b.label)); // Sort form A-Z
      this.emitChange({action: AppConstants.LIST_CREATIVE_FORMATS});
    };

    const onError = (message) => {
      this.emitError({action: AppConstants.LIST_CREATIVE_FORMATS, message});
    };

    this.post({
      url: ServerConstants.CREATIVE_LIBRARY.CREATIVE_FORMATS.LIST,
      onSuccess,
      onError,
    });
  },

  dispatcherIndex: register((action) => {
    if (action.actionType === AppConstants.LIST_CREATIVE_FORMATS) {
      CreativeFormatStore.listCreativeFormats();
    }
    return true;
  }),
});

export default CreativeFormatStore;
