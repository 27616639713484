/* eslint-disable no-else-return */
import PropTypes from 'prop-types';
import React from 'react';
import { Favorite } from 'actions';
import { FavoriteStore, ContextStore } from 'stores';
import { isEmptyObject } from 'constants/Utils';
import classnames from 'classnames';
import Button from 'weborama-ui-react/Button';
import AppConstants from 'constants/AppConstants';

/**
 * Class FavouriteButton
 */
class FavoriteButton extends React.Component {
  static getFavoriteObj() {
    let isFavorite = false;
    let favorite = {};

    FavoriteStore.getFavorites().forEach((favItem) => {
      if (parseInt(favItem.account.id, 10) === parseInt(ContextStore.routingParams().accountId, 10)) {
        if (ContextStore.routingParams().active === 'cl' && !isEmptyObject(favItem.creative)
          && parseInt(favItem.creative.id, 10) === parseInt(ContextStore.routingParams().creativeId, 10)) {
          isFavorite = true;
          favorite = favItem;
        } else if (ContextStore.routingParams().active === 'cm' && !isEmptyObject(favItem.campaign)
          && parseInt(favItem.campaign.id, 10) === parseInt(ContextStore.routingParams().campaignId, 10)
          && favItem.url.indexOf('/public/tag') === -1) {
          isFavorite = true;
          favorite = favItem;
        } else if (ContextStore.routingParams().active === 'tags' && !isEmptyObject(favItem.campaign)) {
          if (favItem.url.indexOf(encodeURIComponent(ContextStore.routingParams().token)) > -1) {
            isFavorite = true;
            favorite = favItem;
          }
        }
      }
    });
    return { isFavorite, favorite };
  }

  /**
   * constructor
   * @date   2017-01-19
   * @param  {Object}   props properties
   */
  constructor(props, context) {
    super(props, context);

    const cntx = context;

    if (props.favFromProps) {
      cntx.params = Object.assign({}, ContextStore.routingParams(), props.params);
    }
    let favObj = {
      isFavorite: undefined,
      favorite: undefined,
    };
    if (ContextStore.routingParams()) {
      favObj = FavoriteButton.getFavoriteObj(cntx);
    }

    this.state = {
      loading: false,
      isFavorite: favObj.isFavorite,
      favorite: favObj.favorite,
      favorites: [],
      oldContextParams: {},
    };

    this.handleFavorite = this.handleFavorite.bind(this);
    this.onFavoriteError = this.onFavoriteError.bind(this);
    this.calculateState = this.calculateState.bind(this);
    this.onFavoriteStoreChange = this.onFavoriteStoreChange.bind(this);

    FavoriteStore.addChangeListener(this.onFavoriteStoreChange);
    FavoriteStore.addErrorListener(this.onFavoriteError);
  }

  componentDidUpdate() {
    if ((JSON.stringify(FavoriteStore.getFavorites()) !== JSON.stringify(this.state.favorites)
      || (JSON.stringify(ContextStore.routingParams()) !== JSON.stringify(this.state.oldContextParams)))
      && ContextStore.routingParams() !== undefined) {
      this.calculateState();
    }
  }

  calculateState() {
    const cntx = Object.assign({}, ContextStore.routingParams());

    if (this.props.favFromProps) {
      cntx.params = Object.assign({}, ContextStore.routingParams(), this.props.params);
    }

    const favObj = FavoriteButton.getFavoriteObj(cntx);

    this.setState({
      favorites: FavoriteStore.getFavorites(),
      favorite: favObj.favorite,
      isFavorite: favObj.isFavorite,
      loading: false,
      oldContextParams: ContextStore.routingParams(),
    });
  }

  onFavoriteStoreChange(data) {
    if (data.action === AppConstants.LIST_FAVORITES) {
      this.calculateState();
    }
  }

  /**
   * Favorite store error callback
   * @date   2017-01-20
   */
  onFavoriteError() {
    this.setState({ loading: false });
  }

  /**
   * Handles (un)-favourite
   * @date   2017-01-19
   */
  handleFavorite() {
    this.setState({ loading: true });
    if (this.state.isFavorite) {
      Favorite.removeFavorite(this.state.favorite.id);
    } else {
      const data = {
        url: ContextStore.location() ? ContextStore.location().pathname : this.props.location.pathname,
        account_id: ContextStore.routingParams().accountId,
      };
      if (ContextStore.routingParams().active === 'cl') {
        data.folder_id = ContextStore.routingParams().folderId;
        data.creative_id = ContextStore.routingParams().creativeId;
      } else if (ContextStore.routingParams().active === 'cm' || ContextStore.routingParams().active === 'tags') {
        data.project_id = this.props.favFromProps ? this.props.params.projectId : ContextStore.routingParams().projectId;
        data.campaign_id = this.props.favFromProps ? this.props.params.campaignId : ContextStore.routingParams().campaignId;
      }
      Favorite.addFavorite(data);
    }
  }

  componentWillUnmount() {
    FavoriteStore.removeErrorListener(this.onFavoriteError);
    FavoriteStore.removeChangeListener(this.onFavoriteStoreChange);
  }

  /**
   * Renders Favorite button
   */
  render() {
    const iconClassNames = classnames({
      yellow: this.state.isFavorite,
      black: !this.state.isFavorite,
      small: this.props.small,
      spinner: this.state.loading,
      loading: this.state.loading,
      star: !this.state.loading,
      icon: true,
    });
    if (!this.props.isMenuItem) {
      return (
        <Button
          disabled={this.props.disabled}
          basic
          icon
          onClick={this.handleFavorite}
          toolTipPosition={this.props.toolTipPosition || 'left center'}
          toolTip={this.state.isFavorite ? 'Remove from favorites' : 'Add to favorites'}
        >
          <i className={iconClassNames} />
        </Button>
      );
    } else if (this.props.isMenuItem) {
      return (
        <span
          data-position={this.props.toolTipPosition || 'bottom left'}
          data-tooltip={this.state.isFavorite ? 'Remove from favorites' : 'Add to favorites'}
          data-inverted=""
        >
          <a
            className="active item"
            onClick={this.handleFavorite}
          >
            <i className={iconClassNames} />
          </a>
        </span>
      );
    }
    return null;
  }
}

export default FavoriteButton;

FavoriteButton.propTypes = {
  favFromProps: PropTypes.bool,
  small: PropTypes.bool,
  params: PropTypes.object,
  toolTipPosition: PropTypes.string,
  isMenuItem: PropTypes.bool,
  location: PropTypes.object,
  disabled: PropTypes.bool,
};
