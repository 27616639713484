/* eslint-disable import/no-duplicates */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ContextStore, AdElementStore } from 'stores';
import Icon from 'weborama-ui-react/Icon';
import Item from 'weborama-ui-react/Item';
import AppConstants from 'constants/AppConstants';
import { UIActions } from 'actions';

function MoveAdElementsButton({ creative, adPositions, toolTipPosition, disabledMoveElements }) {

  const [selectedAdElements, setSelectedAdElements] = useState([]);

  function onAdElementStoreChange(data) {
    if (data.action === AppConstants.SELECT_ADELEMENTS) {
      const tempArr = [];
      for (const value in data.data.adElements) {
        if (Object.prototype.hasOwnProperty.call(data.data.adElements, value) && value.toLowerCase() !== 'actiontype') {
          tempArr.push(data.data.adElements[value]);
        }
      }
      setSelectedAdElements(tempArr);
    }

    if (data.action === AppConstants.UPDATE_MULTIPLE_ROLE_ADELEMENT) {
      setSelectedAdElements([]);
    }
  }

  useEffect(() => {
    AdElementStore.addChangeListener(onAdElementStoreChange);

    return () => {
      AdElementStore.removeChangeListener(onAdElementStoreChange);
    };
  });

  return (
    <span data-inverted="" data-tooltip="Move selected ad elements" data-position={toolTipPosition || 'top center'}>
      <Item
        useATag
        disabled={disabledMoveElements(selectedAdElements)}
        style={{ color: 'black' }}
        onClick={() => {
          if (selectedAdElements.length !== 0 && !disabledMoveElements(selectedAdElements)) {

            const selectedAdElementIds = selectedAdElements.map(singleElement => {
              return singleElement.ad_element_id;
            });

            const roleId = (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'preproduction') ? '11' : '6';
            const payload = {
              accountId: ContextStore.routingParams().accountId,
              adElementIds: selectedAdElementIds,
              adElementRoleId: roleId,
              // Fixed position, different values in preprod and prod!
              creativeId: creative.id,
              creativeVersionId: creative.latest_creative_version_id,
              adPositions: adPositions,
              selectedAdElements: selectedAdElements,
            };

            UIActions.moveAdElements(payload);
          }
        }}
      >
        <Icon exchange />
      </Item>
    </span>
  );
}

export default MoveAdElementsButton;

MoveAdElementsButton.propTypes = {
  creative: PropTypes.object,
  adPositions: PropTypes.array,
  toolTipPosition: PropTypes.string,
  disabledMoveElements: PropTypes.func,
};
