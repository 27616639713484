const moduleWrapper = require('components/ModuleWrapper');
const campaignManagerModule = require('components/CampaignManagerModule');

module.exports = {
  path: 'account(/:account)/cm',
  component: moduleWrapper.default,
  name: 'CampaignManager',
  indexRoute: {
    component: campaignManagerModule.default,
  },
  childRoutes: [
    {
      path: '*',
      getComponent(nextState, cb) {
        require.ensure([], () => {
          cb(null, campaignManagerModule.default);
        });
      },
    },
  ],
};
