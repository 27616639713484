import PropTypes from 'prop-types';
import React from 'react';
import UIActions from 'actions/UIActions';
import Dropdown from 'weborama-ui-react/Dropdown';
import Item from 'weborama-ui-react/Item';
import Menu from 'weborama-ui-react/Menu';
import Icon from 'weborama-ui-react/Icon';
import CommentPopup from 'components/CommentPopup';

import { getDateFromTimeStamp, parseComments } from 'constants/Utils';

const InsertionWrapper = ((props) => {
  const handleEditInsertion = () => {
    UIActions.editInsertion(props.insertion);
  };
  const handleRemoveInsertion = () => {
    UIActions.removeInsertion(props.insertion);
  };
  const handleAssignCreatives = () => {
    UIActions.assignCreatives({ insertion: props.insertion, adSpace: props.adSpace, creatives: props.creatives });
  };
  const viewTags = () => {
    UIActions.viewTags(props.insertion);
  };
  const duplicateInsertion = () => {
    UIActions.duplicateInsertion(props.insertion);
  };

  let deliveryFormat = '';
  if (parseInt(props.insertion.delivery_format_id, 10) === 6) {
    deliveryFormat = (
      <span style={{ marginLeft: '10px' }}>
        <i className="youtube play icon" />
        {' Vast / Vpaid'}
      </span>
    );
  } else if (parseInt(props.insertion.delivery_format_id, 10) === 2) {
    deliveryFormat = (
      <span style={{ marginLeft: '10px' }}>
        <i className="image icon" />
        {' Image'}
      </span>
    );
  } else if (parseInt(props.insertion.delivery_format_id, 10) === 1) {
    deliveryFormat = (
      <span style={{ marginLeft: '10px' }}>
        <i className="external icon" />
        {' Redirect'}
      </span>
    );
  }

  let startDateFormat = 'auto';
  if (props.insertion.real_start_date !== null) {
    startDateFormat = getDateFromTimeStamp(new Date(props.insertion.real_start_date).getTime());
  }
  const startDate = (
    <span>
      <i className="clock icon" />
      {startDateFormat}
      {'\u00a0\u00a0'}
    </span>
  );

  let endDateFormat = 'auto';
  if (props.insertion.real_end_date !== null) {
    endDateFormat = getDateFromTimeStamp(new Date(props.insertion.real_end_date).getTime());
  }
  const endDate = (
    <span>
      <i className="flag checkered icon" />
      {endDateFormat}
    </span>
  );
  return (
    <tr>
      <td className="single line collapsing">
        <i
          className={props.isExpanded ? 'minus square icon cursor-pointer' : 'add square icon cursor-pointer'}
          onClick={() => { props.onExpandCollapse(props.insertion); }}
        />
        <Dropdown floating labeled menu isMenu>
          <a className="cursor-pointer">{props.insertion.label}</a>
          <i className="dropdown icon" />
          <Menu>
            <Item onClick={handleEditInsertion}>
              <i className="edit icon" />
              {'Edit insertion'}
            </Item>
            <Item onClick={duplicateInsertion}>
              <i className="copy outline icon" />
              {'Duplicate insertion'}
            </Item>
            <Item onClick={handleAssignCreatives}>
              <i className="linkify icon" />
              {'Link creative(s)'}
            </Item>

            <Item onClick={viewTags}>
              <i className="file text outline icon" />
              {'View tag(s)'}
            </Item>
            <Item onClick={handleRemoveInsertion}>
              <i className="trash icon" />
              {'Remove insertion'}
            </Item>
          </Menu>
        </Dropdown>
        <span style={{ marginLeft: '4px' }}>
          {props.insertion.comments
            && <CommentPopup comment={parseComments(props.insertion.comments)} popup={<div ref="test">hallo</div>} />
          }
        </span>
      </td>
      <td>{props.insertion.stage}</td>
      <td className="single line">{deliveryFormat}</td>
      <td className="single line">{startDate}</td>
      <td className="single line">{endDate}</td>
      <td>
        {(
          <span
            onClick={() => window.open(`${window.location.origin}/public/tags/${props.token[0].token}`, '_blank')}
            data-inverted="true"
            data-size="small"
            data-tooltip="Open insertion tag page"
            style={{ cursor: 'pointer' }}
          >
            <Icon external />
          </span>
      )}
      </td>
    </tr>
  );
});

export default InsertionWrapper;

InsertionWrapper.propTypes = {
  insertion: PropTypes.object,
  adSpace: PropTypes.object,
  creatives: PropTypes.array,
  isExpanded: PropTypes.bool,
  onExpandCollapse: PropTypes.func,
  token: PropTypes.array,
};
