import {dispatch} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const Support = {
  /**
   * Dispatches an action to create a support ticket
   * @param {Object} data data object
   */
  createTicket(data) {
    const obj = Object.assign({}, {
      actionType: AppConstants.CREATE_TICKET,
    }, data);

    dispatch(obj);
  },
};

export default Support;
