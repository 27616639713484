import PropTypes from 'prop-types';
import React from 'react';
import Segment from 'weborama-ui-react/Segment';
import Button from 'weborama-ui-react/Button';
import './AdSpaceSegment.sass';

const adSpace = ((props) => {
  const handleDownloadZip = () => {
    props.onDownloadZipFile(props.adSpace.token);
  };

  const handleDownloadText = () => {
    props.onDownloadTextFile(props.adSpace.token);
  };

  const handleOpenTagPage = () => {
    window.open(`${window.location.origin}/public/tags/${props.adSpace.token}`);
  };

  const showTokenBtn = props.isAuthenticated ? (
    <Button
      toolTip="Open tag page"
      toolTipPosition="bottom right"
      onClick={handleOpenTagPage}
    >
      <i className="external icon" />
    </Button>
  ) : null;

  return (
    <div className="tags-adspace-wrapper">
      <h4 className="ui top attached header tag-adspace-header">
        {props.adSpace.label}
        <span style={{position: 'absolute', right: '7px', top: '7px'}}>
          <div className="ui mini icon buttons">
            {showTokenBtn}
            <Button
              toolTip="Download zip file"
              onClick={handleDownloadZip}
              toolTipPosition="bottom right"
            >
              <i className="file archive outline icon" />
            </Button>
            <Button
              toolTip="Download text file"
              onClick={handleDownloadText}
              toolTipPosition="bottom right"
            >
              <i className="file text outline icon" />
            </Button>
          </div>
        </span>
      </h4>
      <Segment attached>
        {props.children}
      </Segment>
    </div>
  );
});

export default adSpace;

adSpace.propTypes = {
  adSpace: PropTypes.object,
  onDownloadTextFile: PropTypes.func,
  onDownloadZipFile: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  children: PropTypes.any,
};
