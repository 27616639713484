import PropTypes from 'prop-types';
import React from 'react';
import {Field} from 'weborama-ui-react/Form';
import Button, {Buttons} from 'weborama-ui-react/Button';
import Message from 'weborama-ui-react/Message';

const RemoveItemForm = props => (
  <div>
    <div className="content" style={{marginBottom: '20px'}}>
      <Field>
        <Message>
          <h4 className="header">
            {props.header}
            <div className="sub header">
              {props.subHeader}
            </div>
          </h4>
        </Message>
      </Field>
    </div>
    <div className="actions">
      <Buttons two>
        <Button primary approve>Ok!</Button>
        <Button cancel>Cancel</Button>
      </Buttons>
    </div>
  </div>
);

export default RemoveItemForm;

RemoveItemForm.propTypes = {
  header: PropTypes.string,
  subHeader: PropTypes.string,
};
