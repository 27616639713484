const SCREENAD_URL = '//media.adrcdn.com/scripts/screenad_interface_1.0.3_scrambled.js';
const SCREENAD_CLICK = 'screenad.click()';

let results = [];

/**
 * Structured result array item
 * @param {String} status Status after checking
 * @param {String} message Display message
 * @param {Number | Null} amount If quantifiable, found amount
 */
class ResultItem {
  constructor(status, message, amount) {
    this.status = status;
    this.message = message;
    this.amount = amount;
  }
}

// Also check whether injected item is a zip file or a string, then handle accordingly!

/**
 * Returns the head tag from provided stringified html
 * @param {String} htmlText stringified html
 */
function getHead(htmlText) {

  const headRegex = new RegExp(/<head[^>]*>[\s\S]*<\/head>/, 'im');

  const head = htmlText.match(headRegex);

  return head[0];

}

/**
 * Check incoming html text for screenad cdn matches
 * @param {String} htmlText
 */
function countApis(htmlText) {

  const screenadRegex = new RegExp(SCREENAD_URL, 'g');

  if (htmlText.match(screenadRegex)) {
    results.push(new ResultItem('OK', 'ScreenAd link present', null));
  } else {
    results.push(new ResultItem('Error', 'ScreenAd link not present', null));
  }
}

/**
 * Check incoming html text for screenad cdn matches
 * @param {String} htmlText
 */
function countClicks(htmlText) {

  const screenadRegex = new RegExp(SCREENAD_CLICK, 'g');

  const matchedClicks = htmlText.match(screenadRegex);

  if (matchedClicks) {
    results.push(new ResultItem('OK', 'ScreenAd click present', matchedClicks.length));
  } else {
    results.push(new ResultItem('Error', 'ScreenAd click not present', 0));
  }
}

/**
 * Check incoming html text for https protocol matches
 * @param {String} htmlText
 */
function countProtocols(htmlText) {

  const regex = new RegExp(/script(?:.*)\ssrc[\s=]+['"].*:\/\//, 'igm');

  const matchedHttps = htmlText.match(regex);

  // eslint-disable-next-line no-console
  console.log('protocolTestResult', matchedHttps);

  if (matchedHttps && matchedHttps.length > 0) {
    results.push(new ResultItem('Warning', 'Don\'t link directly using https', matchedHttps.length));
  } else {
    results.push(new ResultItem('OK', 'No Https protocol found', 0));
  }
}

/**
 * Check comments in the head
 * @param {String} htmlHead
 */
function checkComments(htmlHead) {

  const screenAdMetadataRegex = new RegExp(/<!--[\s\S]*->/, 'i');
  const scrVersionRegex = new RegExp(/SCRVERSION: screenad_interface_1.0.3/, 'i');
  const scrFormatRegex = new RegExp(/<!--\s*SCRFORMAT:[\s\S]*->/, 'i');
  const scrSticky = new RegExp(/<!--\s*SCRSTICKY:[\s\S]*->/, 'i');
  const scrWidth = new RegExp(/<!--\s*SCRWIDTH:[\s\S]*->/, 'i');
  const scrHeight = new RegExp(/<!--\s*SCRHEIGHT:[\s\S]*->/, 'i');

  if (
    htmlHead.match(screenAdMetadataRegex)
    && htmlHead.match(scrVersionRegex)
    && htmlHead.match(scrFormatRegex)
    && htmlHead.match(scrSticky)
    && htmlHead.match(scrWidth)
    && htmlHead.match(scrHeight)
  ) {
    results.push(new ResultItem('OK', 'Comments are present within head', null));
  } else {
    results.push(new ResultItem('Error', 'Missing comments in the head', null));
  }

}

export default function UploadChecker(htmlText) {

  results = []; // Clear the results

  getHead(htmlText);
  countApis(htmlText);
  countClicks(htmlText);
  countProtocols(htmlText);
  checkComments(getHead(htmlText));
  // console.log(results);
  // console.log(htmlText);

  return results;
}
