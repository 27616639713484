import {dispatch} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const Size = {
  /**
   * Dispatches action to fetch a list of ad sizes
   * @date   2016-06-07
   */
  list() {
    dispatch({
      actionType: AppConstants.LIST_SIZES,
    });
  },
};

export default Size;
