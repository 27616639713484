import {dispatch} from 'dispatchers/AppDispatcher';
import AppConstants from 'constants/AppConstants';

const AdElementRole = {
  /**
   * Dispatches an action to list ad element roles
   * @date   2016-07-22
   */
  list() {
    dispatch({
      actionType: AppConstants.LIST_AD_ELEMENT_ROLES,
    });
  },
};

export default AdElementRole;
