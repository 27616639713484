import BaseStore from 'stores/BaseStore';
import AppConstants from 'constants/AppConstants';
import ServerConstants from 'constants/ServerConstants';
import {register} from 'dispatchers/AppDispatcher';
/**
 * Handles campaign labels
 */
const CampaignLabelStore = Object.assign({}, BaseStore, {
  campaignLabels: [],
  /**
   * Returns list with campaign labels
   * @date   2016-12-16
   * @return {Array}   campaign labels
   */
  getCampaignlabels() {
    return this.campaignLabels;
  },
  /**
   * Fetches a list of campaign labels
   * @date   2016-12-16
   * @param  {number}   accountId Account Id
   */
  fetchCampaignLabels(data) {
    const onSuccess = (response) => {
      this.campaignLabels = response.data.slice();
      this.emitChange({action: AppConstants.LIST_CAMPAIGN_LABELS});
    };
    const onError = (message) => {
      this.emitError({action: AppConstants.LIST_CAMPAIGN_LABELS, message});
    };

    this.post({
      url: ServerConstants.CAMPAIGN_MANAGER.CAMPAIGN_LABELS.LIST,
      data,
      onSuccess,
      onError,
    });
  },
  dispatcherIndex: register((action) => {
    switch (action.actionType) {
      case AppConstants.LIST_CAMPAIGN_LABELS:
        CampaignLabelStore.fetchCampaignLabels({account_id: action.accountId});
        break;
      // no default
    }
  }),
});

export default CampaignLabelStore;
