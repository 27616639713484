import React from 'react';
import PropTypes from 'prop-types';
import Table from 'weborama-ui-react/Table';

const FileList = ((props) => {
  let files = null;
  let counter = 0;
  if (Object.prototype.hasOwnProperty.call(props.fileType, 'files')) {
    files = props.fileType.files.map((file) => {
      counter += 1;
      return (
        <tr key={counter}><td>{file.name}</td></tr>
      );
    });
  }

  return (
    <div style={{maxHeight: '200px', overflowY: 'auto', overflowX: 'hidden'}}>
      <Table single line compact very basic>
        <thead>
          <tr><th>{props.fileType.type}</th></tr>
        </thead>
        <tbody>
          {files}
        </tbody>
      </Table>
    </div>
  );
});

export default FileList;

FileList.propTypes = {
  fileType: PropTypes.object,
};
