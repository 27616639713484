export default {
  ADD_FOLDER: 'ui_add_folder',
  EDIT_FOLDER: 'ui_edit_folder',
  REMOVE_FOLDER: 'ui_remove_folder',
  ADD_CREATIVE: 'ui_add_creative',
  EDIT_CREATIVE: 'ui_edit_creative',
  DOWNLOAD_CREATIVE: 'ui_download_creative',
  REMOVE_CREATIVE: 'ui_remove_creative',
  DUPLICATE_CREATIVE: 'ui_duplicate_creative',
  ADD_ADPOSITION: 'ui_add_adposition',
  REMOVE_ADPOSITION: 'ui_remove_adposition',
  SELECT_FILE: 'ui_select_file',
  ADD_PROJECT: 'ui_add_project',
  EDIT_PROJECT: 'ui_edit_project',
  REMOVE_PROJECT: 'ui_remove_project',
  ADD_CAMPAIGN: 'ui_add_campaign',
  EDIT_CAMPAIGN: 'ui_edit_campaign',
  REMOVE_CAMPAIGN: 'ui_remove_campaign',
  ADD_INSERTION: 'ui_add_insertion',
  EDIT_INSERTION: 'ui_edit_insertion',
  REMOVE_INSERTION: 'ui_remove_insertion',
  ASSIGN_CREATIVE: 'ui_assign_creative',
  ASSING_CAMPAIGN: 'ui_assign_campaign',
  SET_DEFAULT_CREATIVE: 'ui_set_default_creative',
  VIEW_TAGS: 'ui_view_tags',
  DUPLICATE_INSERTION: 'ui_duplicate_insertion',
  UNASSIGN_CREATIVE: 'ui_unassign_creative',
  GENERATE_TAGS: 'ui_generate_tags',
  MANAGE_AD_NETWORKS: 'ui_manage_ad_networks',
  REPORT_BUG: 'ui_report_bug',
  MANAGE_VAST_SETTINGS: 'manage_vast_settings',
  ADD_EXTRA_CLICK: 'ui_add_extra_click',
  ADD_TRACKER: 'ui_add_tracker',
  EDIT_TRACKER: 'ui_edit_tracker',
  ADD_AD_NETWORK: 'ui_add_ad_network',
  EDIT_AD_NETWORK: 'ui_edit_ad_network',
  REMOVE_AD_NETWORK: 'ui_remove_ad_network',
  ADD_AD_SPACE: 'ui_add_ad_space',
  EDIT_AD_SPACE: 'ui_edit_ad_space',
  REMOVE_AD_SPACE: 'ui_remove_ad_space',
  CLEAR_ACCOUNT_CACHE: 'ui_clear_account_cache',
  EASTER_EGG: 'ui_easter_egg',
  RECREATE_SETUP_SCRIPT: 'ui_recreate-setup_script',
  EDIT_SETUP_SCRIPT: 'ui_edit_setup_script',
  CHANGE_ACCOUNT: 'ui_change_account',
  OPEN_SITE_OFFER_MODAL: 'ui_open_site_offer_modal',
  MOVE_ADELEMENTS: 'move_adelements'
};
