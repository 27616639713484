import React from 'react';
import Header from 'weborama-ui-react/Header';
import Icon from 'weborama-ui-react/Icon';
import {getDateFromTimeStamp} from 'constants/Utils';
import PropTypes from 'prop-types';

function calculateFolderStatus(props) {
  if (props.folder.created_at) {
    // If there is a folder, display it
    // replace - with / to avoid Nan value in firefox
    return 'Created: ' + getDateFromTimeStamp(props.folder.created_at?.replace(/-/g, '/'));
  }

  return 'No folders found';
}

const FolderDetails = props => (
  <Header h2>
    <Icon folder open grey />
    <div className="content">
      {props.folder.label}
      <div className="sub header">
        {calculateFolderStatus(props)}
      </div>
    </div>
  </Header>
);

export default FolderDetails;

FolderDetails.propTypes = {
  folder: PropTypes.object,
};
